import { useTranslation } from 'react-i18next';
import MobileBackButton from '../../../components/common/MobileBackButton';
import Breadcrumb from '../../../components/signup/quick/Breadcrumb';
import QuickCompleteSection from '../../../components/signup/quick/QuickComplete';

export default function QuickSignupComplete() {
  const { t } = useTranslation();

  return (
    <>
      <section className="bg-[#F8F7F8] pt-[180px] pb-48 max-md:pt-[62px] max-md:pb-24 max-md:bg-white">
        <div className="flex justify-center max-md:block">
          <div>
            <section className="flex flex-col text-[32px] max-2xl:text-3xl space-y-1 max-md:px-5 max-md:text-2xl">
              <MobileBackButton />

              <div className="leading-[140%]">
                <h1>{t('이제 고객님의 전용 AI직원과')}</h1>
                <h1 className="font-bold">{t('대화하실 수 있어요!')}</h1>
              </div>
              <div className="text-black4 text-base !mt-8">
                <Breadcrumb current="complete" />
              </div>
            </section>

            <section className="mt-7 p-10 bg-white rounded-lg text-lg space-y-10 max-md:px-5 max-md:w-full max-md:mt-5 max-md:p-5 md:min-w-[720px] w-full">
              <QuickCompleteSection />
            </section>
          </div>
        </div>
      </section>
    </>
  );
}
