export default function TopArrow() {
  return (
    <svg
      className="w-4 h-5 max-md:w-[10px] max-md:h-[13px]"
      viewBox="0 0 17 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.7141 7.28501L8.71409 0.285014C8.61899 0.193973 8.50684 0.122608 8.38409 0.0750135C8.14063 -0.0250045 7.86755 -0.0250045 7.62409 0.0750135C7.50134 0.122608 7.3892 0.193973 7.29409 0.285014L0.294092 7.28501C0.200853 7.37825 0.126893 7.48894 0.0764322 7.61076C0.0259719 7.73259 0 7.86315 0 7.99501C0 8.26132 0.105788 8.51671 0.294092 8.70501C0.482395 8.89332 0.73779 8.99911 1.00409 8.99911C1.27039 8.99911 1.52579 8.89332 1.71409 8.70501L7.00409 3.40501V18.995C7.00409 19.2602 7.10945 19.5146 7.29699 19.7021C7.48452 19.8897 7.73887 19.995 8.00409 19.995C8.26931 19.995 8.52366 19.8897 8.7112 19.7021C8.89874 19.5146 9.00409 19.2602 9.00409 18.995V3.40501L14.2941 8.70501C14.3871 8.79874 14.4977 8.87314 14.6195 8.9239C14.7414 8.97467 14.8721 9.00081 15.0041 9.00081C15.1361 9.00081 15.2668 8.97467 15.3887 8.9239C15.5105 8.87314 15.6211 8.79874 15.7141 8.70501C15.8078 8.61205 15.8822 8.50145 15.933 8.37959C15.9838 8.25773 16.0099 8.12703 16.0099 7.99501C16.0099 7.863 15.9838 7.7323 15.933 7.61044C15.8822 7.48858 15.8078 7.37798 15.7141 7.28501Z"
        fill="black"
      />
    </svg>
  );
}
