export default function HandStarIcon() {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.86127 1.36335C9.36793 0.45445 9.62126 0 10 0C10.3787 0 10.6321 0.45445 11.1387 1.36335L11.2698 1.59849C11.4138 1.85677 11.4858 1.98591 11.598 2.07112C11.7103 2.15633 11.8501 2.18796 12.1296 2.25122L12.3842 2.30881C13.3681 2.53142 13.86 2.64273 13.977 3.01909C14.0941 3.39546 13.7587 3.78763 13.088 4.57197L12.9144 4.77489C12.7238 4.99777 12.6285 5.10922 12.5857 5.24709C12.5428 5.38496 12.5572 5.53364 12.586 5.83102L12.6122 6.10176C12.7136 7.14824 12.7644 7.67148 12.4579 7.90409C12.1515 8.13669 11.6909 7.92462 10.7697 7.50047L10.5314 7.39074C10.2696 7.2702 10.1387 7.20994 10 7.20994C9.86126 7.20994 9.73037 7.27021 9.46859 7.39074L9.23027 7.50047C8.30907 7.92462 7.84847 8.13669 7.54206 7.90409C7.23565 7.67148 7.28635 7.14824 7.38776 6.10176L7.41399 5.83102C7.44281 5.53364 7.45722 5.38496 7.41435 5.24709C7.37147 5.10922 7.27617 4.99777 7.08557 4.77489L6.91204 4.57197C6.2413 3.78763 5.90593 3.39546 6.02297 3.01909C6.14001 2.64273 6.63194 2.53142 7.61581 2.30881L7.87035 2.25122C8.14994 2.18796 8.28973 2.15633 8.40198 2.07112C8.51422 1.98591 8.58621 1.85677 8.73019 1.59849L8.86127 1.36335Z"
        fill="url(#paint0_linear_1726_100858)"
      />
      <path
        d="M17.4306 5.68168C17.684 5.22723 17.8106 5 18 5C18.1894 5 18.316 5.22722 18.5694 5.68167L18.6349 5.79925C18.7069 5.92839 18.7429 5.99296 18.799 6.03556C18.8551 6.07817 18.925 6.09398 19.0648 6.12561L19.1921 6.15441C19.684 6.26571 19.93 6.32136 19.9885 6.50955C20.047 6.69773 19.8794 6.89381 19.544 7.28598L19.4572 7.38744C19.3619 7.49889 19.3143 7.55461 19.2928 7.62354C19.2714 7.69248 19.2786 7.76682 19.293 7.91551L19.3061 8.05088C19.3568 8.57412 19.3822 8.83574 19.229 8.95204C19.0758 9.06835 18.8455 8.96231 18.3849 8.75023L18.2657 8.69537C18.1348 8.6351 18.0694 8.60497 18 8.60497C17.9306 8.60497 17.8652 8.6351 17.7343 8.69537L17.6151 8.75023C17.1545 8.96231 16.9242 9.06835 16.771 8.95204C16.6178 8.83574 16.6432 8.57412 16.6939 8.05088L16.707 7.91551C16.7214 7.76682 16.7286 7.69248 16.7072 7.62354C16.6857 7.55461 16.6381 7.49889 16.5428 7.38744L16.456 7.28599C16.1206 6.89381 15.953 6.69773 16.0115 6.50955C16.07 6.32136 16.316 6.26571 16.8079 6.15441L16.9352 6.12561C17.075 6.09398 17.1449 6.07817 17.201 6.03556C17.2571 5.99296 17.2931 5.92839 17.3651 5.79925L17.4306 5.68168Z"
        fill="url(#paint1_linear_1726_100858)"
      />
      <path
        d="M1.43063 5.68168C1.68396 5.22723 1.81063 5 2 5C2.18937 5 2.31604 5.22722 2.56937 5.68167L2.63491 5.79925C2.7069 5.92839 2.74289 5.99296 2.79901 6.03556C2.85513 6.07817 2.92503 6.09398 3.06482 6.12561L3.19209 6.15441C3.68403 6.26571 3.93 6.32136 3.98852 6.50955C4.04704 6.69773 3.87935 6.89381 3.54398 7.28598L3.45722 7.38744C3.36191 7.49889 3.31426 7.55461 3.29283 7.62354C3.27139 7.69248 3.27859 7.76682 3.293 7.91551L3.30612 8.05088C3.35682 8.57412 3.38218 8.83574 3.22897 8.95204C3.07576 9.06835 2.84546 8.96231 2.38487 8.75023L2.2657 8.69537C2.13481 8.6351 2.06937 8.60497 2 8.60497C1.93063 8.60497 1.86519 8.6351 1.7343 8.69537L1.61513 8.75023C1.15454 8.96231 0.924235 9.06835 0.77103 8.95204C0.617824 8.83574 0.643176 8.57412 0.693879 8.05088L0.706997 7.91551C0.721405 7.76682 0.728609 7.69248 0.707173 7.62354C0.685736 7.55461 0.638085 7.49889 0.542784 7.38744L0.456019 7.28599C0.12065 6.89381 -0.0470351 6.69773 0.0114842 6.50955C0.0700035 6.32136 0.315971 6.26571 0.807907 6.15441L0.935177 6.12561C1.07497 6.09398 1.14487 6.07817 1.20099 6.03556C1.25711 5.99296 1.29311 5.92839 1.36509 5.79925L1.43063 5.68168Z"
        fill="url(#paint2_linear_1726_100858)"
      />
      <path
        d="M2 19.3884H4.25993C5.27079 19.3884 6.29253 19.4937 7.27633 19.6964C9.01657 20.0549 10.8488 20.0983 12.6069 19.8138C13.4738 19.6734 14.326 19.4589 15.0975 19.0865C15.7939 18.7504 16.6469 18.2766 17.2199 17.7459C17.7921 17.216 18.388 16.3487 18.8109 15.6707C19.1736 15.0894 18.9982 14.3762 18.4245 13.943C17.7873 13.4619 16.8417 13.462 16.2046 13.9433L14.3974 15.3084C13.697 15.8375 12.932 16.3245 12.0206 16.4699C11.911 16.4874 11.7962 16.5033 11.6764 16.5172M11.6764 16.5172C11.6403 16.5214 11.6038 16.5254 11.5668 16.5292M11.6764 16.5172C11.8222 16.486 11.9669 16.396 12.1028 16.2775C12.746 15.7161 12.7866 14.77 12.2285 14.1431C12.0991 13.9977 11.9475 13.8764 11.7791 13.7759C8.98173 12.1074 4.62942 13.3782 2 15.2429M11.6764 16.5172C11.6399 16.525 11.6033 16.5292 11.5668 16.5292M11.5668 16.5292C11.0434 16.5829 10.4312 16.5968 9.75177 16.5326"
        stroke="url(#paint3_linear_1726_100858)"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1726_100858"
          x1="6"
          y1="0"
          x2="11.8434"
          y2="9.34808"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF478B" />
          <stop offset="1" stopColor="#FFBB54" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1726_100858"
          x1="16"
          y1="5"
          x2="18.9217"
          y2="9.67404"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF478B" />
          <stop offset="1" stopColor="#FFBB54" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1726_100858"
          x1="0"
          y1="5"
          x2="2.92169"
          y2="9.67404"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF478B" />
          <stop offset="1" stopColor="#FFBB54" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_1726_100858"
          x1="2"
          y1="13"
          x2="4.74604"
          y2="23.6688"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF478B" />
          <stop offset="1" stopColor="#FFBB54" />
        </linearGradient>
      </defs>
    </svg>
  );
}
