export default function MicIcon() {
  return (
    <svg
      className="w-[100px] h-[100px] max-md:w-[72px] max-md:h-[72px]"
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="50" cy="50" r="50" fill="#222222" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40.801 38.8403C40.801 33.9601 44.7612 30 49.6414 30C54.5215 30 58.4817 33.9601 58.4817 38.8403V49.0807C58.4817 53.9609 54.5215 57.921 49.6414 57.921C44.7612 57.921 40.801 53.9609 40.801 49.0807V38.8403ZM51.1414 44.7605C51.6614 44.7605 52.1214 44.4205 52.2614 43.9005C52.4215 43.2805 52.0614 42.6405 51.4614 42.4805C50.2814 42.1604 49.0413 42.1604 47.8613 42.4805C47.2413 42.6405 46.8613 43.2805 47.0413 43.9005C47.2013 44.5205 47.8213 44.9005 48.4613 44.7205C49.2413 44.5005 50.0614 44.5005 50.8414 44.7205L50.8414 44.7205C50.9414 44.7405 51.0414 44.7605 51.1414 44.7605ZM52.2214 40.7804C52.7015 40.7804 53.1415 40.5004 53.3215 40.0204C53.5415 39.4203 53.2215 38.7603 52.6215 38.5403C50.7014 37.8403 48.5813 37.8403 46.6612 38.5403C46.0612 38.7403 45.7412 39.4203 45.9612 40.0204C46.1812 40.6204 46.8613 40.9404 47.4613 40.7204C48.8613 40.2004 50.4214 40.2004 51.8214 40.7204C51.9614 40.7604 52.0814 40.7804 52.2214 40.7804ZM62.481 45.6393C62.481 44.8593 63.1011 44.2393 63.8811 44.2393C64.6411 44.2393 65.2611 44.8593 65.2811 45.6193V48.7794C65.2811 56.9197 59.0009 63.64 51.0406 64.34V68.6002C51.0406 69.3802 50.4206 70.0002 49.6406 70.0002C48.8605 70.0002 48.2405 69.3802 48.2405 68.6002V64.34C40.2602 63.62 34 56.9197 34 48.7794V45.6193C34 44.8393 34.62 44.2193 35.4001 44.2193C36.1801 44.2193 36.8001 44.8393 36.8001 45.6193V48.7994C36.8001 55.8797 42.5603 61.6399 49.6406 61.6399C56.7208 61.6399 62.481 55.8797 62.481 48.7994V45.6393Z"
        fill="url(#paint0_linear_1628_100536)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1628_100536"
          x1="34"
          y1="30"
          x2="65.7031"
          y2="69.6628"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF478B" />
          <stop offset="1" stopColor="#FFBB54" />
        </linearGradient>
      </defs>
    </svg>
  );
}
