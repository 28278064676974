import { SetStateAction } from 'react';
import { EnterpriseInfoType } from '../../../views/help/find-id';
import { numberException } from '../../hooks/useValidate';

export default function FindIdComponent({
  userType,
  enterpriseInfo,
  setEnterpriseInfo,
}: {
  userType: 'M03A0' | 'M03B0';
  enterpriseInfo: EnterpriseInfoType;
  setEnterpriseInfo: React.Dispatch<SetStateAction<EnterpriseInfoType>>;
}) {
  return (
    <div>
      {userType === 'M03A0' ? (
        <div className="flex flex-col text-xl max-md:text-base">
          <span>휴대폰 본인인증을 통해 아이디(이메일)를</span>
          <span>찾을 수 있습니다.</span>
        </div>
      ) : (
        <div className="text-left flex flex-col space-y-7 max-md:space-y-5">
          <div className="space-y-3">
            <span className="text-lg max-md:text-base">회사명</span>
            <input
              className="w-full py-4 px-3 rounded-lg border border-borderLightGray focus:border-primary max-md:p-3"
              value={enterpriseInfo.companyNm}
              onChange={(e) =>
                setEnterpriseInfo((prev) => ({
                  ...prev,
                  companyNm: e.target.value,
                }))
              }
              maxLength={20}
              placeholder="회사명을 입력해주세요."
              type="text"
            />
          </div>
          <div className="space-y-3">
            <span className="text-lg max-md:text-base">사업자등록번호</span>
            <input
              onChange={(e) => {
                setEnterpriseInfo((prev) => ({
                  ...prev,
                  memberBrno: e.target.value,
                }));
              }}
              value={enterpriseInfo.memberBrno}
              onKeyDown={(e) => {
                numberException.includes(e.key) && e.preventDefault();
                enterpriseInfo.memberBrno.length &&
                  enterpriseInfo.memberBrno.length > 9 &&
                  e.key !== 'Backspace' &&
                  e.key !== 'Tab' &&
                  e.preventDefault();
              }}
              className="w-full py-4 px-3 rounded-lg border border-borderLightGray focus:border-primary max-md:p-3"
              placeholder="숫자만 10자리 입력해주세요."
              type="number"
            />
          </div>
          <div className="space-y-3">
            <span className="text-lg max-md:text-base">개업일자</span>
            <input
              onChange={(e) => {
                setEnterpriseInfo((prev) => ({
                  ...prev,
                  establishYmd: e.target.value,
                }));
              }}
              onKeyDown={(e) => {
                numberException.includes(e.key) && e.preventDefault();
                enterpriseInfo.establishYmd.length &&
                  enterpriseInfo.establishYmd.length > 7 &&
                  e.key !== 'Backspace' &&
                  e.key !== 'Tab' &&
                  e.preventDefault();
              }}
              value={enterpriseInfo.establishYmd}
              className="w-full py-4 px-3 rounded-lg border border-borderLightGray focus:border-primary max-md:p-3"
              placeholder="'-'없이 8자리를 입력해주세요."
              type="number"
            />
          </div>
          <div className="space-y-3">
            <span className="text-lg max-md:text-base">대표자명</span>
            <input
              onChange={(e) => {
                setEnterpriseInfo((prev) => ({
                  ...prev,
                  ceoFnm: e.target.value,
                }));
              }}
              maxLength={20}
              value={enterpriseInfo.ceoFnm}
              className="w-full py-4 px-3 rounded-lg border border-borderLightGray focus:border-primary max-md:p-3"
              placeholder="대표자명을 입력해주세요."
              type="text"
            />
          </div>
        </div>
      )}
    </div>
  );
}
