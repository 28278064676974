import { Dialog, Transition } from '@headlessui/react';
import { Fragment, SetStateAction } from 'react';
import LoginComponent from '../LoginComponent';

export default function LoginModal({
  closeModal,
  modal,
}: {
  closeModal: React.Dispatch<SetStateAction<boolean>>;
  modal: boolean;
}) {
  return (
    <>
      <Transition appear show={modal} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-50"
          onClose={() => {
            closeModal(false);
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 max-md:inset-0 max-md:bottom-0 rounded-xl overflow-y-hidden">
            <div className="flex min-h-full items-center justify-center p-4 text-center max-md:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-300"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full px-10 max-w-[620px] rounded-[20px] pt-12 pb-[68px] transform overflow-hidden bg-white text-black2 transition-all max-md:w-screen">
                  <LoginComponent
                    isPopup
                    closeModal={() => closeModal(false)}
                  />
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
