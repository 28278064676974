import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { snsLoginArray } from '../../../array/myPageArray';
import { SNS_REDIRECT_URI, SSO_URL } from '../../../constants';
import { getSnsInfo } from '../../../services/PrivateService';
import { OnChangePersonalInfoType } from '../../../services/TypeOfPrivateService';
import { fileBaseURL } from '../../../services/defaultClient';
import PostcodeModal from '../../common/PostcodeModal';
import InformModal from '../../common/modal/InformModal';
import { usePloonianImage } from '../../hooks/usePloonian';
import AgreementSection from './AgreementSection';
import DefaultProfileIcon from './DefaultProfileIcon';
import PasswordModal from './PasswordModal';
import PloonianInfo from './ploonian-info';

const divStyle =
  'py-8 border-b border-borderGray flex items-center max-md:justify-between max-md:px-0 max-md:py-4 max-md:border-none';
const titleStyle =
  'w-[200px] min-w-[200px] font-bold text-lg max-md:text-base max-md:min-w-fit';

export default function MyInformation({
  myInfo,
  profileImage,
  setProfileImage,
  setOnChangeInfo,
  onChangeInfo,
  setPloonianModal,
}: {
  myInfo: any;
  onChangeInfo: OnChangePersonalInfoType;
  profileImage: File | null;
  setProfileImage: React.Dispatch<React.SetStateAction<File | null>>;
  setOnChangeInfo: React.Dispatch<
    React.SetStateAction<OnChangePersonalInfoType>
  >;
  setPloonianModal: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const [key, setKey] = useState(0);
  const [imageUrl, setImageUrl] = useState<any>(null);
  const [initialPath, setInitialPath] = useState('');
  const [passwordModal, setPasswordModal] = useState(false);
  const [informModal, setInformModal] = useState({
    isOpen: false,
    phrase: '',
    content: '',
  });
  const [address, setAddress] = useState({
    roadName: '',
    buildingName: '',
    zipCode: '',
  });
  const [openPostcodeModal, setOpenPostcodeModal] = useState(false);
  const [snsList, setSnsList] = useState([]);
  const { t } = useTranslation();
  const { ploonianThumbnail } = usePloonianImage();
  const isQuickStartUser = myInfo.quickstartStateCd === 1;
  const informText =
    '자세한 사항은 MY문의 > 웹문의 또는 고객센터(1533-6116)로 문의해주세요.';

  useEffect(() => {
    setOnChangeInfo((prev) => ({
      ...prev,
      memberTypeCd: myInfo.memberTypeCd,
      memberAddr1: myInfo.memberAddr1,
      memberAddr2: myInfo.memberAddr2,
      memberZipno: myInfo.memberZipno,
      termsAgreeOptional: {
        ...prev.termsAgreeOptional,
        termsAgreeYn: myInfo.termsAgreeOptional.termsAgreeYn,
        smsAgreeYn: myInfo.termsAgreeOptional.smsAgreeYn,
        mailAgreeYn: myInfo.termsAgreeOptional.mailAgreeYn,
      },
    })),
      setInitialPath(
        !myInfo.profileImagePath ? '' : fileBaseURL + myInfo.profileImagePath
      );
  }, [myInfo]);

  useEffect(() => {
    profileImage
      ? setImageUrl(URL.createObjectURL(profileImage))
      : setImageUrl(null);
  }, [profileImage]);

  useEffect(() => {
    if (!address.roadName) {
      return;
    }
    setOnChangeInfo((prev) => ({
      ...prev,
      memberAddr1:
        address.roadName +
        (address.buildingName ? `, ${address.buildingName}` : ''),
      memberZipno: address.zipCode,
    }));
  }, [address.roadName]);

  const getSnsList = () => {
    getSnsInfo()
      .then((res) => {
        if (Array.isArray(res.data.data)) {
          setSnsList(res.data.data);
        }
      })
      .catch((err) => alert(err));
  };

  useEffect(() => {
    getSnsList();
  }, []);

  const connectSns = (code: string, connected: boolean) => {
    // 반디 sns 연동 및 연동해제 팝업 호출
    const snsurl = !connected
      ? SSO_URL +
        '/sso/sns/register?alk_site=' +
        code +
        '&redirect_uri=' +
        SNS_REDIRECT_URI
      : SSO_URL +
        '/sso/sns/revoke?alk_site=' +
        code +
        '&redirect_uri=' +
        SNS_REDIRECT_URI;
    window.open(snsurl, 'snsPopup', 'width=600, height=800');
  };

  // 반디 sns연동 완료 후 처리
  window.addEventListener('message', function (e) {
    if (e.data.error) {
      switch (e.data.error) {
        case 'ALREADY_REGIST_SNS_SITE_KEY':
          openInfomModal('이미 연동되어 있는 SNS 계정 입니다.', informText);
          break;
        case 'SSO_SNS_ALREADY_EXIST':
          openInfomModal(
            '이미 다른 계정에 연동되어 있는 SNS 계정 입니다.',
            informText
          );
          break;
        case 'NOT_LOGIN':
          openInfomModal(
            '로그인 유효 시간이 만료되었습니다. 다시 로그인 후 시도해주세요.',
            ''
          );
          break;
        case 'NOT_REGIST_SNS_SITE_KEY':
          openInfomModal('이미 연동이 해지된 SNS 계정 입니다.', informText);
          break;
        case 'NOT_REGIST_SNS_KEY':
          openInfomModal(
            '요청한 SNS 키로 등록되지 않은 사용자입니다.',
            informText
          );
          break;
        case 'NOT_SAME_SNS_INFO':
          openInfomModal(
            '요청한 SNS 정보와 등록된 SNS 정보가 일치하지 않습니다.',
            informText
          );
          break;
      }
    } else if (e.data.snsSuccessFlag === 'snsRegistSuccessFlag') {
      openInfomModal('소셜 계정 연동이 완료되었습니다.', '');
      getSnsList();
    } else if (e.data.snsSuccessFlag === 'snsRevokeSuccessFlag') {
      openInfomModal('소셜 계정 연동이 해지되었습니다.', '');
      getSnsList();
    }
  });

  const openInfomModal = (phrase: string, content: string) => {
    setInformModal({
      isOpen: true,
      phrase: phrase,
      content: content,
    });
  };

  const onChangeFileHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    } else if (e.target.files[0].size > 5e6) {
      setInformModal({
        isOpen: true,
        phrase: '파일 제한 용량을 초과했습니다.',
        content: '',
      });
      return;
    }
    setProfileImage(e.target.files[0]);
  };

  const deleteProfileImage = () => {
    setOnChangeInfo((prev) => ({
      ...prev,
      profileImagePathDelYn: 'Y',
    }));
    setInitialPath('');
    setProfileImage(null);
    setKey((prev) => prev + 1);
  };

  return (
    <>
      <PasswordModal
        modal={passwordModal}
        closeModal={() => setPasswordModal(false)}
      />
      <InformModal
        modal={informModal.isOpen}
        phrase={informModal.phrase}
        content={informModal.content}
        closeModal={() =>
          setInformModal((prev) => ({ ...prev, isOpen: false }))
        }
      />
      <PostcodeModal
        closeModal={() => setOpenPostcodeModal(false)}
        openPostcodeModal={openPostcodeModal}
        setAddress={setAddress}
      />

      <div className="w-full mb-20 max-md:mb-10 max-md:w-full text-lg text-black2">
        <h1 className="font-bold text-[32px] max-2xl:text-3xl pb-5 border-b-2 border-black max-md:text-2xl">
          {t('기본 정보')}
        </h1>
        <FixedInfoBox label="아이디(이메일)" displayInfo={myInfo.memberEmail} />
        <div className={divStyle}>
          <div className={titleStyle}>{t('비밀번호')}</div>
          <button
            onClick={() => setPasswordModal(true)}
            className="border border-black py-3 px-6 max-2xl:py-2 max-2xl:px-5 rounded-md text-lg hover:bg-gray5 max-md:text-base max-md:px-4 max-md:min-w-fit"
          >
            {t('비밀번호 변경')}
          </button>
        </div>
        <FixedInfoBox label="이름" displayInfo={myInfo.memberFnm1} />
        <FixedInfoBox label="성별" displayInfo={myInfo.genderCdNm} />
        <FixedInfoBox
          label="휴대폰 번호"
          displayInfo={myInfo.mobileTelno || ''}
        />
        <div
          className={
            'py-8 border-b border-borderGray flex items-center max-md:flex-col max-md:items-start max-md:px-0 max-md:py-4 max-md:space-y-4 max-md:border-none'
          }
        >
          <div className={titleStyle}>{t('SNS 로그인 연동')}</div>
          <div className="flex flex-col space-y-3 w-2/3 max-md:w-full">
            {snsLoginArray.map(({ code, name, icon }, idx) => {
              let sns = snsList
                ? snsList.find((o: any) => o.snsTypeCd === code)
                : false;
              let connectecd = sns ? true : false;
              return (
                <div
                  key={`sns-${idx}`}
                  className="flex justify-between max-md:w-full"
                >
                  <span className="flex items-center max-md:text-sm">
                    <i className="mr-2">{icon}</i>
                    {t(name)}
                  </span>
                  <div className="flex items-center space-x-4 max-md:space-x-1">
                    {sns && (
                      <span className="text-black max-md:text-xs">
                        {t('연동중')} &#40;{' '}
                        {moment(sns['snsConnecteDt']).format('YYYY.MM.DD')}{' '}
                        &#41;
                      </span>
                    )}
                    <button
                      onClick={() => connectSns(code, connectecd)}
                      className="border border-borderLightGray rounded-lg hover:bg-gray5 w-fit cursor-pointer py-2 px-3 max-md:text-sm max-md:p-[10px]"
                    >
                      {sns ? t('연동해지') : t('연동하기')}
                    </button>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div
          className={`${divStyle} max-md:flex-col max-md:items-start max-md:space-y-3`}
        >
          <div className={titleStyle}>{t('나의 플루니안')}</div>
          <PloonianInfo
            quickstartDetail={myInfo.quickstartDetail}
            isQuickStartUser={isQuickStartUser}
            setPloonianModal={setPloonianModal}
            ploonianThumbnail={ploonianThumbnail}
          />
        </div>

        <section>
          <h1 className="font-bold text-[32px] max-2xl:text-3xl pb-5 border-b-2 border-black max-md:text-2xl mt-[60px] max-2xl:mt-12">
            {t('추가 정보')}
          </h1>
          <div className="py-8 flex items-center max-md:flex-col max-md:items-start max-md:px-0 max-md:py-4 max-md:space-y-4 max-md:border-none">
            <div className={titleStyle}>{t('주소')}</div>
            <div className="flex flex-col w-2/3 space-y-3 max-md:w-full">
              <div className="w-full space-x-3 flex justify-between">
                <input
                  className="border bg-gray5 border-borderLightGray py-3 px-4 rounded-md min-w-[370px] w-full text-lg max-md:text-base max-md:min-w-0"
                  type="text"
                  disabled
                  value={
                    address.roadName
                      ? `${address.roadName}, ${address.buildingName}`
                      : myInfo.memberAddr1?.length > 2
                      ? myInfo.memberAddr1
                      : ''
                  }
                  placeholder={t('주소를 검색해주세요.')}
                />
                <button
                  onClick={() => setOpenPostcodeModal(true)}
                  className="border border-black py-3 px-6 rounded-md max-w-[140px] w-full max-md:w-1/3 text-lg hover:bg-gray5 max-md:text-base max-md:px-4"
                >
                  {t('주소검색')}
                </button>
              </div>
              <input
                className="border border-borderLightGray focus:border-primary py-3 px-4 rounded-md min-w-full text-lg max-md:text-base"
                type="text"
                value={onChangeInfo.memberAddr2}
                maxLength={500}
                placeholder={t('상세주소를 입력해주세요.')}
                onChange={(e) =>
                  setOnChangeInfo((prev) => ({
                    ...prev,
                    memberAddr2: e.target.value,
                  }))
                }
              />
            </div>
          </div>

          <div className="py-8 border-y border-borderGray flex items-center max-md:flex-col max-md:space-y-3 max-md:items-start max-md:p-0 max-md:py-5">
            <div className={titleStyle}>{t('프로필 이미지')}</div>
            <div className="flex">
              <label className="rounded-full w-[100px] h-[100px] max-md:w-[94px] max-md:h-[94px] cursor-pointer">
                <div
                  style={{
                    backgroundImage: `url(${
                      imageUrl
                        ? imageUrl
                        : myInfo.profileImagePath
                        ? initialPath
                        : ''
                    })`,
                  }}
                  className="w-full h-full rounded-full bg-cover bg-center relative"
                >
                  {!initialPath && !imageUrl && (
                    <i className="bg-cover w-full h-full">
                      <DefaultProfileIcon />
                    </i>
                  )}
                </div>
                <input
                  key={key}
                  id="profile-image"
                  type="file"
                  className="hidden"
                  accept=".png, .jpg"
                  onChange={(e) => onChangeFileHandler(e)}
                />
              </label>
              <div className="flex flex-col justify-center ml-5 space-y-3 max-md:space-y-2">
                <div className="flex space-x-3">
                  <label
                    className="border border-borderLightGray rounded-lg hover:bg-gray5 w-fit cursor-pointer py-2 px-3 max-md:text-sm max-md:p-[10px] max-md:min-w-fit"
                    htmlFor="profile-image"
                  >
                    {imageUrl || initialPath ? t('사진 변경') : t('사진 등록')}
                  </label>
                  {(imageUrl || initialPath) && (
                    <label
                      onClick={deleteProfileImage}
                      className="border border-borderLightGray rounded-lg hover:bg-gray5 w-fit cursor-pointer py-2 px-3 max-md:text-sm max-md:p-[10px] max-md:min-w-fit"
                    >
                      {t('사진 삭제')}
                    </label>
                  )}
                </div>
                <span className="text-textGray text-sm max-md:text-xs">
                  {t('5MB 미만 / 이미지 파일')}{' '}
                  <br className="hidden max-md:block" />
                  {t('(jpg, png)만 첨부 가능')}
                </span>
              </div>
            </div>
          </div>
        </section>
        <AgreementSection
          titleStyle={titleStyle}
          onChangeInfo={onChangeInfo}
          setOnChangeInfo={setOnChangeInfo}
        />
      </div>
    </>
  );
}

function FixedInfoBox({
  label,
  displayInfo,
}: {
  label: string;
  displayInfo: string;
}) {
  const { t } = useTranslation();
  return (
    <div className={divStyle}>
      <div className={titleStyle}>{t(label)}</div>
      <div className="max-md:text-base">{t(displayInfo)}</div>
    </div>
  );
}
