import AreaSaveIcon from '../components/vector/studio/AreaSaveIcon';
import ChatIcon from '../components/vector/studio/ChatIcon';
import CutIcon from '../components/vector/studio/CutIcon';
import DragDropIcon from '../components/vector/studio/DragDropIcon';
import EffectIcon from '../components/vector/studio/EffectIcon';
import FeeSaveIcon from '../components/vector/studio/FeeSaveIcon';
import MicIcon from '../components/vector/studio/MicIcon';
import MouseClickIcon from '../components/vector/studio/MouseClickIcon';
import RenderIcon from '../components/vector/studio/RenderIcon';
import ResolutionIcon from '../components/vector/studio/ResolutionIcon';
import TimeSaveIcon from '../components/vector/studio/TimeSaveIcon';

export const previewSectionArray = [
  {
    icon: <TimeSaveIcon />,
    keyPhrase: '1/6 시간절감',
    description: '영상 제작시간 및 비용 절감',
  },
  {
    icon: <FeeSaveIcon />,
    keyPhrase: '1/20 비용절감',
    description: '가상인간으로 섭외비 절감',
  },
  {
    icon: <AreaSaveIcon />,
    keyPhrase: '3.3㎡',
    description: '당신의 PC가 스튜디오',
  },
  {
    icon: <DragDropIcon />,
    keyPhrase: '드래그&드랍',
    description: '손쉽게 학습없이 사용',
  },
  {
    icon: <MouseClickIcon />,
    keyPhrase: '딸깍',
    description: '고가 장비없이 손가락 하나로',
  },
];

export const previewStepArray = [
  {
    gifUrl: '/studio/step1.gif',
    title: '새 프로젝트와 메타휴먼 생성',
    content: [
      '내 프로젝트의 제목과 설명 설정',
      '영상 목적에 꼭 맞는 내 메타휴먼 스타일, 자세, 보이스 선택',
      '독백형, 대화형 메타휴먼 설정 가능',
    ],
  },
  {
    gifUrl: '/studio/step2.gif',
    title: '스크립트 작성',
    content: [
      '메타휴먼이 발화할 스크립트 작성',
      '전세계 36개 이상 다국어 지원과 번역',
    ],
  },
  {
    gifUrl: '/studio/step3.gif',
    title: '영상 컨셉에 맞는 효과와 꾸미기',
    content: [
      '배경 패널에서 전체 배경을 설정하고 꾸미기',
      '패널에서 영상 분위기에 맞는 이미지와 효과음 추가',
    ],
  },
  {
    gifUrl: '/studio/step4.gif',
    title: '타임라인 편집과 인코딩',
    content: [
      '타임라인 패널을 통해 자르기, 삭제, 복제 등의 기능을 통한 영상 편집과 요소별 위치 및 길이조정',
      '미리보기를 통해 영상 확인 후 확장자와 화질 선택 후 인코딩',
    ],
  },
];

export const differenceCardArray = [
  {
    icon: <MicIcon />,
    keyPhrase: '1시간 교육',
    description: '스몰데이터 무제한 음성 학습/합성',
  },
  {
    icon: <ChatIcon />,
    keyPhrase: '36개 국어',
    description: '자동 번역과 AI 더빙',
  },
  {
    icon: <CutIcon />,
    keyPhrase: '전문가 수준',
    description: '영상 편집과 인터페이스',
  },
  {
    icon: <RenderIcon />,
    keyPhrase: '실시간 렌더링',
    description: '전용 앱 설치 없는 영상 생성, 송출 및 관리',
  },
  {
    icon: <ResolutionIcon />,
    keyPhrase: '8K 고화질',
    description: '디지털 휴먼 영상 합성',
  },
  {
    icon: <EffectIcon />,
    keyPhrase: '강력한 효과',
    description: '꾸미기, 배경, 음향 효과 콘텐츠',
  },
];
