export default function SelectedRadio() {
  return (
    <svg
      className="!w-[18px] !h-[18px]"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" rx="12" fill="#FF6D51" />
      <rect
        x="7.19922"
        y="7.2002"
        width="9.6"
        height="9.6"
        rx="4.8"
        fill="white"
      />
    </svg>
  );
}
