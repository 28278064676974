import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export default function ScrollToTop() {
  const { pathname } = useLocation();
  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo(0, 0);
    };
    const scrollTop = setTimeout(scrollToTop, 50);
    return () => {
      clearTimeout(scrollTop);
    };
  }, [pathname]);

  return null;
}
