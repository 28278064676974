import { useEffect, useRef } from 'react';
import { regCertRequest } from '../../../services/RegCertService';

function RegCertPopup() {
  const refToken = useRef(null);
  const refEnc = useRef(null);
  const refIntegrity = useRef(null);

  function apiGetEncReqData() {
    regCertRequest()
      .then((res) => {
        if (res.status === 200) {
          const resData = res.data.data;
          window.opener.callbackUpdateEncKey(resData.resultVal);
          const form = document.niceForm;
          refToken.current.value = resData.tokenVersionId;
          refEnc.current.value = resData.encData;
          refIntegrity.current.value = resData.integrityValue;
          form.submit();
        }
      })
      .catch(console.log);
  }

  useEffect(() => {
    apiGetEncReqData();
  }, []);

  return (
    <form
      id="niceForm"
      name="niceForm"
      action="https://nice.checkplus.co.kr/CheckPlusSafeModel/service.cb"
    >
      <input type="hidden" id="m" name="m" value="service" />
      <input
        type="hidden"
        id="token_version_id"
        ref={refToken}
        name="token_version_id"
        value=""
      />
      <input
        type="hidden"
        id="enc_data"
        ref={refEnc}
        name="enc_data"
        value=""
      />
      <input
        type="hidden"
        id="integrity_value"
        ref={refIntegrity}
        name="integrity_value"
        value=""
      />
    </form>
  );
}

export default RegCertPopup;
