import { debounce } from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import {
  noticeCategoryArray,
  serviceFilterArray,
} from '../../../array/SearchFilterArray';
import SearchBar from '../../../components/common/SearchBar';
import ServiceSelectBox from '../../../components/common/SearchFilterSelectBox';
import SubpageTitle from '../../../components/common/SubpageTitle';
import ImageBox from '../../../components/notice/ImageBox';
import MoreArrowDownIcon from '../../../components/vector/notice/MoreArrowDownIcon';
import { getNoticeRequest } from '../../../services/PublicService';
import {
  NoticeListClientDataType,
  NoticeListClientReqType,
} from '../../../services/TypeOfPublicService';
import { siteLangCd } from '../../../services/defaultClient';
import { userinfoAtom } from '../../../store';

const screenSize = window.innerWidth;

export default function Notice() {
  const [noticeCategoryCd, setNoticeCategoryCd] = useState('');
  const [noticeList, setNoticeList] = useState<NoticeListClientDataType[]>([]);
  const [searchFilter, setSearchFilter] = useState<any>({ serviceCd: '' });
  const [searchTxt, setSearchTxt] = useState('');
  const [perPage, setPerPage] = useState(screenSize > 769 ? 9 : 6);
  const [total, setTotal] = useState(0);
  const { t } = useTranslation();
  const { language } = useRecoilValue(userinfoAtom);

  useEffect(() => {
    var AOS = require('aos');
    AOS.init({
      duration: 2000,
    });
  }, []);

  useEffect(() => {
    const reqBody: NoticeListClientReqType = {
      siteLangCd,
      mainPageYn: 'N',
      perPage,
      pageNo: 1,
      serviceCd: searchFilter.serviceCd,
      noticeCategoryCd,
      searchTxt,
    };
    getNoticeRequest(reqBody)
      .then((res) => {
        const resData = res.data.data;
        setTotal(resData?.totalRows);
        setNoticeList(resData?.pageList);
      })
      .catch(console.log);
  }, [noticeCategoryCd, searchFilter, searchTxt, perPage]);

  const handleOnChange = debounce((e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTxt(e.target.value);
  }, 500);

  const isButtonNeeded = () => {
    const requiredPosts = screenSize > 769 ? 9 : 6;
    return total > requiredPosts ? true : false;
  };

  return (
    <main className="bg-black pt-[200px] max-md:pt-[98px] pb-60 max-md:pb-[100px] px-5">
      <div className="max-w-[1600px] max-2xl:max-w-[1200px] mx-auto">
        <section className="max-md:space-y-5">
          <SubpageTitle>Notice</SubpageTitle>
          <h1 className="mt-6 font-extralight text-white text-5xl max-md:text-[28px] leading-snug">
            {t('플루닛의 ')}
            <strong
              className={`font-bold ${
                language === 'kr' ? 'max-md:block' : 'md:block'
              }`}
            >
              {t('새로운 소식을 전해드려요.')}
            </strong>
          </h1>
        </section>
        <section className="mt-[60px] max-md:mt-10 flex items-center w-full justify-between max-md:flex-col max-md:space-y-2">
          <div className="space-x-3 max-md:space-x-0 max-md:grid max-md:grid-cols-4 max-md:w-full max-md:gap-x-2">
            {noticeCategoryArray.map(({ buttonText, value }, idx) => (
              <button
                onClick={() => setNoticeCategoryCd(value)}
                className={`px-7 py-3 max-2xl:px-5 max-md:px-0 max-md:py-3 rounded-lg text-xl max-2xl:text-lg max-md:text-sm border ${
                  noticeCategoryCd === value
                    ? 'text-primary border-primary font-bold'
                    : 'bg-transparent border-black4 text-white hover:bg-slate-100/25 duration-100 ease-out'
                }`}
                key={`category-${idx}`}
              >
                {t(buttonText)}
              </button>
            ))}
          </div>

          <div
            className={`flex ${
              language === 'kr'
                ? 'max-md:space-x-2'
                : 'max-md:flex-col max-md:space-x-0 max-md:space-y-2'
            } items-center space-x-3 max-md:flex max-md:justify-between max-md:w-full`}
          >
            <ServiceSelectBox
              setFilter={setSearchFilter}
              options={serviceFilterArray}
              isMain={true}
            />
            <SearchBar
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleOnChange(e)
              }
              isMain={true}
            />
          </div>
        </section>

        <section
          data-aos-delay="200"
          data-aos="fade-up"
          className="flex justify-center mt-[60px] max-md:mt-10"
        >
          {noticeList?.length ? (
            <div className="text-white w-full grid grid-cols-3 gap-x-10 gap-y-24 max-2xl:gap-x-6 max-2xl:gap-y-16 max-md:grid-cols-2 max-md:gap-x-4 max-md:gap-y-9">
              {noticeList.map((item, idx) => (
                <Link
                  className="w-full"
                  key={`notice-${idx}`}
                  to={item.noticeUuid}
                >
                  <button className="w-full text-left group space-y-7 max-md:space-y-5">
                    <ImageBox
                      category={item.noticeCategoryCdNm}
                      thumbnailPath={item.thumbnailPath}
                    />
                    <div>
                      <span className="text-primary font-bold max-md:text-xs">
                        {t(item.noticeCategoryCdNm)}
                        <strong className="font-normal mx-3 max-2xl:mx-2">
                          |
                        </strong>
                        {t(item.serviceCdNm)}
                      </span>
                      <h1 className="truncate text-2xl max-2xl:text-xl font-bold max-md:text-sm max-md:truncate mb-5 max-md:mb-2 mt-2">
                        {item.noticeTit}
                      </h1>
                      <h3 className="text-white text-opacity-[0.6] max-md:text-xs">
                        {moment(item.registYmd).format('YYYY. MM. DD')}
                      </h3>
                    </div>
                  </button>
                </Link>
              ))}
            </div>
          ) : (
            <span className="text-2xl max-md:text-lg text-black5 mt-32 max-2xl:mt-20 max-md:mt-16">
              {t('조회된 게시물이 없습니다.')}
            </span>
          )}
        </section>
        {isButtonNeeded() && (
          <div className="flex justify-center mt-[140px] max-md:mt-[60px]">
            <button
              onClick={() =>
                setPerPage((prev) => prev + (screenSize > 769 ? 9 : 6))
              }
              className="text-white px-7 max-md:px-6 py-4 max-md:py-3 border bg-black hover:bg-gray5/10 duration-100 ease-out border-white rounded-lg flex items-center space-x-4 group"
            >
              <span className="text-xl max-md:text-base">{t('더 보기')}</span>
              <i className="group-hover:translate-y-[2px] duration-100 ease-out">
                <MoreArrowDownIcon />
              </i>
            </button>
          </div>
        )}
      </div>
    </main>
  );
}
