import { SetStateAction, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { getEmailCheckRequest } from '../../services/PublicService';
import {
  EmailCheckReqDtoInf,
  EmailCheckResDtoInf,
} from '../../services/dto/SignupDtoInf';
import { SignUpInfoRegCertAtom, UserInfoType } from '../../store';
import useValidate, { emailRegex, passwordRegex } from '../hooks/useValidate';
import ClosedEyeIcon from '../vector/common/ClosedEyeIcon';
import EyeIcon from '../vector/common/EyeIcon';
import DeleteIcon from '../vector/inquiry/DeleteIcon';

const inputStyle =
  'w-full py-4 px-3 rounded-lg border border-borderLightGray focus:border-primary max-md:p-3 max-md:text-base';
const divStyle = 'flex flex-col space-y-2 max-2xl:text-base text-lg';
const requiredStyle = 'not-italic text-primary text-[8px]';

export default function UserInfo({
  registerInfo,
  setRegisterInfo,
}: {
  registerInfo: UserInfoType;
  setRegisterInfo: React.Dispatch<SetStateAction<UserInfoType>>;
}) {
  const [showPw, setShowPw] = useState(false);
  const [showConfirmPw, setShowConfirmPw] = useState(false);
  const [emailValidationPhrase, setEmailValidatePhrase] = useState('');
  const regCertResult = useRecoilValue(SignUpInfoRegCertAtom);

  const getNiceGenderString = (gen: string): string => {
    if (!gen) return '';
    if (gen === 'M') return '남성';
    else return '여성';
  };

  const doEmailCheck = () => {
    if (!emailRegex.test(registerInfo.userEmail)) {
      setEmailValidatePhrase(
        '이메일 주소가 올바르지 않습니다. 이메일 주소를 정확하게 입력해주세요.'
      );
      return;
    }
    const reqBody: EmailCheckReqDtoInf = {
      memberEmail: registerInfo.userEmail,
    };
    getEmailCheckRequest(reqBody)
      .then((res) => {
        const resData: EmailCheckResDtoInf = res.data;
        if (res.status === 200 && resData.statusCd == 'OK') {
          setEmailValidatePhrase('');
          if (resData.data.exist) {
            setEmailValidatePhrase('이미 사용중인 이메일입니다.');
          } else {
            setRegisterInfo((prev) => ({
              ...prev,
              emailCheck: true,
            }));
            setEmailValidatePhrase('사용이 가능한 이메일입니다.');
          }
        }
      })
      .catch((err) => {
        alert(err);
      });
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    e.key === 'Enter' && doEmailCheck();
  };

  return (
    <>
      <div className={divStyle}>
        <div className="font-medium flex items-center space-x-1">
          <span>아이디(이메일)</span>
          <i className={requiredStyle}>●</i>
        </div>
        <div className="flex space-x-3">
          <input
            autoComplete="off"
            tabIndex={1}
            maxLength={60}
            onKeyDown={(e) => handleKeyPress(e)}
            className={`${inputStyle} ${
              registerInfo.emailCheck
                ? 'border-success'
                : emailValidationPhrase
                ? 'border-error'
                : ''
            }`}
            onChange={(e) => {
              if (registerInfo.emailCheck) {
                setEmailValidatePhrase('');
                setRegisterInfo((prev) => ({
                  ...prev,
                  emailCheck: false,
                }));
              }
              setRegisterInfo((prev) => ({
                ...prev,
                userEmail: e.target.value,
              }));
            }}
            type="email"
            placeholder="이메일을 입력해주세요."
          />
          <button
            className={`rounded-lg border border-black ${
              registerInfo.emailCheck && 'bg-gray5'
            } hover:bg-gray5 min-w-[144px] max-md:min-w-fit max-md:px-4`}
            onClick={doEmailCheck}
          >
            중복확인
          </button>
        </div>
        <div className="flex relative">
          <span className="text-textGray max-2xl:text-xs text-sm">
            영문,숫자,일부기호(- / _ / . / @)만 입력 가능
          </span>
          {emailValidationPhrase && (
            <span
              className={`${
                registerInfo.emailCheck
                  ? 'text-success'
                  : emailValidationPhrase
                  ? 'text-error'
                  : ''
              } max-2xl:text-xs text-sm absolute -bottom-[22px]`}
            >
              {emailValidationPhrase}
            </span>
          )}
        </div>
      </div>
      <div className={divStyle}>
        <div className="font-medium flex items-center space-x-1">
          <span>비밀번호</span>
          <i className={requiredStyle}>●</i>
        </div>
        <div className="relative">
          <input
            autoComplete="off"
            tabIndex={2}
            className={`w-full py-4 px-3 rounded-lg border border-borderLightGray max-md:p-3 max-md:text-base ${
              passwordRegex.test(registerInfo.userPassword)
                ? 'border-success'
                : useValidate(registerInfo.userPassword)
                ? 'border-error'
                : ''
            }`}
            value={registerInfo.userPassword}
            maxLength={16}
            onChange={(e) =>
              setRegisterInfo((prev) => ({
                ...prev,
                userPassword: e.target.value,
              }))
            }
            type={showPw ? 'text' : 'password'}
            placeholder="비밀번호를 입력해주세요."
          />
          {registerInfo.userPassword && (
            <div className="flex items-center space-x-5 absolute right-4 bottom-1/2 translate-y-1/2">
              <button onClick={() => setShowPw(!showPw)}>
                {showPw ? <EyeIcon /> : <ClosedEyeIcon />}
              </button>
              <button
                onClick={() =>
                  setRegisterInfo((prev) => ({
                    ...prev,
                    userPassword: '',
                  }))
                }
              >
                <DeleteIcon />
              </button>
            </div>
          )}
        </div>
        <div className="flex relative">
          <span className="text-textGray max-2xl:text-xs text-sm">
            영문,숫자,특수문자(!,@,$,^,*,-)를 혼합하여 8~16자로 사용
          </span>
          {registerInfo.userPassword && (
            <span className="text-error max-2xl:text-xs text-sm absolute -bottom-[22px]">
              {useValidate(registerInfo.userPassword)}
            </span>
          )}
        </div>
      </div>
      <div className={divStyle}>
        <div className="font-medium flex items-center space-x-1">
          <span>비밀번호 확인</span>
          <i className={requiredStyle}>●</i>
        </div>
        <div className="relative">
          <input
            autoComplete="off"
            tabIndex={3}
            className={`w-full py-4 px-3 rounded-lg border border-borderLightGray max-md:p-3 max-md:text-base ${
              registerInfo.userPassword !== registerInfo.userPasswordConfirm &&
              registerInfo.userPasswordConfirm.length
                ? 'border-error'
                : registerInfo.userPassword ===
                    registerInfo.userPasswordConfirm &&
                  registerInfo.userPasswordConfirm.length
                ? 'border-success'
                : ''
            }`}
            onChange={(e) =>
              setRegisterInfo((prev) => ({
                ...prev,
                userPasswordConfirm: e.target.value,
              }))
            }
            maxLength={16}
            value={registerInfo.userPasswordConfirm}
            type={showConfirmPw ? 'text' : 'password'}
            placeholder="비밀번호를 한 번 더 입력해주세요."
          />
          {registerInfo.userPasswordConfirm && (
            <div className="flex items-center space-x-5 absolute right-4 bottom-1/2 translate-y-1/2">
              <button onClick={() => setShowConfirmPw(!showConfirmPw)}>
                {showConfirmPw ? <EyeIcon /> : <ClosedEyeIcon />}
              </button>
              <button
                onClick={() =>
                  setRegisterInfo((prev) => ({
                    ...prev,
                    userPasswordConfirm: '',
                  }))
                }
              >
                <DeleteIcon />
              </button>
            </div>
          )}
        </div>
      </div>
      {registerInfo.userPasswordConfirm && (
        <span
          className={`${
            registerInfo.userPassword === registerInfo.userPasswordConfirm
              ? 'text-success'
              : 'text-error'
          } max-2xl:text-xs text-sm`}
        >
          {!(registerInfo.userPassword === registerInfo.userPasswordConfirm)
            ? '비밀번호를 다시 확인해주세요.'
            : '비밀번호가 일치합니다.'}
        </span>
      )}
      <div className={divStyle}>
        <div className="font-medium flex items-center space-x-1">
          <span>이름</span>
          <i className={requiredStyle}>●</i>
        </div>
        <div className="flex space-x-3">
          <input
            className={`${inputStyle} !bg-[#F8F7F8] !text-[#999]`}
            type="text"
            value={regCertResult.staffName || ''}
            disabled
            placeholder="홍길동"
          />
        </div>
      </div>
      <div className={divStyle}>
        <div className="font-medium items-center flex space-x-1">
          <span>성별</span> <i className={requiredStyle}>●</i>
        </div>
        <div className="flex space-x-3">
          <input
            className={`${inputStyle} !bg-[#F8F7F8] !text-[#999]`}
            type="text"
            disabled
            value={getNiceGenderString(regCertResult.staffGender)}
            onChange={(e) =>
              setRegisterInfo((prev) => ({
                ...prev,
                userGender: e.target.value,
              }))
            }
            placeholder="남자"
          />
        </div>
      </div>
      <div className={divStyle}>
        <div className="font-medium flex items-center space-x-1">
          <span>휴대폰 번호</span> <i className={requiredStyle}>●</i>
        </div>
        <div className="flex space-x-3">
          <input
            maxLength={15}
            className={`${inputStyle} !bg-[#F8F7F8] !text-[#999]`}
            type="tel"
            value={regCertResult.staffMobile || ''}
            disabled
            placeholder="010-1111-2222"
          />
        </div>
      </div>
    </>
  );
}
