import { Dialog, Transition } from '@headlessui/react';
import { Fragment, SetStateAction, useEffect, useState } from 'react';
import { getModal } from '../../../services/PublicService';
import { fileBaseURL, siteLangCd } from '../../../services/defaultClient';

export default function MainModal({
  closeModal,
  modal,
  closeToday,
}: {
  closeModal: React.Dispatch<SetStateAction<boolean>>;
  modal: boolean;
  closeToday: () => void;
}) {
  const [modalInfo, setModalInfo] = useState({
    imagePath: null,
    linkPath: '',
    popupTit: '',
    popupUuid: '',
  });

  useEffect(() => {
    getModal({ siteLangCd })
      .then((res) => {
        const resData = res.data.data;
        if (!resData) {
          return;
        }
        setModalInfo(resData[0]);
      })
      .catch(console.log);
  }, []);

  return (
    <>
      {modalInfo.imagePath && (
        <Transition appear show={modal} as={Fragment}>
          <Dialog
            as="div"
            className="fixed z-[100]"
            onClose={() => {
              return;
            }}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black bg-opacity-70" />
            </Transition.Child>

            <div className="fixed inset-0 max-md:inset-0 max-md:bottom-0 rounded-xl overflow-y-hidden w-full px-10 md:px-4">
              <div className="flex min-h-full items-center justify-center text-center w-full">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-300"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Dialog.Panel className="w-full bg-black h-full md:max-w-[600px] md:max-h-[600px] rounded-t-xl transform text-black2 transition-all max-md:w-screen">
                    <section className="flex flex-col w-full h-full">
                      <div
                        onClick={() =>
                          modalInfo.linkPath && window.open(modalInfo.linkPath)
                        }
                        className="w-full h-[300px] md:w-[600px] md:h-[600px] bg-cover bg-center bg-no-repeat rounded-t-xl"
                        style={{
                          backgroundImage: `url(${
                            fileBaseURL + modalInfo.imagePath
                          })`,
                        }}
                      />
                      <div className="bg-white rounded-b-xl py-5 flex justify-between">
                        <button
                          onClick={closeToday}
                          className="w-full border-r border-gray3"
                        >
                          오늘 하루 보지 않기
                        </button>
                        <button
                          onClick={() => closeModal(false)}
                          className="w-full"
                        >
                          닫기
                        </button>
                      </div>
                    </section>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition>
      )}
    </>
  );
}
