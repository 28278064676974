export default function UsageMessageIcon() {
  return (
    <svg
      width="90"
      height="90"
      viewBox="0 0 90 90"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="45" cy="45" r="45" fill="white" />
      <path
        d="M32.863 64.6499L32.8282 64.6712L32.7973 64.6979C32.7721 64.7197 32.742 64.7341 32.7102 64.7403C32.6703 64.7362 32.632 64.7212 32.5995 64.6965C32.5801 64.6732 32.5662 64.6454 32.5592 64.615C32.5509 64.5792 32.5527 64.5419 32.5643 64.5072L32.583 64.4513L32.5882 64.3926L33.1095 58.4996C33.1637 57.8868 32.8384 57.3151 32.3121 57.0287C26.3605 53.7899 22.5 48.3874 22.5 42.3051C22.5 32.6426 32.4264 24.5 44.9836 24.5C57.526 24.5 67.5 32.6274 67.5 42.3051C67.5 51.9838 57.5577 60.1263 45.0173 60.1267C44.0855 60.1219 42.4982 59.9849 41.5861 59.8983C41.0416 59.8466 40.492 59.9701 40.0223 60.2582L32.863 64.6499ZM45.0183 59.4347H45.0209C57.3328 59.4328 66.808 51.4619 66.808 42.3051C66.808 33.1502 57.3016 25.192 44.9836 25.192C32.6529 25.192 23.192 33.1637 23.192 42.3051C23.192 48.2578 27.0958 53.6086 33.2115 56.7202L33.6249 56.9305C33.8076 57.0235 33.9151 57.2186 33.896 57.4227L33.4996 61.6608C33.431 62.3942 34.2245 62.8949 34.857 62.5174L40.2307 59.3099C40.4701 59.1671 40.751 59.1101 41.0271 59.1485C42.3497 59.3324 43.683 59.4279 45.0183 59.4347Z"
        fill="#FF6D51"
        stroke="#FF6D51"
      />
      <circle cx="35" cy="44" r="2" fill="#FF9C63" />
      <circle cx="45" cy="44" r="2" fill="#FF8170" />
      <circle cx="55" cy="44" r="2" fill="#FF5286" />
    </svg>
  );
}
