export const loginButtonArray: {
  text: '아이디(이메일) 찾기' | '비밀번호 찾기';
  link: '/help/find-id' | '/help/find-pw';
}[] = [
  {
    text: '아이디(이메일) 찾기',
    link: '/help/find-id',
  },
  {
    text: '비밀번호 찾기',
    link: '/help/find-pw',
  },
];
