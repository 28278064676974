import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import Loader from '../../components/common/Loader';
import SignupButton from '../../components/common/SignupButtons';
import InformModal from '../../components/common/modal/InformModal';
import LoginModal from '../../components/common/modal/LoginModal';
import DormantEnterprise from '../../components/help/dormant/DormantEnterprise';
import DormantForeigner from '../../components/help/dormant/DormantForeigner';
import i18n from '../../locale/i18n';
import { wakeDormantAccount } from '../../services/PublicService';
import { WakeUpDormantUsertReqDtoInf } from '../../services/TypeOfPublicService';
import { RegCertResultType, dormantUserInfo, userinfoAtom } from '../../store';

export interface EnterpriseInfoType {
  companyNm: string;
  memberBrno: string;
  establishYmd: string;
  ceoFnm: string;
}

export default function Dormant() {
  const [loginModal, setLoginModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const reqBodyRef = useRef<WakeUpDormantUsertReqDtoInf>({
    wakeupMeansVal: '',
    memberEmail: '',
    memberUuid: '',
    serviceCd: 'B15',
  });
  const [informModal, setInformModal] = useState({
    isOpen: false,
    phrase: '',
    secondPhrase: '',
  });
  const [method, setMethod] = useState<'businessInfo' | 'email'>(
    'businessInfo'
  );
  const [encKey, setEncKey] = useState('');
  const [authorizedUserData, setAuthorizedUserData] =
    useState<RegCertResultType>();
  const [enterpriseInfo, setEnterpriseInfo] = useState<EnterpriseInfoType>({
    companyNm: '',
    memberBrno: '',
    establishYmd: '',
    ceoFnm: '',
  });
  const [enterpriseEmail, setEnterpriseEmail] = useState('');
  const [emailCheckInfo, setEmailCheckInfo] = useState({
    authSendUuid: '',
    authCode: '',
  });
  const { t } = useTranslation();
  const { language } = useRecoilValue(userinfoAtom);
  const userInfo = useRecoilValue(dormantUserInfo);
  const { userType, memberEmail, memberUuid } = userInfo;
  const navigate = useNavigate();

  useEffect(() => {
    if (!userInfo.userType) {
      navigate('/');
      return;
    }
    i18n.changeLanguage(language);
  }, []);

  const checkEligibility = () => {
    if (
      userType === 'M03B0' &&
      method === 'businessInfo' &&
      (!enterpriseInfo.companyNm ||
        !enterpriseInfo.memberBrno ||
        !enterpriseInfo.establishYmd ||
        !enterpriseInfo.ceoFnm)
    ) {
      return true;
    } else {
      false;
    }
  };

  const requestWakeUpAccount = () => {
    setLoading(true);
    if (language === 'en') {
      wakeForeignerUser();
    } else if (userType === 'M03B0') {
      wakeEnterpriseUser();
    } else {
      window.open(
        '/popup/signup/regcert',
        'authCheck',
        'width=480, height=800'
      );
    }
  };

  (window as any).callbackUpdateEncKey = (data: any) => {
    setEncKey(data);
  };

  (window as any).callbackGetEncKey = () => {
    return encKey;
  };

  (window as any).callbackSetAuthResult = async (data: RegCertResultType) => {
    await setAuthorizedUserData(data);
  };

  useEffect(() => {
    if (!authorizedUserData) {
      return;
    }
    const mendatoryReqBody = {
      wakeupMeansVal: 'person',
      memberEmail,
      memberUuid,
      serviceCd: 'B15',
    };
    reqBodyRef.current = {
      ...mendatoryReqBody,
      ciVal: authorizedUserData.staffCi,
    } as WakeUpDormantUsertReqDtoInf;

    requestApiForWakeUp();
  }, [authorizedUserData?.staffName]);

  const wakeForeignerUser = () => {
    const mendatoryReqBody = {
      wakeupMeansVal: 'email',
      memberEmail,
      memberUuid,
      serviceCd: 'B15',
    };
    reqBodyRef.current = {
      ...mendatoryReqBody,
      ...emailCheckInfo,
    } as WakeUpDormantUsertReqDtoInf;

    requestApiForWakeUp();
  };
  const wakeEnterpriseUser = () => {
    const mendatoryReqBody = {
      wakeupMeansVal: method === 'email' ? 'email' : 'company',
      memberEmail,
      memberUuid,
      serviceCd: 'B15',
    };

    method === 'businessInfo'
      ? (reqBodyRef.current = {
          ...mendatoryReqBody,
          ...enterpriseInfo,
        } as WakeUpDormantUsertReqDtoInf)
      : (reqBodyRef.current = {
          ...mendatoryReqBody,
          ...emailCheckInfo,
        } as WakeUpDormantUsertReqDtoInf);

    requestApiForWakeUp();
  };

  const requestApiForWakeUp = () => {
    wakeDormantAccount(reqBodyRef.current)
      .then((res) => {
        res.data.statusCd === 'OK'
          ? openWakeUpSuccessModal()
          : openWakeUpFailModal();
      })
      .catch(() => openWakeUpFailModal())
      .finally(() => setLoading(false));
  };

  const openWakeUpSuccessModal = () => {
    setInformModal({
      isOpen: true,
      phrase: '휴면 상태가 해제되었습니다.',
      secondPhrase: '다시 만나 반갑습니다.',
    });
  };

  const openWakeUpFailModal = () => {
    setInformModal({
      isOpen: true,
      phrase: '입력하신 정보를 다시 한 번 확인해주세요.',
      secondPhrase: '',
    });
  };

  const closeInformModal = () => {
    if (informModal.phrase === '휴면 상태가 해제되었습니다.') {
      navigate('/');
    } else {
      setInformModal((prev) => ({
        ...prev,
        isOpen: false,
      }));
    }
  };

  return (
    <>
      <Loader loading={loading} />
      <InformModal
        modal={informModal.isOpen}
        closeModal={closeInformModal}
        phrase={informModal.phrase}
        secondPhrase={informModal.secondPhrase}
      />
      {/* "입력하신 정보를 다시 확인해주세요." */}
      <LoginModal modal={loginModal} closeModal={() => setLoginModal(false)} />
      <section className="pt-[200px] pb-60 max-w-[600px] mx-auto max-md:pt-[98px] max-md:px-5 max-md:pb-24">
        <div className="flex flex-col text-center space-y-[60px] max-md:space-y-10">
          <div className="space-y-10 max-md:space-y-7">
            <h1 className="text-[44px] max-md:text-[28px] font-bold">
              {t('휴면 계정 안내')}
            </h1>
            {userType === 'M03B0' ? (
              <p className="text-center text-black2 md:text-lg leading-8 max-md:leading-[22px]">
                {t(
                  '회원님은 플루닛 계정에 로그인한지 1년 이상 지나 휴면상태로 전환되었습니다. 사업자등록번호 또는 이메일 인증을 통해 휴면 해제해 주세요.'
                )}
              </p>
            ) : (
              <p className="text-center text-black2 md:text-lg leading-8 max-md:leading-[22px]">
                {t('회원님은 플루닛 계정에 로그인한 지')}{' '}
                <br className="hidden md:block" />
                {t('1년 이상 지나 휴면상태로 전환되었습니다.')}{' '}
                <br className="hidden md:block" />
                {t('휴대폰 본인인증을 통해 휴면 해제해 주세요.')}
              </p>
            )}
            {language === 'en' ? (
              <DormantForeigner
                emailCheckInfo={emailCheckInfo}
                setEmailCheckInfo={setEmailCheckInfo}
                requestWakeUpAccount={requestWakeUpAccount}
              />
            ) : userType === 'M03B0' ? (
              <DormantEnterprise
                enterpriseInfo={enterpriseInfo}
                setEnterpriseInfo={setEnterpriseInfo}
                method={method}
                setMethod={setMethod}
                enterpriseEmail={enterpriseEmail}
                setEnterpriseEmail={setEnterpriseEmail}
                emailCheckInfo={emailCheckInfo}
                setEmailCheckInfo={setEmailCheckInfo}
                requestWakeUpAccount={requestWakeUpAccount}
              />
            ) : null}
          </div>

          <div
            className={`flex w-full ${
              (method === 'email' || language === 'en') && 'hidden'
            }`}
          >
            <SignupButton
              disabled={checkEligibility()}
              onClick={requestWakeUpAccount}
            >
              {language === 'en'
                ? 'Confirm'
                : userType === 'M03A0'
                ? '휴대폰본인인증'
                : '확인'}
            </SignupButton>
          </div>
        </div>
      </section>
    </>
  );
}
