import BiFlow from "../home/bi-flow";

const QuickServeFirstSection = () => {
  const DESCRIPTIONS = [
    {
      title: "영상 제작, 시간도 돈도 많이 들겠지?",
      description:
        "지금껏 경험하지 못했던 쉽고 빠른 영상 제작, 저희가 준비했습니다. 국내 최고 AI 기술과 제작 노하우로 고퀼리티 영상을 누구보다도 빠르고 저렴하게 제작합니다. ",
    },
    {
      title: "가격은 절반, 속도는 두 배, 품질은 최고!",
      description:
        "영상 제작이 이렇게 간단했나요? 기업 홍보, 제품 소개, 교육 자료, 행사 영상까지.. 말만 하신다면 세상에서 하나뿐인 당신만의 영상을 만들어 드려요!",
    },
    {
      title: "뭐가 그렇게 다르냐고요?",
      description:
        "AI가 만든 정교한 음성과 이미지, 음악에 영상 전문가의 세심한 손길을 더하여\n속도와 퀄리티를 모두 잡았습니다. 복잡한 영상 제작, 플루닛 영상제작소와 함께하세요.",
    },
    {
      title: "영상이 급하게 필요하시죠?",
      description:
        "“이거 지금 당장 필요한데요?”라는 요청, 많이 들어보셨죠?\n플루닛 영상제작소가 당일 바로 받아보는 '영상 퀵서비스'의 경험을 실현해 드립니다.",
    },
  ];
  return (
    <div className="w-full bg-black flex flex-col items-center justify-center">
      <div className="flex flex-col items-center justify-center mx-auto pt-[50px] pb-10 md:pt-40 md:pb-[76px]">
        <div className="font-bold text-[24px] text-transparent bg-clip-text bg-gradient-to-r from-[#ff4e83] to-[#ffbb54] hidden md:flex">
          영상 제작소
        </div>
        <div className="flex flex-col items-center text-[24px] text-white/60 font-extralight gap-2">
          <div className="font-bold text-[30px] md:text-[50px] text-transparent bg-clip-text bg-gradient-to-r from-[#ff4e83] to-[#ffbb54]">
            '내일은 늦어요'
          </div>
          <div className="font-bold font-white text-[18px] md:text-[28px] whitespace-normal text-center break-keep">
            오늘 바로 당신만을 위한 맞춤 영상을 받아보세요.
          </div>
        </div>
        <div className="mt-8 md:mt-14">
          <img
            className="hidden md:flex"
            alt="video maker description"
            src="/quickserve/description.png"
            width={844}
          />
          <img
            className="flex md:hidden"
            alt="video maker description"
            src="/quickserve/description-mobile.png"
            width={337}
          />
        </div>
        <div className="mt-10 md:mt-[68px] flex flex-col gap-8 md:gap-16 items-center whitespace-normal px-2">
          {DESCRIPTIONS.map((description, index) => {
            return (
              <div
                key={"description_" + index}
                className="flex flex-col items-center gap-2"
              >
                <div className="flex flex-row gap-2 items-center justify-center">
                  <div className="w-2 h-2 rounded-full bg-gradient-to-br from-[#ff4e83] to-[#ffbb54]" />
                  <div className="text-[16px] md:text-[24px] text-white font-bold">
                    {description.title}
                  </div>
                </div>
                <div className="flex max-w-[750px] text-[14px] md:text-[18px] text-center text-white/60 font-extralight whitespace-pre-line">
                  {description.description}
                </div>
              </div>
            );
          })}
        </div>
        {/* <div className="mt-10 md:mt-[68px] text-[18px] md:text-[32px] text-white font-bold">
          AI VIDEO ON YOUR DEMAND
        </div>
        <div className="hidden md:flex mt-5 text-[16px] md:text-[20px] text-center text-white/60 font-extralight">
          가상인간 뿐 아니라 배경 이미지와 음악 생성 등 국내 최고의 생성 AI
          기술에{"\n"}영상 전문가의 터치를 더해 비용 및 시간은 절감하고 퀄리티는
          보장된 영상을 만듭니다.{"\n\n"}가격은 절반으로, 속도는 두배로, 품질은
          A++!{"\n"}기업 홍보, 제품 소개, 교육 및 행사, 정보 및 안내 영상 등
          {"\n"}당신이 필요한 '그 영상'을 플루닛 영상제작소에 맡겨주세요.
        </div>
        <div className="flex flex-wrap break-keep min-w-[337px] md:hidden mt-5 text-[16px] md:text-[20px] text-center text-white/60 font-extralight">
          가상인간 뿐 아니라 배경 이미지와 음악 생성 등{"\n"}국내 최고의 생성 AI
          기술에 영상 전문가의 터치를 더해{"\n"}비용 및 시간은 절감하고 퀄리티는
          보장된 영상을 만듭니다.{"\n\n"}가격은 절반으로, 속도는 두배로, 품질은
          A++!{"\n"}기업 홍보, 제품 소개, 교육 및 행사, 정보 및 안내 영상 등
          {"\n"}당신이 필요한 '그 영상'을{"\n"}플루닛 영상제작소에 맡겨주세요.
        </div> */}
      </div>
      <div className="w-[240px] md:w-[800px] h-[1px] bg-gradient-to-r from-transparent via-white to-transparent my-5" />
      <div className="flex flex-col items-center justify-center pt-[58px] text-white">
        <div>Our Partners</div>
        <BiFlow hasInquiry={false} />
      </div>
    </div>
  );
};

export default QuickServeFirstSection;
