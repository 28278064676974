import { Listbox, Transition } from '@headlessui/react';
import { Fragment, useEffect, useState } from 'react';
import { getFamilySites } from '../../services/PublicService';
import { siteLangCd } from '../../services/defaultClient';
import PlusIcon from '../vector/footer/PlusIcon';

interface FamilySiteListType {
  siteNm: string;
  linkPath: string;
}

export default function ListBox({ width }: { width: 'w-full' | 'w-40' }) {
  const [siteList, setSiteList] = useState<FamilySiteListType[]>([]);
  const [selected, setSelected] = useState(siteList[0]);
  useEffect(() => {
    getFamilySites({
      siteLangCd,
    })
      .then((res) => setSiteList(res.data.data))
      .catch(console.log);
  }, []);
  return (
    <div>
      <Listbox value={selected} onChange={setSelected}>
        <div className={`relative ${width}`}>
          <Listbox.Button className="relative w-full border-b border-[#444444] pb-2 px-0 ">
            <div className="truncate !text-[#888888] flex items-center justify-between">
              <span>Family Site</span>
              <i>
                <PlusIcon />
              </i>
            </div>
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className="absolute max-md:bottom-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-[#222222] py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {siteList.map(({ siteNm, linkPath }, idx) => (
                <Listbox.Option
                  onClick={() => window.open(linkPath)}
                  key={`list-${idx}`}
                  className="relative py-2 px-4 text-[#888888] cursor-pointer bg-[#222222] hover:bg-gray-500 hover:text-white"
                  value={siteNm}
                >
                  <span className="block truncate">{siteNm}</span>
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  );
}
