export default function YoutubeIcon() {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.7">
        <rect width="30" height="30" rx="15" fill="white" />
        <g clipPath="url(#clip0_167_6192)">
          <path
            d="M24.9242 10.6727C24.8103 10.2662 24.5883 9.89821 24.2819 9.6078C23.9669 9.30846 23.5808 9.09433 23.16 8.98563C21.5853 8.57056 15.2763 8.57056 15.2763 8.57056C12.6462 8.54064 10.0167 8.67225 7.40275 8.96466C6.98197 9.0814 6.59657 9.30031 6.28082 9.60193C5.97057 9.90044 5.74585 10.2685 5.62846 10.6719C5.34646 12.191 5.20945 13.7336 5.21927 15.2786C5.20921 16.8223 5.34588 18.3644 5.62846 19.8854C5.74334 20.2871 5.96722 20.6535 6.2783 20.9495C6.58939 21.2455 6.97678 21.4593 7.40275 21.5725C8.99843 21.9867 15.2763 21.9867 15.2763 21.9867C17.9098 22.0167 20.5427 21.8851 23.16 21.5926C23.5808 21.4839 23.9669 21.2698 24.2819 20.9704C24.5882 20.6801 24.8099 20.312 24.9234 19.9055C25.2128 18.387 25.3535 16.8438 25.3435 15.2979C25.3653 13.7455 25.2247 12.1951 24.9242 10.6719V10.6727ZM13.2706 18.1497V12.4084L18.5197 15.2795L13.2706 18.1497Z"
            fill="black"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_167_6192">
          <rect
            width="20.1242"
            height="20.1242"
            fill="white"
            transform="translate(5.21875 5.2168)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
