import { Listbox, Transition } from '@headlessui/react';
import { Fragment, useState } from 'react';
import GlobeIcon from '../vector/header/GlobeIcon';

import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import '../../locale/i18n';
import { userinfoAtom } from '../../store';

const serviceArray: { name: string; value: string }[] = [
  { name: '한국어', value: 'kr' },
  { name: 'English', value: 'en' },
];

export default function LanguageSelectBox({
  isDark,
  clickEvent,
}: {
  isDark: boolean;
  clickEvent?: () => void;
}) {
  const [selected, setSelected] = useState(serviceArray[0]);
  const [{ language }, setSelectLanguage] = useRecoilState(userinfoAtom);
  const [t, i18n] = useTranslation('lang', { useSuspense: false });

  const changeLanguage = (selectedLang: string) => {
    setSelectLanguage((prev: any) => ({
      ...prev,
      language: selectedLang,
    }));
    i18n.changeLanguage(selectedLang);
    window.location.reload();
  };

  return (
    <div onClick={clickEvent}>
      <Listbox value={selected} onChange={setSelected}>
        <div className="relative">
          <Listbox.Button className="relative z-[100] rounded-lg py-2 pl-3 text-left focus:outline-none cursor-pointer">
            <i>
              <GlobeIcon isDark={isDark} />
            </i>
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className="absolute z-50 -left-10 p-5 mt-1 max-h-60 overflow-auto rounded-xl bg-white text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {serviceArray.map((list, idx) => (
                <Listbox.Option
                  key={`list-${idx}`}
                  className={`relative text-black cursor-pointer bg-white hover:bg-opacity-10 hover:text-ploonetOrangeHover text-center rounded-lg ${
                    language === list.value
                      ? 'bg-ploonetOrange bg-opacity-10 text-ploonetOrange'
                      : ''
                  }`}
                  onClick={() => changeLanguage(list.value)}
                  value={list}
                >
                  <span
                    className={`block truncate py-2 px-5 ${
                      language === list.value
                        ? 'font-bold rounded-lg bg-primary bg-opacity-10'
                        : 'font-normal'
                    }`}
                  >
                    {list.name}
                  </span>
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  );
}
