import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import Loader from '../../components/common/Loader';
import MobileBackButton from '../../components/common/MobileBackButton';
import PageLeaveAlert from '../../components/common/PageLeaveAlert';
import PloonianPreviewSection from '../../components/signup/PloonianPreviewSection';
import PloonianSelectSection from '../../components/signup/PloonianSelectSection';
import { ENV } from '../../env';
import {
  getPloonianList,
  reqAgreementTerms,
  signUpCompanyRequest,
  signUpPersonalRequest,
} from '../../services/PublicService';
import { siteLangCd } from '../../services/defaultClient';
import {
  SignupCompanyReqDtoInf,
  SignupPersonalReqDtoInf,
} from '../../services/dto/SignupDtoInf';
import { pushToDataLayer } from '../../services/gtag';
import {
  PloonianInfoType,
  SignUpInfo1Atom,
  SignUpInfo1BusinessAppendAtom,
  SignUpInfo1BusinessAtom,
  SignUpInfo2Atom,
  SignUpInfo3Atom,
  SignUpInfoRegCertAtom,
  signupInfoForForeigner,
  userinfoAtom,
} from '../../store';

export default function CharacterSelect() {
  const signupInfo1 = useRecoilValue(SignUpInfo1Atom);
  const signUpInfo1Business = useRecoilValue(SignUpInfo1BusinessAtom);
  const signUpInfo1BusinessAppend = useRecoilValue(
    SignUpInfo1BusinessAppendAtom
  );
  const signUpInfoRegCert = useRecoilValue(SignUpInfoRegCertAtom);
  const signupInfo2 = useRecoilValue(SignUpInfo2Atom);
  const setSignupInfo3 = useSetRecoilState(SignUpInfo3Atom);
  const foreignRegisterInfo = useRecoilValue(signupInfoForForeigner);
  const [loading, setLoading] = useState(false);
  const [loadingImage, setLoadingImage] = useState(true);
  const [faceList, setFaceList] = useState([{}]);
  const [wearList, setWearList] = useState();
  const [voiceList, setVoiceList] = useState();
  const [termSnums, setTermSnums] = useState({
    M01E0: '',
    M01F0: '',
    M01G0: '',
  });
  const [ploonianInfo, setPloonianInfo] = useState<PloonianInfoType>({
    name: '앨리',
    faceCode: 'ff01',
    voice: '',
    voiceCode: '5',
    outfit: '',
    outfitCode: 'fc01',
    ploonianPath: '',
  });
  const navigate = useNavigate();
  const getPloonianImage = useCallback(
    (type: 'resultImage' | 'resultThumbnail') => {
      let imageUrl;
      type === 'resultImage'
        ? (imageUrl = `${ENV.REACT_APP_AWS_URL}/${ploonianInfo.ploonianPath}/image/result/${ploonianInfo.faceCode}_${ploonianInfo.outfitCode}.png`)
        : (imageUrl = `${ENV.REACT_APP_AWS_URL}/${ploonianInfo.ploonianPath}/image/result/thumbnail/${ploonianInfo.faceCode}_${ploonianInfo.outfitCode}.png`);
      return imageUrl;
    },
    [ploonianInfo.faceCode, ploonianInfo.outfitCode, ploonianInfo.ploonianPath]
  );
  const { t } = useTranslation();
  const { language } = useRecoilValue(userinfoAtom);

  useEffect(() => {
    const termsCdArr = ['M01E0', 'M01F0', 'M01G0'];
    termsCdArr.map((termsCd, idx) =>
      reqAgreementTerms({
        siteLangCd,
        termsCd: termsCd,
      })
        .then((res) => {
          const resData = res.data.data;
          idx === 0
            ? setTermSnums((prev) => ({
                ...prev,
                M01E0: resData.termsSnum,
              }))
            : idx === 1
            ? setTermSnums((prev) => ({
                ...prev,
                M01F0: resData.termsSnum,
              }))
            : setTermSnums((prev) => ({
                ...prev,
                M01G0: resData.termsSnum,
              }));
        })
        .catch(console.log)
    );
  }, []);

  useEffect(() => {
    getPloonianList({ siteLandCd: 'KR' })
      .then((res) => {
        const resData = res.data.data;
        setPloonianInfo((prev) => ({
          ...prev,
          ploonianPath: resData.path,
        }));
        setFaceList(
          resData.listItem.filter((list: any) => list.partType === 'face')
        );
        setWearList(
          resData.listItem.filter((list: any) => list.partType === 'wear')
        );
        setVoiceList(
          resData.listItem.filter((list: any) => list.partType === 'voice')
        );
      })
      .catch(alert)
      .finally(() => setLoadingImage(false));
  }, []);

  const requestSignUp = async () => {
    setLoading(true);
    setSignupInfo3(ploonianInfo);
    if (language === 'en') {
      const {
        memberEmail,
        memberPwd,
        memberFnm1,
        memberFnm2,
        mobileTelno,
        genderCd,
        memberAddr1,
        memberAddr2,
        memberCountryCd,
      } = foreignRegisterInfo;
      const reqJson: any = {
        memberTypeCd: 'M03A0',
        memberEmail,
        memberPwd,
        memberFnm1,
        memberFnm2,
        joinYmd: moment().format('YYYYMMDD'),
        joinServiceCd: 'B15',
        mobileTelno,
        siteLangCd: 'EN',
        authMeansCd: 'M02SM',
        genderCd,
        memberAddr1: memberAddr1 || '',
        memberAddr2: memberAddr2 || '',
        memberZipno: '',
        memberCountryCd,

        termsAgreeList: [
          {
            termsSnum: Number(termSnums.M01E0),
            termsCd: 'M01E0',
            termsAgreeYn: 'Y',
          },
          {
            termsSnum: Number(termSnums.M01F0),
            termsCd: 'M01F0',
            termsAgreeYn: 'Y',
          },
          {
            termsSnum: Number(termSnums.M01G0),
            termsCd: 'M01G0',
            termsAgreeYn: foreignRegisterInfo.agreement?.includes(
              'advertising information'
            )
              ? 'Y'
              : 'N',
            mailAgreeYn: foreignRegisterInfo.advertiseMethod.email ? 'Y' : 'N',
            smsAgreeYn: foreignRegisterInfo.advertiseMethod.sms ? 'Y' : 'N',
          },
        ],
        ploonian: {
          modelId: ploonianInfo.faceCode,
          modelNm: ploonianInfo.name,
          voiceId: ploonianInfo.voiceCode,
          voiceNm: ploonianInfo.voice,
          styleId: ploonianInfo.outfitCode,
          styleNm: ploonianInfo.outfit,
          poseId: '',
          poseNm: '',
          assignTelno: '',
        },
      };
      const reqBody = new FormData();
      reqBody.append('data', JSON.stringify(reqJson));
      if (foreignRegisterInfo.profileImage != null) {
        reqBody.append('profileImagePath', foreignRegisterInfo.profileImage);
      }

      signUpPersonalRequest(reqBody)
        .then((res) => {
          if (res.data.statusCd !== 'OK') {
            return;
          }
          pushToDataLayer('signup');
          navigate('/signup/complete');
        })
        .catch(alert)
        .finally(() => setLoading(false));
      return;
    }

    if (signupInfo1.userType === 'personal') {
      const reqJson: SignupPersonalReqDtoInf = {
        memberTypeCd: 'M03A0',
        memberEmail: signupInfo2.userEmail,
        memberPwd: signupInfo2.userPassword,
        memberFnm1: signUpInfoRegCert.staffName,
        memberFnm2: '',
        joinYmd: moment().format('YYYYMMDD'),
        joinServiceCd: 'B15',
        mobileTelno: signUpInfoRegCert.staffMobile,
        siteLangCd,
        authMeansCd: 'M02SM',
        ciVal: signUpInfoRegCert.staffCi,
        birthYmd: signUpInfoRegCert.staffBirth,
        genderCd: signUpInfoRegCert.staffGender,
        memberAddr1: signupInfo2.homeAddress || '',
        memberAddr2: signupInfo2.addressDetail || '',
        memberZipno: signupInfo2.zipCode || '',
        memberCountryCd: 'KOR',

        termsAgreeList: [
          {
            termsSnum: Number(termSnums.M01E0),
            termsCd: 'M01E0',
            termsAgreeYn: 'Y',
          },
          {
            termsSnum: Number(termSnums.M01F0),
            termsCd: 'M01F0',
            termsAgreeYn: 'Y',
          },
          {
            termsSnum: Number(termSnums.M01G0),
            termsCd: 'M01G0',
            termsAgreeYn: signupInfo1.agreement.includes(
              'advertising information'
            )
              ? 'Y'
              : 'N',
            mailAgreeYn: signupInfo1.advertiseMethod.email ? 'Y' : 'N',
            smsAgreeYn: signupInfo1.advertiseMethod.sms ? 'Y' : 'N',
          },
        ],
        ploonian: {
          modelId: ploonianInfo.faceCode,
          modelNm: ploonianInfo.name,
          voiceId: ploonianInfo.voiceCode,
          voiceNm: ploonianInfo.voice,
          styleId: ploonianInfo.outfitCode,
          styleNm: ploonianInfo.outfit,
          poseId: '',
          poseNm: '',
          assignTelno: '',
        },
      };
      const reqBody = new FormData();
      reqBody.append('data', JSON.stringify(reqJson));
      if (signupInfo2.profileImage != null) {
        reqBody.append('profileImagePath', signupInfo2.profileImage);
      }

      signUpPersonalRequest(reqBody)
        .then((res) => {
          if (res.data.statusCd !== 'OK') {
            return;
          }
          pushToDataLayer('signup');
          navigate('/signup/complete');
        })
        .catch(alert)
        .finally(() => setLoading(false));
    } else if (signupInfo1.userType === 'enterprise') {
      const reqJson: SignupCompanyReqDtoInf = {
        memberTypeCd: 'M03B0',
        memberEmail: signUpInfo1Business.email,
        memberPwd: signUpInfo1BusinessAppend.userPassword,
        memberFnm1: signUpInfo1Business.ownerName,
        memberFnm2: '',
        joinYmd: moment().format('YYYYMMDD'),
        joinServiceCd: 'B15',
        mobileTelno: '',
        siteLangCd,
        authMeansCd: 'M02SM',
        ciVal: '',
        birthYmd: '',
        genderCd: '',

        memberBrno: signUpInfo1Business.businessNumber,
        companyNm: signUpInfo1Business.companyName,
        ceoFnm: signUpInfo1Business.ownerName,
        establishYmd: signUpInfo1Business.createDate,
        companyMainTelno: signUpInfo1BusinessAppend.officeTel || '',
        homepageUrl: signUpInfo1BusinessAppend.companyUrl || '',
        companyAddr1: signUpInfo1BusinessAppend.companyAddress || '',
        companyAddr2: signUpInfo1BusinessAppend.companyAddressDetail || '',
        companyZipno: signUpInfo1BusinessAppend.zipCode || '',
        managerFnm: signUpInfo1BusinessAppend.managerName || '',
        managerEmail: signUpInfo1BusinessAppend.managerEmail || '',
        managerTelno: signUpInfo1BusinessAppend.officeTel || '',
        depatmentNm: signUpInfo1BusinessAppend.department || '',
        positionNm: signUpInfo1BusinessAppend.position || '',
        termsAgreeList: [
          {
            termsSnum: Number(termSnums.M01E0),
            termsCd: 'M01E0',
            termsAgreeYn: 'Y',
          },
          {
            termsSnum: Number(termSnums.M01F0),
            termsCd: 'M01F0',
            termsAgreeYn: 'Y',
          },
          {
            termsSnum: Number(termSnums.M01G0),
            termsCd: 'M01G0',
            termsAgreeYn: signupInfo1.agreement.includes(
              'advertising information'
            )
              ? 'Y'
              : 'N',
            mailAgreeYn: signupInfo1.advertiseMethod.email ? 'Y' : 'N',
            smsAgreeYn: signupInfo1.advertiseMethod.sms ? 'Y' : 'N',
          },
        ],
        ploonian: {
          modelId: ploonianInfo.faceCode,
          modelNm: ploonianInfo.name,
          voiceId: ploonianInfo.voiceCode,
          voiceNm: ploonianInfo.voice,
          styleId: ploonianInfo.outfitCode,
          styleNm: ploonianInfo.outfit,
          poseId: '',
          poseNm: '',
          assignTelno: '',
        },
      };
      const reqBody = new FormData();
      reqBody.append('data', JSON.stringify(reqJson));
      if (signUpInfo1BusinessAppend.companyLogo != null) {
        reqBody.append('companyPath', signUpInfo1BusinessAppend.companyLogo);
      }
      signUpCompanyRequest(reqBody)
        .then((res) => {
          if (res.data.statusCd !== 'OK') {
            return;
          }
          pushToDataLayer('signup');
          navigate('/signup/complete');
        })
        .catch(alert)
        .finally(() => setLoading(false));
    }
  };

  return (
    <>
      <PageLeaveAlert />
      <Loader loading={loading} />
      <section className="px-40 bg-[#F8F7F8] pt-[180px] pb-48 max-xl max-2xl:px-16 max-md:px-0 max-md:pt-[62px] max-md:pb-0">
        <div className="hidden max-md:flex px-5">
          <MobileBackButton />
        </div>
        <section className="text-xl space-y-5 max-md:space-y-2 max-md:px-5 max-w-[800px] mx-auto">
          <div className="mt-5 max-md:space-y-0 text-[32px] max-2xl:text-3xl max-md:text-2xl">
            <span>{t('이제 마지막 단계입니다.')}</span>
            <h1 className="font-bold mt-2">
              {t('회원님만을 위한 플루니안을 ')}
              <br className="hidden max-md:block" /> {t('설정해 주세요.')}
            </h1>
          </div>
          <span className="block text-xl max-2xl:text-lg max-md:text-base">
            {t(
              '플루니안은 사용하시는 서비스와 궁금한 모든 사항들을 가이드해주고 직원으로도 채용하실 수 있습니다.'
            )}
          </span>
        </section>

        <section className="flex flex-col justify-between mt-10 max-w-[800px] mx-auto">
          <PloonianPreviewSection
            loadingImage={loadingImage}
            requestSignUp={requestSignUp}
            getPloonianImage={getPloonianImage('resultImage')}
          />
          <PloonianSelectSection
            loadingImage={loadingImage}
            ploonianPath={ploonianInfo.ploonianPath}
            wearList={wearList}
            faceList={faceList}
            voiceList={voiceList}
            setPloonianInfo={setPloonianInfo}
            ploonianInfo={ploonianInfo}
          />
        </section>
      </section>
    </>
  );
}
