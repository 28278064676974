import { Dialog, Transition } from "@headlessui/react";
import { t } from "i18next";
import moment from "moment";
import { Fragment, SetStateAction, useEffect, useState } from "react";
import { getTelInquiryDetail } from "../../../services/PrivateService";
import AudioPlayer from "../../common/AudioPlayer";
import { usePloonianImage } from "../../hooks/usePloonian";
import CloseIcon from "../../vector/common/CloseIcon";

interface DetailDataType {
  diaSeq: number;
  fdReactionType: "" | null;
  formData: File | null;
  messages: string;
  msgTime: number[];
}

export default function TelInquiryDetailModal({
  closeModal,
  detailModal,
  wavPath,
  id,
}: {
  closeModal: React.Dispatch<SetStateAction<boolean>>;
  detailModal: boolean;
  wavPath: string;
  id: string;
}) {
  const [data, setData] = useState<DetailDataType[]>([]);
  const [loading, setLoading] = useState({
    detail: false,
    voice: false,
  });

  useEffect(() => {
    if (!id || !wavPath) {
      return;
    }
    setLoading({ detail: true, voice: true });
    getTelInquiryDetail({
      siteLangCd: "KR",
      id,
    })
      .then((res) =>
        setData(
          res.data.data.data.dialogues.filter(
            (list: DetailDataType) => list.messages != null
          )
        )
      )
      .catch(console.log)
      .finally(() => setLoading((prev) => ({ ...prev, detail: false })));
  }, [id, wavPath]);

  return (
    <Transition appear show={detailModal} as={Fragment}>
      <Dialog as="div" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25 z-50" />
        </Transition.Child>
        <div className="fixed inset-0 max-md:bottom-0 max-md:inset-auto max-md:w-full z-[60]">
          <div className="flex min-h-full items-center justify-center p-4 max-md:p-0 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95 max-md:scale-100 max-md:translate-y-96"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95 max-md:scale-100 max-md:translate-y-96"
            >
              <Dialog.Panel className="max-w-[496px] w-full max-md:w-screen h-full bg-black overflow-hidden transform rounded-2xl max-md:rounded-b-none transition-all">
                <section>
                  <h3 className="text-2xl max-2xl:text-xl py-6 font-bold leading-6 text-gray-900 text-center border-b border-borderGray w-full flex items-center justify-between px-7 bg-white">
                    {loading.detail && loading.voice ? (
                      <>
                        <div className="w-36 h-3 rounded-full bg-gray2 animate-pulse" />
                        <i
                          className="cursor-pointer"
                          onClick={() => closeModal(false)}
                        >
                          <CloseIcon isDark={false} />
                        </i>
                      </>
                    ) : (
                      <>
                        <span>
                          {t("전화문의상세")}
                          <strong className="max-md:text-lg font-bold">
                            (
                            {moment(data[0]?.msgTime).format(
                              "YYYY.MM.DD HH:mm"
                            )}
                            )
                          </strong>
                        </span>
                        <i
                          className="cursor-pointer"
                          onClick={() => closeModal(false)}
                        >
                          <CloseIcon isDark={false} />
                        </i>
                      </>
                    )}
                  </h3>

                  <section className="bg-[#F8F7F8] flex items-center justify-center py-2">
                    <AudioPlayer wavPath={wavPath} setLoading={setLoading} />
                  </section>
                </section>
                <div className="inquiry-detail px-7 max-md:px-5 pb-6 space-y-4 bg-white h-[584px] max-md:h-[490px] justify-between flex flex-col">
                  <DialogueSection
                    data={data}
                    loading={loading.detail && loading.voice}
                  />
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

function DialogueSection({
  data,
  loading,
}: {
  data: DetailDataType[];
  loading: boolean;
}) {
  const { ploonianThumbnail } = usePloonianImage();

  return (
    <section className="inquiry-detail relative overflow-y-scroll h-full text-black2 text-[13px]">
      <div className="w-full h-9 bg-gradient-to-b from-white/90 to-white/0 sticky top-0" />
      {loading ? (
        <DialogueSkeleton />
      ) : (
        <section className="space-y-7 max-md:space-y-4">
          {data.map((item, idx) => {
            const time = item.msgTime.slice(3);
            return (
              <section key={`item-${idx}`}>
                {item.fdReactionType != null ? (
                  <div className="flex items-end mr-auto">
                    <div className="flex space-x-2 items-start">
                      <img
                        className="w-9"
                        src={ploonianThumbnail}
                        alt="alice icon"
                      />
                      <div className="max-w-[320px] bg-[#FDE9DA] py-2 px-3 rounded-2xl rounded-bl-none">
                        <p className="text-start ">{item.messages}</p>
                      </div>
                    </div>
                    <span className=" max-md:text-xs text-black5 ml-2">
                      {moment(data[0]?.msgTime).format("HH:mm:ss")}
                    </span>
                  </div>
                ) : (
                  <div className="flex flex-row-reverse items-end mr-auto">
                    <div className="flex space-x-2 items-start">
                      <div className="max-w-[320px] bg-borderGray py-2 px-3 rounded-2xl rounded-br-none">
                        <p className="text-right ">{item.messages}</p>
                      </div>
                    </div>
                    <span className=" max-md:text-xs text-black5 mr-2">
                      {moment(data[0]?.msgTime).format("HH:mm:ss")}
                    </span>
                  </div>
                )}
              </section>
            );
          })}
        </section>
      )}
      <div className="w-full h-9 bg-gradient-to-t from-white/90 to-white/0 sticky -bottom-1" />
    </section>
  );
}

function DialogueSkeleton() {
  return (
    <section className="space-y-7 max-md:space-y-4">
      <div className="flex items-end mr-auto">
        <svg
          className="w-9 text-gray4 animate-pulse mr-1"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          viewBox="0 0 20 20"
        >
          <path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z" />
        </svg>
        <div className="w-[320px] h-20 bg-[#FDE9DA] py-2 px-3 rounded-2xl rounded-bl-none animate-pulse" />
        <div className="bg-gray4 ml-2 animate-pulse w-10 h-3 rounded-full" />
      </div>
      <div className="flex flex-row-reverse items-end mr-auto">
        <div className="w-[240px] h-10 bg-borderGray py-2 px-3 rounded-2xl rounded-br-none animate-pulse" />
        <div className="bg-gray4 mr-2 animate-pulse w-10 h-3 rounded-full" />
      </div>
      <div className="flex items-end mr-auto">
        <svg
          className="w-9 text-gray4 animate-pulse mr-1"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          viewBox="0 0 20 20"
        >
          <path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z" />
        </svg>
        <div className="w-[280px] h-10 bg-[#FDE9DA] py-2 px-3 rounded-2xl rounded-bl-none animate-pulse" />
        <div className="bg-gray4 ml-2 animate-pulse w-10 h-3 rounded-full" />
      </div>
    </section>
  );
}
