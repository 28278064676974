import { atom } from 'recoil';
import {
  EnterpriseInfoType,
  PersonalInfoType,
} from '../types/quick/quick-type';

/**
 * 회원가입 step01의 개인으로 회원가입 정보
 */
export interface RegisterInfoType {
  userType?: 'personal' | 'enterprise';
  agreement: string[];
  advertiseMethod: {
    sms: boolean;
    email: boolean;
  };
}

/**
 * 기업 회원가입 step01의 회사로 회원가입 정보
 */
export interface RegisterBusinessInfoType {
  companyName: string;
  ownerName: string;
  createDate: string;
  businessNumber: string;
  email: string;
}

/**
 * 기업 회원가입 step02의 추가적인 회원가입 정보
 */
export interface RegisterBusinessAppendInfoType {
  userPassword: string;
  userPasswordConfirm: string;
  companyLogo?: File | null | undefined;
  companyAddress?: string;
  companyAddressDetail?: string;
  zipCode?: string;
  companyTel?: string;
  companyUrl?: string;
  managerName?: string;
  managerEmail?: string;
  department?: string;
  position?: string;
  officeTel?: string;
}

// signup info type for foreigner
export interface RegisterForeignerInfoType {
  phoneNumberCd: string;
  memberFnm1: string;
  memberFnm2: string;
  memberEmail: string;
  memberPwd: string;
  userPasswordConfirm?: string;
  firstName: string;
  lastName: string;
  genderCd: 'M' | 'F';
  mobileTelno: string;
  memberCountryCd: string;
  memberAddr1?: string;
  memberAddr2?: string;
  profileImage?: File | null | undefined;
  agreement: string[];
  advertiseMethod: {
    sms: boolean;
    email: boolean;
  };
}

/**
 * 회원가입 step01의 휴대폰 본인인증 시 정보 저장
 */
export interface RegCertResultType {
  staffBirth: string;
  staffDi: string;
  staffCi: string;
  staffMobile: string;
  staffName: string;
  staffNational: string;
  staffGender: 'M' | 'F';
  staffMobileType: string;
}

/**
 * 회원가입 step02의 회원의 각종 정보 저장
 */
export interface UserInfoType {
  userEmail: string;
  emailCheck: boolean;
  userPassword: string;
  userPasswordConfirm: string;
  userPhoneNumber: string;
  userGender: string;
  profileImage: any;
  homeAddress?: string;
  addressDetail?: string;
  zipCode?: string;
}

/**
 * 회원가입 step03의 플루니언 기록
 */
export interface PloonianInfoType {
  name: string;
  faceCode: string;
  voice: string;
  voiceCode: string;
  outfit: string;
  outfitCode: string;
  ploonianPath: string;
}

const SignUpInfo1Atom = atom<RegisterInfoType>({
  key: 'siginup_info1',
  default: {} as RegisterInfoType,
});
const SignUpInfo1BusinessAtom = atom<RegisterBusinessInfoType>({
  key: 'siginup_info1_business',
  default: {} as RegisterBusinessInfoType,
});
const signupInfoForForeigner = atom({
  key: 'foreigner_signup_info',
  default: {} as RegisterForeignerInfoType,
});
const SignUpInfo1BusinessAppendAtom = atom<RegisterBusinessAppendInfoType>({
  key: 'siginup_info1_business_append',
  default: {} as RegisterBusinessAppendInfoType,
});
const SignUpInfoRegCertAtom = atom<RegCertResultType>({
  key: 'siginup_info1_regcert',
  default: {} as RegCertResultType,
});

const SignUpInfo2Atom = atom<UserInfoType>({
  key: 'siginup_info2',
  default: {} as UserInfoType,
});

const SignUpInfo3Atom = atom<PloonianInfoType>({
  key: 'siginup_info3',
  default: {} as PloonianInfoType,
});

const quickRegisterPersonalInfo = atom({
  key: 'quick-register-personal-info',
  default: {} as PersonalInfoType,
});

const quickRegisterEnterpriseInfo = atom({
  key: 'quick-register-enterprise-info',
  default: {} as EnterpriseInfoType,
});

const assignedTelno = atom({
  key: 'assigned-tel-no',
  default: '',
});

const exRegisterInfo = atom({
  key: 'ex-register-info',
  default: '',
});

export {
  SignUpInfo1Atom,
  SignUpInfo1BusinessAppendAtom,
  SignUpInfo1BusinessAtom,
  SignUpInfo2Atom,
  SignUpInfo3Atom,
  SignUpInfoRegCertAtom,
  assignedTelno,
  exRegisterInfo,
  quickRegisterEnterpriseInfo,
  quickRegisterPersonalInfo,
  signupInfoForForeigner,
};
