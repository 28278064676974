import { SetStateAction, useState } from 'react';
import { useRecoilValue } from 'recoil';
import {
  RegisterBusinessAppendInfoType,
  SignUpInfo1BusinessAtom,
} from '../../store';
import useValidate, { passwordRegex } from '../hooks/useValidate';
import ClosedEyeIcon from '../vector/common/ClosedEyeIcon';
import EyeIcon from '../vector/common/EyeIcon';
import DeleteIcon from '../vector/inquiry/DeleteIcon';

const inputStyle =
  'w-full py-4 px-3 rounded-lg border border-borderLightGray focus:ring-transparent max-md:p-3 focus:border-primary';
const divStyle = 'flex flex-col space-y-2 max-2xl:text-base text-lg';
const requiredStyle = 'not-italic text-primary text-[8px]';

export default function EnterpriseInfo({
  setRegisterInfo,
  registerInfo,
}: {
  setRegisterInfo: React.Dispatch<
    SetStateAction<RegisterBusinessAppendInfoType>
  >;
  registerInfo: RegisterBusinessAppendInfoType;
}) {
  const [showPw, setShowPw] = useState(false);
  const [showConfirmPw, setShowConfirmPw] = useState(false);
  const signUpInfo1Business = useRecoilValue(SignUpInfo1BusinessAtom);

  return (
    <>
      <div className={divStyle}>
        <div className="font-medium flex items-center space-x-2">
          <span>회사명</span>
          <i className={requiredStyle}>●</i>
        </div>
        <div className="flex space-x-3">
          <input
            className={inputStyle}
            disabled
            type="text"
            value={signUpInfo1Business.companyName}
            placeholder="플루닛"
          />
        </div>
      </div>
      <div className={divStyle}>
        <div className="font-medium flex items-center space-x-2">
          <span>사업자등록번호</span>
          <i className={requiredStyle}>●</i>
        </div>
        <div className="flex space-x-3">
          <input
            className={inputStyle}
            disabled
            type="text"
            value={signUpInfo1Business.businessNumber}
            placeholder="12345674928"
          />
        </div>
      </div>
      <div className={divStyle}>
        <div className="font-medium flex items-center space-x-2">
          <span>아이디(이메일)</span>
          <i className={requiredStyle}>●</i>
        </div>
        <div className="flex space-x-3">
          <input
            className={inputStyle}
            disabled
            type="email"
            value={signUpInfo1Business.email}
            placeholder="email@google.com"
          />
        </div>
      </div>
      <div className={divStyle}>
        <div className="font-medium flex items-center space-x-2">
          <span>개업일자</span>
          <i className={requiredStyle}>●</i>
        </div>
        <div className="flex space-x-3">
          <input
            className={inputStyle}
            disabled
            type="text"
            value={signUpInfo1Business.createDate}
            placeholder="20030810"
          />
        </div>
      </div>
      <div className={divStyle}>
        <div className="font-medium flex items-center space-x-2">
          <span>대표자명</span>
          <i className={requiredStyle}>●</i>
        </div>
        <div className="flex space-x-3">
          <input
            className={inputStyle}
            disabled
            type="text"
            value={signUpInfo1Business.ownerName}
            placeholder="홍길동"
          />
        </div>
      </div>
      <div className={divStyle}>
        <div className="font-medium flex items-center space-x-2">
          <span>비밀번호</span>
          <i className={requiredStyle}>●</i>
        </div>
        <div className="relative">
          <input
            autoComplete="off"
            tabIndex={1}
            className={`w-full py-4 px-3 rounded-lg border border-borderLightGray focus:ring-transparent max-md:p-3 ${
              passwordRegex.test(registerInfo.userPassword)
                ? 'border-success'
                : useValidate(registerInfo.userPassword)
                ? 'border-red-400'
                : ''
            }`}
            value={registerInfo.userPassword}
            maxLength={16}
            onChange={(e) =>
              setRegisterInfo((prev) => ({
                ...prev,
                userPassword: e.target.value,
              }))
            }
            type={showPw ? 'text' : 'password'}
            placeholder="비밀번호를 입력해주세요."
          />
          {registerInfo.userPassword && (
            <div className="flex items-center space-x-5 absolute right-4 bottom-1/2 translate-y-1/2">
              <button onClick={() => setShowPw(!showPw)}>
                {showPw ? <EyeIcon /> : <ClosedEyeIcon />}
              </button>
              <button
                onClick={() =>
                  setRegisterInfo((prev) => ({
                    ...prev,
                    userPassword: '',
                  }))
                }
              >
                <DeleteIcon />
              </button>
            </div>
          )}
        </div>
        <div className="flex relative">
          <span className="text-textGray max-2xl:text-xs text-sm">
            영문, 숫자, 특수문자(!, @, $, ^, *, -, _)를 혼합하여 8~16자로 사용
          </span>
          {registerInfo.userPassword && (
            <span className="text-error max-2xl:text-xs text-sm absolute -bottom-[22px]">
              {useValidate(registerInfo.userPassword)}
            </span>
          )}
        </div>
      </div>
      <div className={divStyle}>
        <div className="font-medium flex items-center space-x-2">
          <span>비밀번호 확인</span>
          <i className={requiredStyle}>●</i>
        </div>
        <div className="relative">
          <input
            autoComplete="off"
            tabIndex={2}
            className={`w-full py-4 px-3 rounded-lg border border-borderLightGray focus:ring-transparent max-md:p-3 ${
              registerInfo.userPassword !== registerInfo.userPasswordConfirm &&
              registerInfo.userPasswordConfirm.length
                ? 'border-error'
                : registerInfo.userPassword ===
                    registerInfo.userPasswordConfirm &&
                  registerInfo.userPasswordConfirm.length
                ? 'border-success'
                : ''
            }`}
            value={registerInfo.userPasswordConfirm}
            maxLength={16}
            type={showConfirmPw ? 'text' : 'password'}
            onChange={(e) =>
              setRegisterInfo((prev) => ({
                ...prev,
                userPasswordConfirm: e.target.value,
              }))
            }
            placeholder="비밀번호를 한 번 더 입력해주세요."
          />
          {registerInfo.userPasswordConfirm && (
            <div className="flex items-center space-x-5 absolute right-4 bottom-1/2 translate-y-1/2">
              <button onClick={() => setShowConfirmPw(!showConfirmPw)}>
                {showPw ? <EyeIcon /> : <ClosedEyeIcon />}
              </button>
              <button
                onClick={() =>
                  setRegisterInfo((prev) => ({
                    ...prev,
                    userPasswordConfirm: '',
                  }))
                }
              >
                <DeleteIcon />
              </button>
            </div>
          )}
        </div>
        {registerInfo.userPasswordConfirm && (
          <span
            className={`${
              registerInfo.userPassword === registerInfo.userPasswordConfirm
                ? 'text-green-500'
                : 'text-rose-400'
            } max-2xl:text-xs text-sm`}
          >
            {!(registerInfo.userPassword === registerInfo.userPasswordConfirm)
              ? '비밀번호를 다시 확인해주세요.'
              : '비밀번호가 일치합니다.'}
          </span>
        )}
      </div>
    </>
  );
}
