import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ConfirmModal from './modal/ConfirmModal';

export default function PageLeaveAlert() {
  const navigate = useNavigate();
  const [modal, setModal] = useState(false);
  const preventGoBack = () => {
    history.pushState(null, '', location.href);
    setModal(true);
  };
  const alertUser = (e: any) => {
    e.preventDefault();
    e.returnValue = '';
  };
  useEffect(() => {
    history.pushState(null, '', location.href);
    window.addEventListener('popstate', preventGoBack);
    window.addEventListener('beforeunload', alertUser);

    return () => {
      window.removeEventListener('popstate', preventGoBack);
      window.removeEventListener('beforeunload', alertUser);
    };
  }, []);

  return (
    <>
      <ConfirmModal
        isOpen={modal}
        firstLine="페이지를 벗어나면"
        secondLine="가입 초기단계로 이동합니다."
        onClick={() => navigate('/signup')}
        closeModal={() => setModal(false)}
      />
    </>
  );
}
