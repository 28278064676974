import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon } from '@heroicons/react/20/solid';
import { t } from 'i18next';
import { Fragment, SetStateAction, useState } from 'react';
import { InquiryTypes } from '../../views/mypage/inquiry/req';
import ChevronDownIcon from '../vector/payment-details/ChevronDownIcon';

const serviceArray: { name: string; value: string }[] = [
  { name: '플루닛', value: 'B15' },
  { name: '워크센터', value: 'B11' },
  { name: '스튜디오', value: 'B13' },
  { name: '메타휴먼', value: 'B12' },
  { name: '손비서앱', value: 'B14' },
];

export default function ServiceSelectBox({
  setRequestInfo,
}: {
  setRequestInfo: React.Dispatch<SetStateAction<InquiryTypes>>;
}) {
  const [selected, setSelected] = useState(serviceArray[0]);

  return (
    <div className="border border-[#cccccc] rounded-md w-80 max-md:w-full">
      <Listbox value={selected} onChange={setSelected}>
        <div className="relative mt-1">
          <Listbox.Button className="relative w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left focus:outline-none max-sm:text-sm">
            <span className="block truncate">{t(selected.name)}</span>
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-4">
              <ChevronDownIcon />
            </span>
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none max-sm:text-sm">
              {serviceArray.map((list, idx) => (
                <Listbox.Option
                  key={`list-${idx}`}
                  onClick={() => {
                    setRequestInfo((prev) => ({
                      ...prev,
                      serviceCd: list.value,
                    }));
                  }}
                  className="relative py-2 px-4 text-black cursor-pointer bg-white hover:bg-[#ffebe5] hover:text-[#FF7B51] pl-10"
                  value={list}
                >
                  {({ selected }) => (
                    <>
                      <span
                        className={`block truncate ${
                          selected
                            ? 'font-medium text-ploonetOrange'
                            : 'font-normal'
                        }`}
                      >
                        {t(list.name)}
                      </span>
                      {selected ? (
                        <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-[#FF7B51]">
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      ) : null}
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  );
}
