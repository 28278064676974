import { Disclosure } from '@headlessui/react';
import { ChevronUpIcon } from '@heroicons/react/20/solid';
import { SetStateAction, useEffect, useState } from 'react';
import { RegisterForeignerInfoType } from '../../store';
import DefaultProfileIcon from '../my-page/my-info/DefaultProfileIcon';

export default function ForeignerOptionalInfoTab({
  registerInfo,
  setRegisterInfo,
  setModalInfo,
}: {
  registerInfo: RegisterForeignerInfoType;
  setRegisterInfo: React.Dispatch<SetStateAction<RegisterForeignerInfoType>>;
  setModalInfo: React.Dispatch<
    SetStateAction<{
      isOpen: boolean;
      phrase: string;
      secondPhrase: string;
    }>
  >;
}) {
  const [key, setKey] = useState(0);
  const [imageUrl, setImageUrl] = useState<any>(null);
  const [address1, setAddress1] = useState({
    streetAdd: '',
    building: '',
  });
  const [address2, setAddress2] = useState({
    city: '',
    region: '',
  });

  useEffect(() => {
    const memberAddr1 = `${address1.streetAdd}br/${address1.building}`;
    const memberAddr2 = `${address2.city}br/${address2.region}`;

    setRegisterInfo((prev) => ({
      ...prev,
      memberAddr1,
      memberAddr2,
    }));
  }, [address1, address2]);

  useEffect(() => {
    registerInfo.profileImage
      ? setImageUrl(URL.createObjectURL(registerInfo.profileImage))
      : setImageUrl(null);
  }, [registerInfo.profileImage]);

  const onChangeFileHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    } else if (e.target.files[0].size > 5e6) {
      setModalInfo({
        isOpen: true,
        phrase: '파일 제한 용량을 초과했습니다.',
        secondPhrase: '',
      });
      return;
    }
    setRegisterInfo((prev: any) => ({
      ...prev,
      profileImage: e.target.files ? e.target.files[0] : null,
    }));
  };

  const deleteProfileImage = () => {
    setRegisterInfo((prev) => ({
      ...prev,
      profileImage: null,
    }));
    setKey((prev) => prev + 1);
  };

  return (
    <>
      <div className="w-full bg-white duration-200 ease-out">
        <Disclosure defaultOpen>
          {({ open }) => (
            <>
              <Disclosure.Button className="flex items-center w-full pb-3 mb-8 border-b border-black2 justify-between text-sm font-medium text-black duration-100 ease-out border-0">
                <section className="items-center flex !justify-between w-full max-md:w-full">
                  <span className="text-xl font-bold">
                    Enter additional information (optional)
                  </span>
                  <ChevronUpIcon
                    className={`${
                      open ? '' : 'rotate-180 transform'
                    } h-5 w-5 duration-300 ease-out`}
                  />
                </section>
              </Disclosure.Button>
              <Disclosure.Panel className="text-sm text-gray-500 rounded-lg space-y-4">
                <section>
                  <h1 className="text-lg font-semibold text-black2 mb-3">
                    Address
                  </h1>
                  <div className="flex flex-col w-full space-y-3">
                    <input
                      tabIndex={8}
                      spellCheck={false}
                      className="border border-borderLightGray focus:border-primary py-3 px-4 rounded-md min-w-full text-lg max-md:text-base text-black2"
                      type="text"
                      maxLength={500}
                      onChange={(e) =>
                        setAddress1((prev) => ({
                          ...prev,
                          streetAdd: e.target.value,
                        }))
                      }
                      placeholder="Street Address, P.O. box, Company name, c/o"
                    />
                    <input
                      tabIndex={9}
                      spellCheck={false}
                      className="border border-borderLightGray focus:border-primary py-3 px-4 rounded-md min-w-full text-lg max-md:text-base text-black2"
                      type="text"
                      maxLength={500}
                      onChange={(e) =>
                        setAddress1((prev) => ({
                          ...prev,
                          building: e.target.value,
                        }))
                      }
                      placeholder="Apartment, Suite, Unit, Building, Floor, etc."
                    />
                    <input
                      tabIndex={9}
                      spellCheck={false}
                      className="border border-borderLightGray focus:border-primary py-3 px-4 rounded-md min-w-full text-lg max-md:text-base text-black2"
                      type="text"
                      maxLength={500}
                      onChange={(e) =>
                        setAddress2((prev) => ({
                          ...prev,
                          city: e.target.value,
                        }))
                      }
                      placeholder="City"
                    />
                    <input
                      tabIndex={9}
                      spellCheck={false}
                      className="border border-borderLightGray focus:border-primary py-3 px-4 rounded-md min-w-full text-lg max-md:text-base text-black2"
                      type="text"
                      maxLength={500}
                      onChange={(e) =>
                        setAddress2((prev) => ({
                          ...prev,
                          region: e.target.value,
                        }))
                      }
                      placeholder="State / Province / Region"
                    />
                  </div>
                </section>
                <section className="mt-8">
                  <h1 className="text-lg font-semibold text-black2 mb-3">
                    Profile Image
                  </h1>
                  <div className="flex items-center space-x-5 max-md:space-x-3">
                    <label className="rounded-full w-24 h-24 cursor-pointer">
                      <div
                        style={{
                          backgroundImage: `url(${imageUrl ? imageUrl : ''})`,
                        }}
                        className="w-full h-full rounded-full bg-cover bg-center relative"
                      >
                        {!imageUrl && (
                          <i className="bg-cover w-full h-full">
                            <DefaultProfileIcon />
                          </i>
                        )}
                      </div>
                      <input
                        id="profile-image"
                        key={key}
                        type="file"
                        className="hidden"
                        accept=".png, .jpg"
                        onChange={(e) => onChangeFileHandler(e)}
                      />
                    </label>
                    <div className="flex flex-col justify-between ml-5 space-y-4 max-md:space-y-2">
                      <div className="flex space-x-3">
                        <label
                          className="border text-black2 border-borderLightGray rounded-lg hover:bg-gray5 w-fit cursor-pointer px-3 py-2 max-md:text-sm max-md:p-[10px]"
                          htmlFor="profile-image"
                        >
                          {imageUrl ? 'Change Photo' : 'Register Photo'}
                        </label>
                        {imageUrl && (
                          <label
                            onClick={deleteProfileImage}
                            className="border text-black2 border-borderLightGray flex items-center rounded-lg hover:bg-gray5 w-fit cursor-pointer py-2 px-3 max-md:text-sm max-md:p-[10px]"
                          >
                            Delete Photo
                          </label>
                        )}
                      </div>
                      <span className="text-textGray text-sm max-md:text-xs">
                        Less than 5MB / <br className="hidden max-md:block" />
                        Only image files (jpg, png) can be attached
                      </span>
                    </div>
                  </div>
                </section>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      </div>
    </>
  );
}
