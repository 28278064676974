import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import MobileBackButton from '../../components/common/MobileBackButton';
import PageLeaveAlert from '../../components/common/PageLeaveAlert';
import SignupButton from '../../components/common/SignupButtons';
import InformModal from '../../components/common/modal/InformModal';
import { passwordRegex } from '../../components/hooks/useValidate';
import ForeignerOptionalInfoTab from '../../components/signup/ForeignerOptionalInfoTab';
import ForeignerUserInfo from '../../components/signup/ForeignerUserInfo';
import { signupInfoForForeigner } from '../../store';

export default function SignUpRegisterInfoForeigner() {
  const [registerInfo, setRegisterInfo] = useRecoilState(
    signupInfoForForeigner
  );
  const [modalInfo, setModalInfo] = useState({
    isOpen: false,
    phrase: '',
    secondPhrase: '',
  });
  const navigate = useNavigate();

  useEffect(() => {
    if (!registerInfo.memberEmail) {
      setModalInfo({
        isOpen: true,
        phrase: 'There is no registration information.',
        secondPhrase: '',
      });
    }
  }, [registerInfo.memberEmail]);

  const checkEligibility = () => {
    return !passwordRegex.test(registerInfo.memberPwd) ||
      registerInfo.memberPwd !== registerInfo.userPasswordConfirm ||
      !registerInfo.memberFnm1 ||
      !registerInfo.memberFnm2 ||
      !registerInfo.genderCd ||
      !registerInfo.memberCountryCd ||
      !registerInfo.mobileTelno
      ? true
      : false;
  };

  const closeModal = () => {
    if (!registerInfo.memberEmail) {
      navigate('/signup');
    } else {
      setModalInfo((prev) => ({
        ...prev,
        isOpen: false,
      }));
    }
  };

  return (
    <>
      <PageLeaveAlert />
      <InformModal
        modal={modalInfo.isOpen}
        closeModal={closeModal}
        phrase={modalInfo.phrase}
        secondPhrase={modalInfo.secondPhrase}
      />
      <section className="bg-[#F8F7F8] pt-[180px] pb-48 max-md:pt-[62px] max-md:pb-24 max-md:bg-white">
        <div className="flex justify-center max-md:block">
          <div>
            <section className="flex flex-col text-3xl max-2xl:text-[28px] space-y-1 max-md:px-5 max-md:text-2xl">
              <MobileBackButton />
              <h1>
                Identity verification has been completed.
                <strong className="font-bold block">
                  Please enter your member information.
                </strong>
              </h1>
            </section>

            <section className="mt-7 p-10 bg-white rounded-lg text-lg space-y-10 max-md:space-y-8 max-md:px-5 max-md:w-full max-md:min-w-full max-md:mt-0 max-md:p-5 min-w-[800px]">
              <ForeignerUserInfo
                registerInfo={registerInfo}
                setRegisterInfo={setRegisterInfo}
              />
              <ForeignerOptionalInfoTab
                setModalInfo={setModalInfo}
                registerInfo={registerInfo}
                setRegisterInfo={setRegisterInfo}
              />
            </section>

            <div className="flex justify-center mt-14 max-md:px-5">
              <SignupButton
                onClick={() => navigate('/signup/step03')}
                disabled={checkEligibility()}
              >
                Next
              </SignupButton>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
