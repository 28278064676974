import { atom } from 'recoil';

const RegCertAuthAtom = atom({
  key: 'regcert_auth',
  default: false as boolean,
});

const slideIndex = atom({
  key: 'slideIndex',
  default: 0,
});

const quickBannerAtom = atom({
  key: 'quickBannerAtom',
  default: true,
});

const inquiryClicked = atom({
  key: 'inquiryClicked',
  default: false,
});

const clicked = atom({
  key: 'clicked',
  default: false,
});

const dark = atom({
  key: 'dark',
  default: false,
});

/**
 * 사업자번호 인증 결과를 저장
 */
const RegCertBusinessNumberAtom = atom({
  key: 'regcert_business_number',
  default: false as boolean,
});

export {
  RegCertAuthAtom,
  RegCertBusinessNumberAtom,
  clicked,
  dark,
  inquiryClicked,
  quickBannerAtom,
  slideIndex,
};
