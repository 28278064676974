export default function LoginCloseIcon() {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="24" cy="24" r="23.5" fill="white" stroke="#EEEEEE" />
      <path
        d="M24 22.6669L28.6669 18L30 19.3331L25.3331 24L30 28.6669L28.6669 30L24 25.3331L19.3331 30L18 28.6669L22.6669 24L18 19.3331L19.3331 18L24 22.6669Z"
        fill="#999999"
      />
    </svg>
  );
}
