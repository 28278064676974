import { SetStateAction, useState } from 'react';
import { RegisterForeignerInfoType } from '../../store';
import { numberException, passwordRegex } from '../hooks/useValidate';
import ClosedEyeIcon from '../vector/common/ClosedEyeIcon';
import EyeIcon from '../vector/common/EyeIcon';
import DeleteIcon from '../vector/inquiry/DeleteIcon';
import NationComboBox from './NationComboBox';

const inputStyle =
  'w-full py-4 px-3 rounded-lg border border-borderLightGray focus:border-primary max-md:p-3';
const divStyle = 'flex flex-col space-y-2 max-2xl:text-base text-lg';
const requiredStyle = 'not-italic text-primary text-[8px]';

export default function ForeignerUserInfo({
  registerInfo,
  setRegisterInfo,
}: {
  registerInfo: RegisterForeignerInfoType;
  setRegisterInfo: React.Dispatch<SetStateAction<RegisterForeignerInfoType>>;
}) {
  const [showPw, setShowPw] = useState(false);
  const [showConfirmPw, setShowConfirmPw] = useState(false);

  function useValidate(password: string) {
    if (!password) {
      return;
    }
    let phrase;
    !passwordRegex.test(password) &&
      (phrase = 'Please combine English, numbers, and special characters.');
    password.length < 8 &&
      (phrase = 'Please enter a password at least 8 characters long.');
    return phrase;
  }

  const setGender = (genderCd: 'M' | 'F') => {
    setRegisterInfo((prev) => ({
      ...prev,
      genderCd,
    }));
  };

  return (
    <>
      <div className={divStyle}>
        <div className="font-medium flex items-center space-x-1">
          <span>ID (Email)</span>
          <i className={requiredStyle}>●</i>
        </div>
        <input
          autoComplete="off"
          maxLength={60}
          className={inputStyle}
          value={registerInfo.memberEmail}
          type="email"
          disabled
          placeholder="example@example.com"
        />
      </div>
      <div className={divStyle}>
        <div className="font-medium flex items-center space-x-1">
          <span>Password</span>
          <i className={requiredStyle}>●</i>
        </div>
        <div className="relative">
          <input
            autoComplete="off"
            tabIndex={2}
            className={`w-full py-4 px-3 rounded-lg border border-borderLightGray max-md:p-3 ${
              passwordRegex.test(registerInfo.memberPwd)
                ? 'border-success'
                : useValidate(registerInfo.memberPwd)
                ? 'border-error'
                : ''
            }`}
            value={registerInfo.memberPwd}
            maxLength={16}
            onChange={(e) =>
              setRegisterInfo((prev) => ({
                ...prev,
                memberPwd: e.target.value,
              }))
            }
            type={showPw ? 'text' : 'password'}
            placeholder="Please enter your password."
          />
          {registerInfo.memberPwd && (
            <div className="flex items-center space-x-5 absolute right-4 bottom-1/2 translate-y-1/2">
              <button onClick={() => setShowPw(!showPw)}>
                {showPw ? <EyeIcon /> : <ClosedEyeIcon />}
              </button>
              <button
                onClick={() =>
                  setRegisterInfo((prev) => ({
                    ...prev,
                    memberPwd: '',
                  }))
                }
              >
                <DeleteIcon />
              </button>
            </div>
          )}
        </div>
        <div className="flex relative">
          <span className="text-textGray max-2xl:text-xs text-sm">
            Use 8 to 16 characters with a combination of English characters,
            numbers, and special characters (!, @, $, ^, *, -, _).
          </span>
          {registerInfo.memberPwd && (
            <span className="text-error max-2xl:text-xs text-sm absolute top-8 md:top-5">
              {useValidate(registerInfo.memberPwd)}
            </span>
          )}
        </div>
      </div>
      <div className={divStyle}>
        <div className="font-medium flex items-center space-x-1">
          <span>Confirm Password</span>
          <i className={requiredStyle}>●</i>
        </div>
        <div className="relative">
          <input
            autoComplete="off"
            tabIndex={2}
            className={`w-full py-4 px-3 rounded-lg border border-borderLightGray max-md:p-3 ${
              registerInfo.memberPwd !== registerInfo.userPasswordConfirm &&
              registerInfo.userPasswordConfirm?.length
                ? 'border-error'
                : registerInfo.memberPwd === registerInfo.userPasswordConfirm &&
                  registerInfo.userPasswordConfirm?.length
                ? 'border-success'
                : ''
            }`}
            onChange={(e) =>
              setRegisterInfo((prev) => ({
                ...prev,
                userPasswordConfirm: e.target.value,
              }))
            }
            maxLength={16}
            value={registerInfo.userPasswordConfirm}
            type={showConfirmPw ? 'text' : 'password'}
            placeholder="Please enter your confirm passowrd."
          />
          {registerInfo.userPasswordConfirm && (
            <div className="flex items-center space-x-5 absolute right-4 bottom-1/2 translate-y-1/2">
              <button onClick={() => setShowConfirmPw(!showConfirmPw)}>
                {showConfirmPw ? <EyeIcon /> : <ClosedEyeIcon />}
              </button>
              <button
                onClick={() =>
                  setRegisterInfo((prev) => ({
                    ...prev,
                    userPasswordConfirm: '',
                  }))
                }
              >
                <DeleteIcon />
              </button>
            </div>
          )}
        </div>
      </div>
      {registerInfo.userPasswordConfirm && (
        <span
          className={`${
            registerInfo.memberPwd === registerInfo.userPasswordConfirm
              ? 'text-success'
              : 'text-error'
          } max-2xl:text-xs text-sm`}
        >
          {!(registerInfo.memberPwd === registerInfo.userPasswordConfirm)
            ? 'Please check your password.'
            : 'Your password is verified.'}
        </span>
      )}
      <div className={divStyle}>
        <div className="font-medium flex items-center space-x-1">
          <span>First Name</span>
          <i className={requiredStyle}>●</i>
        </div>
        <div className="flex space-x-3">
          <input
            tabIndex={3}
            className={inputStyle}
            type="text"
            onChange={(e) =>
              setRegisterInfo((prev) => ({
                ...prev,
                memberFnm1: e.target.value,
              }))
            }
            value={registerInfo.memberFnm1}
            placeholder="Please enter your first name."
            maxLength={20}
          />
        </div>
      </div>
      <div className={divStyle}>
        <div className="font-medium flex items-center space-x-1">
          <span>Last Name</span>
          <i className={requiredStyle}>●</i>
        </div>
        <div className="flex space-x-3">
          <input
            tabIndex={4}
            className={inputStyle}
            type="text"
            onChange={(e) =>
              setRegisterInfo((prev) => ({
                ...prev,
                memberFnm2: e.target.value,
              }))
            }
            value={registerInfo.memberFnm2}
            placeholder="Please enter your last name."
            maxLength={20}
          />
        </div>
      </div>
      <div className={divStyle}>
        <div className="font-medium items-center flex space-x-1">
          <span>Gender</span> <i className={requiredStyle}>●</i>
        </div>
        <div className="font-light flex md:text-lg">
          <button
            onClick={() => setGender('M')}
            className={`${
              registerInfo.genderCd === 'M' &&
              'bg-black2 hover:!bg-black2 text-white'
            } w-full border border-gray3 hover:bg-slate-50 rounded-l-lg py-5 max-md:py-3`}
          >
            Male
          </button>
          <button
            onClick={() => setGender('F')}
            className={`${
              registerInfo.genderCd === 'F' &&
              'bg-black2 hover:!bg-black2 text-white'
            } w-full border border-gray3 hover:bg-slate-50 border-l-0 rounded-r-lg py-5 max-md:py-3`}
          >
            Female
          </button>
        </div>
      </div>
      <div className={divStyle}>
        <div className="font-medium flex items-center space-x-1">
          <span>Phone Number</span> <i className={requiredStyle}>●</i>
        </div>
        <NationComboBox setRegisterInfo={setRegisterInfo} />
        <div className="flex space-x-3">
          <input
            tabIndex={6}
            className={inputStyle}
            type="number"
            value={registerInfo.mobileTelno}
            onKeyDown={(e) =>
              numberException.includes(e.key) && e.preventDefault()
            }
            onChange={(e) =>
              setRegisterInfo((prev) => ({
                ...prev,
                mobileTelno: e.target.value,
              }))
            }
            placeholder="Please enter your phone number."
          />
        </div>
      </div>
    </>
  );
}
