import { Disclosure } from '@headlessui/react';
import { ChevronUpIcon } from '@heroicons/react/20/solid';
import moment from 'moment';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import PrivacyDetailModal from '../../components/help/privacy-policy/DetailModal';
import DivIcon from '../../components/vector/payment-details/DivIcon';
import { reqAgreementTerms } from '../../services/PublicService';
import { siteLangCd } from '../../services/defaultClient';
import { userinfoAtom } from '../../store';

type TermsListType = {
  termsSnum: number;
  termsTxt: string;
  termsYmd: string;
  publicYmd: string;
};

export default function PrivacyPolicy() {
  const [showDetail, setShowDetail] = useState({
    isOpen: false,
    termsTxt: '',
    termsYmd: '',
  });
  const [terms, setTerms] = useState();
  const [termsList, setTermsList] = useState<TermsListType[]>([]);
  const { language } = useRecoilValue(userinfoAtom);
  const { t } = useTranslation();

  useEffect(() => {
    reqAgreementTerms({
      siteLangCd,
      termsCd: 'M01B0',
      termsSnum: 0,
    })
      .then((res) => {
        const resData = res.data.data;
        setTerms(resData.termsTxt);
        setTermsList(resData.termsList);
      })
      .catch(console.log);
  }, []);

  return (
    <>
      <PrivacyDetailModal
        termsTxt={showDetail.termsTxt}
        termsYmd={showDetail.termsYmd}
        closeModal={() => setShowDetail((prev) => ({ ...prev, isOpen: false }))}
        showDetail={showDetail.isOpen}
      />
      <section className="bg-[#F8F7F8] pt-24 px-40 pb-48 max-md:px-5 max-md:pt-[62px] max-md:pb-24 max-md:bg-white max-md:mt-10 mt-[100px]">
        <h1 className="font-bold text-5xl border-b-2 border-black pb-10 mb-[60px] max-md:pb-5 max-md:text-[28px] max-md:border-b max-md:mb-10">
          {t('개인정보 처리방침')}
        </h1>

        <div className="mb-[60px] text-xl max-md:text-base max-md:mb-10">
          {language === 'kr'
            ? '주식회사 플루닛(이하"회사")의 주식회사 플루닛 서비스(이하"서비스")를 이용하고자 하는 자는 본 이용약관의 내용을 확인하고 이에 동의해야 합니다.'
            : 'Any person who intends to use the Ploonet Services (hereinafter referred to as the "Company") of Ploonet Co., Ltd. (hereinafter referred to as the "Service") shall confirm and agree to the terms and conditions of this Service.'}
        </div>
        <section className="terms-container space-y-10">
          <div
            className="privacy-policy"
            dangerouslySetInnerHTML={terms && { __html: terms }}
          />
        </section>
        {termsList.length > 1 ? (
          <DisclosureTab termsList={termsList} setShowDetail={setShowDetail} />
        ) : null}
      </section>
    </>
  );
}

function DisclosureTab({
  setShowDetail,
  termsList,
}: {
  setShowDetail: Dispatch<
    SetStateAction<{
      isOpen: boolean;
      termsTxt: string;
      termsYmd: string;
    }>
  >;
  termsList: TermsListType[];
}) {
  return (
    <div className="w-full rounded-lg mt-20 max-md:mt-10">
      <div className="w-full rounded-lg bg-white duration-200 ease-out border border-gray3">
        <Disclosure>
          {({ open }) => (
            <>
              <Disclosure.Button className="flex items-center w-full justify-between rounded-lg p-7 text-left text-sm font-medium text-black duration-100 ease-out border-0 max-md:p-6">
                <section className="items-center flex !justify-between w-full max-md:flex max-md:w-full max-md:space-y-3 max-md:items-start">
                  <div className="text-lg font-bold max-md:text-sm flex items-center max-md:flex-col">
                    <span className="mr-3">개인정보처리방침</span>
                    <span>
                      공고일자 :{' '}
                      {moment(termsList[0].publicYmd).format(
                        'YYYY년 MM월 DD일'
                      )}
                    </span>
                    <i className="max-md:hidden mx-3">
                      <DivIcon />
                    </i>
                    <span>
                      시행일자 :{' '}
                      {moment(termsList[0].termsYmd).format('YYYY년 MM월 DD일')}
                    </span>
                  </div>

                  <div className="flex h-full">
                    <div className="flex space-x-6">
                      <ChevronUpIcon
                        className={`${
                          open ? '' : 'rotate-180 transform max-md:hidden'
                        } h-7 w-7 duration-200 ease-out max-md:hidden`}
                      />
                    </div>
                    <div className="hidden max-md:block">
                      <ChevronUpIcon
                        className={`${
                          open ? 'rotate-180 transform' : ''
                        } h-5 w-5 duration-300 ease-out`}
                      />
                    </div>
                  </div>
                </section>
              </Disclosure.Button>
              <Disclosure.Panel className="flex flex-col text-sm px-7 pb-7 space-y-7 max-sm:p-5">
                {termsList
                  .slice(1)
                  .map(({ termsTxt, termsYmd, publicYmd }, idx) => (
                    <button
                      key={`changedPolicy-${idx}`}
                      onClick={() =>
                        setShowDetail({ isOpen: true, termsTxt, termsYmd })
                      }
                      className={`text-lg max-md:text-base text-black2 w-full text-left ${
                        idx === 0 && 'border-t pt-4'
                      } border-gray4 hover:underline`}
                    >
                      {`개인정보보호정책 공고일자: ${moment(publicYmd).format(
                        'YYYY.MM.DD'
                      )} 시행일자: ${moment(termsYmd).format(
                        'YYYY.MM.DD'
                      )} (이전 개인정보
                    취급방침보기)`}
                    </button>
                  ))}
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      </div>
    </div>
  );
}
