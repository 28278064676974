import { t } from 'i18next';
import { useRecoilValue } from 'recoil';
import { me, userinfoAtom } from '../../../store';
import { usePloonianImage } from '../../hooks/usePloonian';

export default function PloonianImageSection() {
  const { ploonianThumbnail, ploonianName } = usePloonianImage();
  const { language } = useRecoilValue(userinfoAtom);
  const myInfo = useRecoilValue<any>(me);

  return (
    <section>
      <div className="items-center flex space-x-5 mb-14 max-md:w-full max-md:mb-3 max-md:space-x-3">
        <img
          className="max-md:w-24 w-[140px]"
          src={ploonianThumbnail}
          alt="ploonian icon"
        />
        <h1 className="hidden font-bold text-2xl max-md:flex max-md:flex-col ml-3">
          <span>
            {language === 'kr'
              ? `${myInfo?.memberNickNm}님의 플루니안`
              : `I'm ${ploonianName || ''},`}
          </span>
          <span>
            {language === 'kr' ? `${ploonianName}입니다.` : `your Ploonean.`}
          </span>
        </h1>
        <div className="space-y-4 max-md:sapce-y-0">
          <h1 className="font-bold text-4xl max-2xl:text-3xl max-md:hidden">
            {language === 'kr'
              ? `${myInfo?.memberNickNm}님의 플루니안 ${
                  ploonianName || ''
                }입니다.`
              : `I'm ${ploonianName || ''}, your Ploonean.`}
          </h1>
          <div className="flex flex-col text-xl max-2xl:text-lg max-md:hidden">
            <span>
              {t(
                '플루닛 서비스 이용 중 궁금한 것, 도움이 필요한 것을 언제든 문의해주세요.'
              )}
            </span>
            <span>{t('전화는 24시간 당신을 위해 대기하고 있어요.')}</span>
          </div>
        </div>
      </div>
      <div className="hidden max-md:flex flex-col text-sm">
        <span>
          {t(
            '플루닛 서비스 이용 중 궁금한 것, 도움이 필요한 것을 언제든 문의해주세요. 전화는 24시간 당신을 위해 대기하고 있어요.'
          )}
        </span>
      </div>
    </section>
  );
}
