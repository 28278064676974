import { SetStateAction, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { loginButtonArray } from '../../array/login';

import AppleLoginIcon from '../vector/home/AppleLoginIcon';
import CircleArrowDownIcon from '../vector/home/CircleArrowDownIcon';
import KakaoLoginIcon from '../vector/home/KakaoLoginIcon';
import NaverLoginIcon from '../vector/home/NaverLoginIcon';

import { t } from 'i18next';
import { useTranslation } from 'react-i18next';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import Cookies from 'universal-cookie';
import { SNS_REDIRECT_URI, SSO_SNS_LOGIN_URL } from '../../constants';
import AuthService from '../../services/auth.service';
import { dormantUserInfo, userinfoAtom } from '../../store';
import { emailRegex } from '../hooks/useValidate';
import CheckBox from '../vector/common/CheckBox';
import CheckedBox from '../vector/common/CheckedBox';
import CloseIcon from '../vector/common/CloseIcon';
import ClosedEyeIcon from '../vector/common/ClosedEyeIcon';
import EyeIcon from '../vector/common/EyeIcon';
import LoginCloseIcon from '../vector/home/LoginCloseIcon';
import DeleteIcon from '../vector/inquiry/DeleteIcon';
import ConfirmModal from './modal/ConfirmModal';
import InformModal from './modal/InformModal';

const inputStyle =
  'w-full px-5 py-4 rounded-lg border border-borderLightGray focus:ring-transparent max-md:p-3 max-md:placeholder:text-sm';
const informInitialState = {
  isOpen: false,
  phrase: '',
  content: '',
};

export default function LoginComponent({
  closeModal,
  isPopup,
}: {
  closeModal?: React.Dispatch<SetStateAction<boolean>>;
  isPopup?: boolean;
}) {
  const [informModal, setInformModal] = useState(informInitialState);
  const [confirmModal, setConfirmModal] = useState({
    isOpen: false,
    inquiryId: '',
  });
  const [loginInfo, setLoginInfo] = useState<{
    email: string;
    password: string;
    rememberId: boolean;
  }>({
    email: '',
    password: '',
    rememberId: false,
  });
  const setDormantUserInfo = useSetRecoilState(dormantUserInfo);
  const navigate = useNavigate();

  useEffect(() => {
    const cookies = new Cookies();
    if (cookies.get('rememberedId')) {
      setLoginInfo((prev) => ({
        ...prev,
        rememberId: true,
        email: cookies.get('rememberedId'),
      }));
    }
  }, []);

  const logIn = () => {
    if (!emailRegex.test(loginInfo.email) || !loginInfo.password.length) {
      alert(t('이메일과 비밀번호를 입력해주세요.'));
      return;
    }
    const cookies = new Cookies();
    loginInfo.rememberId
      ? cookies.set('rememberedId', loginInfo.email)
      : cookies.remove('rememberedId');

    AuthService.loginExceed(loginInfo.email)
      .then((res) => {
        if (res.data) {
          setConfirmModal((prev) => ({
            ...prev,
            isOpen: true,
          }));
        } else {
          logInProc();
        }
      })
      .catch((err) => {
        logInProc();
        console.log(err);
      });
  };

  const logInProc = () => {
    AuthService.login(loginInfo.email, loginInfo.password).then((res) => {
      switch (res.cd) {
        case '201':
          setInformModal({
            isOpen: true,
            phrase: '이메일과 비밀번호를 다시 확인해주세요.',
            content: '',
          });
          break;
        case '202':
          setInformModal({
            isOpen: true,
            phrase: '비밀번호를 다시 확인해주세요.',
            content: '',
          });
          break;
        case '301':
          setInformModal({
            isOpen: true,
            phrase: '탈퇴 요청이 처리중입니다.',
            content:
              '탈퇴 신청을 하시게 되면 로그인이 불가합니다. 탈퇴처리 완료 시 고객님이 등록하신 이메일과 연락처를 통해 안내드립니다.',
          });
          break;
        case '401':
          setInformModal({
            isOpen: true,
            phrase: '휴면계정으로 본인인증 후 사용가능합니다.',
            content: '',
          });
          setDormantUserInfo({
            userType: res.userType,
            memberUuid: res.memberUuid,
            memberEmail: loginInfo.email,
          });
          break;
      }
    });
  };

  const closeInformModal = () => {
    if (informModal.phrase === '휴면계정으로 본인인증 후 사용가능합니다.') {
      navigate('/help/dormant');
    }
    setInformModal((prev) => ({
      ...prev,
      isOpen: false,
    }));
  };

  return (
    <>
      <InformModal
        modal={informModal.isOpen}
        closeModal={closeInformModal}
        phrase={informModal.phrase}
        content={informModal.content}
      />
      <ConfirmModal
        isOpen={confirmModal.isOpen}
        onClick={() => logInProc()}
        firstLine="다른 기기에서 로그인 중입니다."
        content="강제 로그아웃하고, 현재 기기로 로그인 하시겠습니까?"
        closeModal={() =>
          setConfirmModal((prev) => ({
            ...prev,
            isOpen: false,
          }))
        }
      />
      <section
        className={`text-left !overflow-visible relatvie w-full flex flex-col items-center ${
          isPopup ? 'pt-0 pb-0' : 'pt-[60px] pb-10'
        }  max-md:pb-8 max-w-[1200px] mx-auto space-y-7 max-md:flex-col max-md:px-5 max-md:pt-9 max-md:space-y-12`}
      >
        <MainLoginSection
          isPopup={isPopup}
          logIn={logIn}
          setLoginInfo={setLoginInfo}
          loginInfo={loginInfo}
          closeModal={() => closeModal && closeModal(false)}
          inputStyle={inputStyle}
        />
        <LoginBottomSection
          isPopup={isPopup}
          closeModal={() => closeModal && closeModal(false)}
        />
      </section>
    </>
  );
}

function MainLoginSection({
  setLoginInfo,
  inputStyle,
  logIn,
  closeModal,
  loginInfo,
  isPopup,
}: {
  setLoginInfo: React.Dispatch<
    SetStateAction<{
      email: string;
      password: string;
      rememberId: boolean;
    }>
  >;
  inputStyle: string;
  logIn: () => void;
  closeModal: React.Dispatch<SetStateAction<boolean>>;
  loginInfo: {
    email: string;
    password: string;
    rememberId: boolean;
  };
  isPopup: boolean | undefined;
}) {
  const [showPw, setShowPw] = useState(false);
  const location = window.location.pathname;
  const { language } = useRecoilValue(userinfoAtom);
  return (
    <div
      className={`relative flex flex-col w-full max-md:w-full ${
        !isPopup && 'pb-20 max-md:pb-6'
      }  border-b`}
    >
      <button
        onClick={() => closeModal(false)}
        className={`absolute right-0 z-50 max-md:hidden ${
          isPopup ? '-top-4' : '-top-[84px]'
        } ${location.includes('/login') && 'hidden'}`}
      >
        {isPopup ? <CloseIcon isDark={false} /> : <LoginCloseIcon />}
      </button>
      <h1 className="font-bold text-[28px] mb-5">LOGIN</h1>

      <section
        className={`flex ${
          isPopup ? 'flex-col !space-x-0 space-y-10' : 'space-x-20'
        } max-md:flex-col items-center max-md:space-x-0 max-md:space-y-10 w-full h-full`}
      >
        <div
          className={`w-full max-w-3xl flex items-center justify-between space-x-3 max-md:flex-col max-md:space-x-0 max-md:space-y-4 ${
            language === 'en' && isPopup ? 'mb-4' : ''
          }`}
        >
          <div className="flex flex-col w-full space-y-3 relative">
            <input
              spellCheck={false}
              onKeyDown={(e) => e.key === 'Enter' && logIn()}
              placeholder={t('이메일을 입력해주세요.')}
              className={inputStyle}
              value={loginInfo.email}
              onChange={(e) =>
                setLoginInfo((prev) => ({
                  ...prev,
                  email: e.target.value,
                }))
              }
              type="email"
            />
            <div className="relative">
              <input
                spellCheck={false}
                onKeyDown={(e) => e.key === 'Enter' && logIn()}
                type={showPw ? 'text' : 'password'}
                placeholder={t('비밀번호를 입력해주세요.')}
                onChange={(e) =>
                  setLoginInfo((prev) => ({
                    ...prev,
                    password: e.target.value,
                  }))
                }
                value={loginInfo.password}
                className={inputStyle}
              />
              {loginInfo.password && (
                <div className="flex items-center space-x-5 absolute right-4 bottom-1/2 translate-y-1/2">
                  <button onClick={() => setShowPw(!showPw)}>
                    {showPw ? <EyeIcon /> : <ClosedEyeIcon />}
                  </button>
                  <button
                    onClick={() =>
                      setLoginInfo((prev) => ({
                        ...prev,
                        password: '',
                      }))
                    }
                  >
                    <DeleteIcon />
                  </button>
                </div>
              )}
            </div>
            <div
              className={`flex w-full justify-between max-md:pr-0 max-md:justify-center mt-5 ${
                !isPopup && 'absolute'
              }  -bottom-10`}
            >
              <div className="max-md:hidden">
                <input
                  id="save-id"
                  type="checkbox"
                  onChange={(e) =>
                    setLoginInfo((prev) => ({
                      ...prev,
                      rememberId: e.target.checked,
                    }))
                  }
                  className="hidden"
                />

                <label
                  htmlFor="save-id"
                  className="cursor-pointer flex items-center space-x-2"
                >
                  {loginInfo.rememberId ? <CheckedBox /> : <CheckBox />}
                  <span>{t('아이디 저장')}</span>
                </label>
              </div>
              <div className="flex space-x-6 text-sm text-textStrongGray max-md:hidden">
                {loginButtonArray.map(({ text, link }, idx) => (
                  <div
                    key={`button-${idx}`}
                    className="flex items-center space-x-6"
                  >
                    <Link to={link}>
                      <button
                        onClick={() => {
                          closeModal && closeModal(false);
                        }}
                        className="hover:text-textGray"
                      >
                        {t(text)}
                      </button>
                    </Link>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="hidden max-md:flex max-md:justify-start max-md:w-full">
            <input
              id="save-id"
              type="checkbox"
              onChange={(e) =>
                setLoginInfo((prev) => ({
                  ...prev,
                  rememberId: e.target.checked,
                }))
              }
              className="hidden"
            />

            <label
              htmlFor="save-id"
              className="cursor-pointer flex items-center space-x-2"
            >
              {loginInfo.rememberId ? <CheckedBox /> : <CheckBox />}
              <span>{t('아이디 저장')}</span>
            </label>
          </div>
          <button
            onClick={logIn}
            className={`bg-gradient-to-br from-primary to-primaryTo hover:from-hoverPrimary hover:to-hoverPrimaryTo text-xl text-white w-[184px] py-12 rounded-lg max-md:w-full max-md:py-5 max-md:text-lg ${
              isPopup && 'mb-9'
            }`}
          >
            {t('로그인')}
          </button>
          <div className="space-x-6 text-sm text-textStrongGray hidden max-md:flex">
            {loginButtonArray.map(({ text, link }, idx) => (
              <div
                key={`button-${idx}`}
                className="flex items-center space-x-6"
              >
                <Link to={link}>
                  <button
                    onClick={() => {
                      closeModal && closeModal(false);
                    }}
                    className="hover:text-textGray"
                  >
                    {t(text)}
                  </button>
                </Link>
              </div>
            ))}
          </div>
        </div>
        <section
          className={`max-md:w-full max-md:justify-center max-md:flex-col max-md:h-full ${
            language === 'en' ? 'hidden' : ''
          } ${isPopup && 'w-full'}`}
        >
          <div
            className={`${
              isPopup
                ? '!flex w-full justify-between space-y-0 border-t border-black/1 py-7'
                : 'space-y-2 max-md:text-center max-md:space-y-6'
            }`}
          >
            <div className="flex flex-col">
              <h1 className="font-bold text-xl mb-1 max-md:text-base">
                {t('SNS계정으로 간편 로그인')}
              </h1>
              <span className="text-lg max-md:text-sm">
                {t('회원가입 후 간편로그인이 가능합니다.')}
              </span>
            </div>
            <div className="flex space-x-2 max-md:justify-center">
              <Link
                to={
                  SSO_SNS_LOGIN_URL +
                  '&alk_site=kakao&redirect_uri=' +
                  SNS_REDIRECT_URI
                }
              >
                <button className="bg-[#F7DC49] hover:bg-opacity-80 rounded-full">
                  <KakaoLoginIcon />
                </button>
              </Link>
              <Link
                to={
                  SSO_SNS_LOGIN_URL +
                  '&alk_site=naver&redirect_uri=' +
                  SNS_REDIRECT_URI
                }
              >
                <button className="bg-[#5eb032] hover:bg-opacity-80 rounded-full">
                  <NaverLoginIcon />
                </button>
              </Link>
              <Link
                to={
                  SSO_SNS_LOGIN_URL +
                  '&alk_site=apple&redirect_uri=' +
                  SNS_REDIRECT_URI
                }
              >
                <button className="bg-black hover:bg-opacity-80 rounded-full">
                  <AppleLoginIcon />
                </button>
              </Link>
            </div>
          </div>
        </section>
      </section>
    </div>
  );
}

function LoginBottomSection({
  closeModal,
  isPopup,
}: {
  closeModal: React.Dispatch<SetStateAction<boolean>>;
  isPopup: boolean | undefined;
}) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { language } = useRecoilValue(userinfoAtom);
  return (
    <div className="flex max-md:flex-col items-center justify-between w-full">
      <div className="flex flex-col space-y-2">
        <span className="text-black2">
          {t(
            '아직 플루닛 통합회원이 아니신가요? 통합회원이 되시면 다양한 서비스를 하나의 ID로 이용하실 수 있습니다.'
          )}
        </span>
        <button
          onClick={() => {
            navigate('/help/total-info');
            closeModal(false);
          }}
          className={`flex items-center space-x-1 justify-start ${
            language === 'en' && 'text-sm'
          }`}
        >
          <span className="text-ploonetOrange font-bold max-md:text-left">
            {t('플루닛 통합서비스 안내')}
          </span>
          <i className="-rotate-90 inline-flex">
            <CircleArrowDownIcon />
          </i>
        </button>
      </div>
      <Link className="max-md:w-full" to="/signup">
        <button
          onClick={() => closeModal(false)}
          className={`border max-md:w-full max-md:mt-5 border-black2 text-black2 rounded-lg ${
            language === 'kr'
              ? 'px-6'
              : language === 'en' && isPopup
              ? 'px-2 whitespace-pre-wrap'
              : 'px-6'
          } py-3 max-2xl:px-4 hover:bg-gray5`}
        >
          {t('통합회원가입')}
        </button>
      </Link>
    </div>
  );
}
