export default function LinkIcon({ primary }: { primary?: boolean }) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1475_92723)">
        <path
          d="M10 20C7.34784 20 4.8043 18.9464 2.92893 17.0711C1.05357 15.1957 0 12.6522 0 10C0 7.34784 1.05357 4.80429 2.92893 2.92892C4.8043 1.05356 7.34784 0 10 0C12.6522 0 15.1957 1.05356 17.0711 2.92892C18.9464 4.80429 20 7.34784 20 10C20 11.3132 19.7413 12.6136 19.2388 13.8268C18.7362 15.0401 17.9997 16.1425 17.0711 17.0711C16.1425 17.9997 15.0401 18.7362 13.8268 19.2388C12.6136 19.7413 11.3132 20 10 20ZM10 1.08749C7.63899 1.09638 5.37723 2.03824 3.70773 3.70773C2.03824 5.37723 1.09639 7.63899 1.0875 10C1.09639 12.361 2.03824 14.6228 3.70773 16.2923C5.37723 17.9618 7.63899 18.9036 10 18.9125C12.361 18.9036 14.6228 17.9618 16.2923 16.2923C17.9618 14.6228 18.9036 12.361 18.9125 10C18.9036 7.63899 17.9618 5.37723 16.2923 3.70773C14.6228 2.03824 12.361 1.09638 10 1.08749Z"
          className={`${primary ? 'fill-primary' : 'fill-white'}`}
        />
        <path
          d="M13.9332 14.2778L12.8407 14.246L12.8107 7.29337L5.85805 7.26332L5.82623 6.17084L13.935 6.16907L13.9332 14.2778Z"
          className={`${primary ? 'fill-primary' : 'fill-white'}`}
        />
        <path
          d="M13.7868 7.14593L6.6866 14.2462L5.8584 13.418L12.9586 6.31773L13.7868 7.14593Z"
          className={`${primary ? 'fill-primary' : 'fill-white'}`}
        />
      </g>
      <defs>
        <clipPath id="clip0_1475_92723">
          <rect
            width="20"
            height="20"
            className={`${primary ? 'fill-primary' : 'fill-white'}`}
          />
        </clipPath>
      </defs>
    </svg>
  );
}
