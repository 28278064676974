export default function PhoneIcon() {
  return (
    <svg
      width="12"
      height="13"
      viewBox="0 0 12 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.6"
        d="M4.52819 1.50858L5.01495 2.38592C5.45423 3.17768 5.27789 4.21633 4.58604 4.91227C4.58603 4.91227 4.58603 4.91227 4.58603 4.91227C4.58592 4.91239 3.74694 5.75654 5.26839 7.28697C6.78918 8.81674 7.62834 7.97416 7.62915 7.97335C7.62917 7.97332 7.62916 7.97334 7.62919 7.97331C8.32104 7.2774 9.35358 7.10003 10.1407 7.54189L11.0129 8.03153C12.2014 8.69875 12.3418 10.3754 11.2971 11.4263C10.6693 12.0577 9.9003 12.5491 9.05019 12.5815C7.61908 12.6361 5.18871 12.2718 2.75078 9.81943C0.312846 7.36711 -0.049339 4.9224 0.00491374 3.48285C0.0371414 2.62772 0.525602 1.85415 1.15335 1.2227C2.19807 0.171819 3.86488 0.313014 4.52819 1.50858Z"
        fill="#222222"
      />
    </svg>
  );
}
