export default function Skeleton({
  isInquiry,
  isTelInquiry,
}: {
  isInquiry?: boolean;
  isTelInquiry?: boolean;
}) {
  const arr = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  return (
    <>
      <>
        {arr.map((_, idx) => (
          <div
            key={`MobileSkeleton-${idx}`}
            className="hidden border animate-pulse border-borderGray p-6 rounded-[10px] items-center justify-between max-md:flex"
          >
            <div className="flex items-center w-full">
              <div className="flex justify-between w-full">
                <div className="flex flex-col space-y-5">
                  <div className="h-3 w-32 bg-gray-500 rounded-full" />
                  <div className="h-2 w-14 bg-gray-300 rounded-full" />
                  <div className="h-2 w-24 bg-gray-300 rounded-full" />
                </div>
                <div className="space-y-7">
                  <div
                    className={`h-6 w-20 ${
                      idx % 2 === 0 ? 'bg-[#7b61ff]' : 'bg-[#39B5B5]'
                    } bg-opacity-50 rounded-full`}
                  />
                  <div className="h-5 w-20 bg-gray-300 rounded-full" />
                </div>
              </div>
            </div>
          </div>
        ))}
        {arr.map((_, idx) => (
          <div
            key={`skeleton-${idx}`}
            className="border animate-pulse border-borderGray p-6 rounded-[10px] flex items-center justify-between max-md:hidden"
          >
            <div className="flex items-center justify-between w-full">
              <div className="flex items-center space-x-4">
                {isTelInquiry ? (
                  <div
                    className={`h-6 w-20 bg-gray-500 bg-opacity-50 rounded-full`}
                  />
                ) : (
                  <>
                    <div
                      className={`h-6 w-20 ${
                        idx % 2 === 0 ? 'bg-[#7b61ff]' : 'bg-[#39B5B5]'
                      } bg-opacity-50 rounded-full`}
                    />
                    <div className="h-3 w-16 bg-gray-300 rounded-full" />
                  </>
                )}
              </div>
              <div className="flex items-center space-x-16">
                {!isInquiry && !isTelInquiry && (
                  <>
                    <div className="h-3 w-32 bg-gray-500 rounded-full" />
                    <div className="h-2 w-14 bg-gray-300 rounded-full" />
                  </>
                )}
                <div className="h-2 w-14 bg-gray-300 rounded-full" />
                <div className="h-7 w-16 bg-gray-300 rounded-full" />
              </div>
            </div>
          </div>
        ))}
      </>
    </>
  );
}
