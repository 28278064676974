export default function StepOneIcon() {
  return (
    <svg
      width="40"
      height="41"
      viewBox="0 0 40 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20 25.957C25.5228 25.957 30 21.4799 30 15.957C30 10.4342 25.5228 5.95703 20 5.95703C14.4772 5.95703 10 10.4342 10 15.957C10 21.4799 14.4772 25.957 20 25.957Z"
        stroke="#666666"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M4.84204 34.7056C6.37885 32.0455 8.58862 29.8367 11.2494 28.3011C13.9101 26.7654 16.9281 25.957 20.0002 25.957C23.0723 25.9571 26.0903 26.7655 28.751 28.3012C31.4117 29.8369 33.6214 32.0458 35.1582 34.7059"
        stroke="#666666"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
