import { ChangeEvent, Dispatch, SetStateAction } from 'react';
import { PersonalInfoType } from '../../../types/quick/quick-type';
import useValidate, { passwordRegex } from '../../hooks/useValidate';

const INPUT_STYLE =
  'w-full h-full py-4 px-3 rounded-lg border border-borderLightGray focus:border-primary max-md:p-3 max-md:placeholder:text-sm';
const DIV_STYLE = 'flex flex-col space-y-2 max-2xl:text-base text-lg';
const REQUIRED_STYLE = 'not-italic text-primary text-[8px]';

type Props = {
  setPersonalInfo: Dispatch<SetStateAction<PersonalInfoType>>;
  personalInfo: PersonalInfoType;
  duplicateChecked: boolean;
  checkEmailDuplicate: (memberEmail: string) => Promise<void>;
  phrase: string;
  setPhrase: Dispatch<SetStateAction<string>>;
};

export default function PersonalInputForm({
  setPersonalInfo,
  personalInfo,
  duplicateChecked,
  checkEmailDuplicate,
  phrase,
  setPhrase,
}: Props) {
  const { memberPwd } = personalInfo;

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setPersonalInfo((prev) => ({ ...prev, [name]: value }));
  };

  const checkDuplicate = () => {
    checkEmailDuplicate(personalInfo.memberEmail);
  };

  return (
    <>
      <div className={DIV_STYLE}>
        <div className="font-medium flex items-center space-x-1">
          <span>아이디(이메일)</span>
          <i className={REQUIRED_STYLE}>●</i>
        </div>
        <div className="flex space-x-3">
          <input
            className={`${INPUT_STYLE} ${
              duplicateChecked ? 'border-success' : phrase ? 'border-error' : ''
            }`}
            autoFocus
            tabIndex={1}
            type="email"
            spellCheck={false}
            maxLength={60}
            name="memberEmail"
            autoComplete="off"
            placeholder="아이디가 될 이메일 정보를 입력해주세요."
            onKeyDown={({ key }) => key === 'Enter' && checkDuplicate()}
            onChange={(e) => {
              setPhrase('');
              handleOnChange(e);
            }}
          />
          <button
            className={`rounded-lg border border-black ${
              duplicateChecked && 'bg-gray5'
            } hover:bg-gray5 min-w-[144px] max-md:min-w-fit max-md:px-4`}
            onClick={checkDuplicate}
          >
            중복확인
          </button>
        </div>
        <div className="flex relative">
          <span className="text-textGray max-2xl:text-xs text-sm">
            영문, 숫자, 일부기호(-, _, ., @,)만 입력 가능
          </span>
          {phrase && (
            <span
              className={`${
                duplicateChecked ? 'text-success' : phrase ? 'text-error' : ''
              } max-2xl:text-xs text-sm absolute -bottom-[22px]`}
            >
              {phrase}
            </span>
          )}
        </div>
      </div>
      <div className={DIV_STYLE}>
        <div className="font-medium flex items-center space-x-1">
          <span>이름</span>
          <i className={REQUIRED_STYLE}>●</i>
        </div>
        <div className="flex space-x-3">
          <input
            className={INPUT_STYLE}
            tabIndex={2}
            spellCheck={false}
            type="text"
            maxLength={20}
            name="memberFnm1"
            autoComplete="off"
            onChange={handleOnChange}
            placeholder="AI가 전화 응대 시 안내할 고객님의 이름 혹은 업체명을 입력해주세요."
          />
        </div>
      </div>
      <div className={DIV_STYLE}>
        <div className="font-medium flex items-center space-x-1">
          <span>비밀번호</span>
          <i className={REQUIRED_STYLE}>●</i>
        </div>
        <div className="flex space-x-3">
          <input
            className={`${INPUT_STYLE} ${
              passwordRegex.test(memberPwd)
                ? '!border-success'
                : useValidate(memberPwd)
                ? '!border-error'
                : ''
            }`}
            tabIndex={3}
            maxLength={16}
            type="password"
            name="memberPwd"
            onChange={handleOnChange}
            autoComplete="off"
            placeholder="비밀번호를 입력해주세요."
          />
        </div>
        <div className="flex relative">
          <span className="text-textGray max-2xl:text-xs text-sm">
            영문, 숫자, 특수문자(!, @, $, ^, *, -, _)를 혼합하여 8~16자로 사용
          </span>
          {memberPwd && (
            <span className="text-error max-2xl:text-xs text-sm absolute -bottom-[22px]">
              {useValidate(memberPwd)}
            </span>
          )}
        </div>
      </div>
    </>
  );
}
