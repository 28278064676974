import HandStarIcon from '../components/vector/workcenter/HandStarIcon';
import IncreaseIcon from '../components/vector/workcenter/IncreaseIcon';
import UnionIcon from '../components/vector/workcenter/UnionIcon';

export const prosArray: {
  imgPath?: string;
  text: string;
  subText: string;
}[] = [
  {
    imgPath: '/workcenter/01.png',
    text: '리셉셔니스트',
    subText:
      '따분한 ARS가 아닌 자연스러운 상호 대화가 가능한 AI가 전화를 받고, 부서 안내와 담당자 연결, 메모 전달까지',
  },
  {
    imgPath: '/workcenter/02.png',
    text: '장애접수',
    subText:
      '서비스 장애 발생 시 24시간 대응과 긴급 문의와 장애현상 별 상담접수를 통한 휴먼 상담사 연결까지 빠르고 속시원한 처리',
  },
  {
    imgPath: '/workcenter/03.png',
    text: '경영지원',
    subText:
      '채용과 복지, 사규 안내, 각종 문서 발급 등 휴먼의 업무 처리에서 반복적인 기초 업무부터 업무 별 지원, 주주 상담과 IR관련 응대 자동화',
  },
  {
    imgPath: '/workcenter/04.png',
    text: '방문예약',
    subText:
      '병원, 호텔, 맛집 예약을 간편하게 관리하고 잦은 노쇼까지 방지할 수 있도록 비즈니스마다 다른 프로세스를 선택하여 바로 응대 가능',
  },
  {
    imgPath: '/workcenter/05.png',
    text: '영업/텔레마케터',
    subText:
      '영업 문의와 전화 상담, 담당자 연결과 동시에 수천명, 수만명에게 동시 전화 영업 가능, 유입, 잠재 고객 정보도 동시 관리하여 아웃바운드 마케팅까지 연계',
  },
  {
    imgPath: '/workcenter/06.png',
    text: '개인 비서',
    subText:
      '운전 중일 때, 회의 중일 때, 휴가 중일 때 걸려오는 전화나 부재 메시지를 한 통화도 놓치지 않고 나 대신 전화응대와 메모를 남겨주는 똑똑한 나만의 AI 개인비서',
  },
];

export const reasonSectionArray = [
  {
    title: '옴니채널 상담 플랫폼 기술',
    paragraph:
      '플루닛의 워크센터는 인바운드, 아웃바운드 전환 뿐 아니라 다양한 외부 채널에서 고객과 연결되어 고객 응대를 통합',
    span: '상담업무의 효율성과 운용성을 극대화',
    icon: <IncreaseIcon />,
  },
  {
    title: '콜센터로 더 높은 해결률 얻기',
    paragraph:
      '플루닛의 워크센터는 콜인프라와 유기적으로 연계된 AI 음성인식 및 합성과 대화 매니저와의 심층 질의응답 기술로 고객 문제 해결 용이',
    span: '심층 질의응답 기술과 친근한 대화 방식',
    icon: <HandStarIcon />,
  },
  {
    title: '고객 재방문과 매출 향상, 운영 비용 절감',
    paragraph:
      '다양한 비즈니스 영역에서 기본 응대 후의 사후 관리 뿐 아니라 휴먼 텔레마케터를 대신한 초도 영업의 역할 가능',
    span: '영업 초기 응대에 대한 인바운드 확장과 아웃바운드 마케팅 지원',
    icon: <UnionIcon />,
  },
];

export const differnceSectionArray = [
  {
    title: 'AI직원채용',
    content:
      '다양한 직무에 맞는 선택 가능 업무 매뉴얼 제공 설정된 업무에 대한 AI 직원 스킬 즉시 확인 가능',
    imagePath: '/workcenter/1.png',
    enImagePath: '/workcenter/1en.png',
  },
  {
    title: '실시간 고객정보와 상담이력 확인',
    content:
      '연결될 때마다 다시 설명해야 하는 수고는 이제 그만! 통화 상대인 고객의 모든 통화내역의 연계로 고객의 요구사항 한번에 파악',
    imagePath: '/workcenter/2.png',
    enImagePath: '/workcenter/2en.png',
  },
  {
    title: '즉시 상담 가능한 환경 세팅과 설정',
    content:
      '상담환경 세팅과 회원가입과 동시에 무료로 부여되는 AI를 직원으로 채용하여 즉시 상담 시작',
    imagePath: '/workcenter/3.png',
    enImagePath: '/workcenter/3en.png',
  },
  {
    title: '아웃바운드 연계 지원',
    content:
      '단건, 대량 선택 발신이 가능한 템플릿 제공 상담 중 팔로업 발신과 다양한 채널 아웃바운드까지 지원',
    imagePath: '/workcenter/4.png',
    enImagePath: '/workcenter/4en.png',
  },
  {
    title: '상담현황 실시간 리포트',
    content:
      'AI직원과 휴먼 상담사의 상담 상태와 현황을 한 눈에 파악할 수 있는 통계 데이터 실시간 제공',
    imagePath: '/workcenter/5.png',
    enImagePath: '/workcenter/5en.png',
  },
  {
    title: '빠르고 편한 서비스 이용',
    content:
      '통합 요금제외 크레딧으로 필요할 때 언제든 다양한 업무 추가와 상담 선택 용이',
    imagePath: '/workcenter/6.png',
    enImagePath: '/workcenter/6en.png',
  },
];

export const usesArray = [
  {
    imgPath: '/studio/1.png',
    videoUrl: '/studio/1.mp4',
    text: '뉴스 및 정보 전달',
    subText:
      '오늘의 날씨 및 뉴스 브리핑, 주식 일단 리포트, 기업의 제품 소개 및 튜토리얼',
  },
  {
    imgPath: '/studio/2.png',
    videoUrl: '/studio/2.mp4',
    text: '캠페인 및 광고 영상',
    subText: '글로벌 환경 캠페인, 선거 공약 캠페인',
  },
  {
    imgPath: '/studio/3.png',
    videoUrl: '/studio/3.mp4',
    text: 'PR 및 IR',
    subText:
      '제품 투자자, 주주 대상 IR 영상, 기업 홍보 영상, 대통령 및 정부기관 연설',
  },
  {
    imgPath: '/studio/4.png',
    videoUrl: '/studio/4.mp4',
    text: '기업 및 학교 교육 프로그램',
    subText: '기업 OJT, 핵심가치 교육, 세미나 및 강연, 방과 후 학습 튜터',
  },
  {
    imgPath: '/studio/5.png',
    videoUrl: '/studio/5.mp4',
    text: '유튜버 및 인플루언서',
    subText: '연예인/인플루언서/가상인간 활용 영상 콘텐츠, 고인 추모 영상',
  },
  {
    imgPath: '/studio/6.png',
    videoUrl: '/studio/6.mp4',
    text: '브랜드 엠버서더',
    subText: '브랜드 홍보대사, 다국어 상품 및 서비스 소개',
  },
];
