import {
  creditUseStatusArray,
  payStatusArray,
  serviceFilterArray,
} from '../array/SearchFilterArray';
import TokenService from '../services/token.service';

export function getCreditUseStatusName(value) {
  return findNameByValue(creditUseStatusArray, value);
}

export function getPayStatusName(value) {
  return findNameByValue(payStatusArray, value);
}

export function getServiceName(value) {
  return findNameByValue(serviceFilterArray, value);
}

export function getServiceValue(name) {
  return findValueByName(serviceFilterArray, value);
}

export function findValueByName(arrays, name) {
  const foundItem = arrays.find((item) => item.name === name);
  return foundItem ? foundItem.value : name;
}

export function findNameByValue(arrays, value) {
  const foundItem = arrays.find((item) => item.value === value);
  return foundItem ? foundItem.name : value;
}

export function getParentPage() {
  return window.innerWidth > 769 ? 10 : 5;
}

export const isLogin = TokenService.getLocalAccessToken() ? true : false;

export const hasEmptyData = (obj) => {
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      if (typeof obj[key] === 'string' && obj[key] === '') {
        return true;
      }
    }
  }
  return false;
};

document.addEventListener('wheel', function (event) {
  if (document.activeElement.type === 'number') {
    document.activeElement.blur();
  }
});
