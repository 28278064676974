import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import SignupButton from '../../components/common/SignupButtons';
import InformModal from '../../components/common/modal/InformModal';
import LoginModal from '../../components/common/modal/LoginModal';
import FindIdComponent from '../../components/help/find-id/FindId';
import ForeignerFindId from '../../components/help/find-id/ForeignerFindId';
import {
  findEnterpriseId,
  findForeignerId,
  findPersonalId,
} from '../../services/PublicService';
import { siteLangCd } from '../../services/defaultClient';
import { RegCertResultType, userinfoAtom } from '../../store';

const userTypeButtonArray: {
  buttonText: '개인회원' | '기업회원';
  value: 'M03A0' | 'M03B0';
}[] = [
  {
    buttonText: '개인회원',
    value: 'M03A0',
  },
  {
    buttonText: '기업회원',
    value: 'M03B0',
  },
];

export interface EnterpriseInfoType {
  companyNm: string;
  memberBrno: string;
  establishYmd: string;
  ceoFnm: string;
}

export type ForeignerInfoType = {
  memberTypeCd: 'M03A0';
  memberFnm1: string;
  memberFnm2: string;
  memberCountryCd: string;
  mobileTelno: string;
};

export default function FindId() {
  const [userType, setUserType] = useState<'M03A0' | 'M03B0'>('M03A0');
  const [foundId, setFoundId] = useState('');
  const [loginModal, setLoginModal] = useState(false);
  const [informModal, setInformModal] = useState({
    isOpen: false,
    phrase: '',
  });
  const [encKey, setEncKey] = useState('');
  const [authorizedUserData, setAuthorizedUserData] =
    useState<RegCertResultType>();
  const [enterpriseInfo, setEnterpriseInfo] = useState<EnterpriseInfoType>({
    companyNm: '',
    memberBrno: '',
    establishYmd: '',
    ceoFnm: '',
  });
  const [foreignerInfo, setForeignerInfo] = useState<ForeignerInfoType>({
    memberTypeCd: 'M03A0',
    memberFnm1: '',
    memberFnm2: '',
    memberCountryCd: '',
    mobileTelno: '',
  });
  const screenSize = window.innerWidth;
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { language } = useRecoilValue(userinfoAtom);

  const checkEligibility = () => {
    if (
      language === 'en' &&
      (!foreignerInfo.memberFnm1 ||
        !foreignerInfo.memberFnm2 ||
        !foreignerInfo.memberCountryCd ||
        !foreignerInfo.mobileTelno)
    ) {
      return true;
    } else if (
      userType === 'M03B0' &&
      (!enterpriseInfo.companyNm ||
        !enterpriseInfo.memberBrno ||
        !enterpriseInfo.establishYmd ||
        !enterpriseInfo.ceoFnm)
    ) {
      return true;
    } else {
      return false;
    }
  };

  const requestFindId = () => {
    if (language === 'en') {
      findForeignerId(foreignerInfo)
        .then((res) => {
          const resData = res.data;
          resData.resultCd === '0000'
            ? setFoundId(resData.data.memberEmail)
            : setInformModal({
                isOpen: true,
                phrase: 'Member information is missing.',
              });
        })
        .catch(console.log);
    } else if (userType === 'M03B0') {
      const { companyNm, memberBrno, establishYmd, ceoFnm } = enterpriseInfo;
      findEnterpriseId({
        siteLangCd,
        memberTypeCd: userType,
        companyNm,
        memberBrno,
        establishYmd,
        ceoFnm,
      })
        .then((res) => {
          const resData = res.data;
          if (resData.resultCd === '0000') {
            setFoundId(resData.data.memberEmail);
          } else {
            setInformModal({
              isOpen: true,
              phrase: '없는 기업 정보입니다.',
            });
          }
        })
        .catch(console.log);
    } else {
      window.open(
        '/popup/signup/regcert',
        'authCheck',
        'width=480, height=800'
      );
    }
  };

  (window as any).callbackUpdateEncKey = (data: any) => {
    setEncKey(data);
  };

  (window as any).callbackGetEncKey = () => {
    return encKey;
  };

  (window as any).callbackSetAuthResult = async (data: RegCertResultType) => {
    await setAuthorizedUserData(data);
  };

  useEffect(() => {
    if (!authorizedUserData) {
      return;
    }
    findPersonalId({
      memberTypeCd: userType,
      siteLangCd,
      memberFnm1: authorizedUserData.staffName,
      genderCd: authorizedUserData.staffGender,
      mobileTelno: authorizedUserData.staffMobile,
    })
      .then((res) => {
        const resData = res.data;
        if (resData.resultCd === '0000') {
          setFoundId(resData.data.memberEmail);
        } else {
          setInformModal({
            isOpen: true,
            phrase: '없는 회원 정보입니다.',
          });
        }
      })
      .catch(console.log);
  }, [authorizedUserData?.staffName]);

  return (
    <>
      <InformModal
        modal={informModal.isOpen}
        closeModal={() =>
          setInformModal((prev) => ({
            ...prev,
            isOpen: false,
          }))
        }
        phrase={informModal.phrase}
        secondPhrase="입력하신 정보를 다시 확인해주세요."
      />
      <LoginModal modal={loginModal} closeModal={() => setLoginModal(false)} />
      <section className="pt-[200px] pb-60 max-w-[600px] mx-auto max-md:pt-[98px] max-md:px-5 max-md:pb-24">
        <div className="flex flex-col text-center space-y-16 max-md:space-y-10">
          <div className="font-bold space-y-10">
            <h1 className="text-[44px] max-md:text-[28px]">
              {t('아이디 찾기')}
            </h1>
            {language === 'kr' && (
              <div className="flex">
                {userTypeButtonArray.map(({ buttonText, value }, idx) => (
                  <button
                    key={`userButton-${idx}`}
                    onClick={() => {
                      setFoundId('');
                      setUserType(value);
                    }}
                    className={`pb-[18px] px-24 font-bold text-xl w-full max-md:text-lg max-md:pb-[15px] max-md:px-0 ${
                      value === userType
                        ? 'border-b-2 border-black'
                        : 'border-b border-borderLightGray text-textGray'
                    } `}
                  >
                    {buttonText}
                  </button>
                ))}
              </div>
            )}
          </div>

          {foundId ? (
            <div className="flex flex-col space-y-4">
              <span className="text-xl max-md:text-base">
                {t('가입된 이메일 아이디를 찾았습니다.')}
              </span>
              <span className="font-bold text-2xl max-md:text-xl">
                {foundId}
              </span>
            </div>
          ) : language === 'kr' ? (
            <FindIdComponent
              setEnterpriseInfo={setEnterpriseInfo}
              enterpriseInfo={enterpriseInfo}
              userType={userType}
            />
          ) : (
            <ForeignerFindId
              setForeignerInfo={setForeignerInfo}
              foreignerInfo={foreignerInfo}
            />
          )}

          <div className="flex flex-col space-y-5 items-center">
            <div className="flex w-full space-x-3">
              {foundId ? (
                <>
                  <SignupButton
                    onClick={() => navigate('/help/find-pw')}
                    color="white"
                  >
                    {t('비밀번호찾기')}
                  </SignupButton>
                  <SignupButton
                    onClick={() =>
                      screenSize < 769
                        ? navigate('/login')
                        : setLoginModal(true)
                    }
                  >
                    {t('로그인')}
                  </SignupButton>
                </>
              ) : (
                <SignupButton
                  disabled={checkEligibility()}
                  onClick={requestFindId}
                >
                  {language === 'en'
                    ? 'Confirm'
                    : userType === 'M03A0'
                    ? '휴대폰본인인증'
                    : '확인'}
                </SignupButton>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
