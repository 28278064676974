export default function OrangeArrowIcon() {
  return (
    <svg
      width="16"
      height="14"
      viewBox="0 0 16 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.172 6.03125L6.808 1.25822L8.222 0L16 6.92108L8.222 13.8422L6.808 12.5839L12.172 7.81091H0V6.03125H12.172Z"
        fill="#FF7B51"
      />
    </svg>
  );
}
