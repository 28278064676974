import React from 'react';

import { VisibilityContext } from 'react-horizontal-scrolling-menu';
import ChevronIcon from '../vector/common/ChevronIcon';

function Arrow({
  children,
  disabled,
  onClick,
}: {
  children: React.ReactNode;
  disabled: boolean;
  onClick: VoidFunction;
}) {
  return (
    <button
      disabled={disabled}
      onClick={onClick}
      style={{
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        right: '1%',
        opacity: disabled ? '0' : '1',
        userSelect: 'none',
      }}
    >
      {children}
    </button>
  );
}

export function LeftArrow() {
  const { isFirstItemVisible, scrollPrev, visibleElements, initComplete } =
    React.useContext(VisibilityContext);

  const [disabled, setDisabled] = React.useState(
    !initComplete || (initComplete && isFirstItemVisible)
  );
  React.useEffect(() => {
    // NOTE: detect if whole component visible
    if (visibleElements.length) {
      setDisabled(isFirstItemVisible);
    }
  }, [isFirstItemVisible, visibleElements]);

  return (
    <Arrow disabled={disabled} onClick={() => scrollPrev()}>
      <div className="max-md:hidden flex bg-gradient-to-r from-black/80 to-black/10 w-10 h-20 cursor-pointer absolute left-0 bottom-1/2 translate-y-1/2 items-center justify-start">
        <i className="rotate-180">
          <ChevronIcon />
        </i>
      </div>
    </Arrow>
  );
}

export function RightArrow() {
  const { isLastItemVisible, scrollNext, visibleElements } =
    React.useContext(VisibilityContext);

  const [disabled, setDisabled] = React.useState(
    !visibleElements.length && isLastItemVisible
  );
  React.useEffect(() => {
    if (visibleElements.length) {
      setDisabled(isLastItemVisible);
    }
  }, [isLastItemVisible, visibleElements]);

  return (
    <Arrow disabled={disabled} onClick={() => scrollNext()}>
      <div
        onClick={() => scrollNext()}
        className="max-md:hidden flex md:bg-gradient-to-l from-black/80 to-black/10 w-16 h-20 cursor-pointer absolute right-0 bottom-1/2 translate-y-1/2 items-center justify-end"
      >
        <ChevronIcon />
      </div>
    </Arrow>
  );
}
