import { t } from 'i18next';

export default function PloonianPreviewSection({
  loadingImage,
  requestSignUp,
  getPloonianImage,
}: {
  loadingImage: boolean;
  requestSignUp: () => void;
  getPloonianImage: string | undefined;
}) {
  return (
    <div className="bg-white w-full rounded-t-[20px] p-16 flex justify-around max-md:w-full max-md:flex-col max-md:rounded-none max-md:space-y-8 max-md:px-5 max-md:py-10 relative">
      <div className="flex justify-center">
        {loadingImage ? (
          <div className="h-[560px] max-md:h-[320px]" />
        ) : (
          <img
            className="h-[560px] max-md:h-[320px]"
            src={getPloonianImage}
            alt="ploonian preview image"
          />
        )}
      </div>
      <button
        onClick={requestSignUp}
        className="bg-gradient-to-b from-primary to-primaryTo hover:from-hoverPrimary hover:to-hoverPrimaryTo absolute right-10 max-md:right-5 bottom-10 max-md:bottom-5 text-white py-4 min-w-[122px] px-4 font-bold text-xl max-2xl:text-lg rounded-md max-md:text-base"
      >
        {t('선택완료')}
      </button>
    </div>
  );
}
