// const envList
const envList = [
  'REACT_APP_SERVICE_MODE',
  'REACT_APP_AWS_URL',
  'REACT_APP_LANGUAGE',
  'PORT',
  'WDS_SOCKET_PORT',
  'REACT_APP_API_URL',
  'REACT_APP_API_DEV_URL',
  'REACT_APP_ODCLOUD_API_URL',
  'REACT_APP_ODCLOUD_ACCESS_KEY',
  'REACT_APP_ODCLOUD_ACCESS_VALUE',
  'REACT_APP_OAUTH2_API_URL',
  'REACT_APP_OAUTH2_REDIRECT_URI',
  'REACT_APP_DEV_BASE_URL',
  'REACT_APP_BASE_URL',
  'REACT_APP_DEV_URL',
  'REACT_APP_SSO_URL',
  'REACT_APP_DEV_SSO_URL',
  'REACT_APP_SSO_REDIRECT_URI',
  'REACT_APP_SNS_REDIRECT_URI',

  'REACT_APP_PAY_DEVSERVER_URL',
  'REACT_APP_PAY_TESTSERVER_URL',
  'REACT_APP_PAY_RELSERVER_URL',

  'REACT_APP_WORKCENTER_URL',
  'REACT_APP_WORKCENTER_DEV_URL',

  'REACT_APP_PAYSYS_AUTH_USER',
  'REACT_APP_PAYSYS_AUTH_PW',
] as const;

export const ENV = {
  REACT_APP_SERVICE_MODE: process.env.REACT_APP_SERVICE_MODE,
  REACT_APP_AWS_URL: process.env.REACT_APP_AWS_URL,
  REACT_APP_LANGUAGE: process.env.REACT_APP_LANGUAGE,
  PORT: process.env.PORT,
  WDS_SOCKET_PORT: process.env.WDS_SOCKET_PORT,
  REACT_APP_DEV_BASE_URL: process.env.REACT_APP_DEV_BASE_URL,
  REACT_APP_BASE_URL: process.env.REACT_APP_BASE_URL,
  REACT_APP_API_URL: process.env.REACT_APP_API_URL,
  REACT_APP_API_DEV_URL: process.env.REACT_APP_API_DEV_URL,
  REACT_APP_ODCLOUD_API_URL: process.env.REACT_APP_ODCLOUD_API_URL,
  REACT_APP_ODCLOUD_ACCESS_KEY: process.env.REACT_APP_ODCLOUD_ACCESS_KEY,
  REACT_APP_ODCLOUD_ACCESS_VALUE: process.env.REACT_APP_ODCLOUD_ACCESS_VALUE,
  REACT_APP_OAUTH2_API_URL: process.env.REACT_APP_OAUTH2_API_URL,
  REACT_APP_OAUTH2_REDIRECT_URI: process.env.REACT_APP_OAUTH2_REDIRECT_URI,

  REACT_APP_DEV_URL: process.env.REACT_APP_DEV_URL,
  REACT_APP_SSO_URL: process.env.REACT_APP_SSO_URL,
  REACT_APP_DEV_SSO_URL: process.env.REACT_APP_DEV_SSO_URL,
  REACT_APP_SSO_REDIRECT_URI: process.env.REACT_APP_SSO_REDIRECT_URI,
  REACT_APP_SNS_REDIRECT_URI: process.env.REACT_APP_SNS_REDIRECT_URI,

  REACT_APP_WORKCENTER_URL: process.env.REACT_APP_WORKCENTER_URL,
  REACT_APP_WORKCENTER_DEV_URL: process.env.REACT_APP_WORKCENTER_DEV_URL,

  REACT_APP_PAY_DEVSERVER_URL: process.env.REACT_APP_PAY_DEVSERVER_URL,
  REACT_APP_PAY_TESTSERVER_URL: process.env.REACT_APP_TESTSERVER_URL,
  REACT_APP_PAY_RELSERVER_URL: process.env.REACT_APP_PAY_RELSERVER_URL,

  REACT_APP_PAYSYS_AUTH_USER: process.env.REACT_APP_PAYSYS_AUTH_USER,
  REACT_APP_PAYSYS_AUTH_PW: process.env.REACT_APP_RELREACT_APP_PAYSYS_AUTH_PW,
} as { [key in (typeof envList)[number]]: string };
