import CreditTotalIcon from '../components/vector/total/CreditTotalIcon';
import MyPloonianIcon from '../components/vector/total/MyPloonianIcon';
import OneIdIcon from '../components/vector/total/OneIdIcon';
import StepFiveIcon from '../components/vector/total/StepFiveIcon';
import StepFourIcon from '../components/vector/total/StepFourIcon';
import StepOneIcon from '../components/vector/total/StepOneIcon';
import StepThreeIcon from '../components/vector/total/StepThreeIcon';
import StepTwoIcon from '../components/vector/total/StepTwoIcon';

export const bodyArray = [
  {
    icon: <OneIdIcon />,
    title: '다양한 서비스를 하나의 ID로!',
    content:
      '스튜디오, 워크센터, 개인용 인공지능 비서 등 다양한 플루닛 서비스의 혜택과 회원 서비스를 간편하게 이용하실 수 있습니다.',
  },
  {
    icon: <CreditTotalIcon />,
    title: '나의 활동과 크레딧을 한눈에!',
    content:
      '나의 서비스 이용 정보를 모아보는 것은 물론, 크레딧 사용내역과 요금제 구매 현황을 한눈에 관리할 수 있습니다.',
  },
  {
    icon: <MyPloonianIcon />,
    title: '나만을 위한 전담 플루니안',
    content:
      '플루니안은 회원님이 사용하시는 서비스와 궁금한 모든 사항들을 가이드해주고 직원으로도 채용하실 수 있습니다.',
  },
];

export const bottomArray = [
  {
    icon: <StepOneIcon />,
    title: '통합회원 전환 신청',
  },
  {
    icon: <StepTwoIcon />,
    title: '본인인증',
  },
  {
    icon: <StepThreeIcon />,
    title: '회원정보입력',
  },
  {
    icon: <StepFourIcon />,
    title: '플루니안 설정',
  },
  {
    icon: <StepFiveIcon />,
    title: '통합회원 전환 완료',
  },
];
