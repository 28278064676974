export default function AppleLoginIcon() {
  return (
    <svg
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="50" height="50" rx="25" fill="black" />
      <path
        d="M25.1407 20.2831C24.2647 20.2831 22.9087 19.2871 21.4807 19.3231C19.5967 19.3471 17.8687 20.4151 16.8967 22.1071C14.9407 25.5031 16.3927 30.5191 18.3007 33.2791C19.2367 34.6231 20.3407 36.1351 21.8047 36.0871C23.2087 36.0271 23.7367 35.1751 25.4407 35.1751C27.1327 35.1751 27.6127 36.0871 29.1007 36.0511C30.6127 36.0271 31.5727 34.6831 32.4967 33.3271C33.5647 31.7671 34.0087 30.2551 34.0327 30.1711C33.9967 30.1591 31.0927 29.0431 31.0567 25.6831C31.0327 22.8751 33.3487 21.5311 33.4567 21.4711C32.1367 19.5391 30.1087 19.3231 29.4007 19.2751C27.5527 19.1311 26.0047 20.2831 25.1407 20.2831ZM28.2607 17.4511C29.0407 16.5151 29.5567 15.2071 29.4127 13.9111C28.2967 13.9591 26.9527 14.6551 26.1487 15.5911C25.4287 16.4191 24.8047 17.7511 24.9727 19.0231C26.2087 19.1191 27.4807 18.3871 28.2607 17.4511Z"
        fill="white"
      />
    </svg>
  );
}
