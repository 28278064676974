import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  ACCESS_TOKEN,
  ACCESS_TOKEN_EXPIRE,
  REFRESH_TOKEN,
  REFRESH_TOKEN_EXPIRE,
} from '../../constants';

const OAuth2Redirect = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  useEffect(() => {
    if (params.get(ACCESS_TOKEN)) {
      localStorage.setItem(ACCESS_TOKEN, params.get(ACCESS_TOKEN));
      localStorage.setItem(REFRESH_TOKEN, params.get(REFRESH_TOKEN));
      localStorage.setItem(
        ACCESS_TOKEN_EXPIRE,
        params.get(ACCESS_TOKEN_EXPIRE)
      );
      localStorage.setItem(
        REFRESH_TOKEN_EXPIRE,
        params.get(REFRESH_TOKEN_EXPIRE)
      );
      navigate('/signup/step02');
    } else {
      navigate('/');
    }
  }, []);
  return null;
};

export default OAuth2Redirect;
