export const numberException = ['e', 'E', '+', '-', '.'];

export const passwordRegex = /^(?=.*[a-zA-Z])(?=.*[!@$^*-])(?=.*[0-9]).{8,16}$/;
export const emailRegex =
  /([\w-.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;

export default function useValidate(password: string) {
  if (!password) {
    return;
  }
  let phrase;

  !passwordRegex.test(password) &&
    (phrase = '영문, 특수문자, 숫자 조합을 확인해주세요.');
  password.length < 8 && (phrase = '비밀번호를 8자 이상 입력해주세요.');
  return phrase;
}
