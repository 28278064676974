import { SetStateAction, useEffect, useState } from 'react';
import { getBanner } from '../../../services/PublicService';
import { fileBaseURL, siteLangCd } from '../../../services/defaultClient';
import CloseIcon from '../../vector/common/CloseIcon';

export default function MainBanner({
  banner,
  setBanner,
}: {
  banner: boolean;
  setBanner: React.Dispatch<SetStateAction<boolean>>;
}) {
  const [bannerData, setBannerData] = useState({
    backgroundColorVal1: '',
    backgroundColorVal2: '',
    backgroundTypeCd: '',
    bannerCtnt: '',
    bannerTit: '',
    bannerUuid: '',
    bannerPath: '',
    bannerLink: '',
  });
  const location = window.location.pathname;

  useEffect(() => {
    location !== '/' && setBanner(false);
  }, [location]);

  useEffect(() => {
    getBanner({ siteLangCd })
      .then((res) => {
        if (res.data.data.pageList.length) {
          setBannerData(res.data.data.pageList[0]);
          setBanner(true);
        } else {
          setBanner(false);
        }
      })
      .catch(console.log);
  }, []);

  return (
    <>
      {banner && bannerData.bannerUuid ? (
        <section
          onClick={() =>
            bannerData.bannerLink && window.open(bannerData.bannerLink)
          }
          style={{
            backgroundImage: `linear-gradient(to right, #${bannerData.backgroundColorVal1}, #${bannerData.backgroundColorVal2})`,
          }}
          className={`max-md:hidden fixed max-w-screen w-full h-[100px] z-50 py-7 ${
            bannerData.bannerPath && 'bg-black'
          } ${bannerData.bannerLink && 'cursor-pointer'}`}
        >
          <div className="relative max-w-[1920px] w-full mx-auto flex justify-center">
            {bannerData.bannerPath ? (
              <div className="flex h-full items-center justify-center text-white text-[28px]">
                <div
                  className="max-w-[1200px] w-full h-[100px] bg-cover bg-center bg-no-repeat"
                  style={{
                    backgroundImage: `url(${
                      fileBaseURL + bannerData.bannerPath
                    })`,
                  }}
                />
              </div>
            ) : (
              <div className="flex h-full items-center justify-center text-white text-[28px]">
                <span
                  dangerouslySetInnerHTML={{ __html: bannerData.bannerCtnt }}
                />
              </div>
            )}

            <button
              onClick={() => setBanner(false)}
              className="absolute bottom-1/2 translate-y-1/2 right-10"
            >
              <CloseIcon isDark isBig />
            </button>
          </div>
        </section>
      ) : null}
    </>
  );
}
