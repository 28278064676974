import { Dispatch, SetStateAction, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { sendEmailRequest } from '../../../services/RegCertService';
import { siteLangCd } from '../../../services/defaultClient';
import { EnterpriseInfoType } from '../../../views/help/dormant';
import Loader from '../../common/Loader';
import InformModal from '../../common/modal/InformModal';
import AuthTimer from '../../hooks/AuthTimer';
import { emailRegex, numberException } from '../../hooks/useValidate';

const methodButtonArray: {
  buttonText: '사업자등록번호' | '이메일(아이디)';
  value: 'businessInfo' | 'email';
}[] = [
  {
    buttonText: '사업자등록번호',
    value: 'businessInfo',
  },
  {
    buttonText: '이메일(아이디)',
    value: 'email',
  },
];
const inputStyle =
  'w-full h-full py-4 px-3 rounded-lg border border-borderLightGray focus:border-primary max-md:p-3 max-md:placeholder:text-sm';
const divStyle = 'flex flex-col space-y-2 max-2xl:text-base text-lg';
export default function DormantEnterprise({
  enterpriseInfo,
  setEnterpriseInfo,
  method,
  setMethod,
  enterpriseEmail,
  setEnterpriseEmail,
  emailCheckInfo,
  setEmailCheckInfo,
  requestWakeUpAccount,
}: {
  enterpriseInfo: EnterpriseInfoType;
  setEnterpriseInfo: React.Dispatch<SetStateAction<EnterpriseInfoType>>;
  method: 'businessInfo' | 'email';
  setMethod: Dispatch<SetStateAction<'businessInfo' | 'email'>>;
  enterpriseEmail: string;
  setEnterpriseEmail: Dispatch<SetStateAction<string>>;
  emailCheckInfo: {
    authSendUuid: string;
    authCode: string;
  };
  setEmailCheckInfo: Dispatch<
    SetStateAction<{
      authSendUuid: string;
      authCode: string;
    }>
  >;
  requestWakeUpAccount: () => void;
}) {
  return (
    <div className="text-left flex flex-col space-y-10 max-md:space-y-5">
      <section className="w-full flex">
        {methodButtonArray.map(({ buttonText, value }, idx) => (
          <button
            key={`method-${idx}`}
            onClick={() => setMethod(value)}
            className={`w-full py-4 max-md:py-3 md:text-lg border duration-150 ${
              idx === 0 ? 'rounded-l-lg' : 'rounded-r-lg'
            } ${
              value === method
                ? 'bg-black2 text-white border-black2'
                : 'border-[#d7d7d7]'
            }`}
          >
            {buttonText}
          </button>
        ))}
      </section>
      {method === 'businessInfo' ? (
        <BusinessMethod
          enterpriseInfo={enterpriseInfo}
          setEnterpriseInfo={setEnterpriseInfo}
        />
      ) : (
        <EmailMethod
          enterpriseEmail={enterpriseEmail}
          setEnterpriseEmail={setEnterpriseEmail}
          emailCheckInfo={emailCheckInfo}
          setEmailCheckInfo={setEmailCheckInfo}
          requestWakeUpAccount={requestWakeUpAccount}
        />
      )}
    </div>
  );
}

function EmailMethod({
  enterpriseEmail,
  setEnterpriseEmail,
  emailCheckInfo,
  setEmailCheckInfo,
  requestWakeUpAccount,
}: {
  enterpriseEmail: string;
  setEnterpriseEmail: Dispatch<SetStateAction<string>>;
  emailCheckInfo: {
    authSendUuid: string;
    authCode: string;
  };
  setEmailCheckInfo: Dispatch<
    SetStateAction<{
      authSendUuid: string;
      authCode: string;
    }>
  >;
  requestWakeUpAccount: () => void;
}) {
  const [modal, setModal] = useState<{
    isOpen: boolean;
    phrase: string;
    secondPhrase?: string;
    content?: string;
  }>({
    isOpen: false,
    phrase: '',
    secondPhrase: '',
    content: '',
  });
  const [isSent, setIsSent] = useState(false);
  const [time, setTime] = useState(600);
  const [loading, setLoading] = useState(false);
  const [isAuthorized, setIsAuthorized] = useState(false);
  const navigate = useNavigate();
  const sendAuthEmail = async () => {
    if (!emailRegex.test(enterpriseEmail)) {
      setModal({
        isOpen: true,
        phrase: '이메일 주소가 올바르지 않습니다.',
        secondPhrase: '이메일 주소를 정확하게 입력해주세요.',
      });
      return;
    }
    setLoading(true);
    sendEmailRequest({
      siteLangCd,
      memberEmail: enterpriseEmail,
    })
      .then((res) => {
        const resData = res.data.data;
        setModal({
          isOpen: true,
          phrase: '인증메일이 발송되었습니다.',
          content: `${enterpriseEmail}으로 발송된 인증메일의 인증코드를 유효시간 10분 안에 입력해주세요.`,
        });
        setIsSent(true);
        setTime(600);
        setEmailCheckInfo((prev) => ({
          ...prev,
          authSendUuid: resData.authSendUuid,
        }));
      })
      .catch((err) => {
        alert(err);
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      <Loader loading={loading} />
      <InformModal
        modal={modal.isOpen}
        phrase={modal.phrase}
        secondPhrase={modal.secondPhrase}
        content={modal.content}
        closeModal={() => {
          if (modal.phrase === '휴면 상태가 해제되었습니다.') {
            navigate('/');
          } else {
            setModal((prev) => ({
              ...prev,
              isOpen: false,
            }));
          }
        }}
      />
      <div className={divStyle}>
        <span className="md:text-lg">이메일(아이디)</span>
        <div className="flex space-x-3">
          <input
            maxLength={60}
            onKeyDown={(e) => e.key === 'Enter' && sendAuthEmail()}
            className={`${
              isAuthorized
                ? 'bg-gray5 border-[#27AE60]'
                : 'border-borderLightGray'
            } w-full h-full py-4 px-3 rounded-lg border focus:border-primary max-md:p-3 max-md:placeholder:text-sm`}
            onChange={(e) => setEnterpriseEmail(e.target.value)}
            disabled={isAuthorized}
            type="email"
            placeholder="이메일 주소를 입력해주세요."
          />
          <button
            onClick={sendAuthEmail}
            disabled={isAuthorized}
            className={`min-w-[140px] max-2xl:min-w-[120px] py-3 max-md:text-sm border border-black rounded-lg hover:bg-slate-100 ${
              isAuthorized && 'border-borderLightGray text-gray2 hover:bg-white'
            }`}
          >
            {isSent ? '인증코드 재발송' : '인증코드 발송'}
          </button>
        </div>
        {isSent && !isAuthorized && (
          <div className="flex space-x-3">
            <div className="w-full relative">
              <input
                onKeyDown={(e) => e.key === 'Enter' && requestWakeUpAccount()}
                className={inputStyle}
                type="text"
                maxLength={6}
                value={emailCheckInfo.authCode}
                onChange={(e) =>
                  setEmailCheckInfo((prev) => ({
                    ...prev,
                    authCode: e.target.value,
                  }))
                }
                placeholder="인증코드 6자리를 입력해주세요."
              />
              {!isAuthorized && (
                <div className="absolute right-5 bottom-1/2 translate-y-1/2">
                  <AuthTimer time={time} setTime={setTime} />
                </div>
              )}
            </div>
            <button
              onClick={requestWakeUpAccount}
              className="min-w-[140px] max-2xl:min-w-[120px] py-3 max-2xl:text-sm bg-black text-white rounded-lg hover:bg-opacity-80"
            >
              인증코드 확인
            </button>
          </div>
        )}
        <span
          className={`${
            isAuthorized ? 'text-[#27AE60]' : 'text-textGray'
          } max-2xl:text-xs text-sm`}
        >
          {isAuthorized
            ? '이메일 인증이 완료되었습니다.'
            : isSent
            ? '이메일로 전송된 인증코드를 확인해주세요.'
            : ''}
        </span>
      </div>
    </>
  );
}

function BusinessMethod({
  enterpriseInfo,
  setEnterpriseInfo,
}: {
  enterpriseInfo: EnterpriseInfoType;
  setEnterpriseInfo: React.Dispatch<SetStateAction<EnterpriseInfoType>>;
}) {
  return (
    <>
      <div className="space-y-[10px]">
        <span className="text-lg max-md:text-base">회사명</span>
        <input
          className="w-full py-4 px-3 rounded-lg border border-borderLightGray focus:border-primary max-md:p-3"
          value={enterpriseInfo.companyNm}
          onChange={(e) =>
            setEnterpriseInfo((prev) => ({
              ...prev,
              companyNm: e.target.value,
            }))
          }
          maxLength={20}
          placeholder="회사명을 입력해주세요."
          type="text"
        />
      </div>
      <div className="space-y-7">
        <div className="space-y-[10px]">
          <span className="text-lg max-md:text-base">사업자등록번호</span>
          <input
            onChange={(e) => {
              setEnterpriseInfo((prev) => ({
                ...prev,
                memberBrno: e.target.value,
              }));
            }}
            value={enterpriseInfo.memberBrno}
            onKeyDown={(e) => {
              numberException.includes(e.key) && e.preventDefault();
              enterpriseInfo.memberBrno.length &&
                enterpriseInfo.memberBrno.length > 9 &&
                e.key !== 'Backspace' &&
                e.key !== 'Tab' &&
                e.preventDefault();
            }}
            className="w-full py-4 px-3 rounded-lg border border-borderLightGray focus:border-primary max-md:p-3"
            placeholder="숫자만 10자리 입력해주세요."
            type="number"
          />
        </div>
        <div className="space-y-[10px]">
          <span className="text-lg max-md:text-base">개업일자</span>
          <input
            onChange={(e) => {
              setEnterpriseInfo((prev) => ({
                ...prev,
                establishYmd: e.target.value,
              }));
            }}
            onKeyDown={(e) => {
              numberException.includes(e.key) && e.preventDefault();
              enterpriseInfo.establishYmd.length &&
                enterpriseInfo.establishYmd.length > 7 &&
                e.key !== 'Backspace' &&
                e.key !== 'Tab' &&
                e.preventDefault();
            }}
            value={enterpriseInfo.establishYmd}
            className="w-full py-4 px-3 rounded-lg border border-borderLightGray focus:border-primary max-md:p-3"
            placeholder="'-'없이 8자리를 입력해주세요."
            type="number"
          />
        </div>
        <div className="space-y-[10px]">
          <span className="text-lg max-md:text-base">대표자명</span>
          <input
            onChange={(e) => {
              setEnterpriseInfo((prev) => ({
                ...prev,
                ceoFnm: e.target.value,
              }));
            }}
            maxLength={20}
            value={enterpriseInfo.ceoFnm}
            className="w-full py-4 px-3 rounded-lg border border-borderLightGray focus:border-primary max-md:p-3"
            placeholder="대표자명을 입력해주세요."
            type="text"
          />
        </div>
      </div>
    </>
  );
}
