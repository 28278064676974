export default function PlayIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_868_79308)">
        <path
          d="M6.49614 4.85494C5.82948 4.47399 5 4.95536 5 5.72318V18.2768C5 19.0446 5.82948 19.526 6.49614 19.1451L17.4806 12.8682C18.1524 12.4843 18.1524 11.5157 17.4806 11.1318L6.49614 4.85494Z"
          fill="#222222"
        />
      </g>
      <defs>
        <clipPath id="clip0_868_79308">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
