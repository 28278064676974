export default function DeleteIcon() {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.0835 6.75721L11.1427 2.698L12.3022 3.85754L8.24304 7.91675L12.3022 11.976L11.1427 13.1355L7.0835 9.07629L3.02429 13.1355L1.86475 11.976L5.92396 7.91675L1.86475 3.85754L3.02429 2.698L7.0835 6.75721Z"
        fill="#222222"
      />
    </svg>
  );
}
