export default function ChatIcon() {
  return (
    <svg
      className="w-[100px] h-[100px] max-md:w-[72px] max-md:h-[72px]"
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="50" cy="50" r="50" fill="#222222" />
      <path
        d="M70 41C70 33.8203 64.1797 28 57 28C52.8343 28 49.1262 29.9646 46.747 33.0144C56.8942 33.4067 65 41.7568 65 52C65 52.2206 64.9962 52.4404 64.9888 52.6592L65.6533 52.837C67.586 53.3541 69.3541 51.586 68.837 49.6533L68.5822 48.7012C68.3765 47.9321 68.5002 47.1196 68.831 46.3954C69.5817 44.752 70 42.9248 70 41Z"
        fill="url(#paint0_linear_1628_100537)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M62 52C62 60.8366 54.8366 68 46 68C43.529 68 41.1888 67.4398 39.0995 66.4396C38.3814 66.0958 37.5679 65.9751 36.7988 66.1809L34.3467 66.837C32.414 67.3541 30.6459 65.586 31.163 63.6533L31.8191 61.2012C32.0249 60.4321 31.9042 59.6186 31.5604 58.9005C30.5602 56.8112 30 54.471 30 52C30 43.1634 37.1634 36 46 36C54.8366 36 62 43.1634 62 52ZM39 54C40.1046 54 41 53.1046 41 52C41 50.8954 40.1046 50 39 50C37.8954 50 37 50.8954 37 52C37 53.1046 37.8954 54 39 54ZM46 54C47.1046 54 48 53.1046 48 52C48 50.8954 47.1046 50 46 50C44.8954 50 44 50.8954 44 52C44 53.1046 44.8954 54 46 54ZM53 54C54.1046 54 55 53.1046 55 52C55 50.8954 54.1046 50 53 50C51.8954 50 51 50.8954 51 52C51 53.1046 51.8954 54 53 54Z"
        fill="url(#paint1_linear_1628_100537)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1628_100537"
          x1="30"
          y1="28"
          x2="59.2169"
          y2="74.7404"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF478B" />
          <stop offset="1" stopColor="#FFBB54" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1628_100537"
          x1="30"
          y1="28"
          x2="59.2169"
          y2="74.7404"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF478B" />
          <stop offset="1" stopColor="#FFBB54" />
        </linearGradient>
      </defs>
    </svg>
  );
}
