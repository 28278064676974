import 'aos/dist/aos.css';
import { t } from 'i18next';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import SwiperCore, { Navigation, Pagination } from 'swiper';
import 'swiper/css/pagination';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';
import {
  howSectionCardArray,
  metahumanProsArray,
  metahumanUsesArray,
  topSectionArray,
} from '../../array/metahumanArray';
import BottomBanner from '../../components/common/BottomBanner';
import SubPageDot from '../../components/common/SubpageDot';
import SubpageTitle from '../../components/common/SubpageTitle';
import DoubleDotIcon from '../../components/vector/metahuman/DoubleDotIcon';
import i18n from '../../locale/i18n';
import { userinfoAtom } from '../../store';
import { isLogin } from '../../utils/common';

SwiperCore.use([Navigation, Pagination]);

export default function Metahuman() {
  const componentRef = useRef(null);
  const { t } = useTranslation();
  const userInfo = useRecoilValue(userinfoAtom);
  useEffect(() => {
    i18n.changeLanguage(userInfo.language);
    var AOS = require('aos');
    AOS.init({
      duration: 1000,
    });
  }, []);

  return (
    <main className="bg-black pt-[200px] max-md:pt-[98px] pb-10">
      {/* {!isLogin && (
        <ShortcutBanner
          firstButton={t('나만의 메타휴먼 만들기')}
          secondButton={null}
          firstLink="/signup"
          secondLink=""
          componentRef={componentRef}
          title={t('플루닛 메타휴먼')}
        />
      )} */}

      <div className="max-w-[1600px] max-2xl:max-w-[1200px] mx-auto space-y-48 max-md:space-y-20">
        <MainSection isLogin={isLogin} />
        <div className="max-md:pl-5" ref={componentRef} data-aos="fade-up">
          <HowSection />
        </div>
        <div className="max-md:px-5" data-aos="fade-up">
          <ExampleSection />
        </div>
        <div className="max-md:px-5">
          <BottomBanner
            topText={t('시공간 언어의 제약을 넘어')}
            bottomText={t('고객님의 니즈에 맞는 플루닛 메타휴먼을 만나보세요')}
            serviceName="Metahuman"
          />
        </div>
      </div>
    </main>
  );
}

function MainSection({ isLogin }: { isLogin: boolean }) {
  return (
    <section className="overflow-y-hidden">
      <div className="max-w-[1600px] mx-auto z-10 flex items-center justify-between max-md:items-end max-md:overflow-x-hidden max-md:pl-5 overflow-y-hidden">
        <section data-aos="fade-right flex flex-col">
          <div className="w-full min-h-fit flex flex-col items-start space-y-24 max-md:space-y-10">
            <div className="space-y-10 max-md:space-y-0">
              <SubpageTitle>Metahuman</SubpageTitle>
              <div className="space-y-3 font-extralight text-white text-5xl max-2xl:text-4xl max-md:text-2xl max-md:space-y-1 max-md:!mt-2">
                <h1 className="leading-snug max-md:!leading-9">
                  {t('전세계 언어와 공간의 제약을 넘어')}
                  <strong className="font-bold block">
                    {t('놀라운 사용자 경험을 제공합니다.')}
                  </strong>
                </h1>
              </div>
              <p className="text-white/70 text-xl font-extralight max-2xl:text-lg max-md:text-base mt-8 max-w-xl max-2xl:max-w-lg max-md:pr-10 max-md:!mt-4">
                {t(
                  'AI메타휴먼은 고객의 목소리를 귀담아 듣고 대화형 AI, 챗봇, GPT와 연계해 상황과 니즈에 따른 유연한 답변을 제공합니다. 모바일 앱, 웹 브라우저, 키오스크 또는 가상공간 등 고객이 존재하는 다양한 공간에서 AI메타휴먼을 만나보세요.'
                )}
              </p>
            </div>

            {/* {!isLogin && (
              <Link className="w-full" to="/signup">
                <HomeButton icon={true}>
                  {t('나만의 메타휴먼 만들기')}
                </HomeButton>
              </Link>
            )} */}
          </div>

          <div className="hidden max-md:block w-screen mt-[60px]">
            <Swiper slidesPerView={1.2}>
              {topSectionArray.map(
                ({ imgPath, keyPhrase, description }, idx) => (
                  <SwiperSlide key={`img-${idx}`}>
                    <div
                      className="h-[480px] w-72 rounded-xl bg-center bg-no-repeat bg-cover relative"
                      style={{ backgroundImage: `url(${imgPath})` }}
                    >
                      <div className="w-full h-60 bg-gradient-to-t from-black to-black/0 absolute bottom-0" />
                      <div className="flex flex-col absolute justify-end space-y-3 h-full text-white px-6 py-7">
                        <h3 className="font-bold text-xl">{keyPhrase}</h3>
                        <span className="block font-extralight text-white/60 text-sm w-48">
                          {t(description)}
                        </span>
                      </div>
                    </div>
                  </SwiperSlide>
                )
              )}
            </Swiper>
          </div>

          <div
            data-aos-delay={`${window.innerWidth < 800 ? '0' : '700'}`}
            data-aos-duration={`${window.innerWidth < 800 ? '1000' : '2000'}`}
            data-aos="fade-up"
            className="w-full min-h-fit flex flex-col items-start space-y-24 max-md:space-y-10 mt-40 max-md:mt-[60px]"
          >
            <div className="flex flex-col space-y-14 max-md:space-y-5">
              <div className="text-5xl text-white max-2xl:text-4xl max-md:text-2xl flex max-md:flex-col max-md:space-y-1 font-extralight">
                <SubPageDot />
                <div className="max-md:flex max-md:flex-col leading-tight max-md:leading-[32px]">
                  <span className="font-bold whitespace-pre-wrap">
                    {t('플루닛 메타휴먼,')}
                  </span>
                  <span>{t(' 이런 장점이 있습니다.')}</span>
                </div>
              </div>
              <ul className="space-y-10 max-md:space-y-7 max-md:pr-5">
                {metahumanProsArray.map((list, idx) => (
                  <li
                    key={`prosList-${idx}`}
                    className={`metaProList-${idx} text-white flex items-center text-xl max-2xl:text-lg`}
                  >
                    <i className="mr-5 max-md:mr-4">
                      <DoubleDotIcon />
                    </i>
                    <span>{t(list)}</span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </section>
        <section
          data-aos-delay="300"
          data-aos-duration="2000"
          data-aos="fade-up"
          className="relative grid grid-cols-2 gap-x-10 gap-y-5 min-w-[46%] max-md:hidden"
        >
          {topSectionArray.map(({ imgPath, keyPhrase, description }, idx) => (
            <div
              key={`phrase-${idx}`}
              className={`h-[480px] w-full rounded-xl bg-center bg-no-repeat bg-cover relative ${
                (idx === 0 || idx === 2) && 'mt-10'
              }`}
              style={{ backgroundImage: `url(${imgPath})` }}
            >
              <div className="w-full h-60 bg-gradient-to-t from-black/70 to-black/0 absolute bottom-0" />
              <div className="flex flex-col absolute justify-end space-y-3 h-full text-white px-6 py-7">
                <h3 className="font-bold text-2xl max-2xl:text-xl">
                  {keyPhrase}
                </h3>
                <span className="block font-extralight text-white/60 max-2xl:text-sm w-48">
                  {t(description)}
                </span>
              </div>
            </div>
          ))}
        </section>
      </div>
    </section>
  );
}

function HowSection() {
  return (
    <section className="space-y-14 max-md:space-y-5">
      <div className="flex flex-col space-y-14">
        <div className="space-y-5 max-md:pr-5">
          <div className="text-5xl text-white max-2xl:text-4xl max-md:text-2xl flex max-md:flex-col max-md:space-y-1 font-extralight">
            <SubPageDot />
            <h1>
              <strong className="font-bold">{t('플루닛 메타휴먼, ')}</strong>
              <br className="md:hidden" />
              {t('어떻게 만들어질까요?')}
            </h1>
          </div>
          <h3 className="text-white/60 text-lg max-2xl:text-base font-extralight md:ml-[30px]">
            {t(
              '놀라운 마법과 같은 기술들이 쉽고 빠른 제작을 가능하게 해줍니다.'
            )}
          </h3>
        </div>
        <section className="max-md:w-full max-md:pr-0">
          <Swiper
            className="max-md:pr-5"
            breakpoints={{
              768: {
                freeMode: true,
                slidesPerView: 4,
                spaceBetween: 20,
              },
            }}
            spaceBetween={20}
            slidesPerView={1.2}
          >
            {howSectionCardArray.map(({ icon, keyPhrase, text }, idx) => (
              <SwiperSlide key={`img-${idx}`}>
                <div className="text-white max-md:w-full max-2xl:h-[480px] h-[520px] max-md:h-[470px] px-10 pt-12 pb-10 max-2xl:px-8 max-2xl:py-7 max-md:px-6 bg-[#141414] rounded-[20px] border border-black2 flex flex-col space-y-16">
                  <div className="space-y-2">
                    <h2 className="mb-2 font-bold text-transparent text-sm bg-clip-text bg-gradient-to-b from-[#FF4E83] to-[#FFBB54]">
                      STEP {idx + 1}
                    </h2>
                    <h3 className="font-bold text-2xl max-2xl:text-xl">
                      {t(keyPhrase)}
                    </h3>
                    <span className="block text-white/60 max-2xl:text-sm font-extralight h-14 max-md:h-10">
                      {t(text)}
                    </span>
                  </div>
                  <i className="flex justify-end items-end h-full">{icon}</i>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </section>
      </div>
    </section>
  );
}

function ExampleSection() {
  return (
    <section className="space-y-14 max-md:space-y-5 text-white">
      <h1 className="text-5xl text-white max-2xl:text-4xl max-md:text-2xl flex font-extralight max-md:flex-col max-md:space-y-1">
        <SubPageDot />

        <span className="mr-3 max-md:mr-2">
          <strong className="font-bold">
            {t('사용자 요구사항과 비즈니스에 최적화된 ')}
          </strong>{' '}
          {t('맞춤형 메타휴먼')}
        </span>
      </h1>
      <div className="flex flex-col space-y-28 max-2xl:space-y-20 max-md:space-y-14">
        {metahumanUsesArray.map((use, idx) => (
          <section
            key={`use-${idx}`}
            className={`flex max-md:flex-col max-md:space-x-0 ${
              idx === 1
                ? 'flex-row-reverse'
                : 'flex-row space-x-28 max-2xl:space-x-24'
            }`}
          >
            <div
              className={`w-1/2 max-md:w-full ${
                idx === 1 && 'ml-28 max-md:ml-0 max-2xl:ml-24'
              }`}
            >
              <Swiper
                spaceBetween={50}
                slidesPerView={1}
                pagination={{ clickable: true }}
              >
                {use.images.map(({ imgPath }, idx) => (
                  <SwiperSlide key={`img-${idx}`}>
                    <div className="space-y-8">
                      <div
                        style={{ backgroundImage: `url(${imgPath})` }}
                        className={`max-md:w-full h-[476px] max-2xl:h-96 max-md:h-[220px] rounded-2xl max-md:rounded-md bg-no-repeat bg-center bg-contain`}
                      />
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
            <div className="w-1/2 max-md:w-full flex flex-col justify-center space-y-10 max-md:space-y-5 max-md:mt-5">
              <div>
                <h3 className="font-bold text-transparent text-2xl max-2xl:text-xl max-md:text-base bg-clip-text bg-gradient-to-br from-[#FF4E83] to-[#FFBB54]">
                  {use.category}
                </h3>
                <h1 className="mt-6 max-md:mt-4 font-bold text-3xl max-2xl:text-2xl max-md:text-lg">
                  {t(use.title)}
                </h1>
                <p className="mt-4 max-md:mt-3 text-xl max-2xl:text-lg max-md:text-base text-white/60 font-extralight">
                  {t(use.paragraph)}
                </p>
              </div>
              <div className="flex flex-col space-y-2 text-xl max-2xl:text-lg max-md:text-base">
                <h3 className="text-primary font-bold ">
                  {t('활용 가능 분야')}
                </h3>
                <span className="text-white/60 font-extralight">
                  {t(use.field)}
                </span>
              </div>
            </div>
          </section>
        ))}
      </div>
    </section>
  );
}
