export function pushToDataLayer(type) {
  const dataLayer = window.dataLayer;
  if (type === 'signup') {
    dataLayer &&
      dataLayer.push({
        event: 'plevent',
        ev_name: 'sign_up',
      });
  } else if (type === 'login') {
    dataLayer &&
      dataLayer.push({
        event: 'plevent',
        ev_name: 'login',
      });
  } else {
    return;
  }
}
