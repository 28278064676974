import { SetStateAction } from 'react';
import { ForeignerInfoType } from '../../../views/help/find-id';
import { numberException } from '../../hooks/useValidate';
import NationComboBox from '../../signup/NationComboBox';

export default function ForeignerFindId({
  foreignerInfo,
  setForeignerInfo,
}: {
  foreignerInfo: ForeignerInfoType;
  setForeignerInfo: React.Dispatch<SetStateAction<ForeignerInfoType>>;
}) {
  return (
    <div>
      <div className="text-left flex flex-col space-y-10 max-md:space-y-5">
        <div className="space-y-3">
          <span className="text-lg max-md:text-base">First Name</span>
          <input
            className="w-full py-4 px-3 rounded-lg border border-borderLightGray focus:border-primary max-md:p-3"
            value={foreignerInfo.memberFnm1}
            onChange={(e) =>
              setForeignerInfo((prev) => ({
                ...prev,
                memberFnm1: e.target.value,
              }))
            }
            placeholder="Please enter your first name."
            type="text"
            maxLength={20}
          />
        </div>
        <div className="space-y-3">
          <span className="text-lg max-md:text-base">Last Name</span>
          <input
            onChange={(e) => {
              setForeignerInfo((prev) => ({
                ...prev,
                memberFnm2: e.target.value,
              }));
            }}
            value={foreignerInfo.memberFnm2}
            maxLength={20}
            className="w-full py-4 px-3 rounded-lg border border-borderLightGray focus:border-primary max-md:p-3"
            placeholder="Please enter your first name."
            type="text"
          />
        </div>
        <div className="space-y-3">
          <div className="font-medium flex items-center space-x-1">
            <span>Phone Number</span>
          </div>
          <NationComboBox setForeignerInfo={setForeignerInfo} />
          <div className="flex space-x-3">
            <input
              className="w-full py-4 px-3 rounded-lg border border-borderLightGray focus:border-primary max-md:p-3"
              type="number"
              value={foreignerInfo.mobileTelno}
              onKeyDown={(e) => {
                numberException.includes(e.key) && e.preventDefault();
                foreignerInfo.mobileTelno.length &&
                  foreignerInfo.mobileTelno.length > 14 &&
                  e.key !== 'Backspace' &&
                  e.key !== 'Tab' &&
                  e.preventDefault();
              }}
              onChange={(e) =>
                setForeignerInfo((prev) => ({
                  ...prev,
                  mobileTelno: e.target.value,
                }))
              }
              placeholder="Enter your phone number."
            />
          </div>
        </div>
      </div>
    </div>
  );
}
