export default function StepThreeIcon() {
  return (
    <svg
      width="40"
      height="41"
      viewBox="0 0 40 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26.875 17.207L17.7083 25.957L13.125 21.582"
        stroke="#666666"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20 35.957C28.2843 35.957 35 29.2413 35 20.957C35 12.6728 28.2843 5.95703 20 5.95703C11.7157 5.95703 5 12.6728 5 20.957C5 29.2413 11.7157 35.957 20 35.957Z"
        stroke="#666666"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
