import { t } from 'i18next';
import UsageMailIcon from '../../vector/mypage/UsageMailIcon';
import UsageMessageIcon from '../../vector/mypage/UsageMessageIcon';
import UsagePhoneIcon from '../../vector/mypage/UsagePhoneIcon';
import UsageRecordIcon from '../../vector/mypage/UsageRecordIcon';
import UsageTalkIcon from '../../vector/mypage/UsageTalkIcon';
import UsageVoiceIcon from '../../vector/mypage/UsageVoiceIcon';

const tagStyle =
  'py-2 px-4 max-2xl:px-3 max-2xl:text-xs font-medium text-sm rounded-full max-md:px-[10px] w-fit bg-gray3 text-black3 whitespace-pre-wrap text-center';

export default function UsageCard({
  data,
}: {
  data: {
    type: string;
    data: {
      label: string;
      value: number;
    }[];
  };
}) {
  const getIcon = () => {
    switch (data.type) {
      case 'phone':
        return <UsagePhoneIcon />;
      case 'message':
        return <UsageMessageIcon />;
      case 'talk':
        return <UsageTalkIcon />;
      case 'voice':
        return <UsageVoiceIcon />;
      case 'mail':
        return <UsageMailIcon />;
      case 'phone':
        return <UsagePhoneIcon />;
      case 'record':
        return <UsageRecordIcon />;
    }
  };

  return (
    <div className="flex items-center py-11 px-7 max-md:p-5 bg-gray5 rounded-[20px]">
      <i className="mr-5">{getIcon()}</i>
      <section className="w-full flex flex-col space-y-3">
        {data.data.map(({ label, value }, idx) => (
          <div
            key={`data-${idx}`}
            className="flex items-center justify-between "
          >
            <div
              className={`${tagStyle} ${
                label === '통화녹음 다운로드' &&
                'max-w-[150px] max-2xl:max-w-[150px] text-center'
              }`}
            >
              {t(label)}
            </div>
            <div className="text-lg max-md:text-base font-medium">
              <strong className="font-bold">
                {value ? value.toLocaleString() : 0}
              </strong>{' '}
              {t('건')}
            </div>
          </div>
        ))}
      </section>
    </div>
  );
}
