export default function KakaoLoginIcon() {
  return (
    <svg
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="50" height="50" rx="25" fill="#FEE500" />
      <path
        d="M25.0001 14.8945C18.9248 14.8945 14 18.7875 14 23.5908C14 26.7167 16.0862 29.4568 19.2182 30.9898C18.9886 31.8482 18.3852 34.1014 18.2648 34.5829C18.1154 35.1814 18.4832 35.1734 18.7251 35.0131C18.9146 34.8872 21.7391 32.9652 22.958 32.1369C23.6198 32.235 24.3022 32.2867 24.9999 32.2867C31.0752 32.2867 36 28.3939 36 23.5911C36.0002 18.7875 31.0754 14.8945 25.0001 14.8945Z"
        fill="#371D1E"
      />
    </svg>
  );
}
