export default function InstagramIcon() {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.7">
        <rect
          width="30"
          height="30"
          rx="15"
          fill="url(#paint0_radial_167_6193)"
        />
        <rect
          width="30"
          height="30"
          rx="15"
          fill="url(#paint1_radial_167_6193)"
        />
        <rect width="30" height="30" rx="15" fill="white" />
        <path
          d="M21.7994 10.1554C21.7994 10.9273 21.1738 11.553 20.4019 11.553C19.6301 11.553 19.0044 10.9273 19.0044 10.1554C19.0044 9.38361 19.6301 8.75792 20.4019 8.75792C21.1738 8.75792 21.7994 9.38361 21.7994 10.1554Z"
          fill="black"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.2777 19.938C17.8505 19.938 19.9361 17.8524 19.9361 15.2797C19.9361 12.7069 17.8505 10.6213 15.2777 10.6213C12.705 10.6213 10.6193 12.7069 10.6193 15.2797C10.6193 17.8524 12.705 19.938 15.2777 19.938ZM15.2777 18.0747C16.8214 18.0747 18.0727 16.8233 18.0727 15.2797C18.0727 13.736 16.8214 12.4846 15.2777 12.4846C13.7341 12.4846 12.4827 13.736 12.4827 15.2797C12.4827 16.8233 13.7341 18.0747 15.2777 18.0747Z"
          fill="black"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.96094 14.907C5.96094 11.7763 5.96094 10.2109 6.57022 9.01512C7.10616 7.96328 7.96133 7.10811 9.01316 6.57217C10.2089 5.96289 11.7743 5.96289 14.905 5.96289H15.6504C18.7811 5.96289 20.3465 5.96289 21.5423 6.57217C22.5941 7.10811 23.4493 7.96328 23.9852 9.01512C24.5945 10.2109 24.5945 11.7763 24.5945 14.907V15.6523C24.5945 18.7831 24.5945 20.3484 23.9852 21.5442C23.4493 22.596 22.5941 23.4512 21.5423 23.9872C20.3465 24.5964 18.7811 24.5964 15.6504 24.5964H14.905C11.7743 24.5964 10.2089 24.5964 9.01316 23.9872C7.96133 23.4512 7.10616 22.596 6.57022 21.5442C5.96094 20.3484 5.96094 18.7831 5.96094 15.6523V14.907ZM14.905 7.82624H15.6504C17.2465 7.82624 18.3315 7.82769 19.1702 7.89622C19.9871 7.96296 20.4049 8.08394 20.6963 8.23243C21.3975 8.58972 21.9676 9.15984 22.3249 9.86106C22.4734 10.1525 22.5944 10.5703 22.6612 11.3872C22.7297 12.2259 22.7311 13.3109 22.7311 14.907V15.6523C22.7311 17.2484 22.7297 18.3335 22.6612 19.1721C22.5944 19.9891 22.4734 20.4068 22.3249 20.6983C21.9676 21.3995 21.3975 21.9696 20.6963 22.3269C20.4049 22.4754 19.9871 22.5964 19.1702 22.6631C18.3315 22.7316 17.2465 22.7331 15.6504 22.7331H14.905C13.3089 22.7331 12.2239 22.7316 11.3852 22.6631C10.5683 22.5964 10.1505 22.4754 9.85911 22.3269C9.15788 21.9696 8.58777 21.3995 8.23048 20.6983C8.08199 20.4068 7.96101 19.9891 7.89426 19.1721C7.82574 18.3335 7.82429 17.2484 7.82429 15.6523V14.907C7.82429 13.3109 7.82574 12.2259 7.89426 11.3872C7.96101 10.5703 8.08199 10.1525 8.23048 9.86106C8.58777 9.15984 9.15788 8.58972 9.85911 8.23243C10.1505 8.08394 10.5683 7.96296 11.3852 7.89622C12.2239 7.82769 13.3089 7.82624 14.905 7.82624Z"
          fill="black"
        />
      </g>
      <defs>
        <radialGradient
          id="paint0_radial_167_6193"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(10.7143 22.5) rotate(-55.3758) scale(27.3424)"
        >
          <stop stopColor="#B13589" />
          <stop offset="0.79309" stopColor="#C62F94" />
          <stop offset="1" stopColor="#8A3AC8" />
        </radialGradient>
        <radialGradient
          id="paint1_radial_167_6193"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(9.64286 31.0714) rotate(-65.1363) scale(24.2081)"
        >
          <stop stopColor="#E0E8B7" />
          <stop offset="0.444662" stopColor="#FB8A2E" />
          <stop offset="0.71474" stopColor="#E2425C" />
          <stop offset="1" stopColor="#E2425C" stopOpacity="0" />
        </radialGradient>
      </defs>
    </svg>
  );
}
