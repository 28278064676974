import { Dialog, Transition } from '@headlessui/react';
import { t } from 'i18next';
import { Fragment, useState } from 'react';
import { modifyUserInfo } from '../../../services/PrivateService';
import { checkBusinessNumberRequest } from '../../../services/RegCertService';
import { OnChangeEnterpriseInfoType } from '../../../services/TypeOfPrivateService';
import Loader from '../../common/Loader';
import InformModal from '../../common/modal/InformModal';

type BusinessInfoType = {
  memberBrnoUpdateYn: 'Y' | 'N';
  memberBrno: string;
  ceoFnm: string;
  establishYmd: string;
  companyNm: string;
};

type Props = {
  company: string;
  ceo: string;
  profileImage: File | null;
  onChangeInfo: OnChangeEnterpriseInfoType;
  modal: boolean;
  closeModal: () => void;
};

const inputStyle = 'py-3 px-4 w-full border-borderLightGray border rounded-lg';

export default function BusinessModifyModal({
  company,
  ceo,
  profileImage,
  onChangeInfo,
  modal,
  closeModal,
}: Props) {
  const [loading, setLoading] = useState(false);
  const [businessInfo, setBusinessInfo] = useState<BusinessInfoType>({
    memberBrnoUpdateYn: 'Y',
    memberBrno: '',
    ceoFnm: ceo,
    establishYmd: '',
    companyNm: company,
  });
  const [informModal, setInformModal] = useState({
    isOpen: false,
    phrase: '',
    secondPhrase: '',
  });

  const handleOnChange = (key: string, value: string) =>
    setBusinessInfo((prev) => ({ ...prev, [key]: value }));

  const modifyBusinessInfo = async () => {
    setLoading(true);
    const isFailed = await verifyBusinessInfo();
    if (isFailed) {
      setLoading(false);
      return;
    }
    await modifyInfo();
  };

  const modifyInfo = async () => {
    const info = { ...onChangeInfo, ...businessInfo };
    modifyUserInfo(info, profileImage, 'M03B0')
      .then((res) => {
        if (res.data.resultCd === '3001' || res.data.resultCd === '2001') {
          setInformModal({
            isOpen: true,
            phrase: '오류가 발생했습니다.',
            secondPhrase: '잠시 후 다시 시도해주세요.',
          });
        } else {
          setInformModal({
            isOpen: true,
            phrase: '고객님의 정보가 정상적으로 저장되었습니다.',
            secondPhrase: '',
          });
        }
      })
      .catch((err) => alert(err))
      .finally(() => setLoading(false));
  };

  const verifyBusinessInfo = async () => {
    let isFailed = false;
    const { memberBrno, establishYmd, ceoFnm, companyNm } = businessInfo;
    await checkBusinessNumberRequest({
      businesses: [
        {
          b_no: memberBrno,
          start_dt: establishYmd,
          p_nm: ceoFnm,
          b_nm: companyNm,
        },
      ],
    })
      .then((res) => {
        if (res.data.data[0].valid !== '01') {
          alert('사업자 정보를 다시 확인해주세요.');
          isFailed = true;
        }
      })
      .catch(console.log);
    return isFailed;
  };

  return (
    <>
      <Loader loading={loading} />
      <InformModal
        modal={informModal.isOpen}
        phrase={informModal.phrase}
        closeModal={() => {
          setInformModal((prev) => ({
            ...prev,
            isOpen: false,
          }));
          if (informModal.phrase === '비밀번호가 정상적으로 변경되었습니다.') {
            closeModal();
          }
        }}
      />
      <Transition appear show={modal} as={Fragment}>
        <Dialog as="div" className="fixed z-50" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 max-md:bottom-0 max-md:inset-auto max-md:w-full">
            <div className="flex min-h-full items-center justify-center p-4 max-md:p-0 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95 max-md:translate-y-80 max-md:scale-100"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-300"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95 max-md:translate-y-80 max-md:scale-100"
              >
                <Dialog.Panel className="w-full pb-10 max-md:pb-0 max-w-[500px] max-md:max-w-full transform overflow-hidden rounded-2xl max-md:rounded-b-none bg-white transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-xl py-5 mb-7 font-bold leading-6 text-gray-900 text-center border-b border-borderGray w-full"
                  >
                    {t('사업자등록번호 등록')}
                  </Dialog.Title>
                  <div className="px-7 max-md:px-5 space-y-5 text-left text-lg max-md:text-base">
                    <div className="space-y-3 max-md:space-y-2 relative">
                      <h1 className="font-medium">{t('사업자등록번호')}</h1>
                      <input
                        autoComplete="off"
                        onChange={({ target }) => {
                          const regex = /^[0-9\b ]{0,13}$/;
                          if (!regex.test(target.value)) return;
                          handleOnChange('memberBrno', target.value);
                        }}
                        value={businessInfo.memberBrno}
                        className="py-3 px-4 w-full rounded-lg border border-borderLightGray"
                        type="text"
                        maxLength={10}
                        placeholder={t('’-’없이 10자리를 입력해주세요')}
                      />
                    </div>
                    <div className="space-y-3 max-md:space-y-2">
                      <h1 className="font-medium">{t('개업일자')}</h1>
                      <input
                        maxLength={8}
                        autoComplete="off"
                        onChange={({ target }) => {
                          const regex = /^[0-9\b ]{0,13}$/;
                          if (!regex.test(target.value)) return;
                          handleOnChange('establishYmd', target.value);
                        }}
                        value={businessInfo.establishYmd}
                        className={`${inputStyle}`}
                        type="text"
                        placeholder={t('‘-’없이 8자리를 입력해주세요')}
                      />
                    </div>
                    <div className="space-y-3 max-md:space-y-2 relative">
                      <h1 className="font-medium">{t('대표자명')}</h1>
                      <input
                        spellCheck={false}
                        autoComplete="off"
                        onChange={({ target }) =>
                          handleOnChange('ceoFnm', target.value)
                        }
                        maxLength={20}
                        value={businessInfo.ceoFnm}
                        className={inputStyle}
                        type="text"
                        placeholder={t('대표자명을 입력해주세요')}
                      />
                    </div>
                    <div className="space-y-3 max-md:space-y-2 relative">
                      <h1 className="font-medium">{t('회사명')}</h1>
                      <input
                        spellCheck={false}
                        autoComplete="off"
                        onChange={({ target }) =>
                          handleOnChange('companyNm', target.value)
                        }
                        maxLength={20}
                        value={businessInfo.companyNm}
                        className={inputStyle}
                        type="text"
                        placeholder={t('대표자명을 입력해주세요')}
                      />
                    </div>
                    <div className="flex space-x-3 max-md:space-x-2 font-bold max-md:pb-7">
                      <button
                        type="button"
                        className="w-full py-4 rounded-lg border border-[#cccccc] hover:bg-gray5"
                        onClick={() => {
                          setBusinessInfo({
                            memberBrnoUpdateYn: 'N',
                            memberBrno: '',
                            ceoFnm: '',
                            establishYmd: '',
                            companyNm: '',
                          });
                          closeModal();
                        }}
                      >
                        {t('취소')}
                      </button>
                      <button
                        type="button"
                        onClick={modifyBusinessInfo}
                        className="bg-gradient-to-br from-primary to-primaryTo hover:from-hoverPrimary hover:to-hoverPrimaryTo w-full py-4 rounded-lg text-white"
                      >
                        {t('확인')}
                      </button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
