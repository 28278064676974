export default function TimeSaveIcon() {
  return (
    <svg
      className="w-[100px] h-[100px] max-md:w-[72px] max-md:h-[72px]"
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="50" cy="50" r="50" fill="#222222" />
      <path
        d="M73 50C73 36.752 62.248 26 49 26C35.752 26 25 36.752 25 50C25 63.248 35.752 74 49 74"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M63 54C62.4477 54 62 54.4477 62 55V64H57.7742C57.4502 64 57.1605 64.2606 57.0481 64.6531C56.9357 65.0455 57.0244 65.4869 57.2704 65.7593L64.4962 73.7593C64.7861 74.0802 65.2139 74.0802 65.5038 73.7593L72.7295 65.7593C72.9755 65.4869 73.0643 65.0455 72.9519 64.6531C72.8395 64.2606 72.5497 64 72.2257 64H68V55C68 54.4477 67.5523 54 67 54H63Z"
        fill="url(#paint0_linear_1619_100531)"
      />
      <path
        d="M49.4544 51.8516H38"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M49.4544 51.8518L59.1328 38.9707"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1619_100531"
          x1="57"
          y1="54"
          x2="72.7685"
          y2="74.1809"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF478B" />
          <stop offset="1" stopColor="#FFBB54" />
        </linearGradient>
      </defs>
    </svg>
  );
}
