import { Dialog, Transition } from '@headlessui/react';
import { Fragment, SetStateAction } from 'react';
import DaumPostcode from 'react-daum-postcode';
import CloseIcon from '../vector/common/CloseIcon';

export default function PostcodeModal({
  setAddress,
  openPostcodeModal,
  closeModal,
}: {
  setAddress: React.Dispatch<
    SetStateAction<{
      roadName: string;
      buildingName: string;
      zipCode: string;
    }>
  >;
  openPostcodeModal: boolean;
  closeModal: React.Dispatch<SetStateAction<boolean>>;
}) {
  const onCompletePost = (data: any) => {
    setAddress({
      roadName: data.address,
      buildingName: data.buildingName,
      zipCode: data.zonecode,
    });
    closeModal(false);
  };

  return (
    <Transition appear show={openPostcodeModal} as={Fragment}>
      <Dialog as="div" className="fixed z-50" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 max-md:inset-auto max-md:bottom-0 overflow-y-hidden">
          <div className="flex min-h-full items-center justify-center p-4 text-center max-md:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95 max-md:scale-100 max-md:translate-y-[400px]"
              enterTo="opacity-100 scale-100 max-md:translate-y-0"
              leave="ease-in duration-300"
              leaveFrom="opacity-100 scale-100 max-md:translate-y-0"
              leaveTo="opacity-0 scale-95 max-md:scale-100 max-md:translate-y-[400px]"
            >
              <Dialog.Panel>
                <div className="relative max-md:w-screen max-md:h-[520px]">
                  <button
                    onClick={() => closeModal(false)}
                    className="w-auto absolute -right-10 -top-10 max-md:static"
                  >
                    <CloseIcon isDark isBig />
                  </button>
                  <DaumPostcode
                    className="!w-[410px] !h-[410px] max-md:!w-full max-md:!h-full"
                    style={{
                      zIndex: 10,
                    }}
                    autoClose
                    onComplete={onCompletePost}
                  />
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
