import { useEffect, useState } from 'react';
import { getMyInfo } from '../../services/PrivateService';
import { isLogin } from '../../utils/common';
import { siteLangCd } from './../../services/defaultClient';

export function useUser() {
  const [myInfo, setMyInfo] = useState<any>();

  useEffect(() => {
    if (!isLogin) return;

    getMyInfo({
      siteLangCd,
    })
      .then(({ data: { data } }) => setMyInfo(data))
      .catch(console.log);
  }, []);

  return { myInfo };
}
