import { useSetRecoilState } from 'recoil';
import { slideIndex } from '../../store';
import useWindowScroll from '../hooks/useWindowScroll';
import TopArrow from '../vector/common/TopArrow';

export default function TopButton({
  isMain,
  slideIdx,
}: {
  isMain?: boolean;
  slideIdx?: number;
}) {
  const setIndex = useSetRecoilState(slideIndex);
  const { scrollY } = useWindowScroll();
  const goTop = () => {
    if (isMain) {
      setIndex(0);
    } else {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  };
  const isVisible = () => {
    if (isMain) {
      return slideIdx && slideIdx > 0 ? true : false;
    } else {
      return scrollY >= 500 ? true : false;
    }
  };

  return (
    <>
      {isVisible() && (
        <button
          onClick={goTop}
          className="top-button fixed bottom-10 z-10 right-7 max-md:right-3 max-md:bottom-6 w-[60px] h-[60px] max-md:w-10 max-md:h-10 rounded-full border border-gray3 bg-white flex justify-center items-center"
        >
          <TopArrow />
        </button>
      )}
    </>
  );
}
