import { Outlet } from 'react-router-dom';

const OutletPopup = () =>{

  return (
    <>
      <section className="overflow-x-hidden">
        <Outlet/>
      </section>
    </>
  );
}

export default OutletPopup;