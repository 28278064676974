import { Dialog, Transition } from '@headlessui/react';
import { t } from 'i18next';
import moment from 'moment';
import { Dispatch, Fragment, SetStateAction, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { subscriptionCancelReasonArray } from '../../../array/SearchFilterArray';
import { cancelMembership } from '../../../services/PrivateService';
import { siteLangCd } from '../../../services/defaultClient';
import { userinfoAtom } from '../../../store';
import Loader from '../../common/Loader';
import InformModal from '../../common/modal/InformModal';
import { getDaysDiff } from '../../hooks/useTime';
import ReasonSelectBox from '../my-info/ReasonSelectBox';

export default function SubScriptionCancelModal({
  closeModal,
  subCancel,
  limitDtTo,
  itemName,
  pkPpCard,
  setDeleteKey,
}: {
  closeModal: React.Dispatch<SetStateAction<boolean>>;
  subCancel: boolean;
  limitDtTo: string;
  itemName: string;
  pkPpCard: number;
  setDeleteKey: Dispatch<SetStateAction<number>>;
}) {
  const [loading, setLoading] = useState(false);
  const [reasonModal, setReasonModal] = useState(false);
  const [cancelInfo, setCancelInfo] = useState<{
    cancelReasonCd: string;
    cancelReasonEtcCtnt: string;
  }>({
    cancelReasonCd: '',
    cancelReasonEtcCtnt: '',
  });
  const [informModal, setInforModal] = useState({
    isOpen: false,
    phrase: '',
    content: '',
  });
  const { language } = useRecoilValue(userinfoAtom);

  const requestWithdraw = () => {
    if (!cancelInfo.cancelReasonCd) {
      alert('해지사유를 선택해주세요.');
    } else {
      cancelProduction();
    }
  };

  const cancelProduction = async () => {
    setLoading(true);
    cancelMembership({
      detailId: pkPpCard,
      siteLangCd,
    })
      .then((res) => {
        if (res.data.statusCd === 'OK') {
          setInforModal({
            isOpen: true,
            phrase: '해지신청이 완료되었습니다.',
            content: `요금제 혜택은 ${moment(limitDtTo).format(
              'YYYY. MM. DD'
            )}까지 이용하실 수 있습니다.`,
          });
          setDeleteKey((prev) => prev + 1);
        } else {
          setInforModal({
            isOpen: true,
            phrase: '오류가 발생했습니다.',
            content: '잠시 후 다시 시도해주세요.',
          });
        }
      })
      .catch(() => {
        setInforModal({
          isOpen: true,
          phrase: '오류가 발생했습니다.',
          content: '잠시 후 다시 시도해주세요.',
        });
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      <Loader loading={loading} />
      <InformModal
        phrase={informModal.phrase}
        content={informModal.content}
        modal={informModal.isOpen}
        closeModal={() => {
          setReasonModal(false);
          setInforModal((prev) => ({
            ...prev,
            isOpen: false,
          }));
          closeModal(false);
        }}
      />
      <Transition appear show={subCancel} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-50"
          onClose={() => {
            closeModal(false);
            setReasonModal(false);
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>
          {!informModal.isOpen && (
            <div
              className={`fixed inset-0 ${
                !informModal.isOpen && 'max-md:bottom-0 max-md:inset-auto'
              } overflow-y-auto max-md:w-full`}
            >
              <div className="flex min-h-full items-center justify-center p-4 max-md:p-0 text-center">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Dialog.Panel className="relative w-full max-w-[496px] px-7 py-10 max-md:px-5 max-md:py-8 transform overflow-hidden rounded-2xl max-md:rounded-b-none bg-white transition-all">
                    <div
                      onClick={() => {
                        closeModal(false);
                        setReasonModal(false);
                      }}
                      className="w-[60px] h-1 rounded-full bg-gray2 absolute right-1/2 translate-x-1/2 top-2 md:hidden"
                    />
                    {reasonModal ? (
                      <>
                        <section className="text-left">
                          <div className="space-y-2 text-black2">
                            <h2 className="text-xl max-md:text-lg font-bold">
                              {t('구독 요금제를 해지하시는 이유를 알려주세요.')}
                            </h2>
                            <div className="text-lg max-md:text-base">
                              {t(
                                '설문에 응답해주시면 더 좋은 서비스를 만드는데 도움이 됩니다.'
                              )}
                            </div>
                          </div>
                          <div className="space-y-3 mt-5">
                            <ReasonSelectBox
                              array={subscriptionCancelReasonArray}
                              setReasonInfo={setCancelInfo}
                            />
                            <textarea
                              onChange={(e) => {
                                setCancelInfo((prev) => ({
                                  ...prev,
                                  cancelReasonEtcCtnt: e.target.value,
                                }));
                              }}
                              value={cancelInfo.cancelReasonEtcCtnt}
                              placeholder={t(
                                '이 외 불편하셨던 점이 있으면 알려주세요.(선택)'
                              )}
                              className="border border-borderLightGray py-2 px-3 rounded-md w-full h-40 text-lg max-md:text-base resize-none"
                            />
                          </div>
                        </section>

                        <button
                          type="button"
                          className="w-full py-4 rounded-lg bg-gradient-to-br from-primary to-primaryTo hover:from-hoverPrimary hover:to-hoverPrimaryTo text-white font-bold text-lg max-md:text-base mt-8 max-md:mt-5"
                          onClick={requestWithdraw}
                        >
                          {language === 'en' ? 'Confirm' : '해지하기'}
                        </button>
                      </>
                    ) : (
                      <>
                        <Dialog.Title
                          as="h3"
                          className="flex flex-col text-left"
                        >
                          <span className="text-black2 font-bold text-xl max-md:text-lg">
                            {t('사용 중인 구독 요금제')}{' '}
                            <br className="hidden max-md:block" />
                            {t('해지 신청을 하시겠습니까?')}
                          </span>
                          <div className="text-lg mt-3 max-md:text-base">
                            {language === 'kr' ? (
                              <p className="text-black2 font-normal">
                                {t('해지신청을 하시면')}{' '}
                                <strong>
                                  {moment(limitDtTo).format('YYYY. MM. DD')}
                                </strong>
                                {t(
                                  '에 구독이 해지 되며, 해당 날짜까지 요금제를 사용하실 수 있습니다.'
                                )}
                              </p>
                            ) : (
                              <p className="text-black2 font-normal">
                                If you request termination, your subscription
                                will be canceled on{' '}
                                <strong>
                                  {moment(limitDtTo).format('YYYY. MM. DD')}
                                </strong>
                                , the payment day on the next month, and you can
                                use the plan until that date.
                              </p>
                            )}
                          </div>
                          <section className="py-5 px-6 max-md:px-5 mt-7 max-md:mt-5 max-md:p-4 max-md:text-sm bg-gray5 rounded-lg text-left space-y-2">
                            <ul className="text-black5 space-y-5">
                              <li>
                                <div className="flex items-center justify-between">
                                  <span className="text-black4">
                                    {t('사용중인 요금제')}
                                  </span>
                                  <span className="text-lg max-md:text-base text-black2">
                                    {t(itemName)}
                                  </span>
                                </div>
                              </li>
                              <li>
                                <div className="flex items-center justify-between">
                                  <span className="text-black4">
                                    {t('남은 사용기간')}
                                  </span>

                                  <span className="text-lg max-md:text-base text-black2">
                                    {`${String(getDaysDiff(limitDtTo)).replace(
                                      '-',
                                      ''
                                    )}${t('일')}`}
                                  </span>
                                </div>
                              </li>{' '}
                            </ul>
                          </section>
                        </Dialog.Title>

                        <section className="mt-8 space-x-2 flex">
                          <button
                            className="border border-[#cccccc] w-full py-[18px] rounded-lg hover:bg-gray5 duration-100 ease-out text-lg font-bold"
                            onClick={() => closeModal(false)}
                          >
                            {t('유지하기')}
                          </button>
                          <button
                            className="w-full text-white font-bold text-lg bg-gradient-to-br from-primary to-primaryTo hover:from-hoverPrimary hover:to-hoverPrimaryTo rounded-lg"
                            onClick={() => setReasonModal(true)}
                          >
                            {t('해지신청하기')}
                          </button>
                        </section>
                      </>
                    )}
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          )}
        </Dialog>
      </Transition>
    </>
  );
}
