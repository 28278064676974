export const payStatusArray: { name: string; value: string }[] = [
  { name: '결제상태 전체', value: '' },
  { name: '준비', value: 'B20101' },
  { name: '가입', value: 'B20102' },
  { name: '충전', value: 'B20103' },
  { name: '충전-자동', value: 'B20104' },
  { name: '차감', value: 'B20105' },
  { name: '차감-마이너스통장', value: 'B20106' },
  { name: '차감-자동', value: 'B20107' },
  { name: '기간만료', value: 'B20108' },
  { name: '이월', value: 'B20109' },
  { name: '결제완료', value: 'B20111' },
  { name: '결제취소', value: 'B20131' },
  { name: '해지', value: 'B20113' },
  { name: '변경', value: 'B20114' },
  { name: '환불', value: 'B20115' },
  { name: '결제실패', value: 'B20116' },
  { name: '예약', value: 'B20117' },
  { name: '해지완료', value: 'B20118' },
  { name: '업그레이드', value: 'B20121' },
  { name: '다운그레이드', value: 'B20122' },
];

export const noticeCategoryArray = [
  {
    buttonText: '전체',
    value: '',
  },
  {
    buttonText: '공지',
    value: 'N0201',
  },
  {
    buttonText: '뉴스',
    value: 'N0202',
  },
  {
    buttonText: '이벤트',
    value: 'N0203',
  },
];
export const serviceFilterArray: { name: string; value: string }[] = [
  { name: '서비스 전체', value: '' },
  { name: '워크센터', value: 'B11' },
  { name: '메타휴먼', value: 'B12' },
  { name: '스튜디오', value: 'B13' },
  { name: '손비서앱', value: 'B14' },
  { name: '플루닛', value: 'B15' },
];
export const subscriptionCancelReasonArray = [
  {
    label: '해지 사유를 선택해주세요.',
    value: '',
  },
  {
    label: '서비스를 자주 이용하지 않아요.',
    value: 'M06A01',
  },
  {
    label: '타사 서비스를 이용하고 있어요.',
    value: 'M06A02',
  },
  {
    label: '요금이 비싸서 부담돼요.',
    value: 'M06A03',
  },
  {
    label: '새로운 콘텐츠가 부족해요.',
    value: 'M06A04',
  },
  {
    label: '개인정보 유출이 우려되요.',
    value: 'M06A05',
  },
  {
    label: '서비스 사용이 어려워요.',
    value: 'M06A06',
  },
  {
    label: '기타',
    value: 'M06B99',
  },
];

export const withdrawReasonArray: { label: string; value: string }[] = [
  {
    label: '탈퇴 사유를 선택해주세요.',
    value: '',
  },
  {
    label: '서비스를 자주 이용하지 않아요.',
    value: 'M06A01',
  },
  {
    label: '타사 서비스를 이용하고 있어요.',
    value: 'M06A02',
  },
  {
    label: '요금이 비싸서 부담돼요.',
    value: 'M06A03',
  },
  {
    label: '새로운 콘텐츠가 부족해요.',
    value: 'M06A04',
  },
  {
    label: '개인정보 유출이 우려되요.',
    value: 'M06A05',
  },
  {
    label: '서비스 사용이 어려워요.',
    value: 'M06A06',
  },
  {
    label: '기타 ',
    value: 'M06B99',
  },
];

export const membershipFilterArray: { name: string; value: string }[] = [
  { name: '요금제 전체', value: '' },
  { name: '베이직', value: 'CARD_OM_002' },
  { name: '프로페셔널', value: 'CARD_OM_003' },
  { name: '비즈니스', value: 'CARD_OM_004' },
  { name: '엔터프라이즈', value: 'CARD_OM_005' },
  { name: '엔트리', value: 'CARD_SM_001' },
  { name: '베이직 플러스', value: 'CARD_SM_012' },
  { name: '프로페셔널 플러스', value: 'CARD_SM_013' },
  { name: '비즈니스 플러스', value: 'CARD_SM_014' },
  { name: '엔터프라이즈 플러스', value: 'CARD_SM_015' },
];

export const payStatusFilterArray: { name: string; value: string }[] = [
  { name: '결제상태 전체', value: '' },
  { name: '결제완료', value: 'B20111' },
  { name: '결제취소', value: 'B20131' },
  { name: '결제실패', value: 'B20116' },
];

export const monthFilterArray: { name: string; value: string }[] = [
  { name: '3개월', value: '3' },
  { name: '6개월', value: '6' },
  { name: '12개월', value: '12' },
  { name: '기간 전체', value: '0' },
];

export const creditUseStatusArray: { name: string; value: string }[] = [
  { name: '구분 전체', value: '' },
  { name: '지급', value: 'B20202' },
  { name: '사용', value: 'B20203' },
  { name: '소멸', value: 'B20204' },
];

export const inquiryStatusFilterArray: { name: string; value: string }[] = [
  { name: '문의 전체', value: '' },
  { name: '접수완료', value: '0' },
  { name: '답변완료', value: '1' },
];

export const termsFilterArray: { name: string; value: string }[] = [
  { name: '통합서비스 이용약관', value: 'M01E0' },
  { name: '개인정보 수집 이용 동의서', value: 'M01F0' },
  { name: '마케팅활용 동의서', value: 'M01G0' },
];

export const genderFilterArray: { name: string; value: string }[] = [
  { name: '여성', value: 'F' },
  { name: '남성', value: 'M' },
];

export const memberTypeFilterArray: { name: string; value: string }[] = [
  { name: '개인', value: 'M03A0' },
  { name: '기업', value: 'M03B0' },
];

export const memberStateFilterArray: { name: string; value: string }[] = [
  { name: '활동', value: 'M040' },
  { name: '휴면', value: 'M041' },
  { name: '탈퇴', value: 'M042' },
  { name: '탈퇴요청', value: 'M043' },
];

export const cancelProcessTypeFilterArray: { name: string; value: string }[] = [
  { name: '일반탈퇴', value: 'M051' },
  { name: '강제탈퇴', value: 'M052' },
];

export const snsTypeFilterArray: { name: string; value: string }[] = [
  { name: '애플', value: 'M07AP' },
  { name: '구글', value: 'M07GG' },
  { name: '카카오톡', value: 'M07KA' },
  { name: '네이버', value: 'M07NA' },
];

export const noticeCategoryFilterArray: { name: string; value: string }[] = [
  // 공지분류코드
  { name: '공지', value: 'N0201' },
  { name: '뉴스', value: 'N0202' },
  { name: '이벤트', value: 'N0203' },
];

export const fileFilterArray: { name: string; value: string }[] = [
  // 화일코드
  { name: '썸네일', value: 'N04I01' },
  { name: '이미지', value: 'N04I02' },
  { name: '첨부화일', value: 'N04F01' },
  { name: '프로필이미지', value: 'N04I03' },
  { name: '첨부화일_답변', value: 'N04F02' },
];

export const backgroundTypeFilterArray: { name: string; value: string }[] = [
  // 배경유형코드
  { name: '단색', value: 'N061' },
  { name: '그라데이션', value: 'N062' },
];

export const qnaCategoryFilterArray: { name: string; value: string }[] = [
  // 문의분류코드
  { name: '회원', value: 'N0501' },
  { name: '메타휴먼', value: 'N0502' },
  { name: '결제', value: 'N0503' },
  { name: '환불', value: 'N0504' },
  { name: '콘텐츠', value: 'N0505' },
  { name: '장애', value: 'N0506' },
  { name: '오류', value: 'N0507' },
  { name: '개선제안', value: 'N0508' },
  { name: '기타', value: 'N0599' },
];

export const cancelReasonFilterArray: { name: string; value: string }[] = [
  // 문의분류코드
  { name: '서비스를 자주 이용하지 않아요.', value: 'M06A01' },
  { name: '타사 서비스를 이용하고 있어요.', value: 'M06A02' },
  { name: '요금이 비싸서 부담돼요.', value: 'M06A03' },
  { name: '새로운 콘텐츠가 부족해요.', value: 'M06A04' },
  { name: '개인정보 유출이 우려되요.', value: 'M06A05' },
  { name: '서비스 사용이 어려워요.', value: 'M06A06' },
  { name: '음란물 생성 및 배포', value: 'M06B01' },
  { name: '저작권 침해', value: 'M06B02' },
  { name: '블랙리스트 회원', value: 'M06B03' },
  { name: '기타', value: 'M06B99' },
];
