import { t } from 'i18next';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { getNoticeDetail } from '../../../services/PublicService';
import { fileBaseURL } from '../../../services/defaultClient';

export default function NoticeDetail() {
  const [notice, setNotice] = useState<any>({});
  const pathname = useLocation();
  const noticeId = pathname.pathname.substring(7);

  useEffect(() => {
    getNoticeDetail(noticeId)
      .then((res) => {
        setNotice(res.data.data);
      })
      .catch(console.log);
  }, [noticeId]);

  return (
    <main className="bg-black pt-[140px] max-md:pt-[98px] pb-64 max-md:pb-[100px] max-md:px-5">
      <div className="max-w-[1150px] max-2xl:max-w-[880px] mx-auto space-y-24 max-2xl:space-y-16 max-md:space-y-16">
        <section className="space-y-10 max-2xl:space-y-7 text-white max-md:space-y-5">
          <h3 className="text-primary font-bold text-2xl max-2xl:text-xl max-md:text-base">
            {t(notice.noticeCategoryCdNm)}
            <strong className="font-normal mx-3">|</strong>
            {t(notice.serviceCdNm)}
          </h3>
          <h1 className="font-bold text-5xl max-2xl:text-5xl max-md:text-[28px] max-md:leading-snug">
            {notice.noticeTit}
          </h1>
          <h3 className="text-[#B0B0B0] text-2xl max-2xl:text-xl max-md:text-base">
            {moment(notice.registYmd).format('YYYY. MM. DD')}
          </h3>
        </section>
        <section className="space-y-10 text-white w-full">
          {notice.thumbnailPath && (
            <div
              style={{
                backgroundImage: `url(${fileBaseURL + notice.imagePath})`,
              }}
              className="bg-no-repeat bg-cover bg-center w-full h-[600px] max-2xl:h-[400px] max-md:h-[200px]"
            />
          )}
        </section>
        <section className="space-y-10 text-white">
          <div
            dangerouslySetInnerHTML={{ __html: notice.noticeTxt }}
            className="notice-detail text-textGray text-xl leading-10 max-md:text-base"
          />
        </section>
      </div>
      <div className="mt-60 max-md:mt-14 flex justify-center">
        <Link to="/notice">
          <button className="w-48 max-md:w-[120px] border border-white text-xl max-md:text-base text-center py-4 rounded-lg text-white hover:bg-gray5/10 duration-100 ease-out">
            {t('목록')}
          </button>
        </Link>
      </div>
    </main>
  );
}
