export default function DoubleDotIcon() {
  return (
    <svg
      className="w-5 h-5 max-md:w-4 max-md:h-4"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        opacity="0.4"
        x="0.5"
        y="0.5"
        width="19"
        height="19"
        rx="9.5"
        stroke="white"
      />
      <rect x="6" y="6" width="8" height="8" rx="4" fill="#ADADAD" />
    </svg>
  );
}
