import { t } from 'i18next';
import { useEffect, useState } from 'react';
import {
  membershipFilterArray,
  monthFilterArray,
  payStatusFilterArray,
  serviceFilterArray,
} from '../../../array/SearchFilterArray';
import MyPageLayout from '../../../components/common/MyPageLayout';
import Skeleton from '../../../components/common/MypageCardSkeleton';
import Pagination from '../../../components/common/Pagination';
import SearchFilter from '../../../components/common/SearchFilter';
import PaymentDetailModal from '../../../components/my-page/payments/DetailModal';
import MobilePaymentCard from '../../../components/my-page/payments/MobilePaymentCard';
import PcPaymentCard from '../../../components/my-page/payments/PcPaymentCard';
import { getPaymentsListApi } from '../../../services/PrivateService';
import {
  PaymentsListClientDataType,
  UnionListClientReqType,
} from '../../../services/TypeOfPrivateService';
import { siteLangCd } from '../../../services/defaultClient';
import { getParentPage } from '../../../utils/common';

const perPage = 10;
const initialModalState: { open: boolean; item: PaymentsListClientDataType } = {
  open: false,
  item: {
    pkPgPayLog: 0,
    itemName: '',
    solutionType: '',
    sumCost: 0,
    payDtFrom: '',
    billStatus: '',
    subscYn: '',
    payName: '', // 요금제명
    payCompanyName: '', // 결제수단명
    credit: 0, // 결제크레딧
    creditFree: 0, // 사용크레딧
  },
};
export const filterOptions = [
  serviceFilterArray,
  membershipFilterArray,
  payStatusFilterArray,
  monthFilterArray,
];

export default function PaymentDetails() {
  const [loading, setLoading] = useState(true);
  const [serviceCategory, setServiceCategory] = useState('');
  const [detailModal, setDetailModal] = useState(initialModalState);
  const [pageNo, setPageNo] = useState(1);
  const [total, setTotal] = useState(0);
  const [searchFilter, setSearchFilter] = useState({
    billStatusCd: '',
    itemCd: '',
    monthType: '3',
    serviceCd: '',
  });
  const [paymentList, setPaymentList] = useState<PaymentsListClientDataType[]>(
    []
  );

  useEffect(() => {
    setLoading(true);
    const reqBody: UnionListClientReqType = {
      siteLangCd,
      serviceCd: searchFilter.serviceCd,
      billStatusCd: searchFilter.billStatusCd,
      itemCd: searchFilter.itemCd,
      monthType: searchFilter.monthType,
      pageNo,
      perPage,
    };
    getPaymentsListApi(reqBody)
      .then((res) => {
        if (res.data.statusCd === 'OK') {
          setTotal(res.data.data.totalRows);
          setPaymentList(res.data.data?.pageList);
        }
      })
      .catch(console.log)
      .finally(() => setLoading(false));
  }, [pageNo, searchFilter]);

  const onPaging = (page: number) => {
    window.scrollTo(0, 0);
    setPageNo(Number(page));
  };

  return (
    <MyPageLayout>
      <PaymentDetailModal
        modal={detailModal.open}
        item={detailModal.item}
        closeModal={() =>
          setDetailModal((prev) => ({
            ...prev,
            open: false,
          }))
        }
      />
      <div className="w-full">
        <div className="flex max-lg:flex-col max-lg:space-y-3 justify-between max-md:mb-6 max-md:w-full">
          <h1 className="font-bold text-4xl max-2xl:text-3xl max-md:text-2xl">
            {t('결제내역')}
          </h1>
          <SearchFilter
            setFilter={setSearchFilter}
            filterOptions={filterOptions}
          />
        </div>

        <section className="space-y-3">
          {loading ? (
            <Skeleton />
          ) : (
            <>
              <MobilePaymentCard
                paymentList={paymentList}
                setDetailModal={setDetailModal}
              />
              <PcPaymentCard
                paymentList={paymentList}
                setDetailModal={setDetailModal}
              />
            </>
          )}
        </section>
        <div className="mt-[60px]">
          {paymentList.length ? (
            <Pagination
              onPaging={onPaging}
              total={total}
              current={pageNo}
              textColor="text-black group-hover:text-white"
              strokeColor="black"
              parentPage={getParentPage()}
              posts_per_page={perPage}
              max={total >= perPage ? Math.floor(total / perPage) + 1 : 1}
            />
          ) : null}
        </div>
      </div>
    </MyPageLayout>
  );
}
