export default function ExclamationIcon() {
  return (
    <svg
      className="w-10 h-10"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 38.0952C29.9937 38.0952 38.0952 29.9937 38.0952 20C38.0952 10.0063 29.9937 1.90476 20 1.90476C10.0063 1.90476 1.90476 10.0063 1.90476 20C1.90476 29.9937 10.0063 38.0952 20 38.0952ZM40 20C40 31.0457 31.0457 40 20 40C8.9543 40 6.63601e-07 31.0457 1.62925e-06 20C2.59489e-06 8.9543 8.95431 -2.83331e-06 20 -1.86766e-06C31.0457 -9.0202e-07 40 8.9543 40 20Z"
        fill="url(#paint0_linear_2976_102350)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.9531 26.3489L20.9531 7.30127L19.0484 7.30127L19.0484 26.3489L20.9531 26.3489Z"
        fill="#FF6D51"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.9531 32.6981L20.9531 28.4653L19.0484 28.4653L19.0484 32.6981L20.9531 32.6981Z"
        fill="#FF6D51"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2976_102350"
          x1="40"
          y1="40"
          x2="3.49691e-06"
          y2="-3.49691e-06"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF4E83" />
          <stop offset="1" stopColor="#FFBB54" />
        </linearGradient>
      </defs>
    </svg>
  );
}
