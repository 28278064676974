import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { userTypeArray } from '../../../array/signup';
import MobileBackButton from '../../../components/common/MobileBackButton';
import SignupButton from '../../../components/common/SignupButtons';
import {
  checkCiDuplicate,
  getEmailCheckRequest,
} from '../../../services/PublicService';
import {
  RegCertResultType,
  quickRegisterEnterpriseInfo,
  quickRegisterPersonalInfo,
  userinfoAtom,
} from '../../../store';

import { useTranslation } from 'react-i18next';
import {
  emailRegex,
  passwordRegex,
} from '../../../components/hooks/useValidate';
import Breadcrumb from '../../../components/signup/quick/Breadcrumb';
import EnterpriseInputForm from '../../../components/signup/quick/EnterpriseInputForm';
import PersonalInputForm from '../../../components/signup/quick/PersonalInputForm';
import {
  EnterpriseInfoType,
  PersonalInfoType,
} from '../../../types/quick/quick-type';
import { hasEmptyData } from '../../../utils/common';

export default function QuickSignupPage() {
  const [userType, setUserType] = useState<'personal' | 'enterprise'>(
    'personal'
  );
  const [personalInfo, setPersonalInfo] = useRecoilState<PersonalInfoType>(
    quickRegisterPersonalInfo
  );
  const [enterpriseInfo, setEnterpriseInfo] =
    useRecoilState<EnterpriseInfoType>(quickRegisterEnterpriseInfo);
  const [duplicateChecked, setDuplicateChecked] = useState(false);
  const [validatePhrase, setValidatePhrase] = useState('');
  const [encKey, setEncKey] = useState('');
  const { language } = useRecoilValue(userinfoAtom);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const checkEmailDuplicate = async (memberEmail: string) => {
    const isEmailValid = validateEmail(memberEmail);
    if (!isEmailValid) return;

    getEmailCheckRequest({ memberEmail })
      .then(({ data }) => {
        const isExist = data.data.exist;
        !isExist ? handleSuccess() : handleExist();
      })
      .catch(console.log);

    function handleSuccess() {
      setDuplicateChecked(true);
      setValidatePhrase('사용이 가능한 이메일입니다.');
    }
    function handleExist() {
      setDuplicateChecked(false);
      setValidatePhrase('이미 사용중인 이메일입니다.');
    }
  };

  const validateEmail = (email: string) => {
    if (!emailRegex.test(email)) {
      setValidatePhrase(
        '이메일 주소가 올바르지 않습니다. 이메일 주소를 정확하게 입력해주세요.'
      );
      setDuplicateChecked(false);
      return false;
    }
    return true;
  };

  //-- 본인인증 > --//

  const handleClickAuthCheck = () => {
    window.open('/popup/signup/regcert', 'authCheck', 'width=480, height=800');
  };
  (window as any).callbackUpdateEncKey = (data: any) => {
    setEncKey(data);
  };
  (window as any).callbackGetEncKey = () => {
    return encKey;
  };
  (window as any).callbackSetAuthResult = async (data: RegCertResultType) => {
    if (userType === 'personal') {
      setPersonalInfo((prev) => ({
        ...prev,
        ciVal: data.staffCi,
        mobileTelno: data.staffMobile,
        mobileTelco: data.staffMobileType,
        birthYmd: data.staffBirth,
        genderCd: data.staffGender,
      }));
    } else {
      setEnterpriseInfo((prev) => ({
        ...prev,
        ciVal: data.staffCi,
        mobileTelno: data.staffMobile,
        mobileTelco: data.staffMobileType,
        birthYmd: data.staffBirth,
        genderCd: data.staffGender,
      }));
    }
    let isExist;
    await checkCiDuplicate({ ciVal: data.staffCi })
      .then((res) => (isExist = res.data.data.exist))
      .catch((err) => alert(err));
    if (isExist) {
      alert('이미 가입된 계정이 있어, 로그인 페이지로 이동합니다.');
      navigate('/login');
      return;
    }

    navigate('/signup/quick/step02');
  };

  const isDisabled =
    hasEmptyData(userType === 'personal' ? personalInfo : enterpriseInfo) ||
    !duplicateChecked ||
    !passwordRegex.test(
      userType === 'personal'
        ? personalInfo.memberPwd
        : enterpriseInfo.memberPwd
    );

  return (
    <section className="bg-[#F8F7F8] pt-[180px] pb-48 max-md:pt-[62px] max-md:pb-24 max-md:bg-white">
      <div className="flex justify-center max-md:block">
        <div>
          <section className="flex flex-col text-[32px] max-2xl:text-3xl space-y-1 max-md:px-5 max-md:text-2xl">
            <MobileBackButton />

            <div className="leading-[140%]">
              <h1>{t('워크센터가 뭘까? 궁금하시죠?')}</h1>
              <h1 className="font-bold">{t('이제 플루닛 통합사이트에서도')}</h1>
              <h1 className="font-bold">
                {t('워크센터를 사전 체험하실 수 있어요.')}
              </h1>
            </div>
            <div className="text-black4 text-base !mt-8">
              <Breadcrumb current="step01" />
            </div>
          </section>

          <section className="mt-7 p-10 bg-white rounded-lg text-lg space-y-10 max-md:px-5 max-md:w-full max-md:mt-5 max-md:p-5">
            <div className="space-y-4 max-2xl:text-lg text-xl">
              <span className="font-bold">
                {t('회원 유형을 선택해주세요.')}
              </span>
              <div
                className={`space-x-4 text-center items-center flex ${
                  language === 'en'
                    ? 'max-md:!grid-cols-1'
                    : 'max-md:grid max-md:grid-cols-2 max-md:space-x-0 max-md:gap-3'
                }`}
              >
                {userTypeArray.map(({ icon, buttonText, type }) => (
                  <button
                    key={type}
                    onClick={() => {
                      setUserType(type);
                      setValidatePhrase('');
                      setDuplicateChecked(false);
                    }}
                    className={`px-36 max-2xl:text-sm text-base py-10 font-bold bg-white hover:bg-gray5 rounded-lg flex flex-col items-center border-2 ${
                      userType === type ? 'border-black' : 'border-borderGray'
                    } max-md:px-0 max-md:py-3`}
                  >
                    <i>{icon}</i>
                    <span>{t(buttonText)}</span>
                  </button>
                ))}
              </div>
            </div>

            {userType === 'enterprise' ? (
              <EnterpriseInputForm
                setEnterpriseInfo={setEnterpriseInfo}
                enterpriseInfo={enterpriseInfo}
                duplicateChecked={duplicateChecked}
                checkEmailDuplicate={checkEmailDuplicate}
                phrase={validatePhrase}
                setPhrase={setValidatePhrase}
              />
            ) : (
              <PersonalInputForm
                setPersonalInfo={setPersonalInfo}
                personalInfo={personalInfo}
                duplicateChecked={duplicateChecked}
                checkEmailDuplicate={checkEmailDuplicate}
                phrase={validatePhrase}
                setPhrase={setValidatePhrase}
              />
            )}
          </section>

          <div className="flex justify-center mt-14 max-md:px-5">
            <SignupButton disabled={isDisabled} onClick={handleClickAuthCheck}>
              휴대폰 본인인증
            </SignupButton>
          </div>
        </div>
      </div>
    </section>
  );
}
