import { t } from 'i18next';
import { debounce } from 'lodash';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import {
  inquiryStatusFilterArray,
  serviceFilterArray,
} from '../../../array/SearchFilterArray';
import { inquiryTypeArray } from '../../../array/myPageArray';
import MyPageLayout from '../../../components/common/MyPageLayout';
import SearchBar from '../../../components/common/SearchBar';
import SearchFilter from '../../../components/common/SearchFilter';
import InquiryTab from '../../../components/my-page/inquiry/InquiryTab';
import PloonianImageSection from '../../../components/my-page/inquiry/PloonianImageSection';
import TelInquiryTab from '../../../components/my-page/inquiry/TelInquiryTab';
import DivIcon from '../../../components/vector/payment-details/DivIcon';
import { ploonianTelNo } from '../../../services/defaultClient';
import { me, userinfoAtom } from '../../../store';

const filterOptions = [serviceFilterArray, inquiryStatusFilterArray];

export default function Inquiry() {
  const [inquiryType, setInquiryType] = useState('웹문의');
  const [searchFilter, setSearchFilter] = useState({
    inquiryStatus: '',
    serviceCd: '',
  });
  const [searchTxt, setSearchTxt] = useState('');
  const myInfo = useRecoilValue<any>(me);
  const { language } = useRecoilValue(userinfoAtom);
  const handleOnChange = debounce((e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTxt(e.target.value);
  }, 300);

  return (
    <MyPageLayout>
      <section className="w-full">
        <PloonianImageSection />
        <div className="mb-7 max-md:mb-6">
          <div className="flex justify-between items-center max-md:flex-col-reverse max-md:justify-start max-md:items-start">
            <div className="space-x-6 flex items-center max-md:space-x-4">
              {inquiryTypeArray.map(({ type }, idx) => (
                <div
                  className="flex items-center space-x-6 max-md:space-x-4"
                  key={`type-${idx}`}
                >
                  <button
                    onClick={() => setInquiryType(type)}
                    className={`${
                      inquiryType === type
                        ? 'text-ploonetOrange border-b-[3px] border-ploonetOrange'
                        : 'text-textGray hover:text-ploonetOrangeHover duration-100 ease-out'
                    } font-bold text-xl`}
                  >
                    {t(type)}
                  </button>
                  {idx === 0 && (
                    <i>
                      <DivIcon />
                    </i>
                  )}
                </div>
              ))}
            </div>
            <div className="flex items-center space-x-3 max-md:border-t max-md:border-borderLightGray max-md:pt-6 max-md:mt-6 max-md:mb-10 max-md:space-x-4 max-md:w-full justify-between">
              <div className="flex flex-col text-right text-lg max-2xl:text-base max-md:text-sm max-md:text-start max-md:justify-between max-md:w-full">
                <span>
                  {myInfo?.modelNm} {t('전화번호')}{' '}
                  <strong>{t(ploonianTelNo)}</strong>
                </span>
                <span>{t('상담내용은 한 번 등록하면 수정이 불가합니다.')}</span>
              </div>
              <Link className="max-md:w-1/2" to="/my-page/inquiry/req">
                <button className="py-3 px-5 bg-black2 text-white rounded-lg hover:bg-opacity-80 duration-100 ease-out max-md:!p-3 max-md:text-sm max-md:w-full">
                  {t('문의글 남기기')}
                </button>
              </Link>
            </div>
          </div>
        </div>
        <div
          className={`flex max-md:flex-col max-md:space-y-3 items-center ${
            inquiryType === '웹문의' ? 'justify-between' : 'justify-end'
          }`}
        >
          {inquiryType === '웹문의' && (
            <SearchFilter
              setFilter={setSearchFilter}
              filterOptions={filterOptions}
            />
          )}
          <SearchBar
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleOnChange(e)
            }
          />
        </div>

        <section className="mt-3 max-md:mt-7">
          {inquiryType === '웹문의' ? (
            <InquiryTab searchTxt={searchTxt} searchFilter={searchFilter} />
          ) : (
            <TelInquiryTab searchTxt={searchTxt} />
          )}
        </section>
      </section>
    </MyPageLayout>
  );
}
