import { useEffect, useState } from 'react';
import NextPageIcon from '../vector/common/NextPageIcon';
import PrevPageIcon from '../vector/common/PrevPageIcon';

const Pagination = (props: any) => {
  const max = Number.isInteger(props.total / props.posts_per_page)
    ? props.max - 1
    : props.max;

  const [parentPage, setParentPage] = useState(0);
  const pageArray = [];

  const buttonStyle =
    'w-10 !h-10 hover:bg-gray3/20 border border-gray3 rounded-lg duration-100 ease-out flex items-center justify-center';

  useEffect(() => {
    setParentPage(
      (props.parentPage -
        ((props.current - 1) % props.parentPage) +
        (props.current - 1)) /
        props.parentPage -
        1
    );
  }, [props.current]);

  for (
    let i = 0;
    i < props.parentPage && i + 1 + props.parentPage * parentPage <= max;
    i += 1
  ) {
    pageArray.push(
      <button
        className={`w-10 h-10 group rounded-lg bg-gradient-to-r border border-gray3 hover:from-hoverPrimary hover:to-hoverPrimaryTo hover:text-white duration-100 ease-out ${
          i + 1 + props.parentPage * parentPage === Number(props.current) &&
          'bg-gradient-to-r from-primary to-primaryTo text-white'
        }`}
        onClick={(e) => props.onPaging(e.currentTarget.dataset.id)}
        data-id={i + 1 + props.parentPage * parentPage}
        key={i}
      >
        <span
          className={
            i + 1 + props.parentPage * parentPage === Number(props.current) &&
            'bg-gradient-to-r hover:from-hoverPrimary hover:to-hoverPrimaryTo text-white'
              ? 'text-white'
              : props.textColor
          }
        >
          {i + 1 + props.parentPage * parentPage}
        </span>
      </button>
    );
  }

  return (
    <>
      <div
        className="flex items-center justify-center mt-0 space-x-3"
        style={{ overflow: 'hidden' }}
      >
        <button
          className={buttonStyle}
          onClick={(e) => {
            if (!(parentPage > 0)) {
              return;
            }
            setParentPage(parentPage - 1);
            props.onPaging(1 + props.parentPage * (parentPage - 1));
          }}
        >
          <PrevPageIcon strokeColor={props.strokeColor} />
        </button>
        {pageArray}
        <button
          className={buttonStyle}
          onClick={(e) => {
            if (!((parentPage + 1) * props.parentPage < max)) {
              return;
            }
            setParentPage(parentPage + 1);
            props.onPaging(1 + props.parentPage * (parentPage + 1));
          }}
        >
          <NextPageIcon strokeColor={props.strokeColor} />
        </button>
      </div>
    </>
  );
};

export default Pagination;
