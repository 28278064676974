export default function PauseIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_868_79376)">
        <rect x="6" y="4" width="4" height="16" rx="2" fill="#222222" />
        <rect x="14" y="4" width="4" height="16" rx="2" fill="#222222" />
      </g>
      <defs>
        <clipPath id="clip0_868_79376">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
