import { useEffect, useState } from 'react';
import { ServiceUsageWorkcenterList } from '../../../array/myPageArray';
import { getServiceListApi } from '../../../services/PrivateService';
import { UnionListClientReqType } from '../../../services/TypeOfPrivateService';
import { siteLangCd } from '../../../services/defaultClient';
import Loader from '../../common/Loader';
import UsageCard from './UsageCard';

export default function ServiceUsageWorkcenterSection() {
  const [serviceUsageWorkcenterList, setServiceUsageWorkcenterList] = useState(
    ServiceUsageWorkcenterList
  );
  const [loading, setLoading] = useState(true);

  const getList = () => {
    const reqBody: UnionListClientReqType = {
      siteLangCd,
      serviceCd: 'B11', // 워크센터
      pageNo: 0,
      perPage: 100,
    };

    getServiceListApi(reqBody)
      .then((res) => {
        const resultValue = res.data.data.resultValue;

        setServiceUsageWorkcenterList((prevData) =>
          prevData.map((item) => {
            return {
              ...item,
              data: item.data.map((dataItem) => {
                switch (dataItem.label) {
                  case '인바운드':
                    return {
                      ...dataItem,
                      value: resultValue['Service.All.Contact.In.Count'],
                    };
                  case '아웃바운드':
                    return {
                      ...dataItem,
                      value: resultValue['Service.All.Contact.Out.Count'],
                    };
                  case 'SMS':
                    return {
                      ...dataItem,
                      value: resultValue['Service.All.Sms.Out.Count'],
                    };
                  case 'LMS':
                    return {
                      ...dataItem,
                      value: resultValue['Service.All.Lms.Out.Count'],
                    };
                  case 'MMS':
                    return {
                      ...dataItem,
                      value: resultValue['Service.All.Mms.Out.Count'],
                    };
                  case '알림톡':
                    return {
                      ...dataItem,
                      value: resultValue['Service.All.Kakao.Noti.Out.Count'],
                    };
                  case '친구톡':
                    return {
                      ...dataItem,
                      value: resultValue['Service.All.Kakao.Friend.Out.Count'],
                    };
                  case '채팅':
                    return {
                      ...dataItem,
                      value: resultValue['Service.All.Chat.In.Count'],
                    };
                  case '이메일':
                    return {
                      ...dataItem,
                      value: resultValue['Service.All.Email.Out.Count'],
                    };
                  default:
                    return dataItem;
                }
              }),
            };
          })
        );
      })
      .catch(console.log)
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getList();
  }, []);

  return (
    <>
      <Loader dimEffect={false} loading={loading} />
      {!loading && (
        <section className="grid grid-cols-3 max-2xl:grid-cols-2 max-md:grid-cols-1 gap-5 max-md:gap-3">
          {serviceUsageWorkcenterList.map((data, idx) => (
            <UsageCard key={`dataCard-${idx}`} data={data} />
          ))}
        </section>
      )}
    </>
  );
}
