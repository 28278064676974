import { atom } from 'recoil';

const localStorageEffect = (key: string) => ({ setSelf, onSet }: any) => {
    if (typeof window !== 'undefined') {
      const savedValue = localStorage.getItem(key);
      // setSelf -> Callbacks to set or reset the value of the atom.
      if (savedValue != null) {
        setSelf(JSON.parse(savedValue));
      }
    
      // onSet -> Subscribe to changes in the atom value.
      onSet((newValue: any, _: any, isReset: boolean) => {
        isReset
          ? localStorage.removeItem(key)
          : localStorage.setItem(key, JSON.stringify(newValue));
      });
    }
  };

export { localStorageEffect };