export default function UsagePhoneIcon() {
  return (
    <svg
      width="90"
      height="90"
      viewBox="0 0 90 90"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="45" cy="45" r="45" fill="white" />
      <path
        d="M38.1232 31.6512L39.6414 34.3716C41.0115 36.8265 40.4615 40.047 38.3036 42.2049C38.3036 42.2049 38.3036 42.2049 38.3036 42.2049C38.3036 42.205 35.6863 44.8224 40.4319 49.568C45.1771 54.3132 47.7946 51.6967 47.795 51.6963C47.795 51.6963 47.795 51.6963 47.795 51.6963C49.9529 49.5384 53.1734 48.9884 55.6284 50.3585L58.3487 51.8767C62.0557 53.9455 62.4935 59.1442 59.2351 62.4027C57.2772 64.3606 54.8786 65.8841 52.2272 65.9846C47.7636 66.1538 40.1834 65.0242 32.5796 57.4204C24.9758 49.8165 23.8461 42.2363 24.0153 37.7728C24.1158 35.1213 25.6393 32.7227 27.5973 30.7648C30.8557 27.5064 36.0544 27.9442 38.1232 31.6512Z"
        stroke="#FF6D51"
        strokeWidth="1.6"
        strokeLinecap="round"
      />
      <path
        d="M51.1287 25.0222C51.2361 24.3586 51.8637 23.9084 52.5274 24.0158C52.5685 24.0237 52.7007 24.0484 52.77 24.0638C52.9085 24.0947 53.1018 24.1422 53.3427 24.2123C53.8245 24.3526 54.4975 24.5837 55.3054 24.954C56.9227 25.6952 59.0751 26.992 61.3085 29.2248C63.542 31.4577 64.8391 33.6094 65.5806 35.2263C65.951 36.0339 66.1821 36.7067 66.3225 37.1884C66.3927 37.4292 66.4401 37.6224 66.471 37.7609C66.4864 37.8302 66.4977 37.8858 66.5056 37.9268L66.5149 37.9775C66.6224 38.6411 66.1762 39.2943 65.5124 39.4018C64.8505 39.5089 64.2269 39.0609 64.1168 38.4004C64.1134 38.3827 64.1041 38.335 64.0941 38.2901C64.0741 38.2002 64.0394 38.0577 63.9845 37.8692C63.8747 37.4921 63.6839 36.9317 63.367 36.2408C62.7342 34.8608 61.5962 32.9552 59.5867 30.9462C57.5771 28.9372 55.6709 27.7996 54.2905 27.1669C53.5995 26.8502 53.0389 26.6594 52.6617 26.5496C52.4731 26.4947 52.2361 26.4403 52.1462 26.4203C51.4855 26.3102 51.0215 25.6839 51.1287 25.0222Z"
        fill="url(#paint0_linear_1240_89770)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M51.496 30.6204C51.6807 29.9741 52.3546 29.5998 53.0012 29.7845L52.6667 30.9548C53.0012 29.7845 53.0012 29.7845 53.0012 29.7845L53.0035 29.7851L53.006 29.7859L53.0114 29.7874L53.024 29.7912L53.0561 29.8012C53.0806 29.809 53.1112 29.8192 53.1476 29.8321C53.2205 29.858 53.3169 29.8948 53.4351 29.9454C53.6716 30.0468 53.9947 30.2032 54.3917 30.4381C55.1863 30.9082 56.271 31.6892 57.5453 32.9631C58.8197 34.2371 59.6008 35.3215 60.0711 36.1159C60.306 36.5127 60.4625 36.8358 60.5639 37.0722C60.6145 37.1904 60.6513 37.2867 60.6772 37.3596C60.6901 37.396 60.7003 37.4266 60.7082 37.4511L60.7182 37.4832L60.7219 37.4957L60.7235 37.5012L60.7242 37.5036C60.7242 37.5036 60.7249 37.506 59.5542 37.8404L60.7249 37.506C60.9096 38.1524 60.5352 38.8261 59.8887 39.0107C59.2476 39.1939 58.5797 38.8275 58.3883 38.1912L58.3823 38.1737C58.3736 38.1493 58.3557 38.1013 58.3257 38.0312C58.2656 37.891 58.1567 37.6618 57.9755 37.3558C57.6135 36.7443 56.9598 35.8205 55.8235 34.6845C54.6872 33.5485 53.763 32.895 53.1514 32.5331C52.8453 32.3519 52.616 32.243 52.4758 32.183C52.4057 32.1529 52.3577 32.135 52.3333 32.1264L52.3158 32.1204C51.6793 31.9291 51.3128 31.2613 51.496 30.6204Z"
        fill="url(#paint1_linear_1240_89770)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1240_89770"
          x1="51.1133"
          y1="24"
          x2="62.3746"
          y2="42.0156"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF478B" />
          <stop offset="1" stopColor="#FFBB54" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1240_89770"
          x1="51.1133"
          y1="24"
          x2="62.3746"
          y2="42.0156"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF478B" />
          <stop offset="1" stopColor="#FFBB54" />
        </linearGradient>
      </defs>
    </svg>
  );
}
