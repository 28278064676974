export default function DownloadIcon() {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.3771 14.223C10.2821 14.3321 10.1446 14.3947 10 14.3947C9.85545 14.3947 9.71794 14.3321 9.62298 14.223L6.28903 10.395C6.10767 10.1868 6.12946 9.87097 6.33769 9.68961C6.54593 9.50825 6.86176 9.53004 7.04312 9.73827L9.50003 12.5593V4.26953C9.50003 3.99339 9.72389 3.76953 10 3.76953C10.2762 3.76953 10.5 3.99339 10.5 4.26953V12.5593L12.9569 9.73827C13.1383 9.53004 13.4541 9.50825 13.6624 9.68961C13.8706 9.87097 13.8924 10.1868 13.711 10.395L10.3771 14.223ZM2.99805 12.0202C2.99805 11.744 2.77419 11.5202 2.49805 11.5202C2.22191 11.5202 1.99805 11.744 1.99805 12.0202V12.0561V12.0561C1.99804 13.263 1.99803 14.2174 2.09364 14.9639C2.19174 15.7299 2.39822 16.3525 2.86838 16.846C3.34181 17.343 3.94448 17.5644 4.68541 17.669C5.40126 17.77 6.31436 17.77 7.45996 17.77H7.49898H12.4999H12.5389C13.6845 17.77 14.5976 17.77 15.3135 17.669C16.0544 17.5644 16.6571 17.343 17.1305 16.846C17.6007 16.3525 17.8071 15.7299 17.9052 14.9639C18.0009 14.2174 18.0008 13.263 18.0008 12.0561V12.0202C18.0008 11.744 17.777 11.5202 17.5008 11.5202C17.2247 11.5202 17.0008 11.744 17.0008 12.0202C17.0008 13.271 16.9999 14.1613 16.9133 14.8369C16.8283 15.5005 16.6686 15.8811 16.4064 16.1563C16.1475 16.4281 15.7946 16.5912 15.1737 16.6788C14.5358 16.7688 13.6935 16.77 12.4999 16.77H7.49898C6.30543 16.77 5.46303 16.7688 4.82516 16.6788C4.20427 16.5912 3.8514 16.4281 3.59245 16.1563C3.33024 15.8811 3.17054 15.5005 3.08554 14.8369C2.99901 14.1613 2.99805 13.271 2.99805 12.0202Z"
        fill="#222222"
      />
    </svg>
  );
}
