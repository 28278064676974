export default function OrangePlayIcon() {
  return (
    <svg
      width="13"
      height="15"
      viewBox="0 0 13 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 5.76795C13.3333 6.53775 13.3333 8.46225 12 9.23205L3.75 13.9952C2.41667 14.765 0.75 13.8027 0.75 12.2631V2.73686C0.75 1.19726 2.41667 0.235009 3.75 1.00481L12 5.76795Z"
        fill="#FF6D51"
      />
    </svg>
  );
}
