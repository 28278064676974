import moment from 'moment';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ENV } from '../../../env';
import {
  getEnterpriseLists,
  getMembershipLists,
  reqGetPayToken,
} from '../../../services/PrivateService';
import {
  EnterpriseListDataType,
  MembershipListDataType,
} from '../../../services/TypeOfPrivateService';
import { siteLangCd } from '../../../services/defaultClient';
import {
  PAYSYSTEM_COMSEQ,
  PAYSYSTEM_MEMSEQ,
  PAYSYSTEM_TOKEN,
} from '../../../services/paySystem';
import { getDaysDiff } from '../../hooks/useTime';

const inActiveStatusArr = ['B20113', 'B20131', 'B20108', 'B20118'];

export default function MembershipCardSection({
  setSubCancel,
  setPayModal,
  setProdCancel,
  deleteKey,
}: {
  setSubCancel: Dispatch<
    SetStateAction<{
      isOpen: boolean;
      itemName: string;
      limitDtTo: string;
      pkPpCard: number;
    }>
  >;
  setPayModal: Dispatch<SetStateAction<boolean>>;
  setProdCancel: Dispatch<
    SetStateAction<{
      isOpen: boolean;
      limitDtTo: string;
      itemName: string;
      costPayback: number;
      cost: number;
      creditPolicy: number;
      creditPolicyFree: number;
      creditFree: number;
      creditUsedRate: number;
      credit: number;
      pkPpCard: number;
    }>
  >;
  deleteKey: number;
}) {
  const [loading, setLoading] = useState({
    membership: true,
    enterprise: true,
  });
  const [membershipList, setMembershipList] = useState<
    MembershipListDataType[]
  >([]);
  const [enterpriseList, setEnterpriseList] = useState<
    EnterpriseListDataType[]
  >([]);

  const navigate = useNavigate();
  const totalCard =
    (enterpriseList?.length ? enterpriseList.length : 0) +
    (membershipList?.length ? membershipList.length : 0);
  const { t } = useTranslation();

  useEffect(() => {
    getMembershipLists({
      siteLangCd,
      returnType: 0,
    })
      .then((res) =>
        setMembershipList(
          res.data.data.pageList.filter(
            (item: MembershipListDataType) => item.payYn === 'Y'
          )
        )
      )
      .catch(console.log)
      .finally(() =>
        setLoading((prev) => ({
          ...prev,
          membership: false,
        }))
      );
    getEnterpriseLists({
      siteLangCd,
      returnType: 0,
    })
      .then((res) => {
        if (res.data.result === -1002) {
          return;
        }
        setEnterpriseList(res.data.data);
      })
      .catch(console.log)
      .finally(() =>
        setLoading((prev) => ({
          ...prev,
          enterprise: false,
        }))
      );
  }, [deleteKey]);

  useEffect(() => {
    if (membershipList.length > 0 && enterpriseList.length > 0) {
      const itemCdArray = membershipList.map((item) => item.itemCd);
      const filterList = enterpriseList.filter(
        (item) => !itemCdArray.includes(item.ppCardCd)
      );
      setEnterpriseList(filterList);
    }
  }, [membershipList, enterpriseList]);

  const setModal = (
    subscYn: string,
    payDate: string,
    limitDtTo: string,
    itemName: string,
    costPayback: number,
    cost: number,
    creditPolicy: number,
    creditPolicyFree: number,
    creditFree: number,
    creditUsedRate: number,
    credit: number,
    pkPpCard: number
  ) => {
    subscYn === 'N' && getDaysDiff(payDate) < 30
      ? setProdCancel({
          isOpen: true,
          limitDtTo,
          itemName,
          costPayback,
          cost,
          creditPolicy,
          creditPolicyFree,
          creditFree,
          creditUsedRate,
          credit,
          pkPpCard,
        })
      : setSubCancel({ isOpen: true, itemName, limitDtTo, pkPpCard });
  };

  const openPayWindow = async (ppCardCd: string, ppCardType: string) => {
    let payToken;
    await reqGetPayToken()
      .then((res) => {
        const data = res.data.data;
        localStorage.setItem(PAYSYSTEM_COMSEQ, data.companySeq);
        localStorage.setItem(PAYSYSTEM_MEMSEQ, data.memberSeq);
        localStorage.setItem(PAYSYSTEM_TOKEN, data.authToken);
        payToken = data.authToken;
      })
      .catch((err) => alert(err));

    const commonPaymentUrl =
      ENV.REACT_APP_SERVICE_MODE === 'production'
        ? `https://paygw.ploonet.com/work/TokenLogin?token=${payToken}&redirect=/home/mypage`
        : `https://dev-pgw.ploonet.com/work/TokenLogin?token=${payToken}&redirect=/home/mypage`;

    const paymentUrl = `${commonPaymentUrl}/${
      ppCardType === 'SUBSC_MAIN' ? 'creditPlanNew' : 'creditNew'
    }?servicePlan=${ppCardCd}&type=B15`;

    window.open(paymentUrl, '결제팝업', 'width=760px,height=726px');
  };

  const isActive = (itemStatus: string, credit: number) => {
    return inActiveStatusArr.includes(itemStatus) || !credit ? false : true;
  };

  const getInactiveButtonText = (itemStatus: string, subscYn: string) => {
    if (subscYn === 'N' && itemStatus === 'B20113') {
      return t('취소완료');
    }
    if (inActiveStatusArr.includes(itemStatus)) {
      switch (itemStatus) {
        case 'B20113':
          return t('해지완료');
        case 'B20118':
          return t('해지완료');
        case 'B20131':
          return t('취소완료');
        case 'B20108':
          return t('사용완료');
      }
    } else {
      return t('사용완료');
    }
  };

  const getButtonText = (subscYn: string, payDate: string) => {
    if (subscYn === 'Y') {
      return t('해지하기');
    } else if (subscYn === 'N' && getDaysDiff(payDate) < 30) {
      return t('취소하기');
    } else {
      return '';
    }
  };

  return (
    <>
      {loading.enterprise || loading.membership ? (
        <section className="grid grid-cols-3 max-2xl:grid-cols-2 max-md:grid-cols-1 gap-5 max-md:gap-3">
          <MembershipSkeleton />
        </section>
      ) : (
        <section className="grid grid-cols-3 max-2xl:grid-cols-2 max-md:grid-cols-1 gap-5 max-md:gap-3">
          {enterpriseList?.length
            ? enterpriseList.map(
                ({ cost, planSnum, ppCardCd, ppCardName, ppCardType }, idx) => (
                  <div
                    key={`membership-${idx}`}
                    className="bg-gradient-to-br from-[#9b63f8] to-[#7280FB] w-full rounded-[20px] min-h-[260px] max-md:min-h-[220px] py-10 max-md:py-[34px] px-9 max-md:px-[30px]"
                  >
                    <div className="text-white font-bold flex items-center justify-between">
                      <h1 className="text-2xl max-md:text-xl">
                        {t(ppCardName)}
                      </h1>
                      <span className="text-sm py-2 px-4 bg-white/20 rounded-full max-md:text-xs">
                        {t('상품')}
                      </span>
                    </div>
                    <button
                      onClick={() => openPayWindow(ppCardCd, ppCardType)}
                      className="mt-4 text-white/80 underline hover:text-white duration-100 font-medium max-md:text-sm"
                    >
                      {t('결제하기')}
                    </button>
                    <div className="pt-5 space-y-4 max-md:space-y-3 border-t border-white/20 text-white mt-6 max-md:text-sm"></div>
                  </div>
                )
              )
            : null}
          {membershipList?.length
            ? membershipList.map(
                (
                  {
                    itemName,
                    subscYn,
                    custYn,
                    limitDtFrom,
                    limitDtTo,
                    payDtTo,
                    itemStatus,
                    credit,
                    costPayback,
                    cost,
                    creditPolicy,
                    creditPolicyFree,
                    creditFree,
                    creditUsedRate,
                    pkPpCard,
                  },
                  idx
                ) => (
                  <div
                    key={`membership-${idx}`}
                    className={`bg-gradient-to-br ${
                      !isActive(itemStatus, credit)
                        ? 'from-gray5 to-gray5'
                        : subscYn === 'Y'
                        ? 'from-[#F05F82] to-[#FF7E7E]'
                        : custYn === 'Y'
                        ? 'from-[#9b63f8] to-[#7280FB]'
                        : 'from-primary to-primaryTo'
                    }  w-full rounded-[20px] min-h-[260px] max-md:min-h-[220px] py-10 max-md:py-[34px] px-9 max-md:px-[30px]`}
                  >
                    <div
                      className={`${
                        !isActive(itemStatus, credit)
                          ? 'text-black5'
                          : 'text-white'
                      } font-bold flex items-center justify-between`}
                    >
                      <h1 className="text-2xl max-md:text-xl">{t(itemName)}</h1>
                      <span
                        className={`text-sm py-2 px-4 ${
                          !isActive(itemStatus, credit)
                            ? 'bg-gray3'
                            : 'bg-white/20'
                        } rounded-full max-md:text-xs`}
                      >
                        {t(subscYn === 'Y' ? '구독' : '상품')}
                      </span>
                    </div>
                    <button
                      disabled={!isActive(itemStatus, credit)}
                      onClick={() =>
                        setModal(
                          subscYn,
                          limitDtFrom,
                          limitDtTo,
                          itemName,
                          costPayback,
                          cost,
                          creditPolicy,
                          creditPolicyFree,
                          creditFree,
                          creditUsedRate,
                          credit,
                          pkPpCard
                        )
                      }
                      className={`mt-4 ${
                        !isActive(itemStatus, credit)
                          ? 'text-gray1 cursor-default'
                          : 'text-white/80 underline hover:text-white duration-100'
                      } font-medium max-md:text-sm`}
                    >
                      {!isActive(itemStatus, credit)
                        ? getInactiveButtonText(itemStatus, subscYn)
                        : getButtonText(subscYn, limitDtFrom)}
                    </button>
                    <div
                      className={`pt-5 space-y-4 max-md:space-y-3  border-t ${
                        !isActive(itemStatus, credit)
                          ? 'border-gray3 text-gray1'
                          : 'border-white/20 text-white'
                      } mt-6 max-md:text-sm`}
                    >
                      <div className="flex justify-between">
                        <span
                          className={`${
                            !isActive(itemStatus, credit)
                              ? 'text-gray1'
                              : 'text-white/80'
                          }`}
                        >
                          {t('사용기간')}
                        </span>
                        <span className="font-medium">
                          {moment(limitDtFrom).format('YYYY. MM. DD')} ~{' '}
                          {moment(limitDtTo).format('YYYY. MM. DD')}
                        </span>
                      </div>
                      {subscYn === 'Y' && (
                        <div className="flex justify-between">
                          <span
                            className={`${
                              !isActive(itemStatus, credit)
                                ? 'text-gray1'
                                : 'text-white/80'
                            }`}
                          >
                            {t('다음 결제 예정일')}
                          </span>
                          <span className="font-medium">
                            {moment(payDtTo).format('YYYY. MM. DD')}
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                )
              )
            : null}
          {!totalCard ? (
            <div className="w-full min-h-[260px] max-md:min-h-[220px] bg-gray5 rounded-[20px] flex justify-center items-center col-span-3">
              <div className="flex flex-col space-y-5 items-center">
                <span className="text-black text-xl max-2xl:text-lg max-md:text-base text-center max-md:whitespace-pre-wrap">
                  {t('나에게 알맞은 유료 요금제를 둘러보세요.')}
                </span>
                <button
                  onClick={() => navigate('/plan')}
                  className="bg-black2 rounded-lg text-white w-fit px-5 py-3"
                >
                  {t('요금제 보러가기')}
                </button>
              </div>
            </div>
          ) : null}
        </section>
      )}
    </>
  );
}

function MembershipSkeleton() {
  const arr = [0, 1, 2];
  return (
    <>
      {arr.map((_, idx) => (
        <div
          key={`skeleton-${idx}`}
          className="bg-gray5 animate-pulse w-full rounded-[20px] min-h-[260px] max-md:min-h-[220px] py-10 max-md:py-[34px] px-9 max-md:px-[30px]"
        >
          <div className="text-black font-bold flex items-center justify-between">
            <div className="w-20 h-6 bg-gray3 animate-pulse rounded-full"></div>
            <div className="w-10 h-4 bg-gray2 animate-pulse rounded-full"></div>
          </div>
          <div className="mt-4 w-10 h-4 bg-gray2 animate-pulse rounded-full"></div>
          <div className="pt-5 space-y-4 max-md:space-y-3  border-t border-white/20 text-black mt-6 max-md:text-sm">
            <div className="flex justify-between">
              <div className="w-32 h-2 bg-gray1 animate-pulse rounded-full"></div>
              <div className="w-10 h-2 bg-gray2 animate-pulse rounded-full"></div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
}
