import 'aos/dist/aos.css';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import SwiperCore, { Navigation, Pagination } from 'swiper';
import 'swiper/css/navigation';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';
import { getNoticeRequest } from '../../services/PublicService';
import {
  NoticeListClientDataType,
  NoticeListClientReqType,
} from '../../services/TypeOfPublicService';
import { fileBaseURL, siteLangCd } from '../../services/defaultClient';
import HomeButton from '../common/HomeButtons';
import ImageBox from '../notice/ImageBox';
import ChevronRight from '../vector/workcenter/ChevronRight';

export default function NewsSection() {
  SwiperCore.use([Navigation, Pagination]);
  const [isReachedEnd, setIsReachedEnd] = useState(false);
  const swiperRef = useRef<any>();
  const [noticeList, setNoticeList] = useState<NoticeListClientDataType[]>([]);
  const { t } = useTranslation();

  useEffect(() => {
    const reqBody: NoticeListClientReqType = {
      siteLangCd,
      mainPageYn: 'Y',
      pageNo: 1,
      serviceCd: '',
      noticeCategoryCd: '',
    };
    getNoticeRequest(reqBody)
      .then((res) => {
        const pageList = res.data.data?.pageList;
        setNoticeList(pageList);
      })
      .catch(console.log);
  }, []);

  const getThumbnailUrl = (thumbnailPath: string, category: string) => {
    let url;
    if (thumbnailPath) {
      url = fileBaseURL + thumbnailPath;
    } else {
      switch (category) {
        case '공지':
          return '/notice/default_notice.png';
        case '뉴스':
          return '/notice/default_news.png';
        case '이벤트':
          return '/notice/default_event.png';
        default:
          return '/notice/default_notice.png';
      }
    }
    return url;
  };

  return (
    <>
      <main className="bg-black max-md:px-5">
        <section className="max-w-[1600px] max-2xl:max-w-[1200px] mx-auto space-y-20 max-md:space-y-[3.75rem]">
          <div className="flex justify-between w-full">
            <div className="w-full flex items-center">
              <div className="text-white flex items-end justify-between w-full max-md:flex-col max-md:items-start">
                <div className="text-[70px] leading-tight max-2xl:text-5xl max-2xl:leading-snug max-md:text-[40px] max-md:leading-tight max-md:mb-6">
                  <h1 className="font-thin">
                    {t('플루닛의')}{' '}
                    <strong className="font-bold block">
                      {t('새로운 소식을 ')}
                      <br className="lg:hidden" />
                      {t('전해드려요.')}
                    </strong>
                  </h1>
                </div>
                <Link to="/notice">
                  <div className="p-[1px] bg-gradient-to-br from-white to-white rounded-lg hover:from-primary hover:to-primaryTo">
                    <HomeButton icon={true} color="main white">
                      {t('새로운 소식 더보기')}
                    </HomeButton>
                  </div>
                </Link>
              </div>
            </div>
          </div>
          <div className="flex relative">
            <button
              onClick={() => swiperRef.current?.slidePrev()}
              className="rotate-180 max-md:hidden absolute -left-20 top-32 max-2xl:top-24"
            >
              <ChevronRight color={!isReachedEnd ? 'gray' : 'white'} />
            </button>
            <Swiper
              onBeforeInit={(swiper) => {
                swiperRef.current = swiper;
              }}
              onReachEnd={() => setIsReachedEnd(true)}
              onReachBeginning={() => setIsReachedEnd(false)}
              spaceBetween={26}
              slidesPerView={1.5}
              breakpoints={{
                768: {
                  slidesPerView: 3,
                  slidesPerGroup: 3,
                  pagination: false,
                },
              }}
              pagination={true}
            >
              {noticeList.map((item, idx) => (
                <SwiperSlide key={`notice-${idx}`}>
                  <div className="text-white max-md:hidden">
                    <div className="flex flex-col w-full space-y-6 text-left group">
                      <Link to={`notice/${item.noticeUuid}`}>
                        <div className="space-y-4">
                          <ImageBox
                            category={item.noticeCategoryCdNm}
                            thumbnailPath={item.thumbnailPath}
                          />
                          <p className="text-[26px] max-2xl:text-xl font-bold line-clamp-2">
                            {item.noticeTit}
                          </p>
                          <h3 className="text-white text-opacity-[0.6]">
                            {moment(item.registYmd).format('YYYY. MM. DD')}
                          </h3>
                        </div>
                      </Link>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
              {noticeList.length === 1 ? (
                <>
                  <SwiperSlide>
                    <div className="text-white max-md:hidden w-full" />
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="text-white max-md:hidden w-full" />
                  </SwiperSlide>
                </>
              ) : noticeList.length < 3 ? (
                <SwiperSlide>
                  <div className="text-white max-md:hidden w-full" />
                </SwiperSlide>
              ) : null}
            </Swiper>
            <button
              className="max-md:hidden absolute -right-20 top-32 max-2xl:top-24"
              onClick={() => swiperRef.current?.slideNext()}
            >
              <ChevronRight color={isReachedEnd ? 'gray' : 'white'} />
            </button>
          </div>
          <div className="text-white hidden max-md:flex h-full max-md:h-60 max-md:!mt-0">
            <Swiper className="relative" spaceBetween={24} slidesPerView={1.3}>
              {noticeList.map((item, idx) => (
                <SwiperSlide key={`mobileNotice-${idx}`}>
                  <Link to={`notice/${item.noticeUuid}`}>
                    <div
                      style={{
                        backgroundImage: `url(${getThumbnailUrl(
                          item.thumbnailPath,
                          item.noticeCategoryCdNm
                        )})`,
                      }}
                      className="w-full h-40 mb-3 bg-cover bg-no-repeat bg-center space-y-3 text-left group hover:opacity-60 duration-300 ease-out rounded-[8px]"
                    />
                    <div className="space-y-2">
                      <h1 className="text-base font-bold">{item.noticeTit}</h1>
                      <h3 className="text-white text-xs text-opacity-[0.6]">
                        {moment(item.registYmd).format('YYYY.MM.DD')}
                      </h3>
                    </div>
                  </Link>
                </SwiperSlide>
              ))}
              {noticeList.length < 3 && (
                <SwiperSlide>
                  <div className="text-white max-md:hidden w-full" />
                </SwiperSlide>
              )}
            </Swiper>
          </div>
        </section>
      </main>
    </>
  );
}
