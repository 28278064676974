import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { userinfoAtom } from '../../../store';
import Button from '../../common/Buttons';
import LoginModal from '../../common/modal/LoginModal';

export default function TotalInfoHeading() {
  const [loginModal, setLoginModal] = useState(false);
  const screenSize = window.innerWidth;
  const navigate = useNavigate();
  const { language } = useRecoilValue(userinfoAtom);
  const { t } = useTranslation();

  return (
    <>
      <LoginModal modal={loginModal} closeModal={() => setLoginModal(false)} />
      <section className="flex flex-col space-y-7 max-md:space-y-3">
        <h1
          className={`text-[44px] max-2xl:text-4xl max-md:text-[26px] ${
            language === 'kr' ? 'font-nomral' : 'font-bold'
          }`}
        >
          <strong
            className={`max-md:block ${
              language === 'kr' ? 'font-bold' : 'font-normal'
            }`}
          >
            {t('플루닛 통합 ID 하나면')}{' '}
          </strong>
          {t('다양한 서비스가 한 손안에 쏙~')}
        </h1>

        <div
          className={`w-full flex ${
            language === 'en'
              ? 'flex-row justify-between items-center'
              : 'flex-row justify-between items-center'
          } max-md:flex-col max-md:space-y-8 `}
        >
          <p className="text-2xl !leading-relaxed max-2xl:text-lg max-md:text-base 2xl:whitespace-pre-wrap">
            {t(
              '플루닛 통합회원이 되어 보유한 크레딧으로 모든 서비스를 자유롭게 이용해 보세요.'
            )}{' '}
            <br className="block max-md:hidden" />
            {t('이용할 수 있는 서비스는 앞으로 더욱 다양해질 예정입니다.')}
          </p>
          <div
            className={`flex max-md:w-full space-x-3 ${
              language === 'en'
                ? 'max-md:flex-col max-md:space-y-3 max-md:space-x-0'
                : ''
            }`}
          >
            {language === 'kr' ? (
              <Button onClick={() => navigate('/signup')} color="white small">
                {t('통합회원가입')}
              </Button>
            ) : (
              <Button
                onClick={() => navigate('/signup')}
                color="white small-en"
              >
                {t('통합회원가입')}
              </Button>
            )}

            <Button
              onClick={() =>
                screenSize < 769 ? navigate('/login') : setLoginModal(true)
              }
              color="small"
            >
              {t('로그인')}
            </Button>
          </div>
        </div>
      </section>
    </>
  );
}
