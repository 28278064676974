import { ReactNode, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
  getCreditsLeft,
  getMembershipsTotal,
  getMyInfo,
} from '../../services/PrivateService';
import {
  currentCredit,
  currentMembership,
  me,
  userinfoAtom,
} from '../../store';

import { useTranslation } from 'react-i18next';
import { navBarArray, navListArray } from '../../array/myPageArray';
import i18n from '../../locale/i18n';
import { siteLangCd } from '../../services/defaultClient';
import OrangeArrowIcon from '../vector/home/OrangeArrowIcon';
import CircleRightArrowIcon from '../vector/mypage/CircleRightArrowIcon';

export default function MyPageLayout({ children }: { children: ReactNode }) {
  const setMyInfo = useSetRecoilState(me);
  const [myCredit, setMyCredit] = useRecoilState(currentCredit);
  const [myMembership, setMyMembership] = useRecoilState(currentMembership);
  const pathName = window.location.pathname;
  const navigate = useNavigate();
  const { t } = useTranslation();
  const userInfo = useRecoilValue(userinfoAtom);

  useEffect(() => {
    i18n.changeLanguage(userInfo.language);
    getMyInfo({
      siteLangCd,
    })
      .then((res) => setMyInfo(res.data.data))
      .catch((err) => alert(err));

    getCreditsLeft({ siteLangCd, returnType: 0 })
      .then((res) => setMyCredit(res.data.data))
      .catch(console.log);

    getMembershipsTotal({ siteLangCd, returnType: 0 })
      .then((res) => setMyMembership(res.data.data))
      .catch(console.log);
  }, []);

  const getInfo = (idx: number) => {
    if (!myCredit || !myMembership) {
      return;
    }
    let value;
    if (idx === 1) {
      value = myCredit.creditLefts ? myCredit.creditLefts : 0;
    } else {
      value = myMembership.totalCount ? myMembership.totalCount : 0;
    }
    return value.toLocaleString();
  };

  return (
    <section className="mt-40 pt-8 max-md:pt-[62px] mb-60 max-md:mt-5 max-md:mb-24 max-w-[1600px] max-2xl:max-w-[1200px] w-full px-4 mx-auto max-md:px-5">
      <section className="flex space-x-20 max-md:space-x-0 max-md:justify-center">
        <div className="flex flex-col space-y-10 min-w-[280px] max-2xl:min-w-[220px] max-md:hidden">
          <h1 className="font-bold text-4xl mb-3">Mypage</h1>
          <section className="space-y-3">
            {navBarArray.map(({ title }, idx) => (
              <div key={`nav-${idx}`} className="flex justify-between">
                <span className="text-xl text-[#aaaaaa]">{t(title)}</span>
                <div
                  onClick={() => idx === 0 && navigate('/my-page/membership')}
                  className="flex items-center space-x-2 group cursor-pointer"
                >
                  <span className="text-xl font-bold">{getInfo(idx)}</span>
                  {title === '유료 요금제' && (
                    <i className="group-hover:translate-x-[2px] duration-200 ease-out">
                      <CircleRightArrowIcon />
                    </i>
                  )}
                </div>
              </div>
            ))}
          </section>
          <section>
            <ul>
              {navListArray.map(({ menu, link }, idx) => (
                <Link key={`navMenu-${idx}`} to={`/my-page${link}`}>
                  <li
                    className={`${
                      idx === 0 ? 'border-y' : 'border-b'
                    } flex items-center justify-between  border-borderGray py-6 text-xl cursor-pointer hover:text-[#ff7a51bd] ${
                      pathName.includes(link) ? 'text-[#FF7B51]' : ''
                    } duration-100 ease-out`}
                  >
                    <span>{t(menu)}</span>
                    {pathName.includes(link) && <OrangeArrowIcon />}
                  </li>
                </Link>
              ))}
            </ul>
          </section>
        </div>
        {children}
      </section>
    </section>
  );
}
