export default function FooterLogoIcon() {
  return (
    <svg
      width="599"
      height="124"
      viewBox="0 0 599 124"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M42.84 22.8C53.88 22.8 61.56 29.04 61.56 38.04C61.56 46.92 53.88 53.28 42.84 53.28H29.16V22.8H42.84ZM46.44 68.52C67.32 68.52 81.72 56.04 81.72 38.04C81.72 20.16 67.32 7.56 46.68 7.56H9.72V93H29.16V68.52H46.44ZM149.49 93V75.72H113.73V7.56H93.57V93H149.49ZM202.107 96C230.067 96 249.387 77.4 249.387 50.28C249.387 23.28 230.067 4.55999 202.107 4.55999C174.027 4.55999 154.827 23.28 154.827 50.28C154.827 77.4 174.027 96 202.107 96ZM202.107 21.84C218.067 21.84 229.227 33.6 229.227 50.28C229.227 66.96 218.067 78.72 202.107 78.72C186.147 78.6 174.987 66.96 174.987 50.28C174.987 33.6 186.147 21.84 202.107 21.84ZM305.762 96C333.722 96 353.042 77.4 353.042 50.28C353.042 23.28 333.722 4.55999 305.762 4.55999C277.682 4.55999 258.482 23.28 258.482 50.28C258.482 77.4 277.682 96 305.762 96ZM305.762 21.84C321.722 21.84 332.882 33.6 332.882 50.28C332.882 66.96 321.722 78.72 305.762 78.72C289.802 78.6 278.642 66.96 278.642 50.28C278.642 33.6 289.802 21.84 305.762 21.84ZM388.297 7.56H366.097V93H385.177V32.04L419.617 93H441.817V7.56H422.617V68.64L388.297 7.56ZM478.176 56.4H513.936V42H478.176V23.76H518.736V7.56H458.736V93H519.936V76.8H478.176V56.4ZM550.739 93H570.899V24.36H596.579V7.56H525.059V24.36H550.739V93Z"
        fill="white"
      />
    </svg>
  );
}
