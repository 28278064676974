import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import Lottie from 'react-lottie';
import animationData from '../../lottie/loader.json';
export default function Loader({
  loading,
  dimEffect = true,
}: {
  loading: boolean;
  dimEffect?: boolean;
}) {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  return (
    <>
      <Transition appear show={loading} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-50"
          onClose={() => {
            return;
          }}
        >
          {dimEffect && (
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black bg-opacity-25" />
            </Transition.Child>
          )}

          <div className="fixed inset-0 max-md:inset-0 rounded-xl max-md:rounded-b-none overflow-y-hidden">
            <div className="flex min-h-full items-center justify-center p-4 text-center max-md:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95 max-md:scale-90"
                enterTo="opacity-100 scale-100 max-md:scale-90"
                leave="ease-in duration-300"
                leaveFrom="opacity-100 scale-100 max-md:scale-90"
                leaveTo="opacity-0 scale-95 max-md:scale-90"
              >
                <Dialog.Panel className="w-full max-w-[600px] rounded-[20px] max-md:rounded-b-none py-6 transform overflow-hidden transition-all max-md:w-screen">
                  <Lottie
                    options={defaultOptions}
                    height={400}
                    width={400}
                    isClickToPauseDisabled
                  />
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
