export default function OneIdIcon() {
  return (
    <svg
      className="w-[108px] h-[80px] max-md:w-[86px] max-md:h-[65px]"
      viewBox="0 0 108 82"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 60.1602L65 60.1602C67.7614 60.1602 70 57.9216 70 55.1602V20.1602C70 17.3987 67.7614 15.1602 65 15.1602L15 15.1602C12.2386 15.1602 10 17.3987 10 20.1602L10 55.1602C10 57.9216 12.2386 60.1602 15 60.1602Z"
        stroke="#222222"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M50 70.1602H30"
        stroke="#222222"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M76.5713 67.6168C83.9656 67.6168 89.9598 61.6226 89.9598 54.2283C89.9598 46.8341 83.9656 40.8398 76.5713 40.8398C69.1771 40.8398 63.1829 46.8341 63.1829 54.2283C63.1829 61.6226 69.1771 67.6168 76.5713 67.6168Z"
        fill="url(#paint0_linear_1240_88106)"
        stroke="#222222"
        strokeWidth="3"
      />
      <path
        d="M55.082 80.7961C57.099 76.8305 60.1741 73.5004 63.9667 71.1744C67.7594 68.8483 72.1217 67.6172 76.5708 67.6172C81.0199 67.6172 85.3822 68.8483 89.1749 71.1743C92.9675 73.5003 96.0426 76.8304 98.0596 80.7961"
        fill="url(#paint1_linear_1240_88106)"
      />
      <path
        d="M55.082 80.7961C57.099 76.8305 60.1741 73.5004 63.9667 71.1744C67.7594 68.8483 72.1217 67.6172 76.5708 67.6172C81.0199 67.6172 85.3822 68.8483 89.1749 71.1743C92.9675 73.5003 96.0426 76.8304 98.0596 80.7961"
        stroke="#222222"
        strokeWidth="3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M95.8003 25.0156C94.1102 25.0156 92.7401 26.3857 92.7401 28.0758V32.1561H88.6598C86.9697 32.1561 85.5996 33.5262 85.5996 35.2164C85.5996 36.9065 86.9697 38.2766 88.6598 38.2766H92.7401V42.3569C92.7401 44.047 94.1102 45.4171 95.8003 45.4171C97.4905 45.4171 98.8606 44.047 98.8606 42.3569V38.2766H102.941C104.631 38.2766 106.001 36.9065 106.001 35.2164C106.001 33.5262 104.631 32.1561 102.941 32.1561H98.8606V28.0758C98.8606 26.3857 97.4905 25.0156 95.8003 25.0156Z"
        fill="url(#paint2_linear_1240_88106)"
      />
      <path
        d="M92.7401 32.1561V33.6561H94.2401V32.1561H92.7401ZM92.7401 38.2766H94.2401V36.7766H92.7401V38.2766ZM98.8606 38.2766V36.7766H97.3606V38.2766H98.8606ZM98.8606 32.1561H97.3606V33.6561H98.8606V32.1561ZM94.2401 28.0758C94.2401 27.2142 94.9387 26.5156 95.8003 26.5156V23.5156C93.2818 23.5156 91.2401 25.5573 91.2401 28.0758H94.2401ZM94.2401 32.1561V28.0758H91.2401V32.1561H94.2401ZM88.6598 33.6561H92.7401V30.6561H88.6598V33.6561ZM87.0996 35.2164C87.0996 34.3547 87.7981 33.6561 88.6598 33.6561V30.6561C86.1413 30.6561 84.0996 32.6978 84.0996 35.2164H87.0996ZM88.6598 36.7766C87.7981 36.7766 87.0996 36.078 87.0996 35.2164H84.0996C84.0996 37.7349 86.1413 39.7766 88.6598 39.7766V36.7766ZM92.7401 36.7766H88.6598V39.7766H92.7401V36.7766ZM94.2401 42.3569V38.2766H91.2401V42.3569H94.2401ZM95.8003 43.9171C94.9387 43.9171 94.2401 43.2186 94.2401 42.3569H91.2401C91.2401 44.8754 93.2818 46.9171 95.8003 46.9171V43.9171ZM97.3606 42.3569C97.3606 43.2186 96.662 43.9171 95.8003 43.9171V46.9171C98.3189 46.9171 100.361 44.8754 100.361 42.3569H97.3606ZM97.3606 38.2766V42.3569H100.361V38.2766H97.3606ZM102.941 36.7766H98.8606V39.7766H102.941V36.7766ZM104.501 35.2164C104.501 36.078 103.803 36.7766 102.941 36.7766V39.7766C105.459 39.7766 107.501 37.7349 107.501 35.2164H104.501ZM102.941 33.6561C103.803 33.6561 104.501 34.3547 104.501 35.2164H107.501C107.501 32.6978 105.459 30.6561 102.941 30.6561V33.6561ZM98.8606 33.6561H102.941V30.6561H98.8606V33.6561ZM97.3606 28.0758V32.1561H100.361V28.0758H97.3606ZM95.8003 26.5156C96.662 26.5156 97.3606 27.2142 97.3606 28.0758H100.361C100.361 25.5573 98.3189 23.5156 95.8003 23.5156V26.5156Z"
        fill="#222222"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1240_88106"
          x1="87.357"
          y1="44.0044"
          x2="63.7478"
          y2="46.2859"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FB9164" />
          <stop offset="1" stopColor="#F66587" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1240_88106"
          x1="93.8821"
          y1="69.1747"
          x2="59.0902"
          y2="80.139"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FB9164" />
          <stop offset="1" stopColor="#F66587" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1240_88106"
          x1="104.018"
          y1="27.4267"
          x2="86.0301"
          y2="29.165"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FB9164" />
          <stop offset="1" stopColor="#F66587" />
        </linearGradient>
      </defs>
    </svg>
  );
}
