import { Disclosure } from '@headlessui/react';
import { ChevronUpIcon } from '@heroicons/react/20/solid';
import { SetStateAction, useEffect, useState } from 'react';
import { UserInfoType } from '../../store';
import PostcodeModal from '../common/PostcodeModal';
import DefaultProfileIcon from '../my-page/my-info/DefaultProfileIcon';

export default function PersonalOptionalInfoTab({
  registerInfo,
  setRegisterInfo,
  setModalInfo,
}: {
  registerInfo: UserInfoType;
  setRegisterInfo: React.Dispatch<SetStateAction<UserInfoType>>;
  setModalInfo: React.Dispatch<
    SetStateAction<{
      isOpen: boolean;
      phrase: string;
      secondPhrase: string;
    }>
  >;
}) {
  const [address, setAddress] = useState({
    roadName: '',
    buildingName: '',
    zipCode: '',
  });
  const [key, setKey] = useState(0);
  const [imageUrl, setImageUrl] = useState<any>(null);
  const [openPostcodeModal, setOpenPostcodeModal] = useState(false);
  const handlePostcodeClick = () => {
    setOpenPostcodeModal(true);
  };

  useEffect(() => {
    registerInfo.profileImage
      ? setImageUrl(URL.createObjectURL(registerInfo.profileImage))
      : setImageUrl(null);
  }, [registerInfo.profileImage]);

  useEffect(() => {
    setRegisterInfo((prev) => ({
      ...prev,
      homeAddress:
        address.roadName +
        (address.buildingName ? `, ${address.buildingName}` : ''),
      zipCode: address.zipCode,
    }));
  }, [address]);

  const onChangeFileHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    } else if (e.target.files[0].size > 5e6) {
      setModalInfo({
        isOpen: true,
        phrase: '파일 제한 용량을 초과했습니다.',
        secondPhrase: '',
      });
      return;
    }
    setRegisterInfo((prev: any) => ({
      ...prev,
      profileImage: e.target.files ? e.target.files[0] : null,
    }));
  };

  const deleteProfileImage = () => {
    setRegisterInfo((prev) => ({
      ...prev,
      profileImage: null,
    }));
    setKey((prev) => prev + 1);
  };

  return (
    <>
      <div className="w-full">
        <PostcodeModal
          closeModal={() => setOpenPostcodeModal(false)}
          openPostcodeModal={openPostcodeModal}
          setAddress={setAddress}
        />
        <div className="w-full bg-white duration-200 ease-out">
          <Disclosure defaultOpen>
            {({ open }) => (
              <>
                <Disclosure.Button className="flex items-center w-full pb-3 mb-8 border-b border-black2 justify-between text-sm font-medium text-black duration-100 ease-out border-0">
                  <section className="items-center flex !justify-between w-full max-md:w-full">
                    <span className="text-xl font-bold">
                      추가 정보 입력 (선택)
                    </span>
                    <div className="flex justify-end w-1/2 h-full">
                      <div className="flex items-center space-x-6 justify-end">
                        <ChevronUpIcon
                          className={`${
                            open ? '' : 'rotate-180 transform'
                          } h-5 w-5 duration-300 ease-out`}
                        />
                      </div>
                    </div>
                  </section>
                </Disclosure.Button>
                <Disclosure.Panel className="text-sm text-gray-500 rounded-lg space-y-4">
                  <section>
                    <h1 className="text-lg font-semibold text-black2 mb-3">
                      주소
                    </h1>
                    <div className="flex flex-col w-full space-y-3">
                      <div className="w-full space-x-3 flex justify-between">
                        <input
                          className="border border-borderLightGray py-3 px-4 rounded-md min-w-[370px] w-full text-lg max-md:min-w-0 max-md:text-base text-black2"
                          disabled
                          value={
                            address.roadName &&
                            `${address.roadName}, ${address.buildingName}`
                          }
                          placeholder="주소를 검색해주세요."
                        />
                        <button
                          onClick={handlePostcodeClick}
                          className="rounded-lg border border-black hover:bg-gray5 min-w-[144px] max-md:min-w-fit max-md:px-4 text-base text-black2"
                        >
                          주소검색
                        </button>
                      </div>
                      <input
                        className="border border-borderLightGray focus:border-primary py-3 px-4 rounded-md min-w-full text-lg max-md:text-base text-black2"
                        type="text"
                        maxLength={500}
                        onChange={(e) => {
                          setRegisterInfo((prev) => ({
                            ...prev,
                            addressDetail: e.target.value,
                          }));
                        }}
                        placeholder="상세주소를 입력해주세요."
                      />
                    </div>
                  </section>
                  <section className="mt-8">
                    <h1 className="text-lg font-semibold text-black2 mb-3">
                      프로필 사진
                    </h1>
                    <div className="flex items-center space-x-5 max-md:space-x-3">
                      <label className="rounded-full w-24 h-24 cursor-pointer">
                        <div
                          style={{
                            backgroundImage: `url(${imageUrl ? imageUrl : ''})`,
                          }}
                          className="w-full h-full rounded-full bg-cover bg-center relative"
                        >
                          {!imageUrl && (
                            <i className="bg-cover w-full h-full">
                              <DefaultProfileIcon />
                            </i>
                          )}
                        </div>
                        <input
                          id="profile-image"
                          key={key}
                          type="file"
                          className="hidden"
                          accept=".png, .jpg"
                          onChange={(e) => onChangeFileHandler(e)}
                        />
                      </label>
                      <div className="flex flex-col justify-between ml-5 space-y-4 max-md:space-y-2">
                        <div className="flex space-x-3">
                          <label
                            className="border text-black2 border-borderLightGray rounded-lg hover:bg-gray5 w-fit cursor-pointer px-3 py-2 max-md:text-sm max-md:p-[10px]"
                            htmlFor="profile-image"
                          >
                            {imageUrl ? '사진 변경' : '사진 등록'}
                          </label>
                          {imageUrl && (
                            <label
                              onClick={deleteProfileImage}
                              className="border border-borderLightGray flex items-center rounded-lg hover:bg-gray5 w-fit cursor-pointer py-2 px-3 max-md:text-sm max-md:p-[10px]"
                            >
                              사진 삭제
                            </label>
                          )}
                        </div>
                        <span className="text-textGray text-sm max-md:text-xs">
                          5MB 미만 이미지 파일
                          <br className="hidden max-md:block" />
                          (png, jpg)만 첨부 가능
                        </span>
                      </div>
                    </div>
                  </section>
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
        </div>
      </div>
    </>
  );
}
