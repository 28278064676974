export default function OrangeArrowIcon({ isBig }: { isBig?: boolean }) {
  return (
    <svg
      className={`group-hover:translate-x-1 duration-150 ${
        isBig ? 'w-4 h-4' : 'w-3 h-3'
      }`}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.5 2.25L8.25 6L4.5 9.75"
        stroke="#FF6D51"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
