import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';
import { getTelInquiryVoice } from '../../services/PrivateService';
import { siteLangCd } from '../../services/defaultClient';
import PauseIcon from '../vector/common/PauseIcon';
import PlayIcon from '../vector/common/PlayIcon';

export default function AudioPlayer({
  wavPath,
  setLoading,
}: {
  wavPath: string;
  setLoading: Dispatch<
    SetStateAction<{
      detail: boolean;
      voice: boolean;
    }>
  >;
}) {
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef<any>(null);
  const [source, setSource] = useState('');

  useEffect(() => {
    getTelInquiryVoice({
      siteLangCd,
      wavPath,
    })
      .then((res) => {
        const blob = new Blob([res.data], { type: 'audio/wav' });
        setSource(URL.createObjectURL(blob));
      })
      .catch(console.log)
      .finally(() => setLoading((prev) => ({ ...prev, voice: false })));

    return () => URL.revokeObjectURL(source);
  }, [wavPath]);

  const handleOnClickPlay = () => {
    isPlaying ? audioRef.current.pause() : audioRef.current.play();
    setIsPlaying(!isPlaying);
  };

  const handleRangeChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setCurrentTime(Number(value));
    audioRef.current.currentTime = value;
  };

  useEffect(() => {
    const audioElement = audioRef.current;
    const handleTimeUpdate = () => setCurrentTime(audioElement.currentTime);
    const handleLoadedData = () => setDuration(audioElement.duration);

    audioElement.addEventListener('timeupdate', handleTimeUpdate);
    audioElement.addEventListener('loadeddata', handleLoadedData);

    return () => {
      audioElement.removeEventListener('timeupdate', handleTimeUpdate);
      audioElement.removeEventListener('loadeddata', handleLoadedData);
    };
  }, []);

  const displayTime = (seconds: number) => {
    let hour = Math.floor(seconds / 3600);
    let min = Math.floor((seconds - hour * 3600) / 60);
    let sec = seconds - hour * 3600 - min * 60;
    function addZero(num: number) {
      return (num < 10 ? '0' : '') + num;
    }
    if (seconds < 61) {
      return `${addZero(min)}:${addZero(sec)}`;
    } else if (seconds < 3601) {
      return `${addZero(min)}:${addZero(sec)}`;
    } else {
      return `${addZero(hour)}:${addZero(min)}:${addZero(sec)}`;
    }
  };

  return (
    <>
      <audio
        className="hidden"
        ref={audioRef}
        controls
        src={source}
        onEnded={() => setIsPlaying(false)}
      />
      <div className="flex !items-center space-x-5 justify-center bg-gray5 rounded-md px-5 max-md:px-7 py-4 relative">
        <button onClick={handleOnClickPlay}>
          {isPlaying ? <PauseIcon /> : <PlayIcon />}
        </button>
        <section className="relative flex items-center">
          <input
            className="relative w-72 max-md:w-48 z-10 bg-opacity-0 h-[6px] bg-gray3 rounded-lg appearance-none cursor-pointer"
            type="range"
            min="0"
            max={duration}
            step="0.1"
            value={currentTime}
            onChange={(e) => handleRangeChange(e)}
          />

          <div
            style={{
              width: `calc(${(currentTime / duration) * 100}%)`,
            }}
            className="absolute left-0 h-[6px] rounded-l-full bg-primary z-[1]"
          />
          <div className="absolute left-0 w-full h-[6px] rounded-l-full bg-gray3 rounded-full" />
        </section>
        <div className="flex space-x-1 text-sm font-bold w-24">
          <span>{displayTime(Math.floor(currentTime))}</span>
          <span>/</span>
          <span>{displayTime(Math.floor(duration))}</span>
        </div>
      </div>
    </>
  );
}
