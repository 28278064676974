import { Listbox, Transition } from '@headlessui/react';
import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ChevronDownIcon from '../vector/payment-details/ChevronDownIcon';

export default function SearchFilterSelectBox({
  setCategory,
  isMain,
  options,
  setFilter,
}: {
  setCategory?: React.Dispatch<React.SetStateAction<string>>;
  isMain?: boolean;
  options: { name: string; value: string }[];
  setFilter: React.Dispatch<React.SetStateAction<{}>>;
}) {
  const [selected, setSelected] = useState(options[0]);
  const { t } = useTranslation();

  const setValue = (list: { name: string; value: string }) => {
    switch (options[0].name) {
      case '문의 전체':
        setFilter((prev) => ({
          ...prev,
          inquiryStatus: list.value,
        }));
        break;
      case '서비스 전체':
        setFilter((prev) => ({
          ...prev,
          serviceCd: list.value,
        }));
        break;
      case '요금제 전체':
        setFilter((prev) => ({
          ...prev,
          itemCd: list.value,
        }));
        break;
      case '결제상태 전체':
        setFilter((prev) => ({
          ...prev,
          billStatusCd: list.value,
        }));
        break;
      case '구분 전체':
        setFilter((prev) => ({
          ...prev,
          creditStatusCd: list.value,
        }));
        break;
      case '3개월':
        setFilter((prev) => ({
          ...prev,
          monthType: list.value,
        }));
        break;
      default:
        break;
    }
  };

  return (
    <div className="w-fit max-w-fit max-md:w-full max-md:max-w-full">
      <Listbox value={selected} onChange={setSelected}>
        <div className="relative">
          <Listbox.Button
            className={`relative w-full border max-2xl:text-sm px-5 py-3 max-2xl:px-4 rounded-lg ${
              isMain ? 'bg-black border-black4 !text-lg' : 'border-gray3'
            }`}
          >
            <div className="flex items-center justify-between space-x-10 max-2xl:space-x-5 max-md:space-x-3 max-md:text-sm">
              <span
                className={`${
                  isMain
                    ? 'text-white font-normal'
                    : 'text-black font-bold max-md:line-clamp-1 max-md:text-left'
                }`}
              >
                {t(selected.name)}
              </span>
              <i>
                <ChevronDownIcon isMain={isMain} />
              </i>
            </div>
          </Listbox.Button>
          <Transition
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="max-md:fixed inset-0 bg-black bg-opacity-30 z-50" />
          </Transition>
          <Transition
            as={Fragment}
            enter="transition ease-in-out duration-100 max-md:duration-300"
            enterFrom="opacity-0 max-md:translate-y-80"
            enterTo="opacity-100 max-md:translate-y-0"
            leave="transition ease-in duration-100 max-md:duration-300"
            leaveFrom="opacity-100 max-md:translate-y-0"
            leaveTo="opacity-0 max-md:translate-y-80"
          >
            <Listbox.Options
              className={`absolute inquiry-detail max-md:fixed max-md:bottom-0 max-md:left-0 mt-1 max-h-fit w-full min-w-fit overflow-auto rounded-lg max-md:rounded-b-none border border-gray3 max-md:text-sm py-1 shadow-xl ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm z-[60] ${
                isMain
                  ? 'bg-black border max-md:border-b-0 max-md:rounded-b-none border-black4'
                  : 'bg-white'
              }`}
            >
              {options.map((list, idx) => (
                <Listbox.Option
                  onClick={() => setValue(list)}
                  key={`list-${idx}`}
                  className={`relative py-3 px-5 text-black cursor-pointer hover:text-[#FF7B51] ${
                    isMain ? 'bg-black' : 'bg-white'
                  }`}
                  value={list}
                >
                  {({ selected }) => (
                    <span
                      className={`block truncate text-base ${
                        selected
                          ? 'font-medium !text-primary'
                          : 'font-normal hover:text-opacity-70'
                      } ${isMain && 'text-white'}`}
                    >
                      {t(list.name)}
                    </span>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  );
}
