import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { userTypeArray } from '../../array/signup';
import Loader from '../../components/common/Loader';
import MobileBackButton from '../../components/common/MobileBackButton';
import SignupButton from '../../components/common/SignupButtons';
import AlertModal from '../../components/common/modal/AlertModal';
import InformModal from '../../components/common/modal/InformModal';
import AuthTimer from '../../components/hooks/AuthTimer';
import SignupAgreement from '../../components/signup/Agreement';
import PolicyModal from '../../components/signup/PolicyModal';
import {
  checkCiDuplicate,
  getEmailCheckRequest,
} from '../../services/PublicService';
import {
  checkBusinessNumberRequest,
  checkEmailAuthNumber,
  sendEmailRequest,
} from '../../services/RegCertService';
import {
  RegCertResultType,
  SignUpInfoRegCertAtom,
  signupInfoForForeigner,
  userinfoAtom,
} from '../../store';

import { useTranslation } from 'react-i18next';
import { emailRegex } from '../../components/hooks/useValidate';
import ForeignerEmailAuth from '../../components/signup/ForeignerEmailAuth';
import PersonalIcon from '../../components/vector/signup/PersonalIcon';
import i18n from '../../locale/i18n';
import { siteLangCd } from '../../services/defaultClient';
import {
  RegisterBusinessInfoType,
  RegisterInfoType,
  SignUpInfo1Atom,
  SignUpInfo1BusinessAtom,
} from '../../store';

const inputStyle =
  'w-full h-full py-4 px-3 rounded-lg border border-borderLightGray focus:border-primary max-md:p-3 max-md:placeholder:text-sm';
const divStyle = 'flex flex-col space-y-2 max-2xl:text-base text-lg';
const requiredStyle = 'not-italic text-primary text-[8px]';

export default function SignUp() {
  const setSignupInfo1 = useSetRecoilState(SignUpInfo1Atom);
  const setSignUpInfo1Business = useSetRecoilState(SignUpInfo1BusinessAtom);
  const setSignUpInfoRegCert = useSetRecoilState(SignUpInfoRegCertAtom);
  const setForeignerInfo = useSetRecoilState(signupInfoForForeigner);
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [encKey, setEncKey] = useState('');
  const [modal, setModal] = useState({
    isOpen: false,
    phrase: '',
    secondPhrase: '',
  });
  const [userType, setUserType] = useState<'personal' | 'enterprise'>(
    'personal'
  );
  const [policyModal, setPolicyModal] = useState<{
    isOpen: boolean;
    termsCode: 'M01E0' | 'M01F0' | 'M01G0';
  }>({
    isOpen: false,
    termsCode: 'M01E0',
  });
  const [registerInfo, setRegisterInfo] = useState<RegisterInfoType>({
    userType: 'personal',
    agreement: [],
    advertiseMethod: {
      sms: false,
      email: false,
    },
  });
  const [enterpriseInfo, setEnterpriseInfo] =
    useState<RegisterBusinessInfoType>({
      companyName: '',
      ownerName: '',
      createDate: '',
      businessNumber: '',
      email: '',
    });
  const { t } = useTranslation();
  const { language } = useRecoilValue(userinfoAtom);
  const navigate = useNavigate();

  useEffect(() => {
    i18n.changeLanguage(language);
  }, []);

  useEffect(() => {
    setSignupInfo1(registerInfo);
  }, [registerInfo]);

  useEffect(() => {
    setSignUpInfo1Business(enterpriseInfo);
  }, [enterpriseInfo]);

  const checkEligibility = () => {
    let eligibility;
    if (language === 'en') {
      !isAuthorized ||
      !registerInfo.agreement.includes('personal information') ||
      !registerInfo.agreement.includes('integrated service')
        ? (eligibility = true)
        : (eligibility = false);
    } else if (userType === 'personal') {
      !registerInfo.agreement.includes('personal information') ||
      !registerInfo.agreement.includes('integrated service')
        ? (eligibility = true)
        : (eligibility = false);
    } else {
      !isAuthorized ||
      !enterpriseInfo.companyName ||
      !enterpriseInfo.ownerName ||
      !enterpriseInfo.createDate ||
      !enterpriseInfo.businessNumber ||
      !registerInfo.agreement.includes('personal information') ||
      !registerInfo.agreement.includes('integrated service')
        ? (eligibility = true)
        : (eligibility = false);
    }
    return eligibility;
  };

  const requestSignup = () => {
    if (language === 'en') {
      setForeignerInfo((prev) => ({
        ...prev,
        agreement: registerInfo.agreement,
        advertiseMethod: {
          sms: registerInfo.advertiseMethod.email,
          email: registerInfo.advertiseMethod.email,
        },
      }));
      navigate('/signup/step02-en');
      return;
    }
    userType === 'personal'
      ? handleClickAuthCheck()
      : handleClickBusinessNumberCheck();
  };

  //-- 본인인증 > --//

  const handleClickAuthCheck = () => {
    window.open('/popup/signup/regcert', 'authCheck', 'width=480, height=800');
  };
  (window as any).callbackUpdateEncKey = (data: any) => {
    setEncKey(data);
  };
  (window as any).callbackGetEncKey = () => {
    return encKey;
  };
  (window as any).callbackSetAuthResult = async (data: RegCertResultType) => {
    setSignUpInfoRegCert(data);
    let isExist;
    await checkCiDuplicate({ ciVal: data.staffCi })
      .then((res) => (isExist = res.data.data.exist))
      .catch((err) => alert(err));
    if (isExist) {
      alert('이미 가입된 계정이 있어, 로그인 페이지로 이동합니다.');
      navigate('/login');
      return;
    }

    userType === 'personal'
      ? navigate('/signup/step02')
      : navigate('/signup/step02-ep');
  };
  //-- < 본인인증 --//

  const handleClickBusinessNumberCheck = () => {
    checkBusinessNumberRequest({
      businesses: [
        {
          b_no: enterpriseInfo.businessNumber,
          start_dt: enterpriseInfo.createDate,
          p_nm: enterpriseInfo.ownerName,
          b_nm: enterpriseInfo.companyName,
        },
      ],
    })
      .then((res) => {
        if (res.data.data[0].valid === '01') {
          navigate('/signup/step02-ep');
        } else {
          alert('사업자 정보를 다시 확인해주세요.');
        }
      })
      .catch((err) => {
        alert(err);
      });
  };

  const changeUserType = (type: 'personal' | 'enterprise') => {
    setUserType(type);
    setRegisterInfo((prev) => ({
      ...prev,
      userType: type,
      agreement: [],
      advertiseMethod: {
        sms: false,
        email: false,
      },
    }));
  };

  return (
    <>
      <InformModal
        modal={modal.isOpen}
        phrase={modal.phrase}
        secondPhrase={modal.secondPhrase}
        closeModal={() =>
          setModal((prev) => ({
            ...prev,
            isOpen: false,
          }))
        }
      />
      <PolicyModal
        termsCode={policyModal.termsCode}
        policyModal={policyModal.isOpen}
        closeModal={() =>
          setPolicyModal((prev) => ({
            ...prev,
            isOpen: false,
          }))
        }
      />
      <section className="bg-[#F8F7F8] pt-[180px] pb-48 max-md:pt-[62px] max-md:pb-24 max-md:bg-white">
        <div className="flex justify-center max-md:block">
          <div>
            <section className="flex flex-col text-[32px] max-2xl:text-3xl space-y-1 max-md:px-5 max-md:text-2xl">
              <MobileBackButton />
              <div className="space-y-5">
                <h1 className="font-bold">{t('플루닛 통합회원가입')}</h1>
                <div className="flex flex-col text-lg text-black4 max-2xl:text-base">
                  <span>
                    {t(
                      '플루닛 통합회원이 되시면 다양한 서비스를 하나의 ID로 이용하실 수 있습니다.'
                    )}
                    <br className="max-md:hidden" />{' '}
                    {t('*만 14세 이상만 가입 가능')}
                  </span>
                  <span className="text-orange-500">
                    {t(
                      '*플루닛 스튜디오 서비스는 통합회원 준비중이므로 별도 회원가입이 필요합니다.'
                    )}
                  </span>
                </div>
              </div>
            </section>

            <section className="mt-7 p-10 bg-white rounded-lg text-lg space-y-10 max-md:px-5 max-md:w-full max-md:mt-0 max-md:p-5">
              <div className="space-y-4 max-2xl:text-lg text-xl">
                <span className="font-bold">
                  {t('회원 유형을 선택해주세요.')}
                </span>
                <div
                  className={`space-x-4 text-center items-center flex ${
                    language === 'en'
                      ? 'max-md:!grid-cols-1'
                      : 'max-md:grid max-md:grid-cols-2 max-md:space-x-0 max-md:gap-3'
                  }`}
                >
                  {language === 'kr' ? (
                    userTypeArray.map(({ icon, buttonText, type }, idx) => (
                      <button
                        key={`userType-${idx}`}
                        onClick={() => {
                          changeUserType(type);
                          setUserType(type);
                        }}
                        className={`px-36 max-2xl:text-sm text-base py-10 font-bold bg-white hover:bg-gray5 rounded-lg flex flex-col items-center border-2 ${
                          userType === type
                            ? 'border-black'
                            : 'border-borderGray'
                        } max-md:px-0 max-md:py-3`}
                      >
                        <i>{icon}</i>
                        <span>{t(buttonText)}</span>
                      </button>
                    ))
                  ) : (
                    <button
                      className={`w-full max-2xl:text-sm text-base py-10 font-bold bg-white hover:bg-gray5 rounded-lg flex flex-col items-center border-2 border-black max-md:px-0 max-md:py-3`}
                    >
                      <i>
                        <PersonalIcon />
                      </i>
                      <span>Individual Registration</span>
                    </button>
                  )}
                </div>
                {language === 'en' ? (
                  <span className="text-black4 text-base block">
                    Membership information can be entered after email
                    verification.
                  </span>
                ) : userType === 'personal' ? (
                  <ul className="list-disc ml-4 text-black4 text-base">
                    <li>휴대폰 본인인증 후 회원정보 입력이 가능합니다.</li>
                    <li>
                      외국인(국내거주)은 외국인등록번호로 본인인증이 가능합니다.
                    </li>
                  </ul>
                ) : (
                  <span className="text-black4 text-base block">
                    이메일 인증 후 회원정보 입력이 가능합니다.
                  </span>
                )}
              </div>
              {language === 'en' && (
                <ForeignerEmailAuth
                  isAuthorized={isAuthorized}
                  setIsAuthorized={setIsAuthorized}
                />
              )}

              {userType === 'enterprise' && language !== 'en' && (
                <EnterpriseInputSection
                  setModalInfo={setModal}
                  isAuthorized={isAuthorized}
                  setIsAuthorized={setIsAuthorized}
                  setEnterpriseInfo={setEnterpriseInfo}
                  enterpriseInfo={enterpriseInfo}
                />
              )}
              <SignupAgreement
                userType={userType}
                setPolicyModal={setPolicyModal}
                setRegisterInfo={setRegisterInfo}
                registerInfo={registerInfo}
              />
            </section>

            <div className="flex justify-center mt-14 max-md:px-5">
              <SignupButton
                onClick={requestSignup}
                disabled={checkEligibility()}
              >
                {language === 'en'
                  ? 'Next'
                  : userType === 'personal'
                  ? '휴대폰 본인인증'
                  : '다음'}
              </SignupButton>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

function EnterpriseInputSection({
  isAuthorized,
  setIsAuthorized,
  setEnterpriseInfo,
  enterpriseInfo,
  setModalInfo,
}: {
  isAuthorized: boolean;
  setIsAuthorized: React.Dispatch<React.SetStateAction<boolean>>;
  setEnterpriseInfo: React.Dispatch<
    React.SetStateAction<RegisterBusinessInfoType>
  >;
  enterpriseInfo: RegisterBusinessInfoType;
  setModalInfo: React.Dispatch<
    React.SetStateAction<{
      isOpen: boolean;
      phrase: string;
      secondPhrase: string;
    }>
  >;
}) {
  const [modal, setModal] = useState({
    isOpen: false,
    title: '',
    content: '',
  });
  const [isSent, setIsSent] = useState(false);
  const [uniqueId, setUniqueId] = useState('');
  const [authNum, setAuthNum] = useState('');
  const [time, setTime] = useState(600);
  const [loading, setLoading] = useState(false);

  const sendAuthEmail = async () => {
    if (!emailRegex.test(enterpriseInfo.email)) {
      setModalInfo({
        isOpen: true,
        phrase: '이메일 주소가 올바르지 않습니다.',
        secondPhrase: '이메일 주소를 정확하게 입력해주세요.',
      });
      return;
    }
    setLoading(true);
    await getEmailCheckRequest({ memberEmail: enterpriseInfo.email })
      .then(async (res) => {
        const resData = res.data.data;
        if (resData.exist) {
          setLoading(false);
          setModal({
            isOpen: true,
            title: '이메일 중복',
            content: '이미 사용 중인 이메일입니다.',
          });
          return;
        } else {
          sendEmailRequest({
            siteLangCd,
            memberEmail: enterpriseInfo.email,
          })
            .then((res) => {
              const resData = res.data.data;
              setModal({
                isOpen: true,
                title: '인증메일이 발송되었습니다.',
                content: `${enterpriseInfo.email}으로 발송된 인증메일의 인증코드를 유효시간 10분 안에 입력해주세요.`,
              });
              setIsSent(true);
              setTime(600);
              setUniqueId(resData.authSendUuid);
            })
            .catch((err) => {
              alert(err);
            })
            .finally(() => setLoading(false));
        }
      })
      .catch((err) => {
        alert(err);
      });
  };

  const checkAuthNum = () => {
    setLoading(true);
    checkEmailAuthNumber({
      memberEmail: enterpriseInfo.email,
      authSendUuid: uniqueId,
      authCode: authNum,
    })
      .then((res) => {
        if (res.data.data.authSuccess) {
          setModal({
            isOpen: true,
            title: '인증 완료',
            content: '이메일 인증이 완료되었습니다.',
          });
          setIsAuthorized(true);
        } else {
          setModal({
            isOpen: true,
            title: '인증 오류',
            content: '인증코드를 다시 한 번 확인해주세요.',
          });
          setAuthNum('');
        }
      })
      .catch((err) => {
        alert(err);
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      <Loader loading={loading} />
      <AlertModal
        closeModal={() => setModal((prev) => ({ ...prev, isOpen: false }))}
        isOpen={modal.isOpen}
        title={modal.title}
        content={modal.content}
      />
      <section className="space-y-8 max-2xl:space-y-6">
        <div className={divStyle}>
          <div className="font-medium flex items-center space-x-1">
            <span>회사명</span>
            <i className={requiredStyle}>●</i>
          </div>
          <div className="flex space-x-3">
            <input
              maxLength={90}
              className={inputStyle}
              onChange={(e) => {
                setEnterpriseInfo((prev) => ({
                  ...prev,
                  companyName: e.target.value,
                }));
              }}
              type="text"
              placeholder="회사명을 입력해주세요."
            />
          </div>
        </div>

        <div className={divStyle}>
          <div className="font-medium flex items-center space-x-1">
            <span>사업자등록번호</span>
            <i className={requiredStyle}>●</i>
          </div>
          <div className="flex space-x-3">
            <input
              onKeyDown={(e) =>
                enterpriseInfo.businessNumber.length > 9 &&
                e.key !== 'Backspace' &&
                e.key !== 'Tab' &&
                e.preventDefault()
              }
              className={inputStyle}
              onChange={(e) => {
                setEnterpriseInfo((prev) => ({
                  ...prev,
                  businessNumber: e.target.value,
                }));
              }}
              type="number"
              placeholder="'-'없이 10자리를 입력해주세요."
            />
          </div>
          <span className="text-textGray max-2xl:text-xs text-sm">
            사업자등록번호 기준으로 한 개의 아이디만 가입 가능
          </span>
        </div>

        <div className={divStyle}>
          <div className="font-medium flex items-center space-x-1">
            <span>개업일자</span>
            <i className={requiredStyle}>●</i>
          </div>
          <div className="flex space-x-3">
            <input
              className={inputStyle}
              onKeyDown={(e) =>
                enterpriseInfo.createDate.length > 7 &&
                e.key !== 'Backspace' &&
                e.key !== 'Tab' &&
                e.preventDefault()
              }
              onChange={(e) =>
                setEnterpriseInfo((prev) => ({
                  ...prev,
                  createDate: e.target.value,
                }))
              }
              type="number"
              placeholder="'-'없이 8자리를 입력해주세요."
            />
          </div>
        </div>

        <div className={divStyle}>
          <div className="font-medium flex items-center space-x-1">
            <span>대표자명</span>
            <i className={requiredStyle}>●</i>
          </div>
          <div className="flex space-x-3">
            <input
              className={inputStyle}
              onChange={(e) =>
                setEnterpriseInfo((prev) => ({
                  ...prev,
                  ownerName: e.target.value,
                }))
              }
              type="text"
              placeholder="대표자명을 입력해주세요."
            />
          </div>
        </div>

        <div className={divStyle}>
          <div className="font-medium flex items-center space-x-1">
            <span>이메일 주소</span>
            <i className={requiredStyle}>●</i>
          </div>
          <div className="flex space-x-3">
            <input
              maxLength={60}
              onKeyDown={(e) => e.key === 'Enter' && sendAuthEmail()}
              className={`${
                isAuthorized
                  ? 'bg-gray5 border-[#27AE60]'
                  : 'border-borderLightGray'
              } w-full h-full py-4 px-3 rounded-lg border focus:border-primary max-md:p-3 max-md:placeholder:text-sm`}
              onChange={(e) =>
                setEnterpriseInfo((prev) => ({
                  ...prev,
                  email: e.target.value,
                }))
              }
              disabled={isAuthorized}
              type="email"
              placeholder="이메일 주소를 입력해주세요."
            />
            <button
              onClick={sendAuthEmail}
              disabled={isAuthorized}
              className={`min-w-[140px] max-2xl:min-w-[120px] py-3 max-md:text-sm border border-black rounded-lg hover:bg-slate-100 ${
                isAuthorized &&
                'border-borderLightGray text-gray2 hover:bg-white'
              }`}
            >
              {isSent ? '인증코드 재발송' : '인증코드 발송'}
            </button>
          </div>
          {isSent && !isAuthorized && (
            <div className="flex space-x-3">
              <div className="w-full relative">
                <input
                  onKeyDown={(e) => e.key === 'Enter' && checkAuthNum()}
                  className={inputStyle}
                  type="text"
                  maxLength={6}
                  value={authNum}
                  onChange={(e) => setAuthNum(e.target.value)}
                  placeholder="인증코드 6자리를 입력해주세요."
                />
                {!isAuthorized && (
                  <div className="absolute right-5 bottom-1/2 translate-y-1/2">
                    <AuthTimer time={time} setTime={setTime} />
                  </div>
                )}
              </div>
              <button
                onClick={checkAuthNum}
                className="min-w-[140px] max-2xl:min-w-[120px] py-3 max-2xl:text-sm bg-black text-white rounded-lg hover:bg-opacity-80"
              >
                인증코드 확인
              </button>
            </div>
          )}
          <span
            className={`${
              isAuthorized ? 'text-[#27AE60]' : 'text-textGray'
            } max-2xl:text-xs text-sm`}
          >
            {isAuthorized
              ? '이메일 인증이 완료되었습니다.'
              : isSent
              ? '이메일로 전송된 인증코드를 확인해주세요.'
              : '영문, 숫자, 일부기호 (- / _ / . / @)만 입력 가능'}
          </span>
        </div>
      </section>
    </>
  );
}
