import AiImage from '../components/vector/metahuman/AiImage';
import AiModelImage from '../components/vector/metahuman/AiModelImage';
import RecordImage from '../components/vector/metahuman/RecordImage';
import RenderImage from '../components/vector/metahuman/RenderImage';

export const metahumanProsArray = [
  '사용자 Needs별 메타휴먼 제작',
  '실시간 인터렉션 서비스',
  '최소한의 영상/음성 데이터로 제작 가능',
  '전용 앱 설치가 필요없는 서버 렌더링',
  '편리한 영상 편집 도구',
];

export const topSectionArray = [
  {
    imgPath: '/metahuman/reception.png',
    keyPhrase: 'Reception',
    description:
      '우리의 가상 인간은 공공 사무실, 진료소, 고객 센터에서 방문객들을 도울 수 있습니다.',
  },
  {
    imgPath: '/metahuman/counselor.png',
    keyPhrase: 'Virtual Counselor',
    description:
      '한정된 인원으로 다수의 방문 고객이나 교육기관 등에서 상담 서비스를 제공할 수 있습니다.',
  },
  {
    imgPath: '/metahuman/pr.png',
    keyPhrase: 'Interactvie PR',
    description:
      '복합문화공간이나 컨퍼런스 장소에서 키오스크를 통해 잠재 고객을 위한 광고/홍보에 이용가능합니다.',
  },
  {
    imgPath: '/metahuman/metaverse.png',
    keyPhrase: 'MetaVerse(AR/VR)',
    description:
      '당신의 회사를 대표하는 가상인간은 물리적 공간을 넘어 사용자들과 상호작용을 합니다.',
  },
];

export const howSectionCardArray = [
  {
    icon: <RecordImage />,
    keyPhrase: '비디오 녹화 및 음성스캔',
    text: '사용자의 비디오와 오디오를 1:1로 매칭하여 녹음합니다.',
  },
  {
    icon: <AiImage />,
    keyPhrase: '데이터 전처리 및 AI 학습',
    text: '학습 데이터의 사전 처리와 사용자에게 최적화된 학습을 수행시킵니다.',
  },
  {
    icon: <AiModelImage />,
    keyPhrase: 'AI모델 생성',
    text: '인공지능 기반의 가상 인간 모델을 생성합니다.',
  },
  {
    icon: <RenderImage />,
    keyPhrase: '렌더링 및 게시',
    text: '당신의 AI가상인간은 다양한 플랫폼과 디바이스에서 바로 사용할 수 있습니다.',
  },
];

export const metahumanExampleArray = [
  {
    imgPath: '/metahuman/sangchul.png',
  },
  {
    imgPath: '/metahuman/jaemyung.png',
  },
];

export const metahumanUsesArray: {
  images: { imgPath: string }[];
  category: '2D Homo-Real' | '3D Cartoony' | '3D Homo-Hyper';
  title: string;
  paragraph: string;
  field: string;
}[] = [
  {
    images: [
      {
        imgPath: '/metahuman/sangchul.png',
      },
    ],
    category: '2D Homo-Real',
    title: '최소한의 영상/음성 데이터로 완성되는 메타휴먼',
    paragraph:
      '딥러닝 알고리즘을 통해 생성되는 2D 메타휴먼은 사람의 말투, 억양, 목소리, 표정까지 그대로 재현가능하며 20분의 영상 데이터, 40분의 음성 데이터만으로도 실존인물과 구분이 어려울만큼 놀라운 결과를 만들어 냅니다.',
    field: '광고 ・ 홍보 ・ 교육용 비디오 콘텐츠',
  },
  {
    images: [
      {
        imgPath: '/metahuman/cartoon-characters.png',
      },
    ],
    category: '3D Cartoony',
    title: '친근한 카툰 스타일 캐릭터',
    paragraph:
      '친숙하고 재미있는 카툰형 메타휴먼은 애니메이션이나 게임의 캐릭터를 실생활에서 접하는 느낌으로 직접 대화하고 행동하며 고객 니즈에 따라 커스터마이징 가능합니다.',
    field: '고객상담 ・ 메타버스 ・ 교육 ・ 광고 ・ 홍보',
  },
  {
    images: [
      {
        imgPath: '/metahuman/homo-hyper.png',
      },
    ],
    category: '3D Homo-Hyper',
    title:
      '시간과 공간의 제약없이 업무 부여 가능한 진짜 사람같은 똑똑한 메타휴먼',
    paragraph:
      '비즈니스의 이미지에 딱 맞는 외모, 체형, 목소리를 가진 맞춤형 3D 메타휴먼은 맥락을 이해하는 자연스러운 대화와 상호작용 가능한 인터랙션으로 놀라운 사용경험을 제공합니다.',
    field: '고객상담 ・ 메타버스 ・ 교육 ・ 광고 ・ 홍보',
  },
];
