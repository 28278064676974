export default function NaverLoginIcon() {
  return (
    <svg
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="50" height="50" rx="25" fill="#03C75A" />
      <path
        d="M27.6018 17.5V25.0685L22.419 17.5H16.8145V32.5H22.3996V24.9315L27.5839 32.5H33.1854V17.5H27.6018Z"
        fill="white"
      />
    </svg>
  );
}
