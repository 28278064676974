import { Combobox, Transition } from '@headlessui/react';
import { CheckIcon } from '@heroicons/react/20/solid';
import { Fragment, SetStateAction, useEffect, useState } from 'react';
import { nationArr } from '../../array/nationArray';
import { RegisterForeignerInfoType } from '../../store';
import { ForeignerInfoType } from '../../views/help/find-id';
import ChevronDownIcon from '../vector/payment-details/ChevronDownIcon';

export default function NationComboBox({
  isOptional,
  setRegisterInfo,
  setForeignerInfo,
}: {
  isOptional?: boolean;
  setRegisterInfo?: React.Dispatch<SetStateAction<RegisterForeignerInfoType>>;
  setForeignerInfo?: React.Dispatch<SetStateAction<ForeignerInfoType>>;
}) {
  const [selected, setSelected] = useState(nationArr[0]);
  const [query, setQuery] = useState('');

  const filteredNations =
    query === ''
      ? nationArr
      : nationArr.filter((person) =>
          person.name
            .toLowerCase()
            .replace(/\s+/g, '')
            .includes(query.toLowerCase().replace(/\s+/g, ''))
        );

  useEffect(() => {
    setRegisterInfo
      ? setRegisterInfo((prev) => ({
          ...prev,
          memberCountryCd: selected.code,
          phoneNumberCd: selected.number,
        }))
      : setForeignerInfo
      ? setForeignerInfo((prev) => ({
          ...prev,
          memberCountryCd: selected.code,
        }))
      : '';
  }, [selected]);

  const getDisplayValue = (name: string, number: number) => {
    return isOptional ? `${name}` : `${name} +${number}`;
  };
  const getOptionValue = (nation: {
    code: string;
    number: string;
    name: string;
  }) => {
    return isOptional ? `${nation.name}` : `${nation.name} +${nation.number}`;
  };

  return (
    <Combobox value={selected} onChange={setSelected}>
      <div className="relative mt-1">
        <div className="border border-[#d7d7d7] relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left">
          <Combobox.Input
            tabIndex={isOptional ? 7 : 5}
            className="w-full border-none py-4 max-2xl:py-3 pl-3 pr-10 !text-base md:!text-lg leading-5 text-gray-900 focus:ring-0"
            displayValue={({ name, number }) => getDisplayValue(name, number)}
            onChange={(e) => setQuery(e.target.value)}
          />
          <Combobox.Button className="absolute inset-y-0 right-3 flex items-center pr-2">
            <ChevronDownIcon />
          </Combobox.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-150"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition ease-in duration-150"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          afterLeave={() => setQuery('')}
        >
          <Combobox.Options className="inquiry-detail z-10 absolute mt-1 max-h-60 w-full overflow-auto rounded-lg bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            {filteredNations.length === 0 && query !== '' ? (
              <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                No country found.
              </div>
            ) : (
              filteredNations.map((nation, idx) => (
                <Combobox.Option
                  key={`nation-${idx}`}
                  className={({ active }) =>
                    `relative cursor-pointer select-none py-2 pl-10 pr-4 ${
                      active ? 'bg-teal-600 text-hoverPrimary' : 'text-gray-900'
                    }`
                  }
                  value={nation}
                >
                  {({ selected }) => (
                    <>
                      <span
                        className={`block truncate ${
                          selected
                            ? 'font-semibold text-hoverPrimary'
                            : 'font-normal'
                        }`}
                      >
                        {getOptionValue(nation)}
                      </span>
                      {selected ? (
                        <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-primary">
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      ) : null}
                    </>
                  )}
                </Combobox.Option>
              ))
            )}
          </Combobox.Options>
        </Transition>
      </div>
    </Combobox>
  );
}
