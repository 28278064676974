import useWindowScroll from '../hooks/useWindowScroll';
import HomeButton from './HomeButtons';

interface PropType {
  title: '플루닛 스튜디오' | '플루닛 워크센터' | '플루닛 메타휴먼';
  componentRef: React.MutableRefObject<null>;
  firstButton: string;
  secondButton: string | null;
  firstLink: string;
  secondLink: string;
}

export default function ShortcutBanner({
  title,
  componentRef,
  firstButton,
  secondButton,
  firstLink,
  secondLink,
}: PropType) {
  const { scrollY } = useWindowScroll();

  return (
    <>
      {scrollY >= 500 && (
        <section className="max-md:hidden shortcut-banner max-w-[1600px] max-2xl:max-w-[1200px] py-3 translate-x-1/2 right-1/2 w-full h-fit flex items-center justify-between bg-black fixed top-[110px] z-10">
          <h1 className="font-bold text-3xl text-transparent bg-clip-text bg-gradient-to-br from-[#FF4E83] to-[#FFBB54]">
            {title}
          </h1>
          <div className="space-x-5 max-md:space-x-3 flex">
            <HomeButton
              onClick={() => {
                if (!firstLink) {
                  return;
                }
                firstLink === '/signup'
                  ? (window.location.href = firstLink)
                  : window.open(firstLink);
              }}
              icon={true}
            >
              {firstButton}
            </HomeButton>
            {secondButton && (
              <div className="p-[1px] bg-gradient-to-br from-white to-white rounded-lg hover:from-primary hover:to-primaryTo">
                <HomeButton
                  onClick={() => {
                    if (!secondLink) {
                      return;
                    }
                    window.open(secondLink);
                  }}
                  icon={true}
                  color="main white"
                >
                  {secondButton}
                </HomeButton>
              </div>
            )}
          </div>
        </section>
      )}
    </>
  );
}
