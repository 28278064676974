export default function PlusIcon() {
  return (
    <svg
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M10 4.5H0V5.5H10V4.5Z" fill="#888888" />
      <path d="M5.5 10L5.5 0L4.5 0L4.5 10H5.5Z" fill="#888888" />
    </svg>
  );
}
