import { Dialog, Transition } from '@headlessui/react';
import { t } from 'i18next';
import { Dispatch, Fragment, SetStateAction } from 'react';

type Props = {
  isOpen: boolean;
  closeModal: Dispatch<SetStateAction<boolean>>;
  onClick?: () => void;
};

export default function QuickstartModal({
  isOpen,
  closeModal,
  onClick,
}: Props) {
  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50"
          onClose={() => {
            return;
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-xs space-y-8 transform overflow-hidden rounded-2xl bg-white p-5 pt-8 text-center align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-bold text-black2 flex flex-col whitespace-pre-wrap"
                  >
                    <span>
                      {t(
                        '워크센터를 정식 이용하시기 전에 무료체험 해보시겠어요?'
                      )}
                    </span>
                  </Dialog.Title>

                  <div className="mt-4 flex space-x-3 font-bold text-base">
                    <button
                      className="py-3 bg-white border border-gray3 text-black2 w-full rounded-xl hover:bg-gray5"
                      onClick={() => closeModal(false)}
                    >
                      {t('아니요')}
                    </button>
                    <button
                      className="py-3 bg-gradient-to-b from-primary to-primaryTo hover:from-hoverPrimary hover:to-hoverPrimaryTo w-full rounded-xl text-white"
                      onClick={onClick}
                    >
                      {t('네, 해볼래요')}
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
