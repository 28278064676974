import { t } from 'i18next';
import { SetStateAction } from 'react';
import { checkBoxArray } from '../../../array/myPageArray';
import { InquiryTypes } from '../../../views/mypage/inquiry/req';
import CheckBox from '../../vector/common/CheckBox';
import CheckedBox from '../../vector/common/CheckedBox';

export default function NotificationCheck({
  divStyle,
  setRequestInfo,
  requestInfo,
  myInfo,
}: {
  divStyle: string;
  setRequestInfo: React.Dispatch<SetStateAction<InquiryTypes>>;
  requestInfo: InquiryTypes;
  myInfo: {
    memberTypeCdNm: string;
    memberFnm1: string;
    memberEmail: string;
    mobileTelno: string;
    memberNickNm: string;
    managerTelno: string;
    managerEmail: string;
  } | null;
}) {
  const getInfo = (idx: number) => {
    if (!myInfo) {
      return;
    }
    let email = '';
    let telNo = '';
    myInfo.memberTypeCdNm === '개인'
      ? ((telNo = myInfo.mobileTelno), (email = myInfo.memberEmail))
      : ((telNo = myInfo.managerTelno), (email = myInfo.managerEmail));
    if (idx === 0) {
      return telNo;
    } else {
      return email;
    }
  };

  return (
    <div className={divStyle}>
      <div className="w-[200px] font-bold text-lg max-md:text-base">
        {t('답변전송알림')}
      </div>
      <div className="flex space-x-4 max-md:flex-col max-md:space-x-0 max-md:space-y-5">
        {checkBoxArray.map(({ method, value }, idx) => (
          <div
            key={`check-${idx}`}
            className="flex items-center space-x-2 max-md:space-x-0 text-lg max-md:text-base"
          >
            <input
              id={value}
              type="checkbox"
              value={value}
              onChange={(e) => {
                idx === 0
                  ? setRequestInfo((prev) => ({
                      ...prev,
                      smsReplyYn: e.target.checked ? 'Y' : 'N',
                    }))
                  : setRequestInfo((prev) => ({
                      ...prev,
                      emailReplyYn: e.target.checked ? 'Y' : 'N',
                    }));
              }}
              defaultChecked={true}
              className="hidden"
            />
            <label
              htmlFor={value}
              className="flex items-center space-x-2 text-black2 cursor-pointer"
            >
              {idx === 0 && requestInfo.smsReplyYn === 'Y' ? (
                <CheckedBox />
              ) : idx === 1 && requestInfo.emailReplyYn === 'Y' ? (
                <CheckedBox />
              ) : (
                <CheckBox />
              )}
              <span>{method}</span>
              <span className="text-black5 ml-1">{`(${getInfo(idx)})`}</span>
            </label>
          </div>
        ))}
      </div>
    </div>
  );
}
