import { KeyboardEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import Loader from '../../components/common/Loader';
import SignupButton from '../../components/common/SignupButtons';
import InformModal from '../../components/common/modal/InformModal';
import LoginModal from '../../components/common/modal/LoginModal';
import { reqTmpPassword } from '../../services/PublicService';
import { userinfoAtom } from '../../store';

export default function FindPw() {
  const [isSent, setIsSent] = useState(false);
  const [loading, setLoading] = useState(false);
  const [memberEmail, setMemberEmail] = useState('');
  const [informModal, setInforModal] = useState(false);
  const [loginModal, setLoginModal] = useState(false);
  const screenSize = window.innerWidth;
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { language } = useRecoilValue(userinfoAtom);

  const getTmpPassword = () => {
    setLoading(true);
    reqTmpPassword({ memberEmail })
      .then((res) =>
        res.data.resultCd === '0000' ? setIsSent(true) : setInforModal(true)
      )
      .catch(() => setInforModal(true))
      .finally(() => setLoading(false));
  };

  const isDisabled = () => {
    return !memberEmail ? true : false;
  };

  return (
    <>
      <LoginModal modal={loginModal} closeModal={() => setLoginModal(false)} />
      <Loader loading={loading} />
      <InformModal
        modal={informModal}
        phrase="가입된 정보가 없습니다."
        content="이메일 주소를 다시 확인해주세요."
        closeModal={() => setInforModal(false)}
      />
      <section className="pt-[200px] pb-60 max-w-[600px] mx-auto max-md:pt-[98px] max-md:px-5 max-md:pb-24">
        <div className="flex flex-col text-center space-y-10">
          <div className="font-bold space-y-10">
            <h1 className="text-[44px] max-md:text-[28px]">
              {t('비밀번호 찾기')}
            </h1>
          </div>
          {isSent ? (
            <div className="flex flex-col space-y-1">
              <span className="text-xl max-md:text-base">
                {t('임시비밀번호가 이메일로 발송되었습니다.')}
              </span>
              <span className="text-xl max-md:text-base">
                {t('로그인 후 비밀번호를 변경해 주세요.')}
              </span>
            </div>
          ) : (
            <div className="flex flex-col space-y-7 max-md:space-y-5">
              <div className="space-y-3">
                <span className="text-lg max-md:text-base">
                  {t('가입한 아이디(이메일)를 입력해주세요.')}
                </span>
                <input
                  onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
                    e.key === 'Enter' && getTmpPassword();
                  }}
                  className="w-full py-4 px-3 rounded-lg border border-borderLightGray focus:ring-transparent max-md:p-3"
                  value={memberEmail}
                  onChange={(e) => setMemberEmail(e.target.value)}
                  placeholder={t('이메일 주소를 입력해주세요.')}
                  type="text"
                />
              </div>
            </div>
          )}

          <div className="flex flex-col space-y-5 items-center">
            <div className="flex w-full space-x-3">
              {isSent ? (
                <>
                  <SignupButton
                    onClick={() =>
                      screenSize < 769
                        ? navigate('/login')
                        : setLoginModal(true)
                    }
                  >
                    {t('로그인')}
                  </SignupButton>
                </>
              ) : (
                <SignupButton disabled={isDisabled()} onClick={getTmpPassword}>
                  {t('임시비밀번호 받기')}
                </SignupButton>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
