import { Disclosure } from '@headlessui/react';
import { ChevronUpIcon } from '@heroicons/react/20/solid';
import { t } from 'i18next';
import moment from 'moment';
import { useEffect, useState } from 'react';
import {
  deleteInquiryData,
  getWebInquiryList,
} from '../../../services/PrivateService';
import { fileBaseURL, siteLangCd } from '../../../services/defaultClient';
import { getParentPage } from '../../../utils/common';
import Skeleton from '../../common/MypageCardSkeleton';
import Pagination from '../../common/Pagination';
import ServiceTag from '../../common/ServiceTag';
import ConfirmModal from '../../common/modal/ConfirmModal';
import InformModal from '../../common/modal/InformModal';
import { usePloonianImage } from '../../hooks/usePloonian';
import DivIcon from '../../vector/payment-details/DivIcon';
import NoData from '../NoData';

const perPage = 10;

export default function InquiryTab({
  searchFilter,
  searchTxt,
}: {
  searchFilter: { inquiryStatus: string; serviceCd: string };
  searchTxt: string;
}) {
  const [confirmModal, setConfirmModal] = useState({
    isOpen: false,
    inquiryId: '',
  });
  const [informModal, setInformModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [inquiryList, setInquiryList] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const [total, setTotal] = useState(0);
  const { ploonianName } = usePloonianImage();
  const [isSearched, setIsSearched] = useState(false);

  useEffect(() => {
    const { serviceCd, inquiryStatus } = searchFilter;
    serviceCd || inquiryStatus || searchTxt
      ? setIsSearched(true)
      : setIsSearched(false);
  }, [searchFilter, searchTxt]);

  useEffect(() => {
    setLoading(true);
    getWebInquiryList({
      siteLangCd,
      perPage,
      pageNo,
      searchTxt,
      serviceCd: searchFilter.serviceCd,
      qnaStatus: searchFilter.inquiryStatus,
    })
      .then((res) => {
        setTotal(res.data.data.totalRows);
        setInquiryList(res.data.data.pageList);
      })
      .catch((err) => alert(err))
      .finally(() => setLoading(false));
  }, [confirmModal, pageNo, searchFilter, searchTxt]);

  const isBatchim = (name: string) => {
    if (!name) {
      return false;
    }
    const charCode = name.charCodeAt(name.length - 1);
    const consonantCode = (charCode - 44032) % 28;
    return consonantCode === 0 ? false : true;
  };

  const deleteInquiry = () => {
    deleteInquiryData(confirmModal.inquiryId)
      .then((res) => {
        if (res.data.resultMsg === 'success') {
          setConfirmModal((prev) => ({
            ...prev,
            isOpen: false,
          }));
          setInformModal(true);
        } else {
          alert('잠시 후에 다시 시도해주세요.');
        }
      })
      .catch((err) => alert(err));
  };

  const onPaging = (page: number) => {
    window.scrollTo(0, 0);
    setPageNo(Number(page));
  };
  return (
    <>
      <InformModal
        phrase="문의가 삭제되었습니다."
        modal={informModal}
        closeModal={() => {
          setInformModal(false);
        }}
      />
      <ConfirmModal
        isOpen={confirmModal.isOpen}
        onClick={() => deleteInquiry()}
        firstLine="문의글을 삭제하시겠습니까?"
        closeModal={() =>
          setConfirmModal((prev) => ({
            ...prev,
            isOpen: false,
          }))
        }
      />
      <section className="space-y-3">
        {loading ? (
          <Skeleton isInquiry />
        ) : inquiryList.length ? (
          inquiryList.map((item: any, idx: number) => (
            <div key={`item-${idx}`} className="w-full rounded-lg">
              <div className="w-full rounded-lg bg-white hover:shadow-md duration-200 ease-out border border-borderGray">
                <Disclosure>
                  {({ open }) => (
                    <>
                      <Disclosure.Button className="flex items-center w-full justify-between rounded-lg py-7 px-6 text-left text-sm text-black duration-100 ease-out border-0 max-md:p-4">
                        <section className="items-center flex !justify-between w-full max-md:flex max-md:flex-col max-md:w-full max-md:space-y-3">
                          <div className="flex flex-row items-center max-md:items-start space-x-6 max-md:flex-row-reverse max-md:justify-between w-full max-md:space-x-0">
                            <div className="flex space-x-2">
                              <ServiceTag type={item.serviceCd}>
                                {item.serviceCdNm}
                              </ServiceTag>
                              <ServiceTag>{item.qnaCategoryCdNm}</ServiceTag>
                            </div>
                            <span className="text-xl max-2xl:text-lg max-md:text-lg font-bold max-md:font-semibold max-w-[600px] max-2xl:max-w-[350px] max-md:max-w-[150px] line-clamp-1 max-md:line-clamp-2 text-ellipsis">
                              {item.inquiryTit}
                            </span>
                          </div>
                          <div className="flex justify-end w-1/2 h-full max-md:space-x-0 max-md:w-full">
                            <div className="flex items-center space-x-6 max-md:space-x-0 max-md:w-full max-md:justify-start justify-end text-lg max-2xl:text-base max-md:text-sm">
                              <ChevronUpIcon
                                className={`${
                                  open
                                    ? 'max-md:hidden'
                                    : 'rotate-180 transform'
                                } h-5 w-5 duration-300 ease-out max-md:hidden`}
                              />
                              <span>
                                {item.answerCtnt
                                  ? t('답변완료')
                                  : t('접수완료')}
                              </span>
                              <i className="max-md:!mx-2">
                                <DivIcon />
                              </i>
                              <span>
                                {moment(item.receiptYmd).format('YYYY. MM. DD')}
                              </span>
                            </div>
                            <div className="hidden max-md:block">
                              <ChevronUpIcon
                                className={`${
                                  open ? 'rotate-180 transform' : ''
                                } h-5 w-5 duration-300 ease-out`}
                              />
                            </div>
                          </div>
                        </section>
                      </Disclosure.Button>
                      <Disclosure.Panel className="break-all px-6 pt-5 pb-7 bg-gray5 rounded-lg max-md:rounded-t-none max-sm:p-5 w-full max-w-full line-clamp-1">
                        <div className="flex items-start max-md:items-start justify-between">
                          <p className="text-black max-md:text-sm w-full whitespace-pre-wrap">
                            {item.inquiryCtnt}
                          </p>
                          {!item.answerCtnt && (
                            <div className="justify-end flex max-md:mt-0 min-w-fit w-32 max-md:w-12">
                              <button
                                onClick={() =>
                                  setConfirmModal({
                                    isOpen: true,
                                    inquiryId: item.qnaUuid,
                                  })
                                }
                                className="text-black2 hover:text-rose-400 font-bold underline max-md:text-sm"
                              >
                                {t('삭제')}
                              </button>
                            </div>
                          )}
                        </div>
                        {item.inquiryFilePath && (
                          <span className="flex items-center text-black3 max-md:text-sm w-full mt-5">
                            <span className="font-bold min-w-fit">
                              첨부파일
                            </span>
                            <figure className="w-[1px] h-3 bg-black3 mx-2" />
                            <span
                              onClick={() =>
                                window.open(fileBaseURL + item.inquiryFilePath)
                              }
                              className="line-clamp-1 cursor-pointer hover:text-primary duration-100"
                            >
                              {item.files[0]?.originalFileNm}
                            </span>
                          </span>
                        )}
                        {item.answerCtnt && (
                          <div className="flex flex-col max-md:text-sm text-black2 border-t border-borderLightGray mt-5 pt-5">
                            <h3 className="font-bold mb-3">
                              플루니안 {ploonianName}
                              {isBatchim(ploonianName) ? '이' : '가'}{' '}
                              답변드립니다.
                            </h3>
                            <div className="flex flex-col space-y-[2px]">
                              <h5>
                                안녕하세요. 플루니안 {ploonianName}
                                {isBatchim(ploonianName) ? '이에요' : '예요'}.
                              </h5>
                              <p className="whitespace-pre-wrap">
                                {item.answerCtnt || ''}
                              </p>
                            </div>
                            <span className="mt-5">
                              {moment(item.answerYmd).format('YYYY.MM.DD')}
                            </span>
                          </div>
                        )}
                        {item.answerFilePath && (
                          <span className="flex items-center text-black3 max-md:text-sm w-full mt-5">
                            <span className="font-bold min-w-fit">
                              첨부파일
                            </span>
                            <figure className="w-[1px] h-3 bg-black3 mx-2" />
                            <span
                              onClick={() =>
                                window.open(fileBaseURL + item.answerFilePath)
                              }
                              className="line-clamp-1 cursor-pointer hover:text-primary duration-100"
                            >
                              {item.inquiryFilePath
                                ? item.files[1]?.originalFileNm
                                : item.files[0]?.originalFileNm}
                            </span>
                          </span>
                        )}
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
              </div>
            </div>
          ))
        ) : (
          <NoData isInquiry={!isSearched} />
        )}
      </section>

      <div className="mt-[60px]">
        {inquiryList.length ? (
          <Pagination
            onPaging={onPaging}
            total={total}
            current={pageNo}
            textColor="text-black group-hover:text-white"
            strokeColor="black"
            parentPage={getParentPage()}
            posts_per_page={perPage}
            max={total >= perPage ? Math.floor(total / perPage) + 1 : 1}
          />
        ) : null}
      </div>
    </>
  );
}
