import { useEffect, useState } from 'react';
import AlertModal from '../common/modal/AlertModal';

const AuthTimer = ({
  time,
  setTime,
}: {
  time: number;
  setTime: React.Dispatch<React.SetStateAction<number>>;
}) => {
  const [modal, setModal] = useState(false);
  useEffect(() => {
    if (time > 0) {
      const Counter = setInterval(() => {
        setTime((prev) => prev - 1);
      }, 1000);
      return () => clearInterval(Counter);
    } else {
      setModal(true);
    }
  }, [time]);
  const timeFormat = (time: number) => {
    const m = Math.floor(time / 60).toString();
    let s = (time % 60).toString();
    if (s.length === 1) s = `0${s}`;
    return `${m}:${s}`;
  };
  return (
    <>
      <AlertModal
        closeModal={() => setModal(false)}
        isOpen={modal}
        title="유효시간이 경과하였습니다."
        content="인증 유효시간이 경과하였습니다. 인증을 다시 진행해주세요."
      />
      <p className="text-sm text-primary">{timeFormat(time)}</p>
    </>
  );
};

export default AuthTimer;
