import { Link } from 'react-router-dom';
import Button from '../../common/Buttons';

export default function QuickError4() {
  return (
    <>
      <section>
        <div className="bg-gray5 rounded-xl py-8 md:py-10 text-center font-semibold leading-6 md:leading-[32px]">
          <h1>죄송합니다.</h1>
          <h1>현재 AI 직원이 많은 업무로 인해</h1>
          <h1>고객님께 바로 배정해드리지 못했습니다.</h1>
          <h1>AI 직원이 준비되는 대로 연락 드리겠습니다.</h1>
        </div>

        <div className="w-full flex flex-col space-y-4 justify-center items-center mt-5">
          <Link to={`/`}>
            <Button>홈으로 이동</Button>
          </Link>
        </div>
      </section>
    </>
  );
}
