import { t } from 'i18next';
import { ButtonHTMLAttributes } from 'react';

export interface ButtonTypeProps
  extends ButtonHTMLAttributes<HTMLButtonElement> {}

export default function DetailButton({ ...props }: ButtonTypeProps) {
  return (
    <button
      className="text-base px-3 py-2 max-md:px-2 bg-[#AAAAAA] hover:bg-[#aaaaaad5] rounded-lg text-white max-md:text-sm"
      {...props}
    >
      {t('상세보기')}
    </button>
  );
}
