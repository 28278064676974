import moment from 'moment';

export default function useTime() {
  let now = new Date();

  const year = now && now.getFullYear();
  const month = now && String(now.getMonth() + 1).padStart(2, '0');
  const nextMonth = now && String(now.getMonth() + 2).padStart(2, '0');
  const date = now && now.getDate();
  const thisMonth = `${year}${month}01`;
  const prevMonth = `${year}${Number(month) - 2}01`;

  return { year, month, date, thisMonth, prevMonth, nextMonth };
}

export function getDaysDiff(specificDate: string) {
  return moment().diff(specificDate, 'days');
}

// 어제날짜 yyyy.mm.dd 형식으로 리턴
export function getYesterday() {
  let today = new Date();
  let yesterday = new Date(today);

  yesterday.setDate(today.getDate() - 1);

  const year = yesterday.getFullYear();
  const month = String(yesterday.getMonth() + 1).padStart(2, '0');
  const day = String(yesterday.getDate()).padStart(2, '0');

  const formattedDate = `${year}.${month}.${day}`;

  return formattedDate;
}

export function getTelDuration(seconds: number, language: 'kr' | 'en') {
  let hour = Math.floor(seconds / 3600);
  let min = Math.floor((seconds - hour * 3600) / 60);
  let sec = seconds - hour * 3600 - min * 60;
  function addZero(num: number) {
    return (num < 10 ? '0' : '') + num;
  }
  if (seconds < 61) {
    return language === 'kr' ? `${addZero(sec)}초` : `${addZero(sec)}sec`;
  } else if (seconds < 3601) {
    return language === 'kr'
      ? `${addZero(min)}분 ${addZero(sec)}초`
      : `${addZero(min)}min ${addZero(sec)}sec`;
  } else {
    return language === 'kr'
      ? `${addZero(hour)}시간 ${addZero(min)}분 ${addZero(sec)}초`
      : `${addZero(hour)}hour ${addZero(min)}min ${addZero(sec)}sec`;
  }
}
