export default function CloseIcon({
  isDark,
  isBig,
}: {
  isDark: boolean;
  isBig?: boolean;
}) {
  return (
    <svg
      width={isBig ? '34' : '24'}
      height={isBig ? '34' : '24'}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.75 5.25L5.25 18.75"
        stroke={isDark ? 'white' : 'black'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.75 18.75L5.25 5.25"
        stroke={isDark ? 'white' : 'black'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
