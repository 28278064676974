import LoginComponent from '../../components/common/LoginComponent';

export interface LoginInfoType {
  email: string;
  password: string;
  rememberId: boolean;
}

export default function Login() {
  return (
    <div className="py-[100px] max-md:pt-[62px]">
      <LoginComponent />
    </div>
  );
}
