import { t } from 'i18next';
import { useRecoilValue } from 'recoil';
import { coreValueArray } from '../../array/aboutArray';
import { userinfoAtom } from '../../store';

export default function MissionSection() {
  const { language } = useRecoilValue(userinfoAtom);
  return (
    <section data-aos="fade-up">
      <div className="border-b border-gray3 pb-20 max-2xl:pb-16 max-md:pb-6 flex max-md:flex-col max-md:space-y-4">
        <h1 className="min-w-[300px] max-md:min-w-full max-2xl:min-w-72 h-fit font-bold text-transparent text-[32px] max-2xl:text-3xl max-md:text-xl bg-clip-text bg-gradient-to-br from-[#FF4E83] to-[#FFBB54]">
          MISSION
        </h1>
        <div className="flex flex-col space-y-8 max-2xl:space-y-7 max-md:space-y-5">
          <div className="flex flex-col font-bold text-5xl max-2xl:text-4xl max-md:text-2xl">
            <span className="black-outlined-text leading-[62px] max-2xl:leading-[48px] max-md:leading-9">
              {t('지식 사회 발전과 인류의 행복에 기여')}
            </span>
            <span className="leading-[62px] max-2xl:leading-[48px] max-md:leading-9">
              {t('더 나은 미래와 공간적 한계를 넘어')}
            </span>
            <span className="leading-[62px] max-2xl:leading-[48px] max-md:leading-9">
              {t('새로운 경험을 제공합니다.')}
            </span>
          </div>
          <p className="max-w-[728px] max-2xl:max-w-xl text-base max-2xl:text-sm !leading-7 max-md:!leading-6 text-black/60">
            {t(
              '줄어드는 노동인구와 인구 변화속에서 4차 산업의 핵심 기술인 AI 인공지능 기술은 이제 먼 미래가 아닌 우리의 현실입니다. 인공지능과 빅데이터 기술 뿐만이 아니라 글로벌 확산과 해외 진출까지 그 영역을 확보해온 솔트룩스의 기술력과 플루닛의 싱싱한 아이디어를 결합하여 모든 이들의 일상과 함께 하며 더 나은 일상을 만들기 위해 노력합니다.'
            )}
          </p>
        </div>
      </div>
      <div className="border-b border-gray3 py-20 max-2xl:py-16 max-md:py-6 flex max-md:flex-col max-md:space-y-4">
        <h1 className="min-w-[300px] max-md:min-w-full max-2xl:min-w-72 h-fit font-bold text-transparent text-[32px] max-2xl:text-3xl max-md:text-xl bg-clip-text bg-gradient-to-br from-[#FF4E83] to-[#FFBB54]">
          VISION
        </h1>
        <div className="flex flex-col space-y-4 max-2xl:space-y-3">
          <span className="black-outlined-text font-bold text-[52px] max-2xl:text-[44px] max-md:text-2xl">
            {t('세상 모든 사람들의 자유로운')}{' '}
            <br className="hidden max-md:block" /> {t('지식 소통')}
          </span>
          <div
            className={`${
              language === 'en'
                ? 'flex flex-col'
                : 'flex max-md:flex-col space-x-8 max-2xl:space-x-7 max-md:space-x-0'
            } max-md:space-y-2 text-[32px] max-2xl:text-[28px] max-md:text-xl font-bold`}
          >
            <span>{t('올곧게 일함')}</span>
            <span>{t('혁신을 통한 공헌')}</span>
            <span>{t('성장을 통한 행복')}</span>
          </div>
        </div>
      </div>
      <div className="pt-20 max-2xl:pt-16 max-md:pt-6 flex max-md:flex-col max-md:space-y-5">
        <h1 className="min-w-[300px] max-2xl:min-w-72 h-fit font-bold text-transparent text-[32px] max-2xl:text-3xl max-md:text-xl bg-clip-text bg-gradient-to-br from-[#FF4E83] to-[#FFBB54]">
          CORE VALUE
        </h1>
        <div className="grid grid-cols-2 max-md:grid-cols-1 gap-y-14 max-md:gap-y-6 gap-x-[60px]">
          {coreValueArray.map(({ heading, paragraph }, idx) => (
            <div key={`coreValue-${idx}`} className="flex flex-col space-y-3">
              <div className="flex space-x-2 max-md:space-x-1">
                <div className="min-w-[8px] w-2 h-2 max-md:w-[6px] max-md:h-[6px] bg-gradient-to-b from-[#FF478B] to-[#FFBB54] rounded-full" />
                <h1 className="font-bold text-2xl max-2xl:text-xl max-md:text-lg">
                  {t(heading)}
                </h1>
              </div>
              <p className="text-black/60 w-full !leading-8 max-md:!leading-normal max-2xl:text-sm ml-4 max-md:ml-3">
                {t(paragraph)}
              </p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
