import moment from 'moment';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { PaymentsListClientDataType } from '../../../services/TypeOfPrivateService';
import { userinfoAtom } from '../../../store';
import { getPayStatusName, getServiceName } from '../../../utils/common';
import DetailButton from '../../common/DetailButtons';
import ServiceTag from '../../common/ServiceTag';
import DivIcon from '../../vector/payment-details/DivIcon';

export default function MobilePaymentCard({
  paymentList,
  setDetailModal,
}: {
  paymentList: PaymentsListClientDataType[];
  setDetailModal: Dispatch<
    SetStateAction<{
      open: boolean;
      item: PaymentsListClientDataType;
    }>
  >;
}) {
  const { t } = useTranslation();
  const { language } = useRecoilValue(userinfoAtom);
  return (
    <>
      {paymentList.map((payment, idx) => (
        <div
          key={`mobilePayment-${idx}`}
          className="border border-borderGray px-5 py-4 rounded-[10px] items-center justify-between hidden max-md:flex h-full flex-col"
        >
          <div className="flex justify-between w-full items-center">
            <span className="font-bold text-lg">{t(payment.itemName)}</span>
            <div className="flex space-x-1">
              <ServiceTag type={payment.subscYn}>
                {payment.subscYn === 'Y' ? '구독' : '상품'}
              </ServiceTag>
              <ServiceTag type={payment.solutionType}>
                {getServiceName(payment.solutionType)}
              </ServiceTag>
            </div>
          </div>
          <div className="flex justify-between w-full items-center">
            <span className="font-bold">
              {language === 'kr'
                ? `${payment.sumCost ? payment.sumCost?.toLocaleString() : 0}원`
                : `KRW ${
                    payment.sumCost ? payment.sumCost?.toLocaleString() : 0
                  }`}
            </span>
          </div>
          <div className="flex justify-between w-full items-center">
            <div className="text-sm flex items-center space-x-2">
              <span className="text-[#666666]">
                {moment(payment.payDtFrom).format('YYYY. MM. DD HH:mm')}
              </span>
              <i>
                <DivIcon />
              </i>
              <span className="text-black2">
                {t(getPayStatusName(payment.billStatus))}
              </span>
            </div>
            <DetailButton
              onClick={() => setDetailModal({ open: true, item: payment })}
            />
          </div>
        </div>
      ))}
    </>
  );
}
