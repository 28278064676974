import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useSetRecoilState } from 'recoil';
import Loader from '../../../components/common/Loader';
import MobileBackButton from '../../../components/common/MobileBackButton';
import SignupButton from '../../../components/common/SignupButtons';
import { useTermSnums } from '../../../components/hooks/useTermSnums';
import SignupAgreement from '../../../components/signup/Agreement';
import PolicyModal from '../../../components/signup/PolicyModal';
import Breadcrumb from '../../../components/signup/quick/Breadcrumb';
import { quickSignUp } from '../../../services/PublicService';
import { siteLangCd } from '../../../services/defaultClient';
import {
  RegisterInfoType,
  assignedTelno,
  quickRegisterEnterpriseInfo,
  quickRegisterPersonalInfo,
} from '../../../store';
import {
  EnterpriseInfoType,
  PersonalInfoType,
} from '../../../types/quick/quick-type';

export default function QuickSignupStepTwo() {
  const [loading, setLoading] = useState(false);
  const [registerInfo, setRegisterInfo] = useState<RegisterInfoType>({
    agreement: [],
    advertiseMethod: {
      sms: false,
      email: false,
    },
  });
  const [personalInfo, setPersonalInfo] = useRecoilState<PersonalInfoType>(
    quickRegisterPersonalInfo
  );
  const [enterpriseInfo, setEnterpriseInfo] =
    useRecoilState<EnterpriseInfoType>(quickRegisterEnterpriseInfo);
  const setAssignTelno = useSetRecoilState(assignedTelno);
  const termSnums = useTermSnums();
  const [policyModal, setPolicyModal] = useState<{
    isOpen: boolean;
    termsCode: 'M01E0' | 'M01F0' | 'M01G0';
  }>({
    isOpen: false,
    termsCode: 'M01E0',
  });
  const { memberFnm1 } = personalInfo;
  const enterprise = enterpriseInfo;
  const navigate = useNavigate();
  const { t } = useTranslation();
  const isPersonalUser = !!personalInfo.ciVal;

  useEffect(() => {
    if (isPersonalUser) {
      setPersonalInfo((prev) => ({
        ...prev,
        memberTypeCd: 'M03A0',
        siteLangCd,
        authMeansCd: 'M02SM',
        joinYmd: moment().format('YYYYMMDD'),
        joinServiceCd: 'B15',
        termsAgreeList: [
          {
            termsSnum: termSnums.M01E0,
            termsCd: 'M01E0',
            termsAgreeYn: 'Y',
          },
          {
            termsSnum: termSnums.M01F0,
            termsCd: 'M01F0',
            termsAgreeYn: 'Y',
          },
          {
            termsSnum: termSnums.M01G0,
            termsCd: 'M01G0',
            termsAgreeYn: registerInfo.agreement?.includes(
              'advertising information'
            )
              ? 'Y'
              : 'N',
            mailAgreeYn: registerInfo.advertiseMethod.email ? 'Y' : 'N',
            smsAgreeYn: registerInfo.advertiseMethod.sms ? 'Y' : 'N',
          },
        ],
      }));
    } else {
      setEnterpriseInfo((prev) => ({
        ...prev,
        memberTypeCd: 'M03B0',
        siteLangCd,
        authMeansCd: 'M02SM',
        joinYmd: moment().format('YYYYMMDD'),
        joinServiceCd: 'B15',
        termsAgreeList: [
          {
            termsSnum: termSnums.M01E0,
            termsCd: 'M01E0',
            termsAgreeYn: 'Y',
          },
          {
            termsSnum: termSnums.M01F0,
            termsCd: 'M01F0',
            termsAgreeYn: 'Y',
          },
          {
            termsSnum: termSnums.M01G0,
            termsCd: 'M01G0',
            termsAgreeYn: registerInfo.agreement?.includes(
              'advertising information'
            )
              ? 'Y'
              : 'N',
            mailAgreeYn: registerInfo.advertiseMethod.email ? 'Y' : 'N',
            smsAgreeYn: registerInfo.advertiseMethod.sms ? 'Y' : 'N',
          },
        ],
      }));
    }
  }, [registerInfo]);

  const isDisabled = () => {
    const { agreement } = registerInfo;
    const disabled =
      !agreement.includes('personal information') ||
      !agreement.includes('integrated service');
    return disabled;
  };

  const requestSignUp = async () => {
    setLoading(true);
    const reqBody = new FormData();
    reqBody.append(
      'data',
      JSON.stringify(isPersonalUser ? personalInfo : enterpriseInfo)
    );
    quickSignUp(reqBody)
      .then(({ data }) =>
        data.resultCd === '0000'
          ? handleSuccess(data.data.assignTelno)
          : handleFail(data.data.result)
      )
      .catch(() => navigate('/signup/quick/error/-3'))
      .finally(() => setLoading(false));

    function handleSuccess(telno: string) {
      setAssignTelno(telno);
      navigate('/signup/quick/complete');
    }

    function handleFail(code: number) {
      navigate(`/signup/quick/error/${String(code)}`);
    }
  };

  return (
    <>
      <Loader loading={loading} />
      <PolicyModal
        termsCode={policyModal.termsCode}
        policyModal={policyModal.isOpen}
        closeModal={() =>
          setPolicyModal((prev) => ({
            ...prev,
            isOpen: false,
          }))
        }
      />
      <section className="bg-[#F8F7F8] pt-[180px] pb-48 max-md:pt-[62px] max-md:pb-24 max-md:bg-white">
        <div className="flex justify-center max-md:block">
          <div>
            <section className="flex flex-col text-[32px] max-2xl:text-3xl space-y-1 max-md:px-5 max-md:text-2xl">
              <MobileBackButton />

              <div className="leading-[140%]">
                <h1>{t(`${memberFnm1 || enterprise.memberFnm1}님의`)}</h1>
                <h1 className="font-bold">{t('동의가 필요해요.')}</h1>
              </div>
              <div className="text-black4 text-base !mt-8">
                <Breadcrumb current="step02" />
              </div>
            </section>

            <section className="mt-7 p-10 bg-white rounded-lg text-lg space-y-10 max-md:px-5 max-md:w-full max-md:mt-5 max-md:p-5 min-w-[720px] w-full">
              <SignupAgreement
                setPolicyModal={setPolicyModal}
                setRegisterInfo={setRegisterInfo}
                registerInfo={registerInfo}
              />
            </section>

            <div className="flex justify-center mt-14 max-md:px-5">
              <SignupButton onClick={requestSignUp} disabled={isDisabled()}>
                다음
              </SignupButton>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
