// import {RegCertAtom} from '@/store';
// import { useRecoilValue, useSetRecoilState } from 'recoil';
import api from './api';
import odcloudApi from './apiOdcloud';

const regCertRequest = async () => {
  return await api.get('/regcert/auth');
};

const regCertResultRequest = async (reqBody) => {
  return await api.post('/regcert/auth/result', reqBody);
};

const sendEmailRequest = async (reqBody) => {
  return await api.post('/api/br/auth/v1/email', reqBody);
};

const checkEmailAuthNumber = async (reqBody) => {
  return await api.post('/api/br/auth/v1/verify', reqBody);
};

const checkBusinessNumberRequest = async (reqBody) => {
  return await odcloudApi.post('/api/nts-businessman/v1/validate', reqBody);
};

export {
  regCertRequest,
  regCertResultRequest,
  sendEmailRequest,
  checkBusinessNumberRequest,
  checkEmailAuthNumber,
};
