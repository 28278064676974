export default function EnterpriseIcon() {
  return (
    <svg
      width="41"
      height="40"
      viewBox="0 0 41 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28.834 31.6667H32.1673V18.3333H22.1673V31.6667H25.5007V21.6667H28.834V31.6667ZM5.50065 31.6667V6.66667C5.50065 6.22464 5.67625 5.80072 5.98881 5.48816C6.30137 5.17559 6.72529 5 7.16732 5H30.5007C30.9427 5 31.3666 5.17559 31.6792 5.48816C31.9917 5.80072 32.1673 6.22464 32.1673 6.66667V15H35.5007V31.6667H37.1673V35H3.83398V31.6667H5.50065ZM12.1673 18.3333V21.6667H15.5007V18.3333H12.1673ZM12.1673 25V28.3333H15.5007V25H12.1673ZM12.1673 11.6667V15H15.5007V11.6667H12.1673Z"
        fill="#222222"
      />
    </svg>
  );
}
