import { ENV } from '../env';

export const ACCESS_TOKEN = 'accessToken';
export const REFRESH_TOKEN = 'refreshToken';
export const ACCESS_TOKEN_EXPIRE = 'accessTokenExpire';
export const REFRESH_TOKEN_EXPIRE = 'refreshTokenExpire';

export const OAUTH2_API_BASE_URL = ENV.REACT_APP_OAUTH2_API_URL;
export const OAUTH2_REDIRECT_URI = ENV.REACT_APP_OAUTH2_REDIRECT_URI;

const BASE_URL =
  ENV.REACT_APP_SERVICE_MODE === 'production'
    ? ENV.REACT_APP_BASE_URL
    : ENV.REACT_APP_DEV_BASE_URL;

export const SSO_URL =
  ENV.REACT_APP_SERVICE_MODE === 'production'
    ? ENV.REACT_APP_SSO_URL
    : ENV.REACT_APP_DEV_SSO_URL;

export const SSO_REDIRECT_URI = BASE_URL + ENV.REACT_APP_SSO_REDIRECT_URI;
export const SNS_REDIRECT_URI = BASE_URL + ENV.REACT_APP_SNS_REDIRECT_URI;

export const SSO_SESSION_URL = SSO_URL + '/sso/apply/sid';
export const SSO_SNS_LOGIN_URL =
  SSO_URL + '/sso/sns/auth?response_type=code&client_id=brand';
export const SSO_LOGIN_URL =
  SSO_URL +
  '/sso/auth?response_type=code&client_id=brand' +
  '&redirect_uri=' +
  SSO_REDIRECT_URI;
export const SSO_AUTH_URL =
  SSO_URL + '/sso/auth?response_type=code&client_id=brand';
