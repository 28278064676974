import { Dialog, Transition } from '@headlessui/react';
import { Fragment, SetStateAction } from 'react';
import LoginComponent from '../common/LoginComponent';

export default function LoginPage({
  closeModal,
  loginPage,
  banner,
  quickBanner,
}: {
  closeModal: React.Dispatch<SetStateAction<boolean>>;
  loginPage: boolean;
  banner: boolean;
  quickBanner: boolean;
}) {
  return (
    <Transition appear show={loginPage} as={Fragment}>
      <Dialog as="div" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="absolute top-0 z-10 w-full h-full bg-black bg-opacity-25" />
        </Transition.Child>
        <div
          className={`absolute z-20 ${
            banner || quickBanner ? 'top-[130px]' : 'top-[36px]'
          } max-md:-top-7 inset-0 overflow-y-auto`}
        >
          <div className="flex text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-0"
              enterTo="opacity-100 scale-100 translate-y-[82px]"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-100"
            >
              <Dialog.Panel className="w-full transform !overflow-visible bg-white transition-all border-t border-gray4">
                <LoginComponent closeModal={() => closeModal(false)} />
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
