import AppleIcon from '../components/vector/myInfo/AppleIcon';
import KakaoIcon from '../components/vector/myInfo/KakaoIcon';
import NaverIcon from '../components/vector/myInfo/NaverIcon';

// types
export interface PaymentsArrayType {
  service: '워크센터' | '스튜디오' | '손비서앱' | '';
  membership: '베이직' | '비즈니스' | '프로페셔널' | '';
  productType: 'product' | 'subscription' | '';
  date: string;
  status: '결제완료' | '취소완료' | '취소예정' | '';
  membershipPrice: number;
}

export interface CreditsListType {
  service: '워크센터' | '스튜디오' | '손비서앱';
  item: string;
  creditUsed: number;
  usedDate: string;
  status: '사용' | '지급' | '소멸';
}

export interface InquiryListType {
  service: '스튜디오' | '워크센터' | '손비서앱';
  type: '회원관련' | '결제환불' | '메타휴먼';
  postTitle: string;
  registerDate: string;
  status: '접수완료' | '답변완료';
  postContent: string;
  contactAnswer: string;
}

interface RadioArrayType {
  type:
    | '회원'
    | '메타휴먼'
    | '결제/환불'
    | '콘텐츠'
    | '장애'
    | '오류'
    | '개선제안'
    | '기타';
  value:
    | 'N0501'
    | 'N0502'
    | 'N0503'
    | 'N0504'
    | 'N0505'
    | 'N0506'
    | 'N0507'
    | 'N0508'
    | 'N0599';
}

export interface MembershipType {
  serviceType: '상품' | '구독';
  serviceName:
    | '엔터프라이즈'
    | '프로페셔널'
    | '베이직'
    | '엔터프라이즈 플러스'
    | '프로페셔널 플러스'
    | '베이직 플러스';
  status: 'using' | 'beforeUse' | 'expired';
  expDate?: string;
  periodUse: string;
}

interface SnsLoginType {
  code: 'kakao' | 'naver' | 'apple';
  name: '카카오 로그인' | '네이버 로그인' | '애플 로그인';
  connected: boolean;
  icon: JSX.Element;
}

interface navListArrayType {
  menu:
    | '요금제관리'
    | '결제내역'
    | '크레딧내역'
    | '서비스 사용내역'
    | '결제수단관리'
    | 'MY문의'
    | '내 정보';

  link:
    | '/membership'
    | '/payments'
    | '/credits'
    | '/service-usage'
    | '/pay-method'
    | '/inquiry'
    | '/my-info';
}

// array
export const adReceptionArray: {
  agreement: 'E-Mail' | 'SMS';
}[] = [
  {
    agreement: 'E-Mail',
  },
  {
    agreement: 'SMS',
  },
];

export const payMethodDummyList = [
  {
    cardCompany: '현대카드',
    isMain: true,
    cardNo: '1234-****-****-3939',
    registeredDate: '2023. 04. 11',
  },
  {
    cardCompany: '국민카드',
    isMain: false,
    cardNo: '4920-****-****-1939',
    registeredDate: '2023. 05. 12',
  },
  {
    cardCompany: '신한카드',
    isMain: false,
    cardNo: '9921-****-****-0021',
    registeredDate: '2023. 03. 11',
  },
];

export const inquiryTypeArray: {
  type: '웹문의' | '전화문의';
}[] = [
  {
    type: '웹문의',
  },
  {
    type: '전화문의',
  },
];

export const radioGroupArray: RadioArrayType[] = [
  {
    type: '회원',
    value: 'N0501',
  },
  {
    type: '메타휴먼',
    value: 'N0502',
  },
  {
    type: '결제/환불',
    value: 'N0503',
  },
  {
    type: '콘텐츠',
    value: 'N0505',
  },
  {
    type: '장애',
    value: 'N0506',
  },
  {
    type: '오류',
    value: 'N0507',
  },
  {
    type: '개선제안',
    value: 'N0508',
  },
  {
    type: '기타',
    value: 'N0599',
  },
];

export const checkBoxArray: {
  method: 'SMS' | 'E-mail';
  value: 'sms' | 'email';
}[] = [
  {
    method: 'SMS',
    value: 'sms',
  },
  {
    method: 'E-mail',
    value: 'email',
  },
];

export const navBarArray: { title: '유료 요금제' | '보유 크레딧' }[] = [
  {
    title: '유료 요금제',
  },
  {
    title: '보유 크레딧',
  },
];

export const navListArray: navListArrayType[] = [
  {
    menu: '요금제관리',
    link: '/membership',
  },
  {
    menu: '결제내역',
    link: '/payments',
  },
  {
    menu: '크레딧내역',
    link: '/credits',
  },
  {
    menu: '서비스 사용내역',
    link: '/service-usage',
  },
  // {
  //   menu: '결제수단관리',
  //   link: '/pay-method',
  // },
  {
    menu: 'MY문의',
    link: '/inquiry',
  },
  {
    menu: '내 정보',
    link: '/my-info',
  },
];

export const ServiceUsageWorkcenterList = [
  {
    type: 'phone',
    data: [
      { label: '인바운드', value: 0 },
      { label: '아웃바운드', value: 0 },
    ],
  },
  {
    type: 'message',
    data: [
      { label: 'SMS', value: 0 },
      { label: 'LMS', value: 0 },
      { label: 'MMS', value: 0 },
    ],
  },
  {
    type: 'talk',
    data: [
      { label: '알림톡', value: 0 },
      { label: '친구톡', value: 0 },
    ],
  },
  {
    type: 'voice',
    data: [{ label: '채팅', value: 0 }],
  },
  {
    type: 'mail',
    data: [{ label: '이메일', value: 0 }],
  },
];

export const ServiceUsageSonList = [
  {
    type: 'phone',
    data: [
      { label: '인바운드', value: 0 },
      { label: '아웃바운드', value: 0 },
    ],
  },
  {
    type: 'message',
    data: [
      { label: 'SMS', value: 0 },
      { label: 'LMS', value: 0 },
      { label: 'MMS', value: 0 },
    ],
  },
  {
    type: 'record',
    data: [{ label: '통화녹음 다운로드', value: 0 }],
  },
];

export const usePurposeArray = [
  {
    purposeName: '워크센터',
    value: 'workcenter',
  },
  {
    purposeName: '플루니안',
    value: 'Ploonian',
  },
  {
    purposeName: '스튜디오',
    value: 'Studio',
  },
  {
    purposeName: '유튜브/방송',
    value: 'Youtube/Stream',
  },
  {
    purposeName: '어시스트',
    value: 'Assist',
  },
  {
    purposeName: '기타',
    value: 'etc',
  },
];

export const snsLoginArray: SnsLoginType[] = [
  {
    code: 'kakao',
    name: '카카오 로그인',
    connected: true,
    icon: <KakaoIcon />,
  },
  {
    code: 'naver',
    name: '네이버 로그인',
    connected: false,
    icon: <NaverIcon />,
  },
  {
    code: 'apple',
    name: '애플 로그인',
    connected: false,
    icon: <AppleIcon />,
  },
];
