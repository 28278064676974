import { t } from 'i18next';
import { KeyboardEvent, useState } from 'react';
import { useRecoilValue } from 'recoil';
import Button from '../../../components/common/Buttons';
import Loader from '../../../components/common/Loader';
import MyPageLayout from '../../../components/common/MyPageLayout';
import SignupButton from '../../../components/common/SignupButtons';
import InformModal from '../../../components/common/modal/InformModal';
import { emailRegex } from '../../../components/hooks/useValidate';
import PloonianModal from '../../../components/my-page/PloonianModal';
import MyEnterpriseInformation from '../../../components/my-page/my-info/MyEnterpriseInformation';
import MyForeignerInformation from '../../../components/my-page/my-info/MyForeignerInfomation';
import MyInformation from '../../../components/my-page/my-info/MyInformation';
import WithdrawModal from '../../../components/my-page/my-info/WithdrawModal';
import {
  modifyUserInfo,
  reqCheckPassword,
} from '../../../services/PrivateService';
import { reqAgreementTerms } from '../../../services/PublicService';
import {
  OnChangeEnterpriseInfoType,
  OnChangePersonalInfoType,
} from '../../../services/TypeOfPrivateService';
import { siteLangCd } from '../../../services/defaultClient';
import { me, userinfoAtom } from '../../../store';

export default function MyInfo() {
  const [loading, setLoading] = useState(false);
  const [ploonianModal, setPloonianModal] = useState(false);
  const [withdrawModal, setWithdrawModal] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [passwordInfo, setPasswordInfo] = useState('');
  const myInfo = useRecoilValue<any>(me);
  const [informModal, setInformModal] = useState({
    isOpen: false,
    phrase: '',
    secondPhrase: '',
  });
  const { language } = useRecoilValue(userinfoAtom);
  const [profileImage, setProfileImage] = useState<File | null>(null);
  const [onChangeInfo, setOnChangeInfo] = useState<OnChangePersonalInfoType>({
    memberTypeCd: '',
    memberAddr1: '',
    memberAddr2: '',
    memberZipno: '',
    termsAgreeOptional: {
      agreeSnum: 47,
      termsSnum: 3,
      termsCd: 'M01G0',
      termsAgreeYn: 'N',
      mailAgreeYn: 'N',
      smsAgreeYn: 'N',
    },
  });
  const [onChangeEnterpriseInfo, setOnChangeEnterpriseInfo] =
    useState<OnChangeEnterpriseInfoType>({
      memberTypeCd: '',
      companyAddr1: '',
      companyAddr2: '',
      companyZipno: '',
      companyMainTelno: '',
      homepageUrl: '',
      managerFnm: '',
      managerEmail: '',
      managerTelno: '',
      depatmentNm: '',
      positionNm: '',
      termsAgreeOptional: {
        agreeSnum: 47,
        termsSnum: 3,
        termsCd: 'M01G0',
        termsAgreeYn: 'N',
        mailAgreeYn: 'N',
        smsAgreeYn: 'N',
      },
    });

  const requestVerification = () => {
    setLoading(true);
    reqCheckPassword({ siteLangCd, password: passwordInfo })
      .then((res) => {
        res.data.data.success
          ? setIsVerified(true)
          : setInformModal({
              isOpen: true,
              phrase: '비밀번호를 다시 확인해주세요.',
              secondPhrase: '',
            });
      })
      .catch((err) => alert(err))
      .finally(() => setLoading(false));
  };

  const modifyMyInfo = () => {
    if (
      myInfo?.memberTypeCd === 'M03B0' &&
      onChangeEnterpriseInfo.managerEmail.length &&
      !emailRegex.test(onChangeEnterpriseInfo.managerEmail)
    ) {
      setInformModal({
        isOpen: true,
        phrase: '이메일 형식을 확인해주세요.',
        secondPhrase: '',
      });
      return;
    }
    setLoading(true);
    reqAgreementTerms({
      siteLangCd,
      termsCd: 'M01G0',
    })
      .then((res) => {
        myInfo.memberTypeCd === 'M03A0'
          ? setOnChangeInfo((prev) => ({
              ...prev,
              termsAgreeOptional: {
                ...prev.termsAgreeOptional,
                termsSnum: res.data.data.termsSnum,
              },
            }))
          : setOnChangeEnterpriseInfo((prev) => ({
              ...prev,
              termsAgreeOptional: {
                ...prev.termsAgreeOptional,
                termsSnum: res.data.data.termsSnum,
              },
            }));
      })
      .catch(console.log);
    modifyUserInfo(
      myInfo.memberTypeCd === 'M03A0' ? onChangeInfo : onChangeEnterpriseInfo,
      profileImage,
      myInfo.memberTypeCd
    )
      .then((res) => {
        if (res.data.resultCd === '3001' || res.data.resultCd === '2001') {
          setInformModal({
            isOpen: true,
            phrase: '오류가 발생했습니다.',
            secondPhrase: '잠시 후 다시 시도해주세요.',
          });
        } else {
          setInformModal({
            isOpen: true,
            phrase: '고객님의 정보가 정상적으로 저장되었습니다.',
            secondPhrase: '',
          });
        }
      })
      .catch((err) => alert(err))
      .finally(() => setLoading(false));
  };

  const handleOnKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    e.key === 'Enter' && requestVerification();
  };

  return (
    <MyPageLayout>
      <Loader loading={loading} />
      <WithdrawModal
        modal={withdrawModal}
        closeModal={() => setWithdrawModal(false)}
      />
      <InformModal
        modal={informModal.isOpen}
        phrase={informModal.phrase}
        secondPhrase={informModal.secondPhrase}
        closeModal={() =>
          setInformModal((prev) => ({
            ...prev,
            isOpen: false,
          }))
        }
      />
      <PloonianModal
        modal={ploonianModal}
        closeModal={() => setPloonianModal(false)}
      />
      <section className="max-md:w-full w-full">
        {!isVerified ? (
          <section className="w-full h-full bg-[#F8F7F8] max-md:bg-white rounded-lg max-md:w-full">
            <div className="h-full flex flex-col space-y-10 justify-center items-center text-center max-md:items-stretch max-md:space-y-7">
              <h1 className="font-bold text-[32px] max-md:text-[28px]">
                {t('비밀번호 재확인')}
              </h1>
              <div className="flex flex-col text-xl max-md:text-base">
                <span>{t('고객님의 개인정보를 안전하게 보호하기 위해 ')}</span>
                <span>{t('비밀번호를 다시 확인합니다.')}</span>
              </div>
              <div className="flex flex-col space-y-5 w-[480px] max-md:w-full">
                <input
                  autoComplete="off"
                  onKeyDown={handleOnKeyPress}
                  onChange={(e) => setPasswordInfo(e.target.value)}
                  placeholder={t('비밀번호를 입력해주세요.')}
                  className="py-3 px-4 w-full border rounded-lg border-borderLightGray focus:border-primary"
                  type="password"
                />
                <SignupButton
                  disabled={!passwordInfo.length}
                  color="sub orange"
                  onClick={requestVerification}
                >
                  {t('확인')}
                </SignupButton>
              </div>
            </div>
          </section>
        ) : (
          <>
            {myInfo?.memberCountryCd !== 'KOR' &&
            myInfo?.memberTypeCd === 'M03A0' ? (
              <MyForeignerInformation
                setPloonianModal={setPloonianModal}
                onChangeInfo={onChangeInfo}
                setProfileImage={setProfileImage}
                profileImage={profileImage}
                setOnChangeInfo={setOnChangeInfo}
                myInfo={myInfo}
              />
            ) : myInfo?.memberTypeCd === 'M03A0' ? (
              <MyInformation
                setPloonianModal={setPloonianModal}
                onChangeInfo={onChangeInfo}
                setProfileImage={setProfileImage}
                profileImage={profileImage}
                setOnChangeInfo={setOnChangeInfo}
                myInfo={myInfo}
              />
            ) : (
              <MyEnterpriseInformation
                setPloonianModal={setPloonianModal}
                setProfileImage={setProfileImage}
                profileImage={profileImage}
                setOnChangeInfo={setOnChangeEnterpriseInfo}
                onChangeInfo={onChangeEnterpriseInfo}
                myInfo={myInfo}
              />
            )}

            <div className="flex flex-col items-center justify-between space-y-[60px] max-md:space-y-7">
              <div className="max-md:w-full">
                <div className="flex items-center space-x-2">
                  <Button onClick={modifyMyInfo} color="orange">
                    {t('저장하기')}
                  </Button>
                </div>
              </div>
              <div
                className={`w-full flex items-center justify-between text-black2 ${
                  language === 'en' && 'max-md:flex-col max-md:items-start'
                }`}
              >
                <span
                  className={`text-lg max-md:text-sm w-3/4 ${
                    language === 'kr' ? 'max-md:w-[180px]' : 'max-md:w-full'
                  }`}
                >
                  {t(
                    '플루닛 통합회원 탈퇴를 원하시면 회원탈퇴 버튼을 눌러주세요.'
                  )}
                </span>
                <button
                  onClick={() => setWithdrawModal(true)}
                  className={`border border-black py-3 px-6 rounded-md w-fit max-md:w-fit text-lg hover:bg-gray5 max-md:text-base max-md:px-3 max-md:min-w-fit ${
                    language === 'en' && 'max-md:mt-5 max-md:w-full'
                  }`}
                >
                  {t('회원 탈퇴')}
                </button>
              </div>
            </div>
          </>
        )}
      </section>
    </MyPageLayout>
  );
}
