export default function MobileLogoSection() {
  return (
    <svg
      width="335"
      height="230"
      viewBox="0 0 335 230"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_2695_100946"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="335"
        height="230"
      >
        <rect width="335" height="230" fill="#F5F5F5" />
      </mask>
      <g mask="url(#mask0_2695_100946)">
        <path
          d="M-559.516 0.453125H807.6"
          stroke="#EEEEEE"
          strokeMiterlimit="10"
        />
        <path
          d="M-559.516 18.0879H807.6"
          stroke="#EEEEEE"
          strokeMiterlimit="10"
        />
        <path
          d="M-559.516 35.7227H807.6"
          stroke="#EEEEEE"
          strokeMiterlimit="10"
        />
        <path
          d="M-559.516 53.3594H807.6"
          stroke="#EEEEEE"
          strokeMiterlimit="10"
        />
        <path
          d="M-559.516 70.9941H807.6"
          stroke="#EEEEEE"
          strokeMiterlimit="10"
        />
        <path
          d="M-559.516 88.5898H807.6"
          stroke="#EEEEEE"
          strokeMiterlimit="10"
        />
        <path
          d="M-559.516 106.227H807.6"
          stroke="#EEEEEE"
          strokeMiterlimit="10"
        />
        <path
          d="M-559.516 123.861H807.6"
          stroke="#EEEEEE"
          strokeMiterlimit="10"
        />
        <path
          d="M-559.516 141.496H807.6"
          stroke="#EEEEEE"
          strokeMiterlimit="10"
        />
        <path
          d="M-559.516 159.094H807.6"
          stroke="#EEEEEE"
          strokeMiterlimit="10"
        />
        <path
          d="M-559.516 176.729H807.6"
          stroke="#EEEEEE"
          strokeMiterlimit="10"
        />
        <path
          d="M-559.516 194.363H807.6"
          stroke="#EEEEEE"
          strokeMiterlimit="10"
        />
        <path
          d="M-559.516 211.998H807.6"
          stroke="#EEEEEE"
          strokeMiterlimit="10"
        />
        <path
          d="M-559.516 229.596H807.6"
          stroke="#EEEEEE"
          strokeMiterlimit="10"
        />
        <path
          d="M141.562 0.453125V335.368"
          stroke="#EEEEEE"
          strokeMiterlimit="10"
        />
        <path
          d="M124.027 0.453125V335.368"
          stroke="#EEEEEE"
          strokeMiterlimit="10"
        />
        <path
          d="M106.527 0.453125V335.368"
          stroke="#EEEEEE"
          strokeMiterlimit="10"
        />
        <path
          d="M88.9922 0.453125V335.368"
          stroke="#EEEEEE"
          strokeMiterlimit="10"
        />
        <path
          d="M71.4531 0.453125V335.368"
          stroke="#EEEEEE"
          strokeMiterlimit="10"
        />
        <path
          d="M53.918 0.453125V335.368"
          stroke="#EEEEEE"
          strokeMiterlimit="10"
        />
        <path
          d="M36.3828 0.453125V335.368"
          stroke="#EEEEEE"
          strokeMiterlimit="10"
        />
        <path
          d="M18.8828 0.453125V335.368"
          stroke="#EEEEEE"
          strokeMiterlimit="10"
        />
        <path
          d="M1.34766 0.453125V335.368"
          stroke="#EEEEEE"
          strokeMiterlimit="10"
        />
        <path
          d="M281.777 0.453125V335.368"
          stroke="#EEEEEE"
          strokeMiterlimit="10"
        />
        <path
          d="M264.238 0.453125V335.368"
          stroke="#EEEEEE"
          strokeMiterlimit="10"
        />
        <path
          d="M246.742 0.453125V335.368"
          stroke="#EEEEEE"
          strokeMiterlimit="10"
        />
        <path
          d="M229.207 0.453125V335.368"
          stroke="#EEEEEE"
          strokeMiterlimit="10"
        />
        <path
          d="M211.672 0.453125V335.368"
          stroke="#EEEEEE"
          strokeMiterlimit="10"
        />
        <path
          d="M194.133 0.453125V335.368"
          stroke="#EEEEEE"
          strokeMiterlimit="10"
        />
        <path
          d="M176.637 0.453125V335.368"
          stroke="#EEEEEE"
          strokeMiterlimit="10"
        />
        <path
          d="M159.098 0.453125V335.368"
          stroke="#EEEEEE"
          strokeMiterlimit="10"
        />
        <path
          d="M334.352 0.453125V335.368"
          stroke="#EEEEEE"
          strokeMiterlimit="10"
        />
        <path
          d="M316.852 0.453125V335.368"
          stroke="#EEEEEE"
          strokeMiterlimit="10"
        />
        <path
          d="M299.316 0.453125V335.368"
          stroke="#EEEEEE"
          strokeMiterlimit="10"
        />
      </g>
      <g clipPath="url(#clip0_2695_100946)">
        <path
          d="M178.724 153.914H174.916C173.917 153.914 173.107 154.729 173.107 155.734C173.107 156.738 173.917 157.553 174.916 157.553H178.724C179.723 157.553 180.533 156.738 180.533 155.734C180.533 154.729 179.723 153.914 178.724 153.914Z"
          fill="url(#paint0_linear_2695_100946)"
        />
        <path
          d="M169.49 142.102C168.49 142.102 167.682 142.915 167.682 143.921V155.734C167.682 156.74 168.49 157.553 169.49 157.553C170.49 157.553 171.298 156.74 171.298 155.734V143.907C171.298 142.901 170.49 142.088 169.49 142.088V142.102Z"
          fill="#44505F"
        />
        <path
          d="M153.75 162.032C153.75 161.026 154.558 160.213 155.558 160.213H162.833C163.833 160.213 164.641 161.026 164.641 162.032C164.641 163.039 163.833 163.852 162.833 163.852H155.558C154.558 163.852 153.75 163.039 153.75 162.032Z"
          fill="url(#paint1_linear_2695_100946)"
        />
        <path
          d="M131.309 150.399C131.103 150.082 130.788 149.931 130.391 149.931H127.062V145.024H130.227C130.651 145.024 130.98 144.858 131.185 144.527C131.377 144.224 131.473 143.866 131.473 143.452C131.473 143.039 131.377 142.694 131.185 142.377C130.98 142.046 130.651 141.881 130.227 141.881H110.499C110.074 141.881 109.745 142.046 109.54 142.377C109.348 142.68 109.252 143.039 109.252 143.452C109.252 143.866 109.348 144.21 109.54 144.527C109.745 144.858 110.074 145.024 110.499 145.024H113.664V149.931H110.335C109.924 149.931 109.622 150.096 109.403 150.399C109.211 150.689 109.115 151.047 109.115 151.461C109.115 151.888 109.211 152.26 109.403 152.563C109.608 152.894 109.924 153.059 110.335 153.059H130.405C130.816 153.059 131.131 152.894 131.336 152.563C131.528 152.26 131.624 151.888 131.624 151.461C131.624 151.061 131.528 150.702 131.336 150.399H131.309ZM117.157 149.931V145.024H123.555V149.931H117.157Z"
          fill="#44505F"
        />
        <path
          d="M131.172 171.489C130.967 171.158 130.638 170.993 130.213 170.993H113.828C113.554 170.993 113.445 170.869 113.445 170.579V169.27C113.445 168.967 113.554 168.856 113.828 168.856H128.008C129.035 168.856 129.734 168.663 130.131 168.264C130.528 167.864 130.72 167.147 130.72 166.086V163.605C130.72 162.543 130.528 161.827 130.131 161.413C129.734 161 129.035 160.807 128.008 160.807H111.088C110.65 160.807 110.335 160.972 110.143 161.303C109.965 161.606 109.869 161.937 109.869 162.295C109.869 162.695 109.965 163.053 110.143 163.343C110.335 163.66 110.65 163.825 111.088 163.825H126.843C127.09 163.825 127.199 163.936 127.199 164.211V165.562C127.199 165.838 127.09 165.948 126.843 165.948H112.664C111.636 165.948 110.938 166.155 110.54 166.554C110.143 166.968 109.951 167.685 109.951 168.746V171.227C109.951 172.288 110.143 173.005 110.54 173.419C110.938 173.818 111.65 174.025 112.664 174.025H130.213C130.638 174.025 130.967 173.86 131.172 173.557C131.364 173.267 131.46 172.909 131.46 172.509C131.46 172.151 131.364 171.82 131.172 171.503V171.489Z"
          fill="#44505F"
        />
        <path
          d="M134.227 155.734C134.008 155.417 133.679 155.266 133.254 155.266H107.444C107.019 155.266 106.691 155.417 106.471 155.734C106.266 156.024 106.156 156.396 106.156 156.823C106.156 157.278 106.266 157.664 106.471 157.967C106.691 158.298 107.019 158.463 107.444 158.463H133.254C133.679 158.463 134.008 158.298 134.227 157.967C134.432 157.664 134.542 157.278 134.542 156.823C134.542 156.368 134.432 156.024 134.227 155.734Z"
          fill="url(#paint2_linear_2695_100946)"
        />
        <path
          d="M141.118 145.465H156.201C156.585 145.465 156.763 145.631 156.763 146.031V147.767C156.763 148.167 156.599 148.333 156.201 148.333H142.597C141.57 148.333 140.871 148.539 140.474 148.939C140.077 149.352 139.885 150.069 139.885 151.131V154.783C139.885 155.845 140.077 156.561 140.474 156.961C140.871 157.361 141.57 157.554 142.597 157.554H159.722C160.174 157.554 160.517 157.388 160.736 157.058C160.941 156.754 161.051 156.382 161.051 155.955C161.051 155.555 160.941 155.183 160.75 154.866C160.53 154.521 160.188 154.356 159.736 154.356H144.022C143.639 154.356 143.46 154.191 143.46 153.791V152.027C143.46 151.641 143.625 151.461 144.022 151.461H157.626C158.653 151.461 159.352 151.268 159.749 150.855C160.147 150.441 160.339 149.725 160.339 148.663V145.052C160.339 143.991 160.147 143.274 159.749 142.86C159.352 142.447 158.653 142.254 157.626 142.254H141.145C140.693 142.254 140.351 142.419 140.131 142.764C139.926 143.081 139.83 143.453 139.83 143.894C139.83 144.294 139.94 144.666 140.131 144.983C140.351 145.328 140.693 145.493 141.145 145.493L141.118 145.465Z"
          fill="#44505F"
        />
        <path
          d="M151.927 172.661C151.927 173.226 151.735 173.653 151.365 173.915C151.009 174.163 150.585 174.301 150.105 174.301C149.626 174.301 149.201 174.177 148.845 173.915C148.475 173.653 148.283 173.226 148.283 172.661V164.859C148.283 164.184 147.735 163.632 147.064 163.632H137.227C136.775 163.632 136.433 163.453 136.214 163.095C136.008 162.764 135.912 162.378 135.912 161.951C135.912 161.524 136.022 161.138 136.214 160.821C136.433 160.476 136.775 160.311 137.227 160.311H150.708C151.379 160.311 151.927 160.862 151.927 161.537V172.647V172.661Z"
          fill="#44505F"
        />
        <path
          d="M188.122 162.019C188.478 162.267 188.903 162.405 189.383 162.405C189.862 162.405 190.287 162.281 190.643 162.019C191.013 161.757 191.205 161.33 191.205 160.764V142.556C191.205 141.991 191.013 141.564 190.643 141.302C190.287 141.054 189.862 140.916 189.383 140.916C188.903 140.916 188.492 141.04 188.122 141.302C187.752 141.564 187.561 141.991 187.561 142.556V160.764C187.561 161.33 187.752 161.757 188.122 162.019Z"
          fill="#44505F"
        />
        <path
          d="M192.409 171.378C192.327 170.937 192.081 170.62 191.67 170.399C190.628 169.917 189.628 169.435 188.683 168.952C187.71 168.456 186.751 167.932 185.833 167.395C185.327 167.119 184.902 166.829 184.559 166.526C184.217 166.237 183.929 165.906 183.724 165.561C183.504 165.231 183.354 164.831 183.258 164.404C183.162 163.962 183.107 163.452 183.107 162.874V161.564C183.107 160.999 182.915 160.572 182.546 160.31C182.189 160.062 181.765 159.924 181.285 159.924C180.806 159.924 180.395 160.048 180.025 160.31C179.655 160.572 179.463 160.999 179.463 161.564V162.874C179.463 163.452 179.408 163.962 179.312 164.404C179.216 164.831 179.066 165.217 178.847 165.561C178.627 165.906 178.34 166.223 177.997 166.512C177.641 166.816 177.203 167.105 176.696 167.381C175.792 167.905 174.833 168.428 173.86 168.938C172.928 169.421 171.915 169.903 170.846 170.386C170.449 170.592 170.202 170.923 170.134 171.378C170.065 171.805 170.134 172.246 170.312 172.674C170.503 173.073 170.791 173.377 171.147 173.556C171.325 173.639 171.504 173.68 171.695 173.68C171.915 173.68 172.134 173.625 172.367 173.515C173.312 173.073 174.23 172.632 175.093 172.178C175.956 171.736 176.805 171.282 177.586 170.813C178.901 170.082 179.847 169.435 180.395 168.911C180.819 168.497 181.107 168.098 181.285 167.712C181.45 168.084 181.737 168.456 182.148 168.856C182.683 169.379 183.6 169.986 184.929 170.744C185.738 171.226 186.587 171.681 187.423 172.109C188.272 172.536 189.19 172.991 190.149 173.446C190.587 173.652 190.998 173.666 191.382 173.473C191.738 173.294 192.026 172.991 192.218 172.591C192.409 172.191 192.464 171.764 192.382 171.35L192.409 171.378Z"
          fill="#44505F"
        />
        <path
          d="M87.3062 144.942L81.1003 147.217C75.9081 137.444 64.2633 132.73 53.6186 136.631C42.9603 140.532 37.0557 151.683 39.3161 162.517L33.1102 164.791C31.4525 165.397 30.5894 167.244 31.1922 168.926C31.795 170.608 33.6307 171.462 35.3021 170.856L41.5081 168.581C46.7003 178.354 58.345 183.054 68.9897 179.167C79.6344 175.28 85.5527 164.129 83.2922 153.282L89.4982 151.007C91.1559 150.401 92.019 148.554 91.4162 146.872C90.8134 145.191 88.9776 144.336 87.3062 144.942ZM66.7841 173.047C58.4683 176.094 49.2758 171.779 46.2482 163.413C43.2206 155.046 47.5086 145.797 55.8243 142.751C63.1125 140.091 71.0584 143.068 74.9628 149.463L66.0717 152.716C64.414 153.323 63.5509 155.17 64.1537 156.852C64.7565 158.533 66.5923 159.388 68.2636 158.781L77.1548 155.528C78.2644 162.958 74.0723 170.387 66.7841 173.047Z"
          fill="url(#paint3_linear_2695_100946)"
        />
      </g>
      <g clipPath="url(#clip1_2695_100946)">
        <path
          d="M280.205 58.7112V58.8904C280.205 60.1172 279.217 61.0958 278.009 61.0958H260.702C259.48 61.0958 258.506 60.1034 258.506 58.8904V58.7112C258.506 57.4845 259.494 56.5059 260.702 56.5059H278.009C279.231 56.5059 280.205 57.4983 280.205 58.7112ZM258.492 72.6741V72.5638C258.492 71.3371 259.48 70.3584 260.688 70.3584H272.684C273.905 70.3584 274.88 71.3509 274.88 72.5638V72.6741C274.88 73.9008 273.892 74.8795 272.684 74.8795H260.688C259.467 74.8795 258.492 73.8871 258.492 72.6741ZM263.063 84.0732H277.995C279.217 84.0732 280.191 85.0656 280.191 86.2786V86.4578C280.191 87.6845 279.203 88.6631 277.995 88.6631H260.688C259.467 88.6631 258.492 87.6707 258.492 86.4578V86.2648C258.492 85.038 259.48 84.0594 260.688 84.0594H263.063V84.0732Z"
          fill="url(#paint4_linear_2695_100946)"
        />
        <path
          d="M128.42 66.7886C128.42 72.8258 124.412 77.5674 117.646 77.5674H113.157C111.895 77.5674 110.865 78.6012 110.865 79.8693V86.3752C110.865 87.6433 109.836 88.677 108.573 88.677C107.311 88.677 106.281 87.6433 106.281 86.3752V75.2655C106.281 73.9974 107.311 72.9637 108.573 72.9637H117.687C121.022 72.9637 123.836 71.2407 123.836 67.078C123.836 62.9154 120.734 61.0959 117.399 61.0959H108.573C107.311 61.0959 106.281 60.0622 106.281 58.7941C106.281 57.526 107.311 56.4922 108.573 56.4922H116.204C124.549 56.4922 128.406 60.71 128.406 66.7886H128.42Z"
          fill="#44505F"
        />
        <path
          d="M135.982 56.4922C137.244 56.4922 138.274 57.526 138.274 58.7941V86.3752C138.274 87.6433 137.244 88.677 135.982 88.677C134.719 88.677 133.689 87.6433 133.689 86.3752V58.7941C133.689 57.526 134.719 56.4922 135.982 56.4922ZM140.881 86.3614C140.881 85.0933 141.911 84.0595 143.173 84.0595H148.952C150.214 84.0595 151.244 85.0933 151.244 86.3614C151.244 87.6295 150.214 88.6633 148.952 88.6633H143.173C141.911 88.6633 140.881 87.6295 140.881 86.3614Z"
          fill="#44505F"
        />
        <path
          d="M252.988 86.429V58.9306C252.988 57.9244 252.398 56.9595 251.451 56.6563C249.873 56.1463 248.418 57.3179 248.418 58.8341V76.9459C248.418 77.5937 247.979 78.1588 247.361 78.3242C246.839 78.4621 246.29 78.2966 245.934 77.8969L229.82 59.7163C229.162 58.972 228.077 58.6411 227.158 59.0409C225.648 59.7163 225.332 61.5633 226.334 62.6935L248.843 88.0968C249.145 88.5103 249.571 88.8136 250.065 88.9514C250.106 88.9514 250.147 88.979 250.188 88.9928C250.243 88.9928 250.312 89.0066 250.367 89.0203C250.477 89.0341 250.573 89.0479 250.682 89.0479C250.682 89.0479 250.682 89.0479 250.696 89.0479C250.696 89.0479 250.724 89.0479 250.737 89.0479C251.273 89.0479 251.822 88.8687 252.247 88.469C252.851 87.9314 253.098 87.1458 252.988 86.4152V86.429Z"
          fill="#44505F"
        />
        <path
          d="M230.054 69.7511C228.667 68.3589 226.293 69.3376 226.293 71.3086V86.4569C226.293 87.6836 227.281 88.6623 228.489 88.6623H228.667C229.889 88.6623 230.863 87.6698 230.863 86.4569V71.4878C230.863 70.9089 230.63 70.3438 230.218 69.9303L230.04 69.7511H230.054Z"
          fill="#44505F"
        />
        <path
          d="M283.58 58.7941V58.6976C283.58 57.4708 284.568 56.4922 285.776 56.4922H302.726C303.948 56.4922 304.922 57.4846 304.922 58.6976V58.7941C304.922 60.0208 303.934 60.9994 302.726 60.9994H285.776C284.555 60.9994 283.58 60.007 283.58 58.7941ZM291.966 86.4579V65.8513C291.966 64.6245 292.954 63.6459 294.162 63.6459H294.34C295.562 63.6459 296.536 64.6383 296.536 65.8513V86.4717C296.536 87.6984 295.548 88.677 294.34 88.677H294.162C292.941 88.677 291.966 87.6846 291.966 86.4717V86.4579Z"
          fill="#44505F"
        />
        <path
          d="M168.003 56.0918C158.931 56.0918 151.574 63.4798 151.574 72.5908C151.574 81.7018 158.931 89.0899 168.003 89.0899C177.075 89.0899 184.432 81.7018 184.432 72.5908C184.432 63.4798 177.075 56.0918 168.003 56.0918ZM168.003 84.4861C161.456 84.4861 156.158 79.1656 156.158 72.5908C156.158 66.016 161.456 60.6955 168.003 60.6955C174.55 60.6955 179.848 66.016 179.848 72.5908C179.848 79.1656 174.55 84.4861 168.003 84.4861Z"
          fill="#44505F"
        />
        <path
          d="M204.773 56.0918C195.7 56.0918 188.344 63.4798 188.344 72.5908C188.344 81.7018 195.7 89.0899 204.773 89.0899C213.845 89.0899 221.201 81.7018 221.201 72.5908C221.201 63.4798 213.845 56.0918 204.773 56.0918ZM204.773 84.4861C198.226 84.4861 192.928 79.1656 192.928 72.5908C192.928 66.016 198.226 60.6955 204.773 60.6955C211.319 60.6955 216.617 66.016 216.617 72.5908C216.617 79.1656 211.319 84.4861 204.773 84.4861Z"
          fill="#44505F"
        />
        <path
          d="M87.4102 59.6768L81.1928 61.9511C75.991 52.1785 64.3247 47.4645 53.6604 51.3653C42.9823 55.266 37.0669 66.417 39.3315 77.251L33.1141 79.5253C31.4533 80.1318 30.5887 81.9788 31.1926 83.6604C31.7965 85.342 33.6356 86.1966 35.3101 85.5901L41.5275 83.3158C46.7293 93.0884 58.3955 97.7886 69.0599 93.9016C79.7242 90.0009 85.6534 78.8637 83.3888 68.0159L89.6062 65.7416C91.2669 65.1351 92.1316 63.2881 91.5277 61.6065C90.9238 59.9249 89.0846 59.0703 87.4102 59.6768ZM66.8501 87.7817C58.519 90.8279 49.3096 86.5136 46.2763 78.1469C43.2431 69.7802 47.539 60.5314 55.8701 57.4852C63.1718 54.825 71.1323 57.8022 75.044 64.1979L66.1364 67.4508C64.4757 68.0573 63.611 69.9043 64.2149 71.5859C64.8188 73.2675 66.658 74.1221 68.3324 73.5156L77.24 70.2627C78.3517 77.6921 74.1518 85.1214 66.8501 87.7817Z"
          fill="url(#paint5_linear_2695_100946)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_2695_100946"
          x1="106.212"
          y1="155.734"
          x2="179.478"
          y2="155.734"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#25C5F5" />
          <stop offset="1" stopColor="#B635F5" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2695_100946"
          x1="107.883"
          y1="162.032"
          x2="179.053"
          y2="162.032"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#25C5F5" />
          <stop offset="1" stopColor="#B635F5" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_2695_100946"
          x1="106.211"
          y1="156.865"
          x2="179.477"
          y2="156.865"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#25C5F5" />
          <stop offset="1" stopColor="#B635F5" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_2695_100946"
          x1="40.5696"
          y1="148.176"
          x2="82.0111"
          y2="167.383"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#25C5F5" />
          <stop offset="1" stopColor="#B635F5" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_2695_100946"
          x1="252.947"
          y1="56.1199"
          x2="288.564"
          y2="91.5712"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#25C5F5" />
          <stop offset="1" stopColor="#B635F5" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_2695_100946"
          x1="40.5872"
          y1="62.9101"
          x2="82.0782"
          y2="82.1753"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#25C5F5" />
          <stop offset="1" stopColor="#B635F5" />
        </linearGradient>
        <clipPath id="clip0_2695_100946">
          <rect
            width="161.438"
            height="45.2655"
            fill="white"
            transform="translate(31 135.266)"
          />
        </clipPath>
        <clipPath id="clip1_2695_100946">
          <rect
            width="273.938"
            height="45.2655"
            fill="white"
            transform="translate(31 50)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
