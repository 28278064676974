export default function NaverIcon() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="32" height="32" rx="16" fill="#5EB032" />
      <path
        d="M17.6656 11.2002V16.0441L14.3486 11.2002H10.7617V20.8002H14.3362V15.9563L17.6541 20.8002H21.2391V11.2002H17.6656Z"
        fill="white"
      />
    </svg>
  );
}
