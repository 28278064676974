export default function ClosedEyeIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.46899 3.42491L0.156761 1.06722L1.20307 0L15.8432 14.9335L14.7969 16L12.3494 13.5035C11.0489 14.3449 9.54006 14.7905 8 14.788C4.01294 14.788 0.695813 11.8616 0 8C0.317981 6.24321 1.18431 4.63837 2.46973 3.42491H2.46899ZM10.0386 11.1466L8.9561 10.0424C8.54204 10.2445 8.07674 10.3106 7.62431 10.2316C7.17189 10.1526 6.75464 9.93236 6.43007 9.6013C6.10551 9.27025 5.88962 8.84466 5.81216 8.38319C5.7347 7.92173 5.79949 7.44712 5.9976 7.02479L4.91506 5.92062C4.44456 6.64579 4.23423 7.51453 4.31968 8.37974C4.40513 9.24495 4.78111 10.0534 5.38395 10.6683C5.98679 11.2832 6.77945 11.6667 7.6277 11.7539C8.47595 11.841 9.32767 11.6265 10.0386 11.1466ZM5.02302 1.78524C5.9451 1.41567 6.95 1.21203 8 1.21203C11.9871 1.21203 15.3042 4.1384 16 8C15.7734 9.2563 15.2642 10.4418 14.5122 11.4634L11.658 8.55209C11.7428 7.96954 11.6926 7.37483 11.5115 6.81562C11.3305 6.2564 11.0235 5.74821 10.6152 5.33178C10.2069 4.91534 9.7087 4.60222 9.16044 4.41752C8.61218 4.23281 8.02912 4.18165 7.45799 4.26812L5.02302 1.78599V1.78524Z"
        fill="#222222"
      />
    </svg>
  );
}
