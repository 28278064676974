import { t } from 'i18next';
import moment from 'moment';
import { CreditsListClientDataType } from '../../../services/TypeOfPrivateService';
import { getCreditUseStatusName, getServiceName } from '../../../utils/common';
import ServiceTag from '../../common/ServiceTag';
import DivIcon from '../../vector/payment-details/DivIcon';

export default function MobileCreditCard({
  creditList,
}: {
  creditList: CreditsListClientDataType[];
}) {
  const displayCredit = (
    dispGroupCd: string,
    credit: number,
    freeCredit: number
  ) => {
    return dispGroupCd === 'B20202'
      ? `${(credit + freeCredit).toLocaleString()}`
      : `- ${(credit + freeCredit).toLocaleString()}`;
  };
  return (
    <>
      {creditList.map(
        (
          {
            itemName,
            solutionType,
            dispGroupCd,
            credit,
            creditFree,
            fdRegdate,
          },
          idx
        ) => (
          <div
            key={`mobileCredit-${idx}`}
            className="hidden border border-borderGray p-4 rounded-[10px] items-center justify-between max-md:flex"
          >
            <div className="flex flex-col space-y-3 w-full">
              <div className="flex justify-between w-full">
                <span className="font-bold text-lg">{itemName}</span>
                <ServiceTag type={solutionType}>
                  {getServiceName(solutionType)}
                </ServiceTag>
              </div>
              <span
                className={`${
                  dispGroupCd === 'B20202' /* 지급 */
                    ? 'text-black'
                    : 'text-textGray'
                } font-bold`}
              >
                {displayCredit(dispGroupCd, credit, creditFree)}
              </span>
              <div className="text-sm text-black flex items-center space-x-2">
                <span>{moment(fdRegdate).format('YYYY. MM. DD')}</span>
                <i>
                  <DivIcon />
                </i>
                <span>{t(getCreditUseStatusName(dispGroupCd))}</span>
              </div>
            </div>
          </div>
        )
      )}
    </>
  );
}
