import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import MobileBackButton from '../../components/common/MobileBackButton';
import PageLeaveAlert from '../../components/common/PageLeaveAlert';
import SignupButton from '../../components/common/SignupButtons';
import InformModal from '../../components/common/modal/InformModal';
import { passwordRegex } from '../../components/hooks/useValidate';
import EnterpriseInfo from '../../components/signup/EnterpriseInfo';
import EnterpriseOptionalInfoTab from '../../components/signup/EnterpriseOptionalInfo';
import {
  RegisterBusinessAppendInfoType,
  SignUpInfo1BusinessAppendAtom,
  SignUpInfo1BusinessAtom,
} from '../../store';

const inputStyle =
  'w-full py-4 px-3 rounded-lg border border-borderLightGray focus:border-primary max-md:p-3';
const divStyle = 'flex flex-col space-y-2 max-2xl:text-base text-lg';

export default function EnterpriseSignUpRegisterInfo() {
  const setSignUpInfo1BusinessAppend = useSetRecoilState(
    SignUpInfo1BusinessAppendAtom
  );
  const [modalInfo, setModalInfo] = useState({
    isOpen: false,
    phrase: '',
    secondPhrase: '',
  });
  const [registerInfo, setRegisterInfo] =
    useState<RegisterBusinessAppendInfoType>({
      userPassword: '',
      userPasswordConfirm: '',
      companyLogo: null,
    });
  const signUpInfo1Business = useRecoilValue(SignUpInfo1BusinessAtom);
  const navigate = useNavigate();

  useEffect(() => {
    if (!signUpInfo1Business.companyName) {
      setModalInfo({
        isOpen: true,
        phrase: '가입 정보가 없어 초기 단계로 돌아갑니다.',
        secondPhrase: '',
      });
    }
  }, [signUpInfo1Business]);

  const checkEligibility = () => {
    return !passwordRegex.test(registerInfo.userPassword) ||
      registerInfo.userPassword !== registerInfo.userPasswordConfirm
      ? true
      : false;
  };

  const goToNext = () => {
    setSignUpInfo1BusinessAppend(registerInfo);
    navigate('/signup/step03');
  };

  const closeModal = () => {
    if (!signUpInfo1Business.companyName) {
      navigate('/signup');
    } else {
      setModalInfo((prev) => ({
        ...prev,
        isOpen: false,
      }));
    }
  };

  return (
    <>
      <PageLeaveAlert />
      <InformModal
        modal={modalInfo.isOpen}
        closeModal={closeModal}
        phrase={modalInfo.phrase}
        secondPhrase={modalInfo.secondPhrase}
      />
      <section className="bg-[#F8F7F8] pt-[180px] pb-48 max-md:pt-[62px] max-md:pb-14 max-md:bg-white">
        <div className="flex justify-center max-md:block">
          <div>
            <section className="flex flex-col text-3xl max-2xl:text-[28px] space-y-1 max-md:px-5 max-md:text-2xl">
              <MobileBackButton />
              <div className="space-y-5 leading-5">
                <h1>이메일 인증이 완료되었습니다.</h1>
                <h1 className="font-bold">기업회원 정보를 입력해주세요.</h1>
              </div>
            </section>

            <section className="mt-7 p-10 bg-white rounded-lg text-lg space-y-10 max-md:space-y-8 max-md:px-5 max-md:w-full max-md:min-w-full max-md:mt-0 max-md:p-5 min-w-[800px]">
              <EnterpriseInfo
                registerInfo={registerInfo}
                setRegisterInfo={setRegisterInfo}
              />
              <EnterpriseOptionalInfoTab
                setModalInfo={setModalInfo}
                divStyle={divStyle}
                inputStyle={inputStyle}
                registerInfo={registerInfo}
                setRegisterInfo={setRegisterInfo}
              />
            </section>

            <div className="flex justify-center mt-14 max-md:mt-5 max-md:px-5">
              <SignupButton onClick={goToNext} disabled={checkEligibility()}>
                다음
              </SignupButton>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
