import { useTranslation } from 'react-i18next';
import { useSetRecoilState } from 'recoil';
import { floatingNavArray } from '../../array/home';
import { slideIndex } from '../../store';

type Props = {
  activeIndex: number;
};

export default function FloatingNavigation({ activeIndex }: Props) {
  const { t } = useTranslation();
  const setIndex = useSetRecoilState(slideIndex);

  const isActive = (idx: number) => {
    return idx === activeIndex ? true : false;
  };

  return (
    <nav className="max-md:hidden fixed right-10 bottom-1/2 translate-y-1/2 z-10">
      <ul className="flex flex-col items-end space-y-8 xl:space-y-9 2xl:space-y-10">
        {floatingNavArray.map(({ label, index: idx }) => (
          <li
            onClick={() => setIndex(idx)}
            key={label}
            className="text-white flex items-center space-x-3 2xl:space-x-4 cursor-pointer group"
          >
            <span className="opacity-0 group-hover:opacity-100 duration-300 ease-out">
              {t(label)}
            </span>
            <div
              className={`p-2 rounded-full border ${
                isActive(idx) ? 'border-white' : 'border-transparent'
              } duration-300 ease-out`}
            >
              <div
                className={`w-2 2xl:w-3 h-2 2xl:h-3 bg-[#d9d9d9] ${
                  !isActive(idx) && 'bg-opacity-50'
                } duration-300 ease-out rounded-full`}
              />
            </div>
          </li>
        ))}
      </ul>
    </nav>
  );
}
