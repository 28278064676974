export default function MoreArrowDownIcon() {
  return (
    <svg
      width="16"
      height="20"
      viewBox="0 0 16 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.6831 18.5676L8.6831 0L7.31641 0L7.31641 18.5676H8.6831Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 18.2717L0.966397 10.9004L0 11.9132L7.5168 19.7909C7.78366 20.0706 8.21634 20.0706 8.4832 19.7909L16 11.9132L15.0336 10.9004L8 18.2717Z"
        fill="white"
      />
    </svg>
  );
}
