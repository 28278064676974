import clsx from 'clsx';
import { t } from 'i18next';

interface TagTypeProp {
  children: string;
  type?: string;
}

export default function ServiceTag({ children, type }: TagTypeProp) {
  return (
    <div
      className={clsx(
        'py-2 px-[10px] max-2xl:px-3 max-2xl:text-xs font-bold text-sm rounded-full max-md:px-[10px] w-fit h-full bg-black4 bg-opacity-20 text-black4',
        {
          '!bg-[#A868FA] !text-[#9F56FC] !bg-opacity-20 ': type === 'B11', // 워크센터
          '!bg-[#ff8de2] !text-[#fa56b0] !bg-opacity-20 ': type === 'B12', // 메타휴먼
          '!bg-[#7B61FF] !text-[#7B61FF] !bg-opacity-20 ': type === 'B13', // 스튜디오
          '!bg-[#39B5B5] !text-[#36B3B3] !bg-opacity-20 ': type === 'B14', // 손비서앱
          '!bg-[#FFE9E5] !text-[#FF6D51]': type === 'B15', // 플루닛
        }
      )}
    >
      {t(children)}
    </div>
  );
}
