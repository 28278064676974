import { t } from 'i18next';
import { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { navBarArray, navListArray } from '../../array/myPageArray';
import CircleRightArrowIcon from '../../components/vector/mypage/CircleRightArrowIcon';
import {
  getCreditsLeft,
  getMembershipsTotal,
} from '../../services/PrivateService';
import { siteLangCd } from '../../services/defaultClient';
import { currentCredit, currentMembership } from '../../store';

export default function MyPage() {
  const navigate = useNavigate();
  const screenSize = window.innerWidth;
  const [myCredit, setMyCredit] = useRecoilState(currentCredit);
  const [myMembership, setMyMembership] = useRecoilState(currentMembership);
  const pathName = window.location.pathname;
  useEffect(() => {
    if (screenSize > 769) {
      navigate('/my-page/membership');
    }

    getCreditsLeft({ siteLangCd, returnType: 0 })
      .then((res) => setMyCredit(res.data.data))
      .catch(console.log);

    getMembershipsTotal({ siteLangCd, returnType: 0 })
      .then((res) => setMyMembership(res.data.data))
      .catch(console.log);
  }, []);

  return (
    <section className="mt-40 pt-8  max-md:pt-[62px] mb-60 max-md:mt-5 max-md:mb-24 w-full px-4 mx-auto max-md:px-5">
      <section className="flex space-x-20 max-md:space-x-0 max-md:justify-center">
        <div className="flex flex-col w-full">
          <h1 className="font-bold text-2xl mb-5">Mypage</h1>
          <section className="flex border-y mb-10">
            {navBarArray.map(({ title }, idx) => (
              <div
                key={`nav-${idx}`}
                className={`flex flex-col space-y-5 w-1/2 p-5 ${
                  idx === 0 && 'border-r border-borderGray'
                }`}
              >
                <span className="text-[#aaaaaa]">{t(title)}</span>
                <div
                  onClick={() => idx === 0 && navigate('/my-page/membership')}
                  className="flex items-center space-x-2 cursor-pointer"
                >
                  <span className="text-xl font-bold">
                    {idx === 1
                      ? Number(myCredit.creditLefts).toLocaleString()
                      : myMembership.totalCount}
                  </span>
                  {title === '유료 요금제' && <CircleRightArrowIcon />}
                </div>
              </div>
            ))}
          </section>
          <section>
            <ul>
              {navListArray.map(({ menu, link }, idx) => (
                <Link key={`navMenu-${idx}`} to={`/my-page${link}`}>
                  <li
                    className={`border-b flex items-center justify-between  border-borderGray py-5 text-lg cursor-pointer hover:text-[#ff7a51bd] ${
                      pathName.includes(link) ? 'text-[#FF7B51]' : ''
                    } duration-100 ease-out`}
                  >
                    <span>{t(menu)}</span>
                  </li>
                </Link>
              ))}
            </ul>
          </section>
        </div>
        {null}
      </section>
    </section>
  );
}
