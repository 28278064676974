import { useTranslation } from 'react-i18next';
import { bottomArray } from '../../../array/total-info';

export default function TotalBottomSection() {
  const { t } = useTranslation();
  return (
    <section className="mt-[152px] max-2xl:mt-32 max-md:mt-20 flex flex-col space-y-7">
      <div className="w-full flex max-md:flex-col max-md:space-y-5 items-center justify-between">
        <div className="flex flex-col space-y-3 max-2xl:space-y-2">
          <h4 className="font-bold text-2xl max-2xl:text-xl">
            {t('기존 플루닛 서비스 회원분들은 통합계정전환을 진행해 주세요.')}
          </h4>
          <span className="text-xl max-2xl:text-lg">
            {t('하나의 플루닛 ID로 모든 서비스를 이용할 수 있습니다.')}
          </span>
        </div>
        {/* <Button
          onClick={() => window.open('https://studio.ploonet.com')}
          color="white small thin"
        >
          스튜디오 바로가기
        </Button> */}
      </div>
      <div className="grid grid-cols-5 max-md:grid-cols-1 max-md:gap-y-2 gap-x-5">
        {bottomArray.map(({ icon, title }, idx) => (
          <div
            key={`bottom-${idx}`}
            className="flex flex-col max-md:flex-row max-md:items-center max-md:space-x-4 px-10 py-[30px] max-2xl:p-5 max-md:py-4 bg-gray5 rounded-[10px]"
          >
            <i>{icon}</i>
            <div className="mt-2 max-md:mt-0">
              <span className="mt-3 mb-1 font-bold text-black5 max-md:text-sm">{`STEP 0${
                idx + 1
              }`}</span>
              <h3 className="font-bold text-xl max-2xl:text-lg max-md:text-base">
                {t(title)}
              </h3>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}
