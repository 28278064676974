export const photoArray = [
  {
    imgPath: '/photoflow/1.png',
  },
  {
    imgPath: '/photoflow/2.png',
  },
  {
    imgPath: '/photoflow/3.png',
  },
  {
    imgPath: '/photoflow/4.png',
  },
  {
    imgPath: '/photoflow/5.png',
  },
  {
    imgPath: '/photoflow/1.png',
  },
  {
    imgPath: '/photoflow/2.png',
  },
  {
    imgPath: '/photoflow/3.png',
  },
  {
    imgPath: '/photoflow/4.png',
  },
  {
    imgPath: '/photoflow/5.png',
  },
  {
    imgPath: '/photoflow/1.png',
  },
  {
    imgPath: '/photoflow/2.png',
  },
  {
    imgPath: '/photoflow/3.png',
  },
  {
    imgPath: '/photoflow/4.png',
  },
  {
    imgPath: '/photoflow/5.png',
  },
];

export const coreValueArray = [
  {
    heading: '현실을 뛰어넘는 열정',
    paragraph:
      '육체 시간과 공간적 한계를 넘어 우리의 무한한 가능성을 도전할 수 있도록 한계를 뛰어넘는 무한한 열정을 지향합니다.',
  },
  {
    heading: '천재보다 팀',
    paragraph:
      '천재적인 능력이 있는 한 사람에게 의지하기 보다는 함께 모여 팀웍을 통해 성과를 내는 조직이 더 우선합니다.',
  },
  {
    heading: '책임과 자율',
    paragraph:
      '우리의 책임은 상하,갑을의 관계에서 부여되는 일방적 업무가 아닌 우리 스스로의 자율 속에서 이루고자 하는 목표를 위해 형성됩니다.',
  },
  {
    heading: '결과 위의 노력',
    paragraph:
      '좋은 성과를 내는 것은 중요합니다. 그러나 결과를 위해서만 존재하는 조직은 과정의 노력에 전력을 다하는 조직을 이길 수 없습니다.',
  },
];

export const historyArray = [
  {
    year: '2023',
    historyList: [
      { list: '스튜디오 서비스 CES2023 혁신상 수상' },
      { list: '워크센터 B2B 서비스 오픈 베타 출시' },
      { list: '증자 25억 (자본금 52억)' },
      { list: "메타휴먼 '한지아' 클로즈드 알파 출시" },
      { list: '스튜디오 서비스 상용 출시' },
      { list: '워크센터 B2B 서비스 상용 출시 (예정)' },
      { list: "메타휴먼 '한지아' 클로즈드 알파 출시 (예정)" },
      { list: "워크센터 B2B 서비스 '손비서' 베타 출시 (예정)" },
    ],
  },
  {
    year: '2022',
    historyList: [
      { list: '기간통신사업자(구, 별정통신사업자) 등록' },
      { list: '증자 12억 (자본금 27억)' },
      { list: '스튜디오 서비스 클로즈드 알파 출시' },
      { list: '워크센터 B2B 서비스 클로즈드 알파 출시' },
      { list: '스튜디오 서비스 오픈 베타 출시' },
    ],
  },
  {
    year: '2021',
    historyList: [
      { list: '솔트룩스 자회사 플루닛 스튜디오 법인설립 (자본금 15억)' },
    ],
  },
];

export const patentArray = [
  {
    heading: '특허등록 10-2111735',
    content: '복수의 질의 응답 모듈을 가지는 자동 질의 응답 시스템',
    date: '2022. 04',
  },
  {
    heading: '특허등록 10-2221088',
    content: '모듈형 대화 모델을 위한 시스템 및 방법',
    date: '2022. 04',
  },
  {
    heading: '특허등록 10-2256664',
    content: '다국어 질의 응답 시스템',
    date: '2022. 04',
  },
  {
    heading: '특허출원 10-20220142402',
    content: '메타휴먼을 이용한 영상 컨텐츠 생성 및 편집 장치',
    date: '진행중',
  },
  {
    heading: '특허출원 10-20221150907',
    content:
      '메타휴먼을 이용한 영상 컨텐츠의 생성 방법, 장치 및 컴퓨터-판독 기능 기록매체',
    date: '2022. 04',
  },
  {
    heading: '인증',
    content: '서울전파관리소 기간통신사업자 (구, 별정통신사업자) 인증 취득',
    date: '2022. 04',
  },
  {
    heading: '수상',
    content: 'CES Innovation Awards 2023 Best of Innovation',
    date: '2023. 01',
  },
  {
    heading: '수상',
    content: "2023 ImpaCT-ech 대상 ‘플루닛 스튜디오' 과학기술정보통신부장관상",
    date: '2023. 04',
  },
];
