import { MutableRefObject } from "react";
import { useSetRecoilState } from "recoil";
import { biFlowArray } from "../../array/home";
import { slideIndex } from "../../store";

type Props = {
  inquiryRef?: MutableRefObject<HTMLDivElement | null>;
  hasInquiry?: Boolean;
};

export default function BiFlow({ inquiryRef, hasInquiry = true }: Props) {
  const setIndex = useSetRecoilState(slideIndex);

  return (
    <section className="w-full overflow-x-hidden">
      <h4 className="text-[15px] md:text-base font-normal">Our Partners</h4>
      <div className="w-full relative mt-16 mb-10">
        <div className="absolute bottom-0 left-0 bg-gradient-to-r from-black to-black/0 h-24 w-12 md:w-60 z-10" />
        <ul className="w-fit flex overflow-hidden">
          <div className="min-w-fit flex items-center animate-leftFlow1">
            {biFlowArray.map((path, idx) => (
              <img
                className="px-6 md:px-14 flex"
                key={`bi-${idx}`}
                src={path}
                alt="bi image"
              />
            ))}
          </div>
          <div className="min-w-fit flex items-center animate-leftFlow2">
            {biFlowArray.map((path, idx) => (
              <img
                className="px-6 md:px-14 flex"
                key={`bi-${idx}`}
                src={path}
                alt="bi image"
              />
            ))}
          </div>
        </ul>
        <div className="absolute bottom-0 right-0 bg-gradient-to-l from-black to-black/0 h-28 w-12 md:w-60 z-10" />
      </div>

      <button
        onClick={() => setIndex(6)}
        className="uppercase font-normal 2xl:text-lg leading-8 px-4 md:px-7 2xl:px-10 py-2.5 2xl:py-3 border border-white rounded-lg hover:border-primary hover:text-primary duration-150 max-md:hidden"
      >
        partner with ploonet
      </button>

      {hasInquiry ? (
        <button
          onClick={() =>
            inquiryRef?.current?.scrollIntoView({ behavior: "smooth" })
          }
          className="uppercase font-normal 2xl:text-lg leading-8 px-4 md:px-7 2xl:px-10 py-2.5 2xl:py-3 border border-white rounded-lg hover:border-primary hover:text-primary duration-150 md:hidden"
        >
          partner with ploonet
        </button>
      ) : null}
    </section>
  );
}
