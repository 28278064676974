import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import AuthService from '../../services/auth.service';
import { SSO_AUTH_URL, SSO_REDIRECT_URI } from '../../constants';

const SnsRedirect = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  
  useEffect(() => {
    if (params.get('error')) {
      // 에러 발생
      if (opener) {
        self.close();
        opener.postMessage({ 'error' : params.get('error')}, '*');
      } else {
        document.location.href = '/?error=' + params.get('error');
      }
    } else if (params.get('errorCode')) {
        // 에러 발생
        if (opener) {
          self.close();
          opener.postMessage({ 'error' : params.get('errorCode')}, '*');
        } else {
          document.location.href = '/?error=' + params.get('errorCode');
        }
  
    } else if (params.get('code')) {
      //SNS 로그인 후
      AuthService.snsLogin(params.get('code'));
    } else if (params.get('snsRegistSuccessFlag') === 'Y') {
      //SNS 연동 후 (반디 세션이 끊겨서 재호출)
      document.location.href = SSO_AUTH_URL +
      '&rd_c_p=snsRegistSuccessFlag' +
      '&redirect_uri=' + SSO_REDIRECT_URI;

    } else if (params.get('snsRevokeSuccessFlag') === 'Y') {
      //SNS 연동해지 후 (반디 세션이 끊겨서 재호출)
      document.location.href = SSO_AUTH_URL +
      '&rd_c_p=snsRevokeSuccessFlag' +
      '&redirect_uri=' + SSO_REDIRECT_URI;
    } else {
      alert('처리중 오류가 발생하였습니다.');
      if (opener) {
        self.close();
      } else {
        document.location.href = '/';
      }      
    }

  }, [])
  return null;
}

export default SnsRedirect;