export default function DivIcon() {
  return (
    <svg
      width="1"
      height="12"
      viewBox="0 0 1 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        y="12"
        width="12"
        height="0.999969"
        transform="rotate(-90 0 12)"
        fill="#dddddd"
      />
    </svg>
  );
}
