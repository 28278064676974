export default function MobileMainLogo({ isDark }: { isDark: boolean }) {
  return (
    <svg
      width="122"
      height="20"
      viewBox="0 0 122 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_675_15349)">
        <rect width="121.429" height="20" fill={isDark ? 'black' : 'white'} />
        <path
          d="M42.999 7.37799C42.999 10.0502 41.2186 12.1588 38.2097 12.1588H36.2211C35.6589 12.1588 35.2008 12.6181 35.2008 13.1818V16.0732C35.2008 16.6369 34.7427 17.0962 34.1805 17.0962C33.6183 17.0962 33.1602 16.6369 33.1602 16.0732V11.1463C33.1602 10.5826 33.6183 10.1233 34.1805 10.1233H38.2305C39.709 10.1233 40.9583 9.36129 40.9583 7.51369C40.9583 5.66609 39.584 4.86234 38.0952 4.86234H34.1805C33.6183 4.86234 33.1602 4.40305 33.1602 3.83937C33.1602 3.2757 33.6183 2.81641 34.1805 2.81641H37.5746C41.2811 2.81641 42.999 4.68488 42.999 7.37799Z"
          fill={isDark ? 'white' : 'black'}
        />
        <path
          d="M46.3621 2.81641C46.9243 2.81641 47.3824 3.2757 47.3824 3.83937V16.0628C47.3824 16.6264 46.9243 17.0857 46.3621 17.0857C45.7999 17.0857 45.3418 16.6264 45.3418 16.0628V3.83937C45.3418 3.2757 45.7999 2.81641 46.3621 2.81641ZM48.5381 16.0628C48.5381 15.4991 48.9962 15.0398 49.5584 15.0398H52.1301C52.6923 15.0398 53.1504 15.4991 53.1504 16.0628C53.1504 16.6264 52.6923 17.0857 52.1301 17.0857H49.5584C48.9962 17.0857 48.5381 16.6264 48.5381 16.0628Z"
          fill={isDark ? 'white' : 'black'}
        />
        <path
          d="M98.3461 16.0946V3.89206C98.3461 3.44321 98.0858 3.01524 97.6589 2.87954C96.9614 2.66033 96.3159 3.17181 96.3159 3.85031V11.8879C96.3159 12.1697 96.1284 12.4307 95.8473 12.5038C95.6183 12.5664 95.3684 12.4933 95.2122 12.3159L88.0492 4.25741C87.7576 3.92338 87.2683 3.77724 86.8727 3.95469C86.1959 4.25741 86.0606 5.0716 86.5083 5.57265L96.5137 16.8461C96.649 17.0236 96.8364 17.1593 97.0551 17.2324C97.0759 17.2324 97.0967 17.2428 97.1071 17.2532C97.1383 17.2532 97.1592 17.2532 97.1904 17.2637C97.2321 17.2637 97.2841 17.2741 97.3258 17.2741C97.3258 17.2741 97.3362 17.2741 97.3466 17.2741C97.586 17.2741 97.8255 17.1906 98.0233 17.0236C98.294 16.7835 98.3981 16.439 98.3565 16.1154L98.3461 16.0946Z"
          fill={isDark ? 'white' : 'black'}
        />
        <path
          d="M88.1645 8.69247C87.5502 8.0766 86.4883 8.50458 86.4883 9.38141V16.1037C86.4883 16.6465 86.9256 17.085 87.467 17.085H87.5398C88.0812 17.085 88.5185 16.6465 88.5185 16.1037V9.46491C88.5185 9.20395 88.4144 8.95343 88.2374 8.77598L88.1645 8.70291V8.69247Z"
          fill={isDark ? 'white' : 'black'}
        />
        <path
          d="M111.934 3.83937V3.79762C111.934 3.25482 112.371 2.81641 112.912 2.81641H120.45C120.992 2.81641 121.429 3.25482 121.429 3.79762V3.83937C121.429 4.38217 120.992 4.82058 120.45 4.82058H112.912C112.371 4.82058 111.934 4.38217 111.934 3.83937ZM115.661 16.1045V6.96046C115.661 6.41766 116.098 5.97925 116.64 5.97925H116.712C117.254 5.97925 117.691 6.41766 117.691 6.96046V16.1045C117.691 16.6473 117.254 17.0857 116.712 17.0857H116.64C116.098 17.0857 115.661 16.6473 115.661 16.1045Z"
          fill={isDark ? 'white' : 'black'}
        />
        <path
          d="M60.5836 2.64062C56.5543 2.64062 53.2852 5.91829 53.2852 9.95795C53.2852 13.9976 56.5543 17.2753 60.5836 17.2753C64.6128 17.2753 67.882 13.9976 67.882 9.95795C67.882 5.91829 64.6128 2.64062 60.5836 2.64062ZM60.5836 15.2293C57.6788 15.2293 55.3258 12.8703 55.3258 9.95795C55.3258 7.04563 57.6788 4.68655 60.5836 4.68655C63.4884 4.68655 65.8414 7.04563 65.8414 9.95795C65.8414 12.8703 63.4884 15.2293 60.5836 15.2293Z"
          fill={isDark ? 'white' : 'black'}
        />
        <path
          d="M76.9195 2.64062C72.8903 2.64062 69.6211 5.91829 69.6211 9.95795C69.6211 13.9976 72.8903 17.2753 76.9195 17.2753C80.9487 17.2753 84.2179 13.9976 84.2179 9.95795C84.2179 5.91829 80.9487 2.64062 76.9195 2.64062ZM76.9195 15.2293C74.0147 15.2293 71.6617 12.8703 71.6617 9.95795C71.6617 7.04563 74.0147 4.68655 76.9195 4.68655C79.8243 4.68655 82.1773 7.04563 82.1773 9.95795C82.1773 12.8703 79.8243 15.2293 76.9195 15.2293Z"
          fill={isDark ? 'white' : 'black'}
        />
        <path
          d="M109.424 2.81641H101.813C101.25 2.81641 100.793 3.27207 100.793 3.83415C100.793 4.39624 101.25 4.8519 101.813 4.8519H109.424C109.988 4.8519 110.444 4.39624 110.444 3.83415C110.444 3.27207 109.988 2.81641 109.424 2.81641Z"
          fill={isDark ? 'white' : 'black'}
        />
        <path
          d="M109.424 15.043H101.813C101.25 15.043 100.793 15.4986 100.793 16.0607C100.793 16.6228 101.25 17.0785 101.813 17.0785H109.424C109.988 17.0785 110.444 16.6228 110.444 16.0607C110.444 15.4986 109.988 15.043 109.424 15.043Z"
          fill={isDark ? 'white' : 'black'}
        />
        <path
          d="M107.061 8.94531H101.813C101.25 8.94531 100.793 9.40097 100.793 9.96306C100.793 10.5251 101.25 10.9808 101.813 10.9808H107.061C107.624 10.9808 108.081 10.5251 108.081 9.96306C108.081 9.40097 107.624 8.94531 107.061 8.94531Z"
          fill={isDark ? 'white' : 'black'}
        />
        <path
          d="M24.9655 4.27931L22.2169 5.28139C19.916 0.959941 14.752 -1.11728 10.0253 0.60504C5.29857 2.32736 2.68533 7.25422 3.68481 12.0454L0.936229 13.0475C0.197026 13.3189 -0.177781 14.1331 0.0825016 14.8742C0.342784 15.6153 1.16528 15.9911 1.90448 15.7301L4.65306 14.728C6.95396 19.0495 12.118 21.1267 16.8447 19.4044C21.561 17.6821 24.1847 12.7552 23.1852 7.96403L25.9338 6.96195C26.673 6.69056 27.0478 5.87637 26.7875 5.13525C26.5272 4.39414 25.7047 4.01836 24.9655 4.27931ZM15.866 16.6904C12.1804 18.037 8.09921 16.1268 6.75615 12.4316C5.41309 8.73646 7.31836 4.64465 11.004 3.29812C14.2315 2.11859 17.7609 3.43381 19.4892 6.26259L15.5433 7.70307C14.8041 7.97447 14.4293 8.78865 14.6896 9.52977C14.9603 10.2709 15.7723 10.6467 16.5115 10.3857L20.4574 8.94523C20.9468 12.2228 19.0935 15.5109 15.8556 16.6904H15.866Z"
          fill="url(#paint0_linear_675_15349)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_675_15349"
          x1="0"
          y1="0"
          x2="12.4407"
          y2="26.7261"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={isDark ? 'white' : '#FF478B'} />
          <stop offset="1" stopColor={isDark ? 'white' : '#FFBB54'} />
        </linearGradient>
        <clipPath id="clip0_675_15349">
          <rect width="121.429" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
