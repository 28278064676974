export default function MobileFooterLogo() {
  return (
    <svg
      width="201"
      height="31"
      viewBox="0 0 201 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.28 6.6C14.96 6.6 17.52 8.68 17.52 11.68C17.52 14.64 14.96 16.76 11.28 16.76H6.72V6.6H11.28ZM12.48 21.84C19.44 21.84 24.24 17.68 24.24 11.68C24.24 5.72 19.44 1.52 12.56 1.52H0.24V30H6.72V21.84H12.48ZM47.63 30V24.24H35.71V1.52H28.99V30H47.63ZM65.9691 31C75.2891 31 81.7291 24.8 81.7291 15.76C81.7291 6.76 75.2891 0.519998 65.9691 0.519998C56.6091 0.519998 50.2091 6.76 50.2091 15.76C50.2091 24.8 56.6091 31 65.9691 31ZM65.9691 6.28C71.2891 6.28 75.0091 10.2 75.0091 15.76C75.0091 21.32 71.2891 25.24 65.9691 25.24C60.6491 25.2 56.9291 21.32 56.9291 15.76C56.9291 10.2 60.6491 6.28 65.9691 6.28ZM101.321 31C110.641 31 117.081 24.8 117.081 15.76C117.081 6.76 110.641 0.519998 101.321 0.519998C91.9606 0.519998 85.5606 6.76 85.5606 15.76C85.5606 24.8 91.9606 31 101.321 31ZM101.321 6.28C106.641 6.28 110.361 10.2 110.361 15.76C110.361 21.32 106.641 25.24 101.321 25.24C96.0006 25.2 92.2806 21.32 92.2806 15.76C92.2806 10.2 96.0006 6.28 101.321 6.28ZM129.632 1.52H122.232V30H128.592V9.68L140.072 30H147.472V1.52H141.072V21.88L129.632 1.52ZM160.392 17.8H172.312V13H160.392V6.92H173.912V1.52H153.912V30H174.312V24.6H160.392V17.8ZM185.38 30H192.1V7.12H200.66V1.52H176.82V7.12H185.38V30Z"
        fill="white"
      />
    </svg>
  );
}
