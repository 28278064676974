export default function CreditTotalIcon() {
  return (
    <svg
      className="w-[96px] h-[80px] max-md:w-[76px] max-md:h-[66px]"
      viewBox="0 0 96 83"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.5 60L65.5 60C68.2614 60 70.5 57.7614 70.5 55V20C70.5 17.2386 68.2614 15 65.5 15L15.5 15C12.7386 15 10.5 17.2386 10.5 20L10.5 55C10.5 57.7614 12.7386 60 15.5 60Z"
        stroke="#222222"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M50.5 70H30.5"
        stroke="#222222"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M65.4424 37.7754C74.7975 37.7754 93.5098 39.9344 93.5098 59.3657C93.5098 78.797 74.7975 80.9561 65.4424 80.9561"
        fill="url(#paint0_linear_1240_88119)"
      />
      <path
        d="M65.4424 37.7754C74.7975 37.7754 93.5098 39.9344 93.5098 59.3657C93.5098 78.797 74.7975 80.9561 65.4424 80.9561"
        stroke="#222222"
        strokeWidth="3"
      />
      <path
        d="M66.5124 80.9561C55.1846 80.9561 46.0015 71.2898 46.0015 59.3657C46.0015 47.4417 55.1846 37.7754 66.5124 37.7754C77.8402 37.7754 87.0232 47.4417 87.0232 59.3657C87.0232 71.2898 77.8402 80.9561 66.5124 80.9561Z"
        fill="url(#paint1_linear_1240_88119)"
        stroke="#222222"
        strokeWidth="3"
      />
      <path
        d="M71.912 52.4577C70.5378 51.3434 68.8233 50.7338 67.0541 50.7305C62.5828 50.7305 58.9578 54.5951 58.9578 59.3666C58.9578 64.1381 62.5828 68.0027 67.0541 68.0027C68.8764 68.0027 70.5583 67.3593 71.912 66.2755"
        stroke="white"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1240_88119"
          x1="90.7816"
          y1="42.8786"
          x2="65.9017"
          y2="44.4414"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FB9164" />
          <stop offset="1" stopColor="#F66587" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1240_88119"
          x1="49.989"
          y1="42.8786"
          x2="86.1903"
          y2="46.202"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FB9164" />
          <stop offset="1" stopColor="#F66587" />
        </linearGradient>
      </defs>
    </svg>
  );
}
