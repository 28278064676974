import { Dialog, Transition } from '@headlessui/react';
import { Fragment, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

export default function InformModal({
  closeModal,
  modal,
  phrase,
  secondPhrase,
  content,
}: {
  closeModal: React.Dispatch<SetStateAction<boolean>>;
  modal: boolean;
  phrase: string;
  secondPhrase?: string;
  content?: string;
}) {
  const { t } = useTranslation();
  return (
    <>
      <Transition appear show={modal} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-50"
          onClose={() => {
            return;
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 max-md:inset-0 max-md:bottom-0 rounded-xl overflow-y-hidden">
            <div className="flex min-h-full items-center justify-center p-4 text-center max-md:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-300"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full p-5 max-w-[320px] rounded-[20px] pt-8 transform overflow-hidden bg-white text-black2 transition-all">
                  <div className="flex flex-col font-semibold text-lg mb-6 max-md:mb-3 whitespace-pre-wrap">
                    <span>{t(phrase)}</span>
                    {secondPhrase && <span>{t(secondPhrase)}</span>}
                  </div>
                  {content && (
                    <p className="w-[230px] mx-auto mb-6 whitespace-pre-wrap">
                      {t(content)}
                    </p>
                  )}
                  <button
                    onClick={() => closeModal(false)}
                    className="w-full py-[14px] bg-gradient-to-br from-primary to-primaryTo hover:from-hoverPrimary hover:to-hoverPrimaryTo rounded-xl text-white font-bold"
                  >
                    {t('확인')}
                  </button>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
