import { SSO_SESSION_URL } from '../constants';
import api from './api';
import { pushToDataLayer } from './gtag';
import TokenService from './token.service';

const login = (email, password) => {
  return api
    .post('/api/br/sign/signin', {
      email,
      password,
    })
    .then((res) => {
      const resData = res.data.data;
      if (res.data.statusCd === 'OK') {
        switch (resData.confirmCd) {
          case '202':
            return { cd: '202' };
          case '201':
            return { cd: '201' };
          case '301':
            return { cd: '301' };
          case '401':
            return {
              cd: '401',
              userType: resData.memberTypeCd,
              memberUuid: resData.memberUuid,
            };
        }
        pushToDataLayer('login');
        localStorage.setItem('jwt', JSON.stringify(resData));
        let ssoPsId = resData.ssoPsId;
        let redirectUri = window.location.href;

        if (ssoPsId) {
          document.location.href =
            SSO_SESSION_URL +
            '?p_sid=' +
            ssoPsId +
            '&redirect_uri=' +
            redirectUri;
        }
        return res.data;
      } else {
        alert('이메일과 비밀번호를 다시 확인해주세요.');
      }
    })
    .catch(alert);
};

const loginExceed = (email) => {
  return api
    .post('/api/br/sso/login/exceed', {
      email,
    })
    .then((res) => {
      return res.data;
    })
    .catch(console.log);
};

const snsLogin = (authCode) => {
  return api
    .post('/api/br/sso/snsLogin/' + authCode)
    .then((res) => {
      if (res.data.result === 200) {
        dataLayer &&
          dataLayer.push({
            event: 'plevent',
            ev_name: 'login',
          });
        localStorage.setItem('jwt', JSON.stringify(res.data.data));
        document.location.href = '/';
        return res.data;
      } else {
        alert(res.data.message);
      }
    })
    .catch(() => {
      document.location.href = '/?error=SNS_NOT_REGISTER';
    });
};

const ssoLogin = async (authCode) => {
  return await api
    .post('/api/br/sso/snsLogin/' + authCode)
    .then((res) => {
      if (res.data.result === 200) {
        dataLayer &&
          dataLayer.push({
            event: 'plevent',
            ev_name: 'login',
          });
        localStorage.setItem('jwt', JSON.stringify(res.data.data));
        return res.data;
      } else {
        console.log(res.data);
      }
    })
    .catch(() => {
      document.location.href = '/';
    });
};

const logout = () => {
  TokenService.removeLocalAuthToken();
  localStorage.removeItem('paySystemCompanySeq');
  localStorage.removeItem('paySystemMemberSeq');
  localStorage.removeItem('paySystemToken');
};

const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem('user'));
};

const getPublicMyInfo = () => {
  return api.get('/auth/me');
};

const AuthService = {
  login,
  snsLogin,
  logout,
  getCurrentUser,
  getPublicMyInfo,
  ssoLogin,
  loginExceed,
};

export default AuthService;
