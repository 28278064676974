import { useTranslation } from 'react-i18next';
import SubPageDot from '../common/SubpageDot';

type Props = {
  isEnglish: boolean;
};

export default function SonFourthSection({ isEnglish }: Props) {
  const { t } = useTranslation();

  return (
    <section className="space-y-14 max-md:space-y-5 mt-14 flex max-lg:flex-col-reverse items-center justify-between">
      <div className="relative">
        <img
          className="w-full max-w-lg 2xl:max-w-2xl"
          src={isEnglish ? '/son/son-cards-en.png' : '/son/son-cards.png'}
          alt="name cards"
        />
      </div>
      <div className="space-y-[18px] max-md:pr-5">
        <div className="text-white flex max-md:flex-col max-md:space-y-1 font-extralight">
          <SubPageDot />
          <h1 className="text-2xl md:text-4xl 2xl:text-5xl md:leading-[50px] 2xl:leading-[68px]">
            <strong className="font-bold">{t('12명의 손비서가')}</strong>
            <br />
            {t('채용을 기다리고 있습니다.')}
          </h1>
        </div>
        <p className="text-white/60 text-base lg:text-lg 2xl:text-xl font-extralight md:ml-[30px] md:pr-[30px] w-full lg:max-w-lg 2xl:max-w-[613px]">
          {t(
            '다양한 외형과 목소리를 가진 12명의 캐릭터 중 나의 스타일에 맞는 손비서를 선택하세요. 실시간으로 나의 정보를 학습하여 맞춤 비서로 활용할 수 있습니다.'
          )}
        </p>
      </div>
    </section>
  );
}
