export default function EnglishCopy() {
  return (
    <div className="flex flex-col items-center">
      <span className="md:text-2xl xl:text-3xl 2xl:text-[32px] font-thin max-md:whitespace-pre-wrap">
        {'Generative/conversational AI\n has the potential to change the world'}
      </span>
      <span className="text-lg xl:text-xl 2xl:text-2xl font-thin whitespace-pre-wrap mt-1 xl:mt-2 max-md:hidden">
        {
          'It has the power to create new ideas, products, and services\nthat will make our lives easier, more productive, and more creative.'
        }
      </span>
      <span className="text-sm font-thin whitespace-pre-wrap mt-2 md:hidden">
        {
          'It has the power to create new ideas, products,\nand services that will make our lives easier,\nmore productive, and more creative.'
        }
      </span>
      <span className="text-lg xl:text-xl 2xl:text-2xl mt-6 xl:mt-7 2xl:mt-[36px]">
        A company specializing in Generative/conversational AI services
      </span>
      <h1 className="w-fit mt-2 text-[56px] xl:text-6xl 2xl:text-[80px] text-transparent bg-clip-text bg-gradient-to-r from-[#FF4E83] to-[#FFBB54]">
        PLOONET
      </h1>
    </div>
  );
}
