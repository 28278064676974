export default function AppleIcon() {
  return (
    <svg
      width="31"
      height="32"
      viewBox="0 0 31 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect y="0.5" width="31" height="31" rx="15.5" fill="black" />
      <path
        d="M15.5869 13.0756C15.0438 13.0756 14.2031 12.4581 13.3177 12.4804C12.1496 12.4953 11.0783 13.1575 10.4756 14.2065C9.26291 16.312 10.1631 19.422 11.3461 21.1332C11.9264 21.9664 12.6109 22.9039 13.5186 22.8741C14.3891 22.8369 14.7164 22.3087 15.7729 22.3087C16.8219 22.3087 17.1195 22.8741 18.0421 22.8518C18.9795 22.8369 19.5747 22.0036 20.1476 21.1629C20.8098 20.1957 21.0851 19.2583 21.0999 19.2062C21.0776 19.1988 19.2771 18.5068 19.2548 16.4236C19.2399 14.6827 20.6759 13.8494 20.7428 13.8122C19.9244 12.6144 18.6671 12.4804 18.2281 12.4507C17.0823 12.3614 16.1226 13.0756 15.5869 13.0756ZM17.5213 11.3198C18.0049 10.7395 18.3248 9.92852 18.2355 9.125C17.5436 9.15476 16.7103 9.58628 16.2119 10.1666C15.7655 10.68 15.3786 11.5058 15.4827 12.2944C16.2491 12.354 17.0377 11.9001 17.5213 11.3198Z"
        fill="white"
      />
    </svg>
  );
}
