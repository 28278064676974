import { Dialog, Transition } from '@headlessui/react';
import { Fragment, SetStateAction } from 'react';
import CloseIcon from '../vector/common/CloseIcon';

export default function VideoModal({
  open,
  closeModal,
  videoUrl,
}: {
  open: boolean;
  closeModal: React.Dispatch<
    SetStateAction<{ open: boolean; videoUrl: string }>
  >;
  videoUrl: string;
}) {
  return (
    <Transition appear show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50"
        onClose={() => closeModal({ open: false, videoUrl: '' })}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-50" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-hidden">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-300"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-2xl transform transition-all relative">
                <button
                  className="absolute -right-10 -top-10 max-md:right-1/2 max-md:translate-x-1/2 max-md:-top-12 focus:ring-transparent focus:border-transparent active:ring-transparent"
                  onClick={() => closeModal({ open: false, videoUrl: '' })}
                >
                  <CloseIcon isDark={true} isBig={true} />
                </button>
                <video
                  className="rounded-lg"
                  controls
                  autoPlay
                  controlsList="nodownload nofullscreen"
                  src={videoUrl}
                />
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
