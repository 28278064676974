export default function UsageTalkIcon() {
  return (
    <svg
      width="90"
      height="90"
      viewBox="0 0 90 90"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="45" cy="45" r="45" fill="white" />
      <path
        d="M32.863 64.6499L32.8282 64.6712L32.7973 64.6979C32.7721 64.7197 32.742 64.7341 32.7102 64.7403C32.6703 64.7362 32.632 64.7212 32.5995 64.6965C32.5801 64.6732 32.5662 64.6454 32.5592 64.615C32.5509 64.5792 32.5527 64.5419 32.5643 64.5072L32.583 64.4513L32.5882 64.3926L33.1095 58.4996C33.1637 57.8868 32.8384 57.3151 32.3121 57.0287C26.3605 53.7899 22.5 48.3874 22.5 42.3051C22.5 32.6426 32.4264 24.5 44.9836 24.5C57.526 24.5 67.5 32.6274 67.5 42.3051C67.5 51.9838 57.5577 60.1263 45.0173 60.1267C44.0855 60.1219 42.4982 59.9849 41.5861 59.8983C41.0416 59.8466 40.492 59.9701 40.0223 60.2582L32.863 64.6499ZM45.0183 59.4347H45.0209C57.3328 59.4328 66.808 51.4619 66.808 42.3051C66.808 33.1502 57.3016 25.192 44.9836 25.192C32.6529 25.192 23.192 33.1637 23.192 42.3051C23.192 48.2578 27.0958 53.6086 33.2115 56.7202L33.6249 56.9305C33.8076 57.0235 33.9151 57.2186 33.896 57.4227L33.4996 61.6608C33.431 62.3942 34.2245 62.8949 34.857 62.5174L40.2307 59.3099C40.4701 59.1671 40.751 59.1101 41.0271 59.1485C42.3497 59.3324 43.683 59.4279 45.0183 59.4347Z"
        fill="#FF6D51"
        stroke="#FF6D51"
      />
      <path
        d="M34.9911 40.2506H33.1445C32.9156 40.2573 32.6896 40.1987 32.4927 40.0816C32.4053 40.0269 32.3302 39.9546 32.2721 39.8695C32.2139 39.7843 32.174 39.6881 32.1548 39.5868C32.1489 39.5225 32.1489 39.458 32.1548 39.3938C32.1491 39.2736 32.1724 39.1537 32.2228 39.0444C32.2732 38.9352 32.3493 38.8397 32.4445 38.7661C32.6549 38.6137 32.9088 38.5332 33.1686 38.5367H38.7327C38.9634 38.5286 39.1909 38.5919 39.3844 38.7178C39.4739 38.7701 39.5506 38.8417 39.609 38.9273C39.6675 39.0129 39.7062 39.1103 39.7224 39.2127C39.7282 39.2729 39.7282 39.3335 39.7224 39.3938C39.728 39.5157 39.7048 39.6372 39.6544 39.7484C39.6041 39.8596 39.5281 39.9572 39.4327 40.0333C39.2274 40.1855 38.9759 40.2623 38.7206 40.2506H36.9343V46.5992C36.9416 46.7345 36.9206 46.8697 36.8728 46.9964C36.8251 47.1232 36.7515 47.2386 36.6567 47.3354C36.5658 47.428 36.4566 47.5005 36.3361 47.5483C36.2155 47.5961 36.0863 47.6181 35.9567 47.613C35.73 47.6195 35.5081 47.547 35.3291 47.4079C35.1586 47.272 35.0429 47.0791 35.0032 46.8647C34.9913 46.7766 34.9913 46.6873 35.0032 46.5992L34.9911 40.2506Z"
        fill="url(#paint0_linear_1240_89817)"
      />
      <path
        d="M40.252 39.0111C40.305 38.8125 40.4297 38.6405 40.602 38.5283C40.7855 38.4243 40.9947 38.3741 41.2055 38.3835H41.6641C41.8877 38.3755 42.1092 38.4298 42.3038 38.5404C42.5099 38.679 42.6564 38.8901 42.7141 39.1318L45.128 45.9511C45.1906 46.129 45.243 46.3104 45.2849 46.4943C45.2891 46.5707 45.2891 46.6471 45.2849 46.7235C45.289 46.8412 45.2674 46.9583 45.2215 47.0667C45.1757 47.1751 45.1067 47.2722 45.0194 47.3512C44.9349 47.4378 44.8333 47.5061 44.7212 47.5518C44.6091 47.5975 44.4887 47.6196 44.3677 47.6167C44.1815 47.6352 43.9945 47.5905 43.8369 47.4898C43.6792 47.3891 43.5601 47.2382 43.4987 47.0615L42.9917 45.577H39.8175L39.3105 47.0615C39.2506 47.2417 39.13 47.3955 38.9693 47.4967C38.8087 47.598 38.6179 47.6403 38.4295 47.6167C38.2254 47.622 38.0255 47.5582 37.8622 47.4356C37.7031 47.3091 37.5958 47.1289 37.5605 46.9288C37.5541 46.8605 37.5541 46.7918 37.5605 46.7235C37.5397 46.6037 37.5397 46.4813 37.5605 46.3615C37.5605 46.2288 37.645 46.0838 37.6932 45.9511L40.252 39.0111ZM41.4589 40.5923L40.3123 44.2131H42.5934L41.4589 40.5923Z"
        fill="url(#paint1_linear_1240_89817)"
      />
      <path
        d="M45.5636 39.3973C45.5513 39.1264 45.6467 38.8618 45.8291 38.6611C45.9218 38.5676 46.033 38.4945 46.1557 38.4467C46.2783 38.3989 46.4097 38.3773 46.5412 38.3834C46.7673 38.3807 46.9879 38.4529 47.1688 38.5886C47.3364 38.7302 47.4478 38.9271 47.4826 39.1437C47.4945 39.2278 47.4945 39.3132 47.4826 39.3973V45.7459H50.8017C51.0318 45.7429 51.2579 45.8056 51.4535 45.9268C51.5413 45.981 51.6168 46.0531 51.6751 46.1384C51.7333 46.2236 51.7729 46.3201 51.7914 46.4217C51.7914 46.4217 51.7914 46.5424 51.7914 46.6028C51.7971 46.7229 51.7738 46.8427 51.7234 46.9519C51.673 47.0612 51.5969 47.1568 51.5017 47.2304C51.2913 47.3828 51.0373 47.4631 50.7776 47.4596H46.6981C46.4383 47.4764 46.18 47.4087 45.9618 47.2666C45.7667 47.1207 45.6367 46.904 45.5998 46.6632C45.5909 46.5305 45.5909 46.3974 45.5998 46.2648L45.5636 39.3973Z"
        fill="url(#paint2_linear_1240_89817)"
      />
      <path
        d="M52.0091 39.3964C52.0019 39.1264 52.0967 38.8635 52.2746 38.6602C52.4555 38.4959 52.6875 38.3988 52.9316 38.3856C53.1757 38.3724 53.4168 38.4438 53.6143 38.5877C53.7856 38.7281 53.9012 38.9249 53.9402 39.1429C53.9462 39.2273 53.9462 39.3121 53.9402 39.3964V42.3534L56.9938 38.8291C57.1114 38.7088 57.2364 38.5961 57.3679 38.4912C57.4919 38.4174 57.6341 38.3797 57.7783 38.3825C57.9893 38.3814 58.1956 38.4445 58.3697 38.5636C58.4518 38.6188 58.5219 38.6901 58.5758 38.7731C58.6297 38.856 58.6663 38.949 58.6835 39.0464C58.6884 39.0703 58.6884 39.095 58.6835 39.1189C58.6777 39.1467 58.6777 39.1754 58.6835 39.2033C58.6831 39.3303 58.6498 39.4551 58.587 39.5654C58.5229 39.6823 58.446 39.7915 58.3576 39.8912L56.3541 42.0879L58.768 45.8294V45.9501C58.8933 46.1231 58.9911 46.3145 59.0577 46.5175V46.5777C59.0716 46.7176 59.0515 46.8588 58.9989 46.9891C58.9464 47.1194 58.863 47.235 58.7559 47.326C58.5546 47.4782 58.3083 47.559 58.0559 47.5554C57.8882 47.5623 57.7217 47.5248 57.5731 47.4467C57.4124 47.3389 57.2799 47.194 57.1869 47.0243L54.942 43.4035L53.8678 44.526V46.5415C53.875 46.8116 53.7802 47.0744 53.6023 47.2777C53.5069 47.3709 53.3934 47.4437 53.2689 47.4915C53.1443 47.5392 53.0114 47.561 52.8781 47.5554C52.6554 47.5601 52.4379 47.4875 52.2626 47.3502C52.0921 47.2143 51.9764 47.0215 51.9367 46.8071C51.9248 46.719 51.9248 46.6296 51.9367 46.5415L52.0091 39.3964Z"
        fill="url(#paint3_linear_1240_89817)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1240_89817"
          x1="32.1504"
          y1="38.536"
          x2="41.0817"
          y2="45.9903"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF4E83" />
          <stop offset="1" stopColor="#FFBB54" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1240_89817"
          x1="37.5449"
          y1="38.3824"
          x2="46.643"
          y2="46.006"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF4E83" />
          <stop offset="1" stopColor="#FFBB54" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1240_89817"
          x1="45.5625"
          y1="38.3824"
          x2="54.034"
          y2="44.1948"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF4E83" />
          <stop offset="1" stopColor="#FFBB54" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_1240_89817"
          x1="51.9277"
          y1="38.3824"
          x2="60.8191"
          y2="45.2968"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF4E83" />
          <stop offset="1" stopColor="#FFBB54" />
        </linearGradient>
      </defs>
    </svg>
  );
}
