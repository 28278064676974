export default function EyeIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.170898 8C0.902009 4.01778 4.3919 1 8.58568 1C12.7795 1 16.2686 4.01778 17.0005 8C16.2693 11.9822 12.7795 15 8.58568 15C4.3919 15 0.902787 11.9822 0.170898 8ZM8.58568 11.8889C9.61707 11.8889 10.6062 11.4792 11.3355 10.7499C12.0648 10.0206 12.4746 9.0314 12.4746 8C12.4746 6.9686 12.0648 5.97945 11.3355 5.25014C10.6062 4.52083 9.61707 4.11111 8.58568 4.11111C7.55428 4.11111 6.56512 4.52083 5.83582 5.25014C5.10651 5.97945 4.69679 6.9686 4.69679 8C4.69679 9.0314 5.10651 10.0206 5.83582 10.7499C6.56512 11.4792 7.55428 11.8889 8.58568 11.8889ZM8.58568 10.3333C7.96684 10.3333 7.37334 10.0875 6.93576 9.64992C6.49818 9.21233 6.25234 8.61884 6.25234 8C6.25234 7.38116 6.49818 6.78767 6.93576 6.35008C7.37334 5.9125 7.96684 5.66667 8.58568 5.66667C9.20451 5.66667 9.79801 5.9125 10.2356 6.35008C10.6732 6.78767 10.919 7.38116 10.919 8C10.919 8.61884 10.6732 9.21233 10.2356 9.64992C9.79801 10.0875 9.20451 10.3333 8.58568 10.3333Z"
        fill="#222222"
      />
    </svg>
  );
}
