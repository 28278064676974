export default function LinkIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_503_19638)">
        <path
          d="M9.9998 11H2.0498C2.5498 5.947 6.8138 2 11.9998 2C17.5228 2 21.9998 6.477 21.9998 12C21.9998 17.523 17.5228 22 11.9998 22C6.8148 22 2.5508 18.053 2.0498 13H9.9998V16L14.9998 12L9.9998 8V11Z"
          fill="#222222"
        />
      </g>
      <defs>
        <clipPath id="clip0_503_19638">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
