import { useEffect, useRef, useState } from "react";

const QuickServeSecondSection = () => {
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);
  const [currentVideo, setCurrentVideo] = useState<string | null>(null);
  const videoRef = useRef<HTMLVideoElement | null>(null);

  useEffect(() => {
    if (!isVideoModalOpen) return;
    if (!videoRef) return;
    // videoRef.
  }, [isVideoModalOpen]);

  const PORTFOLIOS = [
    {
      src: "/quickserve/01.png",
      alt: "quickserve 01",
      title: "AI Pick 이모저모 차트쑈",
      description: "SK Btv",
      video: "/quickserve/01.mp4",
    },
    {
      src: "/quickserve/02.png",
      alt: "quickserve 02",
      title: "KIMES 전시 안내영상",
      description: "비트컴퓨터",
      video: "/quickserve/02.mp4",
    },
    {
      src: "/quickserve/03.png",
      alt: "quickserve 03",
      title: "감성컴퓨팅 메타휴먼 강의",
      description: "상명대학교",
      video: "/quickserve/03.mp4",
    },
    {
      src: "/quickserve/04.png",
      alt: "quickserve 04",
      title: "AI Pick 돈되는 정보",
      description: "SK Btv",
      video: "/quickserve/04.mp4",
    },
    {
      src: "/quickserve/05.png",
      alt: "quickserve 05",
      title: "제품 소개 영상 (영문)",
      description: "옵투스제약",
      video: "/quickserve/05.mp4",
    },
    {
      src: "/quickserve/06.png",
      alt: "quickserve 06",
      title: "사용자 가이드 스크립트 작성 Tip",
      description: "플루닛 스튜디오",
      video: "/quickserve/06.mp4",
    },
    {
      src: "/quickserve/07.png",
      alt: "quickserve 07",
      title: "당진 도서관 개관 행사 영상",
      description: "당진시립도서관",
      video: "/quickserve/07.mp4",
    },
    {
      src: "/quickserve/08.png",
      alt: "quickserve 08",
      title: "플루닛 워크센터 사용 가이드",
      description: "플루닛 워크센터",
      video: "/quickserve/08.mp4",
    },
    {
      src: "/quickserve/09.png",
      alt: "quickserve 09",
      title: "인재개발원 아카데미 교육 영상",
      description: "롯데인재개발원",
      video: "/quickserve/09.mp4",
    },
    {
      src: "/quickserve/10.png",
      alt: "quickserve 10",
      title: "우리동네알파고 방송",
      description: "엘지헬로비전",
      video: "/quickserve/10.mp4",
    },
    {
      src: "/quickserve/11.png",
      alt: "quickserve 11",
      title: "AI스타트업-창업경진대회 개막식",
      description: "AICA",
      video: "/quickserve/11.mp4",
    },
    {
      src: "/quickserve/12.png",
      alt: "quickserve 12",
      title: "우수직원 시상식",
      description: "Alvogen",
      video: "/quickserve/12.mp4",
    },
  ];

  const openModal = (videoUrl: string) => {
    setCurrentVideo(videoUrl);
    setIsVideoModalOpen(true);
  };

  const closeModal = () => {
    setCurrentVideo(null);
    setIsVideoModalOpen(false);
  };

  const onClickVideo = (event: React.MouseEvent<HTMLVideoElement>) => {
    event.stopPropagation();
    if (!videoRef) return;
  };

  return (
    <div className="w-full bg-black flex flex-col items-center justify-center">
      <div className="relative text-white text-[20px] md:text-[48px] font-bold mt-[102px] md:mt-[182px]">
        PREVIOUS WORKS
        <div className="absolute top-0 left-[-10px] md:left-[-20px] w-2 h-2 md:w-4 md:h-4 rounded-full bg-gradient-to-br from-[#ff4e83] to-[#ffbb54]" />
      </div>
      <div className="grid grid-cols-2 md:grid-cols-4 mt-[36px] md:mt-[56px]">
        {PORTFOLIOS.map((portfolio, index) => {
          return (
            <div
              key={index}
              className="relative group cursor-pointer"
              onClick={() => openModal(portfolio.video)}
            >
              <img className="w-full" src={portfolio.src} alt={portfolio.alt} />
              <div className="absolute top-0 left-0 z-10 hidden group-hover:flex flex-col w-full h-full bg-black/60 items-center justify-center text-white gap-1 md:gap-2">
                <div className="font-bold text-[18px] md:text-[24px]">
                  {portfolio.title}
                </div>
                <div className="text-[12px] md:text-[16px]">
                  {portfolio.description}
                </div>
              </div>
            </div>
          );
        })}
      </div>
      {isVideoModalOpen && currentVideo && (
        <div
          className="fixed inset-0 bg-black/60 flex items-center justify-center z-40"
          onClick={closeModal}
        >
          <video
            ref={videoRef}
            src={currentVideo}
            className="max-w-[960px] w-full aspect-[16/9] z-50"
            controls
            onClick={onClickVideo}
          >
            Your browser does not support the video tag.
          </video>
        </div>
      )}
    </div>
  );
};

export default QuickServeSecondSection;
