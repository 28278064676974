import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';
import { localStorageEffect } from './atomUtil';

// import { recoilPersist } from 'recoil-persist';
// const { persistAtom } = recoilPersist();

export interface JWTDataType {
  accesstoken: string;
  refreshtoken: string;
  expdate?: number;
}

export interface UserDataType {
  language: string;
}

const authAtom = atom({
  key: 'auth',
  default: false as boolean,
  effects: [localStorageEffect('accessToken')],
});

const me = atom({
  key: 'me',
  default: null,
});

const currentCredit = atom({
  key: 'currentCredit',
  default: { creditPolicy: 0, creditUse: 0, creditLefts: 0, credit: 0 },
});

const currentMembership = atom({
  key: 'currentMembership',
  default: { subscCount: 0, totalCount: 0 },
});

const jwtAtom = atom<JWTDataType>({
  key: 'jwt',
  default: {} as JWTDataType,
  effects: [localStorageEffect('jwt')],
});

const { persistAtom } = recoilPersist({
  key: 'selectedLanguage',
  storage: localStorage,
});

const userinfoAtom = atom({
  key: 'userinfo',
  default: { language: 'kr' } as UserDataType,
  effects_UNSTABLE: [persistAtom],
});

const dormantUserInfo = atom({
  key: 'dormantUserInfo',
  default: { userType: '', memberEmail: '', memberUuid: '' },
});

export {
  authAtom,
  currentCredit,
  currentMembership,
  dormantUserInfo,
  jwtAtom,
  me,
  userinfoAtom,
};
