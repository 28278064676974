import axios from 'axios';
import { SSO_URL } from '../constants';

/* 반디 SSO API */
const ssoApi = axios.create({
    baseURL: SSO_URL,
    headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "Accept": "*/*",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Credentials":"true",
    },
});
  
export default ssoApi;