import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { ENV } from '../../env';
import { getPloonianList } from '../../services/PublicService';
import { me } from '../../store';

export function usePloonianImage() {
  const myInfo = useRecoilValue<any>(me);
  const [path, setPath] = useState('ploonian');

  useEffect(() => {
    getPloonianList({ siteLandCd: 'KR' })
      .then((res) => {
        setPath(res.data.data.path);
      })
      .catch(console.log);
  }, []);

  const ploonianFullImage = `${ENV.REACT_APP_AWS_URL}/${path}/image/result/${myInfo?.modelId}_${myInfo?.styleId}.png`;
  const ploonianThumbnail = `${ENV.REACT_APP_AWS_URL}/${path}/image/result/thumbnail/${myInfo?.modelId}_${myInfo?.styleId}.png`;
  const ploonianName = myInfo?.modelNm;
  const ploonianVoice = myInfo?.voiceId;

  return { ploonianFullImage, ploonianThumbnail, ploonianName, ploonianVoice };
}
