export default function LogoSection() {
  return (
    <svg
      className="w-full h-full"
      viewBox="0 0 895 319"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_1737_100740"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="895"
        height="319"
      >
        <rect y="0.763672" width="895" height="317.865" fill="#F5F5F5" />
      </mask>
      <g mask="url(#mask0_1737_100740)">
        <path
          d="M0.484375 1.2168H1367.6"
          stroke="#EEEEEE"
          strokeMiterlimit="10"
        />
        <path
          d="M0.484375 18.8516H1367.6"
          stroke="#EEEEEE"
          strokeMiterlimit="10"
        />
        <path
          d="M0.484375 36.4863H1367.6"
          stroke="#EEEEEE"
          strokeMiterlimit="10"
        />
        <path
          d="M0.484375 54.123H1367.6"
          stroke="#EEEEEE"
          strokeMiterlimit="10"
        />
        <path
          d="M0.484375 71.7578H1367.6"
          stroke="#EEEEEE"
          strokeMiterlimit="10"
        />
        <path
          d="M0.484375 89.3535H1367.6"
          stroke="#EEEEEE"
          strokeMiterlimit="10"
        />
        <path
          d="M0.484375 106.99H1367.6"
          stroke="#EEEEEE"
          strokeMiterlimit="10"
        />
        <path
          d="M0.484375 124.625H1367.6"
          stroke="#EEEEEE"
          strokeMiterlimit="10"
        />
        <path
          d="M0.484375 142.26H1367.6"
          stroke="#EEEEEE"
          strokeMiterlimit="10"
        />
        <path
          d="M0.484375 159.857H1367.6"
          stroke="#EEEEEE"
          strokeMiterlimit="10"
        />
        <path
          d="M0.484375 177.492H1367.6"
          stroke="#EEEEEE"
          strokeMiterlimit="10"
        />
        <path
          d="M0.484375 195.127H1367.6"
          stroke="#EEEEEE"
          strokeMiterlimit="10"
        />
        <path
          d="M0.484375 212.762H1367.6"
          stroke="#EEEEEE"
          strokeMiterlimit="10"
        />
        <path
          d="M0.484375 230.359H1367.6"
          stroke="#EEEEEE"
          strokeMiterlimit="10"
        />
        <path
          d="M0.484375 247.994H1367.6"
          stroke="#EEEEEE"
          strokeMiterlimit="10"
        />
        <path
          d="M0.484375 265.629H1367.6"
          stroke="#EEEEEE"
          strokeMiterlimit="10"
        />
        <path
          d="M0.484375 283.266H1367.6"
          stroke="#EEEEEE"
          strokeMiterlimit="10"
        />
        <path
          d="M0.484375 300.863H1367.6"
          stroke="#EEEEEE"
          strokeMiterlimit="10"
        />
        <path
          d="M0.484375 318.496H1367.6"
          stroke="#EEEEEE"
          strokeMiterlimit="10"
        />
        <path
          d="M70.5938 1.2168V336.132"
          stroke="#EEEEEE"
          strokeMiterlimit="10"
        />
        <path
          d="M53.0586 1.2168V336.132"
          stroke="#EEEEEE"
          strokeMiterlimit="10"
        />
        <path
          d="M35.5195 1.2168V336.132"
          stroke="#EEEEEE"
          strokeMiterlimit="10"
        />
        <path
          d="M17.9844 1.2168V336.132"
          stroke="#EEEEEE"
          strokeMiterlimit="10"
        />
        <path
          d="M0.449219 1.2168V336.132"
          stroke="#EEEEEE"
          strokeMiterlimit="10"
        />
        <path
          d="M228.309 1.2168V336.132"
          stroke="#EEEEEE"
          strokeMiterlimit="10"
        />
        <path
          d="M210.809 1.2168V336.132"
          stroke="#EEEEEE"
          strokeMiterlimit="10"
        />
        <path
          d="M193.273 1.2168V336.132"
          stroke="#EEEEEE"
          strokeMiterlimit="10"
        />
        <path
          d="M175.738 1.2168V336.132"
          stroke="#EEEEEE"
          strokeMiterlimit="10"
        />
        <path
          d="M158.203 1.2168V336.132"
          stroke="#EEEEEE"
          strokeMiterlimit="10"
        />
        <path
          d="M140.699 1.2168V336.132"
          stroke="#EEEEEE"
          strokeMiterlimit="10"
        />
        <path
          d="M123.164 1.2168V336.132"
          stroke="#EEEEEE"
          strokeMiterlimit="10"
        />
        <path
          d="M105.629 1.2168V336.132"
          stroke="#EEEEEE"
          strokeMiterlimit="10"
        />
        <path
          d="M88.0938 1.2168V336.132"
          stroke="#EEEEEE"
          strokeMiterlimit="10"
        />
        <path
          d="M386.059 1.2168V336.132"
          stroke="#EEEEEE"
          strokeMiterlimit="10"
        />
        <path
          d="M368.562 1.2168V336.132"
          stroke="#EEEEEE"
          strokeMiterlimit="10"
        />
        <path
          d="M351.027 1.2168V336.132"
          stroke="#EEEEEE"
          strokeMiterlimit="10"
        />
        <path
          d="M333.488 1.2168V336.132"
          stroke="#EEEEEE"
          strokeMiterlimit="10"
        />
        <path
          d="M315.953 1.2168V336.132"
          stroke="#EEEEEE"
          strokeMiterlimit="10"
        />
        <path
          d="M298.414 1.2168V336.132"
          stroke="#EEEEEE"
          strokeMiterlimit="10"
        />
        <path
          d="M280.918 1.2168V336.132"
          stroke="#EEEEEE"
          strokeMiterlimit="10"
        />
        <path
          d="M263.383 1.2168V336.132"
          stroke="#EEEEEE"
          strokeMiterlimit="10"
        />
        <path
          d="M245.848 1.2168V336.132"
          stroke="#EEEEEE"
          strokeMiterlimit="10"
        />
        <path
          d="M543.812 1.2168V336.132"
          stroke="#EEEEEE"
          strokeMiterlimit="10"
        />
        <path
          d="M526.273 1.2168V336.132"
          stroke="#EEEEEE"
          strokeMiterlimit="10"
        />
        <path
          d="M508.777 1.2168V336.132"
          stroke="#EEEEEE"
          strokeMiterlimit="10"
        />
        <path
          d="M491.238 1.2168V336.132"
          stroke="#EEEEEE"
          strokeMiterlimit="10"
        />
        <path
          d="M473.703 1.2168V336.132"
          stroke="#EEEEEE"
          strokeMiterlimit="10"
        />
        <path
          d="M456.168 1.2168V336.132"
          stroke="#EEEEEE"
          strokeMiterlimit="10"
        />
        <path
          d="M438.668 1.2168V336.132"
          stroke="#EEEEEE"
          strokeMiterlimit="10"
        />
        <path
          d="M421.133 1.2168V336.132"
          stroke="#EEEEEE"
          strokeMiterlimit="10"
        />
        <path
          d="M403.594 1.2168V336.132"
          stroke="#EEEEEE"
          strokeMiterlimit="10"
        />
        <path
          d="M701.562 1.2168V336.132"
          stroke="#EEEEEE"
          strokeMiterlimit="10"
        />
        <path
          d="M684.027 1.2168V336.132"
          stroke="#EEEEEE"
          strokeMiterlimit="10"
        />
        <path
          d="M666.527 1.2168V336.132"
          stroke="#EEEEEE"
          strokeMiterlimit="10"
        />
        <path
          d="M648.992 1.2168V336.132"
          stroke="#EEEEEE"
          strokeMiterlimit="10"
        />
        <path
          d="M631.453 1.2168V336.132"
          stroke="#EEEEEE"
          strokeMiterlimit="10"
        />
        <path
          d="M613.918 1.2168V336.132"
          stroke="#EEEEEE"
          strokeMiterlimit="10"
        />
        <path
          d="M596.383 1.2168V336.132"
          stroke="#EEEEEE"
          strokeMiterlimit="10"
        />
        <path
          d="M578.883 1.2168V336.132"
          stroke="#EEEEEE"
          strokeMiterlimit="10"
        />
        <path
          d="M561.348 1.2168V336.132"
          stroke="#EEEEEE"
          strokeMiterlimit="10"
        />
        <path
          d="M841.777 1.2168V336.132"
          stroke="#EEEEEE"
          strokeMiterlimit="10"
        />
        <path
          d="M824.238 1.2168V336.132"
          stroke="#EEEEEE"
          strokeMiterlimit="10"
        />
        <path
          d="M806.742 1.2168V336.132"
          stroke="#EEEEEE"
          strokeMiterlimit="10"
        />
        <path
          d="M789.207 1.2168V336.132"
          stroke="#EEEEEE"
          strokeMiterlimit="10"
        />
        <path
          d="M771.672 1.2168V336.132"
          stroke="#EEEEEE"
          strokeMiterlimit="10"
        />
        <path
          d="M754.133 1.2168V336.132"
          stroke="#EEEEEE"
          strokeMiterlimit="10"
        />
        <path
          d="M736.637 1.2168V336.132"
          stroke="#EEEEEE"
          strokeMiterlimit="10"
        />
        <path
          d="M719.098 1.2168V336.132"
          stroke="#EEEEEE"
          strokeMiterlimit="10"
        />
        <path
          d="M894.352 1.2168V336.132"
          stroke="#EEEEEE"
          strokeMiterlimit="10"
        />
        <path
          d="M876.852 1.2168V336.132"
          stroke="#EEEEEE"
          strokeMiterlimit="10"
        />
        <path
          d="M859.316 1.2168V336.132"
          stroke="#EEEEEE"
          strokeMiterlimit="10"
        />
      </g>
      <g clipPath="url(#clip0_1737_100740)">
        <path
          d="M429.273 141.134V141.373C429.273 143.008 427.955 144.313 426.345 144.313H403.269C401.64 144.313 400.341 142.99 400.341 141.373V141.134C400.341 139.498 401.658 138.193 403.269 138.193H426.345C427.974 138.193 429.273 139.517 429.273 141.134ZM400.322 159.751V159.604C400.322 157.968 401.64 156.663 403.25 156.663H419.244C420.873 156.663 422.172 157.987 422.172 159.604V159.751C422.172 161.387 420.855 162.692 419.244 162.692H403.25C401.622 162.692 400.322 161.368 400.322 159.751ZM406.416 174.95H426.327C427.955 174.95 429.255 176.273 429.255 177.89V178.129C429.255 179.765 427.937 181.07 426.327 181.07H403.25C401.622 181.07 400.322 179.747 400.322 178.129V177.872C400.322 176.236 401.64 174.931 403.25 174.931H406.416V174.95Z"
          fill="url(#paint0_linear_1737_100740)"
        />
        <path
          d="M226.893 151.904C226.893 159.954 221.549 166.276 212.527 166.276H206.543C204.86 166.276 203.487 167.654 203.487 169.345V178.02C203.487 179.711 202.115 181.089 200.431 181.089C198.748 181.089 197.375 179.711 197.375 178.02V163.207C197.375 161.516 198.748 160.138 200.431 160.138H212.582C217.029 160.138 220.781 157.84 220.781 152.29C220.781 146.74 216.645 144.314 212.198 144.314H200.431C198.748 144.314 197.375 142.936 197.375 141.245C197.375 139.554 198.748 138.176 200.431 138.176H210.606C221.732 138.176 226.875 143.8 226.875 151.904H226.893Z"
          fill="#44505F"
        />
        <path
          d="M236.976 138.176C238.66 138.176 240.032 139.554 240.032 141.245V178.02C240.032 179.711 238.66 181.089 236.976 181.089C235.292 181.089 233.92 179.711 233.92 178.02V141.245C233.92 139.554 235.292 138.176 236.976 138.176ZM243.509 178.001C243.509 176.311 244.882 174.932 246.565 174.932H254.27C255.953 174.932 257.326 176.311 257.326 178.001C257.326 179.692 255.953 181.071 254.27 181.071H246.565C244.882 181.071 243.509 179.692 243.509 178.001Z"
          fill="#44505F"
        />
        <path
          d="M392.984 178.093V141.428C392.984 140.087 392.197 138.8 390.934 138.396C388.83 137.716 386.89 139.278 386.89 141.3V165.449C386.89 166.312 386.304 167.066 385.481 167.286C384.785 167.47 384.053 167.25 383.577 166.717L362.093 142.476C361.215 141.483 359.769 141.042 358.543 141.575C356.53 142.476 356.109 144.939 357.445 146.446L387.457 180.317C387.86 180.868 388.427 181.272 389.086 181.456C389.141 181.456 389.196 181.493 389.25 181.511C389.324 181.511 389.415 181.53 389.488 181.548C389.635 181.566 389.763 181.585 389.909 181.585C389.909 181.585 389.909 181.585 389.928 181.585C389.928 181.585 389.964 181.585 389.982 181.585C390.696 181.585 391.428 181.346 391.995 180.813C392.801 180.096 393.13 179.049 392.984 178.074V178.093Z"
          fill="#44505F"
        />
        <path
          d="M362.405 155.856C360.557 153.999 357.391 155.304 357.391 157.932V178.13C357.391 179.766 358.708 181.071 360.319 181.071H360.557C362.185 181.071 363.485 179.747 363.485 178.13V158.171C363.485 157.399 363.173 156.646 362.624 156.095L362.387 155.856H362.405Z"
          fill="#44505F"
        />
        <path
          d="M433.775 141.245V141.116C433.775 139.481 435.093 138.176 436.703 138.176H459.304C460.933 138.176 462.232 139.499 462.232 141.116V141.245C462.232 142.881 460.914 144.185 459.304 144.185H436.703C435.075 144.185 433.775 142.862 433.775 141.245ZM444.957 178.13V150.655C444.957 149.019 446.274 147.714 447.885 147.714H448.123C449.751 147.714 451.051 149.037 451.051 150.655V178.148C451.051 179.784 449.733 181.089 448.123 181.089H447.885C446.256 181.089 444.957 179.766 444.957 178.148V178.13Z"
          fill="#44505F"
        />
        <path
          d="M279.671 137.643C267.574 137.643 257.766 147.493 257.766 159.641C257.766 171.789 267.574 181.64 279.671 181.64C291.767 181.64 301.576 171.789 301.576 159.641C301.576 147.493 291.767 137.643 279.671 137.643ZM279.671 175.502C270.942 175.502 263.878 168.408 263.878 159.641C263.878 150.875 270.942 143.781 279.671 143.781C288.4 143.781 295.464 150.875 295.464 159.641C295.464 168.408 288.4 175.502 279.671 175.502Z"
          fill="#44505F"
        />
        <path
          d="M328.696 137.643C316.6 137.643 306.791 147.493 306.791 159.641C306.791 171.789 316.6 181.64 328.696 181.64C340.792 181.64 350.601 171.789 350.601 159.641C350.601 147.493 340.792 137.643 328.696 137.643ZM328.696 175.502C319.967 175.502 312.903 168.408 312.903 159.641C312.903 150.875 319.967 143.781 328.696 143.781C337.425 143.781 344.489 150.875 344.489 159.641C344.489 168.408 337.425 175.502 328.696 175.502Z"
          fill="#44505F"
        />
        <path
          d="M172.214 142.421L163.924 145.454C156.988 132.424 141.433 126.138 127.214 131.339C112.976 136.54 105.089 151.408 108.109 165.854L99.8187 168.886C97.6044 169.695 96.4515 172.157 97.2567 174.399C98.0619 176.642 100.514 177.781 102.747 176.972L111.037 173.94C117.972 186.97 133.527 193.237 147.746 188.054C161.966 182.853 169.871 168.004 166.852 153.54L175.142 150.508C177.356 149.699 178.509 147.236 177.704 144.994C176.898 142.752 174.446 141.613 172.214 142.421ZM144.8 179.895C133.692 183.956 121.413 178.204 117.368 167.048C113.324 155.893 119.052 143.561 130.16 139.499C139.896 135.952 150.51 139.922 155.725 148.449L143.849 152.787C141.634 153.595 140.481 156.058 141.287 158.3C142.092 160.542 144.544 161.682 146.777 160.873L158.653 156.536C160.136 166.442 154.536 176.348 144.8 179.895Z"
          fill="url(#paint1_linear_1737_100740)"
        />
      </g>
      <g clipPath="url(#clip1_1737_100740)">
        <path
          d="M779.216 154.385H774.138C772.806 154.385 771.727 155.471 771.727 156.811C771.727 158.15 772.806 159.237 774.138 159.237H779.216C780.547 159.237 781.627 158.15 781.627 156.811C781.627 155.471 780.547 154.385 779.216 154.385Z"
          fill="url(#paint2_linear_1737_100740)"
        />
        <path
          d="M766.903 138.636C765.57 138.636 764.492 139.72 764.492 141.061V156.812C764.492 158.153 765.57 159.238 766.903 159.238C768.237 159.238 769.315 158.153 769.315 156.812V141.043C769.315 139.702 768.237 138.617 766.903 138.617V138.636Z"
          fill="#44505F"
        />
        <path
          d="M745.916 165.211C745.916 163.869 746.994 162.785 748.327 162.785H758.027C759.36 162.785 760.438 163.869 760.438 165.211C760.438 166.553 759.36 167.637 758.027 167.637H748.327C746.994 167.637 745.916 166.553 745.916 165.211Z"
          fill="url(#paint3_linear_1737_100740)"
        />
        <path
          d="M715.996 149.7C715.722 149.277 715.302 149.075 714.772 149.075H710.333V142.532H714.553C715.119 142.532 715.557 142.311 715.831 141.87C716.087 141.466 716.215 140.988 716.215 140.437C716.215 139.886 716.087 139.426 715.831 139.003C715.557 138.562 715.119 138.342 714.553 138.342H688.249C687.683 138.342 687.245 138.562 686.971 139.003C686.715 139.408 686.587 139.886 686.587 140.437C686.587 140.988 686.715 141.448 686.971 141.87C687.245 142.311 687.683 142.532 688.249 142.532H692.469V149.075H688.03C687.482 149.075 687.08 149.295 686.788 149.7C686.532 150.085 686.404 150.563 686.404 151.115C686.404 151.684 686.532 152.181 686.788 152.585C687.062 153.026 687.482 153.247 688.03 153.247H714.79C715.338 153.247 715.758 153.026 716.032 152.585C716.288 152.181 716.416 151.684 716.416 151.115C716.416 150.582 716.288 150.104 716.032 149.7H715.996ZM697.127 149.075V142.532H705.657V149.075H697.127Z"
          fill="#44505F"
        />
        <path
          d="M715.812 177.817C715.538 177.376 715.1 177.156 714.534 177.156H692.687C692.322 177.156 692.176 176.99 692.176 176.604V174.858C692.176 174.454 692.322 174.307 692.687 174.307H711.593C712.963 174.307 713.894 174.05 714.424 173.517C714.954 172.984 715.21 172.028 715.21 170.613V167.305C715.21 165.89 714.954 164.934 714.424 164.383C713.894 163.832 712.963 163.574 711.593 163.574H689.034C688.449 163.574 688.029 163.795 687.774 164.236C687.536 164.64 687.408 165.081 687.408 165.559C687.408 166.092 687.536 166.57 687.774 166.956C688.029 167.379 688.449 167.599 689.034 167.599H710.04C710.369 167.599 710.515 167.746 710.515 168.114V169.915C710.515 170.282 710.369 170.429 710.04 170.429H691.135C689.765 170.429 688.833 170.705 688.303 171.238C687.774 171.789 687.518 172.745 687.518 174.16V177.468C687.518 178.883 687.774 179.839 688.303 180.39C688.833 180.923 689.783 181.199 691.135 181.199H714.534C715.1 181.199 715.538 180.978 715.812 180.574C716.068 180.188 716.196 179.71 716.196 179.177C716.196 178.7 716.068 178.258 715.812 177.836V177.817Z"
          fill="#44505F"
        />
        <path
          d="M719.887 156.81C719.594 156.388 719.156 156.186 718.59 156.186H684.176C683.61 156.186 683.171 156.388 682.879 156.81C682.605 157.196 682.459 157.693 682.459 158.262C682.459 158.869 682.605 159.383 682.879 159.788C683.171 160.229 683.61 160.449 684.176 160.449H718.59C719.156 160.449 719.594 160.229 719.887 159.788C720.161 159.383 720.307 158.869 720.307 158.262C720.307 157.656 720.161 157.196 719.887 156.81Z"
          fill="url(#paint4_linear_1737_100740)"
        />
        <path
          d="M729.074 143.12H749.186C749.697 143.12 749.935 143.341 749.935 143.874V146.189C749.935 146.722 749.715 146.943 749.186 146.943H731.047C729.677 146.943 728.746 147.218 728.216 147.751C727.686 148.303 727.43 149.258 727.43 150.673V155.544C727.43 156.959 727.686 157.914 728.216 158.447C728.746 158.98 729.677 159.238 731.047 159.238H753.88C754.483 159.238 754.94 159.017 755.232 158.576C755.506 158.172 755.652 157.676 755.652 157.106C755.652 156.573 755.506 156.077 755.25 155.654C754.958 155.195 754.501 154.974 753.898 154.974H732.947C732.435 154.974 732.198 154.753 732.198 154.22V151.868C732.198 151.353 732.417 151.115 732.947 151.115H751.085C752.455 151.115 753.387 150.857 753.917 150.306C754.446 149.755 754.702 148.799 754.702 147.384V142.569C754.702 141.154 754.446 140.198 753.917 139.647C753.387 139.095 752.455 138.838 751.085 138.838H729.111C728.508 138.838 728.052 139.058 727.759 139.518C727.485 139.941 727.357 140.437 727.357 141.025C727.357 141.558 727.504 142.054 727.759 142.477C728.052 142.936 728.508 143.157 729.111 143.157L729.074 143.12Z"
          fill="#44505F"
        />
        <path
          d="M743.486 179.381C743.486 180.134 743.23 180.704 742.737 181.053C742.262 181.384 741.696 181.568 741.057 181.568C740.417 181.568 739.851 181.403 739.376 181.053C738.883 180.704 738.627 180.134 738.627 179.381V168.979C738.627 168.078 737.897 167.343 737.002 167.343H723.886C723.284 167.343 722.827 167.104 722.535 166.626C722.261 166.185 722.133 165.671 722.133 165.101C722.133 164.531 722.279 164.017 722.535 163.594C722.827 163.135 723.284 162.914 723.886 162.914H741.86C742.755 162.914 743.486 163.649 743.486 164.55V179.363V179.381Z"
          fill="#44505F"
        />
        <path
          d="M791.747 165.192C792.222 165.523 792.788 165.706 793.427 165.706C794.067 165.706 794.633 165.541 795.108 165.192C795.601 164.843 795.857 164.273 795.857 163.519V139.242C795.857 138.488 795.601 137.918 795.108 137.569C794.633 137.238 794.067 137.055 793.427 137.055C792.788 137.055 792.24 137.22 791.747 137.569C791.254 137.918 790.998 138.488 790.998 139.242V163.519C790.998 164.273 791.254 164.843 791.747 165.192Z"
          fill="#44505F"
        />
        <path
          d="M797.464 177.671C797.355 177.083 797.026 176.66 796.478 176.366C795.09 175.723 793.756 175.079 792.496 174.436C791.199 173.775 789.92 173.076 788.697 172.359C788.021 171.992 787.454 171.606 786.998 171.202C786.541 170.816 786.158 170.375 785.884 169.915C785.591 169.474 785.39 168.941 785.262 168.371C785.135 167.783 785.062 167.103 785.062 166.331V164.585C785.062 163.832 784.806 163.262 784.313 162.913C783.838 162.582 783.271 162.398 782.632 162.398C781.993 162.398 781.445 162.564 780.952 162.913C780.458 163.262 780.203 163.832 780.203 164.585V166.331C780.203 167.103 780.13 167.783 780.002 168.371C779.874 168.941 779.673 169.456 779.381 169.915C779.088 170.375 778.705 170.797 778.248 171.183C777.773 171.588 777.189 171.973 776.513 172.341C775.307 173.039 774.029 173.738 772.732 174.418C771.49 175.061 770.138 175.704 768.713 176.348C768.183 176.623 767.855 177.064 767.763 177.671C767.672 178.24 767.763 178.829 768.001 179.398C768.257 179.931 768.64 180.336 769.115 180.575C769.353 180.685 769.59 180.74 769.846 180.74C770.138 180.74 770.43 180.666 770.741 180.519C772.001 179.931 773.225 179.343 774.376 178.737C775.527 178.149 776.659 177.542 777.7 176.917C779.454 175.943 780.714 175.079 781.445 174.381C782.011 173.83 782.395 173.297 782.632 172.782C782.851 173.278 783.235 173.775 783.783 174.308C784.495 175.006 785.719 175.815 787.491 176.825C788.569 177.469 789.701 178.075 790.815 178.645C791.948 179.215 793.172 179.821 794.45 180.427C795.035 180.703 795.583 180.722 796.094 180.464C796.569 180.225 796.953 179.821 797.209 179.288C797.464 178.755 797.537 178.185 797.428 177.634L797.464 177.671Z"
          fill="#44505F"
        />
        <path
          d="M657.325 142.421L649.05 145.454C642.127 132.424 626.601 126.138 612.408 131.339C598.197 136.54 590.324 151.408 593.338 165.854L585.064 168.886C582.853 169.695 581.703 172.157 582.506 174.399C583.31 176.642 585.758 177.781 587.986 176.972L596.261 173.94C603.184 186.97 618.71 193.237 632.903 188.054C647.096 182.872 654.987 168.004 651.973 153.54L660.248 150.508C662.458 149.699 663.609 147.236 662.805 144.994C662.001 142.752 659.553 141.613 657.325 142.421ZM629.962 179.895C618.874 183.956 606.618 178.204 602.581 167.048C598.544 155.893 604.261 143.561 615.349 139.499C625.067 135.952 635.661 139.922 640.867 148.449L629.012 152.787C626.802 153.595 625.651 156.058 626.455 158.3C627.259 160.542 629.706 161.682 631.935 160.873L643.79 156.536C645.269 166.442 639.68 176.348 629.962 179.895Z"
          fill="url(#paint5_linear_1737_100740)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_1737_100740"
          x1="392.929"
          y1="137.679"
          x2="440.418"
          y2="184.947"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#25C5F5" />
          <stop offset="1" stopColor="#B635F5" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1737_100740"
          x1="109.783"
          y1="146.732"
          x2="165.104"
          y2="172.419"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#25C5F5" />
          <stop offset="1" stopColor="#B635F5" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1737_100740"
          x1="682.532"
          y1="156.811"
          x2="780.22"
          y2="156.811"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#25C5F5" />
          <stop offset="1" stopColor="#B635F5" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_1737_100740"
          x1="684.76"
          y1="165.211"
          x2="779.654"
          y2="165.211"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#25C5F5" />
          <stop offset="1" stopColor="#B635F5" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_1737_100740"
          x1="682.532"
          y1="158.317"
          x2="780.22"
          y2="158.317"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#25C5F5" />
          <stop offset="1" stopColor="#B635F5" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_1737_100740"
          x1="595.009"
          y1="146.732"
          x2="650.265"
          y2="172.341"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#25C5F5" />
          <stop offset="1" stopColor="#B635F5" />
        </linearGradient>
        <clipPath id="clip0_1737_100740">
          <rect
            width="365.25"
            height="60.3541"
            fill="white"
            transform="translate(97 129.52)"
          />
        </clipPath>
        <clipPath id="clip1_1737_100740">
          <rect
            width="215.25"
            height="60.3541"
            fill="white"
            transform="translate(582.25 129.52)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
