import axios from 'axios';
import queryString from 'query-string';

/**
 * 국세청 사업자등록번호 검증을 위한 API
 */

const access_value = process.env.REACT_APP_ODCLOUD_ACCESS_VALUE;
const odcloud_url = process.env.REACT_APP_ODCLOUD_API_URL;
console.log('odcloud_url:' + odcloud_url);
const odcloudApi = axios.create({
  baseURL: process.env.REACT_APP_ODCLOUD_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  params: {
    serviceKey: access_value,
  },
  paramsSerializer: function (params) {
    return queryString.stringify(params, { encode: false });
  },
});

odcloudApi.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default odcloudApi;
