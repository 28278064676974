import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import translationEN from './langs/all.en.json';
import translationKO from './langs/all.ko.json';

// const languages = ['en', 'ko'];

// the translations
const resources = {
  en: {
    translation: translationEN
  },
  ko: {
    translation: translationKO
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: process.env.REACT_APP_LANGUAGE,
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;