import { useTranslation } from 'react-i18next';
import { bodyArray } from '../../../array/total-info';

export default function TotalBodySection() {
  const { t } = useTranslation();

  return (
    <section className="mt-[100px] max-2xl:mt-20 max-md:mt-10 grid grid-cols-3 max-md:grid-cols-1 gap-x-16 max-2xl:gap-x-12 max-md:gap-y-10">
      {bodyArray.map(({ icon, title, content }, idx) => (
        <div
          key={`bodyBox-${idx}`}
          className="flex flex-col max-md:flex-row max-md:items-start max-md:space-x-5 space-y-5 max-md:space-y-0"
        >
          <i className="max-md:min-w-[90px]">{icon}</i>
          <div className="flex flex-col space-y-5 max-md:space-y-2">
            <h2 className="font-bold text-2xl max-2xl:text-xl max-md:text-lg">
              {t(title)}
            </h2>
            <p className="text-lg max-2xl:text-base max-md:text-sm">
              {t(content)}
            </p>
          </div>
        </div>
      ))}
    </section>
  );
}
