import { CheckIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import { SetStateAction, useCallback, useEffect, useState } from 'react';
import { advertiseMethodArray, agreementArray } from '../../array/signup';
// import { RegisterInfoType } from '../../views/signup';
import { t } from 'i18next';
import { RegisterInfoType } from '../../store';
import CheckBox from '../vector/common/CheckBox';
import CheckedBox from '../vector/common/CheckedBox';

export default function SignupAgreement({
  setRegisterInfo,
  registerInfo,
  setPolicyModal,
  userType,
}: {
  setRegisterInfo: React.Dispatch<SetStateAction<RegisterInfoType>>;
  registerInfo: RegisterInfoType;
  setPolicyModal: React.Dispatch<
    SetStateAction<{ isOpen: boolean; termsCode: 'M01E0' | 'M01F0' | 'M01G0' }>
  >;
  userType?: string;
}) {
  const [smsMethod, setSmsMethod] = useState(false);
  const [emailMethod, setEmailMethod] = useState(false);
  const [checkedBox, setCheckedBox] = useState<string[]>([]);

  const onCheckedAll = useCallback(
    (checked: boolean) => {
      if (checked) {
        const checkedBoxArr: string[] = [];
        agreementArray.forEach((box) => checkedBoxArr.push(box.value));
        setCheckedBox(checkedBoxArr);
      } else {
        setCheckedBox([]);
      }
    },
    [agreementArray]
  );
  const onCheckedSingle = useCallback(
    (
      checked: boolean,
      box:
        | 'integrated service'
        | 'personal information'
        | 'advertising information'
    ) => {
      checked
        ? setCheckedBox((prev) => [...prev, box])
        : setCheckedBox(checkedBox.filter((el) => el !== box));
    },
    [checkedBox]
  );

  useEffect(() => {
    const tmpCheckedbox = Array.from(checkedBox);
    if (!smsMethod && !emailMethod) {
      for (let i = 0; i < tmpCheckedbox.length; i++) {
        if (tmpCheckedbox[i] === 'advertising information') {
          tmpCheckedbox.splice(i, 1);
        }
      }
      setCheckedBox(tmpCheckedbox);
    }
  }, [smsMethod, emailMethod]);

  useEffect(() => {
    if (registerInfo.agreement.includes('advertising information')) {
      setSmsMethod(true);
      setEmailMethod(true);
    }
  }, [registerInfo.agreement]);

  useEffect(() => {
    setRegisterInfo((prev) => ({
      ...prev,
      agreement: checkedBox,
      advertiseMethod: {
        ...prev.advertiseMethod,
        sms: smsMethod,
        email: emailMethod,
      },
    }));
  }, [smsMethod, emailMethod, checkedBox]);

  useEffect(() => {
    if (registerInfo.agreement.includes('advertising information')) {
      return;
    } else {
      setSmsMethod(false);
      setEmailMethod(false);
    }
  }, [registerInfo.agreement]);

  useEffect(() => {
    setCheckedBox([]);
    setSmsMethod(false);
    setEmailMethod(false);
  }, [userType]);

  return (
    <section className="space-y-4 max-md:text-base max-2xl:text-base text-lg">
      <div className="flex items-center mb-6">
        <input
          onChange={() => onCheckedAll(checkedBox.length === 3 ? false : true)}
          id="agree-all"
          type="checkbox"
          className="hidden"
        />
        <label
          htmlFor="agree-all"
          className="flex items-center space-x-2 cursor-pointer"
        >
          {checkedBox.length === 0 ? (
            <CheckBox />
          ) : agreementArray.length === registerInfo.agreement.length ? (
            <CheckedBox />
          ) : (
            <CheckBox />
          )}
          <strong>{t('약관에 모두 동의합니다.')}</strong>
        </label>
      </div>

      {agreementArray.map(({ agreement, value, termsCode, required }, idx) => (
        <div
          key={`agreement-${idx}`}
          className="flex items-center justify-between w-full"
        >
          <div className="flex">
            <input
              id={value}
              onChange={() =>
                onCheckedSingle(
                  checkedBox.includes(value) ? false : true,
                  value
                )
              }
              type="checkbox"
              className="hidden"
            />
            <label
              htmlFor={value}
              className="flex items-center space-x-2 cursor-pointer"
            >
              {checkedBox.includes(value) ? <CheckedBox /> : <CheckBox />}
              <span
                className={`${!required && 'text-textGray'} ${
                  registerInfo.agreement.includes(value) && '!text-black'
                } `}
              >
                {t(required ? '[필수]' : '[선택]')} {t(agreement)}
              </span>
            </label>
          </div>
          <button
            onClick={() =>
              setPolicyModal({ isOpen: true, termsCode: termsCode })
            }
            className="w-5 h-5"
          >
            <ChevronRightIcon />
          </button>
        </div>
      ))}

      {registerInfo.agreement.includes('advertising information') && (
        <div className="flex items-center space-x-8 !text-[#bbbbbb]">
          {advertiseMethodArray.map(({ text }, idx) => (
            <button
              key={`method-${idx}`}
              onClick={() => {
                if (idx === 0) {
                  setSmsMethod(!smsMethod);
                } else {
                  setEmailMethod(!emailMethod);
                }
              }}
              className={`flex items-center space-x-3 ${
                idx === 0 && smsMethod ? 'text-[#FF7B51]' : ''
              } ${idx === 1 && emailMethod ? 'text-[#FF7B51]' : ''}`}
            >
              <CheckIcon className="h-5 w-5" aria-hidden="true" />
              <span>{t(text)}</span>
            </button>
          ))}
        </div>
      )}
    </section>
  );
}
