import { Dialog, Transition } from '@headlessui/react';
import { t } from 'i18next';
import moment from 'moment';
import { Fragment, SetStateAction, useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { getPaymentDetail } from '../../../services/PrivateService';
import { PaymentsListClientDataType } from '../../../services/TypeOfPrivateService';
import { siteLangCd } from '../../../services/defaultClient';
import { userinfoAtom } from '../../../store';

export default function PaymentDetailModal({
  modal,
  closeModal,
  item,
}: {
  modal: boolean;
  closeModal: React.Dispatch<SetStateAction<boolean>>;
  item: PaymentsListClientDataType;
}) {
  const [detailData, setDetailData] = useState({
    billStatus: '',
    credit: 0,
    creditFree: 0,
    itemName: '',
    payCompanyName: '',
    payDtFrom: '',
    payMethod: '',
    payName: '',
    pkPgPayLog: 0,
    solutionType: '',
    subscYn: '',
    sumCost: null,
  });
  const { language } = useRecoilValue(userinfoAtom);

  const getTitle = (item: PaymentsListClientDataType) => {
    let phrase;
    ['B20131', 'B20115'].includes(item.billStatus)
      ? (phrase = '취소상세정보')
      : (phrase = '결제상세정보');
    return t(phrase);
  };

  useEffect(() => {
    getPaymentDetail({ siteLangCd, returnType: 0 }, String(item.pkPgPayLog))
      .then((res) => setDetailData(res.data.data))
      .catch(console.log);
  }, [item]);

  return (
    <>
      <Transition appear show={modal} as={Fragment}>
        <Dialog as="div" className="fixed z-50" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95 max-md:translate-y-80"
                enterTo="opacity-100 scale-100 max-md:translate-y-0"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100 max-md:translate-y-0"
                leaveTo="opacity-0 scale-95 max-md:translate-y-80"
              >
                <Dialog.Panel className="w-full max-md:fixed max-md:bottom-0 max-md:left-0 pb-10 max-w-[496px] transform overflow-hidden rounded-2xl max-md:rounded-b-none bg-white transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-xl max-md:text-lg text-black2 py-5 mb-7 font-bold text-center border-b border-borderGray w-full"
                  >
                    {getTitle(item)}
                  </Dialog.Title>
                  <div className="px-7 max-md:px-5">
                    {item.billStatus === 'B20131' ||
                    item.billStatus === 'B20115' ? (
                      <CancelSection language={language} item={item} />
                    ) : (
                      <SuccessSection language={language} item={item} />
                    )}
                    <button
                      type="button"
                      className="w-full mt-8 py-4 rounded-lg bg-gradient-to-br from-primary to-primaryTo hover:bg-[#ff8063e5] hover:from-hoverPrimary hover:to-hoverPrimaryTo text-white"
                      onClick={() => closeModal(false)}
                    >
                      {t('확인')}
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}

function SuccessSection({
  item,
  language,
}: {
  item: PaymentsListClientDataType;
  language: string;
}) {
  const openReceiptWindow = (
    tranCd: string | undefined,
    orderCd: string | undefined,
    sumCost: number
  ) => {
    if (!tranCd || !orderCd) {
      return;
    }
    const paymentUrl = `https://admin8.kcp.co.kr/assist/bill.BillActionNew.do?cmd=card_bill&tno=${tranCd}&order_no=${orderCd}&trade_mony=${sumCost}`;
    window.open(paymentUrl, '전자영수증', 'width=780px,height=740px');
  };

  return (
    <>
      <div className="flex items-center justify-between pb-5">
        {language === 'kr' ? (
          <h1 className="font-bold text-2xl max-md:text-xl">
            {item.sumCost?.toLocaleString()}원
          </h1>
        ) : (
          <h1 className="font-bold text-2xl max-md:text-xl">
            KRW {item.sumCost?.toLocaleString()}
          </h1>
        )}
        {item.itemName !== '엔트리' ? (
          <button
            onClick={() =>
              openReceiptWindow(item.tranCd, item.orderCd, item.sumCost)
            }
            className="py-[8px] px-[10px] text-base max-md:text-sm border border-black rounded-lg hover:bg-gray5"
          >
            {t('전자영수증')}
          </button>
        ) : null}
      </div>
      <div className="flex items-center justify-between">
        <span>{t('요금제명')}</span>
        <span className="text-lg max-md:text-base">{t(item.itemName)}</span>
      </div>
      <div className="flex items-center justify-between mt-4">
        <span>{t('요금제 금액')}</span>
        {language === 'kr' ? (
          <span className="text-lg max-md:text-base">
            {item.sumCost?.toLocaleString()}원
          </span>
        ) : (
          <span className="text-lg max-md:text-base">
            KRW {item.sumCost?.toLocaleString()}
          </span>
        )}
      </div>
      <div className="flex items-center justify-between mt-4">
        <span>{t('결제일시')}</span>
        <span className="text-lg max-md:text-base">
          {moment(item.payDtFrom).format('YYYY. MM. DD HH:mm')}
        </span>
      </div>
      {item.payMethod != null && (
        <div className="flex items-center justify-between mt-4">
          <span>{t('결제수단')}</span>
          <span className="text-lg max-md:text-base">
            {item.payMethod === 'CARD' ? t('신용카드') : t('간편결제')}
          </span>
        </div>
      )}
    </>
  );
}
function CancelSection({
  item,
  language,
}: {
  item: PaymentsListClientDataType;
  language: string;
}) {
  const getUsedCredit = (item: any) => {
    if (!item) {
      return;
    }
    let usedCredit;
    item.subscYn === 'Y'
      ? (usedCredit = item.creditPolicy - item.credit)
      : (usedCredit = item.creditPolicyFree - item.creditFree);
    return usedCredit;
  };

  return (
    <>
      <div className="flex items-center justify-between font-bold">
        <span className="text-lg max-md:text-base">{t('최초 결제 금액')}</span>
        {language === 'kr' ? (
          <span className="text-2xl max-md:text-xl">
            {item.sumCost?.toLocaleString()}원
          </span>
        ) : (
          <span className="text-2xl max-md:text-xl">
            KRW {item.sumCost?.toLocaleString()}
          </span>
        )}
      </div>
      <div className="flex items-center justify-between max-md:text-sm mt-4 pb-5 border-b border-gray3">
        <span className="max-md:text-base">{t('결제수단')}</span>
        <span className="text-lg max-md:text-base">
          {' '}
          {item.payMethod === 'CARD' ? t('신용카드') : t('간편결제')}
        </span>
      </div>
      <div className="flex items-center justify-between max-md:text-sm mt-4">
        <span>{t('요금제명')}</span>
        <span className="text-lg max-md:text-base">{t(item.itemName)}</span>
      </div>
      <div className="flex items-center justify-between max-md:text-sm mt-4">
        <span>{t('결제 크레딧')}</span>
        <span className="text-lg max-md:text-base">
          {item.subscYn === 'Y'
            ? item.credit?.toLocaleString()
            : item.creditPolicy?.toLocaleString()}
        </span>
      </div>
      <div className="flex items-center justify-between max-md:text-sm mt-4 pb-5 border-b border-gray3">
        <span>{t('사용 크레딧')}</span>
        <span className="text-lg max-md:text-base">
          {getUsedCredit(item)?.toLocaleString()}
        </span>
      </div>
      <div className="flex items-center justify-between max-md:text-sm font-bold mt-4">
        <span className="text-lg max-md:text-base">
          {item.billStatus === '취소완료'
            ? t('환불 금액')
            : t('환불 예정 금액')}
        </span>
        {language === 'kr' ? (
          <span className="text-2xl max-md:text-xl">
            {item.paybackCost?.toLocaleString()}원
          </span>
        ) : (
          <span className="text-2xl max-md:text-xl">
            KRW {item.paybackCost?.toLocaleString()}
          </span>
        )}
      </div>
      <div className="flex justify-end text-sm mt-1">{t('부가세 포함')}</div>
    </>
  );
}
