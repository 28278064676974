export default function MobileLeftArrow() {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.14645 9.64453L9.64645 1.14453L10.3536 1.85164L2.70711 9.49809H19.832V10.4981H2.70711L10.3536 18.1445L9.64645 18.8516L1.14645 10.3516C0.951185 10.1564 0.951185 9.83979 1.14645 9.64453Z"
        fill="black"
      />
      <path
        d="M9.64645 1.14453L9.82322 0.967755C9.72559 0.870123 9.5673 0.870124 9.46967 0.967755L9.64645 1.14453ZM1.14645 9.64453L0.96967 9.46775L0.969669 9.46776L1.14645 9.64453ZM10.3536 1.85164L10.5303 2.02842C10.5772 1.98153 10.6036 1.91794 10.6036 1.85164C10.6036 1.78533 10.5772 1.72175 10.5303 1.67486L10.3536 1.85164ZM2.70711 9.49809L2.53033 9.32131C2.45883 9.39281 2.43744 9.50034 2.47614 9.59376C2.51483 9.68717 2.60599 9.74809 2.70711 9.74809V9.49809ZM19.832 9.49809H20.082C20.082 9.36001 19.9701 9.24809 19.832 9.24809V9.49809ZM19.832 10.4981V10.7481C19.9701 10.7481 20.082 10.6362 20.082 10.4981H19.832ZM2.70711 10.4981V10.2481C2.60599 10.2481 2.51483 10.309 2.47614 10.4024C2.43744 10.4958 2.45883 10.6034 2.53033 10.6749L2.70711 10.4981ZM10.3536 18.1445L10.5303 18.3213C10.628 18.2237 10.628 18.0654 10.5303 17.9678L10.3536 18.1445ZM9.64645 18.8516L9.46967 19.0284C9.5673 19.126 9.72559 19.126 9.82322 19.0284L9.64645 18.8516ZM1.14645 10.3516L0.969669 10.5284L0.96967 10.5284L1.14645 10.3516ZM9.46967 0.967755L0.96967 9.46775L1.32322 9.82131L9.82322 1.32131L9.46967 0.967755ZM10.5303 1.67486L9.82322 0.967755L9.46967 1.32131L10.1768 2.02842L10.5303 1.67486ZM2.88388 9.67486L10.5303 2.02842L10.1768 1.67486L2.53033 9.32131L2.88388 9.67486ZM19.832 9.24809H2.70711V9.74809H19.832V9.24809ZM20.082 10.4981V9.49809H19.582V10.4981H20.082ZM2.70711 10.7481H19.832V10.2481H2.70711V10.7481ZM10.5303 17.9678L2.88388 10.3213L2.53033 10.6749L10.1768 18.3213L10.5303 17.9678ZM9.82322 19.0284L10.5303 18.3213L10.1768 17.9678L9.46967 18.6749L9.82322 19.0284ZM0.96967 10.5284L9.46967 19.0284L9.82322 18.6749L1.32322 10.1749L0.96967 10.5284ZM0.969669 9.46776C0.676777 9.76065 0.676777 10.2355 0.969669 10.5284L1.32322 10.1749C1.22559 10.0772 1.22559 9.91894 1.32322 9.82131L0.969669 9.46776Z"
        fill="black"
      />
    </svg>
  );
}
