import { Dialog, Transition } from '@headlessui/react';
import { t } from 'i18next';
import { Fragment, SetStateAction, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { reqChangePassword } from '../../../services/PrivateService';
import { siteLangCd } from '../../../services/defaultClient';
import { userinfoAtom } from '../../../store';
import Loader from '../../common/Loader';
import InformModal from '../../common/modal/InformModal';
import useValidate, { passwordRegex } from '../../hooks/useValidate';

interface PasswordType {
  currentPassword: string;
  changingPassword: string;
  confirmPassword: string;
}

const inputStyle = 'py-3 px-4 w-full border-borderLightGray border rounded-lg';

export default function PasswordModal({
  modal,
  closeModal,
}: {
  modal: boolean;
  closeModal: React.Dispatch<SetStateAction<boolean>>;
}) {
  const [loading, setLoading] = useState(false);
  const [onChangeInfo, setOnChangeInfo] = useState<PasswordType>({
    currentPassword: '',
    changingPassword: '',
    confirmPassword: '',
  });
  const [informModal, setInformModal] = useState({
    isOpen: false,
    phrase: '',
  });
  const { language } = useRecoilValue(userinfoAtom);

  function useValidateEn(password: string) {
    if (!password) {
      return;
    }
    let phrase;
    !passwordRegex.test(password) &&
      (phrase = 'Please combine English, numbers, and special characters.');
    password.length < 8 &&
      (phrase = 'Please enter a password at least 8 characters long.');
    return phrase;
  }

  const changePassword = () => {
    setLoading(true);
    reqChangePassword({
      siteLangCd,
      memberPwd: onChangeInfo.currentPassword,
      newMemberPwd: onChangeInfo.changingPassword,
    })
      .then((res) => {
        if (res.data.resultCd === '0000') {
          setInformModal({
            isOpen: true,
            phrase: '비밀번호가 정상적으로 변경되었습니다.',
          });
        } else {
          switch (res.data.data) {
            case 'password not match':
              setInformModal({
                isOpen: true,
                phrase: '현재 비밀번호를 다시 확인해주세요.',
              });
              break;
            case 'password not changed':
              setInformModal({
                isOpen: true,
                phrase:
                  '현재 비밀번호와 동일한 비밀번호는 사용하실 수 없습니다.',
              });
              break;
          }
        }
      })
      .catch((err) => alert(err))
      .finally(() => {
        setOnChangeInfo({
          currentPassword: '',
          changingPassword: '',
          confirmPassword: '',
        });
        setLoading(false);
      });
  };

  const checkEligibility = () => {
    return !passwordRegex.test(onChangeInfo.changingPassword) ||
      onChangeInfo.changingPassword !== onChangeInfo.confirmPassword
      ? true
      : false;
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (
      !passwordRegex.test(onChangeInfo.changingPassword) ||
      onChangeInfo.changingPassword !== onChangeInfo.confirmPassword
    ) {
      return;
    }
    e.key === 'Enter' && changePassword();
  };

  return (
    <>
      <Loader loading={loading} />
      <InformModal
        modal={informModal.isOpen}
        phrase={informModal.phrase}
        closeModal={() => {
          setInformModal((prev) => ({
            ...prev,
            isOpen: false,
          }));
          if (informModal.phrase === '비밀번호가 정상적으로 변경되었습니다.') {
            closeModal(false);
          }
        }}
      />
      <Transition appear show={modal} as={Fragment}>
        <Dialog as="div" className="fixed z-50" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 max-md:bottom-0 max-md:inset-auto max-md:w-full">
            <div className="flex min-h-full items-center justify-center p-4 max-md:p-0 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95 max-md:translate-y-80 max-md:scale-100"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-300"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95 max-md:translate-y-80 max-md:scale-100"
              >
                <Dialog.Panel className="w-full pb-10 max-md:pb-0 max-w-[500px] max-md:max-w-full transform overflow-hidden rounded-2xl max-md:rounded-b-none bg-white transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-xl py-5 mb-7 font-bold leading-6 text-gray-900 text-center border-b border-borderGray w-full"
                  >
                    {t('비밀번호 변경')}
                  </Dialog.Title>
                  <div className="px-7 max-md:px-5 space-y-5 text-left text-lg max-md:text-base">
                    <div className="space-y-3 max-md:space-y-2 relative">
                      <h1 className="font-medium">{t('현재 비밀번호')}</h1>
                      <input
                        autoComplete="off"
                        onKeyDown={(e) => handleKeyPress(e)}
                        onChange={(e) => {
                          setOnChangeInfo((prev) => ({
                            ...prev,
                            currentPassword: e.target.value,
                          }));
                        }}
                        value={onChangeInfo.currentPassword}
                        className="py-3 px-4 w-full rounded-lg border border-borderLightGray"
                        type="password"
                        placeholder={t('현재 비밀번호를 입력해주세요')}
                      />
                    </div>
                    <div className="space-y-3 max-md:space-y-2">
                      <h1 className="font-medium">{t('새 비밀번호')}</h1>
                      <input
                        autoComplete="off"
                        onKeyDown={(e) => handleKeyPress(e)}
                        onChange={(e) => {
                          setOnChangeInfo((prev) => ({
                            ...prev,
                            changingPassword: e.target.value,
                          }));
                        }}
                        maxLength={16}
                        value={onChangeInfo.changingPassword}
                        className={`${inputStyle} ${
                          passwordRegex.test(onChangeInfo.changingPassword)
                            ? 'border-success'
                            : useValidate(onChangeInfo.changingPassword)
                            ? 'border-red-400'
                            : ''
                        }`}
                        type="password"
                        placeholder={t('새로운 비밀번호를 입력해주세요')}
                      />
                      <div className="flex relative">
                        <span className="text-sm max-2xl:text-xs text-black5">
                          {t(
                            '영문, 숫자, 특수문자(!, @, $, ^, *, -, _)를 혼합하여 8~16자로 사용'
                          )}
                        </span>
                        {onChangeInfo.changingPassword && (
                          <span className="text-error max-2xl:text-xs text-sm absolute -bottom-5">
                            {language === 'kr'
                              ? useValidate(onChangeInfo.changingPassword)
                              : useValidateEn(onChangeInfo.changingPassword)}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="space-y-3 max-md:space-y-2 relative">
                      <h1 className="font-medium">{t('새 비밀번호 확인')}</h1>
                      <input
                        autoComplete="off"
                        onKeyDown={(e) => handleKeyPress(e)}
                        onChange={(e) => {
                          setOnChangeInfo((prev) => ({
                            ...prev,
                            confirmPassword: e.target.value,
                          }));
                        }}
                        maxLength={16}
                        value={onChangeInfo.confirmPassword}
                        className={`${inputStyle} ${
                          onChangeInfo.changingPassword !==
                            onChangeInfo.confirmPassword &&
                          onChangeInfo.confirmPassword.length
                            ? 'border-error'
                            : onChangeInfo.changingPassword ===
                                onChangeInfo.confirmPassword &&
                              onChangeInfo.confirmPassword.length
                            ? 'border-success'
                            : ''
                        }`}
                        type="password"
                        placeholder={t(
                          '새로운 비밀번호를 한 번 더 입력해주세요'
                        )}
                      />
                      {onChangeInfo.confirmPassword && (
                        <span
                          className={`${
                            onChangeInfo.changingPassword ===
                            onChangeInfo.confirmPassword
                              ? 'text-green-500'
                              : 'text-rose-400'
                          } max-2xl:text-xs text-sm`}
                        >
                          {!(
                            onChangeInfo.changingPassword ===
                            onChangeInfo.confirmPassword
                          )
                            ? t('비밀번호를 다시 확인해주세요.')
                            : t('비밀번호가 일치합니다.')}
                        </span>
                      )}
                    </div>
                    <div className="flex space-x-3 max-md:space-x-2 font-bold max-md:pb-7">
                      <button
                        type="button"
                        className="w-full py-4 rounded-lg border border-[#cccccc] hover:bg-gray5"
                        onClick={() => {
                          setOnChangeInfo({
                            currentPassword: '',
                            changingPassword: '',
                            confirmPassword: '',
                          });
                          closeModal(false);
                        }}
                      >
                        {t('취소')}
                      </button>
                      <button
                        type="button"
                        disabled={checkEligibility()}
                        className={`${
                          checkEligibility()
                            ? 'bg-gray2'
                            : 'bg-gradient-to-br from-primary to-primaryTo hover:from-hoverPrimary hover:to-hoverPrimaryTo'
                        } w-full py-4 rounded-lg text-white`}
                        onClick={changePassword}
                      >
                        {t('비밀번호 변경하기')}
                      </button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
