import { useTranslation } from 'react-i18next';
import SubPageDot from '../common/SubpageDot';

type Props = {
  isEnglish: boolean;
};

export default function SonFifthSection({ isEnglish }: Props) {
  const { t } = useTranslation();
  return (
    <section className="space-y-14 max-md:space-y-5 mt-14 flex max-lg:flex-col items-center justify-between">
      <div className="space-y-[18px] max-md:pr-5 w-full">
        <div className="text-white flex max-md:flex-col max-md:space-y-1 font-extralight">
          <SubPageDot />
          <h1 className="text-2xl md:text-4xl 2xl:text-5xl md:leading-[50px] 2xl:leading-[68px]">
            {t('직접 연락하기 어려울 때,')}
            <br />
            <strong className="font-bold">{t('손비서가 대신 답장')}</strong>
          </h1>
        </div>
        <p className="text-white/60 text-base lg:text-lg 2xl:text-xl font-extralight md:ml-[30px] md:pr-[30px] w-full lg:max-w-lg 2xl:max-w-[613px]">
          {t(
            '고객이 남긴 메모를 확인하고 바쁠 때나 연락이 어려울 때 손비서를 통해 문자나 전화로 즉시 답장 요청이 가능합니다.'
          )}
        </p>
      </div>
      <div className="relative max-lg:!mt-10">
        <img
          className="max-lg:w-full max-w-lg lg:max-w-xl 2xl:max-w-[700px]"
          src={isEnglish ? '/son/son-reply-en.png' : '/son/son-reply.png'}
          alt="reply"
        />
      </div>
    </section>
  );
}
