export default function MinusIcon() {
  return (
    <svg
      width="41"
      height="40"
      viewBox="0 0 41 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28.2832 20H20.2832L12.2832 20"
        stroke="#FF6D51"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
}
