import { useEffect, useState } from 'react';

import { t } from 'i18next';
import {
  creditUseStatusArray,
  monthFilterArray,
  serviceFilterArray,
} from '../../../array/SearchFilterArray';
import MyPageLayout from '../../../components/common/MyPageLayout';
import Skeleton from '../../../components/common/MypageCardSkeleton';
import Pagination from '../../../components/common/Pagination';
import SearchFilter from '../../../components/common/SearchFilter';
import MobileCreditCard from '../../../components/my-page/credits/MobileCreditCard';
import PcCreditCard from '../../../components/my-page/credits/PcCreditCard';
import { getCreditsListApi } from '../../../services/PrivateService';
import {
  CreditsListClientDataType,
  UnionListClientReqType,
} from '../../../services/TypeOfPrivateService';
import { siteLangCd } from '../../../services/defaultClient';
import { getParentPage } from '../../../utils/common';

const perPage = 10;

const filterOptions = [
  serviceFilterArray,
  creditUseStatusArray,
  monthFilterArray,
];

export default function CreditDetails() {
  const [loading, setLoading] = useState(true);
  const [creditList, setCreditList] = useState<CreditsListClientDataType[]>([]);
  const [pageNo, setPageNo] = useState(1);
  const [total, setTotal] = useState(0);
  const [searchFilter, setSearchFilter] = useState({
    creditStatusCd: '',
    monthType: '3',
    serviceCd: '',
  });

  useEffect(() => {
    setLoading(true);
    const reqBody: UnionListClientReqType = {
      siteLangCd,
      serviceCd: searchFilter.serviceCd,
      creditStatusCd: searchFilter.creditStatusCd,
      monthType: searchFilter.monthType,
      pageNo,
      perPage,
    };
    getCreditsListApi(reqBody)
      .then((res) => {
        if (res.data.statusCd === 'OK') {
          setTotal(res.data.data.totalRows);
          setCreditList(res.data.data?.pageList);
        }
      })
      .catch(console.log)
      .finally(() => setLoading(false));
  }, [pageNo, searchFilter]);

  const onPaging = (page: number) => {
    window.scrollTo(0, 0);
    setPageNo(Number(page));
  };

  return (
    <MyPageLayout>
      <div className="w-full">
        <div className="flex max-lg:flex-col max-lg:space-y-3 justify-between max-md:mb-6 max-md:w-full">
          <h1 className="font-bold text-4xl max-2xl:text-3xl max-md:text-2xl">
            {t('크레딧 내역')}
          </h1>
          <SearchFilter
            setFilter={setSearchFilter}
            filterOptions={filterOptions}
          />
        </div>

        <section className="space-y-3">
          {loading ? (
            <Skeleton />
          ) : (
            <>
              <MobileCreditCard creditList={creditList} />
              <PcCreditCard creditList={creditList} />
            </>
          )}
        </section>
        <div className="mt-[60px]">
          {creditList.length ? (
            <Pagination
              onPaging={onPaging}
              total={total}
              current={pageNo}
              textColor="text-black group-hover:text-white"
              strokeColor="black"
              parentPage={getParentPage()}
              posts_per_page={perPage}
              max={total >= perPage ? Math.floor(total / perPage) + 1 : 1}
            />
          ) : null}
        </div>
      </div>
    </MyPageLayout>
  );
}
