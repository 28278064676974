import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { userinfoAtom } from '../../store';

export default function CeoSection() {
  const { t } = useTranslation();
  const { language } = useRecoilValue(userinfoAtom);
  return (
    <section
      data-aos="fade-up"
      className="bg-[#F5F5F5] rounded-[40px] p-20 max-2xl:p-16 max-md:py-7 max-md:px-6 relative max-md:pb-36"
    >
      <h1 className="font-bold text-[40px] max-2xl:text-4xl max-md:text-xl">
        {t('매년 20% 성장의 저력을 바탕으로')} <br />{' '}
        {t('다가올 20년을 준비하겠습니다.')}
      </h1>
      <div className="w-[60%] max-md:w-full mt-8 max-md:mt-5 mb-10 max-md:mb-40 max-md:space-y-3">
        <p className="leading-7 max-md:leading-5 text-black4 max-md:text-sm">
          {t(
            '인공지능 및 빅데이터 분야에서 독보적인 경쟁력을 갖추고 지난 20년 이상 축척해 온 솔트룩스의 기술을 기반으로 2021년 설립된 플루닛은, 생성형 인공지능을 기반으로 고해상 영상 생성 방송 플랫폼인 스튜디오와 옴니채널 기반의 고객 커뮤니케이션을 제공하는 워크센터, 그리고 2023년 하반기 출시를 앞두고 있는 개인 비서앱 서비스까지 새롭게 다가올 미래를 준비하고 있습니다.'
          )}
        </p>
        <p className="leading-7 max-md:leading-5 text-black4 max-md:text-sm">
          {t(
            '숨가쁘게 빨리 변화하는 시대에도 인간의 물리적 한계를 넘어 생활을 풍요롭고 편리하게 하는 기업, 사람을 이롭게 하되 침해하지 않는 자유로 인공지능의 진정한 가치를 지키기 위해 플루닛은 바람직한 변화를 선도하여 우리 고객과 사회, 인류사회의 지속가능한 발전에 기여하고자 합니다.'
          )}
        </p>
        <p className="leading-7 max-md:leading-5 text-black4 max-md:text-sm">
          {t(
            '플루닛의 도전적인 행보와 끊임없는 노력을 지켜봐 주시고 아낌없는 조언을 부탁드립니다.'
          )}
        </p>
      </div>
      <div className="flex max-md:flex-col max-md:items-start max-md:absolute max-md:bottom-40 items-center">
        <span className="font-bold">
          {t('플루닛 대표이사')}
          <strong
            className={`font-bold ${
              language === 'kr' && 'tracking-widest'
            } ml-4 max-md:block max-md:ml-0`}
          >
            {t('이경일')}
          </strong>
        </span>
        <i className="ml-4 max-md:ml-0 w-[166px] max-md:w-[118px] max-md:mt-3">
          <img src="/signature.png" alt="ceo signature" />
        </i>
      </div>
      <div className="absolute right-0 bottom-0 w-1/3 max-md:w-56">
        <img src="/tony.png" alt="ceo image" />
      </div>
    </section>
  );
}
