export default function MusicFlowIcon() {
  return (
    <svg
      className="w-4 h-4"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1240_89302)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 1.25C12.4142 1.25 12.75 1.58579 12.75 2V22C12.75 22.4142 12.4142 22.75 12 22.75C11.5858 22.75 11.25 22.4142 11.25 22V2C11.25 1.58579 11.5858 1.25 12 1.25Z"
          fill="#FF6D51"
          stroke="#FF6D51"
          strokeLinecap="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17 5.25C17.4142 5.25 17.75 5.58579 17.75 6V18C17.75 18.4142 17.4142 18.75 17 18.75C16.5858 18.75 16.25 18.4142 16.25 18V6C16.25 5.58579 16.5858 5.25 17 5.25Z"
          fill="#FF6D51"
          stroke="#FF6D51"
          strokeLinecap="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2 8.25C2.41421 8.25 2.75 8.58579 2.75 9V15C2.75 15.4142 2.41421 15.75 2 15.75C1.58579 15.75 1.25 15.4142 1.25 15V9C1.25 8.58579 1.58579 8.25 2 8.25Z"
          fill="#FF6D51"
          stroke="#FF6D51"
          strokeLinecap="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22 8.25C22.4142 8.25 22.75 8.58579 22.75 9V15C22.75 15.4142 22.4142 15.75 22 15.75C21.5858 15.75 21.25 15.4142 21.25 15V9C21.25 8.58579 21.5858 8.25 22 8.25Z"
          fill="#FF6D51"
          stroke="#FF6D51"
          strokeLinecap="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7 5.25C7.41421 5.25 7.75 5.58579 7.75 6V18C7.75 18.4142 7.41421 18.75 7 18.75C6.58579 18.75 6.25 18.4142 6.25 18V6C6.25 5.58579 6.58579 5.25 7 5.25Z"
          fill="#FF6D51"
          stroke="#FF6D51"
          strokeLinecap="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1240_89302">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
