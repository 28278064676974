import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import HomeButton from '../../components/common/HomeButtons';
import Loader from '../../components/common/Loader';
import InformModal from '../../components/common/modal/InformModal';
import InquiryTypeSelectBox from '../../components/home/InquiryTypeSelectBox';
import PolicyModal from '../../components/signup/PolicyModal';
import HomeCheckIcon from '../../components/vector/home/HomeCheckIcon';
import { addPartnershipRequest } from '../../services/PublicService';
import { siteLangCd } from '../../services/defaultClient';
import { PartnershipAddReqDtoInf } from '../../services/dto/PartnershipDtoInf';
import { userinfoAtom } from '../../store';
import { emailRegex, numberException } from '../hooks/useValidate';

const korean = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/;

const formDataInitialState: PartnershipAddReqDtoInf = {
  siteLangCd,
  inquirerFnm: '',
  inquirerEmail: '',
  inquirerTelno: '',
  inquiryTit: '',
  inquiryCtnt: '',
  privacyAgreeYn: 'Y',
  privacyAgreeDt: '',
  inquiryCompanyNm: 'company name',
};

const inputStyle =
  'no-round min-w-[500px] max-md:min-w-fit w-full pb-4 max-md:py-3 max-md:mt-3 text-xl max-md:text-base text-white bg-transparent border-white/20 border-0 border-b focus:ring-transparent focus:outline-none focus:border-white focus:border-opacity-[0.6] placeholder:text-white/30 duration-300';

export default function ContactSection() {
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState({ isOpen: false, phrase: '' });
  const [isAgreed, setIsAgreed] = useState(false);
  const [policyModal, setPolicyModal] = useState(false);
  const [informPhrase, setInformPhrase] = useState(false);
  const [formData, setFormData] =
    useState<PartnershipAddReqDtoInf>(formDataInitialState);
  const { language } = useRecoilValue(userinfoAtom);
  const { t } = useTranslation();

  useEffect(() => {
    setFormData((prev) => ({
      ...prev,
      privacyAgreeDt: moment().format('YYYY-MM-DD HH:mm:ss'),
    }));
  }, [isAgreed]);

  const sendInquiryForm = async () => {
    if (!isAgreed) {
      setModal({ isOpen: true, phrase: '개인정보처리방침에 동의해주세요.' });
      return;
    }
    if (!emailRegex.test(formData.inquirerEmail)) {
      setModal({
        isOpen: true,
        phrase: '이메일 형식을 확인해주세요.',
      });
      return;
    }
    setLoading(true);
    addPartnershipRequest(formData)
      .then(() => {
        setModal({ isOpen: true, phrase: '문의 접수가 완료되었습니다.' });
        setFormData(formDataInitialState);
        setIsAgreed(false);
      })
      .catch(() =>
        setModal({
          isOpen: true,
          phrase: '입력하신 정보를 다시 한 번 확인해주세요.',
        })
      )
      .finally(() => setLoading(false));
  };

  return (
    <>
      <Loader loading={loading} />
      <InformModal
        modal={modal.isOpen}
        closeModal={() => setModal((prev) => ({ ...prev, isOpen: false }))}
        phrase={modal.phrase}
      />
      <PolicyModal
        termsCode="M01F0"
        policyModal={policyModal}
        closeModal={() => setPolicyModal(false)}
      />
      <section className="w-screen z-10 space-y-20 pt-52 pb-24 max-md:pb-20 max-md:px-5 max-md:pt-[120px] max-md:space-y-6 max-md:overflow-y-hidden bg-[#111111]">
        <div className="text-white flex justify-center text-center items-end w-full max-md:text-start">
          <div className="flex flex-col space-y-5 max-md:space-y-4">
            <h1 className="font-bold text-7xl max-md:text-[32px] max-md:leading-10">
              Ready to <br className="lg:hidden" /> Work Together?
            </h1>
            <h3 className="text-2xl text-white/60 max-md:text-base font-extralight md:max-w-[1000px]">
              {t(
                '플루닛의 기술과 서비스, 제휴 관련 궁금한 사항이 있으시다면 아래 문의 내용을 기재하고 Contact Us를 클릭해주세요.'
              )}
            </h3>
          </div>
        </div>
        <section className="flex flex-col mx-auto space-y-16 max-md:space-y-3 max-w-[1600px] max-2xl:max-w-[1200px]">
          <div className="space-y-10 w-full max-md:space-y-0">
            <div className="flex space-x-10 max-md:flex-col max-md:space-x-0">
              <input
                onChange={(e) =>
                  setFormData((prev) => ({
                    ...prev,
                    inquirerFnm: e.target.value,
                  }))
                }
                maxLength={20}
                value={formData.inquirerFnm}
                className={inputStyle}
                type="text"
                placeholder={t('이름 *')}
              />
              <input
                onChange={(e) =>
                  setFormData((prev) => ({
                    ...prev,
                    inquirerTelno: e.target.value,
                  }))
                }
                onKeyDown={(e: any) => {
                  formData.inquirerTelno.length > 14 &&
                    e.key !== 'Backspace' &&
                    e.key !== 'Tab' &&
                    e.preventDefault();
                  numberException.includes(e.key) && e.preventDefault();
                }}
                value={formData.inquirerTelno}
                className={inputStyle}
                type="number"
                placeholder={t('핸드폰번호 *')}
                maxLength={15}
              />
            </div>
            <div className="flex space-x-10 max-md:flex-col max-md:space-x-0 relative">
              <input
                maxLength={60}
                onChange={(e) => {
                  if (korean.test(e.target.value)) {
                    setInformPhrase(true);
                    return;
                  }
                  informPhrase && setInformPhrase(false);
                  setFormData((prev) => ({
                    ...prev,
                    inquirerEmail: e.target.value,
                  }));
                }}
                value={formData.inquirerEmail}
                className={inputStyle}
                type="email"
                placeholder={t('이메일 *')}
              />
              <InquiryTypeSelectBox
                formData={formData}
                setFormData={setFormData}
              />
              {informPhrase && (
                <span className="absolute bottom-10 left-0 text-xs md:-bottom-9 md:text-lg md:-left-10 text-rose-400 animate-shake">
                  {t('한글은 입력하실 수 없습니다.')}
                </span>
              )}
            </div>
            <div>
              <input
                className={inputStyle}
                spellCheck={false}
                maxLength={1500}
                onChange={(e) =>
                  setFormData((prev) => ({
                    ...prev,
                    inquiryCtnt: e.target.value,
                  }))
                }
                value={formData.inquiryCtnt}
                type="text"
                placeholder={t('문의 내용')}
              />
            </div>
          </div>
          <div className="flex justify-between items-center max-md:flex-col max-md:!items-start max-md:space-y-14">
            <button className="flex items-center space-x-4 max-md:space-x-3">
              <i onClick={() => setIsAgreed(!isAgreed)}>
                <HomeCheckIcon opacity={isAgreed ? '1' : '0.2'} />
              </i>
              <span
                className={`text-white text-xl max-md:text-sm ${
                  isAgreed ? 'text-opacity-100' : 'text-opacity-60'
                }`}
              >
                {language === 'kr' ? (
                  <>
                    <u onClick={() => setPolicyModal(true)}>개인정보처리방침</u>
                    <span onClick={() => setIsAgreed(!isAgreed)}>
                      에 동의합니다.
                    </span>
                  </>
                ) : (
                  <>
                    <span onClick={() => setIsAgreed(!isAgreed)}>
                      I agree with the
                    </span>
                    <u onClick={() => setPolicyModal(true)}> Privacy Policy.</u>
                  </>
                )}
              </span>
            </button>

            <HomeButton onClick={sendInquiryForm} color="mobile orange">
              CONTACT US
            </HomeButton>
          </div>
        </section>
      </section>
    </>
  );
}
