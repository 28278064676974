import { useTranslation } from 'react-i18next';
import SubPageDot from '../common/SubpageDot';

type Props = {
  isEnglish: boolean;
};

export default function SonSixthSection({ isEnglish }: Props) {
  const { t } = useTranslation();

  return (
    <section className="space-y-14 max-md:space-y-5 mt-14 flex max-lg:flex-col-reverse items-center justify-between ">
      <img
        className="w-full max-w-xs md:max-w-md 2xl:max-w-[534px]"
        src={isEnglish ? '/son/mobile2-en.png' : '/son/mobile2.png'}
        alt="mobile screen"
      />
      <div className="space-y-[18px] max-md:pr-5">
        <div className="text-white flex max-md:flex-col max-md:space-y-1 font-extralight">
          <SubPageDot />
          <h1 className="text-2xl md:text-4xl 2xl:text-5xl md:leading-[50px] 2xl:leading-[68px] whitespace-pre-wrap">
            {t('누구랑 무슨 대화를 했더라?')}
            <br />
            <strong className="font-bold">
              {t('기억나지 않는 내용은 통화 검색')}
            </strong>
          </h1>
        </div>
        <p className="text-white/60 text-base lg:text-lg 2xl:text-xl font-extralight md:ml-[30px] md:pr-[30px] w-full lg:max-w-lg 2xl:max-w-[613px] whitespace-pre-wrap">
          {t(
            '통화 내용이나 부재메시지 내용이 기억나지 않을 때는 검색 기능으로 원하는 구간을 다시 들어보거나 다운로드해보세요!'
          )}
        </p>
      </div>
    </section>
  );
}
