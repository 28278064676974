import { t } from 'i18next';
import moment from 'moment';
import { Dispatch, SetStateAction } from 'react';
import { useRecoilValue } from 'recoil';
import { PaymentsListClientDataType } from '../../../services/TypeOfPrivateService';
import { userinfoAtom } from '../../../store';
import { getPayStatusName, getServiceName } from '../../../utils/common';
import DetailButton from '../../common/DetailButtons';
import ServiceTag from '../../common/ServiceTag';
import NoData from '../NoData';

export default function PcPaymentCard({
  paymentList,
  setDetailModal,
}: {
  paymentList: PaymentsListClientDataType[];
  setDetailModal: Dispatch<
    SetStateAction<{
      open: boolean;
      item: PaymentsListClientDataType;
    }>
  >;
}) {
  const { language } = useRecoilValue(userinfoAtom);
  return (
    <>
      {paymentList.length ? (
        paymentList.map((payment, idx) => (
          <div
            key={`payment-${idx}`}
            className="border border-borderGray p-6 rounded-[10px] flex items-center justify-between max-md:hidden"
          >
            <div className="flex items-center space-x-2 min-w-[34%]">
              <ServiceTag type={payment.solutionType}>
                {getServiceName(payment.solutionType)}
              </ServiceTag>
              <ServiceTag type={payment.subscYn === 'Y' ? '구독' : '상품'}>
                {payment.subscYn === 'Y' ? '구독' : '상품'}
              </ServiceTag>
              <span className="flex items-center font-bold text-xl max-2xl:text-lg !ml-3">
                {t(payment.itemName)}
              </span>
            </div>
            <div className="flex items-center w-full">
              <span className="font-bold ml-10 text-xl max-2xl:text-lg w-1/4 text-end">
                {language === 'kr'
                  ? `${
                      payment.sumCost ? payment.sumCost?.toLocaleString() : 0
                    }원`
                  : `KRW ${
                      payment.sumCost ? payment.sumCost?.toLocaleString() : 0
                    }`}
              </span>
              <span className="text-[#666666] w-1/2 text-end">
                {moment(payment.payDtFrom).format('YYYY.MM.DD HH:mm')}
              </span>
              <span className="text-black2 w-1/4 text-end md:whitespace-pre-wrap">
                {t(getPayStatusName(payment.billStatus))}
              </span>
              <div className="w-1/4 text-end">
                <DetailButton
                  onClick={() => setDetailModal({ open: true, item: payment })}
                />
              </div>
            </div>
          </div>
        ))
      ) : (
        <NoData />
      )}
    </>
  );
}
