import { useTranslation } from 'react-i18next';
import SubPageDot from '../common/SubpageDot';

type Props = {
  isEnglish: boolean;
};

export default function SonThirdSection({ isEnglish }: Props) {
  const { t } = useTranslation();

  return (
    <section className="space-y-14 max-md:space-y-5 mt-14 flex max-lg:flex-col items-center justify-between">
      <div className="space-y-[18px] max-md:pr-5">
        <div className="text-white flex max-md:flex-col max-md:space-y-1 font-extralight">
          <SubPageDot />
          <h1 className="text-2xl md:text-4xl 2xl:text-5xl md:leading-[50px] 2xl:leading-[68px]">
            <strong className="font-bold">
              {t('이제 아이폰 통화녹음도 OK')}
            </strong>
            <br />
            {t('텍스트로 읽기 쉽게')}
          </h1>
        </div>
        <p className="text-white/60 text-base lg:text-lg 2xl:text-xl font-extralight md:ml-[30px] md:pr-[30px] w-full lg:max-w-lg 2xl:max-w-[613px]">
          {t(
            '손비서에 가입하면 착신전환 없이 바로 사용할 수 있는 AI비서 번호가 즉시 발급됩니다. 모든 통화 내용은 실시간으로 녹음되며 텍스트로도 변환되어 확인하실 수 있습니다.'
          )}
        </p>
      </div>
      <img
        className="w-full max-w-xs md:max-w-md 2xl:max-w-[534px]"
        src={isEnglish ? '/son/mobile1-en.png' : '/son/mobile1.png'}
        alt="mobile screen"
      />
    </section>
  );
}
