import { useTranslation } from 'react-i18next';
import DownloadIcon from '../vector/about/DownloadIcon';
import LogoSection from '../vector/about/LogoSection';
import MobileLogoSection from '../vector/about/MobileLogoSection';

export default function CiSection() {
  const { t } = useTranslation();
  return (
    <section className="max-md:!mt-32">
      <div className="flex max-md:flex-col">
        <h1 className="min-w-[300px] max-2xl:min-w-72 font-bold text-[32px] max-2xl:text-3xl max-md:text-xl">
          CI
        </h1>
        <div className="space-y-14 max-md:space-y-7 w-full max-md:mt-5">
          <div className="space-y-4">
            <h2 className="font-bold text-[28px] max-2xl:text-2xl max-md:text-base">
              {t('플루닛의 CI는 Planet Plus Moon의 결합어입니다.')}
            </h2>
            <p className="leading-7 max-md:text-sm">
              {t(
                '플루닛은 지구를 닮은 평행우주상의 멀티버스 세상입니다. 이 새로운 세상을 만들어가기 위해 우리를 꼭 닮은 플루니안과 시공간을 넘는 다양한 일상으로 인공지능과 지적으로 협력하며 소통해 나가는 의미를 형상화 하였습니다.'
              )}
            </p>
          </div>

          <div>
            <i className="max-md:hidden">
              <LogoSection />
            </i>
            <i className="hidden max-md:block">
              <MobileLogoSection />
            </i>
            <div className="flex max-md:flex-col max-md:items-start items-center justify-between mt-6 max-md:mt-3">
              <span className="text-black/40 max-md:text-sm">
                {t('가이드를 준수하여 변형되지 않도록 사용합니다.')}
              </span>
              <div className="flex min-w-fit max-md:w-full max-md:space-y-3 max-md:flex-col max-md:space-x-0 space-x-3 max-md:mt-7">
                <a
                  className="flex max-md:justify-between max-2xl:text-sm rounded-lg border border-black px-5 py-4 max-2xl:px-4 max-2xl:py-3 hover:bg-slate-100"
                  href="/download/ploonet_logo.ai"
                  download
                  target="_blank"
                  rel="noreferrer"
                >
                  {t('전체 LOGO AI 파일')}
                  <i className="ml-2">
                    <DownloadIcon />
                  </i>
                </a>

                <a
                  className="flex max-md:justify-between max-2xl:text-sm rounded-lg border border-black px-5 py-4 max-2xl:px-4 max-2xl:py-3 hover:bg-slate-100"
                  href="/download/ploonet_png.zip"
                  download
                  target="_blank"
                  rel="noreferrer"
                >
                  {t('전체 LOGO PNG 파일')}
                  <i className="ml-2">
                    <DownloadIcon />
                  </i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
