import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { regCertResultRequest } from '../../../services/RegCertService';

function RegCertResult() {
  const location = useLocation();
  function apiGetDecResData(data) {
    const reqBody = {
      encKey: window.opener.callbackGetEncKey(),
      encData: data,
    };
    regCertResultRequest(reqBody)
      .then((res) => {
        if (res.status === 200) {
          window.opener.callbackSetAuthResult(res.data.data);
        }
        window.close();
      })
      .catch(console.log);
  }

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const encData = query.get('enc_data') !== null ? query.get('enc_data') : '';
    apiGetDecResData(encData);
  }, []);

  return null;
}

export default RegCertResult;
