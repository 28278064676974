import { Dialog, Transition } from '@headlessui/react';
import { Fragment, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { reqAgreementTerms } from '../../services/PublicService';
import { siteLangCd } from '../../services/defaultClient';

export default function PolicyModal({
  termsCode,
  closeModal,
  policyModal,
}: {
  termsCode: 'M01E0' | 'M01F0' | 'M01G0';
  closeModal: React.Dispatch<SetStateAction<boolean>>;
  policyModal: boolean;
}) {
  const [terms, setTerms] = useState('');
  const { t } = useTranslation();
  useEffect(() => {
    reqAgreementTerms({
      siteLangCd,
      termsCd: termsCode,
    })
      .then((res) => setTerms(res.data.data.termsTxt))
      .catch(console.log);
  }, [termsCode]);

  const getTitle = () => {
    let title;
    termsCode === 'M01E0'
      ? (title = '통합서비스 이용약관 동의')
      : termsCode === 'M01F0'
      ? (title = '개인정보 수집 이용 동의')
      : (title = '광고성 정보 수신 및 마케팅 활용 동의');
    return t(title);
  };

  return (
    <>
      <Transition appear show={policyModal} as={Fragment}>
        <Dialog as="div" className="fixed z-50" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 max-md:bottom-0 max-md:inset-auto max-md:w-full">
            <div className="flex min-h-full items-center justify-center p-4 max-md:p-0 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95 max-md:translate-y-80 max-md:scale-100"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-300"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95 max-md:translate-y-80 max-md:scale-100"
              >
                <Dialog.Panel className="w-full max-w-[600px] rounded-[20px] max-md:rounded-b-none py-6 transform overflow-hidden bg-white transition-all max-md:w-screen">
                  <Dialog.Title
                    as="h3"
                    className="text-2xl max-md:text-xl pb-6 font-bold leading-6 text-gray-900 text-center border-b border-borderGray w-full whitespace-pre-wrap"
                  >
                    {getTitle()}
                  </Dialog.Title>

                  <section className="p-7">
                    <div className="h-96 text-left overflow-y-scroll inquiry-detail terms-box">
                      <div
                        className="signup-policy_modal"
                        dangerouslySetInnerHTML={{ __html: terms }}
                      />
                    </div>
                  </section>
                  <div className="px-7">
                    <button
                      onClick={() => closeModal(false)}
                      className="py-5 bg-gradient-to-br from-primary to-primaryTo hover:from-hoverPrimary hover:to-hoverPrimaryTo w-full rounded-xl text-white font-bold text-lg"
                    >
                      {t('확인')}
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
