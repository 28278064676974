import { useTranslation } from 'react-i18next';

type Props = {
  isEnglish: boolean;
};

export default function SonSecondSection({ isEnglish }: Props) {
  const { t } = useTranslation();

  return (
    <section className="mt-10 2xl:mt-16 text-white flex flex-col text-center justify-center items-center">
      <div className="text-4xl 2xl:text-6xl leading-8 md:leading-[60px] 2xl:leading-[80px]">
        <h1 className="font-extralight max-md:text-base">
          {t('보이스피싱, 스팸, 업무전화만 받기도 바쁜데')}
        </h1>
        <h1 className="font-bold max-md:text-2xl">
          {t('이제 손비서에게 맡기시고')}
          <br className="md:hidden" /> {t('스마트한 워라밸을 누려보세요')}
        </h1>
      </div>
      <img
        className="2xl:mt-8 max-w-3xl 2xl:max-w-5xl max-md:hidden"
        src={isEnglish ? '/son/pc-icons-en.png' : '/son/pc-icons.png'}
        alt="icons"
      />
      <img
        className="md:hidden"
        src={isEnglish ? '/son/mobile-icons-en.png' : '/son/mobile-icons.png'}
        alt="icons"
      />
    </section>
  );
}
