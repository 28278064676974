import { Disclosure } from '@headlessui/react';
import { ChevronUpIcon } from '@heroicons/react/20/solid';
import { SetStateAction, useEffect, useState } from 'react';
import { RegisterBusinessAppendInfoType } from '../../store';
import PostcodeModal from '../common/PostcodeModal';
import { numberException } from '../hooks/useValidate';
import DefaultCompanyLogo from '../my-page/my-info/DefaultCompanyIcon';

export default function EnterpriseOptionalInfoTab({
  registerInfo,
  setRegisterInfo,
  divStyle,
  inputStyle,
  setModalInfo,
}: {
  registerInfo: RegisterBusinessAppendInfoType;
  setRegisterInfo: React.Dispatch<
    SetStateAction<RegisterBusinessAppendInfoType>
  >;
  setModalInfo: React.Dispatch<
    SetStateAction<{
      isOpen: boolean;
      phrase: string;
      secondPhrase: string;
    }>
  >;
  divStyle: string;
  inputStyle: string;
}) {
  const [address, setAddress] = useState({
    roadName: '',
    buildingName: '',
    zipCode: '',
  });
  const [imageUrl, setImageUrl] = useState<any>(null);
  const [openPostcodeModal, setOpenPostcodeModal] = useState(false);
  const [key, setKey] = useState(0);

  useEffect(() => {
    registerInfo.companyLogo
      ? setImageUrl(URL.createObjectURL(registerInfo.companyLogo))
      : setImageUrl(null);
  }, [registerInfo.companyLogo]);

  useEffect(() => {
    setRegisterInfo((prev) => ({
      ...prev,
      companyAddress:
        address.roadName +
        (address.buildingName ? `, ${address.buildingName}` : ''),
      zipCode: address.zipCode,
    }));
  }, [address]);

  const deleteLogo = () => {
    setRegisterInfo((prev) => ({
      ...prev,
      companyLogo: null,
    }));
    setKey((prev) => prev + 1);
  };

  const onChangeFileHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    } else if (e.target.files[0].size > 5e6) {
      setModalInfo({
        isOpen: true,
        phrase: '파일 제한 용량을 초과했습니다.',
        secondPhrase: '',
      });
      return;
    }
    setRegisterInfo((prev: any) => ({
      ...prev,
      companyLogo: e.target.files ? e.target.files[0] : null,
    }));
  };

  return (
    <>
      <div className="w-full">
        <PostcodeModal
          closeModal={() => setOpenPostcodeModal(false)}
          openPostcodeModal={openPostcodeModal}
          setAddress={setAddress}
        />
        <div className="w-full bg-white duration-200 ease-out">
          <Disclosure defaultOpen>
            {({ open }) => (
              <>
                <Disclosure.Button className="flex items-center w-full pb-3 mb-8 border-b border-black2 justify-between text-sm font-medium text-black duration-100 ease-out border-0">
                  <section className="items-center flex !justify-between w-full max-md:w-full">
                    <span className="text-xl font-bold">
                      추가 정보 입력 (선택)
                    </span>
                    <div className="flex justify-end w-1/2 h-full">
                      <div className="flex items-center space-x-6 justify-end">
                        <ChevronUpIcon
                          className={`${
                            open ? '' : 'rotate-180 transform max-md:hidden'
                          } h-5 w-5 duration-300 ease-out max-md:hidden`}
                        />
                      </div>
                      <div className="hidden max-md:block">
                        <ChevronUpIcon
                          className={`${
                            open ? '' : 'rotate-180 transform'
                          } h-5 w-5 duration-300 ease-out`}
                        />
                      </div>
                    </div>
                  </section>
                </Disclosure.Button>
                <Disclosure.Panel className="text-sm text-gray-500 rounded-lg space-y-8">
                  <h1 className="text-lg font-semibold text-black2 mb-3">
                    회사 정보
                  </h1>
                  <section className="space-y-8 max-2xl:space-y-6 text-black2">
                    <div className="flex flex-col w-full space-y-3">
                      <div className="font-medium flex items-center space-x-1 max-2xl:text-base text-lg">
                        <span>회사 주소</span>
                      </div>
                      <div className="w-full space-x-3 flex justify-between">
                        <input
                          className="border border-borderLightGray py-3 px-4 rounded-md min-w-[370px] w-full text-lg max-md:min-w-0 max-md:text-base text-black2"
                          disabled
                          value={
                            address.roadName &&
                            `${address.roadName}, ${address.buildingName}`
                          }
                          placeholder="주소를 검색해주세요."
                        />
                        <button
                          onClick={() => setOpenPostcodeModal(true)}
                          className="rounded-lg border border-black hover:bg-gray5 min-w-[144px] max-md:min-w-fit max-md:px-4 text-base text-black2"
                        >
                          주소검색
                        </button>
                      </div>
                      <input
                        tabIndex={3}
                        className="border border-borderLightGray focus:border-primary py-3 px-4 rounded-md min-w-full text-lg max-md:text-base"
                        type="text"
                        maxLength={500}
                        onChange={(e) => {
                          setRegisterInfo((prev) => ({
                            ...prev,
                            companyAddressDetail: e.target.value,
                          }));
                        }}
                        placeholder="상세주소를 입력해주세요."
                      />
                    </div>
                    <div className={divStyle}>
                      <div className="font-medium flex items-center space-x-1">
                        <span>회사 대표전화</span>
                      </div>
                      <div className="flex space-x-3">
                        <input
                          tabIndex={4}
                          onPaste={(e) => e.preventDefault()}
                          onKeyDown={(e) => {
                            numberException.includes(e.key) &&
                              e.preventDefault();
                            registerInfo.companyTel?.length &&
                              registerInfo.companyTel?.length > 14 &&
                              e.key !== 'Backspace' &&
                              e.key !== 'Tab' &&
                              e.preventDefault();
                          }}
                          className={inputStyle}
                          onChange={(e) =>
                            setRegisterInfo((prev) => ({
                              ...prev,
                              companyTel: e.target.value,
                            }))
                          }
                          type="number"
                          placeholder="'-'없이 회사 대표전화를 입력해주세요."
                        />
                      </div>
                    </div>
                    <div className={divStyle}>
                      <div className="font-medium flex items-center space-x-1">
                        <span>회사 홈페이지</span>
                      </div>
                      <div className="flex space-x-3">
                        <input
                          tabIndex={5}
                          className={inputStyle}
                          onChange={(e) =>
                            setRegisterInfo((prev) => ({
                              ...prev,
                              companyUrl: e.target.value,
                            }))
                          }
                          type="text"
                          maxLength={100}
                          placeholder="회사 홈페이지를 입력해주세요."
                        />
                      </div>
                    </div>
                  </section>
                  <section>
                    <h1 className="text-lg font-semibold text-black2 mb-3">
                      회사 로고 등록
                    </h1>
                    <div className="flex items-center space-x-5 max-md:space-x-3">
                      <label className="rounded-full w-[100px] h-[100px] max-md:w-[94px] max-md:h-[94px] cursor-pointer">
                        <div
                          style={{
                            backgroundImage: `url(${imageUrl ? imageUrl : ''})`,
                          }}
                          className="w-full h-full rounded-full bg-cover bg-center relative"
                        >
                          {!imageUrl && (
                            <i className="bg-cover w-full h-full">
                              <DefaultCompanyLogo />
                            </i>
                          )}
                        </div>
                        <input
                          id="profile-logo"
                          key={key}
                          type="file"
                          className="hidden"
                          accept=".png, .jpg"
                          onChange={(e) => onChangeFileHandler(e)}
                        />
                      </label>
                      <div className="flex flex-col justify-between ml-5 space-y-4 max-md:space-y-2">
                        <div className="flex space-x-3">
                          <label
                            className="border border-borderLightGray rounded-lg hover:bg-gray5 w-fit cursor-pointer p-3 max-md:text-sm max-md:p-[10px]"
                            htmlFor="profile-logo"
                          >
                            {imageUrl ? '로고 변경' : '로고 등록'}
                          </label>
                          {imageUrl && (
                            <label
                              onClick={deleteLogo}
                              className="border border-borderLightGray flex items-center rounded-lg hover:bg-gray5 w-fit cursor-pointer py-2 px-3 max-md:text-sm max-md:p-[10px]"
                            >
                              로고 삭제
                            </label>
                          )}
                        </div>
                        <span className="text-textGray text-sm max-md:text-xs">
                          최대용량:5MB미만{' '}
                          <br className="hidden max-md:block" /> 이미지
                          파일(png, jpg)만 첨부 가능
                        </span>
                      </div>
                    </div>
                  </section>

                  <section className="border-t border-gray3 pt-10 max-2xl:pt-8 text-black2">
                    <h1 className="text-lg font-semibold mb-8">담당자 정보</h1>
                    <section className="space-y-8 max-2xl:space-y-6">
                      <div className={divStyle}>
                        <div className="font-medium flex items-center space-x-1">
                          <span>담당자 이름</span>
                        </div>
                        <div className="flex space-x-3">
                          <input
                            tabIndex={6}
                            className={inputStyle}
                            onChange={(e) =>
                              setRegisterInfo((prev) => ({
                                ...prev,
                                managerName: e.target.value,
                              }))
                            }
                            maxLength={20}
                            type="text"
                            placeholder="담당자 이름을 입력해주세요."
                          />
                        </div>
                      </div>
                      <div className={divStyle}>
                        <div className="font-medium flex items-center space-x-1">
                          <span>담당자 이메일</span>
                        </div>
                        <div className="flex space-x-3">
                          <input
                            tabIndex={7}
                            className={inputStyle}
                            onChange={(e) =>
                              setRegisterInfo((prev) => ({
                                ...prev,
                                managerEmail: e.target.value,
                              }))
                            }
                            type="email"
                            maxLength={60}
                            spellCheck={false}
                            placeholder="담당자 이메일을 입력해주세요."
                          />
                        </div>
                      </div>
                      <div className={divStyle}>
                        <div className="font-medium flex items-center space-x-1">
                          <span>소속부서</span>
                        </div>
                        <div className="flex space-x-3">
                          <input
                            tabIndex={8}
                            className={inputStyle}
                            onChange={(e) =>
                              setRegisterInfo((prev) => ({
                                ...prev,
                                department: e.target.value,
                              }))
                            }
                            type="text"
                            maxLength={30}
                            placeholder="소속부서를 입력해주세요."
                          />
                        </div>
                      </div>
                      <div className={divStyle}>
                        <div className="font-medium flex items-center space-x-1">
                          <span>직책</span>
                        </div>
                        <div className="flex space-x-3">
                          <input
                            tabIndex={9}
                            className={inputStyle}
                            onChange={(e) =>
                              setRegisterInfo((prev) => ({
                                ...prev,
                                position: e.target.value,
                              }))
                            }
                            type="text"
                            maxLength={30}
                            placeholder="직책을 입력해주세요."
                          />
                        </div>
                      </div>
                      <div className={divStyle}>
                        <div className="font-medium flex items-center space-x-1">
                          <span>회사 전화번호</span>
                        </div>
                        <div className="flex space-x-3">
                          <input
                            tabIndex={10}
                            onPaste={(e) => e.preventDefault()}
                            onKeyDown={(e) => {
                              numberException.includes(e.key) &&
                                e.preventDefault();
                              registerInfo.officeTel?.length &&
                                registerInfo.officeTel?.length > 14 &&
                                e.key !== 'Backspace' &&
                                e.key !== 'Tab' &&
                                e.preventDefault();
                            }}
                            className={inputStyle}
                            onChange={(e) =>
                              setRegisterInfo((prev) => ({
                                ...prev,
                                officeTel: e.target.value,
                              }))
                            }
                            type="number"
                            placeholder="회사 전화번호를 입력해주세요."
                          />
                        </div>
                      </div>
                    </section>
                  </section>
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
        </div>
      </div>
    </>
  );
}
