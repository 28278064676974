export default function StepTwoIcon() {
  return (
    <svg
      width="40"
      height="41"
      viewBox="0 0 40 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27 34.707L27 7.20703C27 5.82632 25.8807 4.70703 24.5 4.70703L9.5 4.70703C8.11929 4.70703 7 5.82632 7 7.20703L7 34.707C7 36.0877 8.11929 37.207 9.5 37.207H24.5C25.8807 37.207 27 36.0877 27 34.707Z"
        stroke="#666666"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17 12.207C18.0355 12.207 18.875 11.3676 18.875 10.332C18.875 9.2965 18.0355 8.45703 17 8.45703C15.9645 8.45703 15.125 9.2965 15.125 10.332C15.125 11.3676 15.9645 12.207 17 12.207Z"
        fill="#666666"
      />
      <circle cx="28.5" cy="18.457" r="7.5" fill="#F8F7F8" />
      <path
        d="M27.5513 23.1712L23.2969 18.9166L24.849 17.3644L27.5513 20.0667L32.075 15.543L33.6271 17.0953L27.5513 23.1712Z"
        fill="#666666"
      />
      <path
        d="M28.4566 27.6204H19.5V18.6636C19.5 13.725 23.5178 9.70703 28.4566 9.70703C33.3954 9.70703 37.4134 13.7248 37.4134 18.6636C37.4132 23.6029 33.3954 27.6204 28.4566 27.6204ZM21.6954 25.425H28.4564C32.1843 25.425 35.2175 22.3918 35.2175 18.6638C35.2175 14.9361 32.1843 11.9029 28.4564 11.9029C24.7287 11.9029 21.6954 14.9361 21.6954 18.6638V25.425Z"
        fill="#666666"
      />
    </svg>
  );
}
