import clsx from 'clsx';
import { ButtonHTMLAttributes } from 'react';

export interface ButtonTypeProps
  extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: string;
  color?: 'orange' | 'white' | 'small orange' | 'sub orange';
}

export default function SignupButton({
  children,
  color = 'orange',
  ...props
}: ButtonTypeProps) {
  return (
    <button
      className={clsx(
        'py-7 w-full text-white rounded-lg text-xl font-bold max-md:text-lg',
        {
          'bg-gradient-to-br from-primary to-primaryTo hover:from-hoverPrimary hover:to-hoverPrimaryTo max-md:px-0 max-md:py-4':
            color === 'orange',
          'bg-gradient-to-br from-primary to-primaryTo hover:from-hoverPrimary hover:to-hoverPrimaryTo max-md:px-0 !py-[18px] max-md:py-4 max-md:!w-full':
            color === 'sub orange',
          'bg-gradient-to-br from-primary to-primaryTo hover:from-hoverPrimary hover:to-hoverPrimaryTo !rounded-full !text-lg !px-[94px] !py-5':
            color === 'small orange',
          'bg-white border border-borderLightGray hover:bg-gray5 !text-black max-md:px-0 max-md:py-4':
            color === 'white',
          '!bg-gradient-to-b !from-[#cccccc] !to-[#cccccc] cursor-not-allowed':
            props.disabled,
        }
      )}
      {...props}
    >
      {children}
    </button>
  );
}
