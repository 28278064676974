export default function DragDropIcon() {
  return (
    <svg
      className="w-[100px] h-[100px] max-md:w-[72px] max-md:h-[72px]"
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="50" cy="50" r="50" fill="#222222" />
      <path
        d="M74.0007 55.5166V49.9845C74.0007 40.7643 70.3084 37.0762 61.0778 37.0762H50.001C40.7704 37.0762 37.0781 40.7643 37.0781 49.9845V61.0487C37.0781 70.2689 40.7704 73.957 50.001 73.957H55.5394"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M31.9999 61.8112C27.7919 60.1342 26 56.3958 26 49.9725V38.9083C26 29.6881 29.6923 26 38.9229 26H49.9997C56.6262 26 60.3984 27.9007 61.9995 32.3842"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="2 4"
      />
      <path
        d="M72.0789 66.2872L69.0697 67.3014C68.239 67.578 67.5743 68.2234 67.2974 69.0717L66.2821 72.0775C65.4144 74.6776 61.759 74.6223 60.9468 72.0222L57.5314 61.0501C56.8668 58.8741 58.8791 56.8457 61.0391 57.528L72.042 60.9395C74.6266 61.7508 74.6635 65.4205 72.0789 66.2872Z"
        fill="url(#paint0_linear_1619_100534)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1619_100534"
          x1="57.4023"
          y1="57.3936"
          x2="69.5353"
          y2="76.792"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF478B" />
          <stop offset="1" stopColor="#FFBB54" />
        </linearGradient>
      </defs>
    </svg>
  );
}
