import ChevronRight from '../../vector/common/ChevronRight';

const listArray = [
  {
    label: '간편계정 만들기',
    value: 'step01',
  },
  {
    label: '약관동의',
    value: 'step02',
  },
  {
    label: 'AI직원 대화 시작',
    value: 'complete',
  },
];
type Props = {
  current: 'step01' | 'step02' | 'complete';
};
export default function Breadcrumb({ current }: Props) {
  return (
    <ul className="flex">
      {listArray.map(({ label, value }, idx) => (
        <li
          key={value}
          className={`${
            current === value ? 'text-primary' : 'text-black5'
          } flex items-center font-semibold`}
        >
          <span className="max-md:text-xs">{label}</span>
          <i className="mx-2">{idx !== 2 && <ChevronRight />}</i>
        </li>
      ))}
    </ul>
  );
}
