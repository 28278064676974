import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { OnChangeEnterpriseInfoType } from '../../../services/TypeOfPrivateService';
import { fileBaseURL } from '../../../services/defaultClient';
import PostcodeModal from '../../common/PostcodeModal';
import InformModal from '../../common/modal/InformModal';
import { usePloonianImage } from '../../hooks/usePloonian';
import { numberException } from '../../hooks/useValidate';
import PolicyModal from '../../signup/PolicyModal';
import AgreementSection from './AgreementSection';
import DefaultCompanyLogo from './DefaultCompanyIcon';
import PasswordModal from './PasswordModal';
import BusinessModifyModal from './business-modify-modal';
import PloonianInfo from './ploonian-info';

const divStyle =
  'py-8 border-b border-borderGray flex items-center max-md:justify-between max-md:px-0 max-md:py-4';
const titleStyle =
  'w-[200px] min-w-[200px] max-md:min-w-fit font-bold text-lg max-md:text-base max-md:w-1/2';

export default function MyEnterpriseInformation({
  myInfo,
  profileImage,
  setProfileImage,
  setOnChangeInfo,
  onChangeInfo,
  setPloonianModal,
}: {
  setPloonianModal: React.Dispatch<React.SetStateAction<boolean>>;
  myInfo: any;
  profileImage: File | null;
  setProfileImage: React.Dispatch<React.SetStateAction<File | null>>;
  onChangeInfo: OnChangeEnterpriseInfoType;
  setOnChangeInfo: React.Dispatch<
    React.SetStateAction<OnChangeEnterpriseInfoType>
  >;
}) {
  const [key, setKey] = useState(0);
  const [imageUrl, setImageUrl] = useState<any>(null);
  const [passwordModal, setPasswordModal] = useState(false);
  const [businessModal, setBusinessModal] = useState({
    isOpen: false,
    ceoFnm: '',
  });
  const [initialPath, setInitialPath] = useState('');
  const [informModal, setInformModal] = useState({ isOpen: false, phrase: '' });
  const [policyModal, setPolicyModal] = useState(false);
  const [address, setAddress] = useState({
    roadName: '',
    buildingName: '',
    zipCode: '',
  });
  const [openPostcodeModal, setOpenPostcodeModal] = useState(false);
  const { ploonianThumbnail } = usePloonianImage();
  const { t } = useTranslation();
  const isQuickStartUser = myInfo.quickstartStateCd === 1;

  useEffect(() => {
    const {
      memberTypeCd,
      companyAddr1,
      companyAddr2,
      companyZipno,
      companyMainTelno,
      homepageUrl,
      managerFnm,
      managerEmail,
      managerTelno,
      depatmentNm,
      positionNm,
      termsAgreeOptional,
    } = myInfo;
    setOnChangeInfo((prev) => ({
      ...prev,
      memberTypeCd,
      companyAddr1,
      companyAddr2,
      companyZipno,
      companyMainTelno,
      homepageUrl,
      managerFnm,
      managerEmail,
      managerTelno,
      depatmentNm,
      positionNm,
      termsAgreeOptional: {
        ...prev.termsAgreeOptional,
        termsAgreeYn: termsAgreeOptional.termsAgreeYn,
        smsAgreeYn: termsAgreeOptional.smsAgreeYn,
        mailAgreeYn: termsAgreeOptional.mailAgreeYn,
      },
    }));
    setInitialPath(!myInfo.companyPath ? '' : fileBaseURL + myInfo.companyPath);
  }, [myInfo]);

  useEffect(() => {
    profileImage
      ? setImageUrl(URL.createObjectURL(profileImage))
      : setImageUrl(null);
  }, [profileImage]);

  useEffect(() => {
    if (!address.roadName) {
      return;
    }
    setOnChangeInfo((prev) => ({
      ...prev,
      companyAddr1:
        address.roadName +
        (address.buildingName ? `, ${address.buildingName}` : ''),
      companyZipno: address.zipCode,
    }));
  }, [address.roadName]);

  const onChangeFileHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    } else if (e.target.files[0].size > 5e6) {
      setInformModal({
        isOpen: true,
        phrase: '파일 제한 용량을 초과했습니다.',
      });
      return;
    }
    setProfileImage(e.target.files[0]);
  };

  const deleteProfileImage = () => {
    setOnChangeInfo((prev) => ({
      ...prev,
      companyPathDelYn: 'Y',
    }));
    setInitialPath('');
    setProfileImage(null);
    setKey((prev) => prev + 1);
  };

  return (
    <>
      <BusinessModifyModal
        ceo={myInfo?.ceoFnm}
        company={myInfo?.companyNm}
        profileImage={profileImage}
        onChangeInfo={onChangeInfo}
        modal={businessModal.isOpen}
        closeModal={() =>
          setBusinessModal((prev) => ({ ...prev, isOpen: false }))
        }
      />
      <PasswordModal
        modal={passwordModal}
        closeModal={() => setPasswordModal(false)}
      />
      <InformModal
        modal={informModal.isOpen}
        phrase={informModal.phrase}
        closeModal={() =>
          setInformModal((prev) => ({ ...prev, isOpen: false }))
        }
      />
      <PolicyModal
        policyModal={policyModal}
        termsCode="M01G0"
        closeModal={() => setPolicyModal(false)}
      />
      <PostcodeModal
        closeModal={() => setOpenPostcodeModal(false)}
        openPostcodeModal={openPostcodeModal}
        setAddress={setAddress}
      />
      <div className="w-full mb-20 max-md:mb-10 max-md:w-full text-lg text-black2">
        <section>
          <h1 className="font-bold text-[32px] max-2xl:text-3xl pb-5 border-b-2 border-black max-md:text-2xl">
            {t('기본 정보')}
          </h1>
          <FixedInfoBox
            label="아이디(이메일)"
            displayInfo={myInfo.memberEmail}
          />
          <div className={divStyle}>
            <div className={titleStyle}>{t('비밀번호')}</div>
            <button
              onClick={() => setPasswordModal(true)}
              className="border border-black py-3 px-6 max-2xl:py-2 max-2xl:px-5 rounded-md text-lg hover:bg-gray5 max-md:text-base max-md:px-4 max-md:min-w-fit"
            >
              {t('비밀번호 변경')}
            </button>
          </div>
          <FixedInfoBox label="회사명" displayInfo={myInfo.companyNm} />
          <div className={divStyle}>
            <div className={titleStyle}>{t('사업자등록번호')}</div>
            <div
              className={`max-md:text-base flex items-end max-md:space-y-4 md:items-center max-md:flex-col ${
                !myInfo.memberBrno && 'text-black5'
              }`}
            >
              <span>{t(myInfo.memberBrno || '000-00-00000')}</span>
              {!myInfo.memberBrno && (
                <button
                  onClick={() =>
                    setBusinessModal({ isOpen: true, ceoFnm: myInfo.ceoFnm })
                  }
                  className="border text-black border-black py-3 px-6 max-2xl:py-2 max-2xl:px-5 rounded-md text-lg hover:bg-gray5 max-md:text-base max-md:px-4 max-md:w-fit md:ml-4"
                >
                  사업자등록번호 등록
                </button>
              )}
            </div>
          </div>
          <FixedInfoBox
            label="개업일자"
            displayInfo={myInfo.establishYmd || '0000. 00. 00'}
            isNone={!myInfo.establishYmd}
          />
          <FixedInfoBox label="대표자명" displayInfo={myInfo.ceoFnm} />
          <div
            className={`${divStyle} max-md:items-start max-md:flex-col max-md:space-y-3`}
          >
            <div className={titleStyle}>{t('나의 플루니안')}</div>
            <PloonianInfo
              quickstartDetail={myInfo.quickstartDetail}
              isQuickStartUser={isQuickStartUser}
              setPloonianModal={setPloonianModal}
              ploonianThumbnail={ploonianThumbnail}
            />
          </div>
        </section>

        <section>
          <h1 className="font-bold text-[32px] max-2xl:text-3xl pb-5 border-b-2 border-black max-md:text-2xl mt-[60px] max-2xl:mt-12">
            {t('회사 정보')}
          </h1>
          <div className="py-8 border-b border-borderGray flex items-center max-md:flex-col max-md:items-start max-md:px-0 max-md:py-4 max-md:space-y-4 max-md:border-none">
            <div className={titleStyle}>{t('회사 주소')}</div>
            <div className="flex flex-col w-2/3 space-y-3 max-md:w-full">
              <div className="w-full space-x-3 flex justify-between">
                <input
                  className="border bg-gray5 border-borderLightGray py-3 px-4 rounded-md min-w-[370px] w-full text-lg max-md:min-w-0 max-md:text-base"
                  type="text"
                  value={
                    address.roadName
                      ? `${address.roadName}, ${address.buildingName}`
                      : onChangeInfo.companyAddr1?.length > 2
                      ? onChangeInfo.companyAddr1
                      : ''
                  }
                  disabled
                  placeholder={t('주소를 검색해주세요.')}
                />
                <button
                  onClick={() => setOpenPostcodeModal(true)}
                  className="border border-black py-3 px-6 rounded-md max-w-[140px] w-full max-md:w-1/3 text-lg hover:bg-gray5 max-md:text-base max-md:px-4"
                >
                  {t('주소검색')}
                </button>
              </div>
              <input
                className="border border-borderLightGray focus:border-primary py-3 px-4 rounded-md min-w-full text-lg max-md:text-base"
                type="text"
                value={onChangeInfo.companyAddr2}
                maxLength={500}
                placeholder={t('상세주소를 입력해주세요.')}
                onChange={(e) =>
                  setOnChangeInfo((prev) => ({
                    ...prev,
                    companyAddr2: e.target.value,
                  }))
                }
              />
            </div>
          </div>

          <InputBox
            value={onChangeInfo.companyMainTelno}
            label="회사 대표전화"
            placeholder="회사 대표전화를 입력해주세요."
            type="number"
            onKeyDown={(e: any) => {
              onChangeInfo?.companyMainTelno?.length > 14 &&
                e.key !== 'Backspace' &&
                e.key !== 'Tab' &&
                e.preventDefault();
              numberException.includes(e.key) && e.preventDefault();
            }}
            onChange={(e: any) =>
              setOnChangeInfo((prev) => ({
                ...prev,
                companyMainTelno: e.target.value,
              }))
            }
          />
          <InputBox
            maxLength={100}
            value={onChangeInfo.homepageUrl}
            label="회사 홈페이지"
            placeholder="회사 홈페이지 주소를 입력해주세요."
            onChange={(e: any) =>
              setOnChangeInfo((prev) => ({
                ...prev,
                homepageUrl: e.target.value,
              }))
            }
          />

          <div className="py-8 border-b border-borderGray flex items-center max-md:flex-col max-md:space-y-3 max-md:items-start max-md:p-0 max-md:py-6">
            <div className={titleStyle}>{t('회사 로고')}</div>
            <div className="flex">
              <label className="rounded-full w-[100px] h-[100px] max-md:w-[94px] max-md:h-[94px] cursor-pointer">
                <div
                  style={{
                    backgroundImage: `url(${
                      imageUrl
                        ? imageUrl
                        : myInfo.companyPath
                        ? fileBaseURL + myInfo.companyPath
                        : ''
                    })`,
                  }}
                  className="w-full h-full rounded-full bg-cover bg-center relative"
                >
                  {!initialPath && !imageUrl && (
                    <i className="bg-cover w-full h-full">
                      <DefaultCompanyLogo />
                    </i>
                  )}
                </div>
                <input
                  key={key}
                  id="profile-logo"
                  type="file"
                  className="hidden"
                  accept=".png, .jpg"
                  onChange={(e) => onChangeFileHandler(e)}
                />
              </label>
              <div className="flex flex-col justify-center ml-5 space-y-3 max-md:space-y-2">
                <div className="flex space-x-3">
                  <label
                    className="border border-borderLightGray rounded-lg hover:bg-gray5 w-fit cursor-pointer py-2 px-3 max-md:text-sm max-md:p-[10px] max-md:min-w-fit"
                    htmlFor="profile-logo"
                  >
                    {imageUrl || initialPath ? t('로고 변경') : t('로고 등록')}
                  </label>
                  {(imageUrl || initialPath) && (
                    <label
                      onClick={deleteProfileImage}
                      className="border border-borderLightGray rounded-lg hover:bg-gray5 w-fit cursor-pointer py-2 px-3 max-md:text-sm max-md:p-[10px] max-md:min-w-fit"
                    >
                      {t('로고 삭제')}
                    </label>
                  )}
                </div>

                <span className="text-textGray text-sm max-md:text-xs">
                  {t('5MB 미만 / 이미지 파일')}{' '}
                  <br className="hidden max-md:block" />
                  {t('(jpg, png)만 첨부 가능')}
                </span>
              </div>
            </div>
          </div>
        </section>

        <section>
          <h1 className="font-bold text-[32px] max-2xl:text-3xl pb-5 border-b-2 border-black max-md:text-2xl mt-[60px] max-2xl:mt-12">
            담당자 정보
          </h1>

          <InputBox
            value={onChangeInfo.managerFnm}
            label="담당자 이름"
            maxLength={20}
            placeholder="회사 담당자를 입력해주세요."
            onChange={(e: any) =>
              setOnChangeInfo((prev) => ({
                ...prev,
                managerFnm: e.target.value,
              }))
            }
          />

          <InputBox
            value={onChangeInfo.managerEmail}
            label="담당자 이메일"
            type="email"
            maxLength={60}
            placeholder="담당자 이메일을 입력해주세요."
            onChange={(e: any) =>
              setOnChangeInfo((prev) => ({
                ...prev,
                managerEmail: e.target.value,
              }))
            }
          />

          <InputBox
            maxLength={20}
            value={onChangeInfo.depatmentNm}
            label="소속 부서"
            placeholder="소속 부서를 입력해주세요."
            onChange={(e: any) =>
              setOnChangeInfo((prev) => ({
                ...prev,
                depatmentNm: e.target.value,
              }))
            }
          />

          <InputBox
            maxLength={20}
            value={onChangeInfo.positionNm}
            label="직책"
            placeholder="직책을 입력해주세요."
            onChange={(e: any) =>
              setOnChangeInfo((prev) => ({
                ...prev,
                positionNm: e.target.value,
              }))
            }
          />

          <InputBox
            value={onChangeInfo.managerTelno}
            label="회사 전화번호"
            placeholder="회사 전화번호를 입력해주세요."
            type="number"
            onKeyDown={(e: any) => {
              onChangeInfo.managerTelno.length > 14 &&
                e.key !== 'Backspace' &&
                e.key !== 'Tab' &&
                e.preventDefault();
              numberException.includes(e.key) && e.preventDefault();
            }}
            onChange={(e: any) =>
              setOnChangeInfo((prev) => ({
                ...prev,
                managerTelno: e.target.value,
              }))
            }
          />
        </section>
        <AgreementSection
          titleStyle={titleStyle}
          onChangeInfo={onChangeInfo}
          setOnChangeInfo={setOnChangeInfo}
        />
      </div>
    </>
  );
}
function FixedInfoBox({
  label,
  displayInfo,
  isNone,
}: {
  label: string;
  displayInfo: string;
  isNone?: boolean;
}) {
  const { t } = useTranslation();
  return (
    <div className={divStyle}>
      <div className={titleStyle}>{t(label)}</div>
      <div className={`max-md:text-base ${isNone && 'text-black5'}`}>
        {t(displayInfo)}
      </div>
    </div>
  );
}

function InputBox({
  label,
  value,
  placeholder,
  onChange,
  type = 'text',
  onKeyDown,
  maxLength,
}: {
  label: string;
  value: string;
  placeholder: string;
  onChange: any;
  type?: string;
  onKeyDown?: any;
  maxLength?: number;
}) {
  return (
    <div
      className={`${divStyle} max-md:flex-col max-md:space-y-2 max-md:items-start `}
    >
      <div className={titleStyle}>{t(label)}</div>
      <input
        maxLength={maxLength}
        spellCheck={false}
        onKeyDown={onKeyDown}
        value={value}
        className="border border-borderLightGray focus:border-primary py-3 px-4 rounded-md w-full text-lg max-md:placeholder:text-base"
        type={type}
        placeholder={t(placeholder)}
        onChange={(e) => onChange(e)}
      />
    </div>
  );
}
