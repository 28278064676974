import { Dialog, Transition } from '@headlessui/react';
import { t } from 'i18next';
import {
  Fragment,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { usePloonianImage } from '../hooks/usePloonian';
import CloseIcon from '../vector/common/CloseIcon';
import MusicFlowIcon from '../vector/common/MusicFlowIcon';
import OrangePlayIcon from '../vector/mypage/OrangePlayIcon';

export default function PloonianModal({
  closeModal,
  modal,
}: {
  closeModal: React.Dispatch<SetStateAction<boolean>>;
  modal: boolean;
}) {
  const [audio, setAudio] = useState<HTMLAudioElement | null>();
  const [isPlaying, setIsPlaying] = useState(false);
  const { ploonianFullImage, ploonianThumbnail, ploonianName, ploonianVoice } =
    usePloonianImage();

  useEffect(
    () =>
      setAudio(
        new Audio(
          `https://ploonian.s3.ap-northeast-2.amazonaws.com/ploonian/voice/${ploonianVoice}.wav`
        )
      ),
    [ploonianVoice]
  );

  const handleAudioClick = useCallback(() => {
    if (!audio) {
      return;
    }
    if (isPlaying) {
      audio.pause();
      audio.currentTime = 0;
      setIsPlaying(false);
      return;
    }
    audio.play().catch(console.log);
    setIsPlaying(true);
    audio.onended = () => {
      setIsPlaying(false);
    };
  }, [isPlaying, audio]);

  const close = () => {
    closeModal(false);
    if (!audio) {
      return;
    }
    audio.pause();
    audio.currentTime = 0;
    setIsPlaying(false);
  };

  return (
    <>
      <Transition appear show={modal} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-50"
          onClose={() => {
            return;
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 max-md:inset-auto max-md:bottom-0 rounded-xl max-md:rounded-b-none overflow-y-hidden">
            <div className="flex min-h-full items-center justify-center p-4 text-center max-md:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95 max-md:scale-100 max-md:translate-y-96"
                enterTo="opacity-100 scale-100 max-md:translate-y-0"
                leave="ease-in duration-300"
                leaveFrom="opacity-100 scale-100 max-md:translate-y-0"
                leaveTo="opacity-0 scale-95 max-md:scale-100 max-md:translate-y-96"
              >
                <Dialog.Panel className="w-full max-w-[500px] rounded-[20px] max-md:rounded-b-none transform overflow-hidden bg-white transition-all max-md:w-screen">
                  <Dialog.Title
                    as="h3"
                    className="text-2xl max-md:text-xl py-6 font-bold leading-6 text-gray-900 text-center border-b border-borderGray w-full relative"
                  >
                    {t('나의 플루니안')}
                    <button
                      onClick={close}
                      className="absolute bottom-1/2 translate-y-1/2 right-7"
                    >
                      <CloseIcon isDark={false} isBig />
                    </button>
                  </Dialog.Title>

                  <section className="p-7 max-md:p-5 bg-[#F8F7F8]">
                    <div className="flex justify-center">
                      <img
                        draggable={false}
                        className="h-[390px] max-md:h-[320px]"
                        src={ploonianFullImage}
                        alt="ploonian image"
                      />
                    </div>

                    <div className="py-4 px-5 bg-white w-full rounded-xl mt-10 max-md:mt-5 flex items-center justify-between">
                      <div className="flex items-center space-x-4 max-md:space-x-3">
                        <div
                          className="w-11 h-11 rounded-full bg-cover bg-center bg-no-repeat"
                          style={{
                            backgroundImage: `url(${ploonianThumbnail})`,
                          }}
                        />
                        <span className="text-black2 font-bold text-xl max-md:text-lg">
                          {ploonianName}
                        </span>
                      </div>

                      <button
                        onClick={handleAudioClick}
                        className="border border-primary hover:bg-gray5 rounded-full px-4 py-2 flex items-center space-x-2"
                      >
                        {isPlaying ? <MusicFlowIcon /> : <OrangePlayIcon />}

                        <span className="text-sm">{t('목소리 듣기')}</span>
                      </button>
                    </div>
                  </section>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
