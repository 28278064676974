export default function MailIcon() {
  return (
    <svg
      width="15"
      height="13"
      viewBox="0 0 15 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.6"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.87868 1.39363C0 2.2775 0 3.70006 0 6.54518C0 9.3903 0 10.8129 0.87868 11.6967C1.75736 12.5806 3.17157 12.5806 6 12.5806H9C11.8284 12.5806 13.2426 12.5806 14.1213 11.6967C15 10.8129 15 9.3903 15 6.54518C15 3.70006 15 2.2775 14.1213 1.39363C13.2426 0.509766 11.8284 0.509766 9 0.509766H6C3.17157 0.509766 1.75736 0.509766 0.87868 1.39363ZM12.4321 3.16524C12.631 3.40531 12.5988 3.76209 12.3601 3.96215L10.7127 5.34305C10.048 5.90031 9.50916 6.35198 9.03362 6.65964C8.53824 6.98012 8.05581 7.18257 7.5 7.18257C6.94419 7.18257 6.46176 6.98012 5.96638 6.65964C5.49084 6.35198 4.95203 5.90032 4.28727 5.34306L2.6399 3.96215C2.40124 3.76209 2.369 3.40531 2.56788 3.16524C2.76676 2.92518 3.12145 2.89274 3.3601 3.0928L4.97928 4.45007C5.679 5.03661 6.1648 5.44252 6.57494 5.70786C6.97196 5.96471 7.2412 6.05093 7.5 6.05093C7.7588 6.05093 8.02804 5.96471 8.42506 5.70786C8.8352 5.44252 9.321 5.03661 10.0207 4.45007L11.6399 3.0928C11.8786 2.89274 12.2332 2.92518 12.4321 3.16524Z"
        fill="#222222"
      />
    </svg>
  );
}
