import axios from 'axios';
// import moment from 'moment';
import moment from 'moment-timezone';
import { ENV } from '../env';

export const PAYSYSTEM_MEMID = 'paySystemMemberId';
export const PAYSYSTEM_MEMSEQ = 'paySystemMemberSeq';
export const PAYSYSTEM_TOKEN = 'paySystemToken';
export const PAYSYSTEM_COMSEQ = 'paySystemCompanySeq';
export const PAYSYSTEM_EXPDATE = 'paySystemExpiredDate';

let URL = '';
switch (ENV.REACT_APP_SERVICE_MODE) {
  case 'develop':
    URL = ENV.REACT_APP_PAY_DEVSERVER_URL;
    break;
  case 'test':
    URL = ENV.REACT_APP_PAY_TESTSERVER_URL;
    break;
  case 'production':
    URL = ENV.REACT_APP_PAY_RELSERVER_URL;
    break;
  default:
    URL = ENV.REACT_APP_PAY_DEVSERVER_URL;
}

export const paySysBaseURL = URL;

const initPaySystemAxios = () => {
  const defaultAxios = axios.create({
    url: paySysBaseURL,
    baseURL: paySysBaseURL,
    auth: {
      username: ENV.REACT_APP_PAYSYS_AUTH_USER,
      password: ENV.REACT_APP_PAYSYS_AUTH_PW,
    },
    headers: { 'auth-token': localStorage.getItem(PAYSYSTEM_TOKEN) },
  });
  return defaultAxios;
};

// 인증 토큰
const nameSpaceAuth = '/api/account/v1/authentication';
// 사용자 검증
const nameSpaceValidate = '/api/account/v1/authentication/validate';

// 회원가입
const nameSpaceAccountCreate = '/api/account/v1/members';
// 회원변경 (정상,정지,탈퇴)
const nameSpaceAccountModify = '/api/account/v1/members';
export const ACCOUNT_STATUS_NORMAL = 'A1101'; // 계정 정상
export const ACCOUNT_STATUS_STOP = 'A1102'; // 계정 정지
export const ACCOUNT_STATUS_DEL = 'A1103'; // 계정 탈퇴

// 회원 상태 변경
const nameSpaceAccountStatus = '/api/account/v1/members';

// 구독(요금제) 정보 조회
const nameSpaceSubscribeList = '/api/billing/v1/credits/info/b13';

// 사용자 결제 내역 조회
const nameSpacePaymentUsageList = '/api/payment/v1/payments/usages';

// 사용자 크레딧 사용 내역 조회
const nameSpaceCreditUsageList = '/api/billing/v1/credits/usages';

// 크레딧 잔액 조회
const nameSpaceCurrentCredit = '/api/billing/v1/credits/left/b13';

// 크레딧 차감
const nameSpaceMinusCredit = '/api/billing/v1/credits/minus';

const paySystemAxios = initPaySystemAxios();

/**************************************************************
 * 결제시스템 인증 Method
 **************************************************************/
interface PaySystemInfoTypes {
  result: number;
  memberSeq: number;
  authToken: string;
  companySeq: number;
  tokenExpiredDate: number;
}

/**
 * 결제 시스템에 로그인 후 연동에 필요한 값을 local storage에 저장.
 * @param args
 */
export const saveLocalPaySystemInfo = (args: PaySystemInfoTypes) => {
  localStorage.setItem(PAYSYSTEM_TOKEN, args.authToken);
  localStorage.setItem(PAYSYSTEM_COMSEQ, args.companySeq.toString());
  localStorage.setItem(PAYSYSTEM_EXPDATE, args.tokenExpiredDate.toString());
  localStorage.setItem(PAYSYSTEM_MEMSEQ, args.memberSeq.toString());
};

/**
 * localstorage에 저장되어 있는 값을 가져온다.
 * @returns boolean : localstorage에 값이 이상인 경우 false, 정상인 경우 true
 *          PaySystemInfoTypes : localstorage에서 가져온 값.
 */
const getLocalPaySystemInfo = (): [boolean, PaySystemInfoTypes] => {
  let info: PaySystemInfoTypes = {
    result: 0,
    memberSeq: 0,
    authToken: '',
    companySeq: 0,
    tokenExpiredDate: 0,
  };

  const memseq = localStorage.getItem(PAYSYSTEM_MEMSEQ);
  if (!memseq) {
    return [false, info];
  }
  info.memberSeq = Number(memseq);
  const token = localStorage.getItem(PAYSYSTEM_TOKEN);
  if (!token) {
    return [false, info];
  }
  info.authToken = token;

  const comseq = localStorage.getItem(PAYSYSTEM_COMSEQ);
  if (!comseq) {
    return [false, info];
  }
  info.companySeq = Number(comseq);

  const exp = localStorage.getItem(PAYSYSTEM_EXPDATE);
  if (!exp) {
    return [false, info];
  }
  info.tokenExpiredDate = Number(exp);

  return [true, info];
};

/**
 * localstorage에 저장된 company seq 값을 구한다.
 * @returns
 */
const getLocalCompanySeq = (): string | undefined => {
  let companySeq: string | undefined = undefined;
  const tSeq = localStorage.getItem(PAYSYSTEM_COMSEQ);
  if (tSeq) {
    companySeq = tSeq;
  }

  return companySeq;
};

/**
 * 결제시스템의 토큰의 만료시간을 지났는지 확인.
 */
export const isValidateExpireDate = (expDate?: number): boolean => {
  let expireToken;
  if (!expDate) {
    let expire = localStorage.getItem(PAYSYSTEM_EXPDATE);
    if (!expire) {
      return false;
    }
    expireToken = Number(expire);
  } else {
    expireToken = expDate;
  }
  const curTS = moment().tz('Asia/Seoul').valueOf();
  if (curTS < expireToken) {
    return true;
  }
  return false;
};

/**
 * localstoreage의 결제시스템 정보의 정상 유무판단
 * 결제시스템 정보가 없거나 유효기간을 지났으면 새로운 값을 요청하여
 * 저장시킨다.
 * @returns
 */
export const validatePayInfo = async (): Promise<any> => {
  const [isOkPayInfo, payInfo] = getLocalPaySystemInfo();

  if (!isOkPayInfo || !isValidateExpireDate(payInfo.tokenExpiredDate)) {
    // 다시 인증 요청 수행
    authPaySystem().then((res) => {
      if (!res) {
        return;
      }
      const data = res.data;
      if (res.status === 200) {
        saveLocalPaySystemInfo(data);
        return Promise.resolve(true);
      } else {
        localStorage.clear();
        return Promise.resolve(false);
      }
    });
  }
  return Promise.resolve(true);
};

export const getPayInfoToken = (): string | null => {
  return localStorage.getItem(PAYSYSTEM_TOKEN);
};

/**************************************************************
 * 결제시스템 인증 API
 **************************************************************/

/**
 * [중요] 결제시스템 인증 요청.
 * 로그인 시 결제시스템에 대한 로그인 작업도 같이 수행함.
 * 로그인 후 결제시스템 토큰 refresh 하기 위하여 주기적으로 수행하게 된다.
 * @param userid
 * @returns
 */
export const authPaySystem = async (userid?: string) => {
  let curUserid = 'schneider@gmail.com';
  if (!userid) {
    const localUserid = localStorage.getItem(PAYSYSTEM_MEMID);
    if (!localUserid) {
      console.error('userid not found !!!');
      return;
    }
    curUserid = localUserid ? localUserid : '';
  } else {
    curUserid = userid;
    localStorage.setItem(PAYSYSTEM_MEMID, userid);
  }
  const userStr = 'anonymous_' + curUserid;
  const base64Userid = window.btoa(userStr);
  return await paySystemAxios.get(`${nameSpaceAuth}/${base64Userid}`);
};

/**
 * 유효성 검사, 새로운 토큰을 발급함.
 * 하지만 유효기간이 지나면 아무 쓸모 없는 메소드이므로 사용하지 않는다.
 * @param authtoken
 * @returns
 */
export const reqValidatePaySystem = async (authtoken: any) => {
  console.log(
    '[authPaySystem] auth-username:' + ENV.REACT_APP_PAYSYS_AUTH_USER
  );
  console.log('[authPaySystem] auth-password:' + ENV.REACT_APP_PAYSYS_AUTH_PW);
  const headersValues = { 'auth-token': authtoken };
  return await paySystemAxios.post(`${nameSpaceValidate}`, {
    headers: headersValues,
  });
};

interface AccountCreateTypes {
  name: string;
  email: string;
  mobile: string;
  password: string;
}

/**
 * 회원가입을 요청한다.
 * @param email
 * @param name
 * @param mobile
 * @param password
 * @returns
 */
export const reqAccountCreatePaySystem = async (args: AccountCreateTypes) => {
  return await paySystemAxios.post(`${nameSpaceAccountCreate}`, {
    signUpPathCode: 'A3022',
    ...args,
  });
};

interface AccountMobileTypes {
  userId: string;
  mobile: string;
}
/**
 * 회원의 전화번호를 수정한다.
 * @param userId
 * @param mobile
 * @returns
 */
export const reqAccountModifyMobilePaySystem = async (
  args: AccountMobileTypes
) => {
  const base64Userid = window.btoa('anonymous_' + args.userId);
  const body = {
    memberId: args.userId,
    mobile: args.mobile,
  };
  const authtoken = getPayInfoToken();
  const headersValues = { 'auth-token': authtoken };

  return await paySystemAxios.put(`${nameSpaceAccountModify}/${base64Userid}`, {
    data: JSON.stringify(body),
    headers: headersValues,
  });
};

/**
 * 회원의 비밀번호를 수정한다.
 * [주의] 회원의 비밀번호를 변경한 후에는 token을 다시 발급 받아야한다.
 * @param userId
 * @param passwd
 * @returns
 */
export const reqAccountModifyPasswdPaySystem = async (
  userId: string,
  passwd: string
) => {
  console.log(
    '[reqAccountModifyPaySystem] userId:' + userId + ', passwd:' + passwd
  );
  const base64Userid = window.btoa(userId);
  const body = {
    memberId: userId,
    password: passwd,
  };
  console.log('[reqAccountModifyPaySystem] body:' + JSON.stringify(body));
  // const headers = { 'auth': {
  //   username: process.env.REACT_APP_PAYSYS_AUTH_USER,
  //   password: process.env.REACT_APP_PAYSYS_AUTH_PW
  // } };

  return await paySystemAxios.post(
    `${nameSpaceAccountModify}/${base64Userid}`,
    {
      data: JSON.stringify(body),
    }
  );
};

/**
 * 회원상태를 변경한다.
 * @param userId
 * @param mode
 * @returns
 */
export const reqAccountStatusPaySystem = async (
  userId: string,
  mode: string
) => {
  const base64Userid = window.btoa(userId);
  const body = {
    statusCode: mode,
  };

  return await paySystemAxios.put(
    `${nameSpaceAccountStatus}/${base64Userid}/updatestatus`,
    {
      data: JSON.stringify(body),
    }
  );
};

/**************************************************************
 * 결제시스템 결제 API
 **************************************************************/

/**
 * 사용자 가입(구독) 정보를 가져온다.
 */
export const reqGetSubscribeList = async (comSeq?: string) => {
  let companySeq = '';
  if (comSeq) {
    companySeq = comSeq;
  } else {
    let tSeq = getLocalCompanySeq();
    if (tSeq === undefined) {
      console.error(
        '[reqGetSubscribeList] not found CompanySeq at localstorage'
      );
    } else {
      companySeq = tSeq;
    }
  }
  return await paySystemAxios.get(`${nameSpaceSubscribeList}/${companySeq}`);
};

interface PaymentUsageTypes {
  page?: number; // 페이지 번호
  size?: number; // 페이지 사이즈
  order?: string; // ASC, DESC
  from?: string; // 20230101
  to?: string; // 20230330
}
/**
 * 사용자의 결제내역 조회
 * @param args : PaymentUsageTypes pagination 정보
 * @param comSeq : 회사ID
 * @returns
 */
export const reqGetPaymentUsageList = async (
  args: PaymentUsageTypes,
  comSeq?: string
) => {
  let companySeq = '';
  if (comSeq) {
    companySeq = comSeq;
  } else {
    let tSeq = getLocalCompanySeq();
    if (tSeq === undefined) {
      console.error(
        '[reqGetPaymentUsageList] not found CompanySeq at localstorage'
      );
    } else {
      companySeq = tSeq;
    }
  }

  // only test landhyun15@satlux.com
  //companySeq = '399';

  return await paySystemAxios.get(
    `${nameSpacePaymentUsageList}/${companySeq}`,
    {
      params: {
        ...args,
        'solution-type': 'B13',
      },
    }
  );
};

/**
 * 현재 크레딧 잔액 조회를 수행한다.
 * @param comSeq
 * @returns
 */
export const reqGetCurrentCredit = async (comSeq?: string) => {
  let companySeq = '';
  if (comSeq) {
    companySeq = comSeq;
  } else {
    let tSeq = getLocalCompanySeq();
    if (tSeq === undefined) {
      console.error(
        '[reqGetCurrentCredit] not found CompanySeq at localstorage'
      );
    } else {
      companySeq = tSeq;
    }
  }
  return await paySystemAxios.get(`${nameSpaceCurrentCredit}/${companySeq}`);
};

/**************************************************************
 * 결제시스템 크레딧 API
 **************************************************************/

/**
 * 크레딧을 차감한다. => 성공
 * @param comSeq : companySeq
 * @returns
 */
export const reqCreditMinus = async (
  itemCd: string,
  itemName: string,
  credit: number,
  comSeq?: string
) => {
  let companySeq = '';
  if (comSeq) {
    companySeq = comSeq;
  } else {
    let tSeq = getLocalCompanySeq();
    if (tSeq === undefined) {
      alert('[setCreditMinus] not found CompanySeq at localstorage');
    } else {
      companySeq = tSeq;
    }
  }
  const body = {
    solutionType: 'B13',
    fkCompany: companySeq,
    items: [
      {
        itemCd: itemCd,
        itemName: itemName,
        itemCnt: 1,
        credit: credit,
      },
    ],
  };

  return await paySystemAxios.post(`${nameSpaceMinusCredit}`, body);
};
