export default function ChevronDownIcon({ isMain }: { isMain?: boolean }) {
  return (
    <svg
      width="12"
      height="8"
      viewBox="0 0 12 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 5.05916L10.6669 0.117619L12 1.5292L6 7.88232L-2.77704e-07 1.5292L1.33312 0.117619L6 5.05916Z"
        fill={isMain ? 'white' : '#222222'}
      />
    </svg>
  );
}
