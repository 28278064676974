import 'aos/dist/aos.css';
import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import SonMainSection from '../../components/son/MainSection';
import SonFifthSection from '../../components/son/SonFifthSection';
import SonFourthSection from '../../components/son/SonFourthSection';
import SonSecondSection from '../../components/son/SonSecondSection';
import SonSixthSection from '../../components/son/SonSixthSection';
import SonThirdSection from '../../components/son/SonThirdSection';
import { userinfoAtom } from '../../store';

export default function SonPage() {
  const { language } = useRecoilValue(userinfoAtom);
  const isEnglish = language === 'en';

  useEffect(() => {
    var AOS = require('aos');
    AOS.init({
      duration: 1000,
    });
  }, []);

  return (
    <main className="bg-black pt-[140px] max-md:pt-[98px] px-5 pb-10 overflow-hidden">
      <div className="max-w-[1600px] max-2xl:max-w-[1200px] mx-auto">
        <div data-aos="fade-up">
          <SonMainSection isEnglish={isEnglish} />
        </div>
        <div data-aos="fade-up">
          <SonSecondSection isEnglish={isEnglish} />
        </div>
        <div data-aos="fade-up">
          <SonThirdSection isEnglish={isEnglish} />
        </div>
        <div data-aos="fade-up">
          <SonFourthSection isEnglish={isEnglish} />
        </div>
        <div data-aos="fade-up">
          <SonFifthSection isEnglish={isEnglish} />
        </div>
        <div data-aos="fade-up">
          <SonSixthSection isEnglish={isEnglish} />
        </div>
        <div data-aos="fade-up">
          <BottomBanner isEnglish={isEnglish} />
        </div>
      </div>
    </main>
  );
}

function BottomBanner({ isEnglish }: { isEnglish: boolean }) {
  const onClickAppStore = () => {
    console.log(`app store clicked`)
    window.open('https://apps.apple.com/kr/app/%EC%86%90%EB%B9%84%EC%84%9C/id6449721341', '_blank');
  }

  const onClickGooglePlay = () => {
    window.open('https://play.google.com/store/apps/details?id=com.ploonet.aihandy', '_blank');
  }

  return (
    <div className="w-full h-[196px] md:h-[219px] px-5 md:px-16 flex max-lg:flex-col max-lg:space-y-5 items-start lg:items-center justify-center lg:justify-between rounded-xl bg-gradient-to-br from-[#ED9900] via-[#FF7376] to-[#FF6C7B]">
      {isEnglish ? (
        <div className="text-white text-xl md:text-3xl 2xl:text-[40px]">
          <span className="font-extralight">Meet </span>
          <span className="font-semibold">your own AI assistant </span>
          <br className="md:hidden" />
          <span className="font-extralight">right now!</span>
        </div>
      ) : (
        <div className="text-white text-xl md:text-3xl 2xl:text-[40px]">
          <span className="font-semibold">나만의 AI비서, </span>
          <br className="md:hidden" />
          <span className="font-extralight">지금 바로 만나보세요!</span>
        </div>
      )}
      <div className={`flex space-x-4`}>
        <button onClick={onClickAppStore}>
          <img
            className="w-[120px] h-[36px] md:w-[200px] md:h-[59px]"
            src="/img/appstore.png"
            alt="app store link button"
          />
        </button>
        <button onClick={onClickGooglePlay}>
          <img
            className="w-[120px] h-[36px] md:w-[200px] md:h-[59px]"
            src="/img/googleplay.png"
            alt="google play link button"
          />
        </button>
      </div>
    </div>
  );
}
