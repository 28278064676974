export default function UnionIcon() {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.5 7C8.84315 7 7.5 5.65685 7.5 4C7.5 2.34315 8.84315 1 10.5 1C12.1569 1 13.5 2.34315 13.5 4C13.5 5.65685 12.1569 7 10.5 7Z"
        stroke="url(#paint0_linear_1726_100865)"
        strokeWidth="1.5"
      />
      <path
        d="M4 19C2.34315 19 1 17.6569 1 16C1 14.3431 2.34315 13 4 13C5.65685 13 7 14.3431 7 16C7 17.6569 5.65685 19 4 19Z"
        stroke="url(#paint1_linear_1726_100865)"
        strokeWidth="1.5"
      />
      <path
        d="M17 19C15.3431 19 14 17.6569 14 16C14 14.3431 15.3431 13 17 13C18.6569 13 20 14.3431 20 16C20 17.6569 18.6569 19 17 19Z"
        stroke="url(#paint2_linear_1726_100865)"
        strokeWidth="1.5"
      />
      <path
        d="M18.5 11C18.5 8.61063 17.4525 6.46589 15.7916 5M2.5 11C2.5 8.61063 3.54752 6.46589 5.20838 5M8.5 18.748C9.13924 18.9125 9.8094 19 10.5 19C11.1906 19 11.8608 18.9125 12.5 18.748"
        stroke="url(#paint3_linear_1726_100865)"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1726_100865"
          x1="13.5"
          y1="1"
          x2="6.48894"
          y2="5.38253"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF478B" />
          <stop offset="1" stopColor="#FFBB54" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1726_100865"
          x1="7"
          y1="13"
          x2="-0.0110614"
          y2="17.3825"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF478B" />
          <stop offset="1" stopColor="#FFBB54" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1726_100865"
          x1="20"
          y1="13"
          x2="12.9889"
          y2="17.3825"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF478B" />
          <stop offset="1" stopColor="#FFBB54" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_1726_100865"
          x1="2.5"
          y1="5"
          x2="12.0784"
          y2="22.5123"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF478B" />
          <stop offset="1" stopColor="#FFBB54" />
        </linearGradient>
      </defs>
    </svg>
  );
}
