import { Link, useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { slideIndex } from '../../../store';
import Button from '../../common/Buttons';
import OrangeArrowIcon from '../../vector/myInfo/orange-arrow-icon';

export default function QuickError() {
  const setIndex = useSetRecoilState(slideIndex);
  const navigate = useNavigate();

  return (
    <>
      <section>
        <div className="bg-gray5 rounded-xl py-8 md:py-10 text-center font-semibold leading-6 md:leading-[32px]">
          <h1>죄송합니다.</h1>
          <h1>회원가입에 실패하였습니다.</h1>
          <h1>다시 시도해주시기 바랍니다.</h1>
        </div>

        <div className="w-full flex flex-col space-y-4 justify-center items-center mt-5">
          <p>
            계속해서 실패하신다면 <strong>'문의하기'</strong>를 이용해 문의하여
            주시기 바랍니다.
          </p>
          <button
            onClick={() => {
              navigate('/');
              setIndex(6);
            }}
            className="w-fit text-primary font-bold flex items-center space-x-1 group"
          >
            <span>문의하기</span>
            <OrangeArrowIcon isBig />
          </button>{' '}
          <Link to={`/`}>
            <Button>홈으로 이동</Button>
          </Link>
        </div>
      </section>
    </>
  );
}
