export default function MouseClickIcon() {
  return (
    <svg
      className="w-[100px] h-[100px] max-md:w-[72px] max-md:h-[72px]"
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="50" cy="50" r="50" fill="#222222" />
      <path
        d="M50.5 75C60.6873 75 69 66.575 69 56.25V43.75C69 33.425 60.6873 25 50.5 25C40.3127 25 32 33.425 32 43.75V56.25C32 66.575 40.3127 75 50.5 75Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M51 35V25"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M51 48C48.7867 48 47 46.258 47 44.1V38.9C47 36.742 48.7867 35 51 35C53.1867 35 55 36.742 55 38.9V44.1C55 46.258 53.1867 48 51 48Z"
        fill="url(#paint0_linear_1619_100535)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1619_100535"
          x1="47"
          y1="35"
          x2="57.5618"
          y2="45.3978"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF478B" />
          <stop offset="1" stopColor="#FFBB54" />
        </linearGradient>
      </defs>
    </svg>
  );
}
