export default function MainLogo({ isDark }: { isDark: boolean }) {
  return (
    <svg
      width="195"
      height="32"
      viewBox="0 0 195 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_167_6342)">
        <path
          d="M68.9782 11.8083C68.9782 16.0839 66.1222 19.4576 61.2955 19.4576H58.1055C57.2037 19.4576 56.4688 20.1924 56.4688 21.0943V25.7206C56.4688 26.6225 55.7339 27.3574 54.8321 27.3574C53.9302 27.3574 53.1953 26.6225 53.1953 25.7206V17.8375C53.1953 16.9357 53.9302 16.2008 54.8321 16.2008H61.3289C63.7005 16.2008 65.7047 14.9816 65.7047 12.0254C65.7047 9.06926 63.5001 7.78325 61.1118 7.78325H54.8321C53.9302 7.78325 53.1953 7.04839 53.1953 6.14651C53.1953 5.24463 53.9302 4.50977 54.8321 4.50977H60.2767C66.2225 4.50977 68.9782 7.49933 68.9782 11.8083Z"
          fill={isDark ? 'white' : '#222222'}
        />
        <path
          d="M74.3711 4.50977C75.273 4.50977 76.0079 5.24463 76.0079 6.14651V25.7039C76.0079 26.6058 75.273 27.3407 74.3711 27.3407C73.4692 27.3407 72.7344 26.6058 72.7344 25.7039V6.14651C72.7344 5.24463 73.4692 4.50977 74.3711 4.50977ZM77.8617 25.7039C77.8617 24.802 78.5966 24.0672 79.4985 24.0672H83.6237C84.5256 24.0672 85.2605 24.802 85.2605 25.7039C85.2605 26.6058 84.5256 27.3407 83.6237 27.3407H79.4985C78.5966 27.3407 77.8617 26.6058 77.8617 25.7039Z"
          fill={isDark ? 'white' : '#222222'}
        />
        <path
          d="M157.762 25.7537V6.22964C157.762 5.51148 157.345 4.82672 156.66 4.6096C155.541 4.25887 154.506 5.07724 154.506 6.16284V19.023C154.506 19.4739 154.205 19.8914 153.754 20.0083C153.387 20.1086 152.986 19.9916 152.735 19.7077L141.245 6.81419C140.777 6.27975 139.992 6.04593 139.357 6.32985C138.272 6.81419 138.055 8.11691 138.773 8.91858L154.823 26.9562C155.04 27.2401 155.341 27.4572 155.692 27.5741C155.725 27.5741 155.758 27.5908 155.775 27.6075C155.825 27.6075 155.859 27.6075 155.909 27.6242C155.975 27.6242 156.059 27.6409 156.126 27.6409C156.126 27.6409 156.142 27.6409 156.159 27.6409C156.543 27.6409 156.927 27.5073 157.245 27.2401C157.679 26.8559 157.846 26.3048 157.779 25.7871L157.762 25.7537Z"
          fill={isDark ? 'white' : '#222222'}
        />
        <path
          d="M141.427 13.9124C140.442 12.9271 138.738 13.6118 138.738 15.0147V25.7705C138.738 26.639 139.44 27.3404 140.308 27.3404H140.425C141.294 27.3404 141.995 26.639 141.995 25.7705V15.1484C141.995 14.7308 141.828 14.33 141.544 14.0461L141.427 13.9291V13.9124Z"
          fill={isDark ? 'white' : '#222222'}
        />
        <path
          d="M179.559 6.14651V6.0797C179.559 5.21123 180.26 4.50977 181.129 4.50977H193.22C194.089 4.50977 194.79 5.21123 194.79 6.0797V6.14651C194.79 7.01498 194.089 7.71645 193.22 7.71645H181.129C180.26 7.71645 179.559 7.01498 179.559 6.14651ZM185.538 25.7707V11.1402C185.538 10.2718 186.239 9.57031 187.108 9.57031H187.225C188.093 9.57031 188.794 10.2718 188.794 11.1402V25.7707C188.794 26.6392 188.093 27.3407 187.225 27.3407H187.108C186.239 27.3407 185.538 26.6392 185.538 25.7707Z"
          fill={isDark ? 'white' : '#222222'}
        />
        <path
          d="M97.1882 4.22559C90.7247 4.22559 85.4805 9.46985 85.4805 15.9333C85.4805 22.3968 90.7247 27.641 97.1882 27.641C103.652 27.641 108.896 22.3968 108.896 15.9333C108.896 9.46985 103.652 4.22559 97.1882 4.22559ZM97.1882 24.3675C92.5285 24.3675 88.754 20.593 88.754 15.9333C88.754 11.2736 92.5285 7.49907 97.1882 7.49907C101.848 7.49907 105.622 11.2736 105.622 15.9333C105.622 20.593 101.848 24.3675 97.1882 24.3675Z"
          fill={isDark ? 'white' : '#222222'}
        />
        <path
          d="M123.391 4.22559C116.928 4.22559 111.684 9.46985 111.684 15.9333C111.684 22.3968 116.928 27.641 123.391 27.641C129.855 27.641 135.099 22.3968 135.099 15.9333C135.099 9.46985 129.855 4.22559 123.391 4.22559ZM123.391 24.3675C118.732 24.3675 114.957 20.593 114.957 15.9333C114.957 11.2736 118.732 7.49907 123.391 7.49907C128.051 7.49907 131.826 11.2736 131.826 15.9333C131.826 20.593 128.051 24.3675 123.391 24.3675Z"
          fill={isDark ? 'white' : '#222222'}
        />
        <path
          d="M175.533 4.50977H163.324C162.42 4.50977 161.688 5.23882 161.688 6.13816C161.688 7.03749 162.42 7.76655 163.324 7.76655H175.533C176.437 7.76655 177.17 7.03749 177.17 6.13816C177.17 5.23882 176.437 4.50977 175.533 4.50977Z"
          fill={isDark ? 'white' : '#222222'}
        />
        <path
          d="M175.533 24.0674H163.324C162.42 24.0674 161.688 24.7964 161.688 25.6958C161.688 26.5951 162.42 27.3242 163.324 27.3242H175.533C176.437 27.3242 177.17 26.5951 177.17 25.6958C177.17 24.7964 176.437 24.0674 175.533 24.0674Z"
          fill={isDark ? 'white' : '#222222'}
        />
        <path
          d="M171.742 14.3135H163.324C162.42 14.3135 161.688 15.0425 161.688 15.9419C161.688 16.8412 162.42 17.5703 163.324 17.5703H171.742C172.646 17.5703 173.379 16.8412 173.379 15.9419C173.379 15.0425 172.646 14.3135 171.742 14.3135Z"
          fill={isDark ? 'white' : '#222222'}
        />
        <path
          d="M40.0488 6.84797L35.6397 8.45131C31.9486 1.5369 23.6647 -1.78669 16.0822 0.969052C8.49978 3.72479 4.30771 11.6079 5.91105 19.2739L1.50187 20.8772C0.316062 21.3114 -0.28519 22.6141 0.132346 23.7999C0.549883 24.9858 1.8693 25.587 3.0551 25.1695L7.46429 23.5661C11.1553 30.4805 19.4392 33.8041 27.0217 31.0484C34.5875 28.2926 38.7962 20.4096 37.1929 12.7436L41.6021 11.1402C42.7879 10.706 43.3891 9.40329 42.9716 8.21749C42.5541 7.03168 41.2346 6.43043 40.0488 6.84797ZM25.4518 26.706C19.5394 28.8605 12.9925 25.8041 10.838 19.8918C8.68349 13.9795 11.7399 7.43252 17.6522 5.27803C22.8296 3.39076 28.4914 5.49515 31.2639 10.0212L24.934 12.326C23.7482 12.7603 23.147 14.063 23.5645 15.2488C23.9987 16.4346 25.3014 17.0359 26.4872 16.6183L32.8171 14.3135C33.6021 19.5578 30.6292 24.8187 25.4351 26.706H25.4518Z"
          fill="url(#paint0_linear_167_6342)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_167_6342"
          x1="0.999025"
          y1="1.00049"
          x2="34.499"
          y2="29.5005"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={isDark ? 'white' : '#FF4E83'} />
          <stop offset="1" stopColor={isDark ? 'white' : '#FFBB54'} />
        </linearGradient>
        <clipPath id="clip0_167_6342">
          <rect width="194.789" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
