export default function RenderIcon() {
  return (
    <svg
      className="w-[100px] h-[100px] max-md:w-[72px] max-md:h-[72px]"
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="50" cy="50" r="50" fill="#222222" />
      <path
        d="M48.65 32.0567C48.5062 32.0197 48.3554 32 48.2 32H44C43.421 32 42.8716 32 42.35 32.0017L42.35 32.0123V39.6506L48.65 39.6506L48.65 32.0567Z"
        fill="url(#paint0_linear_1628_100539)"
      />
      <path
        d="M51.35 41.0107L51.35 41.0006L51.35 40.9904V35.6H56C56.579 35.6 57.1284 35.6 57.6501 35.6017L57.65 35.6123V43.2506L51.35 43.2506V41.0107Z"
        fill="url(#paint1_linear_1628_100539)"
      />
      <path
        d="M48.65 42.3506L32.0035 42.3506L32 42.3506L32 53.6C32 54.4131 32 55.1612 32.0072 55.8508L32.0347 55.8506H48.65V42.3506Z"
        fill="url(#paint2_linear_1628_100539)"
      />
      <path
        d="M51.35 57.2106L51.35 57.2006L51.35 57.1905L51.35 45.9506L67.9966 45.9506L68 45.9506L68 57.2C68 58.0131 68 58.7612 67.9928 59.4508L67.9654 59.4506H51.35V57.2106Z"
        fill="url(#paint3_linear_1628_100539)"
      />
      <path
        d="M48.65 58.5506L42.35 58.5506V64.3888L42.35 64.3983C42.8716 64.4 43.421 64.4 44 64.4H48.65L48.65 58.5506Z"
        fill="url(#paint4_linear_1628_100539)"
      />
      <path
        d="M51.35 67.9433C51.4938 67.9803 51.6446 68 51.8 68H56C56.579 68 57.1284 68 57.6501 67.9983L57.65 67.9888V62.1506H51.35V67.9433Z"
        fill="url(#paint5_linear_1628_100539)"
      />
      <path
        d="M39.65 32.0418C36.7526 32.1404 34.9911 32.4713 33.7574 33.5816C32.4073 34.7967 32.0944 36.5806 32.0219 39.6506L39.65 39.6506V32.0418Z"
        fill="url(#paint6_linear_1628_100539)"
      />
      <path
        d="M60.35 43.2506V35.6418C63.2474 35.7404 65.009 36.0713 66.2426 37.1816C67.5927 38.3967 67.9056 40.1806 67.9781 43.2506L60.35 43.2506Z"
        fill="url(#paint7_linear_1628_100539)"
      />
      <path
        d="M67.8907 62.1506H60.35V67.9582C63.2474 67.8596 65.009 67.5287 66.2426 66.4184C67.3038 65.4633 67.7242 64.1568 67.8907 62.1506Z"
        fill="url(#paint8_linear_1628_100539)"
      />
      <path
        d="M39.65 58.5506L39.65 64.3582C36.7526 64.2596 34.9911 63.9287 33.7574 62.8184C32.6962 61.8633 32.2758 60.5568 32.1093 58.5506L39.65 58.5506Z"
        fill="url(#paint9_linear_1628_100539)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1628_100539"
          x1="32"
          y1="32"
          x2="58.2952"
          y2="74.0664"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF478B" />
          <stop offset="1" stopColor="#FFBB54" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1628_100539"
          x1="32"
          y1="32"
          x2="58.2952"
          y2="74.0664"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF478B" />
          <stop offset="1" stopColor="#FFBB54" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1628_100539"
          x1="32"
          y1="32"
          x2="58.2952"
          y2="74.0664"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF478B" />
          <stop offset="1" stopColor="#FFBB54" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_1628_100539"
          x1="32"
          y1="32"
          x2="58.2952"
          y2="74.0664"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF478B" />
          <stop offset="1" stopColor="#FFBB54" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_1628_100539"
          x1="32"
          y1="32"
          x2="58.2952"
          y2="74.0664"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF478B" />
          <stop offset="1" stopColor="#FFBB54" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_1628_100539"
          x1="32"
          y1="32"
          x2="58.2952"
          y2="74.0664"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF478B" />
          <stop offset="1" stopColor="#FFBB54" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_1628_100539"
          x1="32"
          y1="32"
          x2="58.2952"
          y2="74.0664"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF478B" />
          <stop offset="1" stopColor="#FFBB54" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_1628_100539"
          x1="32"
          y1="32"
          x2="58.2952"
          y2="74.0664"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF478B" />
          <stop offset="1" stopColor="#FFBB54" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_1628_100539"
          x1="32"
          y1="32"
          x2="58.2952"
          y2="74.0664"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF478B" />
          <stop offset="1" stopColor="#FFBB54" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_1628_100539"
          x1="32"
          y1="32"
          x2="58.2952"
          y2="74.0664"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF478B" />
          <stop offset="1" stopColor="#FFBB54" />
        </linearGradient>
      </defs>
    </svg>
  );
}
