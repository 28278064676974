export default function CircleArrowDownIcon() {
  return (
    <svg
      className="w-4 h-4"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 0.5C12.416 0.5 16 4.084 16 8.5C16 12.916 12.416 16.5 8 16.5C3.584 16.5 0 12.916 0 8.5C0 4.084 3.584 0.5 8 0.5ZM8 14.9C11.536 14.9 14.4 12.036 14.4 8.5C14.4 4.964 11.536 2.1 8 2.1C4.464 2.1 1.6 4.964 1.6 8.5C1.6 12.036 4.464 14.9 8 14.9ZM8.8 8.5H11.2L8 11.7L4.8 8.5H7.2V5.3H8.8V8.5Z"
        fill="#FF7B51"
      />
    </svg>
  );
}
