export default function DefaultProfileIcon() {
  return (
    <svg
      className="w-[100px] max-md:w-[94px]"
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="50" cy="50" r="50" fill="#DDDDDD" />
      <path
        d="M67 73H33V68.7143C33 65.8727 34.1194 63.1475 36.112 61.1381C38.1046 59.1288 40.8071 58 43.625 58H56.375C59.1929 58 61.8954 59.1288 63.888 61.1381C65.8806 63.1475 67 65.8727 67 68.7143V73ZM50 53.7143C48.3256 53.7143 46.6677 53.3817 45.1208 52.7356C43.5739 52.0895 42.1683 51.1424 40.9844 49.9485C39.8004 48.7546 38.8613 47.3373 38.2205 45.7774C37.5798 44.2175 37.25 42.5456 37.25 40.8571C37.25 39.1687 37.5798 37.4968 38.2205 35.9369C38.8613 34.377 39.8004 32.9597 40.9844 31.7658C42.1683 30.5719 43.5739 29.6248 45.1208 28.9787C46.6677 28.3326 48.3256 28 50 28C53.3815 28 56.6245 29.3546 59.0156 31.7658C61.4067 34.177 62.75 37.4472 62.75 40.8571C62.75 44.2671 61.4067 47.5373 59.0156 49.9485C56.6245 52.3597 53.3815 53.7143 50 53.7143Z"
        fill="white"
      />
    </svg>
  );
}
