export default function UsageVoiceIcon() {
  return (
    <svg
      width="90"
      height="90"
      viewBox="0 0 90 90"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="45" cy="45" r="45" fill="white" />
      <path
        d="M48.5 64C59.5 62.5 63.5 58.5 64 51.5"
        stroke="url(#paint0_linear_1240_89846)"
        strokeWidth="1.2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <circle
        cx="49.5"
        cy="63.5"
        r="4.5"
        fill="url(#paint1_linear_1240_89846)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25 39.8824C25 36.6336 26.6927 34 29.9414 34H32.9414C36.1901 34 37.9412 36.6336 37.9412 39.8824L37.9412 50.4706C37.9412 53.7193 36.1901 56 32.9414 56H29.9414C26.6927 56 25 53.7193 25 50.4706L25 39.8824ZM29.9414 35.5C27.9922 35.5 26.4414 37.9331 26.4414 39.8824V50.4706C26.4414 52.4198 27.9922 54.5 29.9414 54.5H32.9414C34.8906 54.5 36.4414 52.4492 36.4414 50.5V40C36.4414 38.0508 34.8906 35.5 32.9414 35.5H29.9414Z"
        fill="#FF6D51"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M52.0586 39.8824C52.0586 36.6336 53.7513 34 57 34H60C63.2487 34 64.9998 36.6336 64.9998 39.8824L64.9998 50.4706C64.9998 53.7193 63.2487 56 60 56H57C53.7513 56 52.0586 53.7193 52.0586 50.4706L52.0586 39.8824ZM57 35.5C55.0508 35.5 53.5 37.9331 53.5 39.8824V50.4706C53.5 52.4198 55.0508 54.5 57 54.5H60C61.9492 54.5 63.5 52.4492 63.5 50.5V40C63.5 38.0508 61.9492 35.5 60 35.5H57Z"
        fill="#FF6D51"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M42.6471 23.5C34.2004 23.5 26.5 31.2003 26.5 39.6471V50.2353L25 50.2353V39.6471C25 29.9009 32.9009 22 42.6471 22L47.3529 22C57.0991 22 65 29.9009 65 39.6471V50.2353L63.5625 50.2353L63.5 39.6471C63.5 31.2004 55.9467 23.5 47.5 23.5H42.6471Z"
        fill="#FF6D51"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1240_89846"
          x1="48.5"
          y1="51.5"
          x2="60.7163"
          y2="66.6482"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF4E83" />
          <stop offset="1" stopColor="#FFBB54" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1240_89846"
          x1="45"
          y1="59"
          x2="54"
          y2="68"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF4E83" />
          <stop offset="1" stopColor="#FFBB54" />
        </linearGradient>
      </defs>
    </svg>
  );
}
