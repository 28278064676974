import { Dialog, Transition } from '@headlessui/react';
import { Dispatch, Fragment, SetStateAction, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { cancelMembership } from '../../../services/PrivateService';
import { siteLangCd } from '../../../services/defaultClient';
import { userinfoAtom } from '../../../store';
import Loader from '../../common/Loader';
import InformModal from '../../common/modal/InformModal';
import { getDaysDiff } from '../../hooks/useTime';

export default function ProductionCancelModal({
  closeModal,
  prodCancel,
  itemName,
  limitDtTo,
  cost,
  creditPolicy,
  creditPolicyFree,
  creditFree,
  credit,
  creditUsedRate,
  costPayback,
  detailId,
  setDeleteKey,
}: {
  closeModal: React.Dispatch<SetStateAction<boolean>>;
  prodCancel: boolean;
  itemName: string;
  limitDtTo: string;
  cost: number;
  creditPolicy: number;
  creditPolicyFree: number;
  creditFree: number;
  credit: number;
  creditUsedRate: number;
  costPayback: number;
  detailId: number;
  setDeleteKey: Dispatch<SetStateAction<number>>;
}) {
  const [loading, setLoading] = useState(false);
  const [informModal, setInforModal] = useState({
    isOpen: false,
    phrase: '',
    content: '',
  });
  const { language } = useRecoilValue(userinfoAtom);
  const initialRef = useRef(null);
  const { t } = useTranslation();

  const cancelProduction = async () => {
    setLoading(true);
    cancelMembership({
      detailId,
      siteLangCd,
    })
      .then((res) => {
        if (res.data.statusCd === 'OK') {
          setInforModal({
            isOpen: true,
            phrase: '취소신청이 완료되었습니다.',
            content: '결제 방식에 따라 최대 3일 정도 소요될 수 있습니다.',
          });
          setDeleteKey((prev) => prev + 1);
        } else {
          setInforModal({
            isOpen: true,
            phrase: '오류가 발생했습니다.',
            content: '잠시 후 다시 시도해주세요.',
          });
        }
      })
      .catch(() => {
        setInforModal({
          isOpen: true,
          phrase: '오류가 발생했습니다.',
          content: '잠시 후 다시 시도해주세요.',
        });
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      <Loader loading={loading} />
      <InformModal
        modal={informModal.isOpen}
        phrase={informModal.phrase}
        content={informModal.content}
        closeModal={() => {
          closeModal(false);
          setInforModal((prev) => ({
            ...prev,
            isOpen: false,
          }));
        }}
      />
      {!informModal.isOpen && (
        <Transition appear show={prodCancel} as={Fragment}>
          <Dialog
            initialFocus={initialRef}
            as="div"
            className="fixed z-50"
            onClose={closeModal}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black bg-opacity-25" />
            </Transition.Child>

            <div
              className={`fixed inset-0 ${
                !informModal.isOpen && 'max-md:bottom-0 max-md:inset-auto'
              } overflow-y-auto max-md:w-full`}
            >
              <div className="flex min-h-full items-center justify-center p-4 max-md:p-0 text-center">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Dialog.Panel className="relative w-full focus:top-0 max-w-[496px] overflow-y-scroll max-h-[740px] max-md:max-h-[600px] max-md:overflow-y-scroll inquiry-detail px-8 pt-10 max-md:px-5 max-md:pt-8 transform overflow-hidden rounded-2xl bg-white transition-all">
                    <div
                      onClick={() => closeModal(false)}
                      className="w-[60px] h-1 rounded-full bg-gray2 absolute right-1/2 translate-x-1/2 top-2 md:hidden"
                    />
                    <Dialog.Title
                      as="h3"
                      className="flex flex-col text-xl font-bold text-left"
                    >
                      <span ref={initialRef} className="text-black2">
                        {t('사용 중인 상품 요금제')}{' '}
                        <br className="hidden max-md:block" />{' '}
                        {t('취소 신청을 하시겠습니까?')}
                      </span>
                      <div className="text-lg mt-3 max-md:text-base">
                        <p className="text-black2 font-normal">
                          {t(
                            '취소신청 하시면 이용 중인 요금제를 더 이상 이용할 수 없으며 남아있는 크레딧은 자동 소멸됩니다.'
                          )}
                        </p>
                      </div>
                    </Dialog.Title>
                    <section className="py-5 px-6 max-md:py-4 max-md:px-5 mt-7 max-md:mt-5 max-md:p-4 max-md:text-sm bg-gray5 rounded-lg text-left space-y-2">
                      <ul className="text-black5 space-y-5 max-md:space-y-3">
                        <li>
                          <div className="flex items-center justify-between">
                            <span className="text-black4">
                              {t('사용중인 요금제')}
                            </span>
                            <span className="text-lg max-md:text-base text-black2">
                              {t(itemName)}
                            </span>
                          </div>
                        </li>
                        <li>
                          <div className="flex items-center justify-between">
                            <span className="text-black4">
                              {t('남은 사용기간')}
                            </span>
                            <span className="text-lg text-black2">{`${String(
                              getDaysDiff(limitDtTo)
                            ).replace('-', '')}${t('일')}`}</span>
                          </div>
                        </li>
                        <li>
                          <div className="flex items-center justify-between">
                            <span className="text-black4">
                              {t('결제 금액(부가세 포함)')}
                            </span>
                            <span className="text-lg text-black2">
                              {language === 'kr'
                                ? `${(cost * 1.1).toLocaleString()}원`
                                : `KRW ${(cost * 1.1).toLocaleString()}`}
                            </span>
                          </div>
                        </li>
                      </ul>
                    </section>
                    <section className="py-5 px-6 max-md:py-4 max-md:px-5 mt-5 border border-gray3 rounded-lg">
                      <div className="border-b border-gray3 pb-4 mb-4 max-md:pb-3 max-md:mb-3 font-bold text-lg max-md:text-base text-left">
                        {t('크레딧 내역')}
                      </div>
                      <section className="flex flex-col space-y-4 max-md:space-y-2">
                        <div className="flex items-center justify-between font-bold text-black2 max-md:text-base">
                          <span>{t('총 지급 크레딧')}</span>
                          <span className="text-lg max-md:text-base">
                            {(creditPolicy + creditPolicyFree).toLocaleString()}
                          </span>
                        </div>
                        <div className="flex items-center justify-between text-black2 max-md:text-base">
                          <span>ㄴ {language === 'en' ? 'Paid' : '결제'}</span>
                          <span className="text-lg max-md:text-base">
                            {creditPolicy.toLocaleString()}
                          </span>
                        </div>
                        <div className="flex items-center justify-between text-black2 max-md:text-base">
                          <span>ㄴ {t('보너스')}</span>
                          <span className="text-lg max-md:text-base">
                            {creditPolicyFree.toLocaleString()}
                          </span>
                        </div>
                        <div className="flex items-center justify-between font-bold text-black2 max-md:text-base">
                          <span>{t('사용 크레딧')}</span>
                          <span className="text-lg max-md:text-base">
                            {(
                              creditPolicy +
                              creditPolicyFree -
                              (credit + creditFree)
                            ).toLocaleString()}
                          </span>
                        </div>
                        <div className="flex items-center justify-between font-bold text-primary max-md:text-base">
                          <span>{t('환불 가능 크레딧')}</span>
                          <span className="text-lg max-md:text-base">
                            {Math.floor(
                              credit - credit * (creditUsedRate / 100)
                            ).toLocaleString()}
                          </span>
                        </div>
                      </section>
                    </section>

                    <section className="py-5 px-6 mt-5 max-md:py-4 max-md:px-5 border border-gray3 rounded-lg">
                      <div className="flex items-center justify-between text-lg font-bold text-black2 max-md:text-base">
                        <span>{t('환불예정금액')}</span>
                        <span>
                          {language === 'kr'
                            ? `${costPayback.toLocaleString()}원`
                            : `KRW ${costPayback.toLocaleString()}`}
                        </span>
                      </div>
                    </section>

                    <section className="text-left mt-5">
                      <h3 className="font-bold text-black4 mb-1">
                        {t('상품 요금제 취소하기 유의사항')}
                      </h3>
                      <ul className="text-black5 text-sm list-disc marker:text-black5 ml-5">
                        <li>
                          {t(
                            '플루닛 요금제 결제 시 지급된 보너스 크레딧은 취소신청 시 자동 소멸 됩니다.'
                          )}
                        </li>
                        <li>
                          {t(
                            '결제 방식에 따라 최대 3일 정도 소요될 수 있습니다.'
                          )}
                        </li>
                        <li>
                          {t(
                            '취소 요청이 거절될 경우, 고객센터로 별도 문의주시기 바랍니다.'
                          )}
                        </li>
                      </ul>
                    </section>

                    <section className="mt-5 pb-5 space-x-2 flex sticky bottom-0 bg-white">
                      <button
                        className="border border-[#cccccc] w-full py-[18px] rounded-lg hover:bg-gray5 duration-100 ease-out text-lg font-bold max-md:text-base"
                        onClick={() => closeModal(false)}
                      >
                        {t('취소')}
                      </button>
                      <button
                        className="w-full text-white font-bold text-lg bg-gradient-to-br from-primary to-primaryTo hover:from-hoverPrimary hover:to-hoverPrimaryTo rounded-lg max-md:text-base"
                        onClick={cancelProduction}
                      >
                        {t('취소신청하기')}
                      </button>
                    </section>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition>
      )}
    </>
  );
}
