export default function CutIcon() {
  return (
    <svg
      className="w-[100px] h-[100px] max-md:w-[72px] max-md:h-[72px]"
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="50" cy="50" r="50" fill="#222222" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40 32C35.5817 32 32 35.5817 32 40V60C32 64.4183 35.5817 68 40 68H60C64.4183 68 68 64.4183 68 60V40C68 35.5817 64.4183 32 60 32H40ZM60.8405 56.3629C61.1788 55.7612 60.9653 54.9991 60.3635 54.6607L51.6298 49.7503L60.3635 44.8399C60.9653 44.5016 61.1788 43.7395 60.8405 43.1377C60.5021 42.536 59.74 42.3224 59.1383 42.6607L49.0792 48.3163L45.2692 46.1742C46.3195 45.4004 47.0009 44.1549 47.0009 42.7503C47.0009 40.4031 45.0981 38.5003 42.7509 38.5003C40.4037 38.5003 38.5009 40.4031 38.5009 42.7503C38.5009 44.3639 39.4002 45.7675 40.7249 46.4873L46.5286 49.7503L40.7249 53.0134C39.4002 53.7331 38.5009 55.1367 38.5009 56.7503C38.5009 59.0975 40.4037 61.0003 42.7509 61.0003C45.0981 61.0003 47.0009 59.0975 47.0009 56.7503C47.0009 55.3457 46.3195 54.1002 45.2692 53.3265L49.0792 51.1843L59.1383 56.8399C59.74 57.1783 60.5021 56.9647 60.8405 56.3629ZM41.8917 44.2753C41.3599 43.975 41.0009 43.4046 41.0009 42.7503C41.0009 41.7838 41.7844 41.0003 42.7509 41.0003C43.7174 41.0003 44.5009 41.7838 44.5009 42.7503C44.5009 43.7168 43.7174 44.5003 42.7509 44.5003C42.4409 44.5003 42.1498 44.4197 41.8972 44.2784L41.8917 44.2753ZM41.8917 55.2254C41.3599 55.5257 41.0009 56.0961 41.0009 56.7503C41.0009 57.7168 41.7844 58.5003 42.7509 58.5003C43.7174 58.5003 44.5009 57.7168 44.5009 56.7503C44.5009 55.7838 43.7174 55.0003 42.7509 55.0003C42.4409 55.0003 42.1498 55.0809 41.8972 55.2223L41.8917 55.2254Z"
        fill="url(#paint0_linear_1628_100538)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1628_100538"
          x1="32"
          y1="32"
          x2="58.2952"
          y2="74.0664"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF478B" />
          <stop offset="1" stopColor="#FFBB54" />
        </linearGradient>
      </defs>
    </svg>
  );
}
