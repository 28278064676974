import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilState, useRecoilValue } from "recoil";
import SwiperCore from "swiper";
import "swiper/swiper.min.css";
import Cookies from "universal-cookie";
import MainModal from "../../components/common/modal/MainModal";
import MobileMainSection from "../../components/home/MobileMainSection";
import PcMainSection from "../../components/home/PcMainSection";
import { slideIndex, userinfoAtom } from "../../store";

export default function Home() {
  const [swiper, setSwiper] = useState<SwiperCore>();
  const [index, setIndex] = useRecoilState(slideIndex);
  const [mainModal, setMainModal] = useState(false);
  const userInfo = useRecoilValue(userinfoAtom);
  const [t, i18n] = useTranslation("lang", { useSuspense: false });

  if (swiper) {
    if (index >= 0) swiper.slideTo(index);
    setIndex(-1);
  }

  const closeToday = () => {
    const cookies = new Cookies();
    let date = new Date();
    let expireDate = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      23,
      59,
      59
    );
    cookies.set("mainModal", false, {
      path: "/",
      expires: expireDate,
    });
    setMainModal(false);
  };

  if (swiper) {
    if (index >= 0) swiper.slideTo(index);
    setIndex(-1);
  }

  useEffect(() => {
    i18n.changeLanguage(userInfo.language);
    const cookies = new Cookies();
    cookies.get("mainModal") ? setMainModal(false) : setMainModal(true);
  }, []);

  return (
    <>
      <MainModal
        closeToday={closeToday}
        modal={mainModal}
        closeModal={() => setMainModal(false)}
      />
      <PcMainSection />
      <MobileMainSection />
    </>
  );
}
