import 'aos/dist/aos.css';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { photoArray } from '../../array/aboutArray';
import CeoSection from '../../components/about/Ceo';
import CiSection from '../../components/about/Ci';
import HistorySection from '../../components/about/History';
import MissionSection from '../../components/about/Mission';
import PatentSection from '../../components/about/Patent';
import RecruitSection from '../../components/about/Recruit';
import { userinfoAtom } from '../../store';

export default function About() {
  const { t } = useTranslation();
  const { language } = useRecoilValue(userinfoAtom);

  useEffect(() => {
    var AOS = require('aos');
    AOS.init({
      duration: 1000,
      disable: function () {
        var maxWidth = 800;
        return window.innerWidth < maxWidth;
      },
    });
  }, []);

  return (
    <section className="pt-[180px] pb-48 max-md:pt-[98px] max-md:px-5 max-md:pb-24 overflow-hidden">
      <main className="max-w-[1200px] max-2xl:max-w-[1000px] mx-auto inquiry-detail">
        <div
          data-aos="fade"
          className="text-center space-y-8 max-2xl:space-y-7 max-w-[1200px]"
        >
          <h1 className="font-bold text-[50px] max-2xl:text-[40px] max-md:text-2xl">
            {language === 'kr' ? (
              <>
                플루닛은 생성형, 대화형 인공지능 <br className="md:hidden" />{' '}
                기술로
                <br className="max-2xl:block max-md:hidden" /> 세상 모든
                사람들의 일상과
                <br className="md:hidden" /> 함께 하며 더 나은 내일을 만듭니다.
              </>
            ) : (
              <>
                Ploonet: Shaping a better tomorrow with generative and
                conversational AI.
              </>
            )}
          </h1>
          <h3 className="text-2xl max-2xl:text-xl max-md:text-base">
            {t(
              '플루닛은 인공지능 전문 기업 솔트룩스의 자회사로 딥러닝, 메타휴먼, 인공지능 영상생성과 발송 플랫폼,'
            )}
            <br />
            {t(
              'AI 비서 서비스 등을 활용한 다양한 플랫폼과 서비스를 통해 성장하고 있는 AI 기업입니다.'
            )}
          </h3>
        </div>
        <section className="mt-[120px] max-2xl:mt-28 max-md:mt-10 flex space-x-3 w-screen mb-40 max-md:mb-12 -translate-x-52">
          {photoArray.map(({ imgPath }, idx) => (
            <div
              key={`photoflow-${idx}`}
              style={{ backgroundImage: `url(${imgPath})` }}
              className="photo-flow min-w-[480px] max-md:min-w-[300px] max-md:min-h-[170px] min-h-[270px] rounded-3xl bg-contain bg-center bg-no-repeat"
            />
          ))}
        </section>
        <div className="space-y-40 max-2xl:space-y-32 max-md:space-y-20">
          <MissionSection />
          <CeoSection />
          <HistorySection />
          <CiSection />
          <div>
            <PatentSection />
            <RecruitSection />
          </div>
        </div>
      </main>
    </section>
  );
}
