export default function MyPloonianIcon() {
  return (
    <svg
      className="w-[119px] h-[80px] max-md:w-[86px] max-md:h-[59px]"
      width="119"
      height="81"
      viewBox="0 0 119 81"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 60.4785L65 60.4785C67.7614 60.4785 70 58.2399 70 55.4785V20.4785C70 17.7171 67.7614 15.4785 65 15.4785L15 15.4785C12.2386 15.4785 10 17.7171 10 20.4785L10 55.4785C10 58.2399 12.2386 60.4785 15 60.4785Z"
        stroke="#222222"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M50 70.4785H30"
        stroke="#222222"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M95.6477 73.5745C95.3591 72.2467 94.031 68.4364 90.2788 66.4735C86.526 64.5107 79.9642 62.1631 79.9642 62.1631C79.9642 62.1631 79.6177 61.0468 79.1173 60.431C78.6171 59.8152 77.6162 59.3147 77.6162 59.3147V58.1984C77.6162 58.1984 78.4244 57.9677 78.6938 56.6593C78.9633 55.3507 79.0016 52.695 79.0016 52.695C79.0016 52.695 81.1817 52.6472 81.6765 49.6451C82.1712 46.643 81.0993 45.4019 81.0993 45.4019C81.0993 45.4019 82.3888 43.5353 81.8497 37.6851C81.3109 31.8349 76.3846 30.0645 71.458 30.0645C66.5314 30.0645 61.6051 31.8349 61.0663 37.6851C60.5275 43.5349 61.817 45.4019 61.817 45.4019C61.817 45.4019 60.7447 46.643 61.2398 49.6451C61.7349 52.6472 63.9147 52.695 63.9147 52.695C63.9147 52.695 63.953 55.3507 64.2224 56.6593C64.4918 57.9677 65.3001 58.1984 65.3001 58.1984V59.3147C65.3001 59.3147 64.2995 59.8152 63.799 60.431C63.2989 61.0468 62.9524 62.1631 62.9524 62.1631C62.9524 62.1631 56.3903 64.5107 52.6378 66.4735C48.8853 68.4364 47.5575 72.2467 47.2689 73.5745C46.9803 74.9024 47.2689 77.8466 47.2689 77.8466H71.4583H95.6477C95.6477 77.8466 95.9363 74.9024 95.6477 73.5745Z"
        fill="url(#paint0_linear_1240_88130)"
        stroke="#222222"
        strokeWidth="3"
      />
      <path
        d="M114.181 65.0017C113.062 64.2507 110.751 63.1365 109.075 62.4685C106.084 61.2764 105.872 57.6916 107.096 57.4554C109.943 56.9067 110.242 56.433 110.622 55.7985C110.892 55.3472 110.315 54.7938 110.27 53.4567C110.225 52.1196 110.394 47.4493 109.928 45.9647C109.524 44.6825 107.921 39.3326 102.662 39.3326C102.595 39.3309 102.529 39.3301 102.464 39.3301C100.69 39.3301 99.7756 39.9294 99.1342 40.7992C99.1163 40.8037 99.0968 40.8057 99.0794 40.8113C95.2277 42.3508 96.5961 44.6245 95.7332 45.6421C94.8702 46.66 95.099 52.1196 95.0538 53.4567C95.0086 54.7938 94.4317 55.3475 94.7018 55.7985C95.0819 56.433 95.3807 56.9067 98.2277 57.4554C99.4523 57.6916 99.2401 61.2761 96.2489 62.4685C95.3606 62.8227 94.2945 63.3021 93.3213 63.7865C94.1501 64.2155 94.9058 64.6434 95.4672 65.02C99.218 67.5358 99.6794 71.761 99.5364 75.3591C100.597 75.3569 101.654 75.3535 102.662 75.3487C104.67 75.3586 106.871 75.3619 108.912 75.3619C112.996 75.3619 116.443 75.3487 116.443 75.3487C116.866 75.2854 116.928 73.4799 116.954 73.0489C117.147 69.8584 116.813 66.767 114.181 65.0017Z"
        fill="url(#paint1_linear_1240_88130)"
        stroke="#222222"
        strokeWidth="3"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1240_88130"
          x1="91.0485"
          y1="35.7114"
          x2="48.1811"
          y2="39.928"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FB9164" />
          <stop offset="1" stopColor="#F66587" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1240_88130"
          x1="114.698"
          y1="43.5884"
          x2="93.7106"
          y2="44.9212"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FB9164" />
          <stop offset="1" stopColor="#F66587" />
        </linearGradient>
      </defs>
    </svg>
  );
}
