import { t } from 'i18next';
import moment from 'moment';
import { CreditsListClientDataType } from '../../../services/TypeOfPrivateService';
import { getCreditUseStatusName, getServiceName } from '../../../utils/common';
import ServiceTag from '../../common/ServiceTag';
import NoData from '../NoData';

export default function PcCreditCard({
  creditList,
}: {
  creditList: CreditsListClientDataType[];
}) {
  const displayCredit = (
    dispGroupCd: string,
    credit: number,
    freeCredit: number
  ) => {
    return dispGroupCd === 'B20202'
      ? `${(credit + freeCredit).toLocaleString()}`
      : `- ${(credit + freeCredit).toLocaleString()}`;
  };
  return (
    <>
      {creditList.length ? (
        creditList.map(
          (
            {
              solutionType,
              itemName,
              dispGroupCd,
              credit,
              creditFree,
              fdRegdate,
            },
            idx
          ) => (
            <div
              key={`credit-${idx}`}
              className="border border-borderGray p-6 rounded-[10px] flex items-center justify-between max-md:hidden"
            >
              <div className="flex items-center space-x-4 max-2xl:space-x-3 min-w-[36%]">
                <ServiceTag type={solutionType}>
                  {getServiceName(solutionType)}
                </ServiceTag>
                <span className="flex items-center font-bold text-xl">
                  {itemName}
                </span>
              </div>
              <div className="flex items-center w-full">
                <span
                  className={`${
                    dispGroupCd === 'B20202' /* 지급 */
                      ? 'text-black'
                      : 'text-textGray'
                  } font-bold text-xl w-full text-end`}
                >
                  {displayCredit(dispGroupCd, credit, creditFree)}
                </span>
                <span className="text-black w-1/2 text-end">
                  {moment(fdRegdate).format('YYYY.MM.DD HH:mm')}
                </span>
                <span className="text-black w-1/3 text-end">
                  {t(getCreditUseStatusName(dispGroupCd))}
                </span>
              </div>
            </div>
          )
        )
      ) : (
        <NoData />
      )}
    </>
  );
}
