import { useTranslation } from 'react-i18next';
import LinkIcon from '../vector/workcenter/LinkIcon';

type Props = {
  isEnglish: boolean;
};

export default function SonMainSection({ isEnglish }: Props) {
  const { t } = useTranslation();

  const onClickAppStore = () => {
    window.open('https://apps.apple.com/kr/app/%EC%86%90%EB%B9%84%EC%84%9C/id6449721341', '_blank');
  }

  const onClickGooglePlay = () => {
    window.open('https://play.google.com/store/apps/details?id=com.ploonet.aihandy', '_blank');
  }

  return (
    <>
      <section className="relative text-white flex max-lg:flex-col items-start justify-between">
        <div className="w-full lg:max-w-xl 2xl:max-w-[720px] lg:translate-y-5">
          <h5 className="flex items-center font-bold text-xl md:text-3xl 2xl:text-[32px]">
            <img
              className="mr-2 md:mr-3 w-[60px] h-4 md:w-[90px] md:h-6"
              src={'/son/son-logo.png'}
              alt="son logo"
            />
            {isEnglish ? 'Mobile App "AI Handy"' : '손비서'}
          </h5>
          <div className="mt-4 text-2xl md:text-4xl 2xl:text-[52px] leading-9 md:leading-[50px] 2xl:leading-[72px]">
            <h1 className="font-extralight whitespace-nowrap">
              {t('전화 한 통, 기록 한 줄까지')}
            </h1>
            <h1 className="font-semibold 2xl:font-bold 2xl:whitespace-pre-wrap">
              {t('어느 하나 놓치지 않는')}
              <br className="lg:hidden" /> {t('나만의 AI 비서')}
            </h1>
          </div>
          <p className="max-w-xl 2xl:max-w-2xl mt-4 md:mt-8 text-white/60 md:text-lg 2xl:text-[20px] lg:leading-7 2xl:leading-8">
            {t(
              '내가 운전 중일때는 부재중 전화도 읽어주고 맛집,날씨,이런저런 대화도 하고 통화녹음도 해주고 내 전화 내용도 기록해주는 이런 신기하고 똑똑한 비서, 대기업 사장님만 채용 가능하냐구요? 회원가입만 하면 여러분도 지금 바로 채용가능하세요!'
            )}
          </p>
          {/* <div
            className={`w-fit p-px ${
              isEnglish ? 'mt-4 2xl:mt-8' : 'mt-10 2xl:mt-[62px]'
            } bg-gradient-to-br from-white to-white rounded-lg hover:from-primary hover:to-primaryTo`}
          >
            <HomeButton
              onClick={() =>
                window.open(
                  'https://coffee-pressure-f38.notion.site/FAQ-f560dca4a250491ba905cdb4998da701'
                )
              }
              icon={true}
              color="main white"
            >
              {t('서비스 이용안내')}
            </HomeButton>
          </div> */}
          <div className={`flex space-x-4 ${isEnglish ? 'mt-6' : 'mt-[62px]'}`}>
            <button onClick={onClickAppStore}>
              <img
                className="w-[120px] h-[36px] md:w-[200px] md:h-[59px]"
                src="/img/appstore.png"
                alt="app store link button"
              />
            </button>
            <button onClick={onClickGooglePlay}>
              <img
                className="w-[120px] h-[36px] md:w-[200px] md:h-[59px]"
                src="/img/googleplay.png"
                alt="google play link button"
              />
            </button>
          </div>
        </div>
        <div className="w-full max-md:w-[90%] md:max-w-md 2xl:max-w-[537px] z-10 max-lg:ml-auto">
          <img
            className="ml-auto"
            src={isEnglish ? '/son/son-bg-en.png' : '/son/son-bg.png'}
            alt="son main"
          />
        </div>
      </section>
      <Banner />
    </>
  );
}

function Banner() {
  const { t } = useTranslation();
  return (
    // <div
    //   className="-translate-y-12 md:-translate-y-14 2xl:-translate-y-16 text-white bg-cover bg-no-repeat w-full rounded-2xl py-10 px-6 lg:px-14 2xl:py-[60px] 2xl:px-[80px] flex max-md:flex-col max-md:items-start items-center justify-between"
    //   style={{ backgroundImage: 'url(/son/banner-bg.png)' }}
    // >
    //   <div>
    //     <h2 className="text-xl lg:text-2xl 2xl:text-[32px] leading-5 2xl:leading-[52px] font-extralight">
    //       {t('개인용 AI비서 모바일APP ‘손비서’')}
    //     </h2>
    //     <h1 className="text-xl lg:text-3xl 2xl:text-[40px] max-2xl:mt-1 leading-5 2xl:leading-[52px] font-semibold 2xl:font-bold">
    //       {t('클로즈 베타 테스터를 모집합니다!')}
    //     </h1>
    //     <span className="block text-white/80 md:text-lg 2xl:text-2xl mt-3 lg:mt-5 2xl:mt-6">
    //       {t(
    //         '플루닛의 새로운 서비스를 가장 먼저 체험해보시고 풍성한 선물도 받아보세요'
    //       )}
    //     </span>
    //   </div>
    //   <div className="flex max-md:mt-10 max-md:space-x-2 md:flex-col md:space-y-4 font-bold max-md:text-sm">
    //     <button
    //       onClick={() =>
    //         window.open(
    //           'https://www.ploonet.com/notice/9e55e5cd-647f-48d7-a685-bfda06f9728c'
    //         )
    //       }
    //       className="flex justify-between items-center py-3 px-4 md:py-4 md:px-5 rounded-lg border border-white group"
    //     >
    //       <span className="text-left">{t('자세히 보기')}</span>
    //       <i className="ml-3 group-hover:translate-x-[2px] group-hover:-translate-y-[2px] duration-100 ease-out max-md:hidden">
    //         <LinkIcon />
    //       </i>
    //     </button>
    //     <button
    //       onClick={() => alert('테스터 모집이 마감되었습니다.')}
    //       className="flex justify-between items-center py-3 px-4 md:py-4 md:px-5 rounded-lg bg-white group"
    //     >
    //       <span className="text-left text-primary whitespace-nowrap">
    //         {t('테스터 신청하기')}
    //       </span>

    //       <i className="ml-3 group-hover:translate-x-[2px] group-hover:-translate-y-[2px] duration-100 ease-out max-md:hidden">
    //         <LinkIcon primary={true} />
    //       </i>
    //     </button>
    //   </div>
    // </div>
    <div
      className="-translate-y-12 md:-translate-y-14 2xl:-translate-y-16 text-white bg-cover bg-no-repeat w-full rounded-2xl py-10 px-6 lg:px-14 2xl:py-[60px] 2xl:px-[80px] flex max-md:flex-col max-md:items-start items-center justify-between"
      style={{ backgroundImage: 'url(/son/banner-bg.png)' }}
    >
      <div>
        <h2 className="text-xl lg:text-2xl 2xl:text-[32px] 2xl:leading-[52px] font-extralight max-md:whitespace-pre-wrap leading-7">
          {t('손비서 사용법이 궁금하시다면')}
        </h2>
        <h1 className="text-xl lg:text-3xl 2xl:text-[40px] max-2xl:mt-1 leading-5 2xl:leading-[52px] font-semibold 2xl:font-bold">
          {t('자주 묻는 질문을 살펴보세요!')}
        </h1>
        <span className="block text-white/80 md:text-lg 2xl:text-2xl mt-3 lg:mt-5 2xl:mt-6 max-md:whitespace-pre-wrap">
          {t('A부터 Z까지 손비서에 대한 모든 걸 알려드려요.')}
        </span>
      </div>
      <div className="flex max-md:mt-10 max-md:space-x-2 md:flex-col md:space-y-4 font-bold max-md:text-sm">
        <button
          onClick={() =>
            window.open(
              'https://coffee-pressure-f38.notion.site/FAQ-f560dca4a250491ba905cdb4998da701'
            )
          }
          className="flex justify-between items-center py-3 px-4 md:py-4 md:px-5 rounded-lg border border-white group"
        >
          <span className="text-left">{t('자세히 보기')}</span>
          <i className="ml-10 group-hover:translate-x-[2px] group-hover:-translate-y-[2px] duration-100 ease-out max-md:hidden">
            <LinkIcon />
          </i>
        </button>
      </div>
    </div>
  );
}
