import { useEffect, useState } from 'react';

export default function useWindowScroll() {
  const [scrollPosition, setScrollPosition] = useState({ x: 0, y: 0 });

  const handleScroll = () => {
    const x = window.scrollX;
    const y = window.scrollY;
    setScrollPosition({ x, y });
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return {
    scrollX: scrollPosition.x,
    scrollY: scrollPosition.y,
  };
}
