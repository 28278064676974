import { useEffect, useState } from 'react';
import { reqAgreementTerms } from '../../services/PublicService';
import { siteLangCd } from '../../services/defaultClient';

const termsCdArr = ['M01E0', 'M01F0', 'M01G0'];

export function useTermSnums() {
  const [termSnums, setTermSnums] = useState({
    M01E0: '',
    M01F0: '',
    M01G0: '',
  });
  useEffect(() => {
    termsCdArr.map((termsCd, idx) =>
      reqAgreementTerms({
        siteLangCd,
        termsCd: termsCd,
      })
        .then((res) => {
          const resData = res.data.data;
          idx === 0
            ? setTermSnums((prev) => ({
                ...prev,
                M01E0: resData.termsSnum,
              }))
            : idx === 1
            ? setTermSnums((prev) => ({
                ...prev,
                M01F0: resData.termsSnum,
              }))
            : setTermSnums((prev) => ({
                ...prev,
                M01G0: resData.termsSnum,
              }));
        })
        .catch(console.log)
    );
  }, []);

  return termSnums;
}
