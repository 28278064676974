import { t } from 'i18next';
import MailIcon from '../vector/about/MailIcon';
import PhoneIcon from '../vector/about/PhoneIcon';

export default function RecruitSection() {
  return (
    <section
      data-aos="fade-up"
      className="flex flex-col space-y-5 ml-[300px] max-md:ml-0 max-md:px-5 mt-10 max-md:mt-7"
    >
      <h1 className="min-w-[300px] max-2xl:min-w-72 h-fit font-bold text-transparent text-[32px] max-2xl:text-3xl max-md:text-2xl bg-clip-text bg-gradient-to-br from-[#FF4E83] to-[#FFBB54]">
        Join us in our challenges <br className="hidden max-md:block" /> and
        growth
      </h1>
      <div className="flex items-start space-x-10">
        <span className="font-bold">{t('채용문의')}</span>
        <div className="flex space-x-10 max-md:flex-col max-md:space-y-6 max-md:space-x-0">
          <div className="flex items-center space-x-3">
            <i>
              <PhoneIcon />
            </i>
            <span className="text-black/60">1533-6116</span>
          </div>
          <div className="flex items-center space-x-3">
            <i>
              <MailIcon />
            </i>
            <span className="text-black/60">support@ploonet.com</span>
          </div>
        </div>
      </div>
    </section>
  );
}
