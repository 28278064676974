const jwt = JSON.parse(localStorage.getItem('jwt'));

const getLocalRefreshToken = () => {
  return jwt ? jwt.refreshToken : null;
};

const getLocalAccessToken = () => {
  return jwt ? jwt.accessToken : null;
};

const getLocalSsoToken = () => {
  return jwt ? jwt.ssoToken : null;
};

const updateLocalAuthToken = (jwt) => {
  localStorage.setItem('jwt', JSON.stringify(jwt));
};

const removeLocalAuthToken = () => {
  localStorage.removeItem('jwt');
};

const TokenService = {
  jwt,
  getLocalSsoToken,
  getLocalRefreshToken,
  getLocalAccessToken,
  updateLocalAuthToken,
  removeLocalAuthToken,
};

export default TokenService;
