import { t } from 'i18next';
import { useState } from 'react';
import { useRecoilValue } from 'recoil';
import MyPageLayout from '../../../components/common/MyPageLayout';
import { getYesterday } from '../../../components/hooks/useTime';
import ServiceUsageSonSection from '../../../components/my-page/service-usage/ServiceUsageSonSection';
import ServiceUsageWorkcenterSection from '../../../components/my-page/service-usage/ServiceUsageWorkCenterSection';
import DivIcon from '../../../components/vector/payment-details/DivIcon';
import { userinfoAtom } from '../../../store';

const serviceTypeArray = [
  // {
  //   label: '스튜디오',
  // },
  {
    label: '워크센터',
  },
  {
    label: '손비서앱',
  },
];
export default function ServiceDetails() {
  const [serviceType, setServiceType] = useState('워크센터');
  const yesterday = getYesterday();
  const { language } = useRecoilValue(userinfoAtom);

  return (
    <MyPageLayout>
      <div className="w-full">
        <div className="flex max-lg:flex-col max-lg:space-y-3 justify-between max-md:mb-6 max-md:w-full">
          <h1 className="font-bold text-4xl max-2xl:text-3xl max-md:text-2xl">
            {t('서비스 사용내역')}
          </h1>
        </div>
        <div className="flex items-center my-7 max-2xl:my-5 max-md:mb-6 max-lg:flex-col max-lg:items-start max-lg:space-y-3 justify-between">
          <div className="flex items-center max-md:mb-3">
            {serviceTypeArray.map(({ label }, idx) => (
              <div className="flex items-center">
                <button
                  onClick={() => setServiceType(label)}
                  className={`text-2xl max-2xl:text-xl font-bold ${
                    serviceType === label
                      ? 'text-hoverPrimary border-b-[3px] border-hoverPrimary'
                      : 'text-black5'
                  }`}
                >
                  {t(label)}
                </button>
                {idx !== 1 && (
                  <i className="mx-6 max-2xl:mx-4">
                    <DivIcon />
                  </i>
                )}
              </div>
            ))}
          </div>
          {language === 'kr' ? (
            <div className="text-lg max-2xl:text-base max-md:text-sm text-black5">
              {yesterday} 00:00:00 ~ {yesterday} 23:59:59 기준
            </div>
          ) : (
            <div className="text-lg max-2xl:text-base max-md:text-sm text-black5">
              Based on {yesterday} 00:00:00 ~ {yesterday} 23:59:59
            </div>
          )}
        </div>
        {serviceType === '워크센터' && <ServiceUsageWorkcenterSection />}
        {serviceType === '손비서앱' && <ServiceUsageSonSection />}
      </div>
    </MyPageLayout>
  );
}
