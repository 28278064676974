import SearchFilterSelectBox from './SearchFilterSelectBox';

interface SearchFilterProp {
  filterOptions: {
    name: string;
    value: string;
  }[][];
  setFilter?: any;
}

export default function SearchFilter({
  filterOptions,
  setFilter,
}: SearchFilterProp) {
  return (
    <div
      className={`flex space-x-2 max-md:grid ${
        filterOptions.length === 1 ? 'max-md:grid-cols-1' : 'max-md:grid-cols-2'
      } max-md:space-x-0 max-md:gap-y-3 max-md:gap-x-2 max-md:w-full`}
    >
      {filterOptions.map((array, idx) => (
        <div
          key={`filter-${idx}`}
          className={`${
            idx === 2 && filterOptions.length === 3 && 'col-span-2'
          }`}
        >
          <SearchFilterSelectBox
            key={`filterBox-${idx}`}
            options={array}
            setFilter={setFilter}
          />
        </div>
      ))}
    </div>
  );
}
