import { Dialog, Transition } from '@headlessui/react';
import { Fragment, SetStateAction, useEffect, useState } from 'react';
import { withdrawReasonArray } from '../../../array/SearchFilterArray';
import { requestWithdrawUser } from '../../../services/PrivateService';

import { t } from 'i18next';
import { useRecoilValue } from 'recoil';
import AuthService from '../../../services/auth.service';
import { currentCredit, currentMembership } from '../../../store';
import Loader from '../../common/Loader';
import InformModal from '../../common/modal/InformModal';
import CheckBox from '../../vector/common/CheckBox';
import CheckedBox from '../../vector/common/CheckedBox';
import ReasonSelectBox from './ReasonSelectBox';

interface CreditType {
  defaultCredit: number;
  usedCredit: number;
  remainingCredit: number;
}

export default function WithdrawModal({
  modal,
  closeModal,
}: {
  modal: boolean;
  closeModal: React.Dispatch<SetStateAction<boolean>>;
}) {
  const [creditInfo, setCreditInfo] = useState<CreditType>({
    defaultCredit: 0,
    usedCredit: 0,
    remainingCredit: 0,
  });
  const [reasonModal, setReasonModal] = useState(false);
  const { subscCount } = useRecoilValue(currentMembership);
  const myCredit = useRecoilValue(currentCredit);

  useEffect(() => {
    if (!subscCount && !myCredit.credit) {
      setReasonModal(true);
      return;
    } else {
      setReasonModal(false);
      setCreditInfo({
        defaultCredit: Number(myCredit.creditPolicy),
        usedCredit: Number(myCredit.creditUse),
        remainingCredit: Number(myCredit.creditLefts),
      });
    }
  }, [myCredit, subscCount]);

  return (
    <>
      {reasonModal ? (
        <ReasonModal
          modal={reasonModal && modal}
          closeModal={() => closeModal(false)}
        />
      ) : (
        <Transition appear show={modal} as={Fragment}>
          <Dialog
            as="div"
            className="fixed z-50"
            onClose={() => console.log('')}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black bg-opacity-25" />
            </Transition.Child>

            <div className="fixed inset-0 max-md:bottom-0 max-md:inset-auto max-md:w-full">
              <div className="flex min-h-full items-center justify-center p-4 max-md:p-0 text-center">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Dialog.Panel className="w-full max-w-[360px] max-md:max-w-none space-y-4 px-8 pt-10 pb-5 transform overflow-hidden rounded-2xl max-md:rounded-b-none bg-white transition-all">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-bold text-gray-900 text-center max-md:text-xl"
                    >
                      {t('이용중인 요금제와 크레딧이')} <br />
                      {t('남아있습니다.')}
                    </Dialog.Title>
                    <div className="mt-3 pb-5 border-b border-[#d7d7d7] max-sm:text-sm">
                      <span className="text-[#FF7B51]">
                        {t('요금제 취소 또는 해지 후 탈퇴가 가능합니다.')}
                      </span>
                      <p className="text-[#666666]">
                        {t(
                          '단, 상품요금제는 취소기간이 지났을 경우 크레딧을 모두 소진하거나 유효기간(3개월) 이후에 탈퇴신청이 가능합니다.'
                        )}
                      </p>
                    </div>
                    <section className="text-lg py-7 space-y-4">
                      <div className="flex justify-between">
                        <span>{t('지급 크레딧')}</span>
                        <span>{creditInfo.defaultCredit.toLocaleString()}</span>
                      </div>
                      <div className="flex justify-between">
                        <span>{t('사용 크레딧')}</span>
                        <span className="text-textGray">
                          {(
                            creditInfo.defaultCredit - myCredit.credit
                          ).toLocaleString()}
                        </span>
                      </div>
                      <div className="flex justify-between font-bold">
                        <span>{t('남은 크레딧')}</span>
                        <span>{myCredit.credit.toLocaleString()}</span>
                      </div>
                    </section>
                    <button
                      className="w-full text-lg font-bold py-4 rounded-lg bg-gradient-to-br from-primary to-primaryTo hover:from-hoverPrimary hover:to-hoverPrimaryTo text-white"
                      onClick={() => closeModal(false)}
                    >
                      {t('확인')}
                    </button>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition>
      )}
    </>
  );
}

function ReasonModal({
  modal,
  closeModal,
}: {
  modal: boolean;
  closeModal: React.Dispatch<SetStateAction<boolean>>;
}) {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [informModal, setInformModal] = useState({
    isOpen: false,
    phrase: '',
    content: '',
  });
  const [withdrawInfo, setWithdrawInfo] = useState<{
    cancelReasonCd: string;
    cancelReasonEtcCtnt: string;
    agree?: boolean;
  }>({
    cancelReasonCd: '',
    cancelReasonEtcCtnt: '',
    agree: false,
  });

  const requestWithdraw = () => {
    const { cancelReasonCd, cancelReasonEtcCtnt, agree } = withdrawInfo;
    if (!agree) {
      setInformModal({
        isOpen: true,
        phrase: '안내사항에 동의해주세요.',
        content: '',
      });
      return;
    } else if (!cancelReasonCd) {
      setInformModal({
        isOpen: true,
        phrase: '탈퇴 사유를 선택해주세요.',
        content: '',
      });
    } else {
      setLoading(true);
      requestWithdrawUser({
        cancelReasonCd,
        cancelReasonEtcCtnt,
      })
        .then((res) => {
          if (res.data.resultCd === '0000') {
            setSuccess(true);
          } else {
            alert(t('잠시 후 다시 시도해주세요.'));
          }
        })
        .catch((err) => alert(err))
        .finally(() => setLoading(false));
    }
  };

  const confirmWithdraw = () => {
    setSuccess(false);
    closeModal(false);
    AuthService.logout();
    window.location.href = '/';
  };

  return (
    <>
      <InformModal
        modal={informModal.isOpen}
        phrase={informModal.phrase}
        closeModal={() =>
          setInformModal((prev) => ({
            ...prev,
            isOpen: false,
          }))
        }
      />
      <Loader loading={loading} />
      <InformModal
        modal={success}
        phrase="회원탈퇴가 완료되었습니다."
        content="플루닛 서비스를 이용해 주셔서 감사합니다. 회원탈퇴 시 회원 정보과 모두 삭제되며, 언제든 재가입이 가능합니다."
        closeModal={confirmWithdraw}
      />
      <Transition appear show={modal} as={Fragment}>
        <Dialog as="div" className="fixed z-50" onClose={() => console.log('')}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>
          <div className="fixed inset-0 max-md:bottom-0 max-md:inset-auto max-md:w-full">
            <div className="flex min-h-full items-center justify-center p-4 max-md:p-0 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95 max-md:scale-100 max-md:translate-y-80"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95 max-md:scale-100 max-md:translate-y-80"
              >
                <Dialog.Panel className="relative w-full max-w-[600px] max-md:max-h-full px-8 pb-10 transform rounded-2xl max-md:rounded-b-none bg-white transition-all max-md:px-0 max-md:overflow-y-scroll inquiry-detail max-md:pb-14">
                  <Dialog.Title
                    as="h3"
                    className="text-2xl max-md:text-xl py-6 max-md:py-5 font-bold text-black2 text-center border-b border-borderGray w-full"
                  >
                    {t('회원탈퇴')}
                  </Dialog.Title>

                  <section className="text-left px-5 max-md:max-h-[480px] max-md:overflow-y-scroll inquiry-detail max-md:pb-5">
                    <div className="py-7 font-bold text-xl max-md:text-lg">
                      <h3>{t('정말 회원탈퇴를 하시겠어요?')}</h3>
                      <h3>
                        {t(
                          '어떤 점이 불편해서 탈퇴를 결심하셨는지 이유를 알려주세요.'
                        )}
                      </h3>
                    </div>
                    <div className="space-y-3">
                      <ReasonSelectBox
                        array={withdrawReasonArray}
                        setReasonInfo={setWithdrawInfo}
                      />
                      <textarea
                        onChange={(e) => {
                          setWithdrawInfo((prev) => ({
                            ...prev,
                            cancelReasonEtcCtnt: e.target.value,
                          }));
                        }}
                        value={withdrawInfo.cancelReasonEtcCtnt}
                        placeholder={t(
                          '이 외 불편하셨던 점이 있으면 알려주세요.(선택)'
                        )}
                        className="border border-borderLightGray py-2 px-3 rounded-md w-full h-24 text-base resize-none"
                      />
                    </div>
                    <div className="py-7 space-y-3">
                      <h3 className="font-bold text-xl max-md:text-lg">
                        {t('플루닛을 떠나기 전에,')}{' '}
                        <br className="md:hidden" />
                        {t('안내사항을 꼭 확인해 주세요.')}
                      </h3>
                      <h3 className="font-normal text-[#666666] max-md:text-sm">
                        {t(
                          '탈퇴 후에 고객님의 모든 콘텐츠와 활동 기록, 크레딧 충전/적립/사용 내역이 모두 삭제되며, 삭제된 정보는 복구가 불가하오니 신중하게 결정해 주세요.'
                        )}
                      </h3>
                      <div className="rounded-lg bg-[#f2f2f2] p-5">
                        <ul className="list-disc ml-5 text-black4 text-sm max-md:text-xs flex flex-col h-full justify-between">
                          <li>
                            {t(
                              '회원탈퇴 시 플루닛 통합 회원에서 탈퇴되는 것으로, 플루닛 브랜드 사이트를 포함한 플루닛 스튜디오, 워크센터, 손비서 등 플루닛 관련 서비스를 이용하실 수 없습니다.'
                            )}
                          </li>
                          <li>
                            {t(
                              '탈퇴 후에 고객님의 모든 콘텐츠와 활동 기록, 크레딧 충전/적립/사용 내역이 모두 삭제되며, 삭제된 정보는 복구가 불가하오니 신중하게 결정해 주세요.'
                            )}
                          </li>
                        </ul>
                      </div>

                      <div className="flex">
                        <input
                          id="agree-check"
                          type="checkbox"
                          onChange={(e) => {
                            setWithdrawInfo((prev) => ({
                              ...prev,
                              agree: e.target.checked,
                            }));
                          }}
                          className="hidden"
                        />
                        <label
                          htmlFor="agree-check"
                          className="flex items-center space-x-2 text-gray-900 text-lg max-md:text-sm cursor-pointer"
                        >
                          {withdrawInfo.agree ? <CheckedBox /> : <CheckBox />}
                          <span>
                            {t(
                              '안내사항을 모두 확인하였으며, 이에 동의합니다.'
                            )}
                          </span>
                        </label>
                      </div>
                    </div>
                  </section>
                  <div className="flex space-x-4 max-md:p-5 max-md:absolute max-md:bottom-0 w-full bg-white">
                    <button
                      type="button"
                      className="w-full text-lg font-bold py-4 rounded-lg hover:bg-gray5 border border-[#cccccc]"
                      onClick={() => closeModal(false)}
                    >
                      {t('취소')}
                    </button>
                    <button
                      className="w-full text-lg font-bold py-4 rounded-lg bg-gradient-to-br from-primary to-primaryTo hover:from-hoverPrimary hover:to-hoverPrimaryTo text-white"
                      onClick={requestWithdraw}
                    >
                      {t('탈퇴하기')}
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
