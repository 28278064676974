export default function EffectIcon() {
  return (
    <svg
      className="w-[100px] h-[100px] max-md:w-[72px] max-md:h-[72px]"
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="50" cy="50" r="50" fill="#222222" />
      <path
        d="M33.6002 35.4949C31.4666 37.6284 31.4666 41.0876 33.6002 43.2211L36.6085 46.2295C36.6311 46.2034 36.6548 46.1779 36.6796 46.1531L44.2585 38.5741C44.2833 38.5494 44.3087 38.5258 44.3347 38.5032L41.3264 35.4949C39.1929 33.3614 35.7337 33.3614 33.6002 35.4949Z"
        fill="url(#paint0_linear_1628_100541)"
      />
      <path
        d="M46.3391 40.5076C46.3166 40.5336 46.2929 40.5591 46.2682 40.5838L38.6893 48.1627C38.6645 48.1875 38.639 48.2112 38.6128 48.2338L56.7789 66.3998C58.9124 68.5334 62.3716 68.5334 64.5051 66.3998C66.6387 64.2663 66.6387 60.8071 64.5051 58.6736L46.3391 40.5076Z"
        fill="url(#paint1_linear_1628_100541)"
      />
      <path
        d="M56.8211 32.582C57.1261 31.806 58.2203 31.806 58.5253 32.582L59.3407 34.6573C59.4338 34.8943 59.6207 35.0818 59.8568 35.1753L61.9244 35.9937C62.6976 36.2998 62.6976 37.3981 61.9244 37.7042L59.8568 38.5227C59.6207 38.6161 59.4338 38.8037 59.3407 39.0406L58.5253 41.1159C58.2203 41.8919 57.1261 41.8919 56.8211 41.1159L56.0057 39.0406C55.9126 38.8037 55.7257 38.6161 55.4896 38.5227L53.422 37.7042C52.6488 37.3981 52.6488 36.2998 53.422 35.9937L55.4896 35.1753C55.7257 35.0818 55.9126 34.8943 56.0057 34.6573L56.8211 32.582Z"
        fill="url(#paint2_linear_1628_100541)"
      />
      <path
        d="M64.1483 45.5084C64.4533 44.7324 65.5476 44.7324 65.8525 45.5084L66.1498 46.2651C66.2429 46.502 66.4298 46.6896 66.6658 46.783L67.4197 47.0815C68.1929 47.3875 68.1929 48.4858 67.4197 48.7919L66.6658 49.0903C66.4298 49.1838 66.2429 49.3713 66.1498 49.6083L65.8525 50.3649C65.5476 51.141 64.4533 51.141 64.1483 50.3649L63.851 49.6083C63.7579 49.3713 63.5711 49.1838 63.335 49.0903L62.5811 48.7919C61.8079 48.4858 61.8079 47.3875 62.5811 47.0815L63.335 46.783C63.5711 46.6896 63.7579 46.502 63.851 46.2651L64.1483 45.5084Z"
        fill="url(#paint3_linear_1628_100541)"
      />
      <path
        d="M36.0418 57.2136C36.3468 56.4376 37.4411 56.4376 37.746 57.2136L38.0433 57.9703C38.1364 58.2072 38.3233 58.3948 38.5593 58.4882L39.3132 58.7866C40.0864 59.0927 40.0864 60.191 39.3132 60.4971L38.5593 60.7955C38.3233 60.889 38.1364 61.0765 38.0433 61.3134L37.746 62.0701C37.4411 62.8462 36.3468 62.8462 36.0418 62.0701L35.7445 61.3134C35.6514 61.0765 35.4646 60.889 35.2285 60.7955L34.4746 60.4971C33.7014 60.191 33.7014 59.0927 34.4746 58.7866L35.2285 58.4882C35.4646 58.3948 35.6514 58.2072 35.7445 57.9703L36.0418 57.2136Z"
        fill="url(#paint4_linear_1628_100541)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1628_100541"
          x1="32"
          y1="32"
          x2="58.2953"
          y2="74.0661"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF478B" />
          <stop offset="1" stopColor="#FFBB54" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1628_100541"
          x1="32"
          y1="32"
          x2="58.2953"
          y2="74.0661"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF478B" />
          <stop offset="1" stopColor="#FFBB54" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1628_100541"
          x1="32"
          y1="32"
          x2="58.2953"
          y2="74.0661"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF478B" />
          <stop offset="1" stopColor="#FFBB54" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_1628_100541"
          x1="32"
          y1="32"
          x2="58.2953"
          y2="74.0661"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF478B" />
          <stop offset="1" stopColor="#FFBB54" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_1628_100541"
          x1="32"
          y1="32"
          x2="58.2953"
          y2="74.0661"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF478B" />
          <stop offset="1" stopColor="#FFBB54" />
        </linearGradient>
      </defs>
    </svg>
  );
}
