import { t } from 'i18next';
import { SetStateAction } from 'react';
import { useRecoilValue } from 'recoil';
import { radioGroupArray } from '../../../array/myPageArray';
import { userinfoAtom } from '../../../store';
import { InquiryTypes } from '../../../views/mypage/inquiry/req';
import Radio from '../../vector/common/Radio';
import SelectedRadio from '../../vector/common/SelectedRadio';

export default function RequestRadioGroup({
  setRequestInfo,
  requestInfo,
}: {
  setRequestInfo: React.Dispatch<SetStateAction<InquiryTypes>>;
  requestInfo: InquiryTypes;
}) {
  const { language } = useRecoilValue(userinfoAtom);
  return (
    <div
      className={`grid gap-x-4 gap-y-5 ${
        language === 'en'
          ? 'grid-cols-4 max-2xl:grid-cols-3 max-md:grid-cols-2'
          : 'grid-cols-8 max-2xl:grid-cols-5 max-md:grid-cols-3'
      } max-md:gap-x-8 max-md:gap-y-4`}
    >
      {radioGroupArray.map(({ type, value }, idx) => (
        <div key={`radio-${idx}`} className="flex items-center space-x-2">
          <input
            id={type}
            type="radio"
            value={value}
            onChange={(e) => {
              setRequestInfo((prev) => ({
                ...prev,
                qnaCategoryCd: e.target.value,
              }));
            }}
            defaultChecked={idx === 0}
            name="default-radio"
            className="hidden"
          />
          <label
            htmlFor={type}
            className="ml-2 flex items-center space-x-2 text-lg font-normal max-md:text-sm text-gray-900 cursor-pointer"
          >
            <i className="w-fit">
              {requestInfo.qnaCategoryCd === value ? (
                <SelectedRadio />
              ) : (
                <Radio />
              )}
            </i>
            <span className="w-fit">{t(type)}</span>
          </label>
        </div>
      ))}
    </div>
  );
}
