export default function StepFourIcon() {
  return (
    <svg
      width="40"
      height="41"
      viewBox="0 0 40 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M36.913 34.0066C36.7172 33.0895 35.816 30.458 33.2701 29.1024C30.7237 27.7468 26.2714 26.1254 26.2714 26.1254C26.2714 26.1254 26.0363 25.3545 25.6968 24.9292C25.3574 24.5039 24.6783 24.1582 24.6783 24.1582V23.3873C24.6783 23.3873 25.2267 23.2279 25.4095 22.3244C25.5923 21.4205 25.6183 19.5864 25.6183 19.5864C25.6183 19.5864 27.0975 19.5534 27.4332 17.4801C27.7689 15.4067 27.0416 14.5496 27.0416 14.5496C27.0416 14.5496 27.9165 13.2604 27.5507 9.2201C27.1851 5.17977 23.8426 3.95703 20.4998 3.95703C17.157 3.95703 13.8144 5.17977 13.4488 9.2201C13.0832 13.2602 13.9582 14.5496 13.9582 14.5496C13.9582 14.5496 13.2306 15.4067 13.5666 17.4801C13.9025 19.5534 15.3815 19.5864 15.3815 19.5864C15.3815 19.5864 15.4075 21.4205 15.5903 22.3244C15.7731 23.2279 16.3215 23.3873 16.3215 23.3873V24.1582C16.3215 24.1582 15.6426 24.5039 15.303 24.9292C14.9637 25.3545 14.7286 26.1254 14.7286 26.1254C14.7286 26.1254 10.276 27.7468 7.72992 29.1024C5.1838 30.458 4.28283 33.0895 4.08703 34.0066C3.89122 34.9236 4.08703 36.957 4.08703 36.957H20.5H36.913C36.913 36.957 37.1088 34.9236 36.913 34.0066Z"
        stroke="#666666"
        strokeWidth="2"
      />
    </svg>
  );
}
