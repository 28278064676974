export const nationArr = [
  { code: 'USA', number: '1', name: 'USA' },
  { code: 'CHN', number: '86', name: 'China' },
  { code: 'GBR', number: '44', name: 'UK' },
  { code: 'DZA', number: '213', name: 'Algeria' },
  { code: 'AGO', number: '244', name: 'Angola' },
  { code: 'ATG', number: '1268', name: 'Antigua&Barbuda' },
  { code: 'ARG', number: '54', name: 'Argentina' },
  { code: 'ARM', number: '374', name: 'Armenia' },
  { code: 'ABW', number: '297', name: 'Aruba' },
  { code: 'AUS', number: '61', name: 'Australia' },
  { code: 'AUT', number: '43', name: 'Austria' },
  { code: 'AZE', number: '994', name: 'Azerbaijan' },
  { code: 'BHS', number: '1242', name: 'Bahamas' },
  { code: 'BHR', number: '973', name: 'Bahrain' },
  { code: 'BGD', number: '880', name: 'Bangladesh' },
  { code: 'BRB', number: '1246', name: 'Barbados' },
  { code: 'BLR', number: '375', name: 'Belarus' },
  { code: 'BEL', number: '32', name: 'Belgium' },
  { code: 'BLZ', number: '501', name: 'Belize' },
  { code: 'BEN', number: '229', name: 'Benin' },
  { code: 'BMU', number: '1441', name: 'Bermuda' },
  { code: 'BTN', number: '975', name: 'Bhutan' },
  { code: 'BOL', number: '591', name: 'Bolivia' },
  { code: 'BIH', number: '387', name: 'Bosnia Herzegovina' },
  { code: 'BWA', number: '267', name: 'Botswana' },
  { code: 'BRA', number: '55', name: 'Brazil' },
  { code: 'BRN', number: '673', name: 'Brunei' },
  { code: 'BGR', number: '359', name: 'Bulgaria' },
  { code: 'BFA', number: '226', name: 'Burkina Faso' },
  { code: 'BDI', number: '257', name: 'Burundi' },
  { code: 'KHM', number: '855', name: 'Cambodia' },
  { code: 'CMR', number: '237', name: 'Cameroon' },
  { code: 'CAN', number: '1', name: 'Canada' },
  { code: 'CPV', number: '238', name: 'Cape Verde Islands' },
  { code: 'CYM', number: '1345', name: 'Cayman Islands' },
  { code: 'CAF', number: '236', name: 'Central African Republic' },
  { code: 'CHL', number: '56', name: 'Chile' },
  { code: 'COL', number: '57', name: 'Colombia' },
  { code: 'COM', number: '269', name: 'Comoros' },
  { code: 'COG', number: '242', name: 'Congo' },
  { code: 'COK', number: '682', name: 'Cook Islands' },
  { code: 'CRI', number: '506', name: 'Costa Rica' },
  { code: 'HRV', number: '385', name: 'Croatia' },
  { code: 'CUB', number: '53', name: 'Cuba' },
  { code: 'CYP', number: '90392', name: 'Cyprus North' },
  { code: 'CYP', number: '357', name: 'Cyprus South' },
  { code: 'CZE', number: '42', name: 'Czech' },
  { code: 'DNK', number: '45', name: 'Denmark' },
  { code: 'DJI', number: '253', name: 'Djibouti' },
  { code: 'DMA', number: '1809', name: 'Dominica' },
  { code: 'DOM', number: '1809', name: 'Dominican Republic' },
  { code: 'ECU', number: '593', name: 'Ecuador' },
  { code: 'EGY', number: '20', name: 'Egypt' },
  { code: 'SLV', number: '503', name: 'El Salvador' },
  { code: 'GNQ', number: '240', name: 'Equatorial Guinea' },
  { code: 'ERI', number: '291', name: 'Eritrea' },
  { code: 'EST', number: '372', name: 'Estonia' },
  { code: 'ETH', number: '251', name: 'Ethiopia' },
  { code: 'FLK', number: '500', name: 'Falkland Islands' },
  { code: 'FJI', number: '679', name: 'Fiji' },
  { code: 'FIN', number: '358', name: 'Finland' },
  { code: 'FRA', number: '33', name: 'France' },
  { code: 'PYF', number: '689', name: 'French Polynesia' },
  { code: 'GAB', number: '241', name: 'Gabon' },
  { code: 'GMB', number: '220', name: 'Gambia' },
  { code: 'GEO', number: '7880', name: 'Georgia' },
  { code: 'DEU', number: '49', name: 'Germany' },
  { code: 'GHA', number: '233', name: 'Ghana' },
  { code: 'GIB', number: '350', name: 'Gibraltar' },
  { code: 'GRC', number: '30', name: 'Greece' },
  { code: 'GRL', number: '299', name: 'Greenland' },
  { code: 'GRD', number: '1473', name: 'Grenada' },
  { code: 'GTM', number: '502', name: 'Guatemala' },
  { code: 'GIN', number: '224', name: 'Guinea' },
  { code: 'GNB', number: '245', name: 'Guinea  Bissau' },
  { code: 'GUY', number: '592', name: 'Guyana' },
  { code: 'HTI', number: '509', name: 'Haiti' },
  { code: 'HND', number: '504', name: 'Honduras' },
  { code: 'HKG', number: '852', name: 'Hong Kong' },
  { code: 'HUN', number: '36', name: 'Hungary' },
  { code: 'ISL', number: '354', name: 'Iceland' },
  { code: 'IND', number: '91', name: 'India' },
  { code: 'IDN', number: '62', name: 'Indonesia' },
  { code: 'IRN', number: '98', name: 'Iran' },
  { code: 'IRQ', number: '964', name: 'Iraq' },
  { code: 'IRL', number: '353', name: 'Ireland' },
  { code: 'ISR', number: '972', name: 'Israel' },
  { code: 'ITA', number: '39', name: 'Italy' },
  { code: 'JAM', number: '1876', name: 'Jamaica' },
  { code: 'JPN', number: '81', name: 'Japan' },
  { code: 'JOR', number: '962', name: 'Jordan' },
  { code: 'KAZ', number: '7', name: 'Kazakhstan' },
  { code: 'KEN', number: '254', name: 'Kenya' },
  { code: 'KIR', number: '686', name: 'Kiribati' },
  { code: 'PRK', number: '850', name: 'Korea North' },
  { code: 'KWT', number: '965', name: 'Kuwait' },
  { code: 'KGZ', number: '996', name: 'Kyrgyzstan' },
  { code: 'LAO', number: '856', name: 'Laos' },
  { code: 'LVA', number: '371', name: 'Latvia' },
  { code: 'LBN', number: '961', name: 'Lebanon' },
  { code: 'LSO', number: '266', name: 'Lesotho' },
  { code: 'LBR', number: '231', name: 'Liberia' },
  { code: 'LBY', number: '218', name: 'Libya' },
  { code: 'LTU', number: '370', name: 'Lithuania' },
  { code: 'LUX', number: '352', name: 'Luxembourg' },
  { code: 'MAC', number: '853', name: 'Macao' },
  { code: 'MKD', number: '389', name: 'Macedonia' },
  { code: 'MDG', number: '261', name: 'Madagascar' },
  { code: 'MWI', number: '265', name: 'Malawi' },
  { code: 'MYS', number: '60', name: 'Malaysia' },
  { code: 'MDV', number: '960', name: 'Maldives' },
  { code: 'MLI', number: '223', name: 'Mali' },
  { code: 'MLT', number: '356', name: 'Malta' },
  { code: 'MHL', number: '692', name: 'Marshall Islands' },
  { code: 'MRT', number: '222', name: 'Mauritania' },
  { code: 'MYT', number: '269', name: 'Mayotte' },
  { code: 'MEX', number: '52', name: 'Mexico' },
  { code: 'FSM', number: '691', name: 'Micronesia' },
  { code: 'MDA', number: '373', name: 'Moldova' },
  { code: 'MCO', number: '377', name: 'Monaco' },
  { code: 'MNG', number: '976', name: 'Mongolia' },
  { code: 'MSR', number: '1664', name: 'Montserrat' },
  { code: 'MAR', number: '212', name: 'Morocco' },
  { code: 'MOZ', number: '258', name: 'Mazambique' },
  { code: 'MMR', number: '95', name: 'Myanmar' },
  { code: 'NAM', number: '264', name: 'Namibia' },
  { code: 'NRU', number: '674', name: 'Nauru' },
  { code: 'NPL', number: '977', name: 'Nepal' },
  { code: 'NLD', number: '31', name: 'Netherlands' },
  { code: 'NCL', number: '687', name: 'New Caledonia' },
  { code: 'NZL', number: '64', name: 'New Zealand' },
  { code: 'NIC', number: '505', name: 'Nicaragua' },
  { code: 'NER', number: '227', name: 'Niger' },
  { code: 'NGA', number: '234', name: 'Nigeria' },
  { code: 'NIU', number: '683', name: 'Niue' },
  { code: 'MNP', number: '670', name: 'Northern Marianas' },
  { code: 'NOR', number: '47', name: 'Norway' },
  { code: 'OMN', number: '968', name: 'Oman' },
  { code: 'PLW', number: '680', name: 'Palau' },
  { code: 'PAN', number: '507', name: 'Panama' },
  { code: 'PNG', number: '675', name: 'Papua New Guinea' },
  { code: 'PRY', number: '595', name: 'Paraguay' },
  { code: 'PER', number: '51', name: 'Peru' },
  { code: 'PHL', number: '63', name: 'Philippines' },
  { code: 'POL', number: '48', name: 'Poland' },
  { code: 'PRT', number: '351', name: 'Portugal' },
  { code: 'QAT', number: '974', name: 'Qatar' },
  { code: 'ROU', number: '40', name: 'Romania' },
  { code: 'RUS', number: '7', name: 'Russia' },
  { code: 'RWA', number: '250', name: 'Rwanda' },
  { code: 'STP', number: '239', name: 'Sao Tome & Principe' },
  { code: 'SAU', number: '966', name: 'Saudi Arabia' },
  { code: 'SEN', number: '221', name: 'Senegal' },
  { code: 'SRB', number: '381', name: 'Serbia' },
  { code: 'SYC', number: '248', name: 'Seychelles' },
  { code: 'SLE', number: '232', name: 'Sierra Leone' },
  { code: 'SGP', number: '65', name: 'Singapore' },
  { code: 'SVK', number: '421', name: 'Slovak Republic' },
  { code: 'SVN', number: '386', name: 'Slovenia' },
  { code: 'SLB', number: '677', name: 'Solomon Islands' },
  { code: 'SOM', number: '252', name: 'Somalia' },
  { code: 'ZAF', number: '27', name: 'South Africa' },
  { code: 'ESP', number: '34', name: 'Spain' },
  { code: 'LKA', number: '94', name: 'Sri Lanka' },
  { code: 'SHN', number: '290', name: 'St.Helena' },
  { code: 'KNA', number: '1869', name: 'St.Kitts' },
  { code: 'LCA', number: '1758', name: 'St.Lucia' },
  { code: 'SDN', number: '249', name: 'Sudan' },
  { code: 'SUR', number: '597', name: 'Suriname' },
  { code: 'SWE', number: '46', name: 'Sweden' },
  { code: 'SYR', number: '963', name: 'Syria' },
  { code: 'TJK', number: '7', name: 'Tajikstan' },
  { code: 'THA', number: '66', name: 'Thailand' },
  { code: 'TGO', number: '228', name: 'Togo' },
  { code: 'TON', number: '676', name: 'Tonga' },
  { code: 'TTO', number: '1868', name: 'Trinidad  Tobago' },
  { code: 'TUN', number: '216', name: 'Tunisia' },
  { code: 'TUR', number: '90', name: 'Turkey' },
  { code: 'TKM', number: '7', name: 'Turkmenistan' },
  { code: 'TCA', number: '1649', name: 'Turks  Caicos Islands' },
  { code: 'TUV', number: '688', name: 'Tuvalu' },
  { code: 'UGA', number: '256', name: 'Uganda' },
  { code: 'UKR', number: '380', name: 'Ukraine' },
  { code: 'ARE', number: '971', name: 'United Arab Emirates' },
  { code: 'URY', number: '598', name: 'Uruguay' },
  { code: 'UZB', number: '7', name: 'Uzbekistan' },
  { code: 'VUT', number: '678', name: 'Vanuatu' },
  { code: 'VAT', number: '379', name: 'Vatican City' },
  { code: 'VEN', number: '58', name: 'Venezuela' },
  { code: 'VNM', number: '84', name: 'Vietnam' },
  { code: 'VGB', number: '84', name: 'Virgin Islands - British' },
  { code: 'WLF', number: '681', name: 'Wallis  Futuna' },
  { code: 'YEM', number: '969', name: 'Yemen' },
  { code: 'ZMB', number: '260', name: 'Zambia' },
  { code: 'ZWE', number: '263', name: 'Zimbabwe' },
];
