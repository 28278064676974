export default function StepFiveIcon() {
  return (
    <svg
      width="40"
      height="41"
      viewBox="0 0 40 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20 25.957C23.4518 25.957 26.25 23.1588 26.25 19.707C26.25 16.2553 23.4518 13.457 20 13.457C16.5482 13.457 13.75 16.2553 13.75 19.707C13.75 23.1588 16.5482 25.957 20 25.957Z"
        stroke="#666666"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M9.97388 32.1092C10.9155 30.258 12.3509 28.7034 14.1214 27.6176C15.8919 26.5318 17.9283 25.957 20.0053 25.957C22.0822 25.957 24.1186 26.5317 25.8891 27.6176C27.6596 28.7034 29.0951 30.258 30.0367 32.1092"
        stroke="#666666"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.5 9.70703H35"
        stroke="#666666"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M31.25 5.95703V13.457"
        stroke="#666666"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M34.8103 18.5911C35.3068 21.717 34.8008 24.9198 33.3648 27.7403C31.9289 30.5609 29.6367 32.8544 26.8169 34.2919C23.9972 35.7295 20.7946 36.2373 17.6685 35.7425C14.5423 35.2477 11.6531 33.7758 9.41508 31.5377C7.17705 29.2997 5.70513 26.4105 5.21036 23.2843C4.71559 20.1582 5.22338 16.9557 6.66092 14.1359C8.09847 11.3161 10.3919 9.02395 13.2125 7.58799C16.0331 6.15204 19.2359 5.64606 22.3618 6.14259"
        stroke="#666666"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
