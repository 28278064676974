import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { reqAgreementTerms } from '../../services/PublicService';
import { siteLangCd } from '../../services/defaultClient';

export default function TermsOfUse() {
  const [terms, setTerms] = useState();
  const { t } = useTranslation();
  useEffect(() => {
    reqAgreementTerms({
      siteLangCd,
      termsCd: 'M01E0',
    })
      .then((res) => setTerms(res.data.data.termsTxt))
      .catch(console.log);
  }, []);

  return (
    <section className="bg-[#F8F7F8] pt-24 px-40 pb-48 max-md:px-5 max-md:pt-[62px] max-md:pb-24 max-md:bg-white max-md:mt-10 mt-[100px]">
      <h1 className="font-bold text-5xl border-b-2 border-black pb-10 mb-[60px] max-md:pb-5 max-md:text-[28px] max-md:border-b max-md:mb-10">
        {t('이용약관')}
      </h1>
      <section className="terms-container">
        <div
          className="terms-use"
          dangerouslySetInnerHTML={terms && { __html: terms }}
        ></div>
      </section>
    </section>
  );
}
