export default function SmallLogo() {
  return (
    <svg
      className="w-8 h-6 mac-md:w-5 max-md:h-5"
      viewBox="0 0 38 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M34.9531 5.9916L31.1051 7.39453C27.8838 1.34442 20.6541 -1.56372 14.0366 0.847554C7.41906 3.25883 3.76048 10.1565 5.15978 16.8643L1.31171 18.2672C0.276816 18.6471 -0.24792 19.787 0.11648 20.8246C0.480881 21.8622 1.63239 22.3883 2.66728 22.0229L6.51535 20.62C9.73665 26.6701 16.9664 29.5782 23.5839 27.167C30.1868 24.7557 33.8599 17.858 32.4606 11.1503L36.3087 9.74734C37.3436 9.36739 37.8683 8.22751 37.5039 7.18993C37.1395 6.15236 35.988 5.62626 34.9531 5.9916ZM22.2137 23.3674C17.0538 25.2526 11.34 22.5782 9.4597 17.405C7.5794 12.2317 10.2468 6.50309 15.4067 4.61791C19.9253 2.96655 24.8665 4.80789 27.2862 8.76822L21.7619 10.7849C20.727 11.1649 20.2022 12.3048 20.5666 13.3423C20.9456 14.3799 22.0825 14.906 23.1174 14.5407L28.6417 12.524C29.3268 17.1127 26.7323 21.716 22.1991 23.3674H22.2137Z"
        fill="url(#paint0_linear_503_65391)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_503_65391"
          x1="0.000976563"
          y1="0.000488281"
          x2="17.4181"
          y2="37.4175"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF478B" />
          <stop offset="1" stopColor="#FFBB54" />
        </linearGradient>
      </defs>
    </svg>
  );
}
