import { useEffect, useState } from 'react';
import { reqCompanyHistory } from '../../services/PublicService';
import { HistoryListResDataInf } from '../../services/TypeOfPublicService';
import { siteLangCd } from '../../services/defaultClient';

export default function HistorySection() {
  const [companyHistory, setCompanyHistory] = useState<HistoryListResDataInf[]>(
    []
  );
  useEffect(() => {
    reqCompanyHistory({
      siteLangCd,
    })
      .then((res) => setCompanyHistory(res.data.data))
      .catch(console.log);
  }, []);

  return (
    <section data-aos="fade-up">
      <div className="flex max-md:flex-col">
        <h1 className="min-w-[300px] max-2xl:min-w-72 font-bold text-[32px] max-2xl:text-3xl max-md:text-xl mt-10 max-md:mt-0">
          HISTORY
        </h1>
        <div className="space-y-10 relative">
          <div className="border-l max-md:h-full w-[3px] bg-primary absolute left-[6px] max-2xl:top-14 top-16 -z-10 history-line" />
          <div className="h-16 w-full absolute max-2xl:-bottom-14 -bottom-16 bg-gradient-to-t from-white to-white/0" />
          {companyHistory?.map(({ year, historyList }, idx) => (
            <div
              key={`history-${idx}`}
              className="flex items-start space-x-24 max-2xl:space-x-20 max-md:space-x-5 group"
            >
              <div className="flex items-center">
                <div className="w-4 h-4 bg-gradient-to-b from-[#FF478B] to-[#FFBB54] rounded-full mr-10 max-md:mr-5">
                  <div className="w-4 h-4 bg-gradient-to-b from-[#FF478B] to-[#FFBB54] rounded-full group-hover:animate-ping" />
                </div>
                <h1 className="text-[40px] max-2xl:text-4xl max-md:text-base font-bold flex">
                  {year}
                </h1>
              </div>

              <ul className="space-y-3 mt-2 max-md:mt-0">
                {historyList.map(({ list }, idx) => (
                  <li
                    key={`list-${idx}`}
                    className="flex items-start text-black2 max-md:text-sm"
                  >
                    <div className="w-1 h-1 bg-gradient-to-b from-[#FF478B] to-[#FFBB54] rounded-full mr-1 mt-[10px] max-md:mt-2" />
                    {list}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
