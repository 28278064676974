export default function PersonalIcon() {
  return (
    <svg
      width="41"
      height="40"
      viewBox="0 0 41 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_503_19754)">
        <path
          d="M20.5007 3.3335C29.7007 3.3335 37.1673 10.8002 37.1673 20.0002C37.1673 29.2002 29.7007 36.6668 20.5007 36.6668C11.3007 36.6668 3.83398 29.2002 3.83398 20.0002C3.83398 10.8002 11.3007 3.3335 20.5007 3.3335ZM10.539 25.6935C12.9857 29.3435 16.659 31.6668 20.7673 31.6668C24.874 31.6668 28.549 29.3452 30.994 25.6935C28.2202 23.1012 24.5639 21.6615 20.7673 21.6668C16.9702 21.6611 13.3132 23.1008 10.539 25.6935ZM20.5007 18.3335C21.8267 18.3335 23.0985 17.8067 24.0362 16.869C24.9739 15.9313 25.5007 14.6596 25.5007 13.3335C25.5007 12.0074 24.9739 10.7356 24.0362 9.79796C23.0985 8.86028 21.8267 8.3335 20.5007 8.3335C19.1746 8.3335 17.9028 8.86028 16.9651 9.79796C16.0274 10.7356 15.5007 12.0074 15.5007 13.3335C15.5007 14.6596 16.0274 15.9313 16.9651 16.869C17.9028 17.8067 19.1746 18.3335 20.5007 18.3335Z"
          fill="#222222"
        />
      </g>
      <defs>
        <clipPath id="clip0_503_19754">
          <rect
            width="40"
            height="40"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
