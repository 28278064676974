import EnterpriseIcon from '../components/vector/signup/EnterpriseIcon';
import PersonalIcon from '../components/vector/signup/PersonalIcon';
import { workcenterDomain } from '../services/defaultClient';

export const userTypeArray: {
  icon: JSX.Element;
  buttonText: '개인으로 가입' | '기업으로 가입';
  type: 'personal' | 'enterprise';
}[] = [
  {
    icon: <PersonalIcon />,
    buttonText: '개인으로 가입',
    type: 'personal',
  },
  {
    icon: <EnterpriseIcon />,
    buttonText: '기업으로 가입',
    type: 'enterprise',
  },
];

export const agreementArray: {
  agreement:
    | '통합서비스 이용약관 동의'
    | '개인정보 수집 이용 동의'
    | '광고성 정보 수신 및 마케팅 활용 동의';
  value:
    | 'integrated service'
    | 'personal information'
    | 'advertising information';
  termsCode: 'M01E0' | 'M01F0' | 'M01G0';
  required: boolean;
}[] = [
  {
    agreement: '통합서비스 이용약관 동의',
    value: 'integrated service',
    termsCode: 'M01E0',
    required: true,
  },
  {
    agreement: '개인정보 수집 이용 동의',
    value: 'personal information',
    termsCode: 'M01F0',
    required: true,
  },
  {
    agreement: '광고성 정보 수신 및 마케팅 활용 동의',
    value: 'advertising information',
    termsCode: 'M01G0',
    required: false,
  },
];

export const advertiseMethodArray: {
  text: 'SMS' | '이메일';
}[] = [
  {
    text: 'SMS',
  },
  {
    text: '이메일',
  },
];

export const linkArray: {
  text: '플루닛 워크센터' | '플루닛 스튜디오';
  link: string;
}[] = [
  {
    text: '플루닛 워크센터',
    link: workcenterDomain,
  },
  {
    text: '플루닛 스튜디오',
    link: 'https://studio.ploonet.com/main',
  },
];
