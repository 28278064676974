import TotalBodySection from '../../components/help/total-info/BodySection';
import TotalBottomSection from '../../components/help/total-info/BottomSection';
import TotalInfoHeading from '../../components/help/total-info/HeadingSection';

export default function TotalInfo() {
  return (
    <main className="pt-[200px] pb-[230px] max-2xl:pb-[200px] max-md:pt-[98px] max-md:pb-[100px] max-md:px-5 mx-auto max-w-[1320px] max-2xl:max-w-[980px] text-black2">
      <TotalInfoHeading />
      <TotalBodySection />
      <TotalBottomSection />
    </main>
  );
}
