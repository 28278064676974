import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { policyArray, snsArray } from '../../array/footerArray';
import i18n from '../../locale/i18n';
import { clicked, userinfoAtom } from '../../store';
import FooterLogoIcon from '../vector/footer/FooterLogoIcon';
import KbIcon from '../vector/footer/KbIcon';
import CertifiedMark from '../vector/footer/CertifiedMark';
import MobileFooterLogo from '../vector/footer/MobileFooterLogo';
import ListBox from './ListBox';

export default function Footer({ isMain }: { isMain?: boolean }) {
  const [linkClicked, setLinkClicked] = useRecoilState(clicked);
  const { t } = useTranslation();
  const { language } = useRecoilValue(userinfoAtom);
  const navigate = useNavigate();
  useEffect(() => {
    i18n.changeLanguage(language);
  }, []);

  return (
    <footer
      className={`bg-black p-40 pt-20 max-xl:px-5 max-md:pt-11 max-md:pb-[60px] ${
        isMain && 'py-20'
      }`}
    >
      <div className="flex justify-between max-w-[1600px] mx-auto">
        <div className="flex space-x-20 max-md:flex-col max-md:space-x-0 max-md:space-y-6 w-full">
          <div className="mb-10 flex flex-col justify-between h-full">
            <ul className="flex space-x-10 max-md:space-x-5">
              <li
                onClick={() => {
                  navigate('/about');
                  setLinkClicked(!linkClicked);
                }}
                className="text-white/80 font-bold text-base cursor-pointer max-md:text-sm"
              >
                {t('회사소개')}
              </li>
              {policyArray.map(({ menu, link }, idx) => (
                <Link key={`footerPolicy-${idx}`} to={`${link}`}>
                  <li
                    onClick={() => setLinkClicked(!linkClicked)}
                    className="text-white/80 font-bold text-base cursor-pointer max-md:text-sm"
                  >
                    {t(menu)}
                  </li>
                </Link>
              ))}
            </ul>

            <div className={`${isMain ? 'mt-10' : 'mt-20'} max-md:mt-10`}>
              <i className="max-md:hidden">
                <FooterLogoIcon />
              </i>
              <i className="hidden max-md:block">
                <MobileFooterLogo />
              </i>
            </div>

            <div className="flex flex-col space-y-2 text-sm text-[#888888] mt-4 max-md:text-[12px] max-md:space-y-0">
              <span>
                {t('사업자등록번호 : 451-81-02812')} |{' '}
                {t('통신판매허가번호 : 2022-서울강남-06647')} |{' '}
                {t('대표이사 : 이경일')}
              </span>
              <span>
                {language === 'kr'
                  ? '서울시 송파구 올림픽로35길 123, 향군타워9층 [05510, (구)신천동 7-29]'
                  : '6F, Garion Building, 39 Samseong-ro 85-gil, Gangnam-gu, Seoul, 06196, Korea'}
              </span>
              <span className="max-md:hidden">
                {t('고객센터 1533-6116')} | FAX. {t('02-3453-1116')} | E-mail.
                support@ploonet.com
              </span>
              <span className="hidden max-md:flex">
                {t('고객센터 1533-6116')}
                <br />
                FAX. {t('02-3453-1116')} | E-mail. support@ploonet.com
              </span>
              <span className="!mt-8 text-[#666666] max-md:text-xs">
                &copy; 2023 PLOONET. All rights reserved.
              </span>
            </div>
          </div>

          <div className="hidden max-md:block space-y-7">
            <div className="items-center justify-between flex">
              <ul className="space-x-2 flex">
                {snsArray.map(({ icon, link }, idx) => (
                  <li
                    onClick={() => window.open(link)}
                    key={`sns-${idx}`}
                    className="cursor-pointer"
                  >
                    {icon}
                  </li>
                ))}
              </ul>
              <KbIcon id="mobile" width="50" />
            </div>
            <ListBox width="w-full" />
          </div>
        </div>
        <div className="text-white flex flex-col items-end justify-between max-md:hidden">
          <ListBox width="w-40" />
          <div className="flex gap-2">
            <CertifiedMark id="pc" width="80" />
            <KbIcon id="pc" width="80" />
          </div>
            <ul className="flex justify-between space-x-5">
              {snsArray.map(({ icon, link }, idx) => (
                <li
                  key={`sns-${idx}`}
                  className="cursor-pointer"
                  onClick={() => window.open(link)}
                >
                  {icon}
                </li>
              ))}
            </ul>
        </div>
      </div>
    </footer>
  );
}
