export default function CircleRightArrowIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.0008 11H2.05078C2.55078 5.947 6.81478 2 12.0008 2C17.5238 2 22.0008 6.477 22.0008 12C22.0008 17.523 17.5238 22 12.0008 22C6.81578 22 2.55178 18.053 2.05078 13H10.0008V16L15.0008 12L10.0008 8V11Z"
        fill="#222222"
      />
    </svg>
  );
}
