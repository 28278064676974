import { t } from 'i18next';
import {
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { ENV } from '../../env';
import { PloonianInfoType } from '../../store';
import MusicFlowIcon from '../vector/common/MusicFlowIcon';
import PlayIcon from '../vector/common/PlayIcon';

const selctBoxArray = [
  {
    buttonText: '외형',
    selectValue: 'appearance',
  },
  {
    buttonText: '의상',
    selectValue: 'outfit',
  },
  // {
  //   buttonText: '목소리',
  //   selectValue: 'voice',
  // },
];

export default function PloonianSelectSection({
  loadingImage,
  setPloonianInfo,
  ploonianInfo,
  faceList,
  wearList,
  voiceList,
  ploonianPath,
}: {
  loadingImage: boolean;
  setPloonianInfo: React.Dispatch<SetStateAction<PloonianInfoType>>;
  ploonianInfo: PloonianInfoType;
  faceList: any;
  wearList: any;
  voiceList: any;
  ploonianPath: string;
}) {
  const [category, setCategory] = useState('appearance');
  return (
    <div className="p-10 max-md:px-5 max-md:pt-7 max-md:pb-[34px] bg-gray4 rounded-b-[20px]">
      <div className="text-black5 flex items-center mb-10 max-md:mb-5">
        {selctBoxArray.map(({ buttonText, selectValue }, idx) => (
          <div className="flex items-center" key={`categoryBtn-${idx}`}>
            <button
              onClick={() => setCategory(selectValue)}
              className={`font-bold text-2xl max-2xl:text-xl ${
                category === selectValue
                  ? 'text-hoverPrimary'
                  : 'hover:text-hoverPrimary/75'
              }`}
            >
              {t(buttonText)}
            </button>
            {idx === 0 && (
              <div className="border-r border-black/20 w-[1px] h-6 mx-6" />
            )}
          </div>
        ))}
      </div>
      {loadingImage ? (
        <Loading />
      ) : category === 'appearance' ? (
        <PloonianSelect
          ploonianPath={ploonianPath}
          faceList={faceList}
          setPloonianInfo={setPloonianInfo}
          ploonianInfo={ploonianInfo}
        />
      ) : category === 'outfit' ? (
        <OutfitSelect
          ploonianPath={ploonianPath}
          wearList={wearList}
          setPloonianInfo={setPloonianInfo}
          ploonianInfo={ploonianInfo}
        />
      ) : (
        <VoiceSelect
          ploonianPath={ploonianPath}
          voiceList={voiceList}
          ploonianInfo={ploonianInfo}
          setPloonianInfo={setPloonianInfo}
        />
      )}
    </div>
  );
}

function PloonianSelect({
  setPloonianInfo,
  ploonianInfo,
  faceList,
  ploonianPath,
}: {
  setPloonianInfo: React.Dispatch<SetStateAction<PloonianInfoType>>;
  ploonianInfo: PloonianInfoType;
  faceList: any;
  ploonianPath: string;
}) {
  return (
    <section className="grid grid-cols-6 gap-6 max-md:grid-cols-4 max-md:gap-y-3 max-md:gap-x-4">
      {faceList.map(
        (
          {
            infoId,
            dispName,
            voiceId,
          }: { infoId: string; dispName: string; voiceId: string },
          idx: number
        ) => (
          <div
            key={`ploonian-${idx}`}
            style={{
              backgroundImage: `url(${ENV.REACT_APP_AWS_URL}/${ploonianPath}/image/character/${infoId}.png)`,
            }}
            className={`border-[3px] bg-white h-[100px] cursor-pointer max-md:w-[74px] max-md:h-[74px] rounded-full bg-contain bg-center bg-no-repeat ${
              ploonianInfo.faceCode === infoId
                ? 'border-[#FF7B51] hover:border-[#FF7B51]'
                : 'border-white hover:border-[#ff7a51bd]'
            } duration-100 ease-out`}
            onClick={() => {
              if (infoId.slice(0, 1) !== ploonianInfo.faceCode.slice(0, 1)) {
                setPloonianInfo((prev) => ({
                  ...prev,
                  outfitCode:
                    ploonianInfo.faceCode.slice(0, 1) === 'm' ? 'fc01' : 'mc01',
                }));
              }
              setPloonianInfo((prev) => ({
                ...prev,
                name: dispName,
                faceCode: infoId,
                voiceCode: voiceId,
                voiceName: '',
              }));
            }}
          />
        )
      )}
    </section>
  );
}

function OutfitSelect({
  setPloonianInfo,
  ploonianInfo,
  wearList,
  ploonianPath,
}: {
  setPloonianInfo: React.Dispatch<SetStateAction<PloonianInfoType>>;
  ploonianInfo: PloonianInfoType;
  wearList: any;
  ploonianPath: string;
}) {
  const outfitList = useMemo(() => {
    switch (ploonianInfo.faceCode.slice(0, 1)) {
      case 'm':
        return wearList.filter((list: any) => list.femaleYn === 'N');
      default:
        return wearList.filter((list: any) => list.femaleYn === 'Y');
    }
  }, [ploonianInfo.faceCode]);
  return (
    <section className="grid grid-cols-4 gap-10 max-md:grid-cols-2 max-md:gap-3">
      {outfitList.map(
        (
          { infoId, dispName }: { infoId: string; dispName: string },
          idx: number
        ) => (
          <div
            key={`clothes-${idx}`}
            className={`rounded-[10px] bg-gradient-to-br p-[3px] text-center flex justify-center items-center cursor-pointer max-md:max-h-[146px] ${
              ploonianInfo.outfitCode === infoId
                ? 'from-primary to-[#FF9A51]'
                : 'hover:from-primary/70 hover:to-[#FF9A51]/70'
            }`}
          >
            <div
              className="w-full h-[200px] bg-contain bg-no-repeat bg-center bg-white rounded-[7px] flex items-center justify-center max-md:max-h-[140px]"
              onClick={() =>
                setPloonianInfo((prev) => ({
                  ...prev,
                  outfit: dispName,
                  outfitCode: infoId,
                }))
              }
            >
              <img
                draggable={false}
                className="w-full max-md:h-full p-3 object-contain h-full"
                src={`${ENV.REACT_APP_AWS_URL}/${ploonianPath}/image/costume/${infoId}.png`}
                alt="ploonian outfit"
              />
            </div>
          </div>
        )
      )}
    </section>
  );
}

function VoiceSelect({
  setPloonianInfo,
  ploonianInfo,
  voiceList,
  ploonianPath,
}: {
  setPloonianInfo: React.Dispatch<SetStateAction<PloonianInfoType>>;
  ploonianInfo: PloonianInfoType;
  voiceList: any;
  ploonianPath: string;
}) {
  const [isPlaying, setIsPlaying] = useState(false);
  const [voices, setVoices] = useState<
    { audio: HTMLAudioElement; id: string }[]
  >([]);
  const [audioActive, setAudioActive] = useState('');

  const voicesList = useMemo(() => {
    switch (ploonianInfo.faceCode.slice(0, 1)) {
      case 'm':
        return voiceList.filter((list: any) => list.femaleYn === 'N');
      default:
        return voiceList.filter((list: any) => list.femaleYn === 'Y');
    }
  }, [ploonianInfo.faceCode]);

  useEffect(() => {
    if (!voiceList) {
      return;
    }
    const voiceArr = voicesList.map((voice: any) => ({
      audio: new Audio(
        `${ENV.REACT_APP_AWS_URL}/${ploonianPath}/voice/${voice.infoId}.wav`
      ),
      id: voice.infoId,
    }));
    setVoices(voiceArr);
  }, [voicesList]);

  const handleAudioClick = useCallback(
    (audioId: string) => {
      const audio = voices.find((voice: any) => voice.id === audioId)?.audio;
      if (!audio) {
        return;
      }
      if (isPlaying) {
        audio.pause();
        setIsPlaying(false);
        return;
      }
      audio.play();
      setIsPlaying(true);
      setAudioActive(audioId);
      audio.onended = () => {
        setIsPlaying(false);
        setAudioActive('');
      };
    },
    [isPlaying, voices]
  );

  return (
    <section className="grid grid-cols-2 gap-10 max-md:grid-cols-1 max-md:gap-y-3">
      {voicesList.map(
        (
          { infoId, dispName }: { infoId: string; dispName: string },
          idx: number
        ) => (
          <div
            key={`clothes-${idx}`}
            className={`rounded-[10px] p-[3px] max-2xl:p-[2px] text-center flex justify-center items-center ${
              ploonianInfo.voice === dispName
                ? 'bg-primary'
                : 'hover:bg-primary/80'
            }`}
          >
            <button
              className={`w-full flex justify-between px-[30px] ${
                isPlaying && audioActive !== infoId
                  ? 'cursor-not-allowed bg-gray5'
                  : 'bg-white'
              } py-5 max-2xl:py-4 rounded-[8px] text-center`}
              onClick={() =>
                setPloonianInfo((prev) => ({
                  ...prev,
                  voice: dispName,
                  voiceCode: infoId,
                }))
              }
            >
              <span>{dispName}</span>
              <button
                className={`${
                  isPlaying && audioActive !== infoId && 'cursor-not-allowed'
                }`}
                disabled={isPlaying && audioActive !== infoId}
                onClick={(e) => {
                  e.stopPropagation();
                  handleAudioClick(infoId);
                }}
              >
                {isPlaying && audioActive === infoId ? (
                  <MusicFlowIcon />
                ) : (
                  <PlayIcon />
                )}
              </button>
            </button>
          </div>
        )
      )}
    </section>
  );
}
function Loading() {
  const arr = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
  return (
    <section className="grid grid-cols-6 gap-6 max-md:grid-cols-4 max-md:gap-y-3 max-md:gap-x-4">
      {arr.map((_, idx) => (
        <div
          key={`skeleton-${idx}`}
          className={`border-[3px] bg-white h-[100px] cursor-pointer max-md:w-[74px] max-md:h-[74px] rounded-full bg-contain bg-center bg-no-repeat animate-pulse`}
        >
          <svg
            className="w-full h-full text-gray-200 mr-4"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 20 20"
          >
            <path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z" />
          </svg>
        </div>
      ))}
    </section>
  );
}
