import { t } from 'i18next';
import ExclamationIcon from '../vector/common/ExclamationIcon';

export default function NoData({ isInquiry }: { isInquiry?: boolean }) {
  return (
    <section className="mt-20 flex flex-col space-y-5 items-center">
      <ExclamationIcon />
      <span className="text-black2 text-lg max-md:text-base">
        {isInquiry
          ? t('문의하신 내역이 없습니다.')
          : t('조회된 내역이 없습니다.')}
      </span>
    </section>
  );
}
