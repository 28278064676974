export default function BurgerIcon({ isDark }: { isDark: boolean }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 18 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17 1L1 1"
        stroke={isDark ? 'white' : 'black'}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M17 7L1 7"
        stroke={isDark ? 'white' : 'black'}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M17 13L1 13"
        stroke={isDark ? 'white' : 'black'}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
}
