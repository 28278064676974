export default function KoreanCopy() {
  return (
    <div className="flex flex-col items-center">
      <span className="text-2xl xl:text-3xl 2xl:text-[40px] 2xl:leading-tight font-semibold">
        꿈이 현실이 되는 세상,
      </span>
      <span className="text-2xl xl:text-3xl 2xl:text-[40px] 2xl:leading-tight font-thin">
        생성형/대화형 AI 서비스
      </span>
      <h1 className="w-fit mt-6 text-[56px] xl:text-6xl 2xl:text-[80px] text-transparent bg-clip-text bg-gradient-to-r from-[#FF4E83] to-[#FFBB54]">
        플루닛
      </h1>
    </div>
  );
}
