import "aos/dist/aos.css";
import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { userinfoAtom } from "../../store";
import InquirySection from "../../components/quickserve/InquirySection";
import QuickServeFirstSection from "../../components/quickserve/QuickServeFirstSection";
import QuickServeSecondSection from "../../components/quickserve/QuickServeSecondSection";

export default function QuickServePage() {
  const { language } = useRecoilValue(userinfoAtom);
  const [isMuted, setIsMuted] = useState(true);

  const GENERAL_PROCESS = [
    {
      name: "사전 논의",
      description:
        "영상 제작 문의를 통해 의뢰 확인 후\n상담을 통해 방향 설정 및 내용 협의",
    },
    {
      name: "영상 기획",
      description:
        "스토리보드 전달&수정을 통한 기획안 확정\n가상인간 발화용 스크립트 작성과 프롬프팅",
    },
    {
      name: "영상 생성",
      description:
        "작성한 스크립트로 가상인간 영상 생성\n그외 이미지, 배경 음악 등 필요 요소 생성",
    },
    {
      name: "영상 편집",
      description:
        "영상 전문가의 편집, 디자인, 후반 작업 단계\n필요시 실사 촬영 병행하여 편집본 완성",
    },
    {
      name: "최종 납품",
      description:
        "고객 피드백 반영하여 최종본 완성 및 납품\n* 영상 수정 무료 1회 제공",
    },
  ];

  const QUICKSERVE_PROCESS = [
    {
      name: "제작문의 및 안내",
      description: "AI를 활용한 스크립트 및 프롬프트 생성",
      icon: "/quickserve/quickserve_1.png",
    },
    {
      name: "스토리 및 영상 기획",
      description: "AI 도구를 활용한 스토리 작성 및 영상 기획",
      icon: "/quickserve/quickserve_2.png",
    },
    {
      name: "영상 생성 및 편집",
      description: "AI를 활용한 스크립트 및 프롬프트 생성",
      icon: "/quickserve/quickserve_3.png",
    },
    {
      name: "고객 피드백 반영 및 납품",
      description: "고객 최종 확인 후 12~48시간 내 완성본 납품",
      icon: "/quickserve/quickserve_4.png",
    },
  ];

  useEffect(() => {
    var AOS = require("aos");
    AOS.init({
      duration: 1000,
    });
  }, []);

  const scrollToInquiry = (event: React.MouseEvent) => {
    event.preventDefault();
    const element = document.getElementById("inquiry");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  const toggleMute = () => {
    setIsMuted((prev) => !prev);
  };

  const renderMobileProcess = () => {
    return (
      <div className="flex md:hidden flex-col w-full items-center justify-center gap-8 px-[20px]">
        <div className="flex flex-col md:flex-row w-full gap-[50px] justify-center">
          <div
            data-aos="fade-right"
            className="flex flex-col flex-1 w-full items-center justify-start text-white"
          >
            <div className="text-[22px] text-[#d9d9d9]  font-bold">
              Ploonet 영상 제작 프로세스
            </div>
            <div className="text-[#ffffff]/80 mb-4">
              제작 시작 후 5영업일 이내
            </div>
            <div className="flex flex-col gap-8 w-full">
              {GENERAL_PROCESS.map((process, index) => {
                return (
                  <div key={"process" + index} className="relative w-full">
                    <div className="flex flex-row rounded-[16px] w-full items-center justify-start border border-[#ffffff20] bg-[#141414] py-6 px-6 gap-8">
                      <div className="flex flex-col">
                        <div className="bg-gradient-to-r from-[#ff7e7e] to-[#f05f82] text-transparent bg-clip-text">
                          STEP{index + 1}
                        </div>
                        <div className="text-[20px] text-white/80 font-bold">
                          {process.name}
                        </div>
                      </div>
                      <div className="break-keep text-[#aaaaaa] whitespace-pre-wrap">
                        {process.description}
                      </div>
                    </div>
                    {index + 1 !== GENERAL_PROCESS.length ? (
                      <div className="absolute bottom-[-14px] left-1/2 transform -translate-x-1/2">
                        <div
                          className="w-[28px] h-[14px] 
                    border-t-[14px] border-t-[#ffffff20]
                    border-l-[14px] border-l-transparent
                    border-r-[14px] border-r-transparent"
                        />
                      </div>
                    ) : null}
                  </div>
                );
              })}
            </div>
          </div>
          <div
            data-aos="fade-left"
            className="flex flex-col flex-1 w-full items-center justify-start py-10 text-white relative overflow-hidden"
          >
            <div className="text-[28px] font-bold bg-gradient-to-r from-[#ff4e8c] to-[#ffbb54] text-transparent bg-clip-text">
              Ploonet Quickserve™
            </div>
            <div className="text-[16px] font-bold mb-5">
              제작 시작 후 12~48시간 이내
            </div>
            <div className="flex flex-col flex-1 justify-center w-full gap-5">
              {QUICKSERVE_PROCESS.map((process, index) => {
                return (
                  <div
                    key={"quickserve" + index}
                    className="relative flex flex-row w-full items-center justify-start border border-[#ffffff20] py-6 px-8 whitespace-normal"
                  >
                    <div
                      className="absolute inset-0 rounded-[16px] border border-transparent"
                      style={{
                        background:
                          "linear-gradient(to right, #ff4e8c, #ffbb54) border-box",
                        WebkitMask:
                          "linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0)",
                        WebkitMaskComposite: "xor",
                        maskComposite: "exclude",
                        zIndex: -1,
                      }}
                    />
                    <div className="flex flex-row w-full items-center justify-start gap-8">
                      <div className="flex flex-col items-center flex-1">
                        <div className="text-[13px] bg-gradient-to-r from-[#ff4e8c] to-[#ffbb54] text-transparent bg-clip-text">
                          STEP{index + 1}
                        </div>
                        <div>
                          <img
                            alt={"quickserve_icon_" + index}
                            src={process.icon}
                          />
                        </div>
                      </div>
                      <div className="flex flex-col">
                        <div className="text-[18px] font-bold">
                          {process.name}
                        </div>
                        <div className="flex text-[14px] text-[#aaaaaa]">
                          {process.description}
                        </div>
                      </div>
                    </div>
                    {index + 1 !== QUICKSERVE_PROCESS.length ? (
                      <div className="absolute bottom-[-14px] left-1/2 transform -translate-x-1/2">
                        <div
                          className="w-[28px] h-[14px] 
                    border-t-[14px] border-t-[#ffffff20]
                    border-l-[14px] border-l-transparent
                    border-r-[14px] border-r-transparent"
                        />
                      </div>
                    ) : null}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="flex flex-1 w-full break-keep text-[10px] text-[#666666] text-center px-5 whitespace-normal">
          * 2~3분, 표준 견적에 따르는 일반 영상편집에 한정되며, 모션그래픽 등
          전문 작업이 필요할 경우 작업 기간은 조정될 수 있습니다.
          <br />
          <br />
          ** 영상의 길이, 영상 제작에 필요한 수준에 따라 빠른 작업이 불가할 수
          있으며,클라이언트와의 협의를 통해 작업 기간을 조정할 수 있습니다.
        </div>
      </div>
    );
  };

  const renderDesktopProcess = () => {
    return (
      <div className="hidden md:flex flex-col w-full items-center justify-center gap-[50px]">
        <div className="flex flex-row w-full gap-[50px] justify-center">
          <div
            data-aos="fade-right"
            className="flex flex-col flex-1 max-w-[780px] items-center justify-start py-16 bg-[#141414] border border-[#ffffff30] rounded-[20px] text-white"
          >
            <div className="text-[44px] text-[#d9d9d9] font-bold">
              Ploonet 영상 제작 프로세스
            </div>
            <div className="text-[24px] text-[#ffffff]/80 mb-12">
              제작 시작 후 5영업일 이내
            </div>
            <div className="flex flex-col gap-8 max-w-[600px]">
              {GENERAL_PROCESS.map((process, index) => {
                return (
                  <div key={"process" + index} className="relative w-full">
                    <div className="flex flex-row rounded-full w-full items-center justify-start border border-[#ffffff20] py-6 px-20 gap-10">
                      <div className="flex flex-col">
                        <div className="bg-gradient-to-r from-[#ff7e7e] to-[#f05f82] text-transparent bg-clip-text">
                          STEP{index + 1}
                        </div>
                        <div className="text-[20px] text-white/80 font-bold">
                          {process.name}
                        </div>
                      </div>
                      <div className="flex flex-col break-keep text-[#aaaaaa]">
                        {process.description}
                      </div>
                    </div>
                    {index + 1 !== GENERAL_PROCESS.length ? (
                      <div className="absolute bottom-[-14px] left-1/2 transform -translate-x-1/2">
                        <div
                          className="w-[28px] h-[14px] 
                    border-t-[14px] border-t-[#ffffff20]
                    border-l-[14px] border-l-transparent
                    border-r-[14px] border-r-transparent"
                        />
                      </div>
                    ) : null}
                  </div>
                );
              })}
            </div>
          </div>
          <div
            data-aos="fade-left"
            className="flex flex-col flex-1 max-w-[780px] items-center justify-start py-16 bg-[#141414] text-white relative rounded-[20px] overflow-hidden border border-[#ffffff30]"
          >
            <div className="text-[44px] font-bold bg-gradient-to-r from-[#ff4e8c] to-[#ffbb54] text-transparent bg-clip-text">
              Ploonet Quickserve™
            </div>
            <div className="text-[24px] font-bold mb-12">
              제작 시작 후 12~48시간 이내
            </div>
            <div className="flex flex-col flex-1 justify-center max-w-[700px] gap-8 whitespace-normal">
              {QUICKSERVE_PROCESS.map((process, index) => {
                return (
                  <div
                    key={"quickserve" + index}
                    className="relative flex flex-row rounded-full w-full items-center justify-start border border-[#ffffff20] py-6 pr-4 pl-10"
                  >
                    <div
                      className="absolute inset-0 rounded-full border border-transparent"
                      style={{
                        background:
                          "linear-gradient(to right, #ff4e8c, #ffbb54) border-box",
                        WebkitMask:
                          "linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0)",
                        WebkitMaskComposite: "xor",
                        maskComposite: "exclude",
                        zIndex: -1,
                      }}
                    />
                    <div className="flex flex-row w-full items-center justify-end gap-4">
                      <div className="flex flex-row items-center flex-1 gap-6">
                        <div>
                          <img
                            alt={"quickserve_icon_" + index}
                            src={process.icon}
                          />
                        </div>
                        <div className="flex flex-col gap-1">
                          <div className="text-[16px]">STEP{index + 1}</div>
                          <div className="text-[22px] font-bold">
                            {process.name}
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-1 max-w-[200px] text-[18px] text-[#aaaaaa]">
                        {process.description}
                      </div>
                    </div>
                    {index + 1 !== QUICKSERVE_PROCESS.length ? (
                      <div className="absolute bottom-[-14px] left-1/2 transform -translate-x-1/2">
                        <div
                          className="w-[28px] h-[14px] 
                    border-t-[14px] border-t-[#ffffff20]
                    border-l-[14px] border-l-transparent
                    border-r-[14px] border-r-transparent"
                        />
                      </div>
                    ) : null}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="text-[14px] text-[#999999] text-center">
          * 2~3분, 표준 견적에 따르는 일반 영상편집에 한정되며, 모션그래픽 등
          전문 작업이 필요할 경우 작업 기간은 조정될 수 있습니다.{"\n"}** 영상의
          길이, 영상 제작에 필요한 수준에 따라 빠른 작업이 불가할 수 있으며,
          클라이언트와의 협의를 통해 작업 기간을 조정할 수 있습니다.
        </div>
      </div>
    );
  };

  return (
    <main className="bg-black relative pt-[118px] max-md:pt-[60px] pb-10 overflow-hidden whitespace-pre">
      <div
        className="hidden md:flex z-10 fixed right-10 top-40 cursor-pointer"
        onClick={scrollToInquiry}
      >
        <img
          alt="video maker inquiry"
          src="/quickserve/inquiry.png"
          width={125}
          height={125}
        />
      </div>
      <div className="flex items-center justify-center w-full h-auto mx-auto relative mb-10 md:mb-40">
        <div className="relative flex items-center justify-center w-auto h-full max-h-[875px] ratio-[4/3] overflow-hidden">
          <video
            className="w-full h-full cursor-pointer"
            autoPlay
            playsInline
            muted={isMuted}
            onClick={toggleMute}
          >
            <source src="/quickserve/main.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <div
            className="absolute bottom-10 right-10 flex bg-white/40 rounded-full p-1 z-50 cursor-pointer"
            onClick={toggleMute}
          >
            <img
              src={isMuted ? "/quickserve/mute.svg" : "/quickserve/unmute.svg"}
            />
          </div>
        </div>
      </div>
      <div data-aos="fade-up">
        <QuickServeFirstSection />
      </div>
      <div data-aos="fade-up">
        <QuickServeSecondSection />
      </div>
      <div className="w-full bg-black flex flex-col items-center justify-center gap-[60px]">
        <div className="relative text-white text-[20px] md:text-[48px] font-bold mt-[102px] md:mt-[182px] md:mb-[56px]">
          MAKING PROCESS
          <div className="absolute top-0 left-[-10px] md:left-[-20px] w-2 h-2 md:w-4 md:h-4 rounded-full bg-gradient-to-br from-[#ff4e83] to-[#ffbb54]" />
        </div>
        {renderDesktopProcess()}
        {renderMobileProcess()}
      </div>
      <div id="inquiry" />
      <InquirySection />
    </main>
  );
}
