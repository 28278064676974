import { Dialog, Transition } from '@headlessui/react';
import moment from 'moment';
import { Fragment, SetStateAction } from 'react';
import CloseIcon from '../../vector/common/CloseIcon';

const screenSize = window.innerWidth;

export default function PrivacyDetailModal({
  closeModal,
  showDetail,
  termsTxt,
  termsYmd,
}: {
  closeModal: React.Dispatch<SetStateAction<boolean>>;
  showDetail: boolean;
  termsTxt: any;
  termsYmd: string;
}) {
  return (
    <>
      <Transition appear show={showDetail} as={Fragment}>
        <Dialog as="div" className="fixed z-50" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 max-md:inset-auto max-md:bottom-0 overflow-y-hidden">
            <div className="flex min-h-full items-center justify-center p-4 max-md:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95 max-md:scale-100 max-md:translate-y-96"
                enterTo="opacity-100 scale-100 max-md:translate-y-0"
                leave="ease-in duration-300"
                leaveFrom="opacity-100 scale-100 max-md:translate-y-0"
                leaveTo="opacity-0 scale-95 max-md:scale-100 max-md:translate-y-96"
              >
                <Dialog.Panel className="relative w-full rounded-xl max-md:rounded-b-none max-w-[1200px] max-md:h-[600px] transform overflow-hidden bg-white py-16 px-[60px] max-md:py-[30px] max-md:px-5 transition-all max-md:min-w-screen">
                  <Dialog.Title
                    as="h3"
                    className="text-[40px] max-md:text-xl flex justify-between items-center pb-9 max-md:pb-5 font-bold leading-6 text-left border-b-2 border-black w-full"
                  >
                    <span>
                      개인정보처리방침 {moment(termsYmd).format('YYYY-MM-DD')}
                    </span>
                    <button onClick={() => closeModal(false)}>
                      <CloseIcon
                        isBig={screenSize < 769 ? false : true}
                        isDark={false}
                      />
                    </button>
                  </Dialog.Title>

                  <section className="terms-container space-y-10 h-[520px] inquiry-detail overflow-y-scroll pb-24 max-md:pb-10">
                    <div
                      className="privacy-detail_modal"
                      dangerouslySetInnerHTML={termsTxt && { __html: termsTxt }}
                    />
                  </section>
                  <div className="absolute bottom-16 max-md:bottom-0 w-full h-28 max-md:h-10 bg-gradient-to-t from-white to-white/10" />
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
