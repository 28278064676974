import { Dispatch, SetStateAction, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { sendEmailRequest } from '../../../services/RegCertService';
import Loader from '../../common/Loader';
import InformModal from '../../common/modal/InformModal';
import AuthTimer from '../../hooks/AuthTimer';
import { emailRegex } from '../../hooks/useValidate';

const inputStyle =
  'w-full h-full py-4 px-3 rounded-lg border border-borderLightGray focus:border-primary max-md:p-3 max-md:placeholder:text-sm';
const divStyle = 'flex flex-col space-y-2 max-2xl:text-base text-lg';
export default function DormantForeigner({
  emailCheckInfo,
  setEmailCheckInfo,
  requestWakeUpAccount,
}: {
  emailCheckInfo: {
    authSendUuid: string;
    authCode: string;
  };
  setEmailCheckInfo: Dispatch<
    SetStateAction<{
      authSendUuid: string;
      authCode: string;
    }>
  >;
  requestWakeUpAccount: () => void;
}) {
  return (
    <div className="text-left flex flex-col space-y-10 max-md:space-y-5">
      <EmailMethod
        emailCheckInfo={emailCheckInfo}
        setEmailCheckInfo={setEmailCheckInfo}
        requestWakeUpAccount={requestWakeUpAccount}
      />
    </div>
  );
}

function EmailMethod({
  emailCheckInfo,
  setEmailCheckInfo,
  requestWakeUpAccount,
}: {
  emailCheckInfo: {
    authSendUuid: string;
    authCode: string;
  };
  setEmailCheckInfo: Dispatch<
    SetStateAction<{
      authSendUuid: string;
      authCode: string;
    }>
  >;
  requestWakeUpAccount: () => void;
}) {
  const [modal, setModal] = useState<{
    isOpen: boolean;
    phrase: string;
    secondPhrase?: string;
    content?: string;
  }>({
    isOpen: false,
    phrase: '',
    secondPhrase: '',
    content: '',
  });
  const [isSent, setIsSent] = useState(false);
  const [time, setTime] = useState(600);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [isAuthorized, setIsAuthorized] = useState(false);
  const navigate = useNavigate();

  const sendAuthEmail = async () => {
    if (!emailRegex.test(email)) {
      setModal({
        isOpen: true,
        phrase: 'Invalid email address',
        secondPhrase: 'Please check your email.',
      });
      return;
    }
    setLoading(true);
    sendEmailRequest({
      siteLangCd: 'EN',
      memberEmail: email,
    })
      .then((res) => {
        const resData = res.data.data;
        setModal({
          isOpen: true,
          phrase: 'The verification e-mail was sent.',
          content: `Enter the verification code in the verification e-mail sent to ${email} within 10 minutes (validity period).`,
        });
        setIsSent(true);
        setTime(600);
        setEmailCheckInfo((prev) => ({
          ...prev,
          authSendUuid: resData.authSendUuid,
        }));
      })
      .catch((err) => {
        alert(err);
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      <Loader loading={loading} />
      <InformModal
        modal={modal.isOpen}
        phrase={modal.phrase}
        secondPhrase={modal.secondPhrase}
        content={modal.content}
        closeModal={() => {
          if (modal.phrase === '휴면 상태가 해제되었습니다.') {
            navigate('/');
          } else {
            setModal((prev) => ({
              ...prev,
              isOpen: false,
            }));
          }
        }}
      />
      <div className={divStyle}>
        <span className="md:text-lg">ID (E-mail)</span>
        <div className="flex space-x-3">
          <input
            maxLength={60}
            onKeyDown={(e) => e.key === 'Enter' && sendAuthEmail()}
            className={`${
              isAuthorized
                ? 'bg-gray5 border-[#27AE60]'
                : 'border-borderLightGray'
            } w-full h-full py-4 px-3 rounded-lg border focus:border-primary max-md:p-3 max-md:placeholder:text-sm`}
            onChange={(e) => setEmail(e.target.value)}
            disabled={isAuthorized}
            type="email"
            placeholder="Please enter your email address."
          />
          <button
            onClick={sendAuthEmail}
            disabled={isAuthorized}
            className={`min-w-[140px] max-2xl:min-w-[120px] py-3 max-md:text-sm border border-black rounded-lg hover:bg-slate-100 ${
              isAuthorized && 'border-borderLightGray text-gray2 hover:bg-white'
            }`}
          >
            {isSent ? 'Resend Code' : 'Send Code'}
          </button>
        </div>
        {isSent && !isAuthorized && (
          <div className="flex space-x-3">
            <div className="w-full relative">
              <input
                onKeyDown={(e) => e.key === 'Enter' && requestWakeUpAccount()}
                className={inputStyle}
                type="text"
                maxLength={6}
                value={emailCheckInfo.authCode}
                onChange={(e) =>
                  setEmailCheckInfo((prev) => ({
                    ...prev,
                    authCode: e.target.value,
                  }))
                }
                placeholder="Enter verification code."
              />
              {!isAuthorized && (
                <div className="absolute right-5 bottom-1/2 translate-y-1/2">
                  <AuthTimer time={time} setTime={setTime} />
                </div>
              )}
            </div>
            <button
              onClick={requestWakeUpAccount}
              className="min-w-[140px] max-2xl:min-w-[120px] py-3 max-2xl:text-sm bg-black text-white rounded-lg hover:bg-opacity-80"
            >
              Confirm
            </button>
          </div>
        )}
        <span
          className={`${
            isAuthorized ? 'text-[#27AE60]' : 'text-textGray'
          } max-2xl:text-xs text-sm`}
        >
          {isAuthorized
            ? 'Email address verification is complete.'
            : isSent
            ? 'Please check the verification code sent via e-mail.'
            : ''}
        </span>
      </div>
    </>
  );
}
