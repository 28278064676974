export default function CertifiedMark({
  width,
  id
}: {
    width: '80' | '50';
    id: 'pc' | 'mobile';
  }) {

  const handleClickMark = () => {
    window.open('https://cleanspam.or.kr/ci/35', '_blank', 'width=800, height=1151, toolbar=no, menubar=no, scrollbars=no, resizable=no');
  };

  return (
    <img
      width={width}
      height={width}
      style={{cursor:"pointer"}}
      onClick={handleClickMark}
      src="https://yeorcqadlpopsmgaoudu.supabase.co/storage/v1/object/public/certification/mark/292d5107-e11a-4079-bb97-e411022d41d9/cd78b33e-f3a1-445c-afa2-d466c5df45e1-2024-08-28T07:01:44.523Z"
    />
  );
}
