import TokenService from '../services/token.service';
import {
  PasswordChangeDtoInf,
  UnionListClientReqType,
} from './TypeOfPrivateService';
import initAxios from './defaultClient';
const privateNameSpace = '/api/br/private/v1';
const axios = initAxios();

export const getWebInquiryList = async (reqBody: {
  siteLangCd: 'KR' | 'EN';
  perPage: number;
  pageNo: number;
  searchTxt?: string;
  qnaCategoryCd?: string;
  serviceCd?: string;
  qnaStatus?: string;
}) => {
  return await axios.post(`${privateNameSpace}/qna`, reqBody);
};

export const getCreditsLeft = async (reqBody: {
  siteLangCd: 'KR' | 'EN';
  returnType: number;
}) => {
  return await axios.post(`${privateNameSpace}/union/creditLefts`, reqBody);
};

export const getMembershipsTotal = async (reqBody: {
  siteLangCd: string;
  returnType: number;
}) => {
  return await axios.post(`${privateNameSpace}/union/rateplans/total`, reqBody);
};

export const getMembershipLists = async (reqBody: {
  siteLangCd: 'KR' | 'EN';
  returnType: 0;
}) => {
  return await axios.post(`${privateNameSpace}/union/rateplans`, reqBody);
};

export const cancelMembership = async (reqBody: {
  detailId: number;
  siteLangCd: 'KR' | 'EN';
}) => {
  const { detailId } = reqBody;
  return await axios.delete(
    `${privateNameSpace}/union/rateplans/${detailId}/cancel`
  );
};

export const getPayMethodLists = async (reqBody: {
  siteLangCd: 'KR' | 'EN';
  monthType: '0';
  pageNo: 1;
  perPage: 100;
}) => {
  return await axios.post(`${privateNameSpace}/union/paymethods`, reqBody);
};

export const getEnterpriseLists = async (reqBody: {
  siteLangCd: 'KR' | 'EN';
  returnType: 0;
}) => {
  return await axios.post(`${privateNameSpace}/enterprise`, reqBody);
};

export const getPaymentDetail = async (
  reqBody: { siteLangCd: 'KR' | 'EN'; returnType: number },
  id: string
) => {
  return await axios.post(`${privateNameSpace}/union/payments/${id}`, reqBody);
};

export const getTelInquiryList = async (reqBody: {
  siteLangCd: 'KR' | 'EN';
  searchTxt: string;
  monthType: number;
  perPage: number;
  pageNo: number;
}) => {
  return await axios.post(`${privateNameSpace}/union/qnacalls`, reqBody);
};

export const getTelInquiryDetail = async (reqBody: {
  siteLangCd: 'KR' | 'EN';
  id: string;
}) => {
  const { siteLangCd, id } = reqBody;
  return await axios.post(`${privateNameSpace}/union/qnacalls/${id}`, {
    siteLangCd: siteLangCd,
  });
};

export const getTelInquiryVoice = async (reqBody: {
  siteLangCd: 'KR' | 'EN';
  wavPath: string;
}) => {
  const { siteLangCd, wavPath } = reqBody;
  return await axios.post(
    `${privateNameSpace}/union/qnacalls/${wavPath}/wav`,
    {
      siteLangCd,
    },
    { responseType: 'blob' }
  );
};

export const deleteInquiryData = async (args: any) => {
  return await axios.delete(`${privateNameSpace}/qna/${args}`);
};

export const registerWebInquiry = async (reqBody: any, file?: any) => {
  let formData = new FormData();
  formData.append('data', JSON.stringify(reqBody));
  formData.append('inquiryFilePath', file);
  return await axios.post(`${privateNameSpace}/qna/add`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const modifyUserInfo = async (
  reqBody: any,
  file?: any,
  memberTypeCd?: string
) => {
  let formData = new FormData();
  formData.append('data', JSON.stringify(reqBody));
  formData.append(
    memberTypeCd === 'M03A0' ? 'profileImagePath' : 'companyPath',
    file
  );
  return await axios.patch(`${privateNameSpace}/myinfo`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const reqCheckPassword = async (reqBody: {
  siteLangCd: 'KR' | 'EN';
  password: string;
}) => {
  return await axios.post(
    `${privateNameSpace}/myinfo/password/confirm`,
    reqBody
  );
};

export const requestWithdrawUser = async (reqBody: any) => {
  return await axios.post(`${privateNameSpace}/myinfo/withdraw`, reqBody);
};

export const reqGetPayToken = async () => {
  return await axios.post(`${privateNameSpace}/interidc/authentication`);
};

export const getMyInfo = async (reqBody: any) => {
  return await axios.post(`${privateNameSpace}/myinfo`, reqBody);
};

export const reqChangePassword = async (reqBody: PasswordChangeDtoInf) => {
  return await axios.patch(`${privateNameSpace}/myinfo/password`, reqBody);
};

// 결재내역 조회
export const getPaymentsListApi = async (reqBody: UnionListClientReqType) => {
  // console.info(reqBody);
  return await axios.post(`${privateNameSpace}/union/payments`, reqBody);
};

// 크레딧내역 조회
export const getCreditsListApi = async (reqBody: UnionListClientReqType) => {
  // console.info(reqBody);
  return await axios.post(`${privateNameSpace}/union/credits`, reqBody);
};

// 워크센터/손비서 서비스 내역
export const getServiceListApi = async (reqBody: UnionListClientReqType) => {
  // console.info(`${privateNameSpace}/union/onedaymon`);
  return await axios.post(`${privateNameSpace}/union/onedaymon`, reqBody);
};

//sns 연동 정보 조회
export const getSnsInfo = async () => {
  const ssoToken = TokenService.getLocalSsoToken();
  return await axios.post(`${privateNameSpace}/myinfo/snsInfo`, ssoToken);
};

/*
// sns 연동 등록
export const snsRegist = async (snsCode: string) => {
  return await axios.post(`${privateNameSpace}/myinfo/snsRegist/` + snsCode);
};
// sns 연동 해지
export const snsRevoke = async (snsCode: string) => {
  return await axios.post(`${privateNameSpace}/myinfo/snsRevoke/` + snsCode);
};
*/

// 기가입자 퀵스타트
export const exQuickSignUp = async (req: {}) => {
  return await axios.post(`${privateNameSpace}/member/apply-quickstart`, req);
};

// 기능 둘러보기 임시 토큰 발행
export const requestToken = async () => {
  return await axios.post(`${privateNameSpace}/union/tempLogin`);
};
