export default function UsageMailIcon() {
  return (
    <svg
      width="90"
      height="90"
      viewBox="0 0 90 90"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="45" cy="45" r="45" fill="white" />
      <path
        d="M26.75 37.2742C26.75 37.0794 26.9628 36.9595 27.1295 37.0603L44.0938 47.3282C44.6509 47.6654 45.3491 47.6654 45.9062 47.3282L62.8705 37.0603C63.0372 36.9595 63.25 37.0794 63.25 37.2742V56.7826C63.25 58.5775 61.7949 60.0326 60 60.0326H30C28.2051 60.0326 26.75 58.5775 26.75 56.7826V37.2742Z"
        stroke="#FF6D51"
        strokeWidth="1.5"
      />
      <path
        d="M37 30.7631C37 33.164 39.3446 35.6831 41.4952 37.4888C42.9599 38.7185 43.6922 39.3333 45 39.3333C46.3078 39.3333 47.0401 38.7185 48.5048 37.4888C50.6554 35.6831 53 33.164 53 30.7631C53 26.066 48.5999 24.3123 45 27.9408C41.4001 24.3123 37 26.066 37 30.7631Z"
        fill="url(#paint0_linear_1240_89865)"
      />
      <path
        d="M27 37L35 33"
        stroke="#FF6D51"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M55 33L63 37"
        stroke="#FF6D51"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1240_89865"
          x1="37"
          y1="26"
          x2="45.8779"
          y2="43.0433"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF478B" />
          <stop offset="1" stopColor="#FFBB54" />
        </linearGradient>
      </defs>
    </svg>
  );
}
