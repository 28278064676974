import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import SwiperCore, { Mousewheel } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import {
  metahumanButtonArray,
  sonButtonArray,
  studioButtonArray,
  quickServeButtonArray,
  workcenterButtonArray,
} from "../../array/home";
import InformModal from "../../components/common/modal/InformModal";
import MainImageSection from "../../components/home/MainImageSection";
import NewsSection from "../../components/home/NewsSection";
import "../../locale/i18n";
import { exQuickSignUp } from "../../services/PrivateService";
import { workcenterDomain } from "../../services/defaultClient";
import {
  assignedTelno,
  quickBannerAtom,
  slideIndex,
  userinfoAtom,
} from "../../store";
import { isLogin } from "../../utils/common";
import Footer from "../common/Footer";
import Loader from "../common/Loader";
import TopButton from "../common/TopButton";
import QuickstartModal from "../common/modal/QuickstartModal";
import { useUser } from "../hooks/use-user";
import ContactSection from "./Contactsection";
import BiFlow from "./bi-flow";
import EnglishCopy from "./english-copy";
import FloatingNavigation from "./floating-navigation";
import KoreanCopy from "./korean-copy";

SwiperCore.use([Mousewheel]);

export default function PcMainSection() {
  const [loading, setLoading] = useState(false);
  const [swiper, setSwiper] = useState<SwiperCore>();
  const [index, setIndex] = useRecoilState(slideIndex);
  const [informModal, setInformModal] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const screenHeight = window.innerHeight;
  const { t } = useTranslation();
  const [slideIdx, setSlideIdx] = useState(0);
  const [yValue, setYValue] = useState(0);
  const [quickModal, setQuickModal] = useState(false);
  const setAssignedTelno = useSetRecoilState(assignedTelno);
  const { myInfo } = useUser();
  const [activeIdx, setActiveIdx] = useState(0);
  const { language } = useRecoilValue(userinfoAtom);
  const quickBanner = useRecoilValue(quickBannerAtom);

  useEffect(() => {
    if (swiper) {
      if (index >= 0) swiper.slideTo(index);
      setIndex(-1);
    }
    if (index === 5) {
      setSlideIdx(5);
    }
  }, [swiper, index]);

  // sns로그인 요청시 해당 SNS계정이 등록되어 있지 않을 경우
  useEffect(() => {
    if (params.get("error") === "SNS_NOT_REGISTER") {
      setInformModal(true);
    }
  }, [params]);

  useEffect(() => {
    if (slideIdx === 7) {
      const swiperContainer = document.querySelector(".swiper-wrapper");
      swiperContainer?.setAttribute(
        "style",
        `transform: translate3d(0, -${yValue}px, 0); transition-duration: 800ms;`
      );
    }
  }, [slideIdx]);

  const handleQuickStart = () => {
    isLogin ? handleLoggedInUser() : navigate("/signup/quick/step01");
  };

  function handleLoggedInUser() {
    if (myInfo?.quickstartStateCd === 1) {
      setAssignedTelno(myInfo.assignTelno);
      navigate("/signup/quick/complete");
    } else {
      setQuickModal(true);
    }
  }

  const requestQuickSignUp = () => {
    setLoading(true);
    exQuickSignUp({})
      .then(({ data }) =>
        data.resultCd === "0000"
          ? handleSuccess(data.data.assignTelno)
          : handleError(String(data.data.result))
      )
      .catch(() => navigate("/signup/quick/error/-3"))
      .finally(() => setLoading(false));

    function handleSuccess(telno: string) {
      setAssignedTelno(telno);
      navigate("/signup/quick/complete");
    }

    function handleError(result: string) {
      navigate(`/signup/quick/error/${result}`);
    }
  };

  return (
    <>
      <Loader loading={loading} />
      <QuickstartModal
        isOpen={quickModal}
        onClick={requestQuickSignUp}
        closeModal={() => setQuickModal(false)}
      />
      <InformModal
        modal={informModal}
        phrase="SNS 간편 로그인은 통합회원 가입 후 이용 가능합니다."
        content="마이페이지> 내 정보> SNS 로그인 연동"
        closeModal={() => {
          setInformModal(false);
          navigate("/");
        }}
      />
      <FloatingNavigation activeIndex={activeIdx} />
      <section className="bg-black inquiry-detail h-screen overflow-y-hidden max-md:hidden">
        <TopButton isMain slideIdx={slideIdx} />
        <Swiper
          onActiveIndexChange={({ activeIndex }) => setActiveIdx(activeIndex)}
          onSlideChange={(e) => setSlideIdx(e.activeIndex)}
          preventInteractionOnTransition={true}
          slidesPerView={1}
          onSwiper={(e) => {
            setYValue(e.snapGrid[6] + 652);
            setSwiper(e);
          }}
          speed={800}
          mousewheel
          height={screenHeight}
          spaceBetween={0}
          keyboard={{ enabled: true }}
          direction="vertical"
        >
          <SwiperSlide>
            <div className="w-full h-screen bg-black relative translate-y-16 2xl:translate-y-[70px]">
              <div
                className={`w-full swiper-animation bg-black text-white font-bold flex flex-col justify-center h-screen text-center ${
                  quickBanner && "pt-24"
                }`}
              >
                {language === "kr" ? <KoreanCopy /> : <EnglishCopy />}
                <div className="mx-auto w-2/5 h-px bg-gradient-to-r from-white/0 via-white/50 to-white/0 block mb-14 2xl:mb-20 mt-16 xl:mt-20 2xl:mt-[120px]" />
                <BiFlow />
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="bg-black h-screen w-screen pb-1/2">
              <div className="text-white font-bold text-[100px] max-md:text-4xl flex flex-col justify-center h-full px-5 max-md:pr-0">
                <MainImageSection
                  handleQuickStart={handleQuickStart}
                  subTitle={t("국내 유일 옴니채널 인공지능 비서")}
                  title={t("플루닛 워크센터")}
                  sectionButtonArray={workcenterButtonArray}
                  buttonText={t("무료시작하기")}
                  buttonLink={
                    isLogin
                      ? "https://workcenter.ploonet.com/quickStart/QuickStartStep1"
                      : "/signup/quick/step01"
                    // 'https://workcenter.ploonet.com/quickStart/QuickStartStep1'
                  }
                  routeUrl="/work-center"
                  secondButtonRouter="/"
                  vector="AliceNameCard"
                  secondVector="AmberNameCard"
                  mobileVector="MobileFirstSection"
                  section="workcenter"
                />
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="w-screen h-screen bg-black pb-1/2">
              <div className="text-white font-bold text-[100px] flex flex-col justify-center h-full px-5">
                <MainImageSection
                  handleQuickStart={handleQuickStart}
                  subTitle={t("생성형 인공지능 영상생성과 방송 플랫폼")}
                  title={t("플루닛 스튜디오")}
                  sectionButtonArray={studioButtonArray}
                  buttonText={t("Studio 시작하기")}
                  buttonLink="https://studio.ploonet.com/main"
                  routeUrl="/studio"
                  secondButtonRouter="/"
                  vector="studio"
                  mobileVector="MobileFirstSection"
                  section="studio"
                />
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="w-screen h-screen bg-black">
              <div className="text-white font-bold text-[100px] flex flex-col justify-center h-full px-5">
                <MainImageSection
                  handleQuickStart={handleQuickStart}
                  subTitle={t("맞춤형 AI 개인 비서")}
                  title={t("모바일앱 손비서")}
                  sectionButtonArray={sonButtonArray}
                  buttonText="앱 다운로드 받기"
                  buttonLink="/son"
                  routeUrl="/son"
                  secondButtonRouter="/"
                  vector="AliceNameCard"
                  secondVector="AmberNameCard"
                  mobileVector="MobileFirstSection"
                  section="son"
                />
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="w-screen h-screen bg-black">
              <div className="text-white font-bold text-[100px] flex flex-col justify-center h-full px-5">
                <MainImageSection
                  handleQuickStart={handleQuickStart}
                  subTitle={t("AI VIDEO ON YOUR DEMAND")}
                  title={t("플루닛 영상제작소")}
                  sectionButtonArray={quickServeButtonArray}
                  buttonText="제작 문의하기"
                  buttonLink={workcenterDomain}
                  routeUrl="/quickserve"
                  secondButtonRouter="/"
                  vector="AliceNameCard"
                  secondVector="AmberNameCard"
                  mobileVector="MobileFirstSection"
                  section="quickserve"
                />
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="w-screen h-screen bg-black">
              <div className="text-white font-bold text-[100px] flex flex-col justify-center h-full px-5">
                <MainImageSection
                  handleQuickStart={handleQuickStart}
                  subTitle={t("누구나 손쉽게 생성가능한 맞춤형 AI 가상인간")}
                  title={t("플루닛 메타휴먼")}
                  sectionButtonArray={metahumanButtonArray}
                  buttonText={t("나만의 메타휴먼 만들기")}
                  routeUrl="/metahuman"
                  secondButtonRouter="/"
                  vector="AliceNameCard"
                  secondVector="AmberNameCard"
                  mobileVector="MobileFirstSection"
                  section="metahuman"
                />
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="w-screen h-screen relative bg-black swiper-animation">
              <div className="absolute w-screen bottom-1/2 translate-y-1/2 pt-[100px]">
                <NewsSection />
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="w-screen h-screen bg-[#111111] flex justify-center items-center">
              <div>
                <ContactSection />
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide className="footer !h-fit relative">
            <Footer />
          </SwiperSlide>
        </Swiper>
      </section>
    </>
  );
}
