import { useTranslation } from 'react-i18next';
import { patentArray } from '../../array/aboutArray';

export default function PatentSection() {
  const { t } = useTranslation();
  return (
    <section
      data-aos="fade-up"
      className="border-b border-gray3 pb-[120px] max-md:pb-7"
    >
      <div className="flex max-md:flex-col max-md:space-y-5">
        <h1 className="min-w-[300px] max-2xl:min-w-72 font-bold text-[32px] max-2xl:text-3xl max-md:text-xl">
          {t('특허 및 수상내역')}
        </h1>

        <div className="grid grid-cols-2 max-md:grid-cols-1 gap-y-14 max-md:gap-y-6 gap-x-[60px]">
          {patentArray.map(({ heading, content, date }, idx) => (
            <div
              key={`patent-${idx}`}
              className="flex flex-col space-y-3 max-md:space-y-1"
            >
              <div className="flex space-x-2">
                <div className="w-2 h-2 max-md:w-[6px] max-md:h-[6px] bg-gradient-to-b from-[#FF478B] to-[#FFBB54] rounded-full" />
                <h3 className="text-sm max-md:text-xs text-black4 font-bold">
                  {t(heading)}
                </h3>
              </div>
              <span className="font-bold text-xl max-md:text-lg ml-4 w-full">
                {t(content)}
              </span>
              <span className="text-xs text-black1/60 ml-4">{t(date)}</span>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
