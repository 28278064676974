import moment from 'moment';
import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { getTelInquiryList } from '../../../services/PrivateService';
import { TelInquiryDataType } from '../../../services/TypeOfPrivateService';
import { siteLangCd } from '../../../services/defaultClient';
import { userinfoAtom } from '../../../store';
import { getParentPage } from '../../../utils/common';
import DetailButton from '../../common/DetailButtons';
import Skeleton from '../../common/MypageCardSkeleton';
import Pagination from '../../common/Pagination';
import { getTelDuration } from '../../hooks/useTime';
import DivIcon from '../../vector/payment-details/DivIcon';
import NoData from '../NoData';
import TelInquiryDetailModal from './TelInquiryDetailModal';

const perPage = 10;

export default function TelInquiryTab({ searchTxt }: { searchTxt: string }) {
  const [telInquiryList, setTelInquiryList] = useState<TelInquiryDataType[]>(
    []
  );
  const [loading, setLoading] = useState(false);
  const [isSearched, setIsSearched] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [total, setTotal] = useState(0);
  const [detailModal, setDetailModal] = useState({
    isOpen: false,
    id: '',
    wavPath: '',
  });
  const { language } = useRecoilValue(userinfoAtom);

  useEffect(() => {
    searchTxt ? setIsSearched(true) : setIsSearched(false);
  }, [searchTxt]);

  useEffect(() => {
    setLoading(true);
    getTelInquiryList({
      siteLangCd,
      pageNo,
      perPage,
      searchTxt,
      monthType: 0,
    })
      .then((res) => {
        setTotal(res.data.data.totalRows);
        setTelInquiryList(
          res.data.data.pageList.filter(
            (item: TelInquiryDataType) => item.durSec
          )
        );
      })
      .catch(console.log)
      .finally(() => setLoading(false));
  }, [searchTxt]);

  const onPaging = (page: number) => {
    window.scrollTo(0, 0);
    setPageNo(Number(page));
  };

  const getTelDate = (date: string) => {
    return moment(date).format('YYYY. MM. DD HH:mm');
  };

  return (
    <>
      <TelInquiryDetailModal
        closeModal={() =>
          setDetailModal((prev) => ({
            ...prev,
            isOpen: false,
          }))
        }
        id={detailModal.id}
        wavPath={detailModal.wavPath}
        detailModal={detailModal.isOpen}
      />
      <section className="space-y-3">
        {loading ? (
          <Skeleton isTelInquiry />
        ) : telInquiryList?.length ? (
          telInquiryList.map((item, idx) => (
            <div
              key={`item-${idx}`}
              className="w-full rounded-lg bg-white duration-200 ease-out border border-borderGray p-6 text-black2 max-md:p-6"
            >
              <section className="w-full flex justify-between items-center max-md:items-start max-md:flex max-md:flex-col max-md:w-full max-md:space-y-3">
                <div className="text-lg font-bold max-md:text-base max-md:max-w-[200px] line-clamp-1">
                  {item.msgTitle}
                </div>

                <div className="text-lg max-2xl:text-base max-md:w-full flex max-md:items-center max-md:justify-between space-x-7 max-md:space-x-0">
                  <div className="flex items-center space-x-7 max-2xl:space-x-5 max-md:space-x-0">
                    <span className="font-normal max-md:text-sm">
                      {getTelDuration(item.durSec, language)}
                    </span>
                    <i className="max-md:!mx-2">
                      <DivIcon />
                    </i>
                    <span className="max-md:text-sm">
                      {getTelDate(item.fdRegdate)}
                    </span>
                  </div>
                  <DetailButton
                    onClick={() =>
                      setDetailModal({
                        isOpen: true,
                        id: item.pkIssueContact,
                        wavPath: item.pkIssueContact,
                      })
                    }
                  />
                </div>
              </section>
            </div>
          ))
        ) : (
          <NoData isInquiry={!isSearched} />
        )}
      </section>

      <div className="mt-[60px]">
        {telInquiryList.length ? (
          <Pagination
            onPaging={onPaging}
            total={total}
            current={pageNo}
            textColor="text-black group-hover:text-white"
            strokeColor="black"
            parentPage={getParentPage()}
            posts_per_page={perPage}
            max={total >= perPage ? Math.floor(total / perPage) + 1 : 1}
          />
        ) : null}
      </div>
    </>
  );
}
