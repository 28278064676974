export default function DefaultCompanyLogo() {
  return (
    <svg
      className="w-[100px] max-md:w-[94px]"
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="50" cy="50" r="50" fill="#DDDDDD" />
      <g clipPath="url(#clip0_1240_87342)">
        <path
          d="M67.375 65.5H71.125V69.25H29.875V65.5H33.625V33.625C33.625 33.1277 33.8225 32.6508 34.1742 32.2992C34.5258 31.9475 35.0027 31.75 35.5 31.75H65.5C65.9973 31.75 66.4742 31.9475 66.8258 32.2992C67.1775 32.6508 67.375 33.1277 67.375 33.625V65.5ZM43 48.625V52.375H48.625V48.625H43ZM43 41.125V44.875H48.625V41.125H43ZM43 56.125V59.875H48.625V56.125H43ZM52.375 56.125V59.875H58V56.125H52.375ZM52.375 48.625V52.375H58V48.625H52.375ZM52.375 41.125V44.875H58V41.125H52.375Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_1240_87342">
          <rect
            width="45"
            height="45"
            fill="white"
            transform="translate(28 28)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
