import { t } from 'i18next';
import { SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import Button from '../../../components/common/Buttons';
import Loader from '../../../components/common/Loader';
import MyPageLayout from '../../../components/common/MyPageLayout';
import InformModal from '../../../components/common/modal/InformModal';
import PloonianImageSection from '../../../components/my-page/inquiry/PloonianImageSection';
import NotificationCheck from '../../../components/my-page/request/Notification';
import RequestRadioGroup from '../../../components/my-page/request/RadioGroup';
import ServiceSelectBox from '../../../components/request/ServiceSelectBox';
import DeleteIcon from '../../../components/vector/inquiry/DeleteIcon';
import { registerWebInquiry } from '../../../services/PrivateService';
import { siteLangCd } from '../../../services/defaultClient';
import { me } from '../../../store';

const divStyle =
  'py-7 border-b border-borderGray flex items-center max-md:flex-col max-md:items-start max-md:space-y-2 max-md:py-3 max-md:border-none';
const titleStyle = 'w-[200px] font-bold text-lg max-md:text-base';

export interface InquiryTypes {
  name: string;
  email: string;
  phoneNumber: string | null;
  serviceCd: string;
  qnaCategoryCd: string;
  inquiryTit: string;
  inquiryCtnt: string;
  attachment?: File | null;
  smsReplyYn: 'Y' | 'N';
  emailReplyYn: 'Y' | 'N';
}

export default function RequestInquiry() {
  const myInfo = useRecoilValue<{
    memberTypeCdNm: string;
    memberFnm1: string;
    memberEmail: string;
    mobileTelno: string;
    memberNickNm: string;
    managerTelno: string;
    managerEmail: string;
    modalId: string;
    styleId: string;
    modelNm: string;
  } | null>(me);
  const [informModal, setInformModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [requestInfo, setRequestInfo] = useState<InquiryTypes>({
    name: '',
    email: '',
    phoneNumber: '',
    serviceCd: 'B15',
    qnaCategoryCd: 'N0501',
    inquiryTit: '',
    inquiryCtnt: '',
    attachment: null,
    smsReplyYn: 'Y',
    emailReplyYn: 'Y',
  });
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    if (!myInfo) {
      return;
    }
    setRequestInfo((prev) => ({
      ...prev,
      name: myInfo.memberNickNm,
      email: myInfo.memberEmail,
      phoneNumber: myInfo.mobileTelno,
    }));
  }, [myInfo]);

  const requestInquiryForm = () => {
    setLoading(true);
    const {
      serviceCd,
      qnaCategoryCd,
      inquiryTit,
      inquiryCtnt,
      attachment,
      smsReplyYn,
      emailReplyYn,
    } = requestInfo;
    registerWebInquiry(
      {
        siteLangCd,
        serviceCd,
        qnaCategoryCd,
        inquiryTit,
        inquiryCtnt,
        smsReplyYn,
        emailReplyYn,
      },
      attachment
    )
      .then(() => {
        setInformModal(true);
      })
      .catch((err) => alert(err))
      .finally(() => setLoading(false));
  };

  return (
    <MyPageLayout>
      <InformModal
        phrase={t('문의글 등록이 완료되었습니다.')}
        modal={informModal}
        closeModal={() => navigate('/my-page/inquiry')}
      />
      <Loader loading={loading} />
      <div className="w-full max-md:mb-0 max-md:w-full">
        <PloonianImageSection />
        <div className="border-b-2 border-black pb-5 flex items-end justify-between max-md:mt-5 max-md:flex-col max-md:items-baseline max-md:space-y-2">
          <h1 className="font-bold text-4xl max-md:text-2xl">
            {t('문의글 남기기')}
          </h1>
          <span className="text-sm text-[#797979]">
            <i className="text-[#FF7B51] not-italic">*</i>{' '}
            {t('표시는 반드시 입력하셔야 합니다.')}
          </span>
        </div>

        <FixedInfoBox label="이름" displayInfo={requestInfo.name} />
        <FixedInfoBox label="이메일" displayInfo={requestInfo.email} />

        {myInfo?.memberTypeCdNm === '개인' && (
          <FixedInfoBox
            label="휴대폰 번호"
            displayInfo={requestInfo.phoneNumber}
          />
        )}

        <div className={divStyle}>
          <div className={titleStyle}>
            {t('서비스')}
            <i className="text-[#FF7B51] ml-1 not-italic">*</i>
          </div>
          <ServiceSelectBox setRequestInfo={setRequestInfo} />
        </div>
        <div className={divStyle}>
          <div className={titleStyle}>
            {t('문의유형')}
            <i className="text-[#FF7B51] ml-1 not-italic">*</i>
          </div>
          <RequestRadioGroup
            requestInfo={requestInfo}
            setRequestInfo={setRequestInfo}
          />
        </div>
        <div className={divStyle}>
          <div className={titleStyle}>
            {t('제목')}
            <i className="text-[#FF7B51] ml-1 not-italic">*</i>
          </div>
          <input
            spellCheck={false}
            maxLength={30}
            type="text"
            onChange={(e) => {
              setRequestInfo((prev) => ({
                ...prev,
                inquiryTit: e.target.value,
              }));
            }}
            placeholder={t('제목을 입력해주세요.')}
            className="border border-borderLightGray focus:border-primary py-2 px-3 rounded-md w-2/3 text-lg placeholder:text-lg max-md:w-full placeholder:max-md:text-base max-md:text-base focus:outline-4"
          />
        </div>
        <div className={divStyle}>
          <div className={titleStyle}>
            {t('문의내용')}
            <i className="text-[#FF7B51] ml-1 not-italic">*</i>
          </div>
          <textarea
            onKeyDown={(e) =>
              e.key === 'Enter' &&
              setRequestInfo((prev) => ({
                ...prev,
                inquiryCtnt: '\n',
              }))
            }
            spellCheck={false}
            onChange={(e) => {
              setRequestInfo((prev) => ({
                ...prev,
                inquiryCtnt: e.target.value,
              }));
            }}
            placeholder={t('내용을 입력해주세요.')}
            className="border border-borderLightGray focus:border-primary py-2 px-3 rounded-md w-2/3 h-40 text-lg placeholder:text-lg resize-none max-md:w-full placeholder:max-md:text-base max-md:text-base"
          />
        </div>
        <AttachmentSection
          setRequestInfo={setRequestInfo}
          requestInfo={requestInfo}
        />
        <NotificationCheck
          myInfo={myInfo}
          setRequestInfo={setRequestInfo}
          requestInfo={requestInfo}
          divStyle={divStyle}
        />
        <div className="flex justify-center space-x-2 mt-14">
          <Link className="max-md:w-full" to="/my-page/inquiry">
            <Button color="white">{t('취소')}</Button>
          </Link>
          <Button onClick={requestInquiryForm}>{t('확인')}</Button>
        </div>
      </div>
    </MyPageLayout>
  );
}

function AttachmentSection({
  setRequestInfo,
  requestInfo,
}: {
  setRequestInfo: React.Dispatch<SetStateAction<InquiryTypes>>;
  requestInfo: InquiryTypes;
}) {
  const [informModal, setInformModal] = useState(false);
  const [key, setKey] = useState(0);

  useEffect(() => {
    setRequestInfo({ ...requestInfo });
  }, [requestInfo.attachment]);

  const formatBytes = (bytes: number | undefined, decimals = 2) => {
    if (!bytes) return;

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
  };

  const onChangeFileHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || !e.target.files[0]) {
      return;
    } else if (e.target.files[0].size > 1e7) {
      setInformModal(true);
      return;
    }
    setRequestInfo((prev) => ({
      ...prev,
      attachment: e.target.files && e.target.files[0],
    }));
  };

  return (
    <>
      <InformModal
        modal={informModal}
        phrase={t('파일 제한 용량을 초과했습니다.')}
        closeModal={() => setInformModal(false)}
      />
      <div className="flex items-center py-7 border-b border-borderGray max-md:flex-col max-md:space-y-2 max-md:py-4 max-md:border-none">
        <div className="min-w-[200px] max-md:min-w-full font-bold text-lg max-md:text-base">
          {t('첨부파일')}
        </div>
        <div className="relative flex flex-col space-y-2 w-full">
          <div className="flex items-center space-x-2 w-full">
            <div className="relative bg-white border border-[#D7D7D7] rounded-md w-2/3 h-14 px-3 flex items-center group max-md:w-full">
              <span className="max-md:truncate max-md:w-36 max-md:text-sm">
                {requestInfo.attachment ? requestInfo.attachment.name : ''}
              </span>
              <span className="text-xs text-[#797979] mb-2 absolute right-[50px] max-md:right-9 bottom-[10px] max-2xl:bottom-[11px]">
                {formatBytes(requestInfo.attachment?.size)}
              </span>
              {requestInfo.attachment && (
                <button
                  onClick={() => {
                    setRequestInfo((prev) => ({
                      ...prev,
                      attachment: null,
                    }));
                    setKey((prev) => prev + 1);
                  }}
                  className="max-md:block absolute bottom-5 max-2xl:bottom-[21px] right-3 duration-100 ease-out max-md:text-[#FF7B51]"
                >
                  <DeleteIcon />
                </button>
              )}
            </div>
            <div>
              <label
                className="rounded-md border border-black2 text-center py-[15px] max-md:py-4 cursor-pointer w-[140px] max-md:w-[88px] !inline-block max-md:text-sm"
                htmlFor="attachment"
              >
                {t('파일선택')}
                <input
                  key={key}
                  type="file"
                  id="attachment"
                  className="hidden"
                  accept=".png, .jpeg, .png, .pdf, .mp4"
                  onChange={(e) => onChangeFileHandler(e)}
                />
              </label>
            </div>
          </div>
          <span className="text-sm text-black5">
            {t(
              '최대1개, 10MB 이하 / 파일지원형식: gif, jpg, png, pdf, mp4 파일만 등록 가능'
            )}
          </span>
        </div>
      </div>
    </>
  );
}

function FixedInfoBox({
  label,
  displayInfo,
}: {
  label: string;
  displayInfo: string | null;
}) {
  return (
    <div className="py-7 border-b border-borderGray flex text-lg max-md:text-base max-md:justify-between max-md:py-4 max-md:border-none">
      <div className="w-[200px] font-bold">{t(label)}</div>
      <div className="text-[#666666]">{displayInfo}</div>
    </div>
  );
}
