import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import AuthService from '../../services/auth.service';

const SsoRedirect = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const prevPath = sessionStorage.getItem('prevPath');
  const redirectUrl = prevPath ? prevPath : '/';

  sessionStorage.setItem('ssoLoginCheck', true);

  useEffect(() => {
    if (params.get('error')) {
      // 에러 발생
      document.location.href = '/?error=' + params.get('error');
    } else if (params.get('code') == null) {
      //SSO 로그인 안됨
      document.location.href = redirectUrl;
    } else if (params.get('code')) {
      AuthService.ssoLogin(params.get('code'))
        .then((res) => {
          //SNS 연동/해지 후 팝업 메세지  처리
          if (opener) {
            opener.postMessage(
              {
                snsSuccessFlag: params.get('rd_c_p'),
              },
              '*'
            );
            self.close();
          } else {
            //SSO 처리
            document.location.href = redirectUrl;
          }
        })
        .catch((err) => (document.location.href = redirectUrl));
    }
  }, []);
  return null;
};

export default SsoRedirect;
