import clsx from 'clsx';
import { ButtonHTMLAttributes } from 'react';
import HomeButtonIcon from '../vector/home/HomeButtonIcon';

export interface ButtonTypeProps
  extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: string;
  color?: 'orange' | 'white' | 'mobile orange' | 'main white';
  icon?: boolean;
}

export default function HomeButton({
  children,
  color = 'orange',
  icon,
  ...props
}: ButtonTypeProps) {
  return (
    <button
      className={clsx(
        'py-4 px-7 max-md:py-3 max-md:px-3 text-xl max-md:text-sm rounded-lg flex items-center space-x-4 h-fit group max-md:space-x-2 justify-center max-md:w-fit',
        {
          'bg-primary border border-primary hover:bg-[#ff8168] text-white duration-100 ease-out':
            color === 'orange',
          'bg-primary border border-primary hover:bg-[#ff8168] text-white duration-100 ease-out max-md:!w-full font-bold':
            color === 'mobile orange',
          'bg-black hover:bg-slate-800 border border-white text-white duration-100 ease-out':
            color === 'white',
          'bg-black text-white hover:text-primary duration-100 ease-out font-extralight':
            color === 'main white',
          '!bg-gradient-to-b !from-gray1 !to-gray1 text-white cursor-not-allowed border-none':
            props.disabled,
        }
      )}
      {...props}
    >
      <span>{children}</span>
      {icon && (
        <i className="group-hover:translate-x-[2px] group-hover:-translate-y-[2px] duration-100 ease-out fill-green-500">
          <HomeButtonIcon color={color} />
        </i>
      )}
    </button>
  );
}
