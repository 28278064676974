import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { inquiryClicked, slideIndex } from '../../store';
import LinkIcon from '../vector/workcenter/LinkIcon';

const screenSize = window.innerWidth;

export default function BottomBanner({
  topText,
  bottomText,
  serviceName,
}: {
  topText: string;
  bottomText: string;
  serviceName: string;
}) {
  const { t } = useTranslation();
  const setIndex = useSetRecoilState(slideIndex);
  const setIsClicked = useSetRecoilState(inquiryClicked);
  return (
    <div
      data-aos="fade-up"
      className="flex max-md:flex-col max-md:space-y-8 max-md:items-start items-center justify-between w-full bg-no-repeat bg-cover bg-center rounded-xl text-white px-20 py-24 max-2xl:px-16 max-2xl:py-20 max-md:px-8 max-md:py-10"
      style={{
        backgroundImage: "url('/workcenter/workcenter-button.png')",
      }}
    >
      <div className=" flex flex-col text-4xl max-2xl:text-3xl max-md:text-xl whitespace-pre-wrap leading-snug">
        <span className="font-light">{topText}</span>
        <span className="font-bold">{bottomText}</span>
      </div>
      <Link to="/">
        <button
          onClick={() => {
            if (screenSize < 769) {
              setIsClicked(true);
            } else {
              window.scrollTo(0, 0);
              setIndex(5);
            }
          }}
          className="flex items-center font-bold space-x-3 border border-white rounded-lg px-5 py-4 max-md:px-4 max-md:py-3 group hover:bg-white/20 duration-100 ease-out"
        >
          <span className="max-md:text-sm font-bold">
            {t(`${serviceName} 도입문의`)}
          </span>
          <i className="group-hover:translate-x-[2px] group-hover:-translate-y-[2px] duration-100 ease-out max-md:hidden">
            <LinkIcon />
          </i>
        </button>
      </Link>
    </div>
  );
}
