import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { linkArray } from '../../array/signup';
import MobileBackButton from '../../components/common/MobileBackButton';
import SignupButton from '../../components/common/SignupButtons';
import LinkIcon from '../../components/vector/signup/LinkIcon';
import { ENV } from '../../env';
import { ploonianTelNo } from '../../services/defaultClient';
import {
  SignUpInfo1BusinessAtom,
  SignUpInfo3Atom,
  SignUpInfoRegCertAtom,
  signupInfoForForeigner,
  userinfoAtom,
} from '../../store';

export default function SignupComplete() {
  const regCertResult = useRecoilValue(SignUpInfoRegCertAtom);
  const signUpInfo1Business = useRecoilValue(SignUpInfo1BusinessAtom);
  const selectedPloonian = useRecoilValue(SignUpInfo3Atom);
  const foreignRegisterInfo = useRecoilValue(signupInfoForForeigner);
  const { t } = useTranslation();
  const { language } = useRecoilValue(userinfoAtom);

  useEffect(() => {
    const goToMain = () => {
      history.pushState(null, '', '/');
    };
    history.pushState(null, '', '/');
    window.addEventListener('popstate', goToMain);
    return () => {
      window.removeEventListener('popstate', goToMain);
    };
  }, []);

  const getPloonianImage = useCallback(() => {
    return `${ENV.REACT_APP_AWS_URL}/${selectedPloonian.ploonianPath}/image/result/thumbnail/${selectedPloonian.faceCode}_${selectedPloonian.outfitCode}.png`;
  }, [selectedPloonian]);

  return (
    <section className="bg-[#F8F7F8] pt-[200px] pb-48 max-md:pb-24 max-md:pt-[62px]">
      <div className="hidden max-md:flex px-5 py-3">
        <MobileBackButton />
      </div>
      <div className="flex flex-col items-center space-y-12 max-md:space-y-4 justify-center text-center">
        <div className="font-bold text-3xl max-md:text-2xl space-y-2 tracking-wide max-md:tracking-normal">
          <h3 className="font-normal">{t('플루닛 통합회원이 되신 것을')}</h3>
          <h1>{t('환영합니다!')}</h1>
        </div>
        <div className="flex flex-col items-center max-md:px-5">
          <div className="flex flex-col items-center group">
            <img
              className="max-md:w-44 max-2xl:w-52 w-60 mb-7 max-md:mb-5"
              src={getPloonianImage()}
              alt="alice image"
            />
            <div className="bg-white shadow-xl flex flex-col space-y-3 px-10 max-md:px-8 py-7 rounded-xl text-xl max-md:text-lg duration-150 ease-out">
              <p>
                {language === 'kr' ? (
                  <>
                    {foreignRegisterInfo.memberFnm1 ||
                      regCertResult.staffName ||
                      signUpInfo1Business.companyName ||
                      ''}
                    님, 반가워요! 당신의 플루니안{' '}
                    <strong className="font-bold">
                      '{selectedPloonian.name}'
                    </strong>
                    입니다.
                    <br />
                    필요하신 사항이 있으시다면 언제든 저에게 연락주세요.
                  </>
                ) : (
                  <>
                    Nice to meet you,{' '}
                    {foreignRegisterInfo.genderCd === 'M' ? 'Mr. ' : 'Ms. '}
                    {foreignRegisterInfo.memberFnm2 || ''}! I'm{' '}
                    <strong className="font-bold">
                      '{selectedPloonian.name}'.
                    </strong>
                    <br />
                    Contact me if you need anything!
                  </>
                )}
              </p>
              <span className="text-primary font-bold">{t(ploonianTelNo)}</span>
            </div>
          </div>
          <div className="mt-10">
            <Link to="/">
              <SignupButton color="small orange">
                {t('홈으로 이동')}
              </SignupButton>
            </Link>
          </div>{' '}
          <div className="flex max-md:flex-col max-md:space-x-0 max-md:space-y-5 space-x-7 mt-10 font-bold text-lg">
            {linkArray.map(({ link, text }, idx) => (
              <button
                key={`completeLink-${idx}`}
                onClick={() => window.open(link)}
                className="flex items-center space-x-3 group"
              >
                <span>{t(`${text} 바로가기`)}</span>
                <i className="group-hover:translate-x-1 duration-100 ease-out">
                  <LinkIcon />
                </i>
              </button>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}
