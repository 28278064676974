import { t } from 'i18next';
import MagnifierIcon from '../vector/inquiry/MagnifierIcon';

export default function SearchBar({
  isMain,
  onChange,
}: {
  isMain?: boolean;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
}) {
  return (
    <div
      className={`relative max-md:w-full ${
        isMain ? 'w-[500px] max-2xl:w-80 max-md:min-w-[200px]' : 'w-[280px]'
      }`}
    >
      <input
        spellCheck={false}
        type="text"
        className={`px-5 max-md:px-4 py-3 text-lg max-md:text-sm text-gray-900 border border-[#cccccc] rounded-lg focus:border-primary w-full ${
          isMain &&
          'bg-black border !border-black4 focus:!border-primary text-white placeholder:text-black4'
        }`}
        onChange={(e) => onChange(e)}
        placeholder={t(isMain ? '검색어를 입력해주세요.' : '검색어 입력')}
      />
      <button className="text-white flex items-center absolute right-5 bottom-1/2 translate-y-1/2">
        <MagnifierIcon isWhite={!isMain} />
      </button>
    </div>
  );
}
