import clsx from 'clsx';
import { ButtonHTMLAttributes } from 'react';

export interface ButtonTypeProps
  extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: string;
  color?:
    | 'orange'
    | 'white'
    | 'small'
    | 'white small'
    | 'white small-en'
    | 'white small thin';
}

export default function Button({
  children,
  color = 'orange',
  ...props
}: ButtonTypeProps) {
  return (
    <button
      className={clsx(
        'py-3 md:py-4 font-bold text-base md:text-xl rounded-lg w-[200px] max-md:w-full max-2xl:text-lg',
        {
          'bg-gradient-to-r from-primary to-primaryTo hover:from-hoverPrimary hover:to-hoverPrimaryTo text-white':
            color === 'orange',
          'bg-white border border-borderLightGray hover:bg-gray5 text-black':
            color === 'white',
          'bg-gradient-to-r from-primary to-primaryTo hover:from-hoverPrimary hover:to-hoverPrimaryTo text-white max-md:!w-full max-md:max-w-full !w-[180px] !h-[80px] max-2xl:!w-[160px] max-2xl:!h-[60px]':
            color === 'small',
          'bg-white border border-borderLightGray hover:bg-gray5 text-black max-md:!w-full max-md:max-w-full !w-[180px] !h-[80px] max-2xl:!w-[160px] max-2xl:!h-[60px]':
            color === 'white small',
          'bg-white border border-borderLightGray hover:bg-gray5 text-black max-md:!w-full max-md:max-w-full !w-[400px] !h-[80px] max-2xl:!h-[60px]':
            color === 'white small-en',
          'bg-white border border-borderLightGray hover:bg-gray5 text-black w-[166px] !text-lg font-normal':
            color === 'white small thin',
          '!bg-gradient-to-b !from-[#cccccc] !to-[#cccccc] text-white cursor-not-allowed':
            props.disabled,
        }
      )}
      {...props}
    >
      {children}
    </button>
  );
}
