import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { ENV } from '../../../env';
import { requestToken } from '../../../services/PrivateService';
import { useUser } from '../../hooks/use-user';
import OrangeArrowIcon from '../../vector/myInfo/orange-arrow-icon';

type Props = {
  ploonianThumbnail: string;
  setPloonianModal: React.Dispatch<React.SetStateAction<boolean>>;
  isQuickStartUser: boolean;
  quickstartDetail: {
    assignTelno: string;
    modelId: string;
    modelNm: string;
    quickstartStartDt: string;
    stateCd: 1 | 0;
    styleId: string;
    styleNm: string;
    voiceId: string;
    voiceNm: string;
  };
};
export default function PloonianInfo({
  ploonianThumbnail,
  setPloonianModal,
  isQuickStartUser,
  quickstartDetail,
}: Props) {
  const { myInfo } = useUser();
  const { t } = useTranslation();
  const currentDate = moment();
  const expireDate = moment(quickstartDetail?.quickstartStartDt).add(
    30,
    'days'
  );
  const isExpired = currentDate.isSameOrAfter(moment(expireDate));
  const tel = myInfo?.assignTelno?.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');

  const goToWorkcenterWithToken = async () => {
    requestToken()
      .then(({ data }) =>
        data.resultCd === '0000'
          ? handleSuccess(data.data.authToken)
          : handleError()
      )
      .catch(handleError);

    function handleSuccess(token: string) {
      window.open(
        `https://workdesk.ploonet.com/workplace/TokenLogin?token=${token}&redirect=/workplace/quickStart`
      );
    }
    function handleError() {
      alert('잠시 후 다시 시도해주세요.');
    }
  };

  return (
    <div className="w-full flex max-lg:flex-col items-start md:items-center justify-between">
      <div className="flex flex-col">
        {isQuickStartUser && !isExpired && (
          <div className="py-3 px-4 w-fit rounded-3xl rounded-bl-none bg-gray5 font-bold max-md:text-sm mb-5 hidden md:block">
            AI직원으로 채용을 하셨네요!
          </div>
        )}
        <div className="flex items-start space-x-5 max-md:space-x-3">
          <div
            style={{ backgroundImage: `url(${ploonianThumbnail})` }}
            className="max-md:min-w-[48px] w-12 h-12 md:w-20 md:h-20 rounded-full bg-cover bg-center bg-no-repeat"
          />

          <div className="flex flex-col">
            {isQuickStartUser && !isExpired && (
              <div className="py-3 px-4 w-fit rounded-3xl rounded-bl-none bg-gray5 font-bold max-md:text-sm md:hidden mb-3">
                AI직원으로 채용을 하셨네요!
              </div>
            )}
            {isQuickStartUser ? (
              !isExpired && (
                <span className="text-xl md:text-2xl text-primary font-bold leading-none mb-1 md:mb-3">
                  {tel || ''}
                </span>
              )
            ) : (
              <div className="flex flex-col max-md:text-sm md:leading-6 mb-2 md:mb-3">
                <span className="font-bold">고객님의 전담 CS 직원입니다.</span>
                <span>
                  플루니안이 경영지원, 장애접수, 예약 업무도 대신할 수 있어요.
                </span>
              </div>
            )}
            {isQuickStartUser && (
              <>
                <div className="mb-2 md:mb-3 flex flex-col">
                  <span className="md:leading-none max-md:text-sm">
                    고객님의 AI직원 전용 전화번호는 <br className="md:hidden" />
                    <strong>
                      {expireDate.format('YYYY년 MM월 DD일')}
                      {isExpired ? '까지로 만료' : '까지 무료 사용'}
                    </strong>
                    {isExpired ? '되었습니다.' : '하실 수 있습니다.'}
                  </span>
                  {isExpired && (
                    <span className="max-md:text-sm">
                      이제 플루니안은 고객님 전담 CS 직원으로 계속 이용하실 수
                      있습니다.
                    </span>
                  )}
                </div>
                <span className="text-xs md:text-sm text-black4 mb-1">
                  {isExpired
                    ? '계속 AI업무를 경험해보고 싶으시다면 AI직원을 정식으로 채용해주세요!'
                    : '더 많은 AI업무를 경험해보고 싶으시다면 유료 기능 사전체험을 해보세요!'}
                </span>
              </>
            )}
            <button
              onClick={() => {
                isExpired
                  ? window.open('https://workdesk.ploonet.com/workplace/')
                  : isQuickStartUser
                  ? goToWorkcenterWithToken()
                  : window.open(ENV.REACT_APP_WORKCENTER_URL);
              }}
              className="w-fit text-sm text-primary font-bold flex items-center space-x-1 group"
            >
              <span>
                {isExpired
                  ? 'AI직원 만나러가기'
                  : isQuickStartUser
                  ? '기능 둘러보기'
                  : '워크센터 바로가기'}
              </span>
              <OrangeArrowIcon />
            </button>{' '}
            <button
              onClick={() => setPloonianModal(true)}
              className="border h-fit border-borderLightGray rounded-lg hover:bg-gray5 w-full md:w-fit cursor-pointer py-2 px-3 max-md:text-sm max-md:p-[10px] mt-4 md:hidden"
            >
              {t('상세보기')}
            </button>
          </div>
        </div>
      </div>
      <button
        onClick={() => setPloonianModal(true)}
        className="border h-fit border-borderLightGray rounded-lg hover:bg-gray5 w-full md:w-fit cursor-pointer py-2 px-3 max-md:text-sm max-md:p-[10px] max-md:hidden"
      >
        {t('상세보기')}
      </button>
    </div>
  );
}
