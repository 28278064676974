import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
} from '@chakra-ui/react';
import 'aos/dist/aos.css';
import { useCallback, useEffect, useRef, useState } from 'react';
import SwiperCore, { Navigation, Pagination } from 'swiper';
import 'swiper/css/navigation';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';

const isMobile = window.innerWidth < 769;

export default function WorkCenter() {
  const componentRef = useRef(null);

  SwiperCore.use([Navigation, Pagination]);
  const { t } = useTranslation();
  useEffect(() => {
    var AOS = require('aos');
    AOS.init({
      duration: 1000,
    });
  }, []);

  return (
    <main className="bg-black pt-[200px] max-md:pt-[98px] pb-10 overflow-hidden">
      <ShortcutBanner
        firstButton={t('Work Center 바로가기')}
        secondButton={t('서비스 이용안내')}
        firstLink={workcenterDomain}
        secondLink={`${workcenterDomain}/guide`}
        componentRef={componentRef}
        title={t('플루닛 워크센터')}
      />
      <div className="max-w-[1600px] max-2xl:max-w-[1200px] mx-auto space-y-48 max-md:space-y-20">
        <MainSection />
        <div ref={componentRef} data-aos="fade-up">
          <ProsSection />
        </div>
        <div className="max-md:px-5" data-aos="fade-up">
          <ReasonSection />
        </div>
        <div className="max-md:px-5" data-aos="fade-up">
          <DifferenceSection />
        </div>
        <div className="max-md:pt-0 max-md:px-5">
          <BottomBanner
            topText={t('플루닛 워크센터와 함께')}
            bottomText={t('비즈니스의 성과를 창출해보세요.')}
            serviceName="Work Center"
          />
        </div>
      </div>
    </main>
  );
}

function MainSection() {
  return (
    <section className="max-md:px-5">
      <div className="max-w-[1600px] mx-auto z-10 flex max-md:flex-col-reverse items-center justify-between max-md:items-end">
        <div
          data-aos="fade-right"
          className="w-full min-h-fit flex flex-col items-start"
        >
          <div className="space-y-10 max-md:space-y-0 max-md:mt-12">
            <SubpageTitle>Work Center</SubpageTitle>
            <div className="space-y-3 font-extralight text-white text-5xl max-2xl:text-4xl max-md:text-2xl max-md:!leading-9 max-md:space-y-0 max-md:!mt-2">
              <h1>{t('인공지능 직원을 채용해보세요.')}</h1>
              <h1>{t('24시간, 365일 쉬지 않는 AI 비서가')}</h1>
              <h1 className="font-bold">{t('당신을 위해 일합니다')}</h1>
            </div>
            <p className="text-white/70 font-extralight text-xl max-2xl:text-lg max-md:text-base mt-8 max-w-xl max-2xl:max-w-lg max-md:!mt-4">
              {t(
                '부족한 인력과 밤 늦은 시간과 주말 고객 대응, 점점 더 다양해지는 고객 채널 나의 부재중에도 대응해주고 메모해주는 당신만을 위한 1인 1 AI 비서가 직접 채용하신 비즈니스에 맞게 24시간 대응을 해드립니다.'
              )}
            </p>
          </div>
          <div className="space-x-5 max-md:space-x-3 flex mt-[60px] max-md:mt-10">
            <HomeButton
              onClick={() =>
                isMobile
                  ? window.open('https://mwc.ploonet.com')
                  : window.open(workcenterDomain)
              }
              icon={true}
            >
              {t('Work Center 바로가기')}
            </HomeButton>
            <div className="p-[1px] bg-gradient-to-br from-white to-white rounded-lg hover:from-primary hover:to-primaryTo">
              <HomeButton
                onClick={() => window.open(`${workcenterDomain}/guide`)}
                icon={true}
                color="main white"
              >
                {t('서비스 이용안내')}
              </HomeButton>
            </div>
          </div>
        </div>
        <div
          data-aos-duration="2000"
          data-aos="fade-left"
          className="relative w-full h-full"
        >
          <div className="flex relative w-full">
            <img src="/workcenter/workcenter-bg.png" />
          </div>
        </div>
      </div>
    </section>
  );
}

function ProsSection() {
  const swiperRef = useRef<any>();
  const [activeIdx, setActiveIdx] = useState(0);
  const [isReachedEnd, setIsReachedEnd] = useState(false);
  const { language } = useRecoilValue(userinfoAtom);
  const getLeftPercentage = useCallback(() => {
    let percentage = 16.6;
    percentage = percentage * activeIdx;
    return `${String(percentage.toFixed(1))}%`;
  }, [activeIdx]);

  return (
    <>
      <section className="space-y-14 max-md:space-y-5 max-md:pl-5">
        <div className="flex justify-between">
          <h1 className="text-5xl text-white max-2xl:text-4xl max-md:text-2xl flex font-extralight max-md:flex-col max-md:space-y-1">
            <SubPageDot />
            <span
              className={`${
                language === 'en' ? 'font-bold' : 'font-extralight'
              }`}
            >
              <strong
                className={`${
                  language === 'en' ? 'font-extralight' : 'font-bold'
                }`}
              >
                {t('AI 직원, ')}
              </strong>
              {t('어떤 일들을 할 수 있나요?')}
            </span>
          </h1>
          <div className="space-x-10 flex max-md:hidden">
            <button
              onClick={() => swiperRef.current?.slidePrev()}
              className="rotate-180"
            >
              <ChevronRight color={!isReachedEnd ? 'gray' : 'white'} />
            </button>
            <button onClick={() => swiperRef.current?.slideNext()}>
              <ChevronRight color={isReachedEnd ? 'gray' : 'white'} />
            </button>
          </div>
        </div>

        <div className="text-white max-md:w-full max-md:pr-0">
          <Swiper
            className="max-md:pr-5"
            onBeforeInit={(swiper) => {
              swiperRef.current = swiper;
            }}
            onActiveIndexChange={(e) => setActiveIdx(e.activeIndex)}
            onReachEnd={() => setIsReachedEnd(true)}
            onReachBeginning={() => setIsReachedEnd(false)}
            spaceBetween={16}
            slidesPerView={1.4}
            breakpoints={{
              768: {
                slidesPerView: 3,
                slidesPerGroup: 3,
                pagination: false,
                spaceBetween: 50,
              },
            }}
          >
            {prosArray.map(({ imgPath, text, subText }, idx) => (
              <SwiperSlide key={`pros-${idx}`}>
                <div className="space-y-8">
                  <div className="w-auto max-md:w-full h-[280px] max-md:h-[132px] max-2xl:w-[360px] overflow-hidden max-2xl:h-52 rounded-2xl max-md:rounded-md relative group">
                    <div
                      style={{ backgroundImage: `url(${imgPath})` }}
                      className={`space-y-6 h-full w-full rounded-2xl max-md:rounded-md bg-no-repeat bg-center bg-cover group-hover:scale-105 duration-500 ease-out`}
                    />
                  </div>
                  <div className="flex flex-col space-y-3">
                    <h1 className="text-2xl max-2xl:text-xl font-bold">
                      {t(text)}
                    </h1>
                    <span className="max-2xl:text-sm text-white text-opacity-60 max-md:pr-4">
                      {t(subText)}
                    </span>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </section>
      <section className="md:hidden px-5">
        <div className="relative w-full h-[2px] bg-black2 !mt-11">
          <div
            style={{ left: getLeftPercentage() }}
            className="absolute w-1/6 h-[2px] bg-primary duration-300 ease-out"
          />
        </div>
      </section>
    </>
  );
}

function ReasonSection() {
  return (
    <section className="space-y-14 max-md:space-y-5 text-white">
      <h1 className="text-5xl text-white max-2xl:text-4xl max-md:text-2xl flex font-extralight max-md:flex-col max-md:space-y-1">
        <SubPageDot />
        <div>
          <strong className="font-bold">{t('워크센터의 서비스,')}</strong>{' '}
          <br className="md:hidden" /> {t('당신의 비즈니스를 위해')}
          <br className="hidden max-md:block" /> {t('꼭 선택해야 합니다.')}
        </div>
      </h1>

      <section className="flex max-md:flex-col max-md:space-x-0 space-x-10 max-2xl:space-x-6 h-full max-md:h-fit">
        <div className="flex items-end min-h-full w-full bg-white bg-opacity-10 p-14 max-md:px-6 max-md:py-7 rounded-2xl relative overflow-hidden">
          <div
            style={{ backgroundImage: 'url(/subpage/workcenter/wave.jpg)' }}
            className="absolute w-full top-0 right-1/2 translate-x-1/2 max-md:hidden bg-contain h-full bg-no-repeat"
          />
          <div className="flex flex-col space-y-4">
            <h3 className="font-bold text-[28px] max-2xl:text-2xl max-md:text-lg">
              {t('초정밀 대화형 인공지능 기술')}
            </h3>
            <p className="text-black5 text-xl max-2xl:text-lg max-md:text-sm">
              {t(
                '딥러닝과 지식그래프 기술이 융합된 초정밀 3세대 뉴로 심블릭 대화형 인공지능 기술이 적용되어 어려운 질문에 정확하게 응답하고 생성형 인공지능인 Chat GPT도 탑재, 상담대화를 벗어난 다양한 일상의 대화도 자연스러운 응대가 가능'
              )}
            </p>
            <div className="flex items-center space-x-2">
              <i>
                <DialogueIcon />
              </i>
              <span className="text-xl max-2xl:text-lg max-md:text-base text-transparent bg-clip-text bg-gradient-to-br from-[#FF4E83] to-[#FFBB54]">
                {t('대화의 의도 파악과 정확한 응답')}
              </span>
            </div>
          </div>
        </div>
        <div className="flex flex-col w-full space-y-10 max-2xl:space-y-6 max-md:space-y-4 max-md:mt-4">
          {reasonSectionArray.map(({ title, paragraph, span, icon }, idx) => (
            <div
              key={`reason-${idx}`}
              className="bg-white bg-opacity-10 px-14 py-12 max-2xl:px-10 max-md:px-6 max-md:py-7 rounded-2xl space-y-3 flex flex-col"
            >
              <h3 className="font-bold text-[28px] max-2xl:text-2xl max-md:text-lg">
                {t(title)}
              </h3>
              <p
                className={`text-black5 text-xl max-2xl:text-lg max-md:text-sm ${
                  idx === 1
                    ? 'max-w-[530px] max-2xl:max-w-[480px]'
                    : 'max-w-[500px] max-2xl:max-w-[450px]'
                } max-md:max-w-none`}
              >
                {t(paragraph)}
              </p>
              <div className="flex items-center space-x-2">
                <i>{icon}</i>
                <span className="text-xl max-2xl:text-lg max-md:text-base text-transparent bg-clip-text bg-gradient-to-br from-[#FF4E83] to-[#FFBB54]">
                  {t(span)}
                </span>
              </div>
            </div>
          ))}
        </div>
      </section>
    </section>
  );
}

function DifferenceSection() {
  const { language } = useRecoilValue(userinfoAtom);
  const [imageUrl, setImageUrl] = useState(
    language === 'kr' ? '/workcenter/1.png' : '/workcenter/1en.png'
  );
  return (
    <section className="space-y-14 max-md:space-y-5 text-white">
      <h1 className="text-5xl text-white max-2xl:text-4xl max-md:text-2xl flex font-extralight max-md:flex-col max-md:space-y-1">
        <SubPageDot />
        <span>
          <strong className="font-bold">{t('워크센터, ')}</strong>
          {t('이런 점이 다릅니다.')}
        </span>
      </h1>

      <section className="flex items-center max-md:flex-col max-md:space-x-0 space-x-10">
        <div
          style={{
            backgroundImage: `url(${imageUrl})`,
          }}
          className="w-full h-[500px] bg-contain bg-center bg-no-repeat max-md:hidden opacity-1 duration-300"
        />
        <DifferenceDisclosure language={language} setImageUrl={setImageUrl} />
      </section>
    </section>
  );
}

import { t } from 'i18next';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import {
  differnceSectionArray,
  prosArray,
  reasonSectionArray,
} from '../../array/workcenterArray';
import BottomBanner from '../../components/common/BottomBanner';
import HomeButton from '../../components/common/HomeButtons';
import ShortcutBanner from '../../components/common/ShortcutBanner';
import SubPageDot from '../../components/common/SubpageDot';
import SubpageTitle from '../../components/common/SubpageTitle';
import PlusIcon from '../../components/vector/studio/PlusIcon';
import ChevronRight from '../../components/vector/workcenter/ChevronRight';
import DialogueIcon from '../../components/vector/workcenter/DialogIcon';
import MinusIcon from '../../components/vector/workcenter/MinusIcon';
import { workcenterDomain } from '../../services/defaultClient';
import { userinfoAtom } from '../../store';

function DifferenceDisclosure({
  setImageUrl,
  language,
}: {
  setImageUrl: React.Dispatch<React.SetStateAction<string>>;
  language: string;
}) {
  return (
    <section className="w-full h-full">
      <Accordion defaultIndex={0}>
        {differnceSectionArray.map(
          ({ title, content, imagePath, enImagePath }, idx) => (
            <AccordionItem
              className="border-b border-white border-opacity-[0.16] py-5 max-md:py-4"
              key={`dif-${idx}`}
            >
              {({ isExpanded }) => (
                <>
                  <h2
                    className={
                      isExpanded
                        ? 'text-primary text-2xl max-2xl:text-xl max-md:text-base font-bold'
                        : 'text-white/80 font-extralight text-xl max-2xl:text-lg max-md:text-base'
                    }
                  >
                    <AccordionButton
                      onClick={() =>
                        setImageUrl(language === 'kr' ? imagePath : enImagePath)
                      }
                    >
                      <Box as="span" flex="1" textAlign="left">
                        {t(title)}
                      </Box>
                      {isExpanded ? <MinusIcon /> : <PlusIcon />}
                    </AccordionButton>
                  </h2>
                  <AccordionPanel className="text-white/60 w-2/3 max-md:w-full font-extralight text-xl max-2xl:text-lg max-md:text-base mt-5">
                    {t(content)}
                    <div
                      style={{
                        backgroundImage: `url(${imagePath})`,
                      }}
                      className="w-full h-44 bg-contain bg-center bg-no-repeat mt-12 md:hidden"
                    />
                  </AccordionPanel>
                </>
              )}
            </AccordionItem>
          )
        )}
      </Accordion>
    </section>
  );
}
