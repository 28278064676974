export default function PlusIcon() {
  return (
    <svg
      width="41"
      height="40"
      viewBox="0 0 41 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28.2832 20.0001L20.2832 20.0001M20.2832 20.0001L12.2832 20.0001M20.2832 20.0001L20.2832 12M20.2832 20.0001L20.2832 28"
        stroke="#FF6D51"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
}
