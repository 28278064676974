import { t } from 'i18next';
import { useState } from 'react';
import MyPageLayout from '../../../components/common/MyPageLayout';
import InformModal from '../../../components/common/modal/InformModal';
import MembershipCardSection from '../../../components/my-page/membership/MembershipCardSection';
import ProductionCancelModal from '../../../components/my-page/membership/ProductionCancelModal';
import SubScriptionCancelModal from '../../../components/my-page/membership/SubscriptionCancelModal';

export default function Membership() {
  const [subCancel, setSubCancel] = useState({
    isOpen: false,
    itemName: '',
    limitDtTo: '',
    pkPpCard: 0,
  });
  const [prodCancel, setProdCancel] = useState({
    isOpen: false,
    limitDtTo: '',
    itemName: '',
    costPayback: 0,
    cost: 0,
    creditPolicy: 0,
    creditPolicyFree: 0,
    creditUsedRate: 0,
    credit: 0,
    creditFree: 0,
    pkPpCard: 0,
  });
  const [payModal, setPayModal] = useState(false);
  const [deleteKey, setDeleteKey] = useState(0);

  return (
    <MyPageLayout>
      <InformModal
        phrase="결제가 완료되었습니다."
        content="관리자 확인 후 크레딧이 지급되며 사용이 가능합니다."
        modal={payModal}
        closeModal={() => setPayModal(false)}
      />
      <ProductionCancelModal
        prodCancel={prodCancel.isOpen}
        itemName={prodCancel.itemName}
        limitDtTo={prodCancel.limitDtTo}
        cost={prodCancel.cost}
        creditPolicy={prodCancel.creditPolicy}
        creditPolicyFree={prodCancel.creditPolicyFree}
        creditFree={prodCancel.creditFree}
        credit={prodCancel.credit}
        creditUsedRate={prodCancel.creditUsedRate}
        costPayback={prodCancel.costPayback}
        detailId={prodCancel.pkPpCard}
        closeModal={() => setProdCancel((prev) => ({ ...prev, isOpen: false }))}
        setDeleteKey={setDeleteKey}
      />
      <SubScriptionCancelModal
        limitDtTo={subCancel.limitDtTo}
        subCancel={subCancel.isOpen}
        pkPpCard={subCancel.pkPpCard}
        itemName={subCancel.itemName}
        closeModal={() =>
          setSubCancel((prev) => ({
            ...prev,
            isOpen: false,
          }))
        }
        setDeleteKey={setDeleteKey}
      />
      <div className="w-full">
        <div className="mb-8 max-md:mb-6 max-md:w-full">
          <h1 className="font-bold text-4xl max-md:text-2xl">
            {t('요금제 관리')}
          </h1>
        </div>
        <MembershipCardSection
          setProdCancel={setProdCancel}
          setPayModal={setPayModal}
          setSubCancel={setSubCancel}
          deleteKey={deleteKey}
        />
      </div>
    </MyPageLayout>
  );
}
