import FaceBookIcon from '../components/vector/footer/SNS/FacebookIcon';
import InstagramIcon from '../components/vector/footer/SNS/InstagramIcon';
import NaverBlogIcon from '../components/vector/footer/SNS/NaverBlogIcon';
import YoutubeIcon from '../components/vector/footer/SNS/YoutubeIcon';

interface snsArrType {
  link: string;
  icon: JSX.Element;
}
export const snsArray: snsArrType[] = [
  {
    link: 'https://www.facebook.com/people/We-Ploonet/pfbid02P8VirVteqdLPrFvC5D4D3D26xFhLEveSgw3tyAYsAcGNP8UWPVv9G5hDitrDdYhPl/',
    icon: <FaceBookIcon />,
  },
  { link: 'https://blog.naver.com/saltluxmarketing', icon: <NaverBlogIcon /> },
  { link: 'https://www.youtube.com/@ploonet_official', icon: <YoutubeIcon /> },
  {
    link: 'https://www.instagram.com/ploonet_official/',
    icon: <InstagramIcon />,
  },
];

interface policyArrType {
  menu: '이용약관' | '개인정보처리방침';
  link: '/help/terms-use' | '/help/privacy-policy';
}
export const policyArray: policyArrType[] = [
  {
    menu: '이용약관',
    link: '/help/terms-use',
  },
  {
    menu: '개인정보처리방침',
    link: '/help/privacy-policy',
  },
];
