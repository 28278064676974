import axios from 'axios';
import { ENV } from '../env';
import TokenService from './token.service';

export const workcenterDomain =
  ENV.REACT_APP_SERVICE_MODE === 'production'
    ? ENV.REACT_APP_WORKCENTER_URL
    : ENV.REACT_APP_WORKCENTER_DEV_URL;

export const baseURL =
  ENV.REACT_APP_SERVICE_MODE === 'production'
    ? ENV.REACT_APP_API_URL
    : ENV.REACT_APP_API_DEV_URL;

export const fileBaseURL =
  ENV.REACT_APP_SERVICE_MODE === 'production'
    ? ENV.REACT_APP_BASE_URL
    : ENV.REACT_APP_DEV_BASE_URL;

export const ploonianTelNo =
  ENV.REACT_APP_SERVICE_MODE === 'production'
    ? '070-4500-0003'
    : '070-4500-0007';

export const siteLangCd =
  localStorage.getItem('selectedLanguage') === '{"userinfo":{"language":"en"}}'
    ? 'EN'
    : 'KR';

export default function initAxios() {
  const localToken = TokenService.getLocalAccessToken();
  const defaultClient = axios.create({
    baseURL,
    headers: localToken ? { Authorization: `Bearer ${localToken}` } : {},
  });
  return defaultClient;
}
