import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import QuickError from '../../../components/signup/quick/error-3';
import QuickError2 from '../../../components/signup/quick/error2';
import QuickError4 from '../../../components/signup/quick/error4';

const validCodes: string[] = ['-3', '-2', '-1', '3', '2', '4'];

export default function QuickSignupError() {
  const navigate = useNavigate();
  const { code } = useParams();

  const renderError = () => {
    switch (code) {
      case '-3':
      case '-2':
      case '-1':
      case '3':
        return <QuickError />;
      case '2': {
        return <QuickError2 />;
      }
      case '4': {
        return <QuickError4 />;
      }
    }
  };

  useEffect(() => {
    if (!code) return;
    if (!validCodes.includes(code)) {
      navigate('/');
    }
  }, [code]);

  return (
    <>
      <section className="bg-[#F8F7F8] pt-[180px] pb-48 max-md:pt-[62px] max-md:pb-24 max-md:bg-white">
        <div className="flex justify-center max-md:block">
          <div>
            <section className="mt-7 p-10 bg-white rounded-lg text-lg space-y-10 max-md:px-5 max-md:w-full max-md:mt-5 max-md:p-5 md:min-w-[720px] w-full">
              {renderError()}
            </section>
          </div>
        </div>
      </section>
    </>
  );
}
