import { ChevronRightIcon } from '@heroicons/react/20/solid';
import { t } from 'i18next';
import { useState } from 'react';
import PolicyModal from '../../signup/PolicyModal';
import CheckBox from '../../vector/common/CheckBox';
import CheckedBox from '../../vector/common/CheckedBox';

export default function AgreementSection({
  onChangeInfo,
  setOnChangeInfo,
  titleStyle,
}: {
  onChangeInfo: any;
  setOnChangeInfo: React.Dispatch<React.SetStateAction<any>>;
  titleStyle: string;
}) {
  const [policyModal, setPolicyModal] = useState(false);

  const handleOnChange = (
    checked: boolean,
    section: 'termsAgreeYn' | 'smsAgreeYn' | 'mailAgreeYn'
  ) => {
    switch (section) {
      case 'termsAgreeYn':
        setOnChangeInfo((prev: any) => ({
          ...prev,
          termsAgreeOptional: {
            ...prev.termsAgreeOptional,
            termsAgreeYn: checked ? 'Y' : 'N',
            mailAgreeYn: checked ? 'Y' : 'N',
            smsAgreeYn: checked ? 'Y' : 'N',
          },
        }));
        break;
      case 'smsAgreeYn':
        setOnChangeInfo((prev: any) => ({
          ...prev,
          termsAgreeOptional: {
            ...prev.termsAgreeOptional,
            smsAgreeYn: checked ? 'Y' : 'N',
            termsAgreeYn: checked ? 'Y' : 'N',
          },
        }));
        break;
      case 'mailAgreeYn':
        setOnChangeInfo((prev: any) => ({
          ...prev,
          termsAgreeOptional: {
            ...prev.termsAgreeOptional,
            mailAgreeYn: checked ? 'Y' : 'N',
            termsAgreeYn: checked ? 'Y' : 'N',
          },
        }));
        break;
    }
  };
  return (
    <>
      <PolicyModal
        policyModal={policyModal}
        termsCode="M01G0"
        closeModal={() => setPolicyModal(false)}
      />
      <section>
        <h1 className="font-bold text-[32px] max-2xl:text-3xl pb-5 border-b-2 border-black max-md:text-2xl mt-[60px] max-2xl:mt-12">
          {t('수신 설정')}
        </h1>
        <div className="py-8 border-b border-borderGray flex items-center max-md:flex-col max-md:items-start max-md:px-0 max-md:py-4 max-md:space-y-4">
          <div className={titleStyle}>{t('서비스•이벤트 정보')}</div>
          <section className="flex max-md:flex-col max-md:space-y-3 w-full justify-between">
            <div className="flex items-center font-bold text-lg max-md:text-base max-md:flex-wrap">
              <label
                className="flex space-x-3 max-md:space-x-0 cursor-pointer"
                htmlFor="marketing-agree"
              >
                <input
                  onChange={() =>
                    handleOnChange(
                      onChangeInfo.termsAgreeOptional.termsAgreeYn === 'Y'
                        ? false
                        : true,
                      'termsAgreeYn'
                    )
                  }
                  id="marketing-agree"
                  className="hidden"
                  type="checkbox"
                  defaultChecked={
                    onChangeInfo.termsAgreeOptional.termsAgreeYn === 'Y'
                  }
                />
                <i className="mt-1">
                  {onChangeInfo.termsAgreeOptional.termsAgreeYn === 'Y' ||
                  onChangeInfo.termsAgreeOptional.smsAgreeYn === 'Y' ||
                  onChangeInfo.termsAgreeOptional.mailAgreeYn === 'Y' ? (
                    <CheckedBox />
                  ) : (
                    <CheckBox />
                  )}
                </i>
                <span className="mr-6 ml-1 max-md:mr-0 text-black2">
                  <strong className="text-black5 font-bold max-md:ml-3">
                    {t('[선택]')}
                  </strong>{' '}
                  {t('광고성 정보 수신 및 마케팅 활용 동의')}
                  <i
                    onClick={() => setPolicyModal(true)}
                    className="w-5 cursor-pointer ml-1 inline-flex translate-y-1"
                  >
                    <ChevronRightIcon />
                  </i>
                </span>
              </label>
            </div>

            <section className="flex space-x-10 max-md:pl-[22px]">
              <div className="flex items-center text-lg max-md:text-base max-md:flex-wrap">
                <label
                  className="flex items-center space-x-3 cursor-pointer"
                  htmlFor="sms-agree"
                >
                  <input
                    onChange={(e) =>
                      handleOnChange(
                        onChangeInfo.termsAgreeOptional.smsAgreeYn === 'Y'
                          ? false
                          : true,
                        'smsAgreeYn'
                      )
                    }
                    defaultChecked={
                      onChangeInfo.termsAgreeOptional.smsAgreeYn === 'Y'
                    }
                    id="sms-agree"
                    className="hidden"
                    type="checkbox"
                  />
                  {onChangeInfo.termsAgreeOptional.smsAgreeYn === 'Y' ? (
                    <CheckedBox />
                  ) : (
                    <CheckBox />
                  )}
                  <span className="mr-6 ml-1 max-md:mr-0 text-black2">SMS</span>
                </label>
              </div>
              <div className="flex items-center text-lg max-md:text-base max-md:flex-wrap">
                <label
                  className="flex items-center space-x-2 cursor-pointer"
                  htmlFor="email-agree"
                >
                  <input
                    onChange={(e) =>
                      handleOnChange(
                        onChangeInfo.termsAgreeOptional.mailAgreeYn === 'Y'
                          ? false
                          : true,
                        'mailAgreeYn'
                      )
                    }
                    defaultChecked={
                      onChangeInfo.termsAgreeOptional.mailAgreeYn === 'Y'
                    }
                    id="email-agree"
                    className="hidden"
                    type="checkbox"
                  />
                  {onChangeInfo.termsAgreeOptional.mailAgreeYn === 'Y' ? (
                    <CheckedBox />
                  ) : (
                    <CheckBox />
                  )}
                  <span className="text-black2">E-mail</span>
                </label>
              </div>
            </section>
          </section>
        </div>
      </section>
    </>
  );
}
