import "aos/dist/aos.css";
import { TFunction } from "i18next";
import { SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import SwiperCore, { Navigation, Pagination } from "swiper";
import "swiper/css/pagination";
import "swiper/swiper.min.css";
import HomeButton from "../common/HomeButtons";
import useWindowScroll from "../hooks/useWindowScroll";

interface SubSectionPropType {
  subTitle:
    | "국내 유일 옴니채널 인공지능 비서"
    | "생성형 인공지능 영상생성과 방송 플랫폼"
    | "누구나 손쉽게 생성가능한 맞춤형 AI 가상인간"
    | "누구나 손쉽게 생성가능한 또 다른 나"
    | "맞춤형 AI 개인 비서"
    | "AI VIDEO ON YOUR DEMAND";
  title:
    | "플루닛 워크센터"
    | "플루닛 스튜디오"
    | "플루닛 메타휴먼"
    | "모바일앱 손비서"
    | "플루닛 영상제작소";
  sectionButtonArray: {
    buttonText: string;
    phrase: string;
    secondPhrase?: string;
    icon: string;
    selectedIcon: string;
  }[];
  buttonText: string;
  routeUrl: "/work-center" | "/studio" | "/metahuman" | "/son" | "/signup";
  secondButtonRouter: string;
  vector: string;
  buttonLink?: string;
  secondVector?: string;
  mobileVector: string;
  section?: "workcenter" | "studio" | "metahuman" | "son" | "quickserve";
  handleQuickStart: () => void;
}

const inactiveStyle =
  "text-white text-xl max-2xl:text-lg max-md:text-base text-opacity-[0.4] hover:text-opacity-[0.6] hover:border-opacity-[0.4] duration-100 ease-out";
const activeStyle = "text-transparent text-xl max-2xl:text-lg max-md:text-base";

export default function MobileMainImageSection({
  subTitle,
  title,
  sectionButtonArray,
  buttonText,
  buttonLink,
  section,
  routeUrl,
  handleQuickStart,
}: SubSectionPropType) {
  SwiperCore.use([Navigation, Pagination]);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [text, setText] = useState<{
    first: string;
    second?: string;
  }>({
    first: sectionButtonArray ? sectionButtonArray[0].phrase : "",
    second: sectionButtonArray ? sectionButtonArray[0]?.secondPhrase : "",
  });
  const { scrollY } = useWindowScroll();

  const getTriggerPosition = () => {
    switch (title) {
      case "플루닛 워크센터":
        return 600;
      case "플루닛 스튜디오":
        return 1440;
      case "모바일앱 손비서":
        return 2300;
      case "플루닛 영상제작소":
        return 3100;
      case "플루닛 메타휴먼":
        return 4000;
    }
  };

  const getFirstDescription = () => {
    switch (title) {
      case t("플루닛 워크센터"):
        return t("24시간 365일 쉬지 않는 AI 직원이 당신을 위해 일합니다.");
      case t("플루닛 스튜디오"):
        return t("나와 똑닮은 당신만의 가상인간으로 다양한 영상을 제작하고");
      case t("플루닛 메타휴먼"):
        return t("몇 번의 클릭만으로 인간처럼 말하고, 보고, 행동하는");
      case t("모바일앱 손비서"):
        return t("놓치는 전화 없이 모든 전화를 대신 받아주고,");
      case t("플루닛 영상제작소"):
        return t("국내 최고 생성 AI 기술에 영상 전문가의 터치를 더해");
    }
  };
  const getSecondDescription = () => {
    switch (title) {
      case t("플루닛 워크센터"):
        return t("지금 바로 플루닛의 인공지능 직원을 채용해보세요.");
      case t("플루닛 스튜디오"):
        return t(
          "손쉽게 프레젠테이션, 개인방송 또는 뉴스까지 제작하며 스트리밍하세요."
        );
      case t("플루닛 메타휴먼"):
        return t("자연스러운 메타휴먼을 비즈니스 니즈에 맞춰 사용해보세요.");
      case t("모바일앱 손비서"):
        return t(
          "메모 전달도 아이폰 통화녹음도 가능한 나만의 AI비서를 경험해보세요."
        );
      case t("플루닛 영상제작소"):
        return t("절반의 시간과 비용으로 고품질 영상을 제작 공급합니다.");
    }
  };
  return (
    <section className="bg-black max-md:pl-5 max-md:pt-0">
      <div className="w-full h-full mx-auto z-10 flex items-center flex-col">
        {section === "workcenter" ? (
          <WorkCenterImageSection />
        ) : section === "studio" ? (
          <StudioImageSection />
        ) : section === "metahuman" ? (
          <MetahumanImageSection />
        ) : (
          <SonImageSection />
        )}
        <div
          style={{
            transform:
              scrollY < getTriggerPosition()
                ? `translateY(0px)`
                : `translateY(-96px)`,
          }}
          className="w-full duration-1000"
        >
          <div className="w-full mt-[4.5rem]">
            <h2 className="font-bold text-transparent text-xl bg-clip-text bg-gradient-to-r from-[#FF4E83] to-[#FFBB54]">
              {t(subTitle)}
            </h2>
            <h1 className="font-bold text-4xl text-white mt-[0.25rem]">
              {t(title)}
            </h1>
            <p className="text-white w-full opacity-[0.6] text-base mt-4 font-light pr-10">
              {getFirstDescription()}
              <br className="max-md:hidden" />
              {getSecondDescription()}
            </p>

            <div className="flex flex-col font-extralight w-full">
              <MobileButtonSection
                sectionButtonArray={sectionButtonArray}
                setText={setText}
                text={text}
                t={t}
              />
              <div className="flex flex-col h-fit text-white text-sm">
                <span>{t(text.first)}</span>
                {text.second && <span>{t(text.second)}</span>}
              </div>
            </div>

            <div className="flex space-x-3 mt-7 md:mt-12">
              {buttonText === "나만의 메타휴먼 만들기" ? null : (
                <HomeButton
                  onClick={() => {
                    if (
                      buttonText === "베타 테스터 신청하기" ||
                      buttonText === "Apply to Beta Tester"
                    ) {
                      alert("테스터 모집이 마감되었습니다.");
                      return;
                    }

                    if (
                      buttonText === "무료시작하기" ||
                      buttonText === "Get start now"
                    ) {
                      handleQuickStart();
                      return;
                    }
                    if (buttonLink && buttonLink.includes("https")) {
                      window.open(buttonLink);
                    } else {
                      buttonLink && navigate(buttonLink);
                    }
                  }}
                  icon={true}
                >
                  {t(buttonText)}
                </HomeButton>
              )}
              <Link to={routeUrl}>
                <div className="p-[1px] bg-gradient-to-br from-white to-white rounded-lg hover:from-primary hover:to-primaryTo">
                  <HomeButton icon={true} color="main white">
                    {t("더 알아보기")}
                  </HomeButton>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

function MobileButtonSection({
  sectionButtonArray,
  setText,
  text,
  t,
}: {
  sectionButtonArray: {
    buttonText: string;
    phrase: string;
    secondPhrase?: string | undefined;
    icon: string;
    selectedIcon: string;
  }[];
  text: {
    first: string;
    second?: string | undefined;
  };
  setText: (
    value: SetStateAction<{
      first: string;
      second?: string | undefined;
    }>
  ) => void;
  t: TFunction;
}) {
  return (
    <div className="text-white space-x-2 flex overflow-x-scroll inquiry-detail flex-nowrap mt-6 mb-3 pr-5">
      {sectionButtonArray.map(
        ({ buttonText, phrase, secondPhrase, icon, selectedIcon }, idx) => (
          <div
            className={`w-fit whitespace-nowrap bg-gradient-to-r flex items-center justify-center text-center p-[1px] rounded-full cursor-pointer ${
              phrase === text.first
                ? "from-[#FF4E83] to-[#FFBB54]"
                : "from-white/20 to-white/20 hover:from-white/40 hover:to-white/40"
            }`}
            key={`button-${idx}`}
          >
            <div
              onClick={() =>
                setText({
                  first: phrase,
                  second: secondPhrase,
                })
              }
              className={`${
                phrase === text.first ? activeStyle : inactiveStyle
              } px-3 py-2 flex items-center space-x-2 bg-black w-full h-full rounded-full`}
            >
              <div
                style={{
                  backgroundImage: `url(${
                    phrase === text.first ? selectedIcon : icon
                  })`,
                }}
                className="w-6 h-6 bg-center bg-no-repeat bg-contain"
              />
              <span
                className={`${
                  phrase === text.first
                    ? "text-transparent bg-clip-text bg-gradient-to-r from-[#FF4E83] to-[#FFBB54]"
                    : "text-white/50 group-hover:text-white/70"
                } `}
              >
                {t(buttonText)}
              </span>
            </div>
          </div>
        )
      )}
    </div>
  );
}

function WorkCenterImageSection() {
  return (
    <div
      style={{ opacity: scrollY < 600 ? 1 : 0.3 }}
      className="relative h-full duration-1000"
    >
      <img src="/homepage/home-workcenter.png" alt="workcenter image" />
      <div className="max-md:hidden bg-gradient-to-l from-black to-black/10 absolute -right-14 w-20 h-full top-0" />
    </div>
  );
}

function StudioImageSection() {
  return (
    <div
      style={{ opacity: scrollY < 1440 ? 1 : 0.3 }}
      className="relative h-full duration-1000"
    >
      <img src="/homepage/home-studio.png" alt="studio image" />
      <div className="max-md:hidden bg-gradient-to-l from-black to-black/10 absolute -right-10 w-20 h-full top-0" />
    </div>
  );
}

function MetahumanImageSection() {
  return (
    <div
      style={{ opacity: scrollY < 3100 ? 1 : 0.3 }}
      className="relative h-full duration-1000"
    >
      <img src="/homepage/home-metahuman.png" alt="metahuman image" />
      <div className="max-md:hidden bg-gradient-to-l from-black to-black/10 absolute -right-14 w-20 h-full top-0" />
    </div>
  );
}

function SonImageSection() {
  return (
    <div
      style={{ opacity: scrollY < 2300 ? 1 : 0.3 }}
      className="relative h-full duration-1000"
    >
      <img src="/homepage/home-son.png" alt="son image" />
      <div className="max-md:hidden bg-gradient-to-l from-black to-black/10 absolute -right-14 w-20 h-full top-0" />
    </div>
  );
}
