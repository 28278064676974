import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import {
  assignedTelno,
  quickRegisterEnterpriseInfo,
  quickRegisterPersonalInfo,
} from '../../../store';
import { isLogin } from '../../../utils/common';
import Button from '../../common/Buttons';
import VideoModal from '../../common/VideoModal';
import { useUser } from '../../hooks/use-user';

export default function QuickCompleteSection() {
  const [modal, setModal] = useState(false);
  const personal = useRecoilValue(quickRegisterPersonalInfo);
  const enterprise = useRecoilValue(quickRegisterEnterpriseInfo);
  const assignTelno = useRecoilValue(assignedTelno)?.replace(
    /(\d{3})(\d{4})(\d{4})/,
    '$1-$2-$3'
  );
  const { myInfo } = useUser();
  const telNo = myInfo?.assignTelno?.replace(
    /(\d{3})(\d{4})(\d{4})/,
    '$1-$2-$3'
  );
  const navigate = useNavigate();

  const handleOnClick = () =>
    isLogin ? navigate('/my-page/my-info') : navigate('/login');

  return (
    <>
      <VideoModal
        open={modal}
        videoUrl="/video/workcenter.mp4"
        closeModal={() => setModal(false)}
      />
      <section>
        <div className="bg-gray5 rounded-xl py-8 md:py-10 text-center font-bold leading-6 md:leading-[32px]">
          <p className="md:text-xl">
            {personal.memberFnm1 ||
              enterprise.memberFnm1 ||
              myInfo?.memberFnm1 ||
              myInfo?.managerFnm ||
              ''}
            님을 위한 AI와 전용 전화번호가
            <br className="md:hidden" /> 지금 바로 만들어졌어요!
            <br />
            무료 1개월간 마음껏 사용해 보세요
          </p>
          <div className="mt-6 text-transparent text-2xl md:text-[32px] bg-clip-text bg-gradient-to-br from-[#FF4E83] to-[#FFBB54]">
            {assignTelno || telNo || ''}
          </div>
        </div>
        <p className="mt-10 md:mt-6 text-center max-md:text-sm">
          위 알려드린 고객님만을 위한 전화번호로 전화를 하시면
          <br />
          <strong className="font-bold max-md:text-base">
            고객님의 정보를 학습한 AI와
            <br className="md:hidden" /> 지금 바로 자유로운 대화가 가능합니다.
          </strong>
        </p>
        <div className="flex space-x-2 md:space-x-3 mt-6 md:mt-10 justify-center">
          <Button onClick={() => setModal(true)} color="white">
            워크센터 둘러보기
          </Button>

          <Button onClick={handleOnClick}>나의 AI 확인</Button>
        </div>
        <div className="w-full h-px bg-gray4 mt-10 md:mt-[60px] mb-8" />
        <div className="text-center">
          <h3 className="md:text-xl leading-6 font-bold">
            꼭 알고 계셔야 해요!
          </h3>
          <span className="text-black5 text-sm max-md:leading-5">
            플루닛 워크센터의 '무료시작하기' 서비스의 AI직원과 전용 전화번호는
            생성 후 1개월만 무료 사용이 가능해요.
          </span>
        </div>
        <div className="bg-gray5 rounded-xl mt-4 md:mt-6 py-6 md:py-8 text-center">
          <h4 className="font-bold">유료기능 사전 체험하기</h4>
          <div className="text-black4 text-sm max-md:leading-5 mt-1 md:mt-2">
            플루닛의 전문 가이드에게 전화하시면
            <br className="md:hidden" /> 유료 사용을 위한 모든 준비를 도와
            드립니다.
          </div>
          <div className="leading-6 font-bold text-sm flex justify-center space-x-2 mt-3">
            <span>워크센터 전문가이드</span>
            <span className="text-primary">070-4500-0078</span>
          </div>
        </div>
      </section>
    </>
  );
}
