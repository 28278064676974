import 'aos/dist/aos.css';
import { useEffect, useRef, useState } from 'react';
import SwiperCore, { Navigation, Pagination } from 'swiper';
import 'swiper/css/navigation';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';

SwiperCore.use([Navigation, Pagination]);

export default function Studio() {
  const componentRef = useRef(null);
  const { t } = useTranslation();
  const { language } = useRecoilValue(userinfoAtom);
  useEffect(() => {
    var AOS = require('aos');
    AOS.init({
      duration: 1000,
    });
  }, []);

  return (
    <main className="bg-black pt-[200px] max-md:pt-[98px] pb-10 px-0 max-md:overflow-x-hidden overflow-hidden">
      <ShortcutBanner
        firstButton={t('Studio 바로가기')}
        secondButton={t('서비스 이용안내')}
        firstLink="https://studio.ploonet.com/main"
        secondLink="https://studio.ploonet.com/main/tutorial"
        componentRef={componentRef}
        title={t('플루닛 스튜디오')}
      />
      <div className="max-w-[1600px] max-2xl:max-w-[1200px] mx-auto space-y-48 max-md:space-y-20">
        <MainSection language={language} componentRef={componentRef} />
        <div data-aos="fade-up">
          <UsesSection />
        </div>
        <div className="max-md:px-5" data-aos="fade-up">
          <PreviewSection />
        </div>
        <div className="max-md:px-5" data-aos="fade-up">
          <DifferenceSection />
        </div>
        <div className="max-md:px-5">
          <BottomBanner
            topText={t('플루닛 스튜디오와 함께')}
            bottomText={t(
              '당신만의 가상인간으로 디지털 크리에이터가 되어 보세요.'
            )}
            serviceName="Studio"
          />
        </div>
      </div>
    </main>
  );
}

function MainSection({
  componentRef,
  language,
}: {
  componentRef: React.MutableRefObject<null>;
  language: 'kr' | 'en';
}) {
  return (
    <section className="max-md:px-5">
      <div className="max-w-[1600px] mx-auto z-10 flex max-md:flex-col-reverse items-center justify-between max-md:items-end">
        <div
          data-aos="fade-right"
          className="w-full min-h-fit flex flex-col items-start"
        >
          <div className="space-y-10 max-md:space-y-0 max-md:mt-12">
            <SubpageTitle>Studio</SubpageTitle>
            <div className="font-extralight text-white text-5xl max-2xl:text-4xl leading-tight max-md:text-2xl max-md:!leading-9 max-md:space-y-0 max-md:!mt-2 whitespace-pre-wrap">
              <h1>{t('생성형 인공지능')}</h1>
              <h1>{t('영상생성과 방송 플랫폼')}</h1>
              <h1 className="font-bold">{t('플루닛 스튜디오')}</h1>
            </div>
            <p className="text-white/70 font-extralight text-xl max-2xl:text-lg max-md:text-base mt-8 max-w-[630px] max-2xl:max-w-lg max-md:!mt-4">
              {t(
                '지구에 살고 있는 누구든지 나와 닮은 AI 가상인간을 만들고 고가 장비없이, 유명인이나 촬영 장소 섭외도 필요 없이 클릭만 하면 크리에이터가 될 수 있다면 여러분들은 어떤 영상을 제작해보고 싶으신가요?'
              )}
              <br />
              {t(
                '불가능할 것 같은 이 현실을 플루닛 스튜디오가 마법처럼 해결해드립니다. 당신만의 가상 인간으로 방송과 영상 스트리밍을 지금 바로 시도해보세요.'
              )}
            </p>
          </div>
          <div
            ref={componentRef}
            className="space-x-5 max-md:space-x-3 flex mt-[60px] max-md:mt-10"
          >
            <HomeButton
              onClick={() => window.open('https://studio.ploonet.com/main')}
              icon={true}
            >
              {t('Studio 바로가기')}
            </HomeButton>
            <div className="p-[1px] bg-gradient-to-br from-white to-white rounded-lg hover:from-primary hover:to-primaryTo">
              <HomeButton
                onClick={() =>
                  window.open('https://studio.ploonet.com/main/tutorial')
                }
                icon={true}
                color="main white"
              >
                {t('서비스 이용안내')}
              </HomeButton>
            </div>
          </div>
        </div>
        <div
          data-aos-duration="2000"
          data-aos="fade-left"
          className="relative w-full"
        >
          <div className="flex relative">
            <div className="swiper-animation-studio fade-in-left">
              <img
                src={
                  language === 'kr'
                    ? '/studio/studio.gif'
                    : '/studio/en-studio.gif'
                }
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

function UsesSection() {
  const [isReachedEnd, setIsReachedEnd] = useState(false);
  const [activeIdx, setActiveIdx] = useState(0);
  const [videoModal, setVideoModal] = useState({
    open: false,
    videoUrl: '',
  });
  const swiperRef = useRef<any>();
  const { language } = useRecoilValue(userinfoAtom);
  const getLeftPercentage = () => {
    let percentage = 16.6;
    percentage = percentage * activeIdx;
    return `${String(percentage.toFixed(1))}%`;
  };
  return (
    <>
      <VideoModal
        videoUrl={videoModal.videoUrl}
        open={videoModal.open}
        closeModal={() => setVideoModal({ open: false, videoUrl: '' })}
      />
      <section className="space-y-14 max-md:space-y-5 max-md:pl-5">
        <div className="flex justify-between">
          <h1 className="text-5xl text-white max-2xl:text-4xl max-md:text-2xl flex items-start max-md:items-baseline max-md:flex-col max-md:space-y-1 font-extralight">
            <SubPageDot />
            <span
              className={`${
                language === 'kr'
                  ? ''
                  : 'font-bold whitespace-pre-wrap leading-tight'
              }`}
            >
              <strong
                className={`${
                  language === 'kr' ? 'font-bold' : 'font-extralight'
                }`}
              >
                {t('플루닛 스튜디오, ')}
              </strong>{' '}
              <br className={`${language === 'en' ? 'block' : 'md:hidden'}`} />
              {t('이렇게 활용할 수 있어요.')}
            </span>
          </h1>
          <div className="space-x-10 flex max-md:hidden">
            <button
              onClick={() => swiperRef.current?.slidePrev()}
              className="rotate-180"
            >
              <ChevronRight color={!isReachedEnd ? 'gray' : 'white'} />
            </button>
            <button onClick={() => swiperRef.current?.slideNext()}>
              <ChevronRight color={isReachedEnd ? 'gray' : 'white'} />
            </button>
          </div>
        </div>

        <div className="text-white max-md:w-full max-md:pr-0">
          <Swiper
            className="max-md:pr-5"
            onBeforeInit={(swiper) => {
              swiperRef.current = swiper;
            }}
            onActiveIndexChange={(e) => setActiveIdx(e.activeIndex)}
            onReachEnd={() => setIsReachedEnd(true)}
            onReachBeginning={() => setIsReachedEnd(false)}
            spaceBetween={16}
            slidesPerView={1.4}
            breakpoints={{
              768: {
                slidesPerView: 3,
                slidesPerGroup: 3,
                spaceBetween: 50,
              },
            }}
          >
            {usesArray.map(({ imgPath, videoUrl, text, subText }, idx) => (
              <SwiperSlide
                onClick={() => setVideoModal({ open: true, videoUrl })}
                key={`pros-${idx}`}
              >
                <div className="space-y-8 max-md:space-y-4">
                  <div className="w-auto max-md:w-full h-[280px] max-md:h-[132px] max-2xl:w-[360px] overflow-hidden max-2xl:h-52 rounded-2xl max-md:rounded-md relative group cursor-pointer">
                    <div
                      style={{ backgroundImage: `url(${imgPath})` }}
                      className={`space-y-6 h-full w-full rounded-2xl max-md:rounded-md bg-no-repeat bg-center bg-cover group-hover:scale-105 duration-500 ease-out`}
                    />
                  </div>
                  <div className="flex flex-col space-y-3 max-md:space-y-2">
                    <h1 className="text-2xl max-2xl:text-xl font-bold">
                      {t(text)}
                    </h1>
                    <span className="max-2xl:text-sm text-white text-opacity-60 max-md:pr-4">
                      {t(subText)}
                    </span>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </section>
      <section className="md:hidden px-5">
        <div className="relative w-full h-[2px] bg-black2 !mt-11">
          <div
            style={{ left: getLeftPercentage() }}
            className="absolute w-1/6 h-[2px] bg-primary duration-300 ease-out"
          />
        </div>
      </section>
    </>
  );
}

function PreviewSection() {
  const [gifUrl, setGifUrl] = useState(previewStepArray[0].gifUrl);
  return (
    <section className="space-y-14 max-md:space-y-0 text-white">
      <div className="space-y-5">
        <h1 className="text-5xl text-white max-2xl:text-4xl max-md:text-2xl flex font-extralight max-md:flex-col max-md:space-y-1">
          <SubPageDot />
          <div className="max-md:flex-col max-md:flex">
            <span>{t('미리 만나보는')} </span>
            <span className="font-bold">{t('플루닛 스튜디오')}</span>
          </div>
        </h1>
        <h3 className="text-lg max-md:text-base text-white text-opacity-60 font-extralight max-md:!mt-4">
          {t(
            '세계 유일 웹 기반 타임라인 AI 영상 편집 제작도구의 다양한 기능으로 제작 시간과 비용 감축은 물론, 원하는 메타휴먼 모델로 손쉽게 전문가 수준의 영상 창작이 가능합니다.'
          )}
        </h3>
      </div>
      <div className="space-y-32 max-2xl:space-y-20 max-md:!mt-5">
        <section className="bg-[#141414] rounded-[20px] border border-black2 w-full p-14 max-md:py-7 max-md:px-6 flex max-md:flex-col max-md:space-y-7 justify-between">
          {previewSectionArray.map(({ icon, keyPhrase, description }, idx) => (
            <div
              className="items-center flex flex-col max-md:flex-row max-md:items-center max-md:space-x-5 max-md:space-y-0 space-y-8"
              key={`previewCard-${idx}`}
            >
              <i>{icon}</i>
              <div className="md:flex md:flex-col md:items-center text-center max-md:text-start space-y-3 max-md:space-y-2">
                <h4 className="text-2xl font-bold max-2xl:text-xl max-md:text-lg">
                  {t(keyPhrase)}
                </h4>
                <span className="block text-white/60 max-md:text-sm md:w-[200px]">
                  {t(description)}
                </span>
              </div>
            </div>
          ))}
        </section>
        <section className="flex max-md:flex-col max-md:space-x-0 space-x-60 max-2xl:space-x-24">
          <div
            style={{
              backgroundImage: `url(${gifUrl})`,
            }}
            className="w-full h-[426px] bg-contain bg-no-repeat bg-center max-md:hidden rounded-lg relative opacity-1 duration-300"
          >
            <div className="w-[400px] h-[400px] max-2xl:w-[300px] max-2xl:h-[300px] rounded-full bg-black2 absolute -right-40 max-2xl:-right-16 -top-10 max-2xl:-top-10 -z-10" />
          </div>
          <div className="w-full h-auto">
            <DifferenceDisclosure setGifUrl={setGifUrl} />
          </div>
        </section>
      </div>
    </section>
  );
}

function DifferenceSection() {
  return (
    <section className="text-white">
      <h1 className="text-5xl text-white max-2xl:text-4xl max-md:text-2xl flex font-extralight mb-[60px] max-md:mb-5 max-md:flex-col max-md:space-y-1">
        <SubPageDot />
        <div className="max-md:flex max-md:flex-col">
          <span className="font-bold">{t('스튜디오, ')}</span>
          <span>{t('어떤 점이 다를까요?')}</span>
        </div>
      </h1>

      <section className="grid grid-cols-3 max-md:grid-cols-1 gap-10 max-2xl:gap-4">
        {differenceCardArray.map(({ icon, keyPhrase, description }, idx) => (
          <div
            key={`difference-${idx}`}
            className="flex items-center bg-[#141414] rounded-[20px] border border-black2 w-full py-10 px-12 max-md:py-7 max-md:px-6 max-2xl:px-5 space-x-8 max-2xl:space-x-6"
          >
            <i>{icon}</i>
            <div className="flex flex-col space-y-3 max-md:space-y-2">
              <h1 className="font-bold text-2xl max-2xl:text-xl max-md:text-lg text-white">
                {t(keyPhrase)}
              </h1>
              <span className="block text-white/60 max-2xl:text-sm font-extralight">
                {t(description)}
              </span>
            </div>
          </div>
        ))}
      </section>
    </section>
  );
}

import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
} from '@chakra-ui/react';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import {
  differenceCardArray,
  previewSectionArray,
  previewStepArray,
} from '../../array/studioArray';
import { usesArray } from '../../array/workcenterArray';
import BottomBanner from '../../components/common/BottomBanner';
import HomeButton from '../../components/common/HomeButtons';
import ShortcutBanner from '../../components/common/ShortcutBanner';
import SubPageDot from '../../components/common/SubpageDot';
import SubpageTitle from '../../components/common/SubpageTitle';
import VideoModal from '../../components/common/VideoModal';
import PlusIcon from '../../components/vector/studio/PlusIcon';
import ChevronRight from '../../components/vector/workcenter/ChevronRight';
import MinusIcon from '../../components/vector/workcenter/MinusIcon';
import { userinfoAtom } from '../../store';

function DifferenceDisclosure({ setGifUrl }: { setGifUrl: any }) {
  return (
    <section className="w-full h-full">
      <Accordion defaultIndex={0}>
        {previewStepArray.map(({ title, content, gifUrl }, idx) => (
          <AccordionItem
            className="border-b border-white border-opacity-[0.16] py-5 max-md:py-4"
            key={`prev-${idx}`}
          >
            {({ isExpanded }) => (
              <>
                <h2
                  className={
                    isExpanded
                      ? 'text-2xl max-2xl:text-xl max-md:text-base font-bold'
                      : 'text-white/80 font-extralight text-xl max-2xl:text-lg max-md:text-base'
                  }
                >
                  <AccordionButton onClick={() => setGifUrl(gifUrl)}>
                    <Box as="span" flex="1" textAlign="left">
                      <strong className="text-primary mr-2">
                        STEP{idx + 1}
                      </strong>{' '}
                      {t(title)}
                    </Box>
                    {isExpanded ? <MinusIcon /> : <PlusIcon />}
                  </AccordionButton>
                </h2>
                <AccordionPanel className="text-white/60 font-extralight text-xl max-2xl:text-lg max-md:text-base mt-5">
                  <ul className="flex flex-col">
                    {content.map((phrase, idx) => (
                      <li
                        key={`contentStep-${idx}`}
                        className="text-white text-opacity-60 text-xl max-2xl:text-lg max-md:text-base flex space-x-3"
                      >
                        <div className="w-1 h-1 bg-primary rounded-full mt-[11px]" />
                        <span>{t(phrase)}</span>
                      </li>
                    ))}
                  </ul>
                  <div
                    style={{
                      backgroundImage: `url(${gifUrl})`,
                    }}
                    className="w-full h-44 bg-contain bg-no-repeat bg-center mt-12 md:hidden rounded-lg z-10"
                  />
                </AccordionPanel>
              </>
            )}
          </AccordionItem>
        ))}
      </Accordion>
    </section>
  );
}
