import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import MobileBackButton from '../../components/common/MobileBackButton';
import PageLeaveAlert from '../../components/common/PageLeaveAlert';
import SignupButton from '../../components/common/SignupButtons';
import InformModal from '../../components/common/modal/InformModal';
import { passwordRegex } from '../../components/hooks/useValidate';
import PersonalOptionalInfoTab from '../../components/signup/PersonalOptionalInfo';
import UserInfo from '../../components/signup/UserInfo';
import {
  SignUpInfo2Atom,
  SignUpInfoRegCertAtom,
  UserInfoType,
} from '../../store';

export default function SignUpRegisterInfo() {
  const setSignupInfo2 = useSetRecoilState(SignUpInfo2Atom);
  const [modalInfo, setModalInfo] = useState({
    isOpen: false,
    phrase: '',
    secondPhrase: '',
  });
  const [registerInfo, setRegisterInfo] = useState<UserInfoType>({
    userEmail: '',
    emailCheck: false,
    userPassword: '',
    userPasswordConfirm: '',
    userPhoneNumber: '',
    userGender: '',
    profileImage: null,
  });
  const navigate = useNavigate();
  const regCertResult = useRecoilValue(SignUpInfoRegCertAtom);

  useEffect(() => {
    if (!regCertResult.staffName) {
      setModalInfo({
        isOpen: true,
        phrase: '가입 정보가 없어 초기 단계로 돌아갑니다.',
        secondPhrase: '',
      });
    }
  }, [regCertResult]);

  const checkEligibility = () => {
    return !passwordRegex.test(registerInfo.userPassword) ||
      registerInfo.userPassword !== registerInfo.userPasswordConfirm ||
      !registerInfo.emailCheck
      ? true
      : false;
  };

  const requestSignup = async () => {
    setSignupInfo2(registerInfo);
    navigate('/signup/step03');
  };

  const closeModal = () => {
    if (!regCertResult.staffName) {
      navigate('/signup');
    } else {
      setModalInfo((prev) => ({
        ...prev,
        isOpen: false,
      }));
    }
  };

  return (
    <>
      <PageLeaveAlert />
      <InformModal
        modal={modalInfo.isOpen}
        closeModal={closeModal}
        phrase={modalInfo.phrase}
        secondPhrase={modalInfo.secondPhrase}
      />
      <section className="bg-[#F8F7F8] pt-[180px] pb-48 max-md:pt-[62px] max-md:pb-24 max-md:bg-white">
        <div className="flex justify-center max-md:block">
          <div>
            <section className="flex flex-col text-3xl max-2xl:text-[28px] space-y-1 max-md:px-5 max-md:text-2xl">
              <MobileBackButton />
              <div className="space-y-5 leading-5">
                <h1>본인인증이 완료되었습니다.</h1>
                <h1 className="font-bold">회원정보를 입력해주세요.</h1>
              </div>
            </section>

            <section className="mt-7 p-10 bg-white rounded-lg text-lg space-y-10 max-md:space-y-8 max-md:px-5 max-md:w-full max-md:min-w-full max-md:mt-0 max-md:p-5 min-w-[800px]">
              <UserInfo
                registerInfo={registerInfo}
                setRegisterInfo={setRegisterInfo}
              />
              <PersonalOptionalInfoTab
                setModalInfo={setModalInfo}
                registerInfo={registerInfo}
                setRegisterInfo={setRegisterInfo}
              />
            </section>

            <div className="flex justify-center mt-14 max-md:px-5">
              <SignupButton
                onClick={requestSignup}
                disabled={checkEligibility()}
              >
                다음
              </SignupButton>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
