import { fileBaseURL } from '../../services/defaultClient';

export default function ImageBox({
  thumbnailPath,
  category,
}: {
  thumbnailPath: string;
  category: string;
}) {
  const getThumbnailUrl = (thumbnailPath: string) => {
    let url;
    if (thumbnailPath) {
      url = fileBaseURL + thumbnailPath;
    } else {
      switch (category) {
        case '공지':
          return '/notice/default_notice.png';
        case '뉴스':
          return '/notice/default_news.png';
        case '이벤트':
          return '/notice/default_event.png';
        default:
          return '/notice/default_notice.png';
      }
    }
    return url;
  };
  const boxSize = 'max-2xl:min-h-[240px] min-h-[300px] max-md:min-h-[110px]';

  return (
    <div
      className={`${boxSize} overflow-hidden rounded-2xl max-md:rounded-md relative`}
    >
      <div
        style={{
          backgroundImage: `url(${getThumbnailUrl(thumbnailPath)})`,
        }}
        className={`${boxSize} space-y-6 h-full w-full rounded-2xl max-md:rounded-md bg-no-repeat bg-center bg-cover group-hover:scale-105 duration-500 ease-out`}
      />
      <div
        className={`${boxSize} absolute h-full w-full bg-black/0 top-0 group-hover:bg-black/40 duration-500 ease-out`}
      />
    </div>
  );
}
