export default function AreaSaveIcon() {
  return (
    <svg
      className="w-[100px] h-[100px] max-md:w-[72px] max-md:h-[72px]"
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="50" cy="50" r="50" fill="#222222" />
      <path
        d="M72.1553 61.8467L62.463 56.212M27.8476 61.7708L37.0784 56.3995"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M50 24.9248L50 48.9248"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M55.1794 74.4481L68.5086 66.7953C71.5289 65.063 74 60.7195 74 57.1517V42.544C74 38.9761 71.5289 34.6326 68.5086 32.9004L55.1794 25.2217C52.3339 23.5929 47.6661 23.5929 44.8206 25.2217L31.4914 32.9004C28.4711 34.6326 26 38.9761 26 42.544V57.1517C26 60.7195 28.4711 65.063 31.4914 66.7953L44.8206 74.474C47.6911 76.1028 52.3339 76.1028 55.1794 74.4481Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M40.5967 56.3078C36.5706 56.3078 35.2266 53.0771 35.2266 49.8463C35.2266 46.6155 36.5706 43.3848 40.5967 43.3848C45.9682 43.3848 54.0234 56.3078 59.3949 56.3078C63.421 56.3078 64.765 53.0771 64.765 49.8463C64.765 46.6155 63.421 43.3848 59.3949 43.3848C54.0234 43.3848 45.9682 56.3078 40.5967 56.3078V56.3078Z"
        stroke="url(#paint0_linear_1619_100533)"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1619_100533"
          x1="32.5757"
          y1="40.8001"
          x2="67.5599"
          y2="54.2772"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF4E83" />
          <stop offset="1" stopColor="#FFBB54" />
        </linearGradient>
      </defs>
    </svg>
  );
}
