import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { nationArr } from '../../../array/nationArray';
import { OnChangePersonalInfoType } from '../../../services/TypeOfPrivateService';
import { fileBaseURL } from '../../../services/defaultClient';
import { userinfoAtom } from '../../../store';
import InformModal from '../../common/modal/InformModal';
import { usePloonianImage } from '../../hooks/usePloonian';
import AgreementSection from './AgreementSection';
import DefaultProfileIcon from './DefaultProfileIcon';
import PasswordModal from './PasswordModal';
import PloonianInfo from './ploonian-info';

const divStyle =
  'py-8 border-b border-borderGray flex items-center max-md:justify-between max-md:px-0 max-md:py-4 max-md:border-none';
const titleStyle =
  'w-[200px] min-w-[200px] font-bold text-lg max-md:text-base max-md:min-w-fit';

export default function MyForeignerInformation({
  myInfo,
  profileImage,
  setProfileImage,
  setOnChangeInfo,
  onChangeInfo,
  setPloonianModal,
}: {
  myInfo: any;
  onChangeInfo: OnChangePersonalInfoType;
  profileImage: File | null;
  setProfileImage: React.Dispatch<React.SetStateAction<File | null>>;
  setOnChangeInfo: React.Dispatch<
    React.SetStateAction<OnChangePersonalInfoType>
  >;
  setPloonianModal: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const [key, setKey] = useState(0);
  const [imageUrl, setImageUrl] = useState<any>(null);
  const [initialPath, setInitialPath] = useState('');
  const [passwordModal, setPasswordModal] = useState(false);
  const [informModal, setInformModal] = useState({
    isOpen: false,
    phrase: '',
    content: '',
  });
  const { ploonianThumbnail } = usePloonianImage();
  const { t } = useTranslation();
  const { language } = useRecoilValue(userinfoAtom);
  const [address1, setAddress1] = useState({
    streetAdd: '',
    building: '',
  });
  const [address2, setAddress2] = useState({
    city: '',
    region: '',
  });
  const isQuickStartUser = myInfo.quickstartStateCd === 1;
  useEffect(() => {
    const memberAddr1 = `${address1.streetAdd}br/${address1.building}`;
    const memberAddr2 = `${address2.city}br/${address2.region}`;

    setOnChangeInfo((prev) => ({
      ...prev,
      memberAddr1,
      memberAddr2,
    }));
  }, [address1, address2]);

  const displayPhoneNumber = (countryCode: string, telNo: string) => {
    const country = nationArr.filter(
      (nation) => nation.code === countryCode
    )[0];
    const { number, name } = country;
    return `${name} +${number} ${telNo}`;
  };

  useEffect(() => {
    setOnChangeInfo((prev) => ({
      ...prev,
      memberTypeCd: myInfo.memberTypeCd,
      memberAddr1: myInfo.memberAddr1,
      memberAddr2: myInfo.memberAddr2,
      memberZipno: myInfo.memberZipno,
      termsAgreeOptional: {
        ...prev.termsAgreeOptional,
        termsAgreeYn: myInfo.termsAgreeOptional.termsAgreeYn,
        smsAgreeYn: myInfo.termsAgreeOptional.smsAgreeYn,
        mailAgreeYn: myInfo.termsAgreeOptional.mailAgreeYn,
      },
    })),
      setInitialPath(
        !myInfo.profileImagePath ? '' : fileBaseURL + myInfo.profileImagePath
      );

    const add1 = myInfo.memberAddr1
      ?.split('br/')
      .map((part: string) => part.trim());
    setAddress1({
      streetAdd: add1[0],
      building: add1[1],
    });
    const add2 = myInfo.memberAddr2
      ?.split('br/')
      .map((part: string) => part.trim());
    setAddress2({
      city: add2[0],
      region: add2[1],
    });
  }, [myInfo]);

  useEffect(() => {
    profileImage
      ? setImageUrl(URL.createObjectURL(profileImage))
      : setImageUrl(null);
  }, [profileImage]);

  const onChangeFileHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    } else if (e.target.files[0].size > 5e6) {
      setInformModal({
        isOpen: true,
        phrase: '파일 제한 용량을 초과했습니다.',
        content: '',
      });
      return;
    }
    setProfileImage(e.target.files[0]);
  };

  const deleteProfileImage = () => {
    setInitialPath('');
    setProfileImage(null);
    setKey((prev) => prev + 1);
  };

  return (
    <>
      <PasswordModal
        modal={passwordModal}
        closeModal={() => setPasswordModal(false)}
      />
      <InformModal
        modal={informModal.isOpen}
        phrase={informModal.phrase}
        content={informModal.content}
        closeModal={() =>
          setInformModal((prev) => ({ ...prev, isOpen: false }))
        }
      />

      <div className="w-full mb-20 max-md:mb-10 max-md:w-full text-lg text-black2">
        <h1 className="font-bold text-[32px] max-2xl:text-3xl pb-5 border-b-2 border-black max-md:text-2xl">
          {language === 'kr' ? '내 정보' : 'My Information'}
        </h1>
        <FixedInfoBox label="아이디(이메일)" displayInfo={myInfo.memberEmail} />
        <div className={divStyle}>
          <div className={titleStyle}>{t('비밀번호')}</div>
          <button
            onClick={() => setPasswordModal(true)}
            className="border border-black py-3 px-6 max-2xl:py-2 max-2xl:px-5 rounded-md text-lg hover:bg-gray5 max-md:text-base max-md:px-4 max-md:min-w-fit"
          >
            {t('비밀번호 변경')}
          </button>
        </div>
        <FixedInfoBox
          label={language === 'kr' ? '이름' : 'First Name'}
          displayInfo={myInfo.memberFnm1}
        />
        <FixedInfoBox
          label={language === 'kr' ? '성' : 'Last Name'}
          displayInfo={myInfo.memberFnm2}
        />
        <FixedInfoBox
          label="성별"
          displayInfo={myInfo.genderCd === 'M' ? t('남성') : t('여성')}
        />
        <FixedInfoBox
          label="휴대폰 번호"
          displayInfo={displayPhoneNumber(
            myInfo.memberCountryCd,
            myInfo.mobileTelno
          )}
        />

        <div
          className={`${divStyle} max-md:flex-col max-md:items-start max-md:space-y-3`}
        >
          <div className={titleStyle}>{t('나의 플루니안')}</div>
          <PloonianInfo
            quickstartDetail={myInfo.quickstartDetail}
            isQuickStartUser={isQuickStartUser}
            setPloonianModal={setPloonianModal}
            ploonianThumbnail={ploonianThumbnail}
          />
        </div>

        <section>
          <h1 className="font-bold text-[32px] max-2xl:text-3xl pb-5 border-b-2 border-black max-md:text-2xl mt-[60px] max-2xl:mt-12">
            {t('추가 정보')}
          </h1>
          <div className="py-8 flex items-center max-md:flex-col max-md:items-start max-md:px-0 max-md:py-4 max-md:space-y-4 max-md:border-none">
            <div className={titleStyle}>{t('주소')}</div>
            <div className="flex flex-col w-2/3 space-y-3 max-md:w-full">
              <input
                className="border border-borderLightGray focus:border-primary py-3 px-4 rounded-md min-w-full text-lg max-md:text-base"
                type="text"
                maxLength={500}
                value={address1.streetAdd}
                placeholder="Street Address, P.O. box, Company name, c/o"
                onChange={(e) =>
                  setAddress1((prev) => ({
                    ...prev,
                    streetAdd: e.target.value,
                  }))
                }
              />
              <input
                className="border border-borderLightGray focus:border-primary py-3 px-4 rounded-md min-w-full text-lg max-md:text-base"
                type="text"
                maxLength={500}
                value={address1.building}
                placeholder="Apartment, Suite, Unit, Building, Floor, etc."
                onChange={(e) =>
                  setAddress1((prev) => ({
                    ...prev,
                    building: e.target.value,
                  }))
                }
              />
              <input
                className="border border-borderLightGray focus:border-primary py-3 px-4 rounded-md min-w-full text-lg max-md:text-base"
                type="text"
                maxLength={500}
                value={address2.city}
                placeholder="City"
                onChange={(e) =>
                  setAddress2((prev) => ({
                    ...prev,
                    city: e.target.value,
                  }))
                }
              />
              <input
                className="border border-borderLightGray focus:border-primary py-3 px-4 rounded-md min-w-full text-lg max-md:text-base"
                type="text"
                maxLength={500}
                value={address2.region}
                placeholder="State / Province / Region"
                onChange={(e) =>
                  setAddress2((prev) => ({
                    ...prev,
                    region: e.target.value,
                  }))
                }
              />
            </div>
          </div>

          <div className="py-8 border-y border-borderGray flex items-center max-md:flex-col max-md:space-y-3 max-md:items-start max-md:p-0 max-md:py-5">
            <div className={titleStyle}>{t('프로필 이미지')}</div>
            <div className="flex">
              <label className="rounded-full w-[100px] h-[100px] max-md:w-[94px] max-md:h-[94px] cursor-pointer">
                <div
                  style={{
                    backgroundImage: `url(${
                      imageUrl
                        ? imageUrl
                        : myInfo.profileImagePath
                        ? initialPath
                        : ''
                    })`,
                  }}
                  className="w-full h-full rounded-full bg-cover bg-center relative"
                >
                  {!initialPath && !imageUrl && (
                    <i className="bg-cover w-full h-full">
                      <DefaultProfileIcon />
                    </i>
                  )}
                </div>
                <input
                  key={key}
                  id="profile-image"
                  type="file"
                  className="hidden"
                  accept=".png, .jpg"
                  onChange={(e) => onChangeFileHandler(e)}
                />
              </label>
              <div className="flex flex-col justify-center ml-5 space-y-3 max-md:space-y-2">
                <div className="flex space-x-3">
                  <label
                    className="border border-borderLightGray rounded-lg hover:bg-gray5 w-fit cursor-pointer py-2 px-3 max-md:text-sm max-md:p-[10px] max-md:min-w-fit"
                    htmlFor="profile-image"
                  >
                    {imageUrl || initialPath ? t('사진 변경') : t('사진 등록')}
                  </label>
                  {(imageUrl || initialPath) && (
                    <label
                      onClick={deleteProfileImage}
                      className="border border-borderLightGray rounded-lg hover:bg-gray5 w-fit cursor-pointer py-2 px-3 max-md:text-sm max-md:p-[10px] max-md:min-w-fit"
                    >
                      {t('사진 삭제')}
                    </label>
                  )}
                </div>
                <span className="text-textGray text-sm max-md:text-xs">
                  {t('5MB 미만 / 이미지 파일')}{' '}
                  <br className="hidden max-md:block" />
                  {t('(jpg, png)만 첨부 가능')}
                </span>
              </div>
            </div>
          </div>
        </section>
        <AgreementSection
          titleStyle={titleStyle}
          onChangeInfo={onChangeInfo}
          setOnChangeInfo={setOnChangeInfo}
        />
      </div>
    </>
  );
}

function FixedInfoBox({
  label,
  displayInfo,
}: {
  label: string;
  displayInfo: string;
}) {
  const { t } = useTranslation();
  return (
    <div className={divStyle}>
      <div className={titleStyle}>{t(label)}</div>
      <div className="max-md:text-base">{t(displayInfo)}</div>
    </div>
  );
}
