export default function AiImage() {
  return (
    <svg
      width="150"
      height="220"
      viewBox="0 0 150 220"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1563_100882)">
        <path
          d="M79.782 159.7L80.601 160.144V204.127C75.0976 207.319 68.3166 207.319 62.7969 204.127V159.305C68.1528 156.558 74.5407 156.673 79.782 159.716V159.7Z"
          fill="white"
        />
        <path
          d="M93.5083 167.101L94.6549 162.233L129.526 165.489V170.572C129.526 173.582 128.052 176.592 125.071 178.319L80.6016 204.127V160.144L93.5083 167.118V167.101Z"
          fill="#FFF8F7"
        />
        <g>
          <path
            opacity="0.06"
            d="M1.44182 182.99V183.04V183.089V183.138L1.49096 183.204L1.5401 183.27L1.58924 183.352L1.65475 183.434L1.68751 183.467L1.76941 183.55L3.35818 185.671L5.19264 187.251L32.1691 202.778L59.1455 218.305L60.6687 218.651L62.5687 219.079L64.9437 219.259L67.335 219.194L69.0876 218.914L70.8402 218.634L72.6255 218.322L74.46 217.993L76.6548 217.598L78.178 216.694L79.7013 215.789L81.159 214.901L82.6168 214.012L84.0581 213.141L85.4667 212.285L86.384 211.726L87.7107 210.904L100.83 202.778L124.94 187.843L125.858 187.267L126.677 186.642L127.381 186L127.971 185.326L128.462 184.635L128.839 183.928L129.101 183.221L129.264 182.513C129.264 182.513 133.867 179.849 128.839 176.246C128.724 176.164 128.642 176.049 128.527 175.967C128.413 175.885 128.331 175.786 128.216 175.704C128.102 175.638 128.02 175.523 127.905 175.457C127.79 175.391 127.708 175.292 127.61 175.227C127.528 175.177 127.463 175.095 127.365 175.029C127.266 174.963 127.201 174.898 127.119 174.848C127.037 174.799 126.955 174.717 126.857 174.667C126.758 174.618 126.677 174.536 126.595 174.486C125.874 174.108 124.793 173.631 123.892 173.236C123.794 173.187 123.679 173.105 123.565 173.055C123.45 173.006 123.335 172.924 123.221 172.874C123.106 172.825 122.975 172.743 122.86 172.693C122.664 172.611 122.434 172.496 122.205 172.414C121.976 172.332 121.73 172.233 121.484 172.151C121.239 172.068 120.993 171.986 120.731 171.92C120.469 171.855 120.174 171.756 119.912 171.69C119.715 171.641 119.503 171.575 119.306 171.542C110.691 169.683 92.3951 169.289 74.5255 169.568C54.4774 169.831 35.0682 170.966 30.6458 171.871C25.0605 173.006 20.5399 174.125 16.9201 175.111C-2.47279 180.391 1.40907 183.023 1.40907 183.023L1.44182 182.99Z"
            fill="#FBFBFD"
          />
          <path
            opacity="0.12"
            d="M2.48993 182.613V182.695L2.50631 182.777L2.53907 182.859L2.58821 182.958L2.65372 183.057L2.73562 183.172L2.8339 183.287L2.89941 183.336L3.01407 183.451L5.258 185.869L7.63298 187.728L33.5284 202.63L59.4238 217.533L60.8979 217.878L62.7323 218.29L65.009 218.487L67.3021 218.438L69.0055 218.191L70.709 217.928L72.4452 217.632L74.2305 217.319L76.3434 216.941L77.8339 216.085L79.3244 215.23L80.7657 214.391L82.1907 213.552L83.5993 212.713L84.9752 211.891L85.8596 211.348L87.1536 210.559L99.8966 202.68L123.335 188.189L124.285 187.596L125.153 186.955L125.907 186.297L126.545 185.623L127.07 184.932L127.495 184.241L127.807 183.534L128.02 182.826C128.02 182.826 133.097 179.882 128.396 176.51C128.282 176.428 128.265 176.296 128.151 176.214C128.036 176.148 128.02 176 127.888 175.934C127.79 175.869 127.741 175.737 127.643 175.671C127.545 175.605 127.495 175.474 127.397 175.424C127.315 175.375 127.282 175.26 127.184 175.211C127.086 175.161 127.053 175.046 126.971 174.997C126.889 174.947 126.824 174.832 126.742 174.783C126.66 174.734 126.595 174.618 126.496 174.569C125.825 174.224 124.531 173.664 123.679 173.303C123.581 173.253 123.483 173.138 123.384 173.105C123.286 173.072 123.171 172.941 123.057 172.908C122.942 172.875 122.811 172.743 122.696 172.694C122.516 172.612 122.27 172.48 122.074 172.398C121.877 172.316 121.599 172.184 121.386 172.118C121.173 172.053 120.895 171.921 120.649 171.855C120.403 171.789 120.092 171.674 119.846 171.608C119.666 171.559 119.437 171.477 119.24 171.444C111.198 169.7 92.9027 169.437 74.8856 169.799C55.3126 170.112 36.2964 171.214 31.5301 172.135C25.9612 173.204 21.4733 174.29 17.9518 175.211C-0.98244 180.211 2.45718 182.547 2.45718 182.547L2.48993 182.613Z"
            fill="#F6F7FC"
          />
          <path
            opacity="0.19"
            d="M3.52168 182.218V182.333L3.55444 182.448L3.60358 182.563L3.66909 182.695L3.76737 182.826L3.88202 182.958L4.01306 183.089L4.09495 183.155L4.25874 183.303L7.14147 186.034L10.0733 188.188L34.8713 202.482L59.6693 216.776L61.0943 217.138L62.8632 217.549L65.0417 217.746H67.2365L68.8908 217.516L70.545 217.286L72.2321 217.023L73.9683 216.743L75.9993 216.398L77.457 215.608L78.9148 214.802L80.3234 214.013L81.7156 213.223L83.0915 212.434L84.4345 211.644L85.3026 211.118L86.5638 210.345L98.9464 202.712L121.713 188.682L122.696 188.073L123.597 187.432L124.383 186.774L125.071 186.099L125.644 185.409L126.119 184.718L126.496 184.01L126.775 183.303C126.775 183.303 132.327 180.079 127.97 176.938C127.872 176.872 127.905 176.707 127.79 176.625C127.692 176.559 127.708 176.395 127.594 176.329C127.495 176.263 127.495 176.099 127.397 176.049C127.299 176 127.299 175.835 127.2 175.77C127.119 175.72 127.119 175.572 127.037 175.523C126.955 175.474 126.938 175.326 126.856 175.276C126.775 175.227 126.742 175.079 126.66 175.046C126.578 175.013 126.529 174.849 126.447 174.816C125.825 174.487 124.318 173.862 123.515 173.516C123.417 173.483 123.335 173.319 123.237 173.286C123.138 173.253 123.024 173.089 122.925 173.056C122.827 173.023 122.68 172.858 122.582 172.825C122.418 172.76 122.172 172.579 121.975 172.513C121.779 172.447 121.517 172.283 121.304 172.217C121.091 172.151 120.813 172.003 120.583 171.937C120.354 171.871 120.026 171.723 119.797 171.674C119.633 171.641 119.388 171.526 119.207 171.493C111.739 169.881 93.4267 169.766 75.2786 170.161C56.1806 170.572 37.5575 171.608 32.4308 172.529C26.8783 173.533 22.4232 174.585 19 175.457C0.540682 180.178 3.5053 182.218 3.5053 182.218H3.52168Z"
            fill="#F1F2FA"
          />
          <path
            opacity="0.25"
            d="M4.5699 181.823V181.987L4.60266 182.152L4.6518 182.3L4.73369 182.464L4.84835 182.629L4.99576 182.793L5.15955 182.958L5.2742 183.056L5.48713 183.221L9.00864 186.247L12.481 188.682L36.198 202.351L59.915 216.019L61.2908 216.381L62.9942 216.792L65.0908 217.006H67.2037L68.7924 216.825L70.3976 216.628L72.0355 216.398L73.7225 216.134L75.688 215.805L77.113 215.065L78.538 214.309L79.9139 213.568L81.2733 212.812L82.6164 212.055L83.9267 211.299L84.7785 210.805L86.0069 210.065L98.0128 202.68L120.108 189.093L121.124 188.451L122.057 187.794L122.893 187.119L123.613 186.445L124.236 185.754L124.76 185.063L125.186 184.372L125.53 183.681C125.53 183.681 131.574 180.161 127.544 177.283C127.446 177.217 127.544 177.036 127.43 176.97C127.331 176.905 127.397 176.724 127.299 176.658C127.217 176.608 127.25 176.411 127.168 176.345C127.086 176.296 127.102 176.099 127.02 176.049C126.955 176 126.971 175.819 126.889 175.786C126.807 175.753 126.824 175.556 126.742 175.523C126.66 175.49 126.643 175.293 126.578 175.26C126.512 175.227 126.463 175.029 126.381 174.997C125.808 174.7 124.072 173.993 123.351 173.681C123.269 173.648 123.171 173.45 123.089 173.417C123.007 173.385 122.893 173.187 122.794 173.154C122.696 173.121 122.549 172.924 122.467 172.891C122.303 172.825 122.057 172.611 121.894 172.562C121.73 172.513 121.435 172.299 121.238 172.25C121.042 172.2 120.747 172.003 120.534 171.954C120.321 171.904 119.977 171.723 119.764 171.674C119.617 171.641 119.338 171.509 119.191 171.477C112.295 169.98 93.9835 170.013 75.7044 170.473C57.4253 170.934 38.835 171.937 33.3808 172.875C27.9265 173.812 23.4223 174.816 20.0973 175.654C2.11304 180.096 4.61904 181.839 4.61904 181.839L4.5699 181.823Z"
            fill="white"
          />
          <path
            opacity="0.31"
            d="M5.61871 181.444V181.642L5.66785 181.839L5.73337 182.036L5.84802 182.234L5.97905 182.431L6.14284 182.629L6.33939 182.826L6.48681 182.941L6.73249 183.138L10.8928 186.461L14.9057 189.192L37.5252 202.252L60.1448 215.312L61.4715 215.674L63.1094 216.085L65.1077 216.315H67.1223L68.6619 216.184L70.218 216.019L71.8067 215.805L73.4283 215.575L75.3119 215.279L76.7041 214.588L78.0963 213.881L79.4394 213.19L80.7661 212.483L82.0764 211.759L83.354 211.035L84.173 210.558L85.3686 209.851L97.0142 202.712L118.438 189.57L119.503 188.912L120.485 188.238L121.354 187.563L122.123 186.872L122.795 186.181L123.368 185.491L123.843 184.8L124.236 184.109C124.236 184.109 130.755 180.309 127.054 177.661C126.972 177.595 127.103 177.398 127.021 177.332C126.939 177.283 127.054 177.052 126.955 177.003C126.873 176.954 126.955 176.724 126.873 176.674C126.791 176.625 126.873 176.411 126.775 176.362C126.71 176.329 126.775 176.098 126.693 176.066C126.611 176.033 126.644 175.802 126.579 175.769C126.513 175.737 126.513 175.506 126.431 175.473C126.349 175.441 126.333 175.21 126.267 175.177C125.743 174.898 123.794 174.125 123.123 173.845C123.041 173.812 122.959 173.582 122.877 173.549C122.795 173.516 122.68 173.286 122.598 173.253C122.516 173.22 122.369 172.99 122.271 172.957C122.123 172.891 121.878 172.661 121.714 172.595C121.55 172.529 121.255 172.299 121.075 172.249C120.895 172.2 120.584 171.97 120.387 171.937C120.191 171.904 119.83 171.674 119.634 171.624C119.503 171.592 119.208 171.46 119.061 171.427C112.738 170.062 94.4263 170.21 75.9998 170.753C57.5733 171.295 39.9985 172.249 34.2003 173.187C28.6969 174.075 24.2909 175.029 21.0642 175.819C3.53856 179.98 5.60233 181.428 5.60233 181.428L5.61871 181.444Z"
            fill="#FFFEFE"
          />
          <path
            opacity="0.38"
            d="M6.64889 181.05V181.28L6.69803 181.511L6.77992 181.741L6.91095 181.988L7.07475 182.218L7.27129 182.448L7.5006 182.679L7.68077 182.81L7.9756 183.024L12.7747 186.643L17.3281 189.653L38.8502 202.088L60.3724 214.523L61.6664 214.901L63.2224 215.313L65.1223 215.543L67.0387 215.592L68.5292 215.477L70.0361 215.345L71.5757 215.164L73.1481 214.951L74.9498 214.671L76.3093 214.046L77.6688 213.405L78.9791 212.763L80.273 212.105L81.5506 211.431L82.7954 210.74L83.598 210.279L84.7445 209.588L96.0134 202.68L116.749 189.982L117.847 189.307L118.862 188.616L119.779 187.926L120.582 187.235L121.286 186.544L121.909 185.853L122.449 185.162L122.908 184.471C122.908 184.471 129.902 180.392 126.544 177.991C126.462 177.941 126.659 177.711 126.577 177.645C126.495 177.596 126.659 177.349 126.577 177.3C126.495 177.25 126.642 177.004 126.56 176.954C126.495 176.905 126.593 176.658 126.511 176.609C126.446 176.576 126.528 176.329 126.462 176.296C126.397 176.264 126.462 176.017 126.38 175.984C126.298 175.951 126.331 175.688 126.266 175.671C126.2 175.655 126.184 175.375 126.118 175.359C125.643 175.112 123.481 174.257 122.875 173.994C122.81 173.961 122.728 173.714 122.662 173.681C122.597 173.648 122.482 173.402 122.4 173.369C122.318 173.336 122.171 173.089 122.089 173.056C121.958 173.007 121.696 172.727 121.548 172.678C121.401 172.628 121.106 172.365 120.942 172.316C120.779 172.267 120.451 172.02 120.271 171.97C120.091 171.921 119.714 171.691 119.534 171.642C119.403 171.609 119.108 171.461 118.977 171.428C113.228 170.194 94.8996 170.457 76.342 171.082C57.7845 171.707 41.2088 172.612 35.0503 173.55C29.5633 174.388 25.1901 175.293 22.0616 176.017C5.01098 179.899 6.59975 181.05 6.59975 181.05H6.64889Z"
            fill="white"
          />
          <path
            opacity="0.44"
            d="M7.69727 180.655V180.919L7.76278 181.182L7.86106 181.445L8.00847 181.725L8.18864 181.988L8.41795 182.251L8.69639 182.514L8.89294 182.662L9.2369 182.909L14.6912 186.84L19.8014 190.146L40.2426 201.973L60.6837 213.799L61.9285 214.178L63.419 214.589L65.2207 214.836L67.0388 214.901L68.4802 214.819L69.9379 214.704L71.412 214.556L72.9353 214.375L74.6715 214.128L75.9982 213.553L77.3249 212.96L78.6025 212.368L79.8637 211.743L81.1085 211.102L82.3205 210.444L83.1068 210L84.2205 209.342L95.129 202.68L115.193 190.426L116.324 189.735L117.372 189.028L118.322 188.337L119.174 187.646L119.927 186.955L120.599 186.281L121.188 185.606L121.712 184.932C121.712 184.932 129.181 180.573 126.151 178.402C126.086 178.353 126.331 178.089 126.249 178.04C126.184 177.991 126.397 177.727 126.315 177.678C126.249 177.629 126.43 177.366 126.348 177.316C126.282 177.283 126.413 176.987 126.348 176.954C126.299 176.922 126.397 176.642 126.348 176.609C126.299 176.576 126.348 176.296 126.299 176.264C126.249 176.231 126.266 175.951 126.217 175.918C126.167 175.885 126.151 175.606 126.086 175.573C125.66 175.342 123.285 174.421 122.728 174.191C122.662 174.158 122.597 173.879 122.531 173.846C122.466 173.813 122.351 173.533 122.286 173.5C122.22 173.467 122.056 173.188 121.991 173.155C121.876 173.105 121.614 172.809 121.483 172.76C121.352 172.711 121.041 172.415 120.893 172.382C120.746 172.349 120.402 172.053 120.238 172.02C120.074 171.987 119.681 171.707 119.518 171.674C119.403 171.642 119.092 171.477 118.977 171.444C113.801 170.326 95.473 170.737 76.768 171.411C59.5699 172.036 42.5029 172.974 36.0004 173.911C30.5461 174.701 26.2057 175.556 23.1592 176.231C6.58349 179.833 7.71365 180.688 7.71365 180.688L7.69727 180.655Z"
            fill="white"
          />
          <path
            opacity="0.5"
            d="M8.74616 180.277V180.589L8.81168 180.902L8.90995 181.198L9.07374 181.51L9.28667 181.806L9.54874 182.102L9.85994 182.382L10.0892 182.563L10.466 182.826L16.559 187.053L22.2098 190.639L41.5535 201.841L60.8973 213.042L62.093 213.42L63.5179 213.815L65.2214 214.062L66.9412 214.144L68.3334 214.095L69.742 214.013L71.167 213.897L72.6411 213.733L74.2954 213.519L75.5894 213.009L76.8833 212.466L78.1281 211.907L79.3566 211.332L80.5686 210.723L81.7479 210.098L82.5177 209.67L83.5988 209.029L94.1305 202.614L113.523 190.82L114.686 190.113L115.784 189.389L116.766 188.682L117.667 187.991L118.47 187.3L119.191 186.626L119.829 185.951L120.403 185.277C120.403 185.277 128.347 180.622 125.66 178.698C125.595 178.648 125.906 178.369 125.841 178.336C125.775 178.286 126.037 177.99 125.972 177.957C125.906 177.924 126.135 177.612 126.07 177.579C126.004 177.546 126.184 177.234 126.119 177.201C126.07 177.168 126.201 176.855 126.152 176.822C126.103 176.789 126.201 176.477 126.152 176.444C126.103 176.411 126.152 176.099 126.103 176.066C126.053 176.033 126.053 175.72 126.004 175.687C125.628 175.49 123.023 174.487 122.548 174.289C122.499 174.273 122.434 173.944 122.368 173.911C122.303 173.878 122.188 173.566 122.139 173.533C122.09 173.5 121.91 173.187 121.844 173.154C121.746 173.105 121.467 172.776 121.353 172.743C121.238 172.71 120.91 172.381 120.779 172.332C120.648 172.283 120.288 171.986 120.157 171.954C120.026 171.921 119.584 171.625 119.453 171.592C119.354 171.575 119.027 171.378 118.912 171.345C114.31 170.358 95.9814 170.884 77.129 171.641C60.4223 172.315 43.732 173.237 36.8855 174.174C31.4476 174.914 27.1399 175.72 24.1917 176.345C8.07462 179.668 8.74616 180.227 8.74616 180.227V180.277Z"
            fill="white"
          />
          <path
            opacity="0.56"
            d="M9.77734 179.882V180.227L9.85924 180.572L9.97389 180.918L10.1541 181.263L10.3834 181.609L10.6782 181.938L11.0222 182.25L11.2842 182.447L11.7101 182.744L18.4419 187.267L24.6332 191.149L42.8959 201.725L61.1586 212.302L62.3052 212.696L63.6646 213.091L65.2698 213.354L66.8913 213.47H68.2344L69.5939 213.404L70.9697 213.305L72.3783 213.173L73.9507 212.976L75.2119 212.516L76.4731 212.022L77.7016 211.512L78.9136 210.969L80.0929 210.41L81.2231 209.834L81.9601 209.44L83.0084 208.831L93.1798 202.663L111.901 191.313L113.113 190.59L114.243 189.866L115.275 189.159L116.209 188.451L117.061 187.76L117.83 187.086L118.518 186.412L119.157 185.754C119.157 185.754 127.576 180.819 125.234 179.141C125.185 179.108 125.529 178.796 125.479 178.763C125.43 178.73 125.742 178.401 125.676 178.368C125.627 178.335 125.889 178.006 125.823 177.973C125.774 177.941 125.987 177.595 125.922 177.562C125.873 177.546 126.053 177.184 126.004 177.167C125.954 177.151 126.069 176.789 126.036 176.773C126.004 176.756 126.053 176.394 126.004 176.362C125.954 176.329 125.971 175.983 125.922 175.95C125.594 175.769 122.777 174.7 122.351 174.519C122.302 174.503 122.236 174.141 122.187 174.108C122.138 174.075 122.023 173.73 121.974 173.697C121.925 173.664 121.745 173.319 121.696 173.302C121.598 173.269 121.336 172.891 121.237 172.858C121.139 172.825 120.795 172.463 120.697 172.43C120.598 172.397 120.205 172.052 120.091 172.019C119.976 171.986 119.517 171.657 119.403 171.641C119.321 171.624 118.961 171.41 118.879 171.394C114.866 170.522 96.5048 171.164 77.5214 172.019C61.2897 172.743 44.9925 173.664 37.802 174.585C32.3805 175.276 28.1056 176.033 25.2556 176.592C9.61355 179.635 9.82648 179.898 9.82648 179.898L9.77734 179.882Z"
            fill="white"
          />
          <path
            opacity="0.62"
            d="M10.8262 179.503V179.882L10.9081 180.26L11.0391 180.638L11.2356 181.017L11.4977 181.395L11.8089 181.757L12.1856 182.102L12.4805 182.316L12.9555 182.629L20.3261 187.448L27.0579 191.61L44.2232 201.577L61.3885 211.545L62.4859 211.94L63.7799 212.335L65.2868 212.598L66.8264 212.73H68.1203H69.4307L70.7574 212.664L72.1168 212.565L73.6237 212.401L74.8522 212.006L76.0806 211.562L77.2763 211.101L78.4556 210.608L79.6021 210.081L80.6995 209.539L81.4202 209.16L82.4357 208.585L92.2304 202.663L110.264 191.758L111.509 191.017L112.688 190.277L113.753 189.553L114.735 188.846L115.636 188.155L116.455 187.481L117.209 186.823L117.913 186.165C117.913 186.165 126.807 180.951 124.792 179.503C124.743 179.47 125.152 179.142 125.103 179.109C125.054 179.076 125.415 178.73 125.365 178.697C125.316 178.665 125.627 178.303 125.578 178.27C125.529 178.237 125.775 177.875 125.742 177.842C125.709 177.826 125.906 177.447 125.857 177.414C125.808 177.382 125.955 177.003 125.922 176.987C125.889 176.97 125.955 176.576 125.922 176.559C125.889 176.543 125.906 176.148 125.873 176.131C125.595 175.983 122.565 174.848 122.188 174.684C122.139 174.667 122.09 174.273 122.057 174.256C122.024 174.24 121.893 173.845 121.86 173.829C121.827 173.812 121.631 173.417 121.598 173.401C121.516 173.368 121.238 172.973 121.156 172.94C121.074 172.907 120.73 172.529 120.632 172.496C120.534 172.463 120.14 172.102 120.042 172.069C119.944 172.036 119.469 171.69 119.371 171.657C119.289 171.641 118.928 171.411 118.846 171.394C115.407 170.654 97.0459 171.394 77.9151 172.348C62.1583 173.121 46.2542 174.042 38.7034 174.947C33.3147 175.589 29.0561 176.296 26.3044 176.806C11.1374 179.586 10.8917 179.536 10.8917 179.536L10.8262 179.503Z"
            fill="white"
          />
          <path
            opacity="0.69"
            d="M11.8594 179.109V179.52L11.9577 179.931L12.1051 180.342L12.318 180.754L12.5964 181.165L12.9404 181.56L13.3499 181.938L13.6611 182.185L14.1688 182.53L22.1946 187.646L29.4669 192.103L45.5349 201.446L61.6028 210.789L62.651 211.184L63.8795 211.578L65.2881 211.858L66.7294 212.022L67.9743 212.072H69.2354L70.513 212.039L71.8233 211.957L73.2483 211.809L74.444 211.463L75.6397 211.085L76.8026 210.674L77.9491 210.23L79.0629 209.753L80.1276 209.243L80.8319 208.881L81.8146 208.322L91.249 202.647L108.611 192.185L109.888 191.412L111.1 190.656L112.214 189.932L113.23 189.225L114.163 188.534L115.031 187.859L115.834 187.202L116.587 186.544C116.587 186.544 125.956 181.05 124.286 179.849C124.253 179.816 124.712 179.471 124.662 179.438C124.63 179.405 125.039 179.043 124.99 179.01C124.957 178.994 125.301 178.599 125.252 178.566C125.219 178.55 125.498 178.138 125.465 178.122C125.432 178.105 125.662 177.694 125.629 177.678C125.596 177.661 125.76 177.234 125.727 177.217C125.694 177.201 125.793 176.773 125.76 176.757C125.727 176.74 125.76 176.313 125.727 176.296C125.481 176.165 122.238 174.964 121.943 174.832C121.911 174.832 121.862 174.388 121.829 174.372C121.796 174.355 121.681 173.928 121.649 173.911C121.616 173.895 121.436 173.467 121.387 173.451C121.321 173.418 121.043 172.99 120.961 172.974C120.879 172.957 120.535 172.529 120.453 172.513C120.371 172.497 119.962 172.085 119.88 172.069C119.798 172.052 119.306 171.658 119.208 171.641C119.143 171.641 118.766 171.378 118.7 171.362C115.834 170.737 97.4731 171.608 78.2112 172.628C62.9295 173.434 47.4185 174.355 39.5237 175.26C34.1514 175.868 29.9255 176.51 27.2721 176.971C12.5801 179.471 11.8594 179.125 11.8594 179.125V179.109Z"
            fill="white"
          />
          <path
            opacity="0.75"
            d="M12.9062 178.714V179.175L13.0045 179.635L13.1683 180.079L13.3976 180.523L13.7088 180.968L14.0855 181.395L14.5278 181.806L14.8717 182.07L15.4286 182.431L24.0932 187.859L31.9224 192.597L46.9093 201.331L61.8962 210.065L62.8953 210.476L64.0419 210.871L65.3522 211.151L66.6953 211.332L67.891 211.414L69.103 211.447H70.3314L71.5926 211.381L72.9357 211.266L74.0986 210.97L75.2616 210.641L76.3917 210.279L77.5055 209.868L78.5865 209.424L79.6184 208.947L80.29 208.601L81.2236 208.075L90.2812 202.63L106.972 192.613L108.282 191.824L109.527 191.051L110.673 190.31L111.738 189.603L112.721 188.912L113.638 188.254L114.49 187.596L115.309 186.955C115.309 186.955 125.152 181.181 123.809 180.211C123.777 180.194 124.301 179.816 124.268 179.8C124.235 179.783 124.694 179.372 124.661 179.356C124.628 179.339 125.021 178.911 124.989 178.895C124.956 178.879 125.283 178.434 125.251 178.418C125.234 178.418 125.48 177.957 125.447 177.941C125.414 177.925 125.611 177.48 125.578 177.464C125.545 177.448 125.66 176.987 125.644 176.97C125.627 176.954 125.644 176.493 125.644 176.477C125.447 176.378 121.991 175.095 121.746 174.98C121.713 174.98 121.68 174.503 121.647 174.487C121.614 174.47 121.5 174.01 121.483 173.993C121.467 173.977 121.271 173.516 121.238 173.5C121.189 173.483 120.894 173.023 120.845 173.006C120.796 172.99 120.419 172.546 120.353 172.513C120.288 172.48 119.862 172.069 119.796 172.052C119.731 172.036 119.223 171.625 119.141 171.608C119.092 171.608 118.699 171.329 118.65 171.312C116.357 170.819 97.9794 171.806 78.5865 172.908C63.7798 173.763 48.6619 174.684 40.4232 175.556C35.0836 176.115 30.8741 176.707 28.319 177.102C14.0855 179.323 12.9226 178.681 12.9226 178.681L12.9062 178.714Z"
            fill="white"
          />
          <path
            opacity="0.81"
            d="M13.9546 178.335V178.829L14.0529 179.322L14.2167 179.816L14.4624 180.293L14.79 180.77L15.1994 181.23L15.6744 181.658L16.0512 181.937L16.6408 182.332L25.9442 188.056L34.3139 193.09L48.2034 201.199L62.0929 209.308L63.0429 209.719L64.1239 210.114L65.3359 210.41L66.5808 210.607L67.7273 210.722L68.8902 210.788H70.0695H71.2652L72.5428 210.706L73.6729 210.476L74.8031 210.196L75.9005 209.884L76.9815 209.522L78.0298 209.111L79.0289 208.666L79.6841 208.337L80.5849 207.844L89.2822 202.646L105.301 193.09L106.66 192.284L107.954 191.494L109.15 190.754L110.264 190.047L111.296 189.356L112.262 188.698L113.179 188.056L114.064 187.415C114.064 187.415 124.383 181.345 123.384 180.638C123.367 180.622 123.924 180.227 123.908 180.21C123.891 180.21 124.399 179.766 124.366 179.75C124.35 179.75 124.776 179.289 124.759 179.273C124.743 179.273 125.087 178.796 125.071 178.779C125.054 178.779 125.333 178.286 125.316 178.286C125.3 178.286 125.496 177.792 125.48 177.776C125.464 177.76 125.595 177.266 125.562 177.266C125.529 177.266 125.595 176.756 125.562 176.74C125.415 176.657 121.746 175.309 121.565 175.226C121.549 175.226 121.516 174.717 121.484 174.7C121.451 174.684 121.353 174.19 121.32 174.19C121.287 174.19 121.107 173.68 121.074 173.68C121.041 173.68 120.747 173.17 120.697 173.154C120.648 173.137 120.288 172.66 120.239 172.644C120.19 172.628 119.747 172.167 119.698 172.151C119.649 172.134 119.125 171.706 119.059 171.69C119.027 171.69 118.617 171.394 118.568 171.377C116.848 170.999 98.4709 172.101 78.9306 173.302C64.5989 174.19 49.8577 175.144 41.275 175.983C35.9518 176.509 31.7751 177.036 29.3182 177.381C15.5598 179.322 13.9219 178.385 13.9219 178.385L13.9546 178.335Z"
            fill="white"
          />
          <path
            opacity="0.88"
            d="M14.9866 177.941V178.467L15.1013 178.994L15.2815 179.52L15.5435 180.03L15.9039 180.54L16.3461 181.033L16.8702 181.494L17.2633 181.79L17.9021 182.201L27.8442 188.221L36.7545 193.534L49.5466 201.035L62.3387 208.535L63.2395 208.947L64.255 209.325L65.3688 209.637L66.5154 209.868L67.6128 210.016L68.7265 210.114L69.8567 210.164H71.0032L72.1989 210.098L73.2963 209.917L74.3937 209.687L75.4584 209.424L76.5066 209.111L77.5221 208.749L78.4885 208.338L79.1273 208.042L79.9954 207.565L88.316 202.614L103.647 193.501L105.039 192.679L106.366 191.873L107.594 191.116L108.741 190.393L109.822 189.702L110.837 189.044L111.804 188.402L112.737 187.761C112.737 187.761 123.531 181.412 122.876 180.935C122.876 180.935 123.482 180.507 123.466 180.491C123.466 180.491 124.006 180.03 123.99 180.014C123.99 180.014 124.448 179.52 124.432 179.52C124.432 179.52 124.809 179.01 124.792 179.01C124.792 179.01 125.087 178.5 125.071 178.484C125.054 178.467 125.284 177.957 125.267 177.941C125.251 177.925 125.398 177.398 125.382 177.398C125.366 177.398 125.431 176.855 125.415 176.855C125.316 176.806 121.418 175.375 121.304 175.326L121.254 174.783C121.254 174.783 121.123 174.24 121.107 174.24C121.091 174.24 120.894 173.714 120.878 173.697C120.845 173.697 120.55 173.171 120.534 173.154C120.517 173.138 120.124 172.628 120.091 172.628C120.059 172.628 119.6 172.135 119.567 172.118C119.535 172.102 118.994 171.641 118.945 171.641C118.912 171.641 118.486 171.329 118.47 171.329C117.323 171.082 98.9296 172.266 79.2583 173.582C65.4016 174.503 51.0535 175.474 42.1268 176.296C36.82 176.79 32.6761 177.25 30.3175 177.546C17.034 179.208 14.9375 177.974 14.9375 177.974L14.9866 177.941Z"
            fill="white"
          />
          <path
            opacity="0.94"
            d="M16.0347 177.546V178.122L16.1494 178.681L16.3459 179.24L16.6244 179.783L17.0011 180.326L17.4597 180.852L18.0166 181.345L18.4424 181.674L19.1304 182.119L29.7277 188.435L39.1784 194.044L50.8895 200.919L62.6006 207.795L63.4523 208.206L64.4023 208.584L65.4178 208.897L66.4824 209.144L67.5307 209.325L68.5953 209.456L69.6763 209.522H70.7737H71.8875L72.9522 209.407L74.0168 209.226L75.0487 208.996L76.0642 208.716L77.0469 208.387L77.9805 208.009L78.603 207.729L79.4383 207.285L87.3985 202.581L102.058 193.912L103.483 193.073L104.842 192.267L106.12 191.511L107.316 190.787L108.429 190.096L109.494 189.438L110.509 188.797L111.509 188.172C111.509 188.172 122.794 181.543 122.45 181.296C122.45 181.296 123.121 180.852 123.105 180.836C123.105 180.836 123.711 180.342 123.695 180.342L124.202 179.832C124.202 179.832 124.628 179.306 124.612 179.289L124.939 178.747C124.939 178.747 125.185 178.187 125.169 178.187C125.152 178.187 125.316 177.628 125.316 177.612C125.316 177.595 125.382 177.036 125.365 177.036C125.316 177.003 121.205 175.506 121.14 175.473L121.107 174.898C121.107 174.898 120.992 174.322 120.976 174.322C120.959 174.322 120.763 173.763 120.763 173.746C120.763 173.746 120.452 173.187 120.435 173.187C120.419 173.187 120.026 172.644 120.009 172.644C119.993 172.644 119.518 172.118 119.502 172.118C119.485 172.118 118.912 171.624 118.896 171.608C118.896 171.608 118.437 171.279 118.421 171.279C117.847 171.147 99.4536 172.414 79.6348 173.845C66.2531 174.783 52.2981 175.802 43.0275 176.592C37.7534 177.036 33.6259 177.447 31.3656 177.694C18.5407 179.076 16.002 177.546 16.002 177.546H16.0347Z"
            fill="#FFF9F8"
          />
          <path
            d="M32.43 177.875C20.0802 178.977 17.0664 177.151 17.0664 177.151V177.76L17.1974 178.368L17.4104 178.961L17.7216 179.553L18.1147 180.128L18.606 180.671L19.1957 181.198L19.6543 181.543L20.375 182.004L41.6187 194.521L62.8624 207.038L63.665 207.466L64.5495 207.844L65.4667 208.173L66.4331 208.437L67.4322 208.65L68.4477 208.815L69.4796 208.914L70.5279 208.963H71.5761L72.608 208.881L73.6399 208.765L74.6554 208.585L75.6381 208.354L76.5881 208.075L77.489 207.729L78.0786 207.466L78.8812 207.038L100.453 194.357L122.024 181.675L122.761 181.214L123.416 180.704L123.973 180.161L124.432 179.602L124.792 179.026L125.07 178.434L125.251 177.842L125.332 177.234L121.008 175.654V175.046L120.877 174.437L120.664 173.845L120.353 173.253L119.96 172.677L119.469 172.134L118.879 171.608L118.42 171.263C118.42 171.263 100.01 172.644 80.0605 174.158C60.1107 175.671 38.6213 177.332 32.4464 177.875H32.43Z"
            fill="#FFF3F0"
          />
        </g>
        <path
          d="M35.3459 175.178L61.9948 159.7C62.2568 159.552 62.5353 159.437 62.8137 159.289V204.111L17.5254 178.402C14.5608 176.675 13.0703 173.682 13.0703 170.672V165.589L35.3295 175.162L35.3459 175.178Z"
          fill="#FFF7F5"
        />
        <path
          d="M61.9944 130.701L17.5251 156.509C11.5958 159.946 11.5958 168.549 17.5251 171.987L62.8133 197.696C68.3167 200.887 75.0977 200.887 80.6174 197.696L125.087 171.888C131.016 168.45 131.016 159.848 125.087 156.41L79.7985 130.701C74.2951 127.51 67.5141 127.51 61.9944 130.701Z"
          fill="white"
        />
        <path
          d="M61.9944 128.793L17.5251 154.601C11.5958 158.038 11.5958 166.641 17.5251 170.079L62.8133 195.788C68.3167 198.979 75.0977 198.979 80.6174 195.788L125.087 169.98C131.016 166.542 131.016 157.94 125.087 154.502L79.7985 128.793C74.2951 125.602 67.5141 125.602 61.9944 128.793Z"
          fill="url(#paint0_linear_1563_100882)"
        />
        <path
          d="M78.9473 135.059L79.6844 135.453V188.747C74.7215 191.625 68.5957 191.625 63.6328 188.747V134.697C68.4647 132.23 74.2137 132.328 78.9473 135.059Z"
          fill="#FFEDE9"
        />
        <path
          d="M91.3303 141.737L92.3622 137.346L123.81 140.273V158.482C123.81 161.196 122.467 163.91 119.797 165.456L79.7012 188.731V135.438L91.3467 141.721L91.3303 141.737Z"
          fill="white"
        />
        <path
          d="M18.7871 140.372V158.581C18.7871 161.295 20.1302 164.009 22.8 165.555L63.6495 188.747V134.697C63.4038 134.829 63.1418 134.928 62.9125 135.076L38.8843 149.024L18.8035 140.388L18.7871 140.372Z"
          fill="white"
        />
        <path
          d="M62.8963 109.448L22.8002 132.723C17.4442 135.831 17.4442 143.579 22.8002 146.688L63.6333 169.864C68.5962 172.742 74.722 172.742 79.6849 169.864L119.781 146.589C125.137 143.48 125.137 135.733 119.781 132.624L78.9478 109.448C73.9849 106.569 67.8591 106.569 62.8963 109.448Z"
          fill="white"
        />
        <path
          d="M62.8963 107.178L22.8002 130.453C17.4442 133.562 17.4442 141.309 22.8002 144.418L63.6333 167.594C68.5962 170.473 74.722 170.473 79.6849 167.594L119.781 144.319C125.137 141.211 125.137 133.463 119.781 130.354L78.9478 107.178C73.9849 104.3 67.8591 104.3 62.8963 107.178Z"
          fill="#FFEDE9"
        />
        <path
          d="M99.6015 152.083C99.6015 152.083 99.6998 152.083 99.749 152.05L115.047 143.595C115.194 143.513 115.244 143.332 115.162 143.201C115.08 143.053 114.9 143.003 114.769 143.086L99.4705 151.54C99.3231 151.622 99.274 151.803 99.3559 151.935C99.405 152.034 99.5033 152.083 99.6179 152.083H99.6015Z"
          fill="white"
        />
        <path
          d="M71.6265 165.422C74.1653 165.422 76.7204 164.797 78.9808 163.547L92.1332 156.277C92.2806 156.195 92.3297 156.014 92.2478 155.882C92.1659 155.734 91.9858 155.685 91.8547 155.767L78.7023 163.037C74.3455 165.455 68.924 165.455 64.5507 163.037L57.0327 158.975C56.8853 158.892 56.7052 158.958 56.6396 159.09C56.5577 159.238 56.6233 159.419 56.7543 159.485L64.2723 163.547C66.5326 164.797 69.0878 165.422 71.6265 165.422Z"
          fill="white"
        />
        <path
          d="M50.3501 155.965C50.4484 155.965 50.5631 155.916 50.6122 155.817C50.6941 155.669 50.6286 155.488 50.4976 155.422L27.8452 143.168C27.6978 143.086 27.5177 143.152 27.4521 143.283C27.3702 143.431 27.4358 143.612 27.5668 143.678L50.2191 155.932C50.2191 155.932 50.3174 155.965 50.3665 155.965H50.3501Z"
          fill="white"
        />
        <path
          d="M29.8769 130.536C29.8769 130.536 29.9752 130.536 30.0243 130.503L37.0182 126.637C37.1656 126.555 37.2148 126.374 37.1329 126.243C37.051 126.095 36.8708 126.045 36.7398 126.128L29.7459 129.993C29.5985 130.075 29.5494 130.256 29.6312 130.388C29.6804 130.486 29.7787 130.536 29.8933 130.536H29.8769Z"
          fill="white"
        />
        <path
          d="M40.6855 124.449C40.6855 124.449 40.7838 124.449 40.8329 124.416L63.8947 111.751C64.0422 111.668 64.0913 111.488 64.0094 111.356C63.9275 111.208 63.7473 111.159 63.6163 111.241L40.5545 123.906C40.4071 123.988 40.3579 124.169 40.4398 124.301C40.489 124.4 40.5873 124.449 40.7019 124.449H40.6855Z"
          fill="white"
        />
        <path
          d="M84.9594 115.452C85.0577 115.452 85.1724 115.403 85.2215 115.304C85.3034 115.156 85.2379 114.975 85.1068 114.909L78.3259 111.241C75.8854 109.892 73.0846 109.235 70.2837 109.366C70.1199 109.366 69.9889 109.514 70.0053 109.679C70.0053 109.843 70.1363 109.958 70.3001 109.958C73.0354 109.827 75.7052 110.452 78.0474 111.751L84.8284 115.419C84.8284 115.419 84.9267 115.452 84.9758 115.452H84.9594Z"
          fill="white"
        />
        <path
          d="M107.251 127.525C107.35 127.525 107.464 127.476 107.513 127.377C107.595 127.229 107.53 127.048 107.399 126.982L90.5118 117.837C90.3644 117.755 90.1843 117.821 90.1187 117.952C90.0532 118.084 90.1024 118.281 90.2334 118.347L107.12 127.492C107.12 127.492 107.219 127.525 107.268 127.525H107.251Z"
          fill="white"
        />
        <path
          opacity="0.3"
          d="M20.7524 13.8828V47.7669C10.2043 53.8694 3.81641 61.8469 3.81641 70.5482V36.697C3.81641 27.9793 10.1879 20.0181 20.7524 13.8828Z"
          fill="url(#paint1_linear_1563_100882)"
        />
        <path
          d="M11.2669 13.9646C11.2669 13.9646 11.398 13.9481 11.4471 13.8988C13.4781 12.2046 15.8203 10.5926 18.3918 9.09578C18.5392 9.01353 18.5884 8.8326 18.5065 8.68456C18.4246 8.53652 18.2444 8.50363 18.097 8.56942C15.4927 10.0827 13.1178 11.7276 11.0704 13.4382C10.9393 13.5369 10.923 13.7343 11.0376 13.8494C11.1031 13.9152 11.185 13.9646 11.2669 13.9646Z"
          fill="#EAEAEA"
        />
        <path
          d="M6.07569 19.2281C6.15758 19.2281 6.25586 19.1952 6.305 19.1129C6.9274 18.307 7.6317 17.501 8.38514 16.7114C8.4998 16.5963 8.4998 16.3989 8.38514 16.3002C8.27049 16.1851 8.07394 16.1851 7.97567 16.3002C7.20585 17.1062 6.48517 17.9286 5.84638 18.7511C5.74811 18.8827 5.76448 19.0636 5.89552 19.1623C5.94465 19.2116 6.01017 19.2281 6.07569 19.2281Z"
          fill="#FF6D51"
        />
        <path
          d="M6.07569 14.9677C6.15758 14.9677 6.25586 14.9348 6.305 14.8525C9.15496 11.201 13.2334 7.81255 18.3928 4.8189C18.5402 4.73666 18.5893 4.55573 18.5074 4.40769C18.4255 4.25965 18.2454 4.21031 18.098 4.29255C12.873 7.33554 8.74548 10.7568 5.84638 14.4742C5.74811 14.6058 5.76448 14.7868 5.89552 14.8854C5.94465 14.9348 6.01017 14.9512 6.07569 14.9512V14.9677Z"
          fill="#FF6D51"
        />
        <path
          d="M15.0509 2.59876C15.0509 2.59876 15.1655 2.59876 15.2146 2.54942C16.2138 1.87503 17.2784 1.20063 18.3922 0.559138C18.5396 0.476895 18.5887 0.295961 18.5069 0.147923C18.425 -0.000113896 18.2448 -0.0494597 18.0974 0.0327833C16.9672 0.690727 15.8862 1.36512 14.8707 2.03951C14.7397 2.12176 14.7069 2.31914 14.7888 2.45073C14.8379 2.53297 14.9362 2.58232 15.0345 2.58232L15.0509 2.59876Z"
          fill="#FF6D51"
        />
        <path
          d="M6.07569 10.7076C6.15758 10.7076 6.25586 10.6747 6.305 10.5925C7.92653 8.51998 9.95754 6.51325 12.3653 4.62166C12.4963 4.52297 12.5127 4.34204 12.4144 4.21045C12.3161 4.07886 12.136 4.06241 12.0049 4.1611C9.56444 6.08559 7.50067 8.12521 5.84638 10.2306C5.74811 10.3622 5.76448 10.5432 5.89552 10.6418C5.94465 10.6912 6.01017 10.7076 6.07569 10.7076Z"
          fill="#EAEAEA"
        />
        <path
          opacity="0.1"
          d="M29.2695 9.96722V43.8349C40.5056 39.6076 54.3623 37.0416 69.4148 36.8442V2.97656C54.3623 3.17395 40.5056 5.73993 29.2695 9.96722Z"
          fill="white"
        />
        <path
          opacity="0.3"
          d="M143.464 51.517V85.3847C143.464 74.5615 133.604 64.8897 118.158 58.4748V24.5742C133.62 30.9892 143.464 40.6609 143.464 51.517Z"
          fill="url(#paint2_linear_1563_100882)"
        />
        <path
          d="M129.951 6.95801C130.049 6.95801 130.131 6.90866 130.197 6.82642C130.279 6.69483 130.246 6.5139 130.115 6.41521C129.41 5.9382 128.69 5.47764 127.969 5.04997C127.838 4.96773 127.641 5.01708 127.56 5.14867C127.478 5.2967 127.527 5.47764 127.658 5.55988C128.379 5.98754 129.083 6.4481 129.787 6.92511C129.836 6.95801 129.902 6.97446 129.951 6.97446V6.95801Z"
          fill="#EAEAEA"
        />
        <path
          d="M125.758 4.39173C125.857 4.39173 125.955 4.34238 126.02 4.24369C126.102 4.09565 126.053 3.91472 125.906 3.84892C123.629 2.59883 121.172 1.41453 118.584 0.328924C118.437 0.263129 118.257 0.328924 118.191 0.49341C118.126 0.641447 118.191 0.822382 118.355 0.888176C120.926 1.95733 123.383 3.12519 125.627 4.37528C125.676 4.40818 125.726 4.40818 125.775 4.40818L125.758 4.39173Z"
          fill="#FF6D51"
        />
        <path
          d="M129.952 10.905C130.05 10.905 130.132 10.8556 130.198 10.7734C130.28 10.6418 130.247 10.4609 130.116 10.3622C126.775 8.10871 122.876 6.06909 118.569 4.27619C118.421 4.21039 118.241 4.27619 118.175 4.44068C118.11 4.58871 118.175 4.76965 118.339 4.83544C122.614 6.61189 126.48 8.65152 129.788 10.8721C129.837 10.905 129.903 10.9214 129.952 10.9214V10.905Z"
          fill="#EAEAEA"
        />
        <path
          d="M129.952 14.8534C130.05 14.8534 130.132 14.804 130.197 14.7218C130.279 14.5902 130.246 14.4093 130.115 14.3106C127.872 12.7973 125.365 11.3663 122.663 10.0668C122.516 10.001 122.335 10.0668 122.27 10.1984C122.204 10.3465 122.27 10.5274 122.401 10.5932C125.087 11.8926 127.56 13.3072 129.788 14.804C129.837 14.8369 129.903 14.8534 129.952 14.8534Z"
          fill="#FF6D51"
        />
        <path
          d="M120.01 9.45792C120.125 9.45792 120.239 9.39213 120.272 9.27699C120.338 9.12895 120.272 8.94802 120.125 8.88222C119.617 8.65194 119.093 8.43811 118.569 8.20783C118.421 8.14204 118.241 8.20783 118.175 8.37232C118.11 8.52035 118.175 8.70129 118.339 8.76708C118.863 8.98091 119.388 9.21119 119.895 9.42503C119.928 9.44147 119.977 9.45792 120.01 9.45792Z"
          fill="#EAEAEA"
        />
        <path
          d="M129.951 19.0967C130.049 19.0967 130.131 19.0473 130.197 18.9651C130.279 18.8335 130.246 18.6526 130.115 18.5539C129.41 18.0769 128.69 17.6163 127.969 17.1886C127.838 17.1064 127.641 17.1557 127.56 17.2873C127.478 17.4354 127.527 17.6163 127.658 17.6986C128.379 18.1262 129.083 18.5868 129.787 19.0638C129.836 19.0967 129.902 19.1131 129.951 19.1131V19.0967Z"
          fill="#EAEAEA"
        />
        <path
          d="M125.758 16.547C125.857 16.547 125.955 16.4977 126.02 16.399C126.102 16.2509 126.053 16.07 125.906 16.0042C123.629 14.7541 121.172 13.5698 118.584 12.4842C118.437 12.4184 118.257 12.4842 118.191 12.6487C118.126 12.7967 118.191 12.9777 118.355 13.0434C120.926 14.1126 123.383 15.2805 125.627 16.5306C125.676 16.5634 125.726 16.5634 125.775 16.5634L125.758 16.547Z"
          fill="#EAEAEA"
        />
        <path
          d="M129.952 23.0446C130.05 23.0446 130.132 22.9953 130.198 22.913C130.28 22.7814 130.247 22.6005 130.116 22.5018C126.775 20.2484 122.876 18.2087 118.569 16.4158C118.421 16.35 118.241 16.4158 118.175 16.5803C118.11 16.7284 118.175 16.9093 118.339 16.9751C122.614 18.7515 126.48 20.7912 129.788 23.0117C129.837 23.0446 129.903 23.0611 129.952 23.0611V23.0446Z"
          fill="#FF6D51"
        />
        <path
          d="M129.952 27.0086C130.05 27.0086 130.132 26.9593 130.197 26.8771C130.279 26.7455 130.246 26.5645 130.115 26.4658C127.872 24.9526 125.365 23.5215 122.663 22.2221C122.516 22.1563 122.335 22.2221 122.27 22.3537C122.204 22.5017 122.27 22.6827 122.401 22.7485C125.087 24.0479 127.56 25.4625 129.788 26.9593C129.837 26.9922 129.903 27.0086 129.952 27.0086Z"
          fill="#EAEAEA"
        />
        <path
          d="M120.01 21.5966C120.125 21.5966 120.239 21.5308 120.272 21.4157C120.338 21.2676 120.272 21.0867 120.125 21.0209C119.617 20.7906 119.093 20.5768 118.569 20.3465C118.421 20.2807 118.241 20.3465 118.175 20.511C118.11 20.659 118.175 20.84 118.339 20.9058C118.863 21.1196 119.388 21.3499 119.895 21.5637C119.928 21.5801 119.977 21.5966 120.01 21.5966Z"
          fill="#EAEAEA"
        />
        <path
          opacity="0.1"
          d="M3.81641 33.835V69.1665C3.81641 76.9632 8.92669 84.1512 17.5421 89.974V54.6589C8.92669 48.8361 3.81641 41.6316 3.81641 33.835Z"
          fill="white"
        />
        <path
          d="M15.0517 102.573C15.1499 102.573 15.2318 102.524 15.2973 102.441C15.3792 102.31 15.3465 102.129 15.2155 102.03C9.18794 97.9673 5.09316 93.3946 3.04577 88.4765C2.98025 88.3284 2.81646 88.2462 2.66905 88.312C2.52163 88.3778 2.43974 88.5423 2.50525 88.6903C4.5854 93.7236 8.76208 98.3785 14.8879 102.507C14.937 102.54 15.0025 102.556 15.0517 102.556V102.573Z"
          fill="#FF6D51"
        />
        <path
          d="M15.0517 108.396C15.1499 108.396 15.2318 108.347 15.2974 108.264C15.3956 108.133 15.3465 107.952 15.2155 107.853C12.906 106.29 10.8586 104.646 9.12242 102.935C9.00777 102.82 8.8276 102.82 8.71294 102.935C8.59829 103.05 8.59829 103.231 8.71294 103.346C10.4819 105.073 12.562 106.751 14.8879 108.33C14.937 108.363 15.0025 108.379 15.0517 108.379V108.396Z"
          fill="#EAEAEA"
        />
        <path
          d="M6.02677 100.221C6.02677 100.221 6.1578 100.205 6.20694 100.155C6.33797 100.057 6.35435 99.8758 6.25607 99.7442C4.89661 97.9842 3.81558 96.1584 3.04577 94.2997C2.98025 94.1517 2.81646 94.0694 2.66905 94.1352C2.52163 94.201 2.43974 94.3655 2.50525 94.5136C3.29145 96.4051 4.40523 98.2803 5.79746 100.09C5.86297 100.172 5.94487 100.205 6.02677 100.205V100.221Z"
          fill="#FF6D51"
        />
        <path
          d="M82.5673 80.1864H74.3941L72.1665 74.4787H62.6339L60.5701 80.1864H52.4297L64.0261 48.7695H70.6433L82.5673 80.1864ZM67.4657 57.9643L64.6485 67.7019H70.1355L67.4821 57.9643H67.4657Z"
          fill="url(#paint3_linear_1563_100882)"
        />
        <path
          d="M86.0723 48.7695H93.361V80.1864H86.0723V48.7695Z"
          fill="url(#paint4_linear_1563_100882)"
        />
        <path
          opacity="0.1"
          d="M79.7832 63.82V99.1351C97.1615 97.4738 112.263 92.5721 122.5 85.7624V50.4473C112.263 57.257 97.1615 62.1587 79.7832 63.82Z"
          fill="white"
        />
        <path
          d="M79.7827 105.485C89.6921 104.547 98.8972 102.574 107.136 99.6293C107.283 99.5799 107.365 99.399 107.316 99.2509C107.267 99.1029 107.103 99.0207 106.939 99.07C98.7334 101.998 89.5774 103.955 79.7499 104.893C79.5862 104.893 79.4715 105.057 79.4879 105.222C79.4879 105.37 79.6353 105.485 79.7827 105.485Z"
          fill="#EAEAEA"
        />
        <g opacity="0.6">
          <path
            opacity="0.3"
            d="M64.8278 97.4742V132.789C48.9564 132.592 34.4281 129.73 22.8809 125.091V89.7598C34.4281 94.4147 48.9564 97.2768 64.8278 97.4742Z"
            fill="url(#paint5_linear_1563_100882)"
          />
        </g>
        <path
          d="M36.8687 83.8706C36.9997 83.8706 37.1308 83.7719 37.1635 83.6403C37.1963 83.4758 37.098 83.3278 36.9506 83.2785C31.9877 82.1106 27.3033 80.6302 22.9956 78.9031C22.8482 78.8373 22.668 78.9031 22.6189 79.0676C22.5533 79.2157 22.6189 79.3966 22.7826 79.4459C27.1067 81.1895 31.8239 82.6699 36.8196 83.8542C36.8359 83.8542 36.8687 83.8542 36.8851 83.8542L36.8687 83.8706Z"
          fill="#EAEAEA"
        />
        <path
          d="M36.8692 79.4791C37.0002 79.4791 37.1312 79.3804 37.164 79.2489C37.1967 79.0844 37.0985 78.9363 36.951 78.887C35.739 78.5909 34.5269 78.2948 33.3476 77.9659C33.2002 77.9165 33.0201 78.0152 32.9873 78.1797C32.9382 78.3442 33.0364 78.4922 33.2002 78.5416C34.3795 78.8705 35.608 79.1831 36.8364 79.4627C36.8528 79.4627 36.8855 79.4627 36.9019 79.4627L36.8692 79.4791Z"
          fill="#EAEAEA"
        />
        <path
          d="M30.76 77.8342C30.8911 77.8342 31.0057 77.7519 31.0385 77.6203C31.0876 77.4723 30.9893 77.2914 30.8419 77.2585C28.123 76.436 25.4696 75.5149 22.98 74.5115C22.8325 74.4457 22.6524 74.5115 22.6032 74.676C22.5377 74.8241 22.6032 75.005 22.767 75.0543C25.273 76.0577 27.9264 76.9953 30.6781 77.8177C30.7109 77.8177 30.7273 77.8177 30.76 77.8177V77.8342Z"
          fill="#FF6D51"
        />
        <path
          d="M36.8687 75.0874C36.9997 75.0874 37.1308 74.9887 37.1635 74.8571C37.1963 74.6926 37.098 74.5446 36.9506 74.4953C31.9877 73.3274 27.3033 71.847 22.9956 70.1199C22.8482 70.0541 22.668 70.1199 22.6189 70.2844C22.5533 70.4325 22.6189 70.6134 22.7826 70.6627C27.1067 72.4063 31.8239 73.8867 36.8196 75.071C36.8359 75.071 36.8687 75.071 36.8851 75.071L36.8687 75.0874Z"
          fill="#EAEAEA"
        />
        <path
          opacity="0.1"
          d="M132.262 99.8758V129.977C125.154 134.714 116.292 138.793 106.252 141.984C98.5375 144.419 90.0695 146.31 81.0938 147.577V117.476C101.781 114.532 119.667 108.248 132.246 99.8594L132.262 99.8758Z"
          fill="white"
        />
        <path
          d="M128.609 98.6412C128.609 98.6412 128.707 98.6412 128.756 98.6083C130.017 97.8681 131.246 97.095 132.409 96.3219C132.54 96.2397 132.589 96.0423 132.491 95.9107C132.409 95.7791 132.212 95.7298 132.081 95.8285C130.918 96.6016 129.69 97.3582 128.445 98.0984C128.297 98.1806 128.265 98.3616 128.347 98.5096C128.396 98.6083 128.494 98.6576 128.609 98.6576V98.6412Z"
          fill="#EAEAEA"
        />
        <path
          d="M115.177 105.155C115.177 105.155 115.259 105.155 115.292 105.139C119.092 103.609 122.712 101.931 126.02 100.138C126.168 100.056 126.217 99.8752 126.135 99.7436C126.053 99.5956 125.873 99.5462 125.742 99.6285C122.45 101.405 118.863 103.083 115.079 104.596C114.932 104.662 114.85 104.826 114.915 104.974C114.965 105.089 115.079 105.155 115.194 105.155H115.177Z"
          fill="#FF6D51"
        />
        <path
          d="M121.616 98.1808C121.616 98.1808 121.697 98.1808 121.747 98.1479C125.612 96.3057 129.199 94.2825 132.426 92.1442C132.557 92.0619 132.606 91.8646 132.508 91.733C132.426 91.6014 132.229 91.552 132.098 91.6507C128.904 93.7726 125.334 95.7793 121.501 97.6216C121.354 97.6874 121.288 97.8683 121.354 98.0163C121.403 98.115 121.501 98.1808 121.616 98.1808Z"
          fill="#FF6D51"
        />
        <path
          d="M115.178 100.993C115.178 100.993 115.26 100.993 115.293 100.977C116.439 100.516 117.586 100.039 118.716 99.5458C118.864 99.4801 118.929 99.2991 118.864 99.1511C118.798 99.003 118.634 98.9372 118.47 99.003C117.357 99.4965 116.21 99.9735 115.064 100.434C114.916 100.5 114.834 100.664 114.9 100.812C114.949 100.928 115.064 100.993 115.178 100.993Z"
          fill="#EAEAEA"
        />
        <path
          d="M129.542 89.7429C129.542 89.7429 129.641 89.7429 129.69 89.6936C130.607 89.1343 131.524 88.5586 132.409 87.9665C132.54 87.8842 132.589 87.6868 132.491 87.5552C132.409 87.4237 132.212 87.3743 132.081 87.473C131.213 88.0487 130.296 88.6244 129.378 89.1837C129.231 89.2659 129.198 89.4468 129.28 89.5949C129.329 89.6936 129.428 89.7429 129.526 89.7429H129.542Z"
          fill="#EAEAEA"
        />
        <path
          d="M115.177 96.8322C115.177 96.8322 115.243 96.8322 115.292 96.8158C119.6 95.0887 123.645 93.1642 127.314 91.1081C127.462 91.0259 127.511 90.8449 127.429 90.7134C127.347 90.5653 127.167 90.516 127.036 90.5982C123.383 92.6543 119.371 94.5623 115.079 96.273C114.932 96.3388 114.85 96.5033 114.915 96.6513C114.965 96.7664 115.079 96.8322 115.194 96.8322H115.177Z"
          fill="#FF6D51"
        />
        <path
          d="M39.7539 175.21L47.2719 179.47V166.164L39.7539 161.903V175.21Z"
          fill="#FFEDE9"
        />
        <path
          d="M50.7109 168.121V181.412L58.2453 185.705V172.398L50.7109 168.121Z"
          fill="#FFEDE9"
        />
        <path
          d="M28.7793 168.976L36.2973 173.253V159.946L28.7793 155.686V168.976Z"
          fill="#FFEDE9"
        />
        <path
          d="M104.646 160.903V174.194L97.1445 178.47V165.164L104.646 160.903Z"
          fill="#FFEDE9"
        />
        <path
          d="M93.7043 167.121V180.412L86.1699 184.705V171.398L93.7043 167.121Z"
          fill="#FFEDE9"
        />
        <path
          d="M115.62 154.686V167.976L108.102 172.253V158.946L115.62 154.686Z"
          fill="#FFEDE9"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_1563_100882"
          x1="13.0781"
          y1="126.399"
          x2="52.2137"
          y2="227.972"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF478B" />
          <stop offset="1" stopColor="#FFBB54" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1563_100882"
          x1="3.81641"
          y1="13.8828"
          x2="39.6534"
          y2="31.0178"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF478B" />
          <stop offset="1" stopColor="#FFBB54" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1563_100882"
          x1="118.158"
          y1="24.5742"
          x2="163.744"
          y2="54.9221"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF478B" />
          <stop offset="1" stopColor="#FFBB54" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_1563_100882"
          x1="52.4297"
          y1="48.7695"
          x2="75.7824"
          y2="84.6075"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF478B" />
          <stop offset="1" stopColor="#FFBB54" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_1563_100882"
          x1="86.0723"
          y1="48.7695"
          x2="102.727"
          y2="54.9509"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF478B" />
          <stop offset="1" stopColor="#FFBB54" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_1563_100882"
          x1="22.8809"
          y1="89.7598"
          x2="54.655"
          y2="139.312"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF478B" />
          <stop offset="1" stopColor="#FFBB54" />
        </linearGradient>
        <clipPath id="clip0_1563_100882">
          <rect width="150" height="220" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
