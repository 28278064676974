import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import { getEmailCheckRequest } from '../../services/PublicService';
import {
  checkEmailAuthNumber,
  sendEmailRequest,
} from '../../services/RegCertService';
import { signupInfoForForeigner } from '../../store';
import Loader from '../common/Loader';
import InformModal from '../common/modal/InformModal';
import AuthTimer from '../hooks/AuthTimer';
import { emailRegex } from '../hooks/useValidate';

const inputStyle =
  'w-full h-full py-4 px-3 rounded-lg border border-borderLightGray focus:border-primary max-md:p-3 max-md:placeholder:text-sm';
const divStyle = 'flex flex-col space-y-2 max-2xl:text-base text-lg';
const requiredStyle = 'not-italic text-primary text-[8px]';
export default function ForeignerEmailAuth({
  isAuthorized,
  setIsAuthorized,
}: {
  isAuthorized: boolean;
  setIsAuthorized: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const [modal, setModal] = useState({
    isOpen: false,
    phrase: '',
    content: '',
  });
  const [foreignerInfo, setForeignerInfo] = useRecoilState(
    signupInfoForForeigner
  );
  const [isSent, setIsSent] = useState(false);
  const [uniqueId, setUniqueId] = useState('');
  const [authNum, setAuthNum] = useState('');
  const [time, setTime] = useState(600);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const sendAuthEmail = async () => {
    if (!emailRegex.test(foreignerInfo.memberEmail)) {
      setModal({
        isOpen: true,
        phrase: 'Invalid email address',
        content: 'Please check your email.',
      });
      return;
    }
    setLoading(true);
    await getEmailCheckRequest({ memberEmail: foreignerInfo.memberEmail })
      .then(async (res) => {
        const resData = res.data.data;
        if (resData.exist) {
          setLoading(false);
          setModal({
            isOpen: true,
            phrase: 'Duplicated email address',
            content: 'This e-mail is already in use.',
          });
          return;
        } else {
          sendEmailRequest({
            memberEmail: foreignerInfo.memberEmail,
            siteLangCd: 'EN',
          })
            .then((res) => {
              const resData = res.data.data;
              setModal({
                isOpen: true,
                phrase: 'The verification e-mail was sent.',
                content: `Enter the verification code in the verification e-mail sent to ${foreignerInfo.memberEmail} within 10 minutes (validity period).`,
              });
              setIsSent(true);
              setTime(600);
              setUniqueId(resData.authSendUuid);
            })
            .catch((err) => {
              alert(err);
            })
            .finally(() => setLoading(false));
        }
      })
      .catch((err) => {
        alert(err);
      });
  };

  const checkAuthNum = () => {
    setLoading(true);
    checkEmailAuthNumber({
      memberEmail: foreignerInfo.memberEmail,
      authSendUuid: uniqueId,
      authCode: authNum,
    })
      .then((res) => {
        if (res.data.data.authSuccess) {
          setModal({
            isOpen: true,
            phrase: 'Verification Completed',
            content: 'Your email verification is completed.',
          });
          setIsAuthorized(true);
        } else {
          setModal({
            isOpen: true,
            phrase: 'Verification Error',
            content: 'Please check your verification code.',
          });
          setAuthNum('');
        }
      })
      .catch((err) => {
        alert(err);
      })
      .finally(() => setLoading(false));
  };
  return (
    <>
      <Loader loading={loading} />
      <InformModal
        modal={modal.isOpen}
        phrase={modal.phrase}
        content={modal.content}
        closeModal={() =>
          setModal((prev) => ({
            ...prev,
            isOpen: false,
          }))
        }
      />
      <div className={divStyle}>
        <div className="font-medium flex items-center space-x-1">
          <span>Email Address</span>
          <i className={requiredStyle}>●</i>
        </div>
        <div className="flex space-x-3">
          <input
            onKeyDown={(e) => e.key === 'Enter' && sendAuthEmail()}
            className={`${
              isAuthorized
                ? 'bg-gray5 border-[#27AE60]'
                : 'border-borderLightGray'
            } w-full h-full py-4 px-3 rounded-lg border focus:border-primary max-md:p-3 max-md:placeholder:text-sm`}
            onChange={(e) =>
              setForeignerInfo((prev: any) => ({
                ...prev,
                memberEmail: e.target.value,
              }))
            }
            disabled={isAuthorized}
            maxLength={60}
            type="email"
            placeholder="Enter your e-mail address."
          />
          <button
            onClick={sendAuthEmail}
            disabled={isAuthorized}
            className={`min-w-[140px] max-2xl:min-w-[120px] py-3 max-md:text-sm border border-black rounded-lg hover:bg-slate-100 ${
              isAuthorized && 'border-borderLightGray text-gray2 hover:bg-white'
            }`}
          >
            {isSent ? 'Resend Code' : 'Send Code'}
          </button>
        </div>
        {isSent && !isAuthorized && (
          <div className="flex space-x-3">
            <div className="w-full relative">
              <input
                onKeyDown={(e) => e.key === 'Enter' && checkAuthNum()}
                className={inputStyle}
                type="text"
                maxLength={6}
                value={authNum}
                onChange={(e) => setAuthNum(e.target.value)}
                placeholder="Enter verification code."
              />
              {!isAuthorized && (
                <div className="absolute right-5 bottom-1/2 translate-y-1/2">
                  <AuthTimer time={time} setTime={setTime} />
                </div>
              )}
            </div>
            <button
              onClick={checkAuthNum}
              className="min-w-[140px] max-2xl:min-w-[120px] py-3 max-2xl:text-sm bg-black text-white rounded-lg hover:bg-opacity-80"
            >
              Confirm
            </button>
          </div>
        )}
        <span
          className={`${
            isAuthorized ? 'text-[#27AE60]' : 'text-textGray'
          } max-2xl:text-xs text-sm`}
        >
          {isAuthorized
            ? 'Email address verification is complete.'
            : isSent
            ? 'Please check the verification code sent via e-mail.'
            : 'Only alphanumeric characters and some symbols (- / _ / . / @) are allowed for input.'}
        </span>
      </div>
    </>
  );
}
