import axios from 'axios';
import { ENV } from '../env';
import { isLogin } from '../utils/common';
import TokenService from './token.service';

export const baseAPI =
  ENV.REACT_APP_SERVICE_MODE === 'production'
    ? ENV.REACT_APP_API_URL
    : ENV.REACT_APP_API_DEV_URL;
const instance = axios.create({
  withCredentials: true,
  baseURL: baseAPI,
  headers: {
    'Content-Type': 'application/json',
  },
});

instance.interceptors.request.use(
  (config) => {
    const token = config.token || TokenService.getLocalAccessToken();
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    } else {
      isLogin && console.log('[api] token not found');
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const AUTH_PAGES = [
  '/signup',
  '/login',
  '/api/br/auth',
  '/api/br/public/',
  '/api/br/sso/',
];
const isAuthPages = (url) => AUTH_PAGES.some((page) => page.startsWith(url));

instance.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const { config } = err;
    const originalConfig = config;
    const isAuthPageRequested = isAuthPages(config.url);

    if (!isAuthPageRequested && err.response?.status === 401) {
      // Access Token was expired
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;
        try {
          const res = await instance.post('/auth/refreshtoken', {
            refreshtoken: TokenService.getLocalRefreshToken(),
          });
          TokenService.updateLocalAuthToken(res.data);
          return instance(originalConfig);
        } catch (_error) {
          return Promise.reject(_error);
        }
      }
    }
    return Promise.reject(err);
  }
);

export default instance;
