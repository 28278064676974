import api from "./api";

const publicNameSpace = "/api/br/public/v1";

// import { NoticeListClientReqType, PloonianListReqDtoInf } from './TypeOfPublicService';
// import { PartnershipAddReqDtoInf } from "./dto/PartnershipDtoInf";
import { RequestDtoInf } from "./dto/CommonDtoInf";
import { PartnershipAddReqDtoInf } from "./dto/PartnershipDtoInf";
import { EmailCheckReqDtoInf } from "./dto/SignupDtoInf";
import {
  EnterpriseFindIdReqDtoInf,
  ForeignerFindIdReqDtoInf,
  HistoryListReqDtoInf,
  NoticeListClientReqType,
  PersonalFindIdReqDtoInf,
  PloonianListReqDtoInf,
  TermsListReqDtoInf,
  WakeUpDormantUsertReqDtoInf,
} from "./TypeOfPublicService";

const getPloonianList = async (reqBody: PloonianListReqDtoInf) => {
  return await api.post(`${publicNameSpace}/union/ploonian`, reqBody);
};

const reqTmpPassword = async (reqBody: { memberEmail: string }) => {
  return await api.post(`${publicNameSpace}/member/findpw`, reqBody);
};

const findEnterpriseId = async (reqBody: EnterpriseFindIdReqDtoInf) => {
  return await api.post(`${publicNameSpace}/member/findid`, reqBody);
};

const findPersonalId = async (reqBody: PersonalFindIdReqDtoInf) => {
  return await api.post(`${publicNameSpace}/member/findid`, reqBody);
};

const findForeignerId = async (reqBody: ForeignerFindIdReqDtoInf) => {
  return await api.post(`${publicNameSpace}/member/fr/findid`, reqBody);
};

const wakeDormantAccount = async (reqBody: WakeUpDormantUsertReqDtoInf) => {
  return await api.post(`${publicNameSpace}/member/wakeup`, reqBody);
};

const getBanner = async (reqBody: { siteLangCd: "KR" | "EN" }) => {
  return await api.post(`${publicNameSpace}/banner`, reqBody);
};

const getModal = async (reqBody: { siteLangCd: "KR" | "EN" }) => {
  return await api.post(`${publicNameSpace}/popup`, reqBody);
};

const getNoticeRequest = async (reqBody: NoticeListClientReqType) => {
  return await api.post(`${publicNameSpace}/notice`, reqBody);
};

const getNoticeDetail = async (reqUrl: string) => {
  return await api.post(`${publicNameSpace}/notice${reqUrl}`);
};

const reqCompanyHistory = async (reqBody: HistoryListReqDtoInf) => {
  return await api.post(`${publicNameSpace}/history`, reqBody);
};

const reqAgreementTerms = async (reqBody: TermsListReqDtoInf) => {
  return await api.post(`${publicNameSpace}/terms`, reqBody);
};

const getNoticeDetailRequest = async (id: string) => {
  return await api.post(`${publicNameSpace}/notice` + id);
};

const addPartnershipRequest = async (reqBody: PartnershipAddReqDtoInf) => {
  return await api.post(`${publicNameSpace}/partnership/add`, reqBody);
};

const getFamilySites = async (langCd: RequestDtoInf) => {
  return await api.post(`${publicNameSpace}/site`, langCd);
};

/**
 * 이메일 체크
 * @param reqBody : EmailCheckReqDtoInf
 * @returns exist true: 이메일 존재, false: 이메일 없음
 */
const getEmailCheckRequest = async (reqBody: EmailCheckReqDtoInf) => {
  return await api.post(`${publicNameSpace}/member/email/exist`, reqBody);
};

const checkCiDuplicate = async (reqBody: { ciVal: string }) => {
  return await api.post(`${publicNameSpace}/member/ci/exist`, reqBody);
};

/**
 * 개인 회원가입 요청
 * @param reqBody FormData (SignupPersonalReqDtoInf, file)
 * @returns
 */
const signUpPersonalRequest = async (reqBody: FormData) => {
  return await api.post(`${publicNameSpace}/member/add`, reqBody, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

/**
 * 회사 회원가입 요청
 * @param reqBody FormData (SignupCompanyReqDtoInf, file)
 * @returns
 */
const signUpCompanyRequest = async (reqBody: FormData) => {
  return await api.post(`${publicNameSpace}/member/add`, reqBody, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

/**
 * quick start
 */
// FIXME:type
const quickSignUp = async (reqBody: any) => {
  return await api.post(`${publicNameSpace}/member/add-quickstart`, reqBody, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

/**
 * inquiry
 */
// FIXME:type
const quickserveInquiry = async (reqBody: any) => {
  return await api.post(`${publicNameSpace}/quickserve/send`, reqBody);
};

export {
  addPartnershipRequest,
  checkCiDuplicate,
  findEnterpriseId,
  findForeignerId,
  findPersonalId,
  getBanner,
  getEmailCheckRequest,
  getFamilySites,
  getModal,
  getNoticeDetail,
  getNoticeDetailRequest,
  getNoticeRequest,
  getPloonianList,
  quickSignUp,
  reqAgreementTerms,
  reqCompanyHistory,
  reqTmpPassword,
  signUpCompanyRequest,
  signUpPersonalRequest,
  wakeDormantAccount,
  quickserveInquiry,
};
